"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      hour: '',
      minute: '',
      second: '',
      timer: ''
    };
  },
  props: {
    remainTime: {
      // 倒计时间总秒数
      default: ''
    }
  },
  mounted: function mounted() {
    if (this.remainTime > 0) {
      this.hour = Math.floor(this.remainTime / 3600 % 24);
      this.minute = Math.floor(this.remainTime / 60 % 60);
      this.second = Math.floor(this.remainTime % 60);
      this.countDowm();
    }
  },
  methods: {
    countDowm: function countDowm() {
      var self = this;
      clearInterval(this.timer);
      this.timer = setInterval(function () {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59;
            self.minute -= 1;
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0;
            self.$emit('countDowmEnd', true);
            clearInterval(self.timer);
          } else {
            self.second -= 1;
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59;
            self.minute -= 1;
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1;
            self.minute = 59;
            self.second = 59;
          } else {
            self.second -= 1;
          }
        }
      }, 1000);
    },
    formatNum: function formatNum(num) {
      return num < 10 ? '0' + num : '' + num;
    }
  },
  computed: {
    hourString: function hourString() {
      return this.formatNum(this.hour);
    },
    minuteString: function minuteString() {
      return this.formatNum(this.minute);
    },
    secondString: function secondString() {
      return this.formatNum(this.second);
    }
  }
};
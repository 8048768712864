var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: {
        visible: _vm.isShow,
        width: "468px",
        "append-to-body": true,
        "before-close": _vm.closeModal,
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("\n    福利申请")]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v("福利申请请联系师资管理人员"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "img-box" },
        _vm._l(_vm.imgList, function (item, index) {
          return _c("div", [
            _c("img", { attrs: { src: item.url, alt: "" } }),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(item.desc))]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
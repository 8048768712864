"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("../css/conmmon.scss");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['orderMagData'],
  data: function data() {
    return {
      tipStatus: true,
      showVideoimg: {
        0: require("@/assets/images/seller/video-dzfd.png"),
        //定制辅导
        1: require("@/assets/images/seller/video-kq.png"),
        //考前突击
        4: require("@/assets/images/seller/video-lw.png"),
        //论文大礼包
        '65-1': require("@/assets/images/seller/video-lw1.png"),
        //论文大礼包
        '65-2': require("@/assets/images/seller/video-lw2.png"),
        //论文大礼包
        '65-3': require("@/assets/images/seller/video-lw3.png"),
        //论文大礼包
        32: require("@/assets/images/seller/video-ts.png"),
        //特殊
        66: require("@/assets/images/seller/video-ts.png"),
        //特殊
        69: require("@/assets/images/seller/video-ts.png") //特殊
      },
      textType: {
        0: "定制辅导",
        1: "考前突击",
        4: "论文大礼包",
        65: "论文大礼包",
        32: "特殊订单",
        66: "特殊订单",
        69: "特殊订单"
      }
    };
  },
  methods: {
    playFun: function playFun(url, type) {
      if (type == 'video') url = "".concat(url, "?response-content-type=video/mp4");
      window.open(url, "_blank");
    }
  }
};
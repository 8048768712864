var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-item" },
    [
      _c("div", { staticClass: "order" }, [
        _vm._v("订  单：" + _vm._s(_vm.order.orderNo)),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "已超时：" + _vm._s(_vm.formatMinAndHour(_vm.order.overtimeMin)) + " "
        ),
        _c("span", [
          _vm._v(
            "（共" +
              _vm._s(_vm.formatMinAndHour(_vm.order.courseClassHours)) +
              "）"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", [_vm._v("• 若订单需要增加课时")]),
        _vm._v(" "),
        _c("div", [
          _vm._v("• 若当前课堂为最后一节课 "),
          _vm.order.unCount
            ? _c("span", [
                _vm._v("(请先前往"),
                _c(
                  "span",
                  {
                    staticClass: "schedule-btn",
                    on: {
                      click: function ($event) {
                        return _vm.goToBeiKe(_vm.order)
                      },
                    },
                  },
                  [_vm._v("备课中心")]
                ),
                _vm._v("完成任务)"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("• 若不确定下一步操作或已经申请过额外课时")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.showModal("applyTime")
                  },
                },
              },
              [_vm._v("申请额外课时")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.showModal("applyEndClass")
                  },
                },
              },
              [_vm._v("申请结课")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: { loading: _vm.loading },
                on: { click: _vm.waitDealOrder },
              },
              [_vm._v("\n        待定\n      ")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("applyEndClass", { ref: "applyEndClass" }),
      _vm._v(" "),
      _c("applyTime", { ref: "applyTime" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-box" },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "type-title" }, [_vm._v("主订单")]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.orderInfo.parentName))]),
      ]),
      _vm._v(" "),
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "子订单", name: "1" } },
            [
              _vm._l(_vm.orderInfo.orderList || [], function (item, index) {
                return [
                  item.courseId != _vm.$route.query.id
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "link",
                          on: {
                            click: function ($event) {
                              return _vm.goToOrder(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.orderNo))]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
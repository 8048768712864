"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("../css/conmmon.scss");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['orderMagData'],
  data: function data() {
    return {
      specialChildType: '',
      typaArr: [{
        id: "1",
        name: "资料整理"
      }, {
        id: "2",
        name: "Appeal Letter"
      }, {
        id: "4",
        name: "作业订单"
      }, {
        id: "8",
        name: "论文批改"
      }, {
        id: "16",
        name: "Proofreading"
      }, {
        id: "32",
        name: "Past-paper"
      }, {
        id: "64",
        name: "论文修改"
      }, {
        id: "128",
        name: "考试订单"
      }],
      typeObj: {
        1: '（%）（客户满意度）',
        2: '（Pass奖金G）',
        4: '（%）',
        8: '（%）（客户满意度）',
        16: '（%）（客户满意度）',
        32: '（%）（客户满意度）',
        64: '（%）',
        128: '（%）'
      }
    };
  },
  components: {},
  computed: {
    // 考试订单的备考审核表状态提示
    showTip: function showTip() {
      var obj = this.orderMagData.courseSubject || {};
      var check = this.orderMagData.teacQualityPrepareExam;
      if (check && check.status == 2 && !obj.isClassEnd) return 1; // 审核表审核通过且没有结课
      else if (check && check.status != 2 && obj.isClassEnd) return 2; // 审核表审核不通过且申请结课
      else return false;
    },
    //是否是是自己负责的单子
    isMy: function isMy() {
      return this.orderMagData.isMy;
    }
  },
  created: function created() {
    if (this.orderMagData.spoType == 66) {
      this.specialChildType = this.orderMagData.studPurchaseOrder.studSpecialOrderExt.type;
    }
  },
  methods: {}
};
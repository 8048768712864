var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp " }, [
    _c("h5", [_vm._v("订单信息")]),
    _vm._v(" "),
    _c("div", { staticClass: "detial-msg-box detial-msg-height" }, [
      _vm.childtype
        ? _c("p", [
            _c("label", [_vm._v("订单子类型")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.specilTypeArr[_vm.childtype]) +
                "\n         "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("课程代码")]),
        _vm._v(
          "\n            " +
            _vm._s(_vm.orderMagData.course_code) +
            "\n         "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("Deadline")]),
        _vm._v(
          "\n            " +
            _vm._s(_vm.orderMagData.deadline) +
            "\n            "
        ),
        _c("span", { staticClass: "tips" }, [
          _vm._v("请讲师严格执行此时间,否则会有额外薪资处罚"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
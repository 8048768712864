"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Modal = require("@/views/dissertation/components/Modal");
var _moment = _interopRequireDefault(require("moment"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    GradeModal: _Modal.GradeModal
  },
  filters: {
    fmtDate: function fmtDate(val) {
      if (val) return (0, _moment.default)(val).format("YYYY-MM-DD");
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["dissertationTitleData", "progressOver"])),
  methods: {
    openModal: function openModal() {
      this.$refs.gradeModal.show();
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lottieWeb = _interopRequireDefault(require("lottie-web"));
var _success = _interopRequireDefault(require("./success.json"));
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      btnShow: false,
      animation: {}
    };
  },
  methods: {
    show: function show() {
      var _this = this;
      this.isShow = true;
      setTimeout(function () {
        _this.animation.play();
        _this.btnShow = true;
      }, 500);
    },
    setAnimation: function setAnimation() {
      var icon = document.getElementById("success");
      this.animation = _lottieWeb.default.loadAnimation({
        container: icon,
        // 包含动画的dom元素
        renderer: "svg",
        // 渲染出来的是什么格式
        loop: false,
        // 循环播放
        autoplay: false,
        // 自动播放
        animationData: _success.default // 动画json的路径
      });
      this.animation.addEventListener("data_ready", function () {
        console.log("animation data has loaded");
      });
    },
    nextStep: function nextStep() {
      this.$emit("submit");
    }
  },
  mounted: function mounted() {
    this.setAnimation();
  }
};
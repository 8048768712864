var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [
      _vm._v("订单信息 "),
      _vm._v(" "),
      _vm.orderMagData.spoType == 65 &&
      _vm.orderMagData.courseVersion == 2 &&
      false
        ? _c(
            "span",
            { staticClass: "paper-btn", on: { click: _vm.gotoPaper } },
            [_vm._v("论文辅导规划表")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detial-msg-box detial-msg-height" },
      [
        _vm.childtype
          ? _c("p", [
              _c("label", [_vm._v("订单子类型")]),
              _vm._v(
                "\n      " + _vm._s(_vm.specilTypeArr[_vm.childtype]) + "\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _c("label", [_vm._v("课程代码")]),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.orderMagData.studPurchaseOrder &&
                  _vm.orderMagData.studPurchaseOrder.courseCode
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("label", [_vm._v("订单状态")]),
          _vm._v(
            "\n      " +
              _vm._s(_vm.OrderStatus[_vm.orderMagData.statused]) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("label", [_vm._v("Deal Deadline")]),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.orderMagData.studPurchaseOrder &&
                  _vm.orderMagData.studPurchaseOrder.endCourseTime
              ) +
              "\n      "
          ),
          _vm.orderMagData.studPurchaseOrder &&
          _vm.orderMagData.studPurchaseOrder.endCourseTime
            ? _c("span", { staticClass: "tips" }, [
                _vm._v("请讲师严格执行此时间,否则会有额外薪资处罚"),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("label", [_vm._v("销售名称")]),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.orderMagData.sysSeller &&
                  _vm.orderMagData.sysSeller.username
                  ? _vm.orderMagData.sysSeller.username
                  : ""
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("label", [_vm._v("师资名称")]),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.orderMagData.sysUserEntity &&
                  _vm.orderMagData.sysUserEntity.username
                  ? _vm.orderMagData.sysUserEntity.username
                  : ""
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("label", [_vm._v("辅导讲师")]),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.isMy
                  ? _vm.orderMagData.teacUser &&
                      _vm.orderMagData.teacUser.username
                  : "--"
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _vm.orderMagData.spoType != 66
          ? [
              _c("p", [
                _c("label", [_vm._v("学生已上课时")]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.orderMagData.allSchooltime) +
                    "MIN\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("系统赠送时长")]),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.orderMagData.allPresenterTime) +
                    "MIN\n      "
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        (_vm.orderMagData.spoType == 66 &&
          [4, 8, 16, 64, 129, 130].includes(_vm.specialChildType)) ||
        [65, 67, 71].includes(Number(_vm.orderMagData.spoType))
          ? _c("p", [
              _c("label", [
                _vm._v(
                  _vm._s(
                    [65, 67, 71].includes(Number(_vm.orderMagData.spoType))
                      ? "工作量"
                      : "字数"
                  )
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.orderMagData.studPurchaseOrder.wordsNum
                        ? _vm.orderMagData.studPurchaseOrder.wordsNum
                        : ""
                    ) +
                    "字\n       "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        (_vm.specialChildType > 2 && _vm.specialChildType != 32) ||
        [128, 129, 130].includes(_vm.specialChildType)
          ? _c("p", [
              _c("label", [
                _vm._v(
                  _vm._s(
                    [128, 129, 130].includes(_vm.specialChildType)
                      ? "工作量"
                      : "Workload"
                  )
                ),
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.orderMagData.studPurchaseOrder.studSpecialOrderExt
                        .workload
                    ) +
                    "\n        字\n      "
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    rewardList: {
      type: Array,
      default: function _default() {
        return [{
          bonus: 888,
          upNum: 100
        }, {
          bonus: 888,
          upNum: 0
        }, {
          bonus: 0,
          upNum: 100
        }];
      }
    }
  },
  data: function data() {
    return {};
  }
};
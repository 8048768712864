"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = exports.StuClassStatus2Val = exports.OwnClassStatus2Val = exports.OwnClassStatus2Color = exports.OwnClassStatus2BGColor = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _VEnum = require("@/utils/VEnum");
var STUDENT = _VEnum.CalendarModelType.STUDENT,
  TEACHER = _VEnum.CalendarModelType.TEACHER;
var Title = exports.Title = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, STUDENT, "“我是学生” — 模拟课听课预约"), TEACHER, "讲师预约模拟课");
var UNAUDITED = _VEnum.OwnClassStatus.UNAUDITED,
  END = _VEnum.OwnClassStatus.END,
  REPORT_DONE = _VEnum.OwnClassStatus.REPORT_DONE,
  COMPLETE = _VEnum.OwnClassStatus.COMPLETE,
  ONGOING = _VEnum.OwnClassStatus.ONGOING,
  AUDIT_FAILED = _VEnum.OwnClassStatus.AUDIT_FAILED,
  IN_CLASS = _VEnum.OwnClassStatus.IN_CLASS;

/**
 * 我的全真模拟课 statused 状态 对应颜色
 */
var OwnClassStatus2Color = exports.OwnClassStatus2Color = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, UNAUDITED, "#60B1FF"), END, "#b1b4b9"), COMPLETE, "#b1b4b9"), ONGOING, "#FFA75E"), AUDIT_FAILED, "#60B1FF"), IN_CLASS, "#39CDA0");

/**
 * 我的全真模拟课 statused 状态 对应背景颜色
 */
var OwnClassStatus2BGColor = exports.OwnClassStatus2BGColor = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, UNAUDITED, "#EFF7FF"), END, "#d1d3d8"), COMPLETE, "#d1d3d8"), ONGOING, "#FFF6EE"), AUDIT_FAILED, "#EFF7FF"), IN_CLASS, "#EBF6EE"), REPORT_DONE, "#F56C6C");

/**
 * 我的课堂 按钮状态
 */
var OwnClassStatus2Val = exports.OwnClassStatus2Val = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, UNAUDITED, "未审核"), END, "已结束"), COMPLETE, "已完成"), ONGOING, "进行中"), AUDIT_FAILED, "审核未通过"), IN_CLASS, "上课中");

/**
 * 学生身份 老师查看课堂列表 按钮状态
 */
var StuClassStatus2Val = exports.StuClassStatus2Val = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, _VEnum.StudentMakeClassStatus.WAIT, "待上课"), _VEnum.StudentMakeClassStatus.COMPLETE, "已完成"), _VEnum.StudentMakeClassStatus.IN_CLASS, "上课中"), _VEnum.StudentMakeClassStatus.IS_REPORT, "填写reaction");
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkRealSimulation = void 0;
var _request = require("./request");
// 万能班长讲师全真模拟课 fakeclass

/**
 * 判断老师或学生身份 是否完成
 * @param {String} userId
 */
var checkRealSimulation = exports.checkRealSimulation = function checkRealSimulation(userId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/checkRealSimulation/").concat(userId),
    method: "get"
  });
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _VEnum = require("@/utils/VEnum");
// import Paperchedule from "@/views/paperchedule";
/* 论文大礼包 */
var _default = exports.default = [{
  path: _VEnum.paperRouterPath.HOME,
  // component: Paperchedule
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/paperchedule'));
    });
  }
}];
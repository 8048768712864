"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isShow: false
    };
  },
  methods: {
    show: function show(row) {
      this.isShow = true;
    },
    closeModal: function closeModal() {
      this.isShow = false;
    },
    receivOrder: function receivOrder() {
      this.$emit("receivOrder");
    }
  }
};
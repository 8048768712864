var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm._v(
      _vm._s(
        _vm.hour
          ? _vm.hourString + ":" + _vm.minuteString + ":" + _vm.secondString
          : _vm.minuteString + ":" + _vm.secondString
      )
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
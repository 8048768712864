"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _plupload = _interopRequireDefault(require("plupload"));
//
//
//
var _default = exports.default = {
  props: {
    commid: {
      type: String,
      default: ""
    },
    curIndex: {
      type: Number,
      default: 0
    },
    childIndex: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: "选择文件"
    }
  },
  data: function data() {
    return {
      courseId: this.$route.query.courseId,
      uploader: null,
      uploadParams: {},
      timeStamp: "",
      uploadFilesLength: [],
      uploadFileName: "",
      beforeId: "",
      uploadFilesArray: [],
      isCrm: false,
      isNewCrm: false
    };
  },
  created: function created() {
    this.isCrm = localStorage.getItem("CrmLinkDiss");
    this.isNewCrm = localStorage.getItem('NewLinkDiss');
  },
  mounted: function mounted() {
    this.initupload();
  },
  methods: {
    //时间戳
    createTimeStamp: function createTimeStamp() {
      this.timeStamp = parseInt(new Date().getTime() / 1000) + "";
    },
    // 上传流程
    initupload: function initupload() {
      this.uploader = null;
      this.createdUploadsign();
      this.createUploader();
    },
    createdUploadsign: function createdUploadsign() {
      //类型判断URL
      this.createTimeStamp();
      var url = this.isCrm === 'true' ? "".concat(this.$api.redirectURL, "api/oss/getAliOSSUploadSign?dir=courseware/").concat(this.timeStamp, "/") : this.isNewCrm === 'true' ? "".concat(this.$api.newCrmUrl, "db/sys/oss/getAliOSSUploadSign?dir=courseware/").concat(this.timeStamp, "/") : "".concat(this.$api.baseURL, "/oss/getAliOSSUploadSign?dir=courseware/").concat(this.timeStamp, "/");
      var _this = this;
      this.$axios({
        url: url
      }).then(function (r) {
        if (r.data.status === 200) {
          var _r$data$body = r.data.body,
            accessid = _r$data$body.accessid,
            host = _r$data$body.host,
            policy = _r$data$body.policy,
            signature = _r$data$body.signature,
            dir = _r$data$body.dir,
            g_dirname = dir;
          _this.uploadParams.accessid = accessid;
          _this.uploadParams.host = host;
          _this.uploadParams.policyBase64 = policy;
          _this.uploadParams.signature = signature;
          _this.uploadParams.g_dirname = dir;
        }
      }).catch(function (er) {});
    },
    formatFileName: function formatFileName(up, filename) {
      this.uploadFileName = encodeURIComponent(filename);
      this.set_upload_param(up, filename, false);
    },
    set_upload_param: function set_upload_param(up, filename, ret) {
      if (ret) {
        this.formatFileName(up, filename);
      }
      var new_multipart_params = {
        key: this.uploadParams.g_dirname + filename,
        policy: this.uploadParams.policyBase64,
        OSSAccessKeyId: this.uploadParams.accessid,
        success_action_status: "200",
        // 让服务端返回200,不然，默认会返回204
        signature: this.uploadParams.signature
      };
      up.setOption({
        url: this.uploadParams.host,
        multipart_params: new_multipart_params
      });
      // 所有uploadstart
      up.start();
    },
    createUploader: function createUploader() {
      this.fileload();
    },
    fileload: function fileload() {
      var _this = this;
      this.uploader = new _plupload.default.Uploader({
        runtimes: "html5,flash,silverlight,html4",
        browse_button: this.commid,
        flash_swf_url: "js/Moxie.swf",
        silverlight_xap_url: "js/Moxie.xap",
        url: "http://oss.aliyuncs.com",
        multi_selection: false,
        init: {
          PostInit: function PostInit() {},
          FilesAdded: function FilesAdded(up, files) {
            _this.$emit("getFiles", _this.curIndex, files[0], _this.childIndex); //待上传的文件
          },
          BeforeUpload: function BeforeUpload(up, file) {
            _this.set_upload_param(up, file.name, true);
          },
          UploadProgress: function UploadProgress(up, file) {
            //会在文件上传过程中不断触发，可以用此事件来显示上传进度
          },
          FileUploaded: function FileUploaded(up, file, info) {
            //当队列中的某一个文件上传完成后触发
            if (info.status == 200) {
              var obj = {
                cdId: _this.courseId,
                groupId: 0,
                name: file.name,
                precent: file.percent,
                size: file.size,
                url: _this.uploadParams.host + "/" + _this.uploadParams.g_dirname + _this.uploadFileName
              };
              _this.uploadFilesArray.push(obj); //已经上传的文件
            } else {
              _this.uploader.destroy();
              _this.$notify.error(info.response);
            }
          },
          UploadComplete: function UploadComplete(up, fileList) {
            _this.$emit("uploadAllFile", _this.curIndex, _this.uploadFilesArray, _this.childIndex);
          },
          Error: function Error(up, err) {
            console.log(up, err, '文件上传失败');
            _this.uploader.destroy();
            _this.$notify({
              title: "提示",
              type: "error",
              message: "文件上传失败， 请刷新重试"
            });
          }
        }
      });
      this.uploader.init();
    },
    startUpload: function startUpload() {
      this.uploader.start();
    },
    // 删除待上传文件
    deleWaitFile: function deleWaitFile(file, index) {
      this.uploader.removeFile(file);
    },
    //清空上传数组
    clearUploadFilesArray: function clearUploadFilesArray() {
      this.uploadFilesArray = [];
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp " }, [
    _c(
      "h5",
      { staticClass: "additional-style" },
      [
        _vm._v("Message  Info "),
        _c("additional", {
          attrs: { additional: _vm.orderMagData.additionals },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detial-msg-box detial-msg-height" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("p", [
          _c("span", {
            staticClass: "font-styless",
            domProps: {
              textContent: _vm._s(
                _vm.orderMagData.studPurchaseOrder &&
                  _vm.orderMagData.studPurchaseOrder.description
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _vm.copywritingCourse &&
        [8, 9].includes(Number(_vm.copywritingCourse.type))
          ? _c("p", [
              _c("label", [_vm._v("Original Work")]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "file-box" },
                _vm._l(_vm.originalWork, function (files, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass: "file-wrap",
                      on: {
                        click: function ($event) {
                          return _vm.openUrl(files.url)
                        },
                      },
                    },
                    [
                      _vm._v("\n                  " + _vm._s(files.name)),
                      _c("br"),
                      _vm._v(" "),
                      _c("i", [_vm._v(_vm._s(files.date))]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.orderMagData.afterSaleRemark
          ? _vm._l(
              JSON.parse(_vm.orderMagData.afterSaleRemark),
              function (item, index) {
                return _c("p", { key: index }, [
                  _c("label", [_vm._v(_vm._s(item.question))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-styless" }, [
                    _vm._v(_vm._s(item.answer)),
                  ]),
                ])
              }
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("label", [_vm._v("Client  Message")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
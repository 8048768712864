var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "medal-list" },
    [
      _vm.medalsList && _vm.medalsList.length
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                trigger: "click",
                "popper-class": "popperOptions",
              },
              on: { show: _vm.show },
            },
            [
              _c(
                "div",
                {
                  ref: "medalbox",
                  staticClass: "medal",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/home/wujiaoxing.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "medal-count" }, [
                    _vm._v(_vm._s(_vm.medalCount || 0) + "枚勋章"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "small-medalbox" },
                    [
                      _vm._l(_vm.medalsList, function (item, index) {
                        return [
                          index < _vm.showNum
                            ? _c("img", {
                                key: index,
                                attrs: { src: item.medalImage, alt: "" },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-caret-right" }),
                ]
              ),
              _vm._v(" "),
              _c("medalModal", {
                ref: "medalModal",
                attrs: { info: _vm.info },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pgwrap-box" }, [
    _c(
      "div",
      { staticClass: "persondetail-wrap" },
      [
        _c(
          "div",
          { staticClass: "persondetail-top" },
          [
            _c(
              "div",
              [
                _c("teacherInfo", {
                  ref: "teacherInfo",
                  attrs: {
                    teacinfo: _vm.teacinfo,
                    teacherType: _vm.teacherType,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "24px", display: "flex" } },
                  [
                    _c("systemNotice", {
                      attrs: {
                        notReadNum: _vm.notReadNum,
                        messageData: _vm.messageData,
                      },
                    }),
                    _vm._v(" "),
                    _c("reflectionNotice", { ref: "reflectionNotice" }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("waitDeal", { staticClass: "income" }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "persondetail-bottom" },
          [
            _c("bottomList", {
              attrs: {
                highListData: _vm.highListData,
                mostLoveData: _vm.mostLoveData,
                texts: _vm.texts,
                incomeData: _vm.incomeData,
                pieMonth: Number(_vm.pieMonth),
                nowMonth: Number(_vm.nowMonth),
                pieDataArr: _vm.pieDataArr,
                redbagObj: _vm.redbagObj,
                activeData: _vm.activeData,
                teacherType: _vm.teacherType,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.updateData.show
          ? _c("div", { staticClass: "new-mask" }, [
              _c("div", { staticClass: "mask-content mask-width560" }, [
                _vm.updateData.title
                  ? _c("h3", [_vm._v(_vm._s(_vm.updateData.title))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.updateData.show == 9
                  ? _c(
                      "div",
                      { staticClass: "newversion-box" },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/newversion.png"),
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.updateData.newversionText.datas,
                          function (text) {
                            return _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(text) +
                                  "\n            "
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.updateData.btntext
                  ? _c(
                      "button",
                      {
                        staticClass: "defaultlight-btn",
                        on: { click: _vm.judgehasModel },
                      },
                      [_vm._v(_vm._s(_vm.updateData.btntext))]
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("redBag", { ref: "redBag" }),
        _vm._v(" "),
        _c("tipsmodel", {
          attrs: { datas: _vm.modelObj },
          on: { yesfuns: _vm.yesfuns },
        }),
        _vm._v(" "),
        _c("month-sum", {
          attrs: { isShow: _vm.showMonth, showM: _vm.showM },
          on: { closeMonth: _vm.closeMonth },
        }),
        _vm._v(" "),
        _c("fullTeacherLevel", {
          attrs: {
            isShow: _vm.showLevel,
            teacinfo: _vm.teacinfo,
            thumbsNext: Number(_vm.thumbsNext),
            levelsArr: _vm.levelsArr,
          },
          on: { closeLevel: _vm.closeLevel },
        }),
        _vm._v(" "),
        _c("partTeacherLevel", {
          ref: "partTeacherLevel",
          attrs: {
            showZanNum: _vm.showZanNum,
            thumbsNext: Number(_vm.thumbsNext),
            showZan: _vm.showZan,
            teacinfo: _vm.teacinfo,
            levelsArr: _vm.levelsArr,
          },
          on: { closeLevel: _vm.closeLevel },
        }),
        _vm._v(" "),
        _c("addTeacherWx", {
          attrs: { isShow: _vm.showAddWx },
          on: { closeAdd: _vm.closeAdd },
        }),
        _vm._v(" "),
        _c("salarySystem", {
          attrs: { isShow: _vm.showSystem },
          on: { closeSystem: _vm.closeSystem },
        }),
        _vm._v(" "),
        _c("welfareNotice", {
          attrs: { isShow: _vm.showNotice },
          on: { closeNotice: _vm.closeNotice, showModel: _vm.showModel },
        }),
        _vm._v(" "),
        _c("upLevel", {
          attrs: { isShow: _vm.showUp, upDetail: _vm.upDetail },
          on: {
            "update:showUp": function ($event) {
              _vm.showUp = false
            },
          },
        }),
        _vm._v(" "),
        _c("useQyWeixin", {
          attrs: { isShow: _vm.showUseQyWeixin },
          on: { close: _vm.closeUseQyweixin },
        }),
        _vm._v(" "),
        _c("settingTimeTip", {
          attrs: { isShow: _vm.isShowTip },
          on: {
            "update:isShowTip": function ($event) {
              _vm.isShowTip = false
            },
          },
        }),
        _vm._v(" "),
        _c("awardList", {
          attrs: {
            totals: _vm.totals,
            tableDataEnd: _vm.tableDataEnd,
            model: _vm.model,
            likeLoading: _vm.likeLoading,
            thumpsText: _vm.thumpsText,
            page: _vm.vals,
          },
        }),
        _vm._v(" "),
        _c("systemNoticeList", {
          attrs: {
            allNoticeData: _vm.allNoticeData,
            model: _vm.model,
            likeLoading: _vm.likeLoading,
            page: _vm.vals,
          },
        }),
        _vm._v(" "),
        _c("noticeVideo", { ref: "showSalary" }),
        _vm._v(" "),
        _c("chatNotify", { ref: "chatNotify", attrs: { needTime: true } }),
        _vm._v(" "),
        _c("examNotice", { ref: "examNotice", attrs: { needTime: true } }),
        _vm._v(" "),
        _c("welecomeNew", {
          ref: "welecomeNew",
          on: { refresh: _vm.getPersondetail },
        }),
        _vm._v(" "),
        _c("reflectionList", { ref: "reflectionList" }),
        _vm._v(" "),
        [1, 2].includes(Number(_vm.showStep))
          ? _c("follow", {
              attrs: { teacinfo: _vm.teacinfo, teacherType: _vm.teacherType },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("classinNotice", { ref: "classinNotice" }),
        _vm._v(" "),
        _c("preExamNotice", {
          ref: "preExamNotice",
          attrs: { needTime: true },
        }),
        _vm._v(" "),
        _c("medalOnlineMedal", { ref: "medalOnlineMedal" }),
        _vm._v(" "),
        _c("platFormNotice", { ref: "platFormNotice" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-container",
      style: _vm.staticPath.BG,
    },
    [
      _c("countdown"),
      _vm._v(" "),
      _c("div", { staticClass: "schedulecard-container" }, [
        _c("section", { staticClass: "title-container" }, [
          _c("div", { staticClass: "title-box" }, [
            _c("div", { staticClass: "back", on: { click: _vm.openBack } }, [
              _c("i", { staticClass: "el-icon-arrow-left" }),
              _vm._v(" "),
              _c("span", [_vm._v("返回上一页")]),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "calendar-app-container" },
          [
            _c("ds-calendar-app", {
              ref: "calendarApp",
              staticClass: "calendar-app",
              attrs: {
                modelType: 0,
                hasModel: false,
                hasValidRange: true,
                value: _vm.defaultEvents,
                dayValue: _vm.dayValue,
                dayData: _vm.dayData,
              },
              on: { picked: _vm.onPicked },
              scopedSlots: _vm._u([
                {
                  key: "classContent",
                  fn: function (ref) {
                    var dayValue = ref.dayValue
                    return [
                      dayValue.length > 0
                        ? _c(
                            "section",
                            { staticClass: "student-container" },
                            [
                              _c("div", { staticClass: "student-header" }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.currentYMD) +
                                      " 周" +
                                      _vm._s(_vm.week)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "共" + _vm._s(dayValue.length) + "节课"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-timeline",
                                _vm._l(dayValue, function (item, i) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: i,
                                      attrs: {
                                        color: "#FF8723",
                                        timestamp: item.dateRange,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "student-panel-container",
                                        },
                                        [
                                          _c("div", { staticClass: "box" }, [
                                            _c("div", { staticClass: "left" }, [
                                              _c(
                                                "span",
                                                { staticClass: "topic" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item
                                                        .extTeacSimulationClassroom
                                                        .courseName
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "reaction" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      (item.studentList
                                                        .length &&
                                                        item.studentList.join(
                                                          ","
                                                        )) ||
                                                        ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "right" },
                                              [
                                                item.currTeacSimulation
                                                  ? [
                                                      [1, 2].includes(
                                                        item.currTeacSimulation
                                                          .statused
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "btn",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openLink(
                                                                      item
                                                                        .currTeacSimulation
                                                                        .roomJoinUrl
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    进入课堂\n                                                "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "btn",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.reservationClass(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    约课\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("预约讲师模拟课听课")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "summary-container",
    },
    [
      _c(
        "div",
        { staticClass: "inp-box" },
        [
          _c("el-input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isEdit,
                expression: "isEdit",
              },
            ],
            ref: "input",
            attrs: { clearable: "", maxlength: 255, size: "mini" },
            model: {
              value: _vm.form.feedbackValue,
              callback: function ($$v) {
                _vm.$set(_vm.form, "feedbackValue", $$v)
              },
              expression: "form.feedbackValue",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isEdit,
                  expression: "!isEdit",
                },
              ],
            },
            [
              _vm.overflow
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.data.summary, placement: "top" } },
                    [
                      _c("span", { staticClass: "span-limit" }, [
                        _vm._v(_vm._s(_vm.data.summary)),
                      ]),
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(_vm.data.summary))]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "func-box" },
        [
          _vm.isEdit
            ? _c(
                "div",
                { staticClass: "btn-plain", on: { click: _vm.submit } },
                [_vm._v("提交")]
              )
            : _c("div", [
                _vm.readonly
                  ? _c("div", [
                      _vm.data.summary
                        ? _c("div", { staticClass: "btn-plain readonly" }, [
                            _vm._v("修改"),
                          ])
                        : _c("div", { staticClass: "btn-plain readonly" }, [
                            _vm._v("反馈"),
                          ]),
                    ])
                  : _c("div", [
                      _vm.data.summary
                        ? _c(
                            "div",
                            {
                              staticClass: "btn-plain",
                              on: { click: _vm.edit },
                            },
                            [_vm._v("修改")]
                          )
                        : _c("div", [
                            _vm.data.statused === _vm.StageStatus.TODO
                              ? _c(
                                  "div",
                                  { staticClass: "btn-plain disabled" },
                                  [_vm._v("反馈")]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "btn-plain",
                                    on: { click: _vm.edit },
                                  },
                                  [_vm._v("反馈")]
                                ),
                          ]),
                    ]),
              ]),
          _vm._v(" "),
          _c(
            "el-tooltip",
            { attrs: { content: "拖拽调整顺序", placement: "top" } },
            [
              _c("svg-icon", {
                class: {
                  disabled: _vm.data.statused !== _vm.StageStatus.TODO,
                  readonly: _vm.readonly,
                },
                attrs: { "class-name": "drag-icon", "icon-class": "drag" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _conf = _interopRequireDefault(require("./conf"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      alertModal: false,
      alertData: {}
    };
  },
  methods: {
    show: function show(type) {
      this.alertData = _conf.default[+type];
      this.alertModal = true;
    },
    handleClose: function handleClose() {
      this.$emit("close");
      this.alertModal = false;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("讲师收入")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detial-msg-box detial-msg-height" },
      [
        [0, 1, 65, 66, 69, 71].includes(_vm.orderMagData.spoType)
          ? _c(
              "p",
              { style: { "margin-bottom": _vm.showTip ? "10px" : "" } },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.orderMagData.teacUser &&
                        _vm.orderMagData.teacUser.type == 1
                        ? "讲师已获(G+OTG)"
                        : "讲师已获(G)"
                    )
                  ),
                ]),
                _vm._v(" "),
                _vm.isMy
                  ? [
                      _vm.orderMagData.teacUser.type == 1
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  Number(_vm.orderMagData.teacG) +
                                    Number(_vm.orderMagData.teacPreG) +
                                    "G"
                                ) +
                                " + " +
                                _vm._s(
                                  Number(_vm.orderMagData.teacOtg) +
                                    Number(_vm.orderMagData.teacPreOtg) +
                                    "OTG"
                                ) +
                                "\n          "
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                Number(_vm.orderMagData.teacG) +
                                  Number(_vm.orderMagData.teacPreG)
                              ) + " "
                            ),
                          ]),
                      _vm._v(" "),
                      _vm.orderMagData.lockTeacG
                        ? _c("span", [
                            _vm._v("【\n            "),
                            _c("img", {
                              staticClass: "lock",
                              attrs: {
                                src: require("@/assets/images/order/lock.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.orderMagData.lockTeacG) +
                                "G】\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : [_vm._v("--")],
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isMy
          ? [
              _vm.showTip == 2
                ? _c("div", { staticClass: "tip" }, [
                    _c("span", [
                      _vm._v(
                        "*由于该订单的备考审核表未通过，仅获得预计收入的80%"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showTip == 1
                ? _c("div", { staticClass: "tip" }, [
                    _c("span", [
                      _vm._v(
                        "*由于该订单的备考审核表已通过，获得预计收入的20%，结课之后可以解锁"
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        ![4, 32, 66].includes(_vm.orderMagData.spoType)
          ? _c("p", [
              _c("label", [_vm._v("订单讲师时薪")]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.isMy ? _vm.orderMagData.hourlyWage + "G" : "--") +
                  "\n      "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _c("label", [_vm._v("\n          预计订单收入(G)\n        ")]),
          _vm._v(" "),
          _vm.orderMagData.spoType == 67 && _vm.orderMagData.borderType
            ? _c("span", [
                _vm._v(_vm._s(_vm.isMy ? "B端业务订单，运营人为报价" : "--")),
              ])
            : _c("span", [
                _vm._v(
                  _vm._s(_vm.isMy ? _vm.orderMagData.planIncome + "G" : "--")
                ),
              ]),
        ]),
        _vm._v(" "),
        [65, 71].includes(Number(_vm.orderMagData.spoType))
          ? [
              _c("p", [
                _c("label", [_vm._v("pass分数线")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.isMy ? _vm.orderMagData.passScore : "--")),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("保分")]),
                _vm._v(" "),
                _vm.isMy
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.orderMagData.guaranteeScore
                            ? "是 " + _vm.orderMagData.guaranteeScore
                            : "否"
                        ) + " "
                      ),
                    ])
                  : _c("span", [_vm._v("--")]),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        [69].includes(Number(_vm.orderMagData.spoType))
          ? _c(
              "p",
              [
                _c("label", [_vm._v(" High Score Guarantee")]),
                _vm._v(" "),
                _vm.isMy
                  ? [
                      _vm.orderMagData.guaranteeScore
                        ? _c("span", [
                            _vm._v(
                              "是" +
                                _vm._s(_vm.orderMagData.guaranteeScore) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v("否")]),
                    ]
                  : [_vm._v("\n          --\n        ")],
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        [66, 69].includes(Number(_vm.orderMagData.spoType))
          ? [
              (_vm.specialChildType &&
                [4, 128].includes(_vm.specialChildType)) ||
              [69].includes(Number(_vm.orderMagData.spoType))
                ? _c("p", [
                    _c("label", [_vm._v("订单pass分")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.isMy ? _vm.orderMagData.passScore : "--")
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.specialChildType &&
              [4, 64, 128].includes(_vm.specialChildType)
                ? _c("p", [
                    _c("label", [_vm._v("是否保分")]),
                    _vm._v(" "),
                    _vm.isMy
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.orderMagData.studPurchaseOrder
                                .studSpecialOrderExt.assuring
                                ? "是 " +
                                    _vm.orderMagData.studPurchaseOrder
                                      .studSpecialOrderExt.assuringScore +
                                    "分"
                                : "否"
                            ) + " "
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        [0, 1, 65, 66, 67, 69, 71].includes(_vm.orderMagData.spoType)
          ? [
              _vm.orderMagData.spoType != 69
                ? _c(
                    "p",
                    [
                      ![129, 130].includes(_vm.specialChildType)
                        ? _c("label", [
                            _vm._v(
                              "Penalty Rate" +
                                _vm._s(
                                  [1, 8, 16, 32].includes(_vm.specialChildType)
                                    ? "（%）（客户满意度）"
                                    : _vm.specialChildType == 2
                                    ? "（Pass奖金G）"
                                    : "（%）"
                                )
                            ),
                          ])
                        : _c("label", [
                            _vm._v("Penalty Rate（%）（客户满意度奖金）"),
                          ]),
                      _vm._v(" "),
                      _vm.isMy
                        ? [
                            _vm.specialChildType == 2
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.orderMagData.teacOrder &&
                                          _vm.orderMagData.teacOrder.passMoney
                                      ) +
                                      "G\n            "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderMagData.teacOrder &&
                                        _vm.orderMagData.teacOrder.penaltyRate
                                        ? _vm.orderMagData.teacOrder.penaltyRate
                                        : "---"
                                    ) + "%"
                                  ),
                                ]),
                          ]
                        : [_vm._v("\n            --\n          ")],
                    ],
                    2
                  )
                : _c("p", [
                    _c(
                      "label",
                      {
                        style: {
                          width: [4, 8].includes(
                            _vm.orderMagData.copywritingCourse.type
                          )
                            ? "220px"
                            : "",
                        },
                        attrs: { for: "" },
                      },
                      [
                        _vm._v(
                          "Penalty Rate" +
                            _vm._s(
                              [4, 8].includes(
                                _vm.orderMagData.copywritingCourse.type
                              )
                                ? "（%）（客户满意度奖金）"
                                : "（%）"
                            )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isMy
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.orderMagData.teacOrder &&
                                _vm.orderMagData.teacOrder.penaltyRate
                                ? _vm.orderMagData.teacOrder.penaltyRate
                                : "---"
                            ) + "%"
                          ),
                        ])
                      : _c("span", [_vm._v("--")]),
                  ]),
              _vm._v(" "),
              [65, 71].includes(Number(_vm.orderMagData.spoType)) ||
              ([1].includes(Number(_vm.orderMagData.spoType)) &&
                _vm.orderMagData.studPurchaseOrder.spreeType == 2)
                ? _c("p", [
                    _c("label", [_vm._v("辅导课时")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.isMy
                            ? _vm.orderMagData.classHourDescription
                            : "--"
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
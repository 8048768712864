"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      sampleList: [{
        name: 'sample1',
        url: 'http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/trainVideos/sample1.pdf'
      }, {
        name: 'sample2',
        url: 'http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/trainVideos/sample2.pdf'
      }]
    };
  },
  methods: {
    closeModal: function closeModal() {
      this.isShow = false;
    },
    seeFile: function seeFile(item) {
      item.name.indexOf('.txt') > -1 || item.name.indexOf('.pdf') > -1 || item.name.indexOf('.jpg') > -1 || item.name.indexOf('.png') > -1 || item.name.indexOf('.jpeg') > -1 || item.url.indexOf('.txt') > -1 || item.url.indexOf('.pdf') > -1 || item.url.indexOf('.jpeg') > -1 || item.url.indexOf('.jpg') > -1 || item.url.indexOf('.png') > -1 ? window.open(item.url) : window.open(item.url, '_self');
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _c(
      "div",
      { staticClass: "main-container" },
      [
        _c(
          "div",
          { ref: "main", staticClass: "mav-main" },
          [_c("navbar", { ref: "navbar" })],
          1
        ),
        _vm._v(" "),
        _c("app-main"),
        _vm._v(" "),
        _vm.showglobal
          ? _c("get-medal", {
              attrs: { isShow: _vm.showglobal, current: _vm.currentObj },
              on: { closeMedal: _vm.closeMedal },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("extraOrderList", { ref: "extraOrderList" }),
        _vm._v(" "),
        _c("platFormNotice", { ref: "platFormNotice" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
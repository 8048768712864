"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateCode = exports.queryUserDetails = exports.queryTeacherUrl = exports.queryLateClassroom = exports.queryCourseByRoomId = exports.queryClassRoom = exports.judgeLogin = exports.judgeComeIn = exports.getMobileCode = exports.getMKClassInLinkByCode = exports.getMKClassInLink = exports.getCode = exports.getClassInLink = exports.creatClass = exports.complateUpdate = exports.complateLogin = exports.complateClass = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
// 获取手机区号
var getMobileCode = exports.getMobileCode = function getMobileCode() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classin/queryCountry"),
    method: 'get'
  });
};
// 校验是否登录
var judgeLogin = exports.judgeLogin = function judgeLogin(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.newCrmUrl, "/classin-api/classin/v1/checkDevice"),
    method: 'get',
    params: params
  });
};
// 标记弹窗的完成
var complateLogin = exports.complateLogin = function complateLogin(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classin/complete"),
    method: 'post',
    data: data
  });
};
// 获取验证码
var getCode = exports.getCode = function getCode(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classin/sendMs"),
    method: 'get',
    params: params
  });
};
// 验证验证码
var validateCode = exports.validateCode = function validateCode(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classin/checkMs"),
    method: 'get',
    params: params
  });
};
// 获取课堂链接
var getClassInLink = exports.getClassInLink = function getClassInLink(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classin/queryTeacClassroomUrl"),
    method: 'get',
    params: params
  });
};
// 获取美刻课堂链接
var getMKClassInLink = exports.getMKClassInLink = function getMKClassInLink(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classRoom/queryTeacherUrl"),
    method: 'get',
    params: params
  });
};
// 获取美刻课堂链接
var getMKClassInLinkByCode = exports.getMKClassInLinkByCode = function getMKClassInLinkByCode(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teac/meike/getSysRoomInfo"),
    method: 'get',
    params: params
  });
};
// 创建美刻课堂
var creatClass = exports.creatClass = function creatClass(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teac/meike/createRoom"),
    method: 'post',
    data: data
  });
};
// 判断是否进入美刻课堂
var judgeComeIn = exports.judgeComeIn = function judgeComeIn(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teac/meike/checkTeac"),
    method: 'get',
    params: params
  });
};
// 查询美刻课堂状态
var queryClassRoom = exports.queryClassRoom = function queryClassRoom(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teac/meike/queryClassRoom"),
    method: 'get',
    params: params
  });
};
// 完成认证
var complateClass = exports.complateClass = function complateClass(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teac/meike/complete"),
    method: 'get',
    params: params
  });
};
// 查询复试课件
var queryUserDetails = exports.queryUserDetails = function queryUserDetails(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teac/meike/queryUserDetails"),
    method: 'get',
    params: params
  });
};
// 查询复试课件
var complateUpdate = exports.complateUpdate = function complateUpdate(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teac/meike/upgradeComplete"),
    method: 'get',
    params: params
  });
};
// 查询当前课堂是否是押题课得
var queryCourseByRoomId = exports.queryCourseByRoomId = function queryCourseByRoomId(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/queryCourseByRoomId"),
    method: 'get',
    params: params
  });
};

// 查询当前迟到课堂
var queryLateClassroom = exports.queryLateClassroom = function queryLateClassroom(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classRoom/queryLateClassroom"),
    method: 'get',
    params: params
  });
};
var queryTeacherUrl = exports.queryTeacherUrl = function queryTeacherUrl(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classRoom/queryTeacherUrl"),
    method: 'get',
    params: params
  });
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("订单需求")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detial-msg-box detial-msg-height" },
      [
        _vm.orderType == 0
          ? [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("订单类型\n            ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "border_box" },
                        [
                          [1, 2].includes(+_vm.studCourseCustomized.orderType)
                            ? [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.studCourseCustomized.orderType == 1
                                        ? "定制化需求"
                                        : "长期需求"
                                    ) +
                                    "\n                "
                                ),
                                _vm.studCourseCustomized.orderType == 2 &&
                                _vm.studCourseCustomized.coverTutoring == 1
                                  ? _c("span", { staticClass: "red-color" }, [
                                      _vm._v("（需要覆盖考核项辅导）"),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("学生对课程的了解程度（0-10）\n            ")]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.studCourseCustomized.homeworkUnderstanding
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("\n              授课范围\n            ")]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "From 【week " +
                            _vm._s(_vm.studCourseCustomized.teachWeekFrom) +
                            "】 To 【week " +
                            _vm._s(_vm.studCourseCustomized.teachWeekTo) +
                            "】"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("\n              学生当前进度\n            ")]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "学生当前课程进度 【week " +
                            _vm._s(_vm.studCourseCustomized.currentCourseWeek) +
                            "】"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("\n              学生可排课时间\n            ")]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          _vm._s(_vm.studCourseCustomized.schedulableTime)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [
                          _vm._v(
                            "\n              学生希望的排课频次\n            "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          _vm._s(_vm.studCourseCustomized.schedulingFrequency)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "p",
                  { staticClass: "f12 lable-text flex-center-spacebetween" },
                  [_vm._v("学生的第一堂课需求是\n          ")]
                ),
                _c("div", { staticClass: "border_box" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.studCourseCustomized.firstLessonNeeds) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "p",
                  { staticClass: "f12 lable-text flex-center-spacebetween" },
                  [_vm._v("学生的订单需求是\n          ")]
                ),
                _c("div", { staticClass: "border_box" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.studCourseCustomized.orderRequirements) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p"),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.orderType == 1
          ? [
              _c("div", [
                _c(
                  "p",
                  { staticClass: "f12 lable-text flex-center-spacebetween" },
                  [_vm._v("学生的订单需求是\n          ")]
                ),
                _c("div", { staticClass: "border_box" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.studCourseCustomized.orderRequirements) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("p"),
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("学生希望的辅导方式\n            ")]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.fdxq[_vm.studCourseCustomized.tutoringMethod]
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("学生能力基础\n            ")]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.xsnl[
                                _vm.studCourseCustomized.basicCapabilities
                              ]
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [
                          _vm._v(
                            "学生所处的考试阶段（及考试形式）\n            "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.ksjd[_vm.studCourseCustomized.examPhase]
                            ) +
                            "\n              （" +
                            _vm._s(_vm.dealExamFormat()) +
                            "）\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 } },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "f12 lable-text flex-center-spacebetween",
                        },
                        [_vm._v("是否提供Past Paper（及答案）\n            ")]
                      ),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.tgpp[
                                _vm.studCourseCustomized.pastPaperProvide
                              ]
                            ) +
                            "\n              (" +
                            _vm._s(
                              _vm.studCourseCustomized.pastPaperAnswers
                                ? "有"
                                : "无"
                            ) +
                            "答案)\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                    [
                      _c("p", { staticClass: "f12 lable-text " }, [
                        _vm._v("学生空余时间："),
                        _c("i", { staticClass: "el-icon-warning" }),
                        _vm._v("当前所在时区 "),
                        _c("span", { staticClass: "red-color f12" }, [
                          _vm._v(_vm._s(_vm.dealTimeZoneList())),
                        ]),
                      ]),
                      _c("div", { staticClass: "border_box" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.studCourseCustomized.freeTime) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p"),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("p", { staticClass: "f12 lable-text " }, [
                      _vm._v("学生其他需求\n            "),
                    ]),
                    _c("div", { staticClass: "border_box" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.studCourseCustomized.otherNeeds) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p"),
                  ]),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _VEnum = require("@/utils/VEnum");
var _tipmodel = _interopRequireDefault(require("@/views/common/tipmodel"));
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      modelObj: {
        show: "",
        btnno: "",
        btnyes: "",
        title: "",
        content: ""
      }
    };
  },
  components: {
    "tip-model": _tipmodel.default
  },
  methods: {
    eliminateFun: function eliminateFun() {
      //培训讲师被淘汰
      var code = window.localStorage.getItem("eliminatecode");
      if (code == 2 || code == 4) {
        this.modelObj = {
          show: true,
          btnno: "",
          btnyes: "我已收到",
          title: "淘汰通知",
          content: "您好,万能讲师对您目前的工作表现进行了考核,考核结果为淘汰,感谢您的参与,工资请去APP账户中提现"
        };
      }
    },
    yesfuns: function yesfuns() {
      removeLocal("teactoken");
      removeLocal("teacinfo");
      this.$router.push("/");
    }
  },
  created: function created() {
    this.eliminateFun();
    var _this$$route = this.$route,
      params = _this$$route.params,
      query = _this$$route.query;
    var path = params.path;
    var code = query.code;
    this.$router.push(+code ? _VEnum.HatchRouterPath.PLAN : _VEnum.HatchRouterPath.LETTER);
  }
};
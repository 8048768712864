"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _bottomItem = _interopRequireDefault(require("@/views/account/newHome/bottomItem"));
var _incomeList = _interopRequireDefault(require("@/views/account/newHome/incomeList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    bottomItem: _bottomItem.default,
    incomeList: _incomeList.default
  },
  props: {
    likeData: {
      type: Object,
      default: function _default() {}
    },
    highListData: {
      type: Object,
      default: function _default() {}
    },
    mostLoveData: {
      type: Object,
      default: function _default() {}
    },
    texts: {
      type: Object,
      default: function _default() {}
    },
    redbagObj: {
      type: Object,
      default: function _default() {}
    },
    activeData: {
      type: Object,
      default: function _default() {}
    },
    teacherType: {
      type: String,
      default: ""
    },
    incomeData: {
      type: Object,
      default: function _default() {}
    },
    pieMonth: {
      type: Number,
      default: 0
    },
    nowMonth: {
      type: Number,
      default: 0
    },
    pieDataArr: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      medalsList: []
    };
  },
  methods: {
    openSingleredbag: function openSingleredbag(val) {
      this.$parent.openSingleredbag(val);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "avatar-uploader",
          attrs: {
            data: _vm.uploadData,
            "before-upload": _vm.handleBefore,
            "show-file-list": false,
            "on-success": _vm.handleSuccess,
            "on-remove": _vm.handleRemove,
            action: _vm.OSS_URL,
            accept: _vm.accept,
            "list-type": "picture-card",
          },
        },
        [
          _vm.url
            ? _c("img", {
                attrs: { src: _vm.url },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.imgRemove($event)
                  },
                },
              })
            : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Material = _interopRequireDefault(require("./Material"));
var _ArrangeClass = _interopRequireDefault(require("./ArrangeClass"));
var _Table = require("@/views/dissertation/cfg/Table");
var _VEnum = require("@/utils/VEnum");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  data: function data() {
    return {
      ClassStatus2Val: _Table.ClassStatus2Val,
      AddClasses: _VEnum.AddClasses,
      StageClassesType: _VEnum.StageClassesType
    };
  },
  props: {
    /* 阶段类型 */
    stageType: {
      type: Number,
      default: 0
    },
    /* 阶段id */
    stageId: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: function _default() {}
    }
  },
  filters: {
    fmtDate: function fmtDate(val) {
      if (val) return (0, _moment.default)(val).format("YYYY-MM-DD HH:mm");
    },
    fmtMins: function fmtMins(val) {
      if (val) return val + "mins";else return "- -";
    }
  },
  components: {
    GMaterial: _Material.default /* 讲师材料 组件 */,
    GArrangeClass: _ArrangeClass.default /* 排课 组件 */
  },
  methods: {
    fmtJson: function fmtJson(json) {
      if (json) return JSON.parse(json);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fileList.length
        ? _vm._l(_vm.fileList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                staticStyle: { "margin-top": "10px" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "file-name",
                    class: { bluefont: _vm.type == 2 },
                    on: {
                      click: function ($event) {
                        return _vm.seeFile(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm._v(" "),
                _vm.type == 1
                  ? _c("i", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: item.loading,
                          expression: "item.loading",
                        },
                      ],
                      staticClass: "el-icon-delete delete-icon",
                      on: {
                        click: function ($event) {
                          return _vm.deleteFile(item, index)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            )
          })
        : [_vm.type == 2 ? _c("div", [_vm._v("暂无申请文件")]) : _vm._e()],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
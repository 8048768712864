import { render, staticRenderFns } from "./ArrangeClass.vue?vue&type=template&id=315b3276&scoped=true"
import script from "./ArrangeClass.vue?vue&type=script&lang=js"
export * from "./ArrangeClass.vue?vue&type=script&lang=js"
import style0 from "./ArrangeClass.vue?vue&type=style&index=0&id=315b3276&lang=scss"
import style1 from "./ArrangeClass.vue?vue&type=style&index=1&id=315b3276&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315b3276",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('315b3276')) {
      api.createRecord('315b3276', component.options)
    } else {
      api.reload('315b3276', component.options)
    }
    module.hot.accept("./ArrangeClass.vue?vue&type=template&id=315b3276&scoped=true", function () {
      api.rerender('315b3276', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dissertation/components/Modal/ArrangeClass.vue"
export default component.exports
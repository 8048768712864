var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-container",
      style: _vm.staticPath.BG,
    },
    [
      _c("countdown", { attrs: { showTime: false, text: _vm.text } }),
      _vm._v(" "),
      _c("div", { staticClass: "idform-container" }, [
        _vm.teacherInfo.childType != 3
          ? _c(
              "section",
              [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [
                    _c("mini-map", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.modalShow,
                          expression: "!modalShow",
                        },
                      ],
                      on: { open: _vm.openModal },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("map-modal", {
                  ref: "modal",
                  on: {
                    close: function ($event) {
                      _vm.modalShow = false
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "main",
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "100px",
                  "label-position": "top",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "请上传您的证件照", prop: "thumb" } },
                  [
                    _c(
                      "div",
                      { staticClass: "avatar-contarner" },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action:
                                "https://classbro-oss.oss-accelerate.aliyuncs.com/",
                              data: _vm.ossData,
                              multiple: false,
                              "show-file-list": false,
                              "on-success": _vm.handleAvatarSuccess,
                              "before-upload": _vm.beforeAvatarUpload,
                            },
                          },
                          [
                            _vm.form.thumb
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.form.thumb },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "tips" }, [
                          _vm._v("展示您最专业的形象给学生"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "demo" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/hatch/idform/user.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("示例：请上传五官清晰的照片")]),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "nickName", label: "您的昵称" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        size: "mini",
                        placeholder: "您的上课昵称或英文名",
                      },
                      model: {
                        value: _vm.form.nickName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "nickName", $$v)
                        },
                        expression: "form.nickName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "birthday", label: "生日" } },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        size: "mini",
                        type: "date",
                        format: "yyyy-MM-dd",
                        placeholder: "选择日期",
                      },
                      model: {
                        value: _vm.form.birthday,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "birthday", $$v)
                        },
                        expression: "form.birthday",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "btn", on: { click: _vm.validate } }, [
              _vm._v("提交"),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "title" }, [
      _c("span", [_vm._v("你的讲师形象")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: { visible: _vm.isShow, width: "480px" },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("进入正式课堂")]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", [_vm._v("请在确认下课后再点击顶部【下课】/【End】按钮")]),
        _vm._v(" "),
        _c("div", [
          _vm._v("否则点击【下课】/【End】后将"),
          _c("span", [_vm._v("无法再次打开")]),
          _vm._v("课堂"),
        ]),
      ]),
      _vm._v(" "),
      _c("el-button", { staticClass: "btn", on: { click: _vm.comeInClass } }, [
        _vm._v("打开课堂"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
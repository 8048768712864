var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showHeaderPicture != null,
              expression: "showHeaderPicture != null",
            },
          ],
          staticClass: "titleName",
          on: { click: _vm.toAccount },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showHeaderPicture == false,
                  expression: "showHeaderPicture == false",
                },
              ],
              staticStyle: { display: "inline-block" },
            },
            [
              _c("img", {
                staticClass: "headerImg",
                staticStyle: {
                  display: "inline-block",
                  "vertical-align": "middle",
                  "margin-right": "10px",
                },
                attrs: { src: _vm.headerPicture, title: _vm.headerPicture },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "middle",
                  },
                },
                [_vm._v(_vm._s(_vm.username))]
              ),
            ]
          ),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showHeaderPicture == true,
                expression: "showHeaderPicture == true",
              },
            ],
            staticClass: "headerImg",
            attrs: { src: _vm.headerPicture, title: _vm.headerPicture },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": false,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.routes, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
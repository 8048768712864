var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mask-content mask-width1100" },
    [
      _c("h3", [
        _vm._v("查看课堂 "),
        _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "see-coursebox" }, [
        _c(
          "div",
          { staticClass: "course-showbox" },
          [
            _c(
              "h4",
              {
                staticClass: "overflowdot cursorpointer",
                on: {
                  click: function ($event) {
                    return _vm.see(_vm.courseshowObj)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.courseshowObj.orderNo))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dis-flex" },
              [
                _c("img", {
                  staticClass: "cinput-icon",
                  attrs: { src: require("../../assets/images/icon-desc.png") },
                }),
                _vm._v(" "),
                _c("label", { staticClass: "cinput-label" }, [
                  _vm._v("课堂名称:"),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  staticStyle: { width: "340px" },
                  attrs: { disabled: _vm.courseshowObj.statused != 1 },
                  model: {
                    value: _vm.courseshowObj.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.courseshowObj, "name", $$v)
                    },
                    expression: "courseshowObj.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.courseshowObj.spoType == 65 &&
            _vm.courseshowObj.courseVersion > 1
              ? [
                  _c(
                    "div",
                    { staticClass: "dis-flex" },
                    [
                      _c("img", {
                        staticClass: "cinput-icon",
                        attrs: {
                          src: require("../../assets/images/icon-desc.png"),
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "cinput-label" }, [
                        _vm._v("课堂阶段:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.stageLoading,
                              expression: "stageLoading",
                            },
                          ],
                          staticStyle: { width: "340px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择课堂阶段",
                            disabled: _vm.courseshowObj.statused != 1,
                          },
                          on: { change: _vm.chooseStage },
                          model: {
                            value: _vm.stageId,
                            callback: function ($$v) {
                              _vm.stageId = $$v
                            },
                            expression: "stageId",
                          },
                        },
                        _vm._l(_vm.stagelist, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label:
                                item.name +
                                "(" +
                                item.startDate.substring(5) +
                                "~" +
                                item.endDate.substring(5) +
                                ")",
                              value: item.id,
                              disabled:
                                item.spoType == 4 ||
                                item.spoType == 32 ||
                                item.spoType == 64 ||
                                item.spoType == 66,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dis-flex" },
                    [
                      _c("img", {
                        staticClass: "cinput-icon",
                        attrs: {
                          src: require("../../assets/images/icon-desc.png"),
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "cinput-label" }, [
                        _vm._v("课堂任务:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "340px" },
                          attrs: { placeholder: "请选择课堂任务" },
                          model: {
                            value: _vm.taskId,
                            callback: function ($$v) {
                              _vm.taskId = $$v
                            },
                            expression: "taskId",
                          },
                        },
                        _vm._l(_vm.list, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.remark, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "dis-flex" }, [
              _c("img", {
                staticClass: "cinput-icon",
                attrs: { src: require("../../assets/images/icon-desc.png") },
              }),
              _vm._v(" "),
              _c("label", { staticClass: "cinput-label" }, [_vm._v("时间:")]),
              _vm._v(" "),
              _vm.courseshowObj.statused == 1
                ? _c("div", [
                    _c(
                      "p",
                      { staticStyle: { "text-align": "left" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { float: "left", width: "170px" },
                          attrs: {
                            type: "date",
                            "picker-options": _vm.pickerOptions1,
                            placeholder: "开始日期",
                            format: "yyyy 年 MM 月 dd 日",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: { change: _vm.changpick },
                          model: {
                            value: _vm.dateStart,
                            callback: function ($$v) {
                              _vm.dateStart = $$v
                            },
                            expression: "dateStart",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-date-picker", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            type: "date",
                            "picker-options": _vm.pickerOptions2,
                            placeholder: "结束日期",
                            format: "yyyy 年 MM 月 dd 日",
                            "value-format": "yyyy-MM-dd",
                          },
                          on: { change: _vm.changpick2 },
                          model: {
                            value: _vm.dateEnd,
                            callback: function ($$v) {
                              _vm.dateEnd = $$v
                            },
                            expression: "dateEnd",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px", float: "left" },
                          attrs: {
                            placeholder: "起始时间",
                            "picker-options": {
                              start: "00:00",
                              step: "00:10",
                              end: "23:50",
                              minTime: _vm.minstartTime,
                            },
                          },
                          model: {
                            value: _vm.startTime,
                            callback: function ($$v) {
                              _vm.startTime = $$v
                            },
                            expression: "startTime",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "结束时间",
                            "picker-options": {
                              start: "00:00",
                              step: "00:10",
                              end: "23:50",
                              minTime: _vm.minendTime,
                            },
                          },
                          model: {
                            value: _vm.endTime,
                            callback: function ($$v) {
                              _vm.endTime = $$v
                            },
                            expression: "endTime",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("span", { staticClass: "cinput-span" }, [
                    _vm._v(_vm._s(_vm.courseshowObj.time)),
                  ]),
            ]),
            _vm._v(" "),
            _vm.courseshowObj.beforeNotifyTime
              ? _c("div", { staticClass: "dis-flex" }, [
                  _c("img", {
                    staticClass: "cinput-icon",
                    attrs: {
                      src: require("../../assets/images/icon-desc.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "cinput-label" }, [
                    _vm._v("提醒:"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "cinput-span" }, [
                    _vm._v(
                      _vm._s(_vm.courseshowObj.beforeNotifyTime) + "分钟以后"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "dis-flex" }, [
              _c("img", {
                staticClass: "cinput-icon",
                attrs: { src: require("../../assets/images/icon-desc.png") },
              }),
              _vm._v(" "),
              _c("label", { staticClass: "cinput-label" }, [
                _vm._v("课堂说明:"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.courseshowObj.description,
                      expression: "courseshowObj.description",
                    },
                  ],
                  staticClass: "cinput-textarea",
                  attrs: { disabled: _vm.courseshowObj.statused != 1 },
                  domProps: { value: _vm.courseshowObj.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.courseshowObj,
                        "description",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.courseshowObj.statused == 1
                  ? _c("p", { staticClass: "upload-box" }, [
                      _c(
                        "button",
                        {
                          staticClass: "default-btn",
                          on: { click: _vm.closeMask },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "defaultlight-btn",
                          on: {
                            click: function ($event) {
                              return _vm.updateClass(_vm.courseshowObj)
                            },
                          },
                        },
                        [_vm._v("保存")]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dis-flex" }, [
              _c("img", {
                staticClass: "cinput-icon",
                attrs: { src: require("../../assets/images/icon-course.png") },
              }),
              _vm._v(" "),
              _c("label", { staticClass: "cinput-label" }, [
                _vm._v("上传课件"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "p",
                  { staticClass: "upload-box" },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        attrs: {
                          "on-preview": _vm.handlePreview,
                          action: _vm.urls,
                          "file-list": _vm.fileList,
                          multiple: false,
                          "show-file-list": true,
                          "on-success": _vm.uploadSuccess,
                          "auto-upload": false,
                          accept: _vm.accept.join(","),
                          "before-upload": _vm.beforeUpload,
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btnshowtext",
                            attrs: { slot: "trigger" },
                            slot: "trigger",
                          },
                          [_vm._v("选择文件")]
                        ),
                        _vm._v(" "),
                        _vm.courseshowObj.isYtk
                          ? _c("p", [
                              _vm._v("请确认课件中"),
                              _c("span", [_vm._v("无公司logo与名称")]),
                              _vm._v("以及"),
                              _c("span", [_vm._v("讲师个人隐私")]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("课件将自动同步到"),
                          _c("span", [_vm._v("课堂内")]),
                          _vm._v("并发送给学生"),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("可上传word,pdf,ppt,mp3,mp4格式，最大支持"),
                          _c("span", [_vm._v("200M")]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "defaultlight-btn",
                        on: { click: _vm.submitUpload },
                      },
                      [_vm._v("上传文件")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "upload-common",
                    staticStyle: { padding: "10px 0" },
                    attrs: { id: "ossfile" },
                  },
                  _vm._l(_vm.uploadFilesLength, function (item) {
                    return _c("ul", [
                      _c("li", [
                        _vm._v(
                          _vm._s(item.name) + "/" + _vm._s(item.filesize) + " "
                        ),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              return _vm.deleteFile(item)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "progress" }, [
                          _c("div", {
                            staticClass: "progress-bar",
                            style: { width: item.percent + "%" },
                          }),
                        ]),
                        _c("span", [_vm._v(_vm._s(item.percent) + "%")]),
                      ]),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", {
                  staticStyle: { padding: "10px 0" },
                  attrs: { id: "scheduleossfile" },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm.courseshowObj.statused == 2 || _vm.courseshowObj.statused == 1
              ? _c(
                  "button",
                  {
                    staticClass: "default-btn cancel-course",
                    on: {
                      click: function ($event) {
                        _vm.maskShow = true
                      },
                    },
                  },
                  [_vm._v("取消排课")]
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.hasOthering,
                expression: "hasOthering",
              },
            ],
            staticClass: "showcurse-box",
          },
          [
            _c("h3", [
              _vm._v("相关课堂(" + _vm._s(_vm.classrooms.length) + ") "),
            ]),
            _vm._v(" "),
            _vm.classrooms.length > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.classrooms, function (list) {
                    return _c("li", [
                      _c("h5", [
                        _c("span", { class: "status" + list.statused }, [
                          _vm._v(_vm._s(_vm.statusText[list.statused])),
                        ]),
                        _vm._v(_vm._s(list.time)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "course-content" }, [
                        _c("p", [
                          _c("b", [_vm._v(_vm._s(list.name))]),
                          _vm._v(
                            "\n              " +
                              _vm._s(list.description) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        list.statused == 4 || list.statused == 2
                          ? _c(
                              "button",
                              {
                                staticClass: "default-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.in_classroom(list)
                                  },
                                },
                              },
                              [_vm._v("\n              进入课堂\n            ")]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.maskShow
        ? _c("div", { staticClass: "new-mask" }, [
            _c("div", { staticClass: "mask-content" }, [
              _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
              _vm._v(" "),
              _c("img", {
                staticClass: "tips-icon",
                attrs: { src: require("../../assets/images/login-tips.png") },
              }),
              _vm._v(" "),
              _c("h5", [_vm._v("提示")]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "padding-left": "20px",
                    "padding-right": "20px",
                  },
                },
                [_vm._v("确认取消排课?")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "btnbox" }, [
                _c(
                  "button",
                  {
                    staticClass: "default-btn forget-pwd",
                    on: { click: _vm.closeMask },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "defaultlight-btn forget-pwd",
                    on: { click: _vm.cancelCourse },
                  },
                  [_vm._v("确认")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("downloadApp", { ref: "downloadApp" }),
      _vm._v(" "),
      _c("goClassIn", { ref: "goClassIn" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "testForms",
      staticClass: "testForm-wrap",
      attrs: { model: _vm.testForm, rules: _vm.rules },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "examTopicCount",
            label: "考试题目数量(e.g:30道选择题、10道判断题、Unknown)",
          },
        },
        [
          _c("el-input", {
            attrs: {
              placeholder: "30道选择题、10道判断题",
              size: "small",
              disabled: _vm.datas.disabled,
            },
            model: {
              value: _vm.testForm.examTopicCount,
              callback: function ($$v) {
                _vm.$set(_vm.testForm, "examTopicCount", $$v)
              },
              expression: "testForm.examTopicCount",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "考试范围", prop: "examScope" } },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { width: "100%" },
              attrs: { disabled: _vm.datas.disabled },
              model: {
                value: _vm.testForm.examScope,
                callback: function ($$v) {
                  _vm.$set(_vm.testForm, "examScope", $$v)
                },
                expression: "testForm.examScope",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [
                _vm._v("所有章节/内容"),
              ]),
              _vm._v(" "),
              _c(
                "el-radio",
                { attrs: { label: 0 } },
                [
                  _vm._v("Week/Chapter\n        "),
                  _vm.testForm.examScope == "0"
                    ? [
                        _c("div", { staticClass: "examScope-other-box" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.testForm.examScopeStart,
                                expression: "testForm.examScopeStart",
                              },
                            ],
                            attrs: { type: "number" },
                            domProps: { value: _vm.testForm.examScopeStart },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.testForm,
                                  "examScopeStart",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("-")]),
                          _vm._v("\n            Week/Chapter\n            "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.testForm.examScopeEnd,
                                expression: "testForm.examScopeEnd",
                              },
                            ],
                            attrs: { type: "number" },
                            domProps: { value: _vm.testForm.examScopeEnd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.testForm,
                                  "examScopeEnd",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          class: { "no-mar-b": _vm.showOther },
          attrs: {
            label: "考试答案要求的文件格式",
            prop: "examAnswerFileFormat",
          },
        },
        [
          _c(
            "el-checkbox-group",
            {
              staticStyle: { width: "100%" },
              attrs: { disabled: _vm.datas.disabled },
              model: {
                value: _vm.testForm.examAnswerFileFormat,
                callback: function ($$v) {
                  _vm.$set(_vm.testForm, "examAnswerFileFormat", $$v)
                },
                expression: "testForm.examAnswerFileFormat",
              },
            },
            _vm._l(_vm.examAnswerFileArr, function (answer, index) {
              return _c(
                "el-checkbox",
                { key: index, attrs: { label: answer.type } },
                [_vm._v(_vm._s(answer.name))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showOther
        ? _c(
            "el-form-item",
            { attrs: { prop: "examAnswerFileOhter" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入其他考试答案文件格式",
                  size: "small",
                  disabled: _vm.datas.disabled,
                },
                model: {
                  value: _vm.testForm.examAnswerFileOhter,
                  callback: function ($$v) {
                    _vm.$set(_vm.testForm, "examAnswerFileOhter", $$v)
                  },
                  expression: "testForm.examAnswerFileOhter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.datas.spoType == 69
        ? _c(
            "el-form-item",
            {
              class: { "no-mar-b": _vm.testForm.examItemTypes.includes(8) },
              attrs: { label: "考试题型（可多选）", prop: "examItemTypes" },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  staticStyle: { width: "100%" },
                  attrs: { disabled: _vm.datas.disabled },
                  on: { change: _vm.examItemTypesFun },
                  model: {
                    value: _vm.testForm.examItemTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.testForm, "examItemTypes", $$v)
                    },
                    expression: "testForm.examItemTypes",
                  },
                },
                _vm._l(_vm.examItemTypes, function (exam, index) {
                  return _c(
                    "el-checkbox",
                    { key: index, attrs: { label: exam.type } },
                    [
                      _vm._v(_vm._s(exam.name) + "\n        "),
                      [
                        exam.type != 8
                          ? _c("div", { staticClass: "examScope-other-box" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.examItemTypes[index].total,
                                    expression: "examItemTypes[index].total",
                                  },
                                ],
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "50px",
                                },
                                attrs: {
                                  size: "small",
                                  type: "number",
                                  disabled:
                                    !_vm.testForm.examItemTypes.includes(
                                      exam.type
                                    ),
                                },
                                domProps: {
                                  value: _vm.examItemTypes[index].total,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.examItemTypes[index],
                                      "total",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v("题\n          "),
                            ])
                          : _vm._e(),
                      ],
                    ],
                    2
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showOtherExamType
        ? _c(
            "el-form-item",
            { attrs: { prop: "examItemTypesOhter" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入其他考试题型",
                  size: "small",
                  disabled: _vm.datas.disabled,
                },
                model: {
                  value: _vm.testForm.examItemTypesOhter,
                  callback: function ($$v) {
                    _vm.$set(_vm.testForm, "examItemTypesOhter", $$v)
                  },
                  expression: "testForm.examItemTypesOhter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.testForm.examScope == 0 && _vm.datas.spoType == 69) ||
      _vm.datas.spoType == 66
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "examDifficulties",
                label: "考试重难知识点(e.g:假设检验、微分方程、M&M理论)",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "假设检验、微分方程、M&M理论",
                  size: "small",
                  disabled: _vm.datas.disabled,
                },
                model: {
                  value: _vm.testForm.examDifficulties,
                  callback: function ($$v) {
                    _vm.$set(_vm.testForm, "examDifficulties", $$v)
                  },
                  expression: "testForm.examDifficulties",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.testForm.examScope == 1 && _vm.datas.spoType == 69
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "examDifficultiesArr",
                label: "考试重难知识点(e.g:假设检验、微分方程、M&M理论)",
              },
            },
            [
              _vm._l(
                _vm.testForm.examDifficultiesArr,
                function (theory, index) {
                  return _c(
                    "p",
                    {
                      key: index,
                      staticStyle: { display: "flex", width: "100%" },
                    },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Theory name："),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "假设检验、微分方程、M&M理论",
                          size: "small",
                          disabled: _vm.datas.disabled,
                        },
                        model: {
                          value:
                            _vm.testForm.examDifficultiesArr[index].content,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.testForm.examDifficultiesArr[index],
                              "content",
                              $$v
                            )
                          },
                          expression:
                            "testForm.examDifficultiesArr[index].content",
                        },
                      }),
                    ],
                    1
                  )
                }
              ),
              _vm._v(" "),
              !_vm.datas.disabled
                ? _c("p", { staticClass: "btn-box" }, [
                    _c(
                      "span",
                      {
                        staticClass: "buttons-addtheory",
                        on: {
                          click: function ($event) {
                            return _vm.testForm.examDifficultiesArr.push({
                              content: "",
                            })
                          },
                        },
                      },
                      [_vm._v("新增")]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "是否遇到问题or特殊要求",
            prop: "specialRequirementsFlag",
          },
        },
        [
          _c(
            "el-radio-group",
            {
              staticStyle: { width: "100%" },
              attrs: { disabled: _vm.datas.disabled },
              model: {
                value: _vm.testForm.specialRequirementsFlag,
                callback: function ($$v) {
                  _vm.$set(_vm.testForm, "specialRequirementsFlag", $$v)
                },
                expression: "testForm.specialRequirementsFlag",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.testForm.specialRequirementsFlag == 1
            ? [
                _c("el-input", {
                  attrs: {
                    placeholder: "请输入遇到问题or特殊要求",
                    size: "small",
                    disabled: _vm.datas.disabled,
                  },
                  model: {
                    value: _vm.testForm.specialRequirements,
                    callback: function ($$v) {
                      _vm.$set(_vm.testForm, "specialRequirements", $$v)
                    },
                    expression: "testForm.specialRequirements",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.datas.showUpload
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "请上传登录学生系统查看的截图",
                prop: "examExtFileList",
              },
            },
            [
              !_vm.datas.disabled
                ? _c(
                    "el-upload",
                    {
                      attrs: {
                        action:
                          "https://classbro-oss.oss-accelerate.aliyuncs.com/",
                        data: _vm.ossData,
                        multiple: false,
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess,
                        "before-upload": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "default-btn",
                          attrs: { id: "allupload" },
                        },
                        [_vm._v("上传图片")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.testForm.examExtFileList, function (imgs, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "show-img-style" },
                  [
                    _c("img", {
                      attrs: { src: imgs.loginScreenshotFileUrl, alt: "" },
                    }),
                    _vm._v(" "),
                    !_vm.datas.disabled
                      ? _c("i", {
                          staticClass: "el-icon-circle-close position-delete",
                          on: {
                            click: function ($event) {
                              return _vm.deleteImg(index)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
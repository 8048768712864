"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("../css/conmmon.scss");
var _orderdetail = require("@/api/orderdetail");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['referenceList', 'additional'],
  data: function data() {
    return {
      show: false,
      chooseObj: {}
    };
  },
  created: function created() {},
  computed: {},
  methods: {
    openUrl: function openUrl(url, filename) {
      var eleLink = document.createElement('a');
      eleLink.download = filename;
      eleLink.style.display = 'none';
      // 字符内容转变成blob地址
      eleLink.href = url;
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
    showReferenceFun: function showReferenceFun(obj) {
      this.show = true;
      this.chooseObj = obj;
      this.additionalRead(obj.courseId);
    },
    additionalRead: function additionalRead(courseId) {
      (0, _orderdetail.additionalRead)(courseId).then(function (res) {
        console.log(res, '信息已读');
      }).catch(function (err) {});
    }
  }
};
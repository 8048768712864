"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  showElcTip: false
};
var actions = {};
var mutations = {
  setshowElcTip: function setshowElcTip(state, data) {
    state.showElcTip = data;
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _VEnum = require("@/utils/VEnum");
var _default = exports.default = {
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    localStorage.setItem(_VEnum.Storage.CRM_LINK_DISS, to.query.isCrm);
    localStorage.setItem(_VEnum.Storage.NEW_LINK_DISS, to.query.isNewCrm);
    next();
  },
  beforeCreate: function beforeCreate() {
    var _this$$route = this.$route,
      params = _this$$route.params,
      query = _this$$route.query;
    var TOKEN = _VEnum.CrmRouterLinkArgs.TOKEN,
      COURSE_ID = _VEnum.CrmRouterLinkArgs.COURSE_ID,
      PATH = _VEnum.CrmRouterLinkArgs.PATH;
    var token = query[TOKEN],
      courseId = query[COURSE_ID],
      link = query[PATH],
      isPermision = query.isPermision,
      isCrm = query.isCrm,
      isNewCrm = query.isNewCrm;
    /* crm 跳转 规划表跳转 只读权限 */
    if (link === "dissertation") {
      localStorage.setItem(_VEnum.Storage.TOKEN, token);
      localStorage.setItem(_VEnum.Storage.CRM_LINK_DISS, true);
      this.$store.dispatch("dissertation/crmGotoDissertation", {
        courseId: courseId
      });
      return;
    } else if (link === "paperSchedule") {
      localStorage.setItem(_VEnum.Storage.TOKEN, token);
      localStorage.setItem(_VEnum.Storage.CRM_LINK_DISS, isCrm);
      localStorage.setItem(_VEnum.Storage.NEW_LINK_DISS, isNewCrm);
      this.$store.dispatch("dissertation/crmGotoPaperRouterPath", {
        courseId: courseId,
        isPermision: isPermision
      });
      return;
    }
    var path = params.path;
    this.$router.replace({
      path: "/" + (path || ""),
      query: query
    });
  },
  render: function render(h) {
    return h(); // avoid warning message
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "516px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("提交考试内容")]),
          _vm._v(" "),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "140px" } },
            [
              _vm.assistExamWay == 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Loom Link",
                        "label-width": "100px",
                        prop: "loomLink",
                        rules: [
                          {
                            required: true,
                            validator: _vm.linkVlidator,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.disabled, type: "url" },
                        model: {
                          value: _vm.form.loomLink,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "loomLink", $$v)
                          },
                          expression: "form.loomLink",
                        },
                      }),
                    ],
                    1
                  )
                : [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Exam Questions",
                          prop: "subject",
                          rules: [
                            {
                              required: true,
                              validator: _vm.subjectVlidator,
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        !_vm.disabled
                          ? _c("uploadFile", {
                              ref: "upload1",
                              staticStyle: { display: "inline-block" },
                              on: { uploadFiles: _vm.uploadFiles },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("file-list", {
                          attrs: {
                            fileList: _vm.form.subject,
                            disabled: _vm.disabled,
                            filesName: "subject",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Yours Answers",
                          prop: "answer",
                          rules: [
                            {
                              required: true,
                              validator: _vm.answerVlidator,
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        !_vm.disabled
                          ? _c("uploadFile", {
                              ref: "upload2",
                              staticStyle: { display: "inline-block" },
                              on: { uploadFiles: _vm.uploadFiles2 },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("file-list", {
                          attrs: {
                            fileList: _vm.form.answer,
                            disabled: _vm.disabled,
                            filesName: "answer",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancle-btn",
                  attrs: { size: "mini" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      staticClass: "confirm-btn",
                      attrs: { size: "mini", loading: _vm.loading },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
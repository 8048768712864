var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.isShow, width: "480px", "append-to-body": "" },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
        close: _vm.closeModal,
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("img", {
          staticClass: "tips-icon",
          attrs: { src: require("@/assets/images/login-tips.png") },
        }),
        _vm._v(" "),
        _c("h2", [_vm._v("是否确认申请结课")]),
        _vm._v(" "),
        _vm.order.over15MinFlag == 1 && !_vm.order.settlementForm
          ? _c("div", { staticClass: "content-desc" }, [
              _c("div", [
                _vm._v("• 点击【确认】后系统将自动以老师名义赠送"),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.formatMinAndHour(_vm.order.overtimeMin)) +
                      "超时课时"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("• 超时薪资不结算，请前往订单群组与学生示好课时赠送"),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btnbox" },
          [
            _c(
              "el-button",
              { staticClass: "default-btn", on: { click: _vm.closeModal } },
              [_vm._v("取消")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "defaultlight-btn",
                attrs: { loading: _vm.loading },
                on: { click: _vm.applyEndClass },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
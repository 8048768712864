"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacWishCard = exports.complateProduct = void 0;
var _request = require("./request");
// 意向表 integrate

/**
 * 讲师填写意愿单
 * @param {String} userId
 * @param {String} wishType 意向类型，逗号分割  0-定制辅导 1-考前突击 65-论文大礼包 66-特殊订单
 */
var teacWishCard = exports.teacWishCard = function teacWishCard(userId, wishType) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/teacWishCard/").concat(userId, "/").concat(wishType),
    method: "post",
    data: {} //后台让传的
  });
};
/**
 * 视频和问卷完成下一步
 * @param {String} userId
 * @param {String} wishType 意向类型，逗号分割  0-定制辅导 1-考前突击 65-论文大礼包 66-特殊订单
 */
var complateProduct = exports.complateProduct = function complateProduct(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/process/v1/addTrainStep"),
    method: "get",
    params: params
  });
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _contentnull = _interopRequireDefault(require("@/views/contentnull"));
var _medalList = _interopRequireDefault(require("./medalList"));
var _highRewardList = _interopRequireDefault(require("./highRewardList"));
var _Utils = require("@/utils/task/Utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    contentnull: _contentnull.default,
    medalList: _medalList.default,
    highRewardList: _highRewardList.default
  },
  props: {
    incomeData: {
      type: Object,
      default: function _default() {}
    },
    texts: {
      type: Object,
      default: function _default() {}
    },
    teacherType: {
      type: String,
      default: ""
    },
    pieMonth: {
      type: Number,
      default: 0
    },
    nowMonth: {
      type: Number,
      default: 0
    },
    pieDataArr: {
      type: Object,
      default: function _default() {}
    },
    redbagObj: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    globalImgFilter: _Utils.globalImgFilter,
    handeMonth: function handeMonth() {
      this.$parent.$parent.handeMonth();
    },
    openSingleredbag: function openSingleredbag(val) {
      if (!this.redbagObj.income) return;
      this.$parent.$parent.openSingleredbag(val);
    }
  }
};
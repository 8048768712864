"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unlockMedal = exports.sortMedal = exports.receiveMedal = exports.readNotice = exports.queryTeacIncomeDetailsV2 = exports.getTeacMedalInfo = exports.getTeacMedal = exports.getNewMedal = exports.getMedalDetail = exports.getHangMedal = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
//获取勋章列表
var getTeacMedalInfo = exports.getTeacMedalInfo = function getTeacMedalInfo(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/getTeacMedalInfo"),
    method: "get",
    params: params
  });
};
//获取新得到的勋章
var getNewMedal = exports.getNewMedal = function getNewMedal(teacId) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/flushTeacMedalInfo?teacId=").concat(teacId),
    method: "get"
  });
};
//领取/挂上勋章墙
var receiveMedal = exports.receiveMedal = function receiveMedal(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/receiveMedal"),
    method: "post",
    data: data
  });
};
//获取已挂勋章
var getHangMedal = exports.getHangMedal = function getHangMedal(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/getGuaMedals"),
    method: "get",
    params: params
  });
};
//获取勋章详情
var getMedalDetail = exports.getMedalDetail = function getMedalDetail(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/getMedalDetail"),
    method: "get",
    params: params
  });
};
// 获取首页收入明细
var queryTeacIncomeDetailsV2 = exports.queryTeacIncomeDetailsV2 = function queryTeacIncomeDetailsV2(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/queryTeacIncomeDetailsV2"),
    method: "get",
    params: params
  });
};
// 首页已挂勋章排序
var sortMedal = exports.sortMedal = function sortMedal(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/medalSort"),
    method: "post",
    data: data
  });
};
//解锁勋章
var unlockMedal = exports.unlockMedal = function unlockMedal(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/unlockMedal"),
    method: "post",
    data: data
  });
};

// 获取悬浮框产看讲师勋章得详情
var getTeacMedal = exports.getTeacMedal = function getTeacMedal(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/queryPollmedal"),
    method: "get",
    params: params
  });
};
// 已读勋章功能通知
var readNotice = exports.readNotice = function readNotice(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacMedalRecord/markMedal "),
    method: "get",
    params: params
  });
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-container",
      style: _vm.staticPath.BG,
    },
    [
      _c("countdown"),
      _vm._v(" "),
      _c("div", { staticClass: "intention-container" }, [
        _c(
          "section",
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _c("mini-map", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.modalShow,
                      expression: "!modalShow",
                    },
                  ],
                  on: { open: _vm.openModal },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("map-modal", {
              ref: "modal",
              on: {
                close: function ($event) {
                  _vm.modalShow = false
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "main",
          [
            _vm._m(0),
            _vm._v(" "),
            _c("section", { staticClass: "content-container" }, [
              _c("span", { staticClass: "label" }, [
                _vm._v("亲爱的" + _vm._s(_vm.userName) + "讲师，您好！"),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("恭喜您通过万能班长全真模拟课环节！")]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n                    下列为万能班长所有课程产品类别，请您根据自身意愿和实际情况，选择您的意向辅导课程产品类别，\n                "
                ),
              ]),
              _vm._v(" "),
              _vm._m(1),
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "video-container" }, [
              _c("span", { staticClass: "video-title" }, [
                _vm._v(
                  "您未选择的课程产品类别，意味着你将放弃该产品类型的课程订单！"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "video-box" }, [
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c(
                      "el-popover",
                      { attrs: { placement: "top-start", trigger: "hover" } },
                      [
                        _c("video", {
                          staticStyle: { "object-fit": "fill" },
                          attrs: {
                            preload: "meta",
                            controlslist: "nodownload",
                            "webkit-playsinline": "",
                            playsinline: "",
                            "x5-playsinline": "",
                            "x-webkit-airplay": "allow",
                            muted: "muted",
                            src: "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20190322/%E8%AE%BA%E6%96%87%E5%A4%A7%E7%A4%BC%E5%8C%85329%E4%BA%8C%E7%BB%B4%E7%A0%81.mp4",
                            loop: "loop",
                            controls: "controls",
                            width: "400",
                            height: "230",
                          },
                          domProps: { muted: true },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            slot: "reference",
                            width: "240px",
                            src: require("@/assets/images/hatch/intention/video1.png"),
                          },
                          slot: "reference",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.checkHandler },
                        model: {
                          value: _vm.checkBoxs.one,
                          callback: function ($$v) {
                            _vm.$set(_vm.checkBoxs, "one", $$v)
                          },
                          expression: "checkBoxs.one",
                        },
                      },
                      [_vm._v("论文大礼包")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c(
                      "el-popover",
                      { attrs: { placement: "top-start", trigger: "hover" } },
                      [
                        _c("video", {
                          staticStyle: { "object-fit": "fill" },
                          attrs: {
                            preload: "meta",
                            controlslist: "nodownload",
                            "webkit-playsinline": "",
                            playsinline: "",
                            "x5-playsinline": "",
                            "x-webkit-airplay": "allow",
                            muted: "muted",
                            src: "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20190322/%E8%80%83%E5%89%8D%E7%AA%81%E5%87%BB%E6%96%B0%E4%BA%8C%E7%BB%B4%E7%A0%8120210423.mp4",
                            loop: "loop",
                            controls: "controls",
                            width: "400",
                            height: "230",
                          },
                          domProps: { muted: true },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            slot: "reference",
                            width: "240px",
                            src: require("@/assets/images/hatch/intention/video2.png"),
                          },
                          slot: "reference",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.checkHandler },
                        model: {
                          value: _vm.checkBoxs.two,
                          callback: function ($$v) {
                            _vm.$set(_vm.checkBoxs, "two", $$v)
                          },
                          expression: "checkBoxs.two",
                        },
                      },
                      [_vm._v("考前突击")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c(
                      "el-popover",
                      { attrs: { placement: "top-start", trigger: "hover" } },
                      [
                        _c("video", {
                          staticStyle: { "object-fit": "fill" },
                          attrs: {
                            preload: "meta",
                            controlslist: "nodownload",
                            "webkit-playsinline": "",
                            playsinline: "",
                            "x5-playsinline": "",
                            "x-webkit-airplay": "allow",
                            muted: "muted",
                            src: "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20190322/%E7%89%B9%E6%AE%8A%E8%AE%A2%E5%8D%95%E6%96%B0%E4%BA%8C%E7%BB%B4%E7%A0%8120210423.mp4",
                            loop: "loop",
                            controls: "controls",
                            width: "400",
                            height: "230",
                          },
                          domProps: { muted: true },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            slot: "reference",
                            width: "240px",
                            src: require("@/assets/images/hatch/intention/video3.png"),
                          },
                          slot: "reference",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.checkHandler },
                        model: {
                          value: _vm.checkBoxs.three,
                          callback: function ($$v) {
                            _vm.$set(_vm.checkBoxs, "three", $$v)
                          },
                          expression: "checkBoxs.three",
                        },
                      },
                      [_vm._v("特殊订单")]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v("(论文批改/修改/润色、翻译)")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "err-box" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errMsg,
                              expression: "errMsg",
                            },
                          ],
                          staticClass: "err-msg",
                        },
                        [_vm._v("请至少选择一个你感兴趣的课程方向！")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btn", on: { click: _vm.validate } }, [
              _vm._v("提交意向表"),
            ]),
            _vm._v(" "),
            _c("unlock-success", {
              ref: "success",
              on: { submit: _vm.nextStep },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "title" }, [
      _c("span", [_vm._v("万能班长讲师产品意向表")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "tips" }, [_vm._v("提交后不可更改")]),
      _vm._v(" "),
      _c("span", {}, [_vm._v("，务必谨慎填写！")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
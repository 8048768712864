"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mixins = require("@/mixins");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  mixins: [_mixins.ClassroomMixin],
  props: ['teacId'],
  data: function data() {
    return {
      code: '',
      loading: false
    };
  },
  methods: {}
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: {
        visible: _vm.isShow,
        width: "500px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("确定接单？")]),
      _vm._v(" "),
      _c("div", { staticClass: "first" }, [_vm._v("讲师您好！")]),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [
        _vm._v("2022.03.08订单执行Reflection机制，订单出现"),
        _c("span", [_vm._v("学生投诉、售后判定讲师责任")]),
        _vm._v("等情况，讲师将面临"),
        _c("span", [_vm._v("双倍薪资扣除")]),
        _vm._v("。"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "desc text" }, [
        _vm._v(
          "请各位讲师谨慎接单，认真完成每一单哦，相信优秀的你不会被扣除薪资的哦~~"
        ),
      ]),
      _vm._v(" "),
      [6].includes(_vm.current.borderType)
        ? _c("div", { staticClass: "text" }, [
            _c("span", [
              _vm._v("*该订单为包课辅导，接单后将承接其下所有子订单"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-box" },
        [
          _c(
            "el-button",
            {
              staticClass: "defaultlight-btn",
              attrs: { loading: _vm.loading },
              on: { click: _vm.receivOrder },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.partLevelData = exports.levelData = void 0;
//全职老师
var levelData = exports.levelData = {
  lv1: {
    // 王牌
    rules: [{
      icon: require('@/assets/images/level/newlevel/newRule/jixiao.png'),
      numText: '1,000',
      desc: '累计绩效要求'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/gongling.png'),
      numText: '2个月',
      desc: '工龄'
    }]
  },
  lv2: {
    // 资深
    rules: [{
      icon: require('@/assets/images/level/newlevel/newRule/fenxiang.png'),
      numText: '分享王*1',
      desc: '分享参与度'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/jixiao.png'),
      numText: '5,200',
      desc: '累计绩效要求'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/tousulv.png'),
      numText: '3%',
      desc: '投诉Fail率'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/gongling.png'),
      numText: '6个月',
      desc: '工龄'
    }]
  },
  lv3: {
    // 特级
    rules: [{
      icon: require('@/assets/images/level/newlevel/newRule/fenxiang.png'),
      numText: '分享王*2',
      desc: '分享参与度'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/jixiao.png'),
      numText: '16,000',
      desc: '累计绩效要求'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/tousulv.png'),
      numText: '2%',
      desc: '投诉Fail率'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/gongling.png'),
      numText: '12个月',
      desc: '工龄'
    }]
  },
  lv4: {
    // T3特级
    rules: [{
      icon: require('@/assets/images/level/newlevel/newRule/fenxiang.png'),
      numText: '分享王*3',
      desc: '分享参与度'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/jixiao.png'),
      numText: '27,000',
      desc: '累计绩效要求'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/tousulv.png'),
      numText: '1.5%',
      desc: '投诉Fail率'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/gongling.png'),
      numText: '18个月',
      desc: '工龄'
    }]
  },
  lv5: {
    // T4特级
    rules: [{
      icon: require('@/assets/images/level/newlevel/newRule/fenxiang.png'),
      numText: '分享王*4',
      desc: '分享参与度'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/jixiao.png'),
      numText: '41,040',
      desc: '累计绩效要求'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/tousulv.png'),
      numText: '1%',
      desc: '投诉Fail率'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/gongling.png'),
      numText: '24个月',
      desc: '工龄'
    }, {
      icon: require('@/assets/images/level/newlevel/newRule/zuzhang.png'),
      numText: '教研组长',
      desc: '晋升'
    }]
  }
};
//兼职老师等级福利
var partLevelData = exports.partLevelData = {
  // 1-3
  lv1: {
    welfare: [{
      icon: require('@/assets/images/level/newlevel/newWare/beizi.png'),
      desc1: "超多等级福利奖励从 <span style='color:#5E4ABB;font-weight:700'>LV4</span> 开始领取奥~"
    }]
  },
  lv4: {
    welfare: [{
      icon: require('@/assets/images/level/newlevel/newWare/jianzhi.png'),
      desc1: '兼职实习证明'
    }, {
      icon: require('@/assets/images/level/newlevel/ware/dingzhi.png'),
      desc1: "定制Classbro讲师套装"
    }]
  },
  // 5-7
  lv5: {
    welfare: [{
      icon: require('@/assets/images/level/newlevel/newWare/jianzhi.png'),
      desc1: '兼职实习证明'
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/tuijian.png'),
      desc1: "经理亲笔推荐信"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/xieziban.png'),
      desc1: "专属手写板"
    },
    // 6级有
    {
      icon: require('@/assets/images/level/newlevel/newWare/mianshi.png'),
      desc1: "全职讲师初复试免试"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/jianzhi.png'),
      desc1: "定制Classbro讲师套装"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/tuandui.png'),
      desc1: "官网讲师团队介绍"
    }]
  },
  lv7: {
    welfare: [{
      icon: require('@/assets/images/level/newlevel/newWare/jianzhi.png'),
      desc1: '兼职实习证明'
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/tuijian.png'),
      desc1: "经理亲笔推荐信"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/mianshi.png'),
      desc1: "全职讲师初复试免试"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/tuandui.png'),
      desc1: "官网讲师团队介绍"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/kaohemianshi.png'),
      desc1: "全职考核免试"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/xieziban.png'),
      desc1: "专属手写板"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/jianzhi.png'),
      desc1: "定制Classbro讲师套装"
    }, {
      icon: require('@/assets/images/level/newlevel/newWare/zhuanshu.png'),
      desc1: "官网讲师专属信息介绍"
    }]
  }
};
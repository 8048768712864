"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _meadlanimation = _interopRequireDefault(require("@/views/hatch/letter/meadlanimation.json"));
var _piaodaianimation = _interopRequireDefault(require("@/views/hatch/letter/piaodaianimation.json"));
var _lottieWeb = _interopRequireDefault(require("lottie-web"));
var _medal = require("@/api/medal");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GetMedal',
  data: function data() {
    return {
      animation: null,
      current: {},
      isShow: false,
      timer: null
    };
  },
  watch: {
    isShow: function isShow(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.init();
        });
      }
    }
  },
  methods: {
    show: function show(val) {
      var _this2 = this;
      this.isShow = true;
      this.current = val;
      this.timer = setTimeout(function () {
        _this2.isShow = false;
      }, 6000);
    },
    handleClose: function handleClose() {
      this.isShow = false;
      clearTimeout(this.timer);
    },
    setAnimation: function setAnimation(index, json, loop, dom, cb) {
      var icon = document.getElementById(dom);
      this["animation" + index] = _lottieWeb.default.loadAnimation({
        container: icon,
        // 包含动画的dom元素
        renderer: "svg",
        // 渲染出来的是什么格式
        loop: loop,
        // 循环播放
        autoplay: true,
        // 自动播放
        animationData: json // 动画json的路径
      });
      cb && this["animation" + index].addEventListener("complete", function () {
        return cb();
      });
    },
    init: function init() {
      var _this3 = this;
      setTimeout(function () {
        _this3.setAnimation(1, _meadlanimation.default, false, 'medal', function () {
          _this3.setAnimation(2, _piaodaianimation.default, true, 'medal1');
        });
      }, 300);
    }
  }
};
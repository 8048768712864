var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "848px",
            "show-close": _vm.type == 2,
            "append-to-body": true,
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("ClassBro万能讲师团队工作、薪酬介绍"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-desc" }, [
            _c("div", [
              _vm._v("热诚欢迎您加入Classbro成为万能讲师大家庭的一份子！"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("请您耐心"),
              _c("span", [_vm._v("观看完视频")]),
              _vm._v("，了解您未来的"),
              _c("span", [_vm._v("接单流程、产品分类、薪酬奖惩机制")]),
              _vm._v("，有助于您更好的完成工作。"),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v("感谢您的配合，祝您工作顺利。生活愉快。")]),
          ]),
          _vm._v(" "),
          _c("div", { class: { showcontrol: _vm.type == 2 } }, [
            _c("video", {
              ref: "video",
              staticClass: "video-style",
              attrs: {
                id: "welcomevideo",
                poster: require("@/assets/images/home/newposter.png"),
                controlsList: "nodownload",
                disablePictureInPicture: "true",
                controls: true,
                src: _vm.url,
              },
            }),
          ]),
          _vm._v(" "),
          _vm.showClose || _vm.type == 2
            ? _c(
                "button",
                {
                  staticClass: "defaultlight-btn",
                  attrs: { slot: "footer" },
                  on: { click: _vm.closeModal },
                  slot: "footer",
                },
                [_vm._v(_vm._s(_vm.type == 1 ? "立即体验" : "我知道了"))]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
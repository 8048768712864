import { render, staticRenderFns } from "./historyLessons.vue?vue&type=template&id=0c010d43&scoped=true"
import script from "./historyLessons.vue?vue&type=script&lang=js"
export * from "./historyLessons.vue?vue&type=script&lang=js"
import style0 from "./historyLessons.vue?vue&type=style&index=0&id=0c010d43&scoped=true&lang=css"
import style1 from "./historyLessons.vue?vue&type=style&index=1&id=0c010d43&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c010d43",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c010d43')) {
      api.createRecord('0c010d43', component.options)
    } else {
      api.reload('0c010d43', component.options)
    }
    module.hot.accept("./historyLessons.vue?vue&type=template&id=0c010d43&scoped=true", function () {
      api.rerender('0c010d43', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account/historyLessons.vue"
export default component.exports
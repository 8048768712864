"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue2FlipCountdown = _interopRequireDefault(require("vue2-flip-countdown"));
var _mixins = require("@/mixins");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    FlipCountdown: _vue2FlipCountdown.default
  },
  mixins: [_mixins.CountDownMixin],
  props: {
    showTime: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "1"
    },
    text: {
      type: String,
      default: "请在倒计时结束前，完成产品视频培训，超时会淘汰哦"
    }
  },
  data: function data() {
    return {
      labels: {
        days: "天",
        hours: "时",
        minutes: "分",
        seconds: "秒"
      }
    };
  }
};
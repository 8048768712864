"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * 查询订单阶段信息
 */
function paper_stage() {
  return {
    status: 200,
    body: [{
      stageType: 3,
      stageClassInstance: [{
        className: "课1",
        paperStageAdd: 1,
        statused: 2,
        type: 13,
        classTime: 32,
        classStartTime: "2020-10-15 22:10",
        teacherFiles: [{
          url: "www.baidu.com",
          name: "wenjian名称",
          remark: "说明说明",
          createAt: "2020-15-11 11:44:44",
          stageClassFileType: 2
        }],
        remark: ["加一堂课"]
      }, {
        className: "课2",
        paperStageAdd: 1,
        statused: 1,
        type: 12,
        classStartTime: "2020-10-15 22:10",
        teacherFiles: [{
          url: "www.baidu.com",
          name: "wenjian名称",
          remark: "说明说明",
          createAt: "2020-15-11 11:44:44",
          stageClassFileType: 5
        }, {
          url: "www.baidu.com",
          name: "wenjian名称",
          createAt: "2020-15-11 11:44:44",
          stageClassFileType: 2
        }],
        remark: ["(1)了解学生需求与水平，确定论文选题方向", "(2)介绍每部分的课程内容和线上答疑、proofreading等课程服务"]
      }]
    }, {
      stageType: 4,
      stageClassInstance: [{
        className: "课1",
        paperStageAdd: 1,
        statused: 2,
        type: 13,
        classTime: 32,
        classStartTime: "2020-10-15 22:10",
        teacherFiles: [],
        remark: ["加一堂课"]
      }, {
        className: "课2",
        paperStageAdd: 1,
        statused: 1,
        type: 12,
        classStartTime: "2020-10-15 22:10",
        teacherFiles: [1],
        remark: ["(1)了解学生需求与水平，确定论文选题方向", "(2)介绍每部分的课程内容和线上答疑、proofreading等课程服务"]
      }]
    }]
  };
}
var _default = exports.default = {
  paper_stage: paper_stage
};
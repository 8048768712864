"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    notReadNum: {
      type: Number,
      default: 0
    },
    messageData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      teacherType: localStorage.getItem("teacType")
    };
  },
  computed: {
    showTip: function showTip() {
      var time = '2022/7/19';
      return this.teacherType == 2 && new Date(time).getTime() - new Date().getTime() > 0;
    }
  },
  methods: {
    lookallNotice: function lookallNotice() {
      this.$parent.lookallNotice();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var state = {
  token: (0, _auth.getToken)(),
  name: '',
  avatar: '',
  ordernum: 0
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ORDERNUM: function SET_ORDERNUM(state, ordernum) {
    state.ordernum = ordernum;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username.trim(),
        password: password
      }).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.token);
        (0, _auth.setToken)(data.token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(state.token).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        var name = data.name,
          avatar = data.avatar;
        commit('SET_NAME', name);
        commit('SET_AVATAR', avatar);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        (0, _router.resetRouter)();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      (0, _auth.removeToken)();
      resolve();
    });
  },
  //订单数量展示
  changeorderNum: function changeorderNum(_ref5, ordernum) {
    var commit = _ref5.commit;
    commit('SET_ORDERNUM', ordernum);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
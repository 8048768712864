var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "peroson-detail" },
    [
      _c("div", { staticClass: "peroson-top" }, [
        _vm.teacinfo.headImage
          ? _c("img", {
              staticClass: "head-img",
              attrs: { src: _vm.teacinfo.headImage, alt: "" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          staticClass: "peroson-avator",
          attrs: { src: _vm._f("globalImgFilter")(_vm.teacinfo), alt: "" },
          on: { error: _vm.imgOnError },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "person-info" }, [
          _c("div", [_vm._v(_vm._s(_vm.teacinfo.nickName) + " ")]),
          _vm._v(" "),
          _c("div", { staticClass: "hourlywage" }, [
            _vm._v(_vm._s((_vm.teacinfo.hourlyWage || 0) * 10) + "RMB/H"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "peroson-bottom" }, [
        _c("div", { staticClass: "total" }, [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/images/home/allmoney.png"),
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "all-bg",
            attrs: { src: require("@/assets/images/home/allbg.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "title-text" }, [_vm._v("当月订单收入")]),
            _vm._v(" "),
            _c("div", { staticClass: "money" }, [
              _vm._v(_vm._s(_vm.incomeInfo.incomeCny || 0)),
              _c("span", [_vm._v("RMB")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dec-num" }, [
              _vm._v(_vm._s(_vm.incomeInfo.incomeG || 0) + "G"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail",
                on: {
                  click: function ($event) {
                    return _vm.showTypeModal("momeyDetail", 1)
                  },
                },
              },
              [_vm._v("明细"), _c("i", { staticClass: "el-icon-caret-right" })]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "total" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/home/add.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "title-text" }, [_vm._v("当月奖金")]),
            _vm._v(" "),
            _c("div", { staticClass: "money" }, [
              _vm._v("\n          " + _vm._s(_vm.incomeInfo.bonusCny || 0)),
              _c("span", [_vm._v("RMB")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dec-num" }, [
              _vm._v(
                "\n          好评:" +
                  _vm._s(_vm.incomeInfo.praiseGB) +
                  "G 高分:" +
                  _vm._s(_vm.incomeInfo.highScoreGB) +
                  "G\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail",
                on: {
                  click: function ($event) {
                    return _vm.showTypeModal("momeyDetail", 2)
                  },
                },
              },
              [_vm._v("明细"), _c("i", { staticClass: "el-icon-caret-right" })]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "total" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("@/assets/images/home/deduct.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "title-text" }, [_vm._v("当月总扣除")]),
            _vm._v(" "),
            _c("div", { staticClass: "money" }, [
              _vm._v("\n          " + _vm._s(_vm.incomeInfo.deductCny || 0)),
              _c("span", [_vm._v("RMB")]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dec-num" }, [
              _vm._v("总扣除：" + _vm._s(_vm.incomeInfo.deductG || 0) + "G"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "detail",
                on: {
                  click: function ($event) {
                    return _vm.showTypeModal("momeyDetail", 3)
                  },
                },
              },
              [_vm._v("明细"), _c("i", { staticClass: "el-icon-caret-right" })]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "medal-box" },
        [
          _c("img", {
            staticClass: "all-medal",
            attrs: {
              src: require("@/assets/images/home/allMedal.png"),
              alt: "",
            },
            on: { click: _vm.goToMedalWall },
          }),
          _vm._v(" "),
          _vm.hangMedalList.length
            ? [
                _c(
                  "draggable",
                  {
                    attrs: { animation: "300" },
                    on: { end: _vm.onEnd },
                    model: {
                      value: _vm.hangMedalList,
                      callback: function ($$v) {
                        _vm.hangMedalList = $$v
                      },
                      expression: "hangMedalList",
                    },
                  },
                  [
                    _c(
                      "transition-group",
                      _vm._l(_vm.hangMedalList, function (item) {
                        return _c("img", {
                          key: item.medalType,
                          staticClass: "medal-img",
                          attrs: { alt: "", src: item.medalImage },
                          on: { click: _vm.goToMedalWall },
                        })
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.hangMedalList.length
            ? [
                _c("img", {
                  staticClass: "position-medal",
                  attrs: {
                    src: require("@/assets/images/home/positionMedal.png"),
                    alt: "",
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.hangMedalList.length
            ? _c(
                "div",
                { staticClass: "desc" },
                [
                  _vm.getMedalNum
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "orange-text",
                            on: { click: _vm.goToMedalWall },
                          },
                          [
                            _vm._v("立即挂起"),
                            _c("i", { staticClass: "el-icon-arrow-right" }),
                            _c("i", { staticClass: "el-icon-arrow-right two" }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.getMedalNum == 1
                          ? _c("div", [_vm._v(" 万能新人，挂起第一枚勋章")])
                          : _c("div", [
                              _vm._v(
                                " 已获得" +
                                  _vm._s(_vm.getMedalNum) +
                                  "枚勋章，挂起第一枚勋章"
                              ),
                            ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("upSalary", { ref: "upSalary" }),
      _vm._v(" "),
      _c("applyWare", { ref: "applyWare" }),
      _vm._v(" "),
      _c("highScore", { ref: "highScore" }),
      _vm._v(" "),
      _c("welecomeNew", { ref: "welecomeNew", attrs: { type: "2" } }),
      _vm._v(" "),
      _c("moneyDetail", { ref: "momeyDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "630px",
            "before-close": _vm.handleClose,
          },
        },
        [
          _vm.isShow
            ? _c("video", {
                ref: "myvideo",
                staticStyle: { width: "100%" },
                attrs: {
                  autoplay: "",
                  controlslist: "nodownload",
                  controls: true,
                  src: _vm.videourl,
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.modelObj.show
    ? _c("div", { staticClass: "new-mask" }, [
        _c("div", { staticClass: "mask-content" }, [
          _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
          _vm._v(" "),
          _c("img", {
            staticClass: "tips-icon",
            attrs: { src: require("../../assets/images/login-tips.png") },
          }),
          _vm._v(" "),
          _c("h5", [_vm._v(_vm._s(_vm.modelObj.title))]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticStyle: { "padding-left": "20px", "padding-right": "20px" },
            },
            [_vm._v(_vm._s(_vm.modelObj.content))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "btnbox" }, [
            _vm.modelObj.btnno
              ? _c(
                  "button",
                  {
                    staticClass: "default-btn forget-pwd",
                    on: { click: _vm.closeMask },
                  },
                  [_vm._v(_vm._s(_vm.modelObj.btnno))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.modelObj.btnyes
              ? _c(
                  "button",
                  {
                    staticClass: "defaultlight-btn forget-pwd",
                    on: { click: _vm.yesFun },
                  },
                  [_vm._v(_vm._s(_vm.modelObj.btnyes))]
                )
              : _vm._e(),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
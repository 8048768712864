"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrderOperatingTime = exports.orderUpdateExam1 = exports.orderUpdateExam = exports.getZipFilePlus = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
var _qs = _interopRequireDefault(require("qs"));
//考试信息保存
var orderUpdateExam = exports.orderUpdateExam = function orderUpdateExam(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/updateExamExt"),
    method: "post",
    data: data
  });
};
// 
var orderUpdateExam1 = exports.orderUpdateExam1 = function orderUpdateExam1(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/updateExamExtV1"),
    method: "post",
    data: data
  });
};
//根据月份获取课程
var updateOrderOperatingTime = exports.updateOrderOperatingTime = function updateOrderOperatingTime(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/updateOrderOperatingTime"),
    method: "post",
    formData: true,
    data: _qs.default.stringify(data)
  });
};
var getZipFilePlus = exports.getZipFilePlus = function getZipFilePlus(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/resource/getZipFilePlus"),
    method: "post",
    data: data
  });
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatMinAndHour = exports.download = exports.checkexe = exports.MinToHourAndMin = void 0;
exports.formatTime = formatTime;
exports.limitInputNumber = limitInputNumber;
exports.param2Obj = param2Obj;
exports.parseTime = parseTime;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if ((0, _typeof2.default)(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * 检查是否安装桌面应用
 */
var checkexe = exports.checkexe = function checkexe(key, callback) {
  var userAgent = navigator.userAgent;
  console.log(userAgent);
  var isChrome = navigator.userAgent.indexOf('Chrome') > -1;
  if (isChrome) {
    window.protocolCheck("".concat(window.electronUrl).concat(key), function (fail) {
      callback();
    }, function (succ) {
      console.log('succ', succ);
    });
  } else {
    callback();
  }
};
var download = exports.download = function download(src, fileName) {
  var x = new XMLHttpRequest();
  x.open("GET", src, true);
  x.responseType = "blob";
  x.onload = function (e) {
    var url = window.URL.createObjectURL(x.response);
    var a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
  };
  x.send();
};
// 分钟转换成[时，分]
var MinToHourAndMin = exports.MinToHourAndMin = function MinToHourAndMin(val) {
  var hour = val && Math.floor(val / 60) || 0;
  var min = val && val % 60 || 0;
  return {
    hour: hour,
    min: min
  };
};
// 分钟转换成[时，分]
var formatMinAndHour = exports.formatMinAndHour = function formatMinAndHour(val) {
  var hour = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'h';
  var min = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'min';
  return val ? "".concat(MinToHourAndMin(val).hour ? MinToHourAndMin(val).hour + hour : '').concat(MinToHourAndMin(val).min ? MinToHourAndMin(val).min.toFixed(2) + min : '') : "0".concat(hour);
};
/**
* 输入框限制输入 只允许输入数字
* value 数值 必填
* max 最大值 选填
* min 最小值 选填
* length 小数点保留位数 选填
* isBlur 失去焦点触发
*/
function limitInputNumber(value) {
  var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var min = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var length = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  var isBlur = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var isMoney = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
  if (!value && value !== 0) {
    return min >= 0 ? min : null;
  }
  //是否需要保留小数
  value = value.toString();
  if (!length) {
    if (max < 0 || min < 0) {
      value = value.replace(/[^\-\d]+/g, '');
    } else {
      value = value.replace(/[^0-9]/ig, "");
    }
  } else {
    // 保留数字和小数点
    if (max < 0 || min < 0) {
      value = value.replace(/[^\-\d^\.]+/g, '');
    } else {
      value = value.replace(/[^\d^\.]+/g, '');
    }
    if (value.indexOf('.') > 0) {
      value = value.slice(0, value.indexOf('.') + (length + 1));
    } else if (value.indexOf('.') === 0 && value.length === 1) {
      value = 0 + value;
    } else if (value.indexOf('.') === value.length - 1 && value.length != 0 && isBlur) {
      value = value.substring(0, value.length - 1);
    }
  }
  if (max) {
    max = Number(max);
    var tempValue = Number(value);
    if (tempValue > max) {
      value = max;
    }
  }
  if (min) {
    min = Number(min);
    var _tempValue = Number(value);
    if (_tempValue < min) {
      value = min;
    }
  }
  if (isMoney) {
    for (var i = 0; i < value.length; i++) {
      if (value[i] === '.') {
        break;
      }
      if (value[i] == 0 && value[i + 1] !== '.' && i == 0) {
        value = value.slice(i + 1, value.length - 1);
        i--;
      }
    }
  }
  if (isBlur) {
    return Number(value);
  } else {
    return value;
  }
}
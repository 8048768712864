var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "560px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v(
              _vm._s(_vm.editClassTime ? "修改课堂" : "安排课堂") + "\n        "
            ),
            _c("div", { staticClass: "time-tip" }, [
              _vm._v("课堂排课时间统一为北京时间,请讲师注意时差"),
            ]),
          ]),
          _vm._v(" "),
          _vm.showfillNeed
            ? _c(
                "div",
                {
                  staticStyle: {
                    color: "red",
                    "text-align": "center",
                    "margin-bottom": "10px",
                  },
                },
                [_vm._v("请在破冰课上课前完成【破冰课授课要求】填写")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "110px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "阶段名称", prop: "stageId", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "410px" },
                      attrs: {
                        placeholder: "请选择阶段名称",
                        disabled: _vm.editClassTime,
                      },
                      on: { change: _vm.changeStage },
                      model: {
                        value: _vm.form.stageId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "stageId", $$v)
                        },
                        expression: "form.stageId",
                      },
                    },
                    _vm._l(_vm.stageList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "阶段课堂任务", prop: "taskId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "410px" },
                      attrs: { placeholder: "请选择阶段下的课堂任务" },
                      model: {
                        value: _vm.form.taskId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "taskId", $$v)
                        },
                        expression: "form.taskId",
                      },
                    },
                    _vm._l(
                      _vm.editClassTime && _vm.oldTaskId != "自主课堂"
                        ? _vm.taskList
                        : _vm.list,
                      function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.remark, value: item.id },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "课堂名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "410px" },
                    attrs: { placeholder: "请输入" },
                    on: { input: _vm.change },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "inline-block", width: "200px" },
                  attrs: { label: "开课日期", prop: "dateStart" },
                },
                [
                  _c("div", { staticClass: "time-desc" }, [
                    _vm._v("北京时间"),
                    _c("span", [_vm._v("(请将课堂安排在阶段时间内)")]),
                  ]),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "date",
                      "picker-options": {
                        disabledDate: function disabledDate(time) {
                          return (
                            time.getTime() <
                            new Date(_vm.beijingTime).getTime() - 8.64e7
                          )
                        },
                      },
                      placeholder: "开始日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                    },
                    on: { change: _vm.changpick },
                    model: {
                      value: _vm.form.dateStart,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dateStart", $$v)
                      },
                      expression: "form.dateStart",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  key: "startTime",
                  staticStyle: { display: "inline-block", width: "200px" },
                  attrs: { prop: "startTime", "label-width": "115px" },
                },
                [
                  _c("el-time-select", {
                    staticStyle: { width: "190px" },
                    attrs: {
                      placeholder: "起始时间",
                      "picker-options": {
                        start: "00:00",
                        step: "00:10",
                        end: "24:00",
                        minTime: _vm.minstartTime,
                      },
                    },
                    on: { change: _vm.getEndTime },
                    model: {
                      value: _vm.form.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "startTime", $$v)
                      },
                      expression: "form.startTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "课节时长", prop: "hour" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "time-select",
                      attrs: { placeholder: "", filterable: "" },
                      on: { change: _vm.getEndTime },
                      model: {
                        value: _vm.form.hour,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hour", $$v)
                        },
                        expression: "form.hour",
                      },
                    },
                    _vm._l(24, function (item, index) {
                      return _c("el-option", {
                        key: item,
                        attrs: {
                          label: index < 10 ? "0" + index : "" + index,
                          value: String(index),
                        },
                      })
                    }),
                    1
                  ),
                  _vm._v("\n          小时\n          "),
                  _c(
                    "el-select",
                    {
                      staticClass: "time-select",
                      attrs: { placeholder: "", filterable: "" },
                      on: { change: _vm.getEndTime },
                      model: {
                        value: _vm.form.minute,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "minute", $$v)
                        },
                        expression: "form.minute",
                      },
                    },
                    _vm._l(_vm.minList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                  _vm._v("\n          分钟\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _vm.classEndTime
                ? _c("div", { staticClass: "end" }, [
                    _vm._v("下课时间：" + _vm._s(_vm.classEndTime)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "time-memo" }, [
                _vm._v("请输入准确上课时长到达结束时间"),
                _c("span", [_vm._v("20分钟")]),
                _vm._v("后课堂关闭"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "课堂说明" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "410px" },
                    attrs: { type: "textarea", placeholder: "请输入" },
                    on: { input: _vm.change },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isPermision
            ? _c(
                "div",
                {
                  staticClass: "footer-btn",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.showfillNeed
                    ? _c("div", { staticClass: "tip" }, [
                        _vm._v("排课后前往填写破冰可授课需求"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "cancle-btn",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "confirm-btn",
                      style: {
                        width: [3].includes(_vm.version) ? "240px" : "190px",
                      },
                      on: { click: _vm.confirmAdd },
                    },
                    [_vm._v("确定")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-need-new", {
        attrs: { isShow: _vm.showNeedNew, deadline: _vm.deadline },
        on: { closeModal: _vm.closeDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
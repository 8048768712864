"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.search");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      onCourseLoading: false,
      browser: "",
      tableDataBegin: [],
      tableDataName: "",
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      modal: false,
      modal_time: false,
      total: 0,
      limit: 15,
      idss: [],
      courseId: "",
      val: 1,
      page: "",
      startTime: "",
      endTime: "",
      tk: "",
      ruleForm: {
        title: "",
        course_time: "",
        description: ""
      },
      rules: {
        title: [{
          required: true,
          message: "请填写课程标题",
          trigger: "blur"
        }],
        course_time: [{
          required: true,
          message: "请选择排课时间",
          trigger: "change"
        }],
        description: [{
          required: true,
          message: "请填写课程详情",
          trigger: "blur"
        }]
      }
    };
  },
  multipleSelection: [],
  mounted: function mounted() {
    this.getTableData();
    this.searchEnter();
  },
  methods: {
    searchEnter: function searchEnter() {
      var _this = this;
      document.onkeydown = function (event) {
        if (event.keyCode == 13) {
          _this.search();
        }
      };
    },
    search: function search() {
      this.getTableData();
    },
    getTableData: function getTableData() {
      var _this2 = this;
      this.onCourseLoading = true;
      this.$axios({
        url: this.$api.baseURL + "/classRoom/list",
        params: {
          page: this.val,
          limit: this.limit,
          statusedArr: "1;2;4;32",
          key: this.tableDataName
        }
      }).then(function (r) {
        _this2.onCourseLoading = false;
        _this2.tableDataEnd = r.data.body.list;
        _this2.total = r.data.body.totalCount;
        _this2.pageSize = r.data.body.totalCount;
        _this2.currentPage = r.data.body.currPage;
      }).catch(function (r) {
        _this2.onCourseLoading = false;
      });
      // var self = this
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     data: {
      //         'token': token,
      //         'page': this.val,
      //         'limit': this.limit,
      //         'statusedArr': '1;2;4;32',
      //         'key': this.tableDataName
      //     },
      //     url: '' + baseURL + '/classRoom/list',
      //     success: function(json) {
      //         self.tableDataEnd = json.body.list
      //         self.total = json.body.totalCount
      //         self.pageSize = json.body.totalCount
      //         self.currentPage = json.body.currPage
      //     }
      // })
    },
    apply: function apply(row, index) {
      this.modal = true;
      this.ruleForm = this.$refs.formName;
      this.courseId = row.id;
      this.cdId = row.cdId;
      this.startTime = row.startTime;
      this.endTime = row.endTime;
      this.description = row.description;
      this.time = [row.startTime, row.endTime];
      this.ruleForm = {
        title: row.name,
        description: row.description,
        course_time: [row.startTime, row.endTime]
      };
    },
    cancel: function cancel(row, index) {
      var _this3 = this;
      this.$confirm("是否要取消排课?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function (_) {
        _this3.$axios({
          url: _this3.$api.baseURL + "/order/cancelClassroom",
          method: "post",
          data: {
            classroomId: row.id
          }
        }).then(function (r) {
          if (r.data.status == 200) {
            _this3.$notify({
              title: '提示',
              message: r.data.body.msg,
              type: "success"
            });
            _this3.getTableData();
          }
          if (r.data.status == 400) {
            _this3.$notify({
              title: '提示',
              message: r.data.body.msg,
              type: "warning"
            });
            _this3.getTableData();
          }
        }).catch(function (er) {});
        // $.ajax({
        //     type: 'post',
        //     dataType: 'json',
        //     data: {
        //         'token': token,
        //         'classroomId': row.id
        //     },
        //     url: '' + baseURL + '/order/cancelClassroom',
        //     success: function(json) {
        //         if (json.status == 200) {
        //             vm.$notify({
        //   title: '提示',
        //                 message: json.body.msg,
        //                 type: 'success'
        //             })
        //             vm.getTableData()
        //         }
        //         if (json.status == 400) {
        //             vm.$notify({
        //   title: '提示',
        //                 message: json.body.msg,
        //                 type: 'warning'
        //             })
        //             vm.getTableData()
        //         }
        //     }
        // })
      }).catch(function (_) {});
    },
    sees: function sees(row, index) {
      // if (row.spoType == 64) {
      //     window.open('bigClassroomInfo.html?id=' + row.cdId + '')
      // } else if (row.scTypeed == 4) {
      //     window.open('seller_detail-wy.html?id=' + row.cdId + '&wyid=' + row.offCourseName + '&iswy=true')
      // } else {
      //     window.open('seller_detail.html?id=' + row.cdId + '')
      // }
      if (row.spoType === 64) {
        var Logistics2 = this.$router.resolve({
          path: "/bigClassRoom",
          query: {
            id: row.cdId,
            parentId: null
          }
        });
        window.open(Logistics2.href);
      } else {
        var type = 'daobi';
        if (row.courseType === 4) {
          type = 'wenya';
        }
        var Logistics1 = this.$router.resolve({
          path: "/sellerDetail",
          query: {
            id: row.courseId,
            type: type
          }
        });
        window.open(Logistics1.href);
      }
    },
    get_studjoinurl: function get_studjoinurl(row, index) {
      alert(row.studentJoinUrl);
    },
    // compile(code) {
    //     var code = getLocal('teactoken')
    //     var c = String.fromCharCode(code.charCodeAt(0) + code.length)
    //     for (var i = 1; i < code.length; i++) {
    //         c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1))
    //     }
    //     this.tk = escape(c)
    // },
    in_classroom: function in_classroom(row, index) {
      var code = window.localStorage.getItem("teactoken");
      var c = String.fromCharCode(code.charCodeAt(0) + code.length);
      for (var i = 1; i < code.length; i++) {
        c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
      }
      var tk = escape(c);
      window.open(this.$api.url + "static-resource/tkcloud/giveLessons.html?classroomId=" + row.id + "&iden=teac&tk=" + tk + "");
      // var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
      //     var isFirefox = typeof InstallTrigger !== 'undefined';
      //     var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
      //     var isIE = /*@cc_on!@*/false || !!document.documentMode;
      //     var isEdge = !isIE && !!window.StyleMedia;
      //     var isChrome = !!window.chrome && !!window.chrome.webstore;
      //     var isBlink = (isChrome || isOpera) && !!window.CSS;
      //     console.log(isChrome);
      //     if(isChrome == true){
      //       window.open(row.teacherJoinUrl);
      //     }else{
      //       alert("您当前使用的不是谷歌浏览器,点击下载浏览器")
      //       window.open("https://sm.myapp.com/original/Browser/70.0.3538.67_chrome_installer_x64.exe");
      //     }
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      // var self = this
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.$axios({
            url: _this4.$api.baseURL + "//order/updateClassroom",
            data: JSON.stringify({
              id: _this4.courseId,
              name: _this4.$refs.ruleForm.model.title,
              cdId: _this4.cdId,
              startTime: _this4.$refs.ruleForm.model.course_time[0],
              endTime: _this4.$refs.ruleForm.model.course_time[1],
              description: _this4.$refs.ruleForm.model.description
            }),
            method: "post",
            headers: {
              "Content-Type": "application/json"
            }
          }).then(function (r) {
            if (r.data.status == 200) {
              _this4.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "success"
              });
              _this4.modal = false;
              _this4.getTableData();
            }
            if (r.data.status == 400) {
              _this4.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "warning"
              });
              _this4.modal = false;
              _this4.getTableData();
            }
          }).catch(function (er) {});
          // $.ajax({
          //     type: 'post',
          //     contentType: 'application/json',
          //     headers: {
          //         'token': token
          //     },
          //     data: JSON.stringify({
          //         id: vm.courseId,
          //         name: this.$refs.ruleForm.model.title,
          //         cdId: vm.cdId,
          //         startTime: this.$refs.ruleForm.model.course_time[0],
          //         endTime: this.$refs.ruleForm.model.course_time[1],
          //         description: this.$refs.ruleForm.model.description
          //     }),
          //     url: '' + baseURL + '//order/updateClassroom',
          //     success: function(json) {
          //         if (json.status == 200) {
          //             vm.$notify({
          //   title: '提示',
          //                 message: json.body.msg,
          //                 type: 'success'
          //             })
          //             vm.modal = false
          //             vm.getTableData()
          //         }
          //         if (json.status == 400) {
          //             vm.$notify({
          //   title: '提示',
          //                 message: json.body.msg,
          //                 type: 'warning'
          //             })
          //             vm.modal = false
          //             this.getTableData()
          //         }
          //     }
          // })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    end_classroom: function end_classroom(row, index) {
      var _this5 = this;
      this.$confirm("是否要结束课堂?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function (_) {
        _this5.$axios({
          url: _this5.$api.baseURL + "/order/finishClassroom",
          method: "post",
          data: {
            classroomId: row.id
          }
        }).then(function (r) {
          if (r.data.status == 200) {
            _this5.$notify({
              title: '提示',
              message: r.data.body.msg,
              type: "success"
            });
            _this5.getTableData();
          }
          if (r.data.status == 400) {
            _this5.$notify({
              title: '提示',
              message: r.data.body.msg,
              type: "warning"
            });
            _this5.getTableData();
          }
        });
        // $.ajax({
        //     type: 'post',
        //     dataType: 'json',
        //     data: {
        //         'token': token,
        //         'classroomId': row.id
        //     },
        //     url: '' + baseURL + '/order/finishClassroom',
        //     success: function(json) {
        //         if (json.status == 200) {
        //             vm.$notify({
        //   title: '提示',
        //                 message: json.body.msg,
        //                 type: 'success'
        //             })
        //             vm.getTableData()
        //         }
        //         if (json.status == 400) {
        //             vm.$notify({
        //   title: '提示',
        //                 message: json.body.msg,
        //                 type: 'warning'
        //             })
        //             vm.getTableData()
        //         }
        //     }
        // })
      }).catch(function (_) {});
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.limit = val;
      this.getTableData();
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.map(function (item) {
        var id = item.id;
        ids.push("" + id + "");
      });
      this.selectedIDs = ids;
      console.log("多选", ids);
      this.idss = this.selectedIDs;
      console.log(this.idss);
    }
  }
};
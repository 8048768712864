"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _api = require("@/api/api");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isB: false,
      //是否是b端课堂
      dialogVisible: false,
      link: 'https://docs.qq.com/doc/DU0pRcnJRTHZRWEhx',
      secretKey: '',
      teacherJoinUrl: ''
      // // winUrl: 'classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/win/teacher/ClassbroRoom.exe',
      //     // macUrl: 'classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/mac/teacher/ClassbroRoom.dmg'
      //     winUrl: 'https://classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/win/teacher/ClassbroRoom-Test.exe',
      //     macUrl: 'https://classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/mac/teacher/ClassbroRoom-Test.dmg'
    };
  },
  methods: {
    show: function show(secretKey, teacherJoinUrl) {
      var isB = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.dialogVisible = true;
      this.secretKey = secretKey;
      this.teacherJoinUrl = teacherJoinUrl;
      this.isB = isB;
    },
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
    },
    gotoClass: function gotoClass() {
      window.open("".concat(window.electronUrl).concat(this.secretKey));
    },
    downloadApp: function downloadApp(type) {
      if (type === 'win') {
        window.open(_api.winUrl);
      } else if (type === 'mac') {
        window.open(_api.macUrl);
      }
    },
    openLink: function openLink(link) {
      window.open(link);
    },
    gotoWebClass: function gotoWebClass() {
      window.open(this.teacherJoinUrl);
    },
    confirm: function confirm() {
      this.isB = false;
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delCookie = delCookie;
exports.getCookie = getCookie;
exports.getLocal = getLocal;
exports.getSession = getSession;
exports.getToken = getToken;
exports.removeLocal = removeLocal;
exports.removeSession = removeSession;
exports.removeToken = removeToken;
exports.setCookie = setCookie;
exports.setLocal = setLocal;
exports.setSession = setSession;
exports.setToken = setToken;
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.constructor");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'vue_admin_template_token';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getSession(key) {
  return window.sessionStorage.getItem(key);
}
function setSession(key, value) {
  return window.sessionStorage.setItem(key, value);
}
function removeSession(key) {
  return window.sessionStorage.removeItem(key);
}
function getLocal(key) {
  var val = window.localStorage.getItem(key);
  if (typeof val === 'string') {
    return JSON.parse(val);
  } else {
    return val;
  }
}
function setLocal(key, value) {
  return window.localStorage.setItem(key, value);
}
function removeLocal(key) {
  return window.localStorage.removeItem(key);
}
function getCookie(name) {
  var arr;
  var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  if (arr = document.cookie.match(reg)) {
    return arr[2];
  } else {
    return null;
  }
}

// 设置cookie,增加到vue实例方便全局调用
function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + '=' + escape(value) + (expiredays == null ? '' : ';expires=' + exdate.toGMTString());
}

// 删除cookie
function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null) {
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
  }
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "848px",
            "show-close": false,
            "append-to-body": true,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("CLASSBRO讲师官网3.0全面升级"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-desc" }, [
            _c("div", [
              _vm._v("讲师等级晋升加速、加速、加速啦！最快"),
              _c("span", [_vm._v("3个月")]),
              _vm._v("课时收入可升至"),
              _c("span", [_vm._v("15G/H")]),
              _vm._v("，持续发力课时收入最高达"),
              _c("span", [_vm._v("30G/H")]),
              _vm._v("；"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("全新晋升规则将从"),
              _c("span", [_vm._v("获赞值")]),
              _vm._v("和"),
              _c("span", [_vm._v("投诉Fail率")]),
              _vm._v("两个标准全面考察讲师的工作结果，"),
              _c("span", [_vm._v("高质量")]),
              _vm._v("一直是我们的核心追求；"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("Ranking排名到达当月设定名次即可获得"),
              _c("span", [_vm._v("“赞红包”")]),
              _vm._v("奖励，助力优质讲师更快晋升；"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("还有更多荣誉勋章等你解锁，获得"),
              _c("span", [_vm._v("勋章挂起")]),
              _vm._v("后可展示在首页排行榜中，全体讲师团队可见。"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("详细规则请查看视频讲解：")]),
          _vm._v(" "),
          _c("video", {
            staticClass: "video-style",
            attrs: {
              id: "video",
              poster: require("@/assets/images/home/poster.png"),
              controlsList: "nodownload",
              disablePictureInPicture: "true",
              controls: true,
              src: "https://classbro-oss.oss-accelerate.aliyuncs.com/statice-resource/20190520/%E6%9C%80%E7%BB%88%E7%89%883.0~1.mp4",
            },
          }),
          _vm._v(" "),
          _vm.showClose
            ? _c(
                "button",
                {
                  staticClass: "defaultlight-btn",
                  attrs: { slot: "footer" },
                  on: { click: _vm.closeModal },
                  slot: "footer",
                },
                [_vm._v("立即体验")]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "myCourse" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loadingCourse,
            expression: "loadingCourse",
          },
        ],
      },
      [
        _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
          _vm.updataFiles
            ? _c("div", { staticClass: "maskLoading" }, [
                _c("div", { staticClass: "imgText" }, [
                  _c("img", {
                    staticStyle: { "margin-left": "40%" },
                    attrs: {
                      src: require("../../assets/images/loading.gif"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("文件较大或上行网速较慢，请耐心等待上传。"),
                    _c("br"),
                    _vm._v("过程中请不要关闭或刷新浏览器！避免上传失败。"),
                    _c("br"),
                    _vm._v("上传成功后页面自动刷新"),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("el-input", {
          staticStyle: { width: "240px" },
          attrs: { placeholder: "请输入姓名" },
          model: {
            value: _vm.tableDataName,
            callback: function ($$v) {
              _vm.tableDataName = $$v
            },
            expression: "tableDataName",
          },
        }),
        _vm._v(" "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.search } },
          [_vm._v("搜索")]
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            ref: "multipleTable",
            staticStyle: { width: "100%", "margin-top": "5px" },
            attrs: { data: _vm.tableDataEnd, border: "" },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { label: "订单号", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#409EFF", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.see(
                                scope.row,
                                scope.$index,
                                scope.offCourseName
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(scope.row.orderNo))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "总上课时长(min)",
                align: "center",
                "show-overflow-tooltip": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.allSchooltime))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "总赠送时长(min)",
                align: "center",
                "show-overflow-tooltip": true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.allPresenterTime))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "订单状态",
                "show-overflow-tooltip": true,
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.courseStatus == 1
                        ? _c("el-tag", [_vm._v("未处理（无任何销售接单）")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 2
                        ? _c("el-tag", [_vm._v("待审核")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 4
                        ? _c("el-tag", [_vm._v("待确定")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 8
                        ? _c("el-tag", [_vm._v("待规划（等待教师/运营接单）")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 16
                        ? _c("el-tag", [
                            _vm._v("规划中(订单第一次超时等待运营接单)"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 32
                        ? _c("el-tag", [
                            _vm._v(
                              "规划中(订单第二次超时等待运营主管强制指派)"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 64
                        ? _c("el-tag", [
                            _vm._v("规划中(运营接单待指派，正在处理)"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 128
                        ? _c("el-tag", [_vm._v("待排课")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 256
                        ? _c("el-tag", [_vm._v("已排课")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 512
                        ? _c("el-tag", [_vm._v("申请结课中")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 1024
                        ? _c("el-tag", [_vm._v("已结课")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 2048
                        ? _c("el-tag", [_vm._v("已强制结课")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 4096
                        ? _c("el-tag", [_vm._v("已取消")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 8192
                        ? _c("el-tag", [_vm._v("坏单申请中")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 16384
                        ? _c("el-tag", [_vm._v("订单作废")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 32768
                        ? _c("el-tag", [_vm._v("销售被更换")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 65536
                        ? _c("el-tag", [_vm._v("事故单申请中")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 131072
                        ? _c("el-tag", [_vm._v("讲师已更换")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.courseStatus == 262144
                        ? _c("el-tag", [_vm._v("事故单更换教师待委派")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center", width: "400" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.statused <= 2 &&
                      (scope.row.spoType == 0 || scope.row.spoType == 1)
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isTest,
                                  expression: "isTest",
                                },
                              ],
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.apply(scope.row, scope.$index)
                                },
                              },
                            },
                            [_vm._v("排课")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (scope.row.statused >= 1 || scope.row.spoType > 1) &&
                      scope.row.spoType != 64 &&
                      scope.row.statused != 4
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.noapply(scope.row, scope.$index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            申请结课\n                        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (scope.row.statused < 1 && scope.row.spoType <= 1) ||
                      scope.row.spoType == 64 ||
                      scope.row.statused == 4
                        ? _c(
                            "el-button",
                            { attrs: { size: "mini", disabled: "" } },
                            [_vm._v("申请结课\n                        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.statused >= 2 &&
                      scope.row.statused <= 8 &&
                      scope.row.spoType != 64
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.give_time(scope.row, scope.$index)
                                },
                              },
                            },
                            [_vm._v("赠送时长")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.statused < 2 ||
                      scope.row.statused > 8 ||
                      scope.row.spoType == 64
                        ? _c(
                            "el-button",
                            { attrs: { size: "mini", disabled: "" } },
                            [_vm._v("赠送时长\n                        ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.uploadDropbox(scope.row, scope.$index)
                            },
                          },
                        },
                        [_vm._v("提交作业")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.modal, width: "50%" },
            on: {
              "update:visible": function ($event) {
                _vm.modal = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "课堂名称", prop: "title" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "title", $$v)
                        },
                        expression: "ruleForm.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                [
                  _c(
                    "div",
                    {
                      staticClass: "app-container",
                      staticStyle: { padding: "0" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开始时间", prop: "value1" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "picker-options": _vm.pciker1,
                              "value-format": "yyyy-MM-dd HH:mm",
                              placeholder: "开始日期",
                            },
                            model: {
                              value: _vm.ruleForm.value1,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "value1", $$v)
                              },
                              expression: "ruleForm.value1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "结束时间", prop: "value2" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm",
                              "picker-options": _vm.pciker2,
                              placeholder: "结束日期",
                            },
                            model: {
                              value: _vm.ruleForm.value2,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "value2", $$v)
                              },
                              expression: "ruleForm.value2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "课堂描述", prop: "description" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "description", $$v)
                        },
                        expression: "ruleForm.description",
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.btnstatus },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.btnval) + "\n                    ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.modal_time, width: "50%" },
            on: {
              "update:visible": function ($event) {
                _vm.modal_time = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "选择课堂", prop: "classroom" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择课堂" },
                        on: { change: _vm.change_classroom },
                        model: {
                          value: _vm.ruleForm.classroom,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "classroom", $$v)
                          },
                          expression: "ruleForm.classroom",
                        },
                      },
                      _vm._l(_vm.classrooms, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "赠送时长", prop: "times" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "220px", "margin-right": "10px" },
                      attrs: { type: "number" },
                      model: {
                        value: _vm.ruleForm.times,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "times", $$v)
                        },
                        expression: "ruleForm.times",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("MIN")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "赠送说明", prop: "explain" } },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.explain,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "explain", $$v)
                        },
                        expression: "ruleForm.explain",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.btnstatus },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm1("ruleForm")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.btnval) + "\n                    ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-pagination", {
          staticStyle: { "margin-top": "15px" },
          attrs: {
            "current-page": _vm.currentPage,
            "page-sizes": [15, 20],
            "page-size": _vm.limit,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.uploadDropBoxDialog,
              width: "50%",
              "before-close": _vm.uploadDropBoxDialogClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.uploadDropBoxDialog = $event
              },
            },
          },
          [
            _c("div", { staticClass: "coursewaressssss" }, [
              _c(
                "div",
                {
                  staticStyle: { "padding-left": "25px", "margin-top": "10px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        id: "selectfiles",
                        refs: "selectfiles",
                        type: "primary",
                      },
                    },
                    [_vm._v("选择文件")]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: { padding: "10px 0" },
                    attrs: { id: "ossfile" },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  { attrs: { id: "postfiles", type: "primary" } },
                  [_vm._v("上传")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findClassroomInfo = exports.changeTimeSwitch = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
// 改变课程表时间开关
// export const changeTimeSwitch = teacId =>
//     request({
//         otherUrl: `${BasicUrl}/api/teacher/teacFlow/v1/updFlowSchedule`,
//         method: "get"
//     });

var changeTimeSwitch = exports.changeTimeSwitch = function changeTimeSwitch(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacFlow/v1/updFlowSchedule"),
    method: "post",
    data: data
  });
};
//获取课堂课链接
var findClassroomInfo = exports.findClassroomInfo = function findClassroomInfo(row) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classRoom/findClassroomInfo/").concat(row.id),
    method: "get"
  });
};
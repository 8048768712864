var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: {
        visible: _vm.isShow,
        width: "420px",
        "append-to-body": true,
        "before-close": _vm.closeModal,
      },
    },
    [
      _c("h3", { staticClass: "title" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/home/upload.png"), alt: "" },
        }),
        _vm._v("提交申诉文件"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v("上传后请等待相关人员与您联系"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "file-content" }, [
        _c(
          "div",
          [
            _vm._v("上传申诉文件\n      "),
            _c("uploadFile", {
              ref: "upload",
              staticClass: "upload-box",
              on: { uploadFiles: _vm.uploadFiles },
            }),
            _vm._v(" "),
            _c("file-list", { attrs: { fileList: _vm.fileList } }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.fileList.length
        ? _c(
            "el-button",
            {
              staticClass: "confirm",
              attrs: { type: "primary", loading: _vm.loading, size: "mini" },
              on: { click: _vm.uploadApplyFile },
            },
            [_vm._v("确定上传")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dissertation-container" },
    [
      _c("G-Title"),
      _vm._v(" "),
      _c("G-Stage"),
      _vm._v(" "),
      _c("G-Table"),
      _vm._v(" "),
      _c("Adjust-Class-Modal", { ref: "adjustModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "minimap-container" }, [
    _c("img", {
      staticClass: "vague",
      attrs: { src: require("@/assets/images/hatch/miniMap.png") },
      on: { click: _vm.openModal },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
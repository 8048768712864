var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "g-table-right-row" }, [
    _c("div", { staticClass: "phase" }),
    _vm._v(" "),
    _c("div", { staticClass: "name" }, [
      _c("span", [_vm._v(_vm._s(_vm.data.className))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "type" }, [
      +_vm.data.paperStageAdd == _vm.AddClasses.YES
        ? _c("span", [_vm._v("加课")])
        : _c("div", [
            +_vm.data.type == _vm.StageClassesType.MUST
              ? _c("span", [_vm._v("必选课")])
              : +_vm.data.type == _vm.StageClassesType.SELECTABLE
              ? _c("span", [_vm._v("可选课")])
              : +_vm.data.type == _vm.StageClassesType.DISSERTATION
              ? _c("div", { staticClass: "class-type" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("span", { staticClass: "class-type-content" }, [
                    _vm._v("论文修改"),
                  ]),
                ])
              : _vm._e(),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "plan" },
      _vm._l(_vm.fmtJson(_vm.data.remark), function (str, i) {
        return _c("span", { key: i }, [_vm._v(_vm._s(str))])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "time" }, [
      _c("span", [_vm._v(_vm._s(_vm._f("fmtDate")(_vm.data.classStartTime)))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "classExplain" }, [
      +_vm.data.type !== _vm.StageClassesType.DISSERTATION
        ? _c("span", [_vm._v(_vm._s(_vm.ClassStatus2Val[+_vm.data.statused]))])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "classroomRemark" }, [
      _c("span", [_vm._v(_vm._s(_vm.data.classroomRemark))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "timeRange" }, [
      _c("span", [_vm._v(_vm._s(_vm._f("fmtMins")(_vm.data.classTime)))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "material" },
      [
        _c("G-Material", {
          attrs: {
            stageId: _vm.stageId,
            data: _vm.data,
            isDissertation: +_vm.data.type == _vm.StageClassesType.DISSERTATION,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "planClass" },
      [
        _c("G-Arrange-Class", {
          attrs: {
            stageId: _vm.stageId,
            stageType: _vm.stageType,
            data: _vm.data,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips-icon" }, [_c("span", [_vm._v("必")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "g-table-index-container" },
    [
      _c("G-Header"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.stageLoading,
              expression: "stageLoading",
            },
          ],
        },
        _vm._l(_vm.stageTables, function (tableData, i) {
          return _c("G-Table", { key: i, attrs: { table: tableData } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.isShow,
            size: "888px",
            "show-close": false,
            "with-header": false,
            "before-close": _vm.closeModal,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "drawer-box",
            },
            [
              _c("div", { staticClass: "drawer-header" }, [
                _c("img", {
                  staticClass: "left-back",
                  attrs: {
                    src: require("@/assets/images/paper/arrow.png"),
                    alt: "",
                  },
                  on: { click: _vm.closeModal },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class: [_vm.isAdd ? "cursor" : ""],
                    on: {
                      click: function ($event) {
                        _vm.isAdd
                          ? _vm.showModal("showEdit", _vm.stageDetail[0])
                          : ""
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.isAdd
                          ? _vm.dateRange
                          : _vm.formatTime(_vm.date, "Y年M月D日")
                      )
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "stage-content" },
                [
                  _vm.stageDetail.length
                    ? _vm._l(_vm.stageDetail, function (item) {
                        return _c("div", [
                          _c("div", { staticClass: "header-box" }, [
                            _c(
                              "div",
                              { staticClass: "info-box" },
                              [
                                _vm.showInput == item.id && _vm.isPermision
                                  ? _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.changeName(item)
                                        },
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name",
                                      },
                                    })
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "stage-name",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editStageName(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(item.name) +
                                            "\n                  "
                                        ),
                                        item.type == null && _vm.isPermision
                                          ? _c("i", {
                                              staticClass:
                                                "el-icon-delete  delete-icon cursor",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showModal(
                                                    "showDelete",
                                                    item
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                _vm._v(" "),
                                _c("div", { staticClass: "stage-time" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "cursor ",
                                      on: {
                                        click: function ($event) {
                                          _vm.isPermision
                                            ? _vm.showModal("showEdit", item)
                                            : ""
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("mdTime")(item.startDate)
                                        ) +
                                          " -\n                    " +
                                          _vm._s(_vm._f("mdTime")(item.endDate))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.isPermision
                                    ? _c("img", {
                                        staticClass: "cursor",
                                        attrs: {
                                          src: require("@/assets/images/paper/edit.png"),
                                          alt: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showModal(
                                              "showEdit",
                                              item
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _vm.isPermision
                                  ? _c("div", { staticClass: "opreate-box" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "new-opreate-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addClass(item)
                                            },
                                          },
                                        },
                                        [_vm._v("排课")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "new-opreate-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.showModal(
                                                "showTask",
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("管理任务")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "task-box" },
                            _vm._l(item.taskList, function (el, idx) {
                              return _c(
                                "div",
                                { staticClass: "task-item" },
                                [
                                  _c("div", { staticClass: "task-info" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "task-name",
                                        class: {
                                          "complate-color": el.status == 2,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              el.newFlag == 2
                                                ? "自定义任务"
                                                : el.title
                                            ) +
                                            _vm._s(
                                              el.newFlag == 2 ? idx + 1 : ""
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "wrap task-detail",
                                      class: {
                                        "complate-color": el.status == 2,
                                      },
                                      domProps: { innerHTML: _vm._s(el.name) },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _vm.isPermision
                                    ? _c(
                                        "div",
                                        { staticClass: "opreate-box" },
                                        [
                                          el.classroomFlag == 2 &&
                                          el.coursewareFlag == 2
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "new-opreate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showModal(
                                                        "showUpload",
                                                        item,
                                                        "pobing"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("上传课件")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          el.classroomFlag == 2
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "new-opreate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addClass(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("排课")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          [1, 8].includes(
                                            Number(el.additionalOperating)
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "opreate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showModal(
                                                        [3].includes(
                                                          _vm.version
                                                        )
                                                          ? "showNeedNew"
                                                          : "showNeed",
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      [3].includes(_vm.version)
                                                        ? "破冰课授课需求"
                                                        : "学生订单需求"
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          [2, 5, 6].includes(
                                            el.additionalOperating
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "opreate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showModal(
                                                        "showUpload",
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      el.additionalOperating ==
                                                        2
                                                        ? "上传任务材料"
                                                        : el.additionalOperating ==
                                                          5
                                                        ? "上传论文批改材料"
                                                        : "上传终版论文批改材料"
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          el.additionalOperating == 3
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "opreate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showModal(
                                                        "showLevel",
                                                        item,
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("批改论文评级")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          el.additionalOperating == 4
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "opreate-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showModal(
                                                        "showLevel",
                                                        item,
                                                        2
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("终极论文评级")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "complate-task",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.complateTask(el)
                                                },
                                              },
                                            },
                                            [
                                              el.status == 1
                                                ? _c(
                                                    "el-radio",
                                                    { attrs: { value: false } },
                                                    [
                                                      _vm._v(
                                                        "完成任务\n                      "
                                                      ),
                                                      _vm._v(" "),
                                                      el.type == 2 &&
                                                      el.additionalOperating ==
                                                        7 &&
                                                      el.newFlag == 1
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "tip",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  el.description
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : [
                                                            el.completeAssessment ==
                                                            1
                                                              ? _c(
                                                                  "p",
                                                                  {
                                                                    staticClass:
                                                                      "tip",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "请处理完成任务"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              el.status == 2
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "complate-btn",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-check",
                                                      }),
                                                      _vm._v("已完成"),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(el.taskClass, function (v, _idx) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "class-item",
                                        class: {
                                          "complate-color": [8, 6, 12].includes(
                                            v.status
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "task-info class-info",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "task-name" },
                                              [
                                                _vm._v(
                                                  "\n                      课堂：" +
                                                    _vm._s(v.name) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "class-time" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm._f("mdTime")(
                                                        v.startDate
                                                      )
                                                    ) +
                                                    " -\n                      " +
                                                    _vm._s(
                                                      _vm._f("mdTime")(
                                                        v.endDate
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _vm._v(" "),
                                                _vm.isPermision &&
                                                [1, 2].includes(v.status)
                                                  ? _c("img", {
                                                      staticClass: "cursor",
                                                      attrs: {
                                                        src: require("@/assets/images/paper/edit.png"),
                                                        alt: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addClass(
                                                            item,
                                                            true,
                                                            v
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "opreate-box" },
                                          [
                                            [2, 4].includes(v.status) &&
                                            _vm.isTeacher
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "new-opreate-btn",
                                                    staticStyle: {
                                                      "margin-top": "5px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.comeClass(v)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("进入课堂")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            [8, 6, 12].includes(v.status)
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "complate-btn",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-check",
                                                    }),
                                                    _vm._v("已完成"),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "task-box" },
                            _vm._l(item.classList, function (v, _idx) {
                              return _c(
                                "div",
                                {
                                  key: _idx,
                                  staticClass: "task-item",
                                  class: {
                                    "complate-color": [8, 6, 12].includes(
                                      v.status
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "task-info class-info" },
                                    [
                                      _c("div", { staticClass: "task-name" }, [
                                        _vm._v(
                                          "\n                    课堂：" +
                                            _vm._s(v.name) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "class-time" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("mdTime")(v.startDate)
                                            ) +
                                            " -\n                    " +
                                            _vm._s(
                                              _vm._f("mdTime")(v.endDate)
                                            ) +
                                            "\n                    "
                                        ),
                                        _vm._v(" "),
                                        _vm.isPermision &&
                                        [1, 2].includes(v.status)
                                          ? _c("img", {
                                              staticClass: "cursor",
                                              attrs: {
                                                src: require("@/assets/images/paper/edit.png"),
                                                alt: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addClass(
                                                    item,
                                                    true,
                                                    v
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "opreate-box" }, [
                                    [2, 4].includes(v.status) && _vm.isTeacher
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "new-opreate-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.comeClass(v)
                                              },
                                            },
                                          },
                                          [_vm._v("进入课堂")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    [8, 6, 12].includes(v.status)
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "line-height": "32px",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-check",
                                            }),
                                            _vm._v("已完成"),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      })
                    : [
                        _c("div", { staticClass: "no-task" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/paper/notask.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("你这一天没有论文辅导任务哦～")]),
                        ]),
                      ],
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("editTime", {
        attrs: {
          isShow: _vm.showEdit,
          isEdit: true,
          isAdd: _vm.isAdd,
          deadline: _vm.deadline,
          stage: _vm.curStage,
        },
        on: { getDetail: _vm.getStageDetail, closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("deleteModal", {
        attrs: {
          isShow: _vm.showDelete,
          curStage: _vm.curStage,
          isAdd: _vm.isAdd,
        },
        on: { getDetail: _vm.getStageDetail, closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("addClass", {
        attrs: {
          isShow: _vm.showClass,
          isAdd: _vm.isAdd,
          curStage: _vm.curStage,
          stageList: _vm.stageList,
          editClassTime: _vm.editClassTime,
          classsInfo: _vm.classsInfo,
          deadline: _vm.deadline,
        },
        on: { getDetail: _vm.getStageDetail, closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("editTask", {
        attrs: {
          isShow: _vm.showTask,
          isAdd: _vm.isAdd,
          curStage: _vm.curStage,
        },
        on: { getDetail: _vm.getStageDetail, closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("addNeed", {
        attrs: { isShow: _vm.showNeed, needs: _vm.needs },
        on: { getDetail: _vm.getStageDetail, closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("add-need-new", {
        attrs: {
          isShow: _vm.showNeedNew,
          deadline: _vm.deadline,
          needs: _vm.needs,
        },
        on: { getDetail: _vm.getStageDetail, closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("paperFiles", {
        ref: "paperFiles",
        attrs: { isShow: _vm.showUpload },
        on: { closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("paperLevel", {
        attrs: { isShow: _vm.showLevel, type: _vm.levelType },
        on: { getDetail: _vm.getStageDetail, closeModal: _vm.closeDialog },
      }),
      _vm._v(" "),
      _c("downloadApp", { ref: "downloadApp" }),
      _vm._v(" "),
      _c("goClassIn", { ref: "goClassIn" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
var _auth = require("@/utils/auth");
var _editAvator = _interopRequireDefault(require("@/views/account/editAvator"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    editAvator: _editAvator.default
  },
  data: function data() {
    var _this = this;
    return {
      teacherType: localStorage.getItem("teacType"),
      id: '',
      passwordvalidate: function passwordvalidate(rule, val, callback) {
        _this.passwordReg = false;
        var regs = /^[0-9a-zA-Z]{6,12}$/;
        if (!regs.test(val)) {
          _this.passwordReg = true;
          return callback(new Error('12位数字或大小写字母'));
        }
      },
      levelArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      passwordReg: false,
      updatePhoto: {
        show: false
      },
      imgcode: '',
      ulpolading: '',
      cansee: require("@/assets/images/person-see.png"),
      cantsee: require("@/assets/images/person-notsee.png"),
      cseeStatus: false,
      seeStatus: false,
      navdata: ['讲师信息', '重置密码'],
      isactives: 0,
      cropperDialog: false,
      teacshow: "",
      professionalCoursesNames: "",
      professionalCourses: "",
      tableDataBegin: [],
      activeName: "first",
      showa: false,
      showb: true,
      tableDataName: "",
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      detail: true,
      total: 0,
      filterTableDataEnd: [],
      dialogFormVisible2: false,
      idss: [],
      options: [],
      selectedOptions: [],
      selects: "",
      countryId: "",
      key: "",
      val: 1,
      page: "",
      name: "",
      superId: "",
      countrys: "",
      universitys: "",
      label: "",
      dialogStatus: "",
      specializedss: "",
      specializeds: "",
      ruleForm: {
        professionalIds: "",
        src: "",
        username: "",
        sex: "",
        birthday: "",
        eduName: "",
        type: "",
        grade: "",
        coachingSkills: "",
        proKnowledge: "",
        workingAttitude: "",
        jobSpecification: "",
        professionalCourses: "",
        mobile: "",
        password: '',
        newpassword: '',
        cnewpassword: '',
        nickName: "",
        wxAccount: ""
      }
    };
  },
  created: function created() {
    // this.$route.query.active == 'medal' && (this.isactives = 1)
  },
  mounted: function mounted() {
    this.view_detail();
    // this.getprofessionalCoursesName();
  },
  methods: {
    getbase64: function getbase64(e) {
      var _this2 = this;
      // 利用fileReader对象获取file
      var file = e.target.files[0];
      var filesize = file.size;
      var filename = file.name;
      // 2,621,440   2M
      if (filesize > 2101440) {
        // 图片大于2MB
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        _this2.imgcode = e.target.result;
      };
    },
    ulpoladImg: function ulpoladImg() {
      var _this3 = this;
      this.ulpolading = true;
      this.$axios({
        url: this.$api.baseURL + "/user/uploadBase64",
        method: 'post',
        data: {
          base64: this.imgcode
        }
      }).then(function (r) {
        _this3.ulpolading = false;
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this3.$router.push({
              path: '/login'
            });
          }
        }
        if (r.status == 200) {
          _this3.updatePhoto.show = false;
          _this3.$notify({
            title: '提示',
            type: 'success',
            message: '图片上传成功'
          });
          _this3.imgcode = '';
          _this3.$set(_this3.ruleForm.src, 'thumb', r.data.body.base64.prePath);
          _this3.$store.dispatch('changephoto', r.data.body.base64.prePath);
        }
      }).catch(function (er) {
        _this3.ulpolading = false;
      });
    },
    changeTap: function changeTap(index) {
      this.isactives = index;
    },
    handleClick: function handleClick(tab, first) {
      var val = tab.index;
      if (val == 0) {
        this.showa = false;
        this.showb = true;
      } else {
        this.showa = true;
        this.showb = false;
      }
    },
    cancel: function cancel() {
      this.ruleForm.password = '';
      this.ruleForm.newpassword = '';
      this.ruleForm.cnewpassword = '';
    },
    update: function update() {
      this.view_detail();
    },
    regInput: function regInput(val, text) {
      this.passwordReg = false;
      var regs = /^[0-9a-zA-Z]{6,12}$/;
      if (!regs.test(val)) {
        this.passwordReg = true;
        return this.$notify({
          title: '提示',
          type: 'error',
          message: text + '为6-12位数字或大小写字母'
        });
      }
    },
    editPassword: function editPassword() {
      var _this4 = this;
      var regs = /^[0-9a-zA-Z]{6,12}$/;
      this.passwordReg = false;
      console.log(regs.test(this.ruleForm.password));
      if (!regs.test(this.ruleForm.password) || !regs.test(this.ruleForm.newpassword) || !regs.test(this.ruleForm.cnewpassword)) {
        this.passwordReg = true;
      }
      if (this.passwordReg) {
        return this.$notify({
          title: '提示',
          type: "error",
          message: "请检查密码格式是否正确,格式为6-12位数字或大小写字母"
        });
      }
      if (this.ruleForm.newpassword === this.ruleForm.cnewpassword) {
        this.$axios({
          url: this.$api.baseURL + '/user/updatePws',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: window.localStorage.getItem('teactoken')
          },
          method: 'post',
          data: {
            oldPws: this.ruleForm.password,
            newPws: this.ruleForm.newpassword,
            userId: this.ruleForm.id
          }
        }).then(function (r) {
          if (r.data.status === 400) {
            return _this4.$notify({
              title: '提示',
              type: 'error',
              message: r.data.body.msg
            });
            if (r.data.body.code === 4011) {
              _this4.$router.push({
                path: "/login"
              });
            }
          }
          _this4.resetPasswordHandleClose();
          _this4.$notify({
            title: '提示',
            type: 'success',
            message: '更新密码成功'
          });
          setTimeout(function () {
            _this4.$router.push({
              path: '/login'
            });
            window.localStorage.clear();
          }, 2000);
        }).catch(function (er) {
          _this4.$notify({
            title: '提示',
            type: 'warning',
            message: er.data.body.msg
          });
        });
      } else {
        return this.$notify({
          title: '提示',
          type: 'warning',
          message: '两次密码输入不正确，请确认'
        });
      }
    },
    resetPasswordHandleClose: function resetPasswordHandleClose() {
      this.ruleForm.password = '';
      this.ruleForm.newpassword = '';
      this.ruleForm.cnewpassword = '';
    },
    view_detail: function view_detail(row, index) {
      var _this5 = this;
      this.$axios({
        url: this.$api.baseURL + "/user/getMyInfo"
      }).then(function (r) {
        console.log(r);
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this5.$router.push({
              path: "/login"
            });
          }
        }
        var detail = r.data.body;
        if (detail.type === 2) {
          _this5.teacshow = true;
        } else {
          _this5.teacshow = false;
        }
        _this5.id = r.data && r.data.body && r.data.body.id;
        if (detail.thumb) {
          // this.imgcode = "" + this.$api.baseURL + "" + detail.thumb + ""
          _this5.imgcode = "" + (detail.thumb.includes('https://') ? '' : _this5.$api.baseURL) + "" + detail.thumb + "";
        }
        _this5.$refs.ruleForm.model.src = detail;
        _this5.$refs.ruleForm.model.username = detail.username;
        _this5.$refs.ruleForm.model.nickName = detail.nickName;
        _this5.$refs.ruleForm.model.sex = detail.sex;
        _this5.$refs.ruleForm.model.level = detail.level;
        _this5.$refs.ruleForm.model.grade = "level" + detail.level + "";
        _this5.$refs.ruleForm.model.local = detail.local;
        _this5.$refs.ruleForm.model.birthday = detail.birthday;
        _this5.$refs.ruleForm.model.wxAccount = detail.wxAccount;
        _this5.$refs.ruleForm.model.mobile = detail.mobile;
        _this5.$refs.ruleForm.model.email = detail.email;
        _this5.$refs.ruleForm.model.universityName = detail.universityName;
        _this5.$refs.ruleForm.model.professionalCourses = [detail.professionalCoursesName];
        _this5.$refs.ruleForm.model.eduName = detail.eduName;
        //   vm.$refs.ruleForm.model.specializeds = [detail.specializeds];
        _this5.$refs.ruleForm.model.specializeds = detail.specializeds;
        _this5.$refs.ruleForm.model.description = detail.description;
        _this5.$refs.ruleForm.model.coachingSkills = "" + detail.teacRate.coachingSkills + "";
        _this5.$refs.ruleForm.model.proKnowledge = "" + detail.teacRate.proKnowledge + "";
        _this5.$refs.ruleForm.model.workingAttitude = "" + detail.teacRate.workingAttitude + "";
        _this5.$refs.ruleForm.model.jobSpecification = "" + detail.teacRate.jobSpecification + "";
        _this5.$refs.ruleForm.model.presenterTimeLimit = detail.presenterTimeLimit;
        _this5.$refs.ruleForm.model.maxLootNum = detail.maxLootNum;
        _this5.$refs.ruleForm.model.teachingExperience = detail.teachingExperience;
        _this5.$refs.ruleForm.model.type = "" + detail.type + "";
        _this5.$refs.ruleForm.model.hourlyWage = "" + detail.hourlyWage + "";
      }).catch(function (er) {});
    },
    editTeacher: function editTeacher(formName) {
      var _this6 = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          _this6.$axios({
            url: _this6.$api.baseURL + "/user/updateTeacher",
            method: "post",
            data: JSON.stringify({
              id: _this6.id,
              nickName: _this6.$refs.ruleForm.model.nickName,
              sex: _this6.$refs.ruleForm.model.sex,
              birthday: _this6.$refs.ruleForm.model.birthday,
              wxAccount: _this6.$refs.ruleForm.model.wxAccount,
              email: _this6.$refs.ruleForm.model.email,
              local: _this6.$refs.ruleForm.model.local
            }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(function (r) {
            if (r.data.status == 200) {
              _this6.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "success"
              });
              setTimeout(function () {
                window.location.reload();
              }, 1500);
            }
            if (r.data.status == 400) {
              _this6.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "warning"
              });
            }
          }).catch(function (er) {});
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    changeheader: function changeheader() {
      // this.updatePhoto.show = true;
      console.log('this.imgcode', this.imgcode);
      this.$refs.editAvator.show(this.imgcode);
    },
    imgOnError: function imgOnError(event) {
      console.log('event', event);
      var errorImg = this.ruleForm.sex == "MALE" ? require('@/assets/images/iconmale.png') : require('@/assets/images/iconfemale.png');
      var img = event.srcElement; //已经弃用
      img.src = errorImg; //data中的图片
      img.onerror = null; //防止闪图
    }
  }
};
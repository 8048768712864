var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "medal-dialog",
      attrs: {
        visible: _vm.isShow,
        width: "650px",
        "close-on-click-modal": false,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
      },
    },
    [
      _c("div", { staticClass: "\n  title-line" }, [
        _vm._v("尊敬的讲师，【万能讲师备课平台】发布"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-area" },
        [
          _c("div", { staticClass: "tip" }, [
            _vm._v(
              "本次更新AI智能一体化备课中心——万能讲师备课平台，请讲师更新后前往使用"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-text" }, [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("@/assets/courseware/time.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "second-title" }, [
                _vm._v("更新时间："),
              ]),
              _vm._v(" 2023年10月30日13:00"),
              _c("span", [
                _vm._v("（本公告所有时间均为"),
                _c("span", { staticClass: "red-text" }, [_vm._v("北京时间")]),
                _vm._v("）"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("@/assets/courseware/gengxin.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "second-title" }, [
                _vm._v("更新范围："),
              ]),
              _vm._v(" 万能讲师网页端"),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("@/assets/courseware/fangshi.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "second-title" }, [
                _vm._v("更新方式："),
              ]),
              _vm._v(" 不停服务器更新\n      "),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("@/assets/courseware/neirong.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "second-title" }, [_vm._v("更新内容")]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", [
                _vm._v(
                  "• 讲师需在万能讲师备课平台中完成所有【排课】【规划表】任务和课件备课准备"
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("•"),
                _c("span", { staticClass: "blue-text" }, [
                  _vm._v("【课堂表】"),
                ]),
                _vm._v(
                  "已迁移至万能讲师备课中心，请在备课中心进行排课、上传课件和进入课堂功能"
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("•"),
                _c("span", { staticClass: "blue-text" }, [
                  _vm._v("【规划表】"),
                ]),
                _vm._v("已迁移至万能讲师备课中心，方便讲师进行备课任务"),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "• 新增课堂&订单备课区域，讲师可以在线制作课件并一键上传课堂"
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "• 备课草稿也可以储存在云端，讲师可以切换不同设备进行备课"
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("• 所有"),
                _c("span", { staticClass: "blue-text" }, [
                  _vm._v("进行中的订单"),
                ]),
                _vm._v(
                  "（除毕业大论文外）将直接被同步到【万能讲师备课中心】进行备课与排课"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("@/assets/courseware/tixing.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "second-title" }, [
                _vm._v("相关提醒："),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("•更新后，"),
              _c("span", { staticClass: "blue-text" }, [
                _vm._v("【万能讲师备课平台】"),
              ]),
              _vm._v("将会是"),
              _c("span", [_vm._v("【排课】")]),
              _vm._v("与"),
              _c("span", [_vm._v("【上传课件至课堂】")]),
              _vm._v("的唯一入口"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "•备课平台将很大程度上提高讲师效率，请讲师通过平台备课后进行云编辑最后可一键上传至课堂"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _c("span", { staticClass: "blue-text" }, [
              _vm._v("以下为详细介绍请仔细阅读"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "img-content" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/help/one.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: { src: require("@/assets/images/help/two.png"), alt: "" },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: require("@/assets/images/help/three.png"),
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.goToFollow } },
            [_vm._v("前往指引")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
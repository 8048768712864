"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _plupload = _interopRequireDefault(require("plupload"));
var _jquery = _interopRequireDefault(require("jquery"));
var _failList = _interopRequireDefault(require("@/views/account/newHome/failList"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    failList: _failList.default
  },
  data: function data() {
    var _this2 = this,
      _ref;
    return _ref = {
      activeName: "1",
      all: false,
      tableDataBegin: [],
      tableDataName: "",
      tableDataEnd: [],
      modal: false,
      modal_time: false,
      total: 0,
      limit: 15,
      idss: [],
      stautsText: {
        0: '待接受（客服派单）',
        1: '待排课',
        2: '已排课',
        4: '申请节课中',
        8: '申请异常订单中',
        16: '已结课',
        32: '异常结束'
      },
      courseId: "",
      val: 1,
      page: "",
      rules: {},
      finishedCourseLoading: false,
      uploader: null,
      uploadFilesLength: [],
      uploadButtonEvent: null,
      uploadFilesArray: [],
      formdata: new FormData(),
      // 创建一个上传图片用的new formdata
      updataFiles: false,
      uploadImgTimes: 0,
      uploadUseData: {},
      showUploadProgress: false,
      uploadStatus: "exception",
      uploadPercent: 20,
      dropboxLoading: false,
      uploadDropBoxDialog: false,
      uploadDropBoxRadio: "11",
      isTest: false
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "uploader", {}), "pciker1", {
      disabledDate: function disabledDate(time) {
        return time.getTime() < Date.now() - 8.64e7;
      }
    }), "pciker2", {
      disabledDate: function disabledDate(time) {
        var beginDateVal = _this2.ruleForm.value1;
        if (beginDateVal) {
          return time.getTime() < beginDateVal;
        }
      }
    }), "uploadStatus", "exception"), "uploadPercent", 20), "showUploadProgress", false), "span", 0), "dropboxCourse", false), "fileListnum", 0), "fileList", []), (0, _defineProperty2.default)(_ref, "uploadImgTimes", 0);
  },
  created: function created() {
    this.activeName = this.$route.query.activeName || '1';
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    seeCouse: function seeCouse(row, index) {
      if (row.spoType === 64) {
        var Logistics2 = this.$router.resolve({
          path: "/bigClassRoom",
          query: {
            id: row.courseId,
            parentId: null
          }
        });
        window.open(Logistics2.href);
      } else {
        var type = 'daobi';
        if (row.courseType === 4) {
          type = 'wenyaold';
        }
        if (row.spoType === 69) {
          type = 'wenya';
        }
        var Logistics1 = this.$router.resolve({
          path: "/sellerDetail",
          query: {
            id: row.courseId,
            type: type
          }
        });
        window.open(Logistics1.href);
      }
    },
    teacinfo: function teacinfo() {
      var _this3 = this;
      this.$axios({
        url: this.$api.baseURL + '/user/getMyInfo'
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this3.$router.push({
              path: '/login'
            });
          }
        }
        var detail = r.data.body;
        if (detail.type == 2) {
          _this3.all = false;
        } else {
          _this3.all = true;
        }
      }).catch(function (er) {});
    },
    deleteFile: function deleteFile(item) {
      var toremove = '';
      for (var i in this.uploadFilesLength) {
        if (this.uploadFilesLength[i].id === item.id) {
          toremove = i;
        }
      }
      this.uploader.files.splice(toremove, 1);
      this.uploadFilesLength.splice(toremove, 1);
    },
    uploadDropbox: function uploadDropbox(val, id) {
      var _this4 = this;
      this.uploadDropBoxDialog = true;
      this.uploadUseData = val;
      function GUID() {
        this.date = new Date(); /* 判断是否初始化过，如果初始化过以下代码，则以下代码将不再执行，实际中只执行一次 */
        if (typeof this.newGUID !== "function") {
          /* 生成GUID码 */
          GUID.prototype.newGUID = function () {
            this.date = new Date();
            var guidStr = "";
            var sexadecimalDate = this.hexadecimal(this.getGUIDDate(), 16);
            var sexadecimalTime = this.hexadecimal(this.getGUIDTime(), 16);
            for (var i = 0; i < 9; i++) {
              guidStr += Math.floor(Math.random() * 16).toString(16);
            }
            guidStr += sexadecimalDate;
            guidStr += sexadecimalTime;
            while (guidStr.length < 32) {
              guidStr += Math.floor(Math.random() * 16).toString(16);
            }
            return this.formatGUID(guidStr);
          };
          /* * 功能：获取当前日期的GUID格式，即8位数的日期：19700101 * 返回值：返回GUID日期格式的字条串 */
          GUID.prototype.getGUIDDate = function () {
            return this.date.getFullYear() + this.addZero(this.date.getMonth() + 1) + this.addZero(this.date.getDay());
          };
          /* * 功能：获取当前时间的GUID格式，即8位数的时间，包括毫秒，毫秒为2位数：12300933 * 返回值：返回GUID日期格式的字条串 */
          GUID.prototype.getGUIDTime = function () {
            return this.addZero(this.date.getHours()) + this.addZero(this.date.getMinutes()) + this.addZero(this.date.getSeconds()) + this.addZero(parseInt(this.date.getMilliseconds() / 10));
          };
          /* * 功能: 为一位数的正整数前面添加0，如果是可以转成非NaN数字的字符串也可以实现 * 参数: 参数表示准备再前面添加0的数字或可以转换成数字的字符串 * 返回值: 如果符合条件，返回添加0后的字条串类型，否则返回自身的字符串 */
          GUID.prototype.addZero = function (num) {
            if (Number(num).toString() != "NaN" && num >= 0 && num < 10) {
              return "0" + Math.floor(num);
            } else {
              return num.toString();
            }
          };
          /*  * 功能：将y进制的数值，转换为x进制的数值 * 参数：第1个参数表示欲转换的数值；第2个参数表示欲转换的进制；第3个参数可选，表示当前的进制数，如不写则为10 * 返回值：返回转换后的字符串 */
          GUID.prototype.hexadecimal = function (num, x, y) {
            if (y != undefined) {
              return parseInt(num.toString(), y).toString(x);
            } else {
              return parseInt(num.toString()).toString(x);
            }
          };
          /* * 功能：格式化32位的字符串为GUID模式的字符串 * 参数：第1个参数表示32位的字符串 * 返回值：标准GUID格式的字符串 */
          GUID.prototype.formatGUID = function (guidStr) {
            var str1 = guidStr.slice(0, 8) + "-";
            var str2 = guidStr.slice(8, 12) + "-";
            var str3 = guidStr.slice(12, 16) + "-";
            var str4 = guidStr.slice(16, 20) + "-";
            var str5 = guidStr.slice(20);
            return str1 + str2 + str3 + str4 + str5;
          };
        }
      }
      var accessid, host, policyBase64, signature, g_dirname, uploadFileName, url, guid, uploadFilesTimes;
      guid = new GUID();
      uploadFilesTimes = 0;
      if (this.uploadUseData.scTypeed === 4) {
        url = this.$api.baseURL + "/oss/getAliOSSUploadSignWyard?dir=" + "courseware/" + guid.newGUID() + "/";
      } else {
        url = this.$api.baseURL + "/oss/getAliOSSUploadSign?dir=" + "courseware/" + guid.newGUID() + "/";
      }
      this.$axios({
        url: url
      }).then(function (r) {
        accessid = r.data.body.accessid;
        host = r.data.body.host;
        policyBase64 = r.data.body.policy;
        signature = r.data.body.signature;
        g_dirname = r.data.body.dir;
        function formatFileName(up, filename) {
          uploadFileName = encodeURIComponent(filename);
          // console.log(uploadFileName);
          set_upload_param(up, filename, false);
        }
        function set_upload_param(up, filename, ret) {
          if (ret) {
            formatFileName(up, filename);
          }
          var new_multipart_params = {
            key: g_dirname + filename,
            policy: policyBase64,
            OSSAccessKeyId: accessid,
            success_action_status: "200",
            // 让服务端返回200,不然，默认会返回204
            signature: signature
          };
          up.setOption({
            url: host,
            multipart_params: new_multipart_params
          });
          up.start();
        }
        var _this = _this4;
        _this4.uploader = new _plupload.default.Uploader({
          runtimes: "html5,flash,silverlight,html4",
          browse_button: "selectfiles",
          flash_swf_url: "../../../public/libs/Moxie.swf",
          silverlight_xap_url: "../../../public/libs/Moxie.xap",
          url: "http://oss.aliyuncs.com",
          init: {
            PostInit: function PostInit() {
              // document.getElementById("ossfile").innerHTML =
              //     "";
              document.getElementById("postfiles").onclick = function () {
                if (_this.uploadDropBoxRadio == '11') {
                  set_upload_param(_this.uploader, '', false);
                  return false;
                } else {
                  if (_this.uploadUseData.hasPastPaper) {
                    if (_this.uploader.files.length == 0) {
                      _this.$message.error('请选择文件');
                      return false;
                    } else {
                      set_upload_param(_this.uploader, '', false);
                      return false;
                    }
                  }
                }
              };
            },
            FilesAdded: function FilesAdded(up, files) {
              for (var i in files) {
                _this.uploadFilesLength.push(files[i]);
              }
              _plupload.default.each(files, function (file) {
                file.filesize = _plupload.default.formatSize(file.size).split(" ")[0] + _plupload.default.formatSize(file.size).split(" ")[1].toUpperCase();
              });
            },
            BeforeUpload: function BeforeUpload(up, file) {
              this.updataFiles = true;
              set_upload_param(up, file.name, true);
            },
            UploadProgress: function UploadProgress(up, file) {
              _this.uploadFilesLength.forEach(function (item) {
                if (item.id === file.id) {
                  item.progressshow = true;
                  item.percent = file.percent;
                }
              });
            },
            FileUploaded: function FileUploaded(up, file, info) {
              if (info.status === 200) {
                uploadFilesTimes++;
                _this.uploadFilesArray = [];
                var aaa = {
                  cdId: _this.uploadUseData.courseId,
                  groupId: _this.uploadDropBoxRadio,
                  name: file.name,
                  url: host + "/" + g_dirname + uploadFileName
                };
                _this.uploadFilesArray.push(aaa);
                _this.$axios({
                  url: _this.$api.baseURL + "/courseware/saveWithOssUrl",
                  method: "post",
                  data: JSON.stringify(_this.uploadFilesArray),
                  headers: {
                    "Content-Type": "application/json"
                  }
                }).then(function (r) {
                  _this.updataFiles = false;
                  if (r.data.status === 200) {
                    _this.$message({
                      type: "success",
                      message: "文件上传成功"
                    });
                    // if (
                    //     uploadFilesTimes ===
                    //     _this.uploadFilesLength
                    //         .length
                    // ) {
                    setInterval(function () {
                      window.location.reload();
                    }, 1000);
                    // }
                  }
                }).catch(function (er) {
                  _this.updataFiles = false;
                });
              } else {
                document.getElementById(file.id).getElementsByTagName("b")[0].innerHTML = info.response;
              }
            },
            Error: function Error(up, err) {
              _this.$message({
                type: "error",
                message: "文件上传失败， 请刷新重试"
              });
              _this.updataFiles = false;
              _this.uploader = this.$plup.destroy();
              _this.uploadDropBoxDialog = false;
              _this.uploadFilesLength = [];
            }
          }
        });
        _this4.uploader.init();
      }).catch(function (er) {});
    },
    uploadDropBoxDialogClose: function uploadDropBoxDialogClose() {
      this.uploadDropBoxDialog = false;
      this.uploadFilesLength = [];
      this.uploader.destroy();
    },
    see: function see(row, index) {
      if (row.spoType === 64) {
        var Logistics2 = this.$router.resolve({
          path: "/bigClassRoom",
          query: {
            id: row.cdId,
            parentId: null
          }
        });
        window.open(Logistics2.href);
      } else {
        var type = 'daobi';
        if (row.courseType === 4) {
          type = 'wenya';
        }
        var Logistics1 = this.$router.resolve({
          path: "/sellerDetail",
          query: {
            id: row.cdId,
            type: type
          }
        });
        window.open(Logistics1.href);
      }
    },
    getTableData: function getTableData() {
      var _this5 = this;
      this.finishedCourseLoading = true;
      this.$axios({
        url: this.$api.baseURL + "/order/list",
        params: {
          page: this.val,
          limit: this.limit,
          statused: "48",
          key: this.tableDataName,
          history: 1
        }
      }).then(function (r) {
        _this5.finishedCourseLoading = false;
        _this5.tableDataEnd = r.data.body.list;
        _this5.total = r.data.body.totalCount;
      }).catch(function (er) {
        _this5.finishedCourseLoading = false;
      });
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.val = 1;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.init();
    },
    search: function search() {
      this.val = 1;
      this.init();
    },
    handleClick: function handleClick() {
      this.tableDataName = '';
      this.val = 1;
      this.limit = 15;
      this.init();
    },
    init: function init() {
      var _this6 = this;
      if (this.activeName == 1) {
        this.teacinfo();
        this.getTableData();
      } else {
        this.$nextTick(function () {
          _this6.$refs.failList && _this6.$refs.failList.init({
            page: _this6.val,
            limit: _this6.limit,
            key: _this6.tableDataName
          });
        });
      }
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { "overflow-y": "auto" },
    },
    [
      _c(
        "section",
        { staticClass: "calendar-app-container" },
        [
          _c(
            "ds-calendar-app",
            {
              ref: "calendarApp",
              staticClass: "calendar-app",
              attrs: {
                modelType: 2,
                hasModel: false,
                validTimes: _vm.daytimeRange,
                value: _vm.defaultEvents,
                eventHeight: 143,
              },
              on: {
                picked: _vm.onPicked,
                "view-day": _vm.viewDay,
                adding: _vm.handleAddDay,
                "mouse-up-event": _vm.viewDay,
                "mouse-click-up": _vm.viewDay,
              },
              scopedSlots: _vm._u([
                {
                  key: "eventTitle",
                  fn: function (ref) {
                    var slotData = ref.slotData
                    var details = ref.details
                    return [
                      details.startDate
                        ? [
                            details.isClass
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "class-item  ellipsis",
                                    staticStyle: { background: "#fff" },
                                  },
                                  [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "Start:" +
                                          _vm._s(
                                            _vm._f("onlyTime")(
                                              details.startDate
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(details.name))]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "ellipsis",
                                    style: {
                                      color: _vm.fontColor[details.status],
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "font-size": "10px" },
                                        style: {
                                          color: _vm.fontColor[details.status],
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("onlyTime")(
                                              details.startDate
                                            )
                                          ) + "\n                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                        【" +
                                        _vm._s(details.name) +
                                        "】\n                        "
                                    ),
                                    details.fixedTaskNum
                                      ? _c(
                                          "span",
                                          {
                                            style: {
                                              color:
                                                _vm.fontColor[details.status],
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "有" +
                                                _vm._s(details.fixedTaskNum) +
                                                "项固定任务"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                          ]
                        : [_c("span", [_vm._v(" ")])],
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "menuRight" }, [
                _c(
                  "span",
                  {
                    staticClass: "day-btn cursor",
                    on: { click: _vm.showToday },
                  },
                  [_vm._v("今天")]
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog delete-modal",
          attrs: {
            visible: _vm.showConfirm,
            width: "500px",
            "before-close": _vm.closeDialog,
            "append-to-body": "",
          },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/login-tips.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("时间超期")]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "\n            阶段时间已超过该订单周期，是否超期安排\n            "
            ),
            _c("div", [
              _vm._v(
                "\n                若超期安排，请务必联系师资管理人员申报，否则讲师负责排期失误责任\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "span",
                {
                  staticClass: "cancle-btn",
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd(_vm.addParams)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "confirm-btn",
                  on: {
                    click: function ($event) {
                      _vm.showConfirm = false
                    },
                  },
                },
                [_vm._v("我再想想")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("taskDetail", {
        attrs: {
          isShow: _vm.showDetail,
          date: _vm.currentymd,
          newStage: _vm.stageDetail,
          isAdd: _vm.isAdd,
          needs: _vm.needs,
          deadline: _vm.deadline,
        },
        on: { getStageList: _vm.init, closeModal: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
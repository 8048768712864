var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detial-warp" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detial-msg-box detial-msg-height" },
        _vm._l(_vm.needsList, function (item, keys) {
          return _c(
            "div",
            {
              key: keys,
              staticClass: "item-card ",
              class: [
                keys % 2 == 1 ? "hasborder" : "",
                _vm.needsList.length == 1 ? "width100" : "width50",
              ],
            },
            [
              item.createBy == _vm.teacId && item.createSource == 1
                ? _c("i", {
                    staticClass: "el-icon-edit-outline",
                    staticStyle: {
                      "font-size": "25px",
                      position: "absolute",
                      right: "30px",
                      top: "20px",
                      color: "#409eff",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.editNeed(item)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _c("span", { staticClass: "labels" }, [_vm._v("填写人")]),
                  _c("span", { staticClass: "text-box" }, [
                    _vm._v(_vm._s(item.createUserName)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _c("span", { staticClass: "labels" }, [_vm._v("填写时间")]),
                  _c("span", { staticClass: "text-box" }, [
                    _vm._v(_vm._s(item.createDate)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(item.demandDetail, function (el, idx) {
                return _c("div", { staticClass: "word-wrap" }, [
                  el.value == 9
                    ? _c("div", [
                        _c("span", { staticClass: "labels" }, [
                          _vm._v(_vm._s(el.question)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-box" }, [
                          _vm._v(_vm._s(_vm.topicList[el.answer.topic]) + " "),
                          [2, 3].includes(Number(el.answer.topic))
                            ? _c("span", [
                                _vm._v("：" + _vm._s(el.answer.topicName)),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : el.value == 3
                    ? _c("div", [
                        _c("span", { staticClass: "labels" }, [
                          _vm._v(_vm._s(el.question)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-box" },
                          _vm._l(el.answer, function (needs, nidx) {
                            return _c("div", { key: nidx }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(nidx + 1) +
                                  "、" +
                                  _vm._s(needs) +
                                  "\n            "
                              ),
                            ])
                          }),
                          0
                        ),
                      ])
                    : el.value == 4
                    ? _c("div", [
                        _c("span", { staticClass: "labels" }, [
                          _vm._v("\n            是否有特殊软件/语言要求:"),
                        ]),
                        _vm._v(" "),
                        el.answer.require == 1
                          ? _c("span", { staticClass: "text-box" }, [
                              _vm._v(_vm._s(el.answer.remark)),
                            ])
                          : _c("span", { staticClass: "text-box" }, [
                              _vm._v("暂无"),
                            ]),
                      ])
                    : el.value == 5
                    ? _c("div", [
                        _c("span", { staticClass: "labels" }, [
                          _vm._v(" 订单文章的reference style"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-box" },
                          _vm._l(el.answer, function (need, idx) {
                            return _c(
                              "span",
                              { key: idx },
                              [
                                need.type == 9
                                  ? [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(need.remark) +
                                          "\n              "
                                      ),
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(need.value) +
                                          "\n              "
                                      ),
                                    ],
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      idx <= el.answer.length - 2 ? "、" : ""
                                    ) +
                                    "\n            "
                                ),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ])
                    : el.value == 6
                    ? _c("div", [
                        _c("span", { staticClass: "labels" }, [
                          _vm._v(_vm._s(el.question)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "text-box" },
                          [
                            _vm._l(el.answer.fileList, function (file, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "file-name",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(file.url)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(file.name) +
                                      "\n            "
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm._l(
                              el.answer.linkList,
                              function (link, linkindex) {
                                return _c(
                                  "div",
                                  {
                                    key: linkindex + "$",
                                    staticClass: "file-name",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downFile(link.answer)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(link.answer) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    : el.value == 7
                    ? _c("div", [
                        _c("span", { staticClass: "labels" }, [
                          _vm._v(_vm._s(el.question)),
                        ]),
                        _vm._v(" "),
                        el.answer.open
                          ? _c("div", { staticClass: "text-box" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    (
                                      "" +
                                      el.answer.reading +
                                      (el.answer.remark
                                        ? "、" + el.answer.remark
                                        : "")
                                    ).replace("其他、", "")
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _c("div", [
                        _c("span", { staticClass: "labels" }, [
                          _vm._v(_vm._s(el.question)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-box" }, [
                          _vm._v(_vm._s(el.answer)),
                        ]),
                      ]),
                ])
              }),
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("add-need", {
        attrs: {
          isShow: _vm.isShow,
          isAdd: _vm.isAdd,
          id: _vm.id,
          "needs-info": _vm.needsInfo,
        },
        on: { getNeeds: _vm.getNeeds, closeModal: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("add-need-new", {
        attrs: {
          isShow: _vm.isShowNew,
          isAdd: _vm.isAdd,
          id: _vm.id,
          deadline:
            _vm.orderDetail.studPurchaseOrder &&
            _vm.orderDetail.studPurchaseOrder.endCourseTime,
          "needs-info": _vm.needsInfo,
        },
        on: { getNeeds: _vm.getNeeds, closeModal: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [
      _vm._v("学生需求完善 "),
      _c("span", { staticStyle: { color: "#ff001d" } }, [
        _vm._v("(请辅导讲师完善)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
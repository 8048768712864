var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: {
        visible: _vm.isShow,
        width: "750px",
        "append-to-body": true,
        "before-close": _vm.closeModal,
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("Reflection Sheet")]),
      _vm._v(" "),
      _c("div", { staticClass: "sec-title" }, [_vm._v("一、 反馈须知")]),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _vm._v("\n      尊敬的各位讲师请"),
          _c("span", [_vm._v("仔细阅读")]),
          _vm._v("以下"),
          _c("span", [_vm._v("须知")]),
          _vm._v(":\n\n    "),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n      1. 本张反馈表格旨在了解各位讲师出现问题的订单的原因，给予讲师方一次申诉的机会，预计会占用您15分钟以上时间。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n      2. 我们希望您认真撰写下方表单，请注意在我方师资人员邀请您填写表单开始，请24小时之内回传，如遇到特殊情况不能按时回传，请务必与师资人员联系。\n      "
          ),
          _c("div", [
            _vm._v(
              "\n        1）请确保您理解此份表单的目的是为了改善我们的服务质量，并期待我们的合作关系更加稳固。\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n        2）在填写开始之前您可以询问此次订单内容得分以及feedback，我方师资人员会相应给予相应文件反馈。\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("3）当填写完成，请立即回传给我方师资人员。")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n      3. 我们期待您填写的每一个原因都有对应截图或者文件佐证您的观点。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n      4. 我方师资人员会根据每一位讲师的反馈情况制定出相应的结论，包括但不限于"
          ),
          _c("span", [
            _vm._v("多倍扣款/取消扣款/给予一部分薪酬，甚至全额发放。"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("\n      5. "),
          _c("span", [
            _vm._v("请注意当前的售后判责不是最终结果，请以申诉结果为准。"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _vm._v("请各位讲师仔细阅读，祝各位生活顺利。"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show() {
      this.isShow = true;
    },
    handleClose: function handleClose() {
      this.isShow = false;
    },
    //打开兼职讲师等级弹框
    showLevel: function showLevel() {
      this.$emit('showModel', '讲师等级');
      this.handleClose();
    }
  }
};
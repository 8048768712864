"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    handleClose: function handleClose() {
      this.$emit('closeNotice');
    },
    //打开兼职讲师等级弹框
    showLevel: function showLevel() {
      this.$emit('showModel', '讲师等级');
      this.handleClose();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.winUrl = exports.scheduleWebUrl = exports.publicOrderDetailUrl = exports.newCrmUrl = exports.mkWinURL = exports.mkMacURL = exports.mkClassScheme = exports.macUrl = exports.default = exports.crmUrl = exports.coursewareUrl = exports.RequestUrl = exports.Ratinglink = exports.LinkUrl = exports.EntryClassUrl = exports.BasicUrl = void 0;
var DefaultCfg = {
  dev: {
    // baseURL: "https://testwww.classbro.cn/api/teacher", //接口地址测试
    baseURL: "https://testteacherapi.classbro.com/routapi/api/teacher",
    //接口地址测试
    url: "http://test.classbro.cn/",
    //(this.$api.url + 'crm/v2.0/im_web/session 用到了)(现在好像已经不用了)
    wytoken: "VkGLtspm1L3HGhi42i_AiPKEqXBd-pkz",
    wybaseUrl: "https://149.129.137.141/dbwy/",
    //好像没有用
    redirectURL: "http://test.classbro.cn/",
    //老crm接口地址
    newCrmUrl: 'http://testconsoleapi.classbro.cn/routapi/',
    //新crm上传接口
    navBarWidth: false
  },
  pro: {
    // baseURL: "https://www.classbro.com/api/teacher", //正式
    baseURL: "https://teacherapi.classbro.com/routapi/api/teacher",
    //正式
    url: "https://www.classbro.com/",
    wytoken: "VkGLtspm1L3HGhi42i_AiPKEqXBd-pkz",
    wybaseUrl: "https://149.129.137.141/dbwy/",
    redirectURL: "https://www.classbro.com/",
    newCrmUrl: " https://consoleapi.classbro.com/routapi/",
    navBarWidth: false
  },
  preview: {
    // baseURL: "https://preview.classbro.com/api/teacher", //预发布
    baseURL: "https://previewconsoleapi.classbro.com/routapi/api/teacher",
    //预发布
    url: "https://preview.classbro.com/",
    wytoken: "VkGLtspm1L3HGhi42i_AiPKEqXBd-pkz",
    wybaseUrl: "https://149.129.137.141/dbwy/",
    redirectURL: "https://preview.classbro.com/",
    newCrmUrl: 'https://previewconsoleapi.classbro.com/routapi',
    //新crm上传接口
    navBarWidth: false
  },
  local: {
    /* 本地目前用的测试，如果接口调用本地，就改这个文件 */
    baseURL: "https://testapi.classbro.com/api/teacher",
    //测试
    url: "https://testapi.classbro.com/",
    wytoken: "VkGLtspm1L3HGhi42i_AiPKEqXBd-pkz",
    wybaseUrl: "https://149.129.137.141/dbwy/",
    redirectURL: "https://testapi.classbro.com/",
    navBarWidth: false
  }
};
var Environment = {
  DEV: "dev",
  PRO: "pro",
  PREVIEW: "preview",
  LOCAL: "local"
};

/* VVVVVVVVVVVVVVVVVV 只调整这个开关 VVVVVVVVVVVVVVVVVV */
/* VVVVVVVVVVVVVVVVVV 只调整这个开关 VVVVVVVVVVVVVVVVVV */
/* VVVVVVVVVVVVVVVVVV 只调整这个开关 VVVVVVVVVVVVVVVVVV */
/* VVVVVVVVVVVVVVVVVV 只调整这个开关 VVVVVVVVVVVVVVVVVV */
/* VVVVVVVVVVVVVVVVVV 只调整这个开关 VVVVVVVVVVVVVVVVVV */
/* VVVVVVVVVVVVVVVVVV 只调整这个开关 VVVVVVVVVVVVVVVVVV */

/**
 * Environment.LOCAL 本地
 * Environment.DEV 测试环境
 * Environment.PREVIEW 预发环境
 * Environment.PRO 正式环境
 */
// const Switch = Environment.PREVIEW;
console.log('VUE_APP_ENV', process.env.VUE_APP_ENV);
var Switch = Environment[process.env.VUE_APP_ENV];

/* ^^^^^^^^^^^^^^^^^^ 只调整这个开关 ^^^^^^^^^^^^^^^^^^ */
/* ^^^^^^^^^^^^^^^^^^ 只调整这个开关 ^^^^^^^^^^^^^^^^^^ */
/* ^^^^^^^^^^^^^^^^^^ 只调整这个开关 ^^^^^^^^^^^^^^^^^^ */
/* ^^^^^^^^^^^^^^^^^^ 只调整这个开关 ^^^^^^^^^^^^^^^^^^ */
/* ^^^^^^^^^^^^^^^^^^ 只调整这个开关 ^^^^^^^^^^^^^^^^^^ */
var _default = exports.default = DefaultCfg[Switch];
var Config = {
  dev: {
    //   RequestUrl: "https://testapi.classbro.com", //测试
    //   BasicUrl: "https://testapi.classbro.com",
    RequestUrl: "https://testteacherapi.classbro.com/routapi",
    //测试
    BasicUrl: "https://testteacherapi.classbro.com/routapi",
    /* 老crm接口 地址 */
    newCrmUrl: "https://testconsoleapi.classbro.com/routapi",
    EntryClassUrl: "https://testapi.classbro.com",
    //课堂训练场（目前没用了）
    Ratinglink: "https://testapi.classbro.com/survey/#/activity?id=15&hideBtn=1",
    LinkUrl: "https://testconsole.classbro.com",
    /* 跳转页面 地址 */
    crmUrl: "https://testconsoleapi.classbro.com/crm",
    /*规划表页面 地址 */
    scheduleWebUrl: 'https://testconsole.classbro.cn/schedule/#/',
    /* 美刻唤起 地址 */
    mkClassScheme: 'wnroombox://enterclass/',
    mkWinURL: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/classbro/teacher/win/test/ClassbroTeacher.exe',
    mkMacURL: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/classbro/teacher/mac/test/ClassbroTeacher.dmg',
    coursewareUrl: 'https://testconsole.classbro.cn/courseware-platform/#/',
    publicOrderDetailUrl: 'https://testconsole.classbro.cn/orderDetails/'
  },
  pro: {
    // RequestUrl: "https://consoleapi.classbro.com" /* 新系统接口 都为 consoleapi */ ,
    // BasicUrl: "https://www.classbro.com" /* 调用老系统 一律为www域名 */ ,
    RequestUrl: "https://teacherapi.classbro.com/routapi" /* 新系统接口 都为 consoleapi */,
    BasicUrl: "https://teacherapi.classbro.com/routapi" /* 调用老系统 一律为www域名 */,
    EntryClassUrl: "https://www.classbro.com",
    Ratinglink: "https://www.classbro.com/survey/#/queryExam?id=212" /* 评分表, 考虑跳转链接比较特殊，参数后台和产品只给了一个写死的数据，为了以防后期改参数，所以单独配置 */,
    LinkUrl: "https://www.classbro.com",
    /* 跳转页面 地址 */
    crmUrl: "https://www.classbro.com/crm/",
    /* 老crm接口 地址 */
    newCrmUrl: "https://consoleapi.classbro.com/routapi",
    /* 新crm接口 地址 */
    /* 课堂应用下载地址 */
    winUrl: 'https://classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/win/teacher/ClassbroRoom.exe',
    macUrl: 'https://classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/mac/teacher/classbroRoom.dmg',
    scheduleWebUrl: 'https://console.classbro.com/schedule/#/',
    /* 美刻唤起 地址 */
    mkClassScheme: 'wnroombox://enterclass/',
    mkWinURL: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/classbro/teacher/win/pro/ClassbroTeacher.exe',
    mkMacURL: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/classbro/teacher/mac/pro/ClassbroTeacher.dmg',
    coursewareUrl: 'https://courseware.classbro.com/#/',
    publicOrderDetailUrl: 'https://console.classbro.com/orderDetails/#/'
  },
  preview: {
    // RequestUrl: "https://previewconsoleapi.classbro.com", //预发环境
    // BasicUrl: "https://previewconsoleapi.classbro.com" /* 预发布环境 api接口地址 */ ,
    RequestUrl: "https://previewconsoleapi.classbro.com/routapi",
    //预发环境
    BasicUrl: "https://previewconsoleapi.classbro.com/routapi" /* 预发布环境 api接口地址 */,
    EntryClassUrl: "https://preview.classbro.com",
    Ratinglink: "https://previewconsole.classbro.com/survey/#/queryExam?id=212" /* 跳转评分 | 问卷 页面 */,
    LinkUrl: "https://previewconsole.classbro.com" /* 跳转页面 地址 */,
    crmUrl: "https://preview.classbro.com/crm/",
    /* 老crm接口 地址 */
    newCrmUrl: "https://previewconsoleapi.classbro.com/routapi",
    /* 新crm接口 地址 */
    /* 课堂应用下载地址 */
    winUrl: 'https://classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/win/teacher/ClassbroRoom-Test.exe',
    macUrl: 'https://classbro-oss-cn.oss-accelerate.aliyuncs.com/statics/app/classroom/mac/teacher/ClassbroRoom-Test.dmg',
    // 规划表页面（考前突击）
    scheduleWebUrl: 'https://previewconsole.classbro.com/schedule/#/',
    /* 美刻唤起 地址 */
    mkClassScheme: 'wnroombox://enterclass/',
    mkWinURL: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/classbro/teacher/win/test/ClassbroTeacher.exe',
    mkMacURL: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/classbro/teacher/mac/test/ClassbroTeacher.dmg',
    /* 备课平台 地址 */
    coursewareUrl: 'https://previewconsole.classbro.com/courseware-platform/#/',
    publicOrderDetailUrl: 'https://previewconsole.classbro.com/orderDetails/#/'
  },
  local: {
    RequestUrl: "http://192.168.1.215:18088" /* alan */,
    EntryClassUrl: "https://testconsole.classbro.com",
    Ratinglink: "https://testapi.classbro.com/survey/#/queryExam?id=212",
    BasicUrl: "https://testapi.classbro.com",
    LinkUrl: "https://testconsole.classbro.com",
    /* 跳转页面 地址 */
    scheduleWebUrl: 'https://previewconsole.classbro.com/schedule/#/',
    /* 美刻唤起 地址 */
    mkClassScheme: 'wnroombox://enterclass/'
  }
};

/* 讲师孵化 | 规划表 接口请求地址 */
var RequestUrl = exports.RequestUrl = Config[Switch].RequestUrl;
/* 调用张磊接口时候单独接口 */
var BasicUrl = exports.BasicUrl = Config[Switch].BasicUrl;
/* 进入课堂训练场地址网页 */
var EntryClassUrl = exports.EntryClassUrl = Config[Switch].EntryClassUrl;
/* 评分表链接地址 网页*/
var Ratinglink = exports.Ratinglink = Config[Switch].Ratinglink;
/* 页面统一跳转地址网页 */
var LinkUrl = exports.LinkUrl = Config[Switch].LinkUrl;
/* 老crm接口 */
var crmUrl = exports.crmUrl = Config[Switch].crmUrl;
/* 新crm接口 */
var newCrmUrl = exports.newCrmUrl = Config[Switch].newCrmUrl;
/* WIN应用地址 */
var winUrl = exports.winUrl = Config[Switch].winUrl;
/* Mac应用地址 */
var macUrl = exports.macUrl = Config[Switch].macUrl;
/* 讲师端页面 */
var scheduleWebUrl = exports.scheduleWebUrl = Config[Switch].scheduleWebUrl;
/* 美刻唤起 */
var mkClassScheme = exports.mkClassScheme = Config[Switch].mkClassScheme;
/* 美刻win下载地址 */
var mkWinURL = exports.mkWinURL = Config[Switch].mkWinURL;
/* 美刻mac下载地址 */
var mkMacURL = exports.mkMacURL = Config[Switch].mkMacURL;
/* 备课平台 地址 */
var coursewareUrl = exports.coursewareUrl = Config[Switch].coursewareUrl;
/* 公共订单详情 地址 */
var publicOrderDetailUrl = exports.publicOrderDetailUrl = Config[Switch].publicOrderDetailUrl;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "arrange-class-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isShow, center: "", width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "upload-form",
            },
            [
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "5px",
                      "margin-bottom": "10px",
                      "font-size": "14px",
                      color: "#666",
                    },
                  },
                  [_vm._v(_vm._s(_vm.stageTitle) + " 阶段课堂")]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "label" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/dissertation/modal/name.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("课堂名称")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled: "",
                        size: "mini",
                        placeholder: "请输入课堂名称",
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "upload-box" }, [
                _c("div", { staticClass: "label" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/dissertation/modal/time.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("上课时间")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        type: "date",
                        "picker-options": {
                          disabledDate: function disabledDate(time) {
                            return (
                              time.getTime() <
                              new Date(_vm.beijingTime).getTime() - 8.64e7
                            )
                          },
                        },
                        placeholder: "开始日期",
                        format: "yyyy 年 MM 月 dd 日",
                        "value-format": "yyyy-MM-dd",
                      },
                      on: { change: _vm.changpick },
                      model: {
                        value: _vm.form.dateStart,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dateStart", $$v)
                        },
                        expression: "form.dateStart",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-time-select", {
                      staticStyle: { width: "170px", "margin-left": "10px" },
                      attrs: {
                        placeholder: "起始时间",
                        "picker-options": {
                          start: "00:00",
                          step: "00:10",
                          end: "24:00",
                          minTime: _vm.minstartTime,
                        },
                      },
                      on: { change: _vm.getEndTime },
                      model: {
                        value: _vm.form.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startTime", $$v)
                        },
                        expression: "form.startTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "label" }, [
                  _c("span", { staticStyle: { "margin-left": "32px" } }, [
                    _vm._v("课节时长"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "time-select",
                        attrs: { placeholder: "", filterable: "" },
                        on: { change: _vm.getEndTime },
                        model: {
                          value: _vm.form.hour,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "hour", $$v)
                          },
                          expression: "form.hour",
                        },
                      },
                      _vm._l(24, function (item, index) {
                        return _c("el-option", {
                          key: item,
                          attrs: {
                            label: index < 10 ? "0" + index : "" + index,
                            value: String(index),
                          },
                        })
                      }),
                      1
                    ),
                    _vm._v("\n          小时\n          "),
                    _c(
                      "el-select",
                      {
                        staticClass: "time-select",
                        attrs: { placeholder: "", filterable: "" },
                        on: { change: _vm.getEndTime },
                        model: {
                          value: _vm.form.minute,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "minute", $$v)
                          },
                          expression: "form.minute",
                        },
                      },
                      _vm._l(_vm.minList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item, value: item },
                        })
                      }),
                      1
                    ),
                    _vm._v("\n          分钟\n          "),
                    _vm.classEndTime
                      ? _c("div", { staticClass: "end" }, [
                          _vm._v("下课时间：" + _vm._s(_vm.classEndTime)),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "time-desc" }, [
                _vm._v("请输入准确上课时长到达结束时间"),
                _c("span", [_vm._v("20分钟")]),
                _vm._v("后课堂关闭"),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "label" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/dissertation/modal/remark.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("课堂说明")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "mini",
                        maxlength: 255,
                        type: "textarea",
                        placeholder: "填写课堂说明",
                      },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "btn-box" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn-plain",
                    on: {
                      click: function ($event) {
                        _vm.isShow = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "btn", on: { click: _vm.validate } }, [
                  _vm._v("确定" + _vm._s(_vm.title)),
                ]),
              ]),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "left" },
      _vm._l(_vm.menusList, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("div", { staticClass: "first" }, [
              _c(
                "span",
                {
                  class: { active: _vm.currentId == item.id },
                  on: {
                    click: function ($event) {
                      return _vm.scrollToTop(item.id)
                    },
                  },
                },
                [_vm._v(_vm._s(item.title))]
              ),
              _vm._v(" "),
              item.showArrow
                ? _c("i", {
                    staticClass: "arrow",
                    class: {
                      "el-icon-arrow-down": item.upType,
                      "el-icon-arrow-up": !item.upType,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showUp(index, -1, !item.upType)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            item.children && item.upType
              ? _vm._l(item.children, function (secItem, secIdx) {
                  return _c(
                    "div",
                    { key: secIdx, staticClass: "second" },
                    [
                      secItem.showArrow
                        ? _c("i", {
                            staticClass: "arrow",
                            class: {
                              "el-icon-arrow-down": secItem.upType,
                              "el-icon-arrow-up": !secItem.upType,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showUp(
                                  index,
                                  secIdx,
                                  !secItem.upType
                                )
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: { active: _vm.currentId == secItem.id },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToTop(secItem.id)
                            },
                          },
                        },
                        [
                          secItem.showNo
                            ? _c("span", [_vm._v(_vm._s(secIdx + 1) + ".")])
                            : _vm._e(),
                          _vm._v(_vm._s(secItem.title)),
                        ]
                      ),
                      _vm._v(" "),
                      secItem.children && secItem.upType
                        ? _vm._l(
                            secItem.children,
                            function (thirdItem, thirdIdx) {
                              return _c(
                                "div",
                                { key: thirdIdx, staticClass: "third" },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        active: _vm.currentId == thirdItem.id,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.scrollToTop(thirdItem.id)
                                        },
                                      },
                                    },
                                    [
                                      thirdItem.showNo
                                        ? _c("span", [_vm._v("●")])
                                        : _vm._e(),
                                      _vm._v(_vm._s(thirdItem.title)),
                                    ]
                                  ),
                                ]
                              )
                            }
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                })
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "content",
        staticClass: "right-content",
        attrs: { id: "right" },
        on: { scroll: _vm.handleScroll },
      },
      [
        _c("aboutMk", { attrs: { teacinfo: _vm.teacinfo } }),
        _vm._v(" "),
        _c("useGuide"),
        _vm._v(" "),
        _c("teacManual"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
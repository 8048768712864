var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model.show && ["全部通知"].includes(_vm.model.type)
    ? _c(
        "div",
        { staticClass: "new-mask" },
        [
          _c(
            "div",
            {
              staticClass:
                "mask-content mask-width560 fixed-mask mask-width984",
            },
            [
              _c("h3", { staticClass: " content-title" }, [
                _vm._v(_vm._s(_vm.model.type) + " "),
                _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "contents-box auto-box" },
                [
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.likeLoading,
                          expression: "likeLoading",
                        },
                      ],
                      staticClass: "likedetail-box",
                    },
                    _vm._l(_vm.allNoticeData.datas, function (items) {
                      return _c("li", [
                        _c(
                          "p",
                          {
                            staticClass: "zan-detial",
                            class: {
                              "important-titile": [25, 26].includes(
                                Number(items.type)
                              ),
                            },
                          },
                          [
                            _vm._v("【" + _vm._s(items.title) + "】"),
                            _c("span", [_vm._v(_vm._s(items.createAt))]),
                          ]
                        ),
                        _vm._v(" "),
                        items.type != 11
                          ? _c("p", {
                              staticClass: "desc",
                              domProps: {
                                innerHTML: _vm._s(items.description),
                              },
                            })
                          : _c("p", { staticClass: "desc" }, [
                              _vm._v(
                                "恭喜您从LV" +
                                  _vm._s(
                                    JSON.parse(items.description).oldLevel
                                  ) +
                                  "升级到LV" +
                                  _vm._s(JSON.parse(items.description).nowLevel)
                              ),
                            ]),
                        _vm._v(" "),
                        [22].includes(items.type)
                          ? _c(
                              "p",
                              {
                                staticClass: "see-detail",
                                on: {
                                  click: function ($event) {
                                    return _vm.showTypeModal(items.type)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-arrow-right" }),
                                _c("i", {
                                  staticClass: "el-icon-arrow-right two",
                                }),
                                _vm._v("查看详情"),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  !_vm.allNoticeData.datas.length && !_vm.likeLoading
                    ? _c("contentnull", { attrs: { statuscode: 4 } })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.allNoticeData.datas.length
                ? _c("el-pagination", {
                    staticClass: "bottom-page",
                    staticStyle: { margin: "15px 10px 0 0", float: "right" },
                    attrs: {
                      small: "",
                      "page-sizes": [20],
                      "current-page": _vm.page,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.allNoticeData.totals,
                    },
                    on: {
                      "size-change": _vm.handleSizeChangen,
                      "current-change": _vm.handleCurrentChangen,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("welecomeNew", { ref: "welecomeNew", attrs: { type: "2" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
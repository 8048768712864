var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("section", { staticClass: "left" }, [
        _c("div", [
          _c("span", [_vm._v("Deal Deadline: ")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("fmtDate")(_vm.dissertationTitleData.dealDeadline))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "progressbar-container" }, [
          _c("span", [_vm._v("毕业论文辅导进度: ")]),
          _vm._v(" "),
          _c("div", { staticClass: "progressbar" }, [
            _c("div", { staticClass: "process" }, [
              _c("div", {
                staticClass: "percent",
                style: "width: " + _vm.dissertationTitleData.schedule + "%",
              }),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(_vm._s(_vm.dissertationTitleData.schedule) + "%"),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "right" },
        [
          +_vm.dissertationTitleData.statused >= 3
            ? _c("div", { staticClass: "btn", on: { click: _vm.openModal } }, [
                _vm._v("论文评级"),
              ])
            : [
                _c("div", { staticClass: "btn disabled" }, [
                  _vm._v("论文评级"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "font-red" }, [
                  _vm._v(
                    "*请根据下方红色指引完成相应位置任务后可以进行论文评级"
                  ),
                ]),
              ],
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("section", [_c("Grade-Modal", { ref: "gradeModal" })], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("毕业论文规划表")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "help" },
    [
      _c(
        "el-popover",
        { attrs: { placement: "top", width: "480", trigger: "hover" } },
        [
          _c("h3", { staticClass: "text-title " }, [_vm._v("备考审核表说明")]),
          _vm._v(" "),
          _c("div", { staticClass: "text-content" }, [
            _c("div", { staticClass: "f700 start" }, [
              _vm._v("尊敬的各位讲师请仔细阅读以下须知:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", [
                _vm._v(
                  "1. 备考审核旨在了解各位讲师在准备的订单详细情况，同时也希望发现并解决讲师在备考过程中遇到的问题，请至少在DDL提前"
                ),
                _c("span", { staticClass: "red-f7" }, [_vm._v("24小时")]),
                _vm._v("上传备考内容。"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", [
                _vm._v(
                  "2. 我们希望您认真完成备考内容，以满足服务质量，请注意提前预留时间并且满足以下备考要求："
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "child" }, [
                _vm._v(
                  "1）请确保您查阅了学生所有的订单内容，如遇到缺少部分订单需求的内容文件，如"
                ),
                _c("span", { staticClass: "red-f7" }, [
                  _vm._v("往期习题或课堂课件"),
                ]),
                _vm._v("等，请及时联系师资人员索要。"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "child" }, [
                _vm._v("2）备考复习的内容可以是学生"),
                _c("span", { staticClass: "red-f7" }, [_vm._v("pastpaper")]),
                _vm._v(
                  "的完成记录，知识点重难点高亮以及批注，明确学生的订单完成内容。"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "child" }, [
                _vm._v("3）上传的文件"),
                _c("span", { staticClass: "red-f7" }, [_vm._v("格式不限")]),
                _vm._v(
                  "，且可以上传多份文件来佐证您有很好的准备此次订单内容，我们的师资人员会逐一审核，如遇到不符合备考内容的最低要求，可能会被"
                ),
                _c("span", { staticClass: "red-f7" }, [_vm._v("退回重做")]),
                _vm._v("甚至"),
                _c("span", { staticClass: "red-f7" }, [_vm._v("取消此次委托")]),
                _vm._v("。"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item" }, [
              _c("div", [
                _vm._v("3. 最后当您查阅完整信息之后，"),
                _c("span", { staticClass: "red-f7" }, [
                  _vm._v("发现考试内容难度与预期不符，或遇到其他困难的情况"),
                ]),
                _vm._v("，请及时向我方师资人员提出。"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "desc" }, [
              _c("div", [
                _vm._v("我们在下方为您准备了备考审核表的两份sample，"),
                _c("span", { staticClass: "f700" }, [_vm._v("仅作内容参考。")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "f700" }, [
                _vm._v("请各位讲师仔细阅读，祝各位生活顺利。"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sample" },
                _vm._l(_vm.sampleList, function (sample, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "file",
                      on: {
                        click: function ($event) {
                          return _vm.seeFile(sample)
                        },
                      },
                    },
                    [_vm._v(_vm._s(sample.name))]
                  )
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("img", {
            attrs: {
              slot: "reference",
              src: require("@/assets/images/paper/wenhao.png"),
            },
            slot: "reference",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subtr = Subtr;
exports.accAdd = accAdd;
exports.cacheIntervalId = cacheIntervalId;
exports.clearCacheInterval = clearCacheInterval;
exports.defaultUrl = defaultUrl;
exports.fmtDateRange = fmtDateRange;
exports.formatNumber = formatNumber;
exports.formatSeconds = formatSeconds;
exports.formatTime = formatTime;
exports.getFmtCurrentLine = getFmtCurrentLine;
exports.getStorage = getStorage;
exports.getTeacherInfo = getTeacherInfo;
exports.getweekday = getweekday;
exports.globalImgFilter = globalImgFilter;
exports.setStorage = setStorage;
exports.sortBy = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _VEnum = require("@/utils/VEnum");
var _elementUi = require("element-ui");
var _moment = _interopRequireDefault(require("moment"));
var _lodash = _interopRequireDefault(require("lodash"));
var _vue = _interopRequireDefault(require("vue"));
/**
 * 将秒数转换为时分秒格式
 * @param {Number} value 秒
 */
// export function formatSeconds(time) {
//     const min = Math.floor(time % 3600);
//     const d = formatBit(Math.floor(time / (3600 * 24)));
//     console.log(d % 24, " d % 24");
//     return {
//         d: d,
//         h: formatBit(Math.floor(time / 3600)),
//         m: formatBit(Math.floor(min / 60)),
//         s: formatBit(time % 60)
//     };
// }

/**
 * 格式化秒
 * @param int  value 总秒数
 */
function formatSeconds(value) {
  var theTime = parseInt(value); // 需要转换的时间秒
  var theTime1 = 0; // 分
  var theTime2 = 0; // 小时
  var theTime3 = 0; // 天
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
      if (theTime2 > 24) {
        //大于24小时
        theTime3 = parseInt(theTime2 / 24);
        theTime2 = parseInt(theTime2 % 24);
      }
    }
  }
  var d, h, m, s;
  if (theTime > 0) {
    s = formatBit(parseInt(theTime)); //秒
  }
  if (theTime1 > 0) {
    m = formatBit(parseInt(theTime1)); //分
  }
  if (theTime2 > 0) {
    h = formatBit(parseInt(theTime2)); //小时
  }
  if (theTime3 > 0) {
    d = formatBit(parseInt(theTime3)); //天
  }
  return {
    d: fmtNilDate(d),
    h: fmtNilDate(h),
    m: fmtNilDate(m),
    s: fmtNilDate(s)
  };
}

/**
 * 讲师孵化 工具
 */
function fmtDateRange(_start, _end) {
  var start = (0, _moment.default)(_start).format("YYYY-MM-DD HH:mm");
  var end = (0, _moment.default)(_end).format("YYYY-MM-DD HH:mm");
  return {
    start: start,
    end: end,
    range: "".concat(start, " ").concat(end)
  };
}

/**
 * is Nil => 00
 */
function fmtNilDate(val) {
  if (_lodash.default.isNil(val)) return "00";else return val;
}

/**
 * 补0
 * @param {Number} val 分 | 秒
 */
function formatBit(val) {
  val = +val;
  return val > 9 ? val : "0" + val;
}

/**
 * 记录定时器id
 */
function cacheIntervalId(id) {
  localStorage.setItem(_VEnum.Storage.INTERVAL, id);
}

/**
 * 用于清理重复定义的定时器
 */
function clearCacheInterval() {
  var _id = localStorage.getItem(_VEnum.Storage.INTERVAL);
  clearInterval(_id);
  /* 清除模拟订单操作 样式定时器 */
  clearInterval(localStorage.getItem(_VEnum.Storage.FAKE_SPEED));
}

/**
 * 格式化流水线
 * 已完成流程operatingState=1 未完成=2
 * 老师所处流程和所处环节acstate=1
 * 若一个流程下有2个环节，一个环节完成了，另一个环节处于进行中或者未完成时，  则流程的operatingState=2
 * lcType: 0-普通流程类型 1-全真模拟课 2-模拟订单流程 4-系统操作培训
 * 环节类型：0-视频环节 1-文档环节 2-测试题环节 4-无文档环节
 */
function getResultLine(process, list) {
  // const _visited = {
  //     1: true,
  //     2: false
  // };
  var _doing = {
    0: false,
    1: true
  };
  if (process.constructor === Array) {
    return process.map(function (item) {
      var processName = item.processName,
        acState = item.acState,
        operatingState = item.operatingState,
        childProcess = item.childProcess,
        lcType = item.lcType,
        hjType = item.hjType,
        classNo = item.classNo,
        path = item.path,
        processReport = item.processReport;
      /* 设置classNo */
      if (_VEnum.HjType.TRAINING_GROUND === +hjType) localStorage.setItem(_VEnum.Storage.CLASS_NO, classNo);
      /* reaction 报告id */
      if (+hjType === 32) localStorage.setItem("processReport", processReport);
      var cache = {
        processName: processName,
        lcType: lcType,
        path: path,
        // visited: _visited[+operatingState] || _doing[+acState],
        loading: _doing[+acState],
        //当前loading
        doing: _doing[+acState],
        acState: acState,
        operatingState: operatingState
      };
      if (childProcess && childProcess.length > 0) {
        cache.childProcess = getResultLine(childProcess, list);
      }
      return cache;
    });
  }
  if (process.constructor === Object) {
    var processName = process.processName,
      acState = process.acState,
      operatingState = process.operatingState,
      childProcess = process.childProcess;
    var cache = {
      acState: acState,
      operatingState: operatingState,
      processName: processName
    };
    if (childProcess && childProcess.length > 0) {
      cache.childProcess = getResultLine(childProcess, list);
    }
    list.push(cache);
  }
  return list;
}

/**
 * 获取UI 流水线
 */
function getFmtCurrentLine(lines) {
  var childProcess = lines.childProcess;
  // 获取流程列表
  if (childProcess.length === 0) {
    (0, _elementUi.Message)({
      message: "流水线流程数据为空",
      type: "warning",
      duration: 5 * 1000
    });
    return [];
  }
  var list = [];
  getResultLine(lines, list);
  var result = list[0];
  // lcType  16-课程培训 8- 闭环管理 ,128-学术检测,256-岗前培训
  var CLASSTRAIN = _VEnum.LcType.CLASSTRAIN,
    ACMTESRT = _VEnum.LcType.ACMTESRT,
    BEFORETRAIN = _VEnum.LcType.BEFORETRAIN,
    CLOSElOOP = _VEnum.LcType.CLOSElOOP;
  result.childProcess = result.childProcess.filter(function (_ref) {
    var lcType = _ref.lcType;
    return [CLASSTRAIN, ACMTESRT, BEFORETRAIN, CLOSElOOP].includes(lcType);
  });
  return result;
}

/**
 * localStorage get by id
 */
function getStorage(id) {
  var storage = JSON.parse(localStorage.getItem(id));
  return storage || null;
}

/**
 * localStorage set
 */
function setStorage(id, data) {
  localStorage.setItem(id, JSON.stringify(data));
}

/**
 * 获取老师登录后的用户详情
 */
function getTeacherInfo() {
  var info = JSON.parse(localStorage.getItem(_VEnum.Storage.TEACHER_INFO));
  if (!info) throw "未能获取到登录用户信息";
  return info;
}

//时间格式化新加(stt)
//数据转化 
function formatNumber(n) {
  n = n.toString();
  return n[1] ? n : '0' + n;
}
// /** 
//  * 时间戳转化为年 月 日 时 分 秒 
//  * number: 传入时间戳 
//  * format：返回格式，支持自定义，但参数必须与formateArr里保持一致 
// */
function formatTime(number, format) {
  var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
  var returnArr = [];
  var date = new Date(number);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1));
  returnArr.push(formatNumber(date.getDate()));
  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));
  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}
//获取当前是周几
function getweekday(date) {
  var weekArray = new Array("日", "一", "二", "三", "四", "五", "六");
  var week = weekArray[new Date(date).getDay()]; //注意此处必须是先new一个Date
  return week;
}
// 加法
function accAdd(arg1, arg2) {
  var r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}
//减法 
function Subtr(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
function globalImgFilter(val) {
  if (!val.thumb) {
    return val.sex == "MALE" ? require('@/assets/images/iconmale.png') : require('@/assets/images/iconfemale.png');
  }
  var sval = "" + (val.thumb.includes('https://') ? '' : _vue.default.prototype.$api.baseURL) + "" + val.thumb + "";
  return sval;
}
//  默认头像
function defaultUrl(val) {
  return val.sex == "MALE" ? require('@/assets/images/iconmale.png') : require('@/assets/images/iconfemale.png');
}

//根据传过来的字段进行排序
var sortBy = exports.sortBy = function sortBy(field) {
  return function (x, y) {
    console.log(111, x, y);
    console.log(222, x[field], y[field]);
    return x[field] && x[field] - y[field];
  };
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "info-box",
    },
    [
      _c("div", [
        _c("span", [_vm._v("Exam Type")]),
        _vm._v(" "),
        _c("span", [
          _vm._v(_vm._s(_vm.wenyaExamTypeObj[_vm.examDetail.examType])),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", [_vm._v("Exam Form")]),
        _vm._v(" "),
        _c("span", [
          _vm._v(_vm._s(_vm.assistExamWayObj[_vm.examDetail.assistExamWay])),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", [_vm._v("Exam Link")]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "link",
            on: {
              click: function ($event) {
                return _vm.openLink(_vm.examDetail.examUrl)
              },
            },
          },
          [_vm._v(_vm._s(_vm.examDetail.examUrl))]
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", [_vm._v("Exam Style")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.examStyle[_vm.examDetail.examStyle]))]),
      ]),
      _vm._v(" "),
      _vm.testFormShow.OfficialOpenFrom
        ? _c("div", [
            _c("span", [_vm._v("Offcial Open From")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.examDetail.examOpenTime))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.testFormShow.StartFrom
        ? _c("div", [
            _c("span", [_vm._v("Start From")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.examDetail.realExamStartTime))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.testFormShow.ToexamCloseTime
        ? _c("div", [
            _c("span", [_vm._v("To")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.examDetail.examCloseTime))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.testFormShow.ExamDuration
        ? _c("div", [
            _c("span", [_vm._v("Exam Duration")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.examDetail.examLimitTime))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.testFormShow.TorealExamEndTime
        ? _c("div", [
            _c("span", [_vm._v("To")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.examDetail.realExamEndTime))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.testFormShow.RealDeadline
        ? _c("div", [
            _c("span", [_vm._v("Deal Deadline")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.examDetail.examTime))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("span", [_vm._v("考试倒计时")]),
          _vm._v(" "),
          _vm.countTime.show
            ? _c("flip-countdown", {
                staticClass: "countdown",
                attrs: { deadline: _vm.countTime.time, labels: _vm.labels },
              })
            : _c("span", { staticStyle: { color: "#ff5843" } }, [
                _vm._v(_vm._s(_vm.countTime.text)),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "qyweixin-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "550px",
            title: "使用“企业微信插件”开启沟通",
            "close-on-click-modal": false,
            "before-close": _vm.closeModal,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content-box" }, [
            _c("h3", [_vm._v("各位讲师您好！")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "  为促进您更高效开展讲师工作，更快速了解订单信息情况，我们引入“企业微信插件”工具进行沟通，请您尽快前往“小班带你飞”完成关注插件任务。"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("请务必在"),
              _c("b", [_vm._v("1月31号")]),
              _vm._v("前完成签署，否则影响工作开展"),
            ]),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: require("@/assets/images/qiyeweixinXBDNF.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("p", [_vm._v("微信扫码前往“小班带你飞”公众号")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visiblePop,
            "append-to-body": true,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiblePop = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("h3", { staticClass: "late_title" }, [
              _c("span", [_vm._v("迟到")]),
              _vm._v("上课"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.lateInfo.orderNo)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text", staticStyle: { "margin-top": "20px" } },
            [
              _vm._v("课堂【" + _vm._s(_vm.lateInfo.classroomName) + "】已经"),
              _c("span", [
                _vm._v("迟到" + _vm._s(_vm.lateInfo.lateMin) + "分钟"),
              ]),
              _vm._v("，请立即前往上课！"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "warning", size: "small" },
                  on: { click: _vm.toRoom },
                },
                [_vm._v("去上课")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
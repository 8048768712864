var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog  task-dailog",
          attrs: {
            visible: _vm.isShow,
            width: "560px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("编辑阶段任务")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.tabelList, stripe: "", border: "" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "date",
                      label: "序号",
                      width: "80",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            任务" +
                                _vm._s(scope.$index + 1) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "任务列表" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.id
                              ? _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(scope.row.name),
                                  },
                                })
                              : _c(
                                  "div",
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: { placeholder: "请输入" },
                                      on: { input: _vm.change },
                                      model: {
                                        value: scope.row.name,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "name", $$v)
                                        },
                                        expression: "scope.row.name",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "cursor",
                                        staticStyle: {
                                          color: "#FF8723",
                                          "margin-left": "20px",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmAdd(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("确认")]
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "date",
                      label: "操作",
                      width: "60",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.newFlag != 1
                              ? _c("i", {
                                  staticClass: "el-icon-delete cursor",
                                  staticStyle: { "font-size": "20px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTask(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "add-btn cursor", on: { click: _vm.addTask } },
                [
                  _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                  _vm._v("新增\n      "),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
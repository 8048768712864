"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TimeOut = _interopRequireDefault(require("@/components/TimeOut"));
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "App",
  provide: function provide() {
    return {
      reload: this.reload,
      resizeFun: this.resizeFun
    };
  },
  components: {
    TimeOut: _TimeOut.default
  },
  data: function data() {
    return {
      isRouterAlive: true
    };
  },
  created: function created() {
    this.$nextTick(function () {
      // this.resizeFun()
      // window.addEventListener('resize', this.resizeFun)
    });
  },
  methods: {
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    resizeFun: function resizeFun(dom) {
      var devicePixelRatio = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      if (devicePixelRatio !== 1) {
        // 如果在笔记本中用IE浏览器打开 则弹出提示
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          // alert('balabala。。。')
        } else {
          console.log('dom', dom);
          var c = dom;
          c.style.zoom = 1 / devicePixelRatio; // 修改页面的缩放比例
        }
      }
      return 1 / devicePixelRatio;
    }
  }
};
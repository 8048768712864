var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "516px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("Exam&Quiz")]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "讲师您好，" +
                _vm._s(_vm.assistExamWayList[_vm.assistExamWay]) +
                " 类型订单需执行以下规范要求："
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", [
                _vm._v("1. 考试前期：请认真备考填写［考试信息］"),
                _vm.teacherType == 2
                  ? _c("p", [_vm._v("并及时上传［备考审核表］")])
                  : _vm._e(),
                _c("span", [
                  _vm._v("以上需要在考试前完成，否则会影响老师正常结单"),
                ]),
              ]),
              _vm._v(" "),
              _vm.assistExamWay == 1
                ? [
                    _c("div", [
                      _vm._v("2. 考试开始：打开Loom录屏软件，"),
                      _c("span", [_vm._v("录制考试完整过程")]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("3. 考试结束："),
                      _c("span", [_vm._v("提交录制视频链接")]),
                    ]),
                  ]
                : [
                    _c("div", [_vm._v("2. 考试开始：考试题目及答案完整保留")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("3. 考试结束："),
                      _c("span", [_vm._v("提交考试题目及答案文件")]),
                    ]),
                  ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "desc", staticStyle: { "margin-top": "10px" } },
                [_vm._v("违规处罚：")]
              ),
              _vm._v(" "),
              _vm.assistExamWay == 1
                ? _c("div", [
                    _vm._v(
                      "1.视频内容将由运营团队检查，若您未能提供记录整个考试的有效视频，"
                    ),
                    _c("span", [_vm._v("扣除全部订单收入")]),
                    _vm._v("，且失去售后申诉权限。"),
                  ])
                : _c("div", [
                    _vm._v(
                      "1. 考试题目及答案将由运营团队检查，若您未能提供记录整个考试的有效内容，"
                    ),
                    _c("span", [_vm._v("扣除全部订单收入")]),
                    _vm._v(",且失去售后申诉权限。"),
                  ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(" 2. 2022.03.08订单执行Reflection机制，订单出现"),
                _c("span", [_vm._v("学生投诉、售后判定讲师责任")]),
                _vm._v("等情况，讲师将面临"),
                _c("span", [_vm._v("双倍薪资扣除")]),
                _vm._v("。"),
              ]),
              _vm._v(" "),
              _vm.assistExamWay == 1
                ? [
                    _c("div", [
                      _vm._v(
                        "Loom录制软件下载指南，请务必及时根据指引完成操作"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "link",
                        on: {
                          click: function ($event) {
                            return _vm.openLink(_vm.url)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.url))]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn-box",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "default-btn ",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("取消 ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "defaultlight-btn",
                      attrs: { loading: _vm.loading },
                      on: { click: _vm.receivOrder },
                    },
                    [_vm._v("确定接单 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
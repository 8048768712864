"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _testmsgForm = _interopRequireDefault(require("@/views/myorders/testmsgForm"));
var _uploadExamCheck = _interopRequireDefault(require("@/views/myorders/uploadExamCheck"));
var _examInfo = _interopRequireDefault(require("@/views/myorders/examInfo"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    testmsgForm: _testmsgForm.default,
    uploadExamCheck: _uploadExamCheck.default,
    examInfo: _examInfo.default
  },
  props: {
    testMsgObj: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isShow: false,
      step: 0,
      teacType: localStorage.getItem("teacType"),
      //(全职1，兼职2)
      current: {},
      stepList: {
        1: '考试信息',
        2: '备考审核'
      },
      active: 0
    };
  },
  computed: {
    btnLoading: function btnLoading() {
      if (this.$refs.testmsgForm) {
        return this.$refs.testmsgForm.loading;
      } else return false;
    }
  },
  methods: {
    show: function show(row, testMsgObj) {
      var _this = this;
      this.isShow = true;
      this.current = row; //订单信息
      this.$nextTick(function () {
        _this.$refs.testmsgForm && _this.$refs.testmsgForm.init();
        _this.$refs.examInfo && _this.$refs.examInfo.init();
        _this.$refs.uploadExamCheck && _this.$refs.uploadExamCheck.getExamCheckFile();
      });
      this.initStep();
      console.log('this.current', this.current);
    },
    //初始化当前步骤
    initStep: function initStep() {
      if ([0].includes(this.current.buttonStatus)) {
        // 未填写
        this.step = 0;
        this.active = 0;
      } else if ([1, 3].includes(this.current.buttonStatus)) {
        // 未到ddl质检未审核\未到ddl质检审核不通过
        this.step = 0;
        this.active = 1;
      } else if ([2, 5, 6, 7, 8].includes(this.current.buttonStatus)) {
        this.step = 0;
        this.active = 2;
      }
    },
    handleClose: function handleClose() {
      this.isShow = false;
      this.$refs.testmsgForm && this.$refs.testmsgForm.clearForm();
      this.cancelUpload();
    },
    pre: function pre() {
      this.step = 0;
    },
    next: function next() {
      var canNext = this.$refs.testmsgForm.validForm();
      if (canNext) this.step = 1;else return this.$notify.warning('有必填项未填写');
    },
    // 提交审核
    uploadCheck: function uploadCheck() {
      this.$refs.testmsgForm.confirm();
    },
    changeStep: function changeStep(value) {
      if (this.active >= value) {
        this.step = value - 1;
      }
    },
    cancelUpload: function cancelUpload() {
      this.$refs.uploadExamCheck && this.$refs.uploadExamCheck.cancelUpload();
    },
    refresh: function refresh() {
      this.$emit('refresh');
    }
  }
};
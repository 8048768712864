var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "680px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("\n      破冰课授课要求\n    "),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form-box",
              attrs: {
                model: _vm.form,
                rules: !_vm.readonly ? _vm.rules : {},
                "label-width": "240px",
                "label-position": "right",
              },
            },
            [
              _c("div", { staticClass: "tip" }, [
                _vm._v("以下内容自动发送给学生查看，请讲师务必确定正确信息"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { label: "Topic:", required: "" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.topicType,
                        callback: function ($$v) {
                          _vm.topicType = $$v
                        },
                        expression: "topicType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("固定Topic"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("不固定Topic"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.topicType == 2
                    ? _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-left": "119px" },
                          model: {
                            value: _vm.form.topic,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "topic", $$v)
                            },
                            expression: "form.topic",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("选择性Topic"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "3" } }, [
                            _vm._v("开放式Topic"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  [2, 3].includes(Number(_vm.form.topic)) && _vm.topicType == 2
                    ? _c("el-input", {
                        staticStyle: {
                          width: "230px",
                          margin: "10px 0 0 116px",
                        },
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入具体辅导的Topic名称",
                        },
                        on: { input: _vm.change },
                        model: {
                          value: _vm.form.topicName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "topicName", $$v)
                          },
                          expression: "form.topicName",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative", "line-height": "30px" },
                  attrs: {
                    label: "Research Paper:",
                    required:
                      !_vm.readonly && [1].includes(Number(_vm.topicType))
                        ? true
                        : false,
                  },
                },
                [
                  _c("uploadFile", {
                    ref: "upload",
                    staticStyle: { display: "inline-block" },
                    on: { uploadFiles: _vm.uploadFiles },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "add", on: { click: _vm.addLink } },
                    [_vm._v(" 上传链接")]
                  ),
                  _vm._v(" "),
                  [1].includes(Number(_vm.topicType))
                    ? _c("div", [
                        _vm._v("请上传" + _vm._s(_vm.fileNum) + "篇资料文件"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("file-list", { attrs: { fileList: _vm.form.fileList } }),
                  _vm._v(" "),
                  _vm._l(_vm.form.linkList, function (item, index) {
                    return _c(
                      "div",
                      {
                        staticStyle: { position: "relative" },
                        style: { marginTop: index ? "8px" : "8px" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "320px" },
                          attrs: { readonly: _vm.readonly },
                          on: { input: _vm.change },
                          model: {
                            value: item.answer,
                            callback: function ($$v) {
                              _vm.$set(item, "answer", $$v)
                            },
                            expression: "item.answer",
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.linkList.length && !_vm.readonly
                          ? _c("i", {
                              staticClass: "el-icon-delete cursor",
                              staticStyle: { "font-size": "20px" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteLink(index)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Reading范围要求:",
                    required: !_vm.readonly && _vm.form.open ? true : false,
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-color": "#ff8723" },
                    model: {
                      value: _vm.form.open,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "open", $$v)
                      },
                      expression: "form.open",
                    },
                  }),
                  _vm._v(" "),
                  _vm.form.open
                    ? _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { position: "relative" },
                          model: {
                            value: _vm.form.reading,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "reading", $$v)
                            },
                            expression: "form.reading",
                          },
                        },
                        _vm._l(_vm.readList, function (key, value) {
                          return _c(
                            "el-checkbox",
                            {
                              key: value,
                              staticStyle: { display: "block" },
                              attrs: { label: key },
                            },
                            [_vm._v(_vm._s(key))]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.open &&
                  _vm.form.reading &&
                  ~_vm.form.reading.indexOf("其他")
                    ? _c("el-input", {
                        staticStyle: { width: "300px", "margin-top": "10px" },
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入Reading范围要求",
                          readonly: _vm.readonly,
                        },
                        on: { input: _vm.change },
                        model: {
                          value: _vm.form.readydesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "readydesc", $$v)
                          },
                          expression: "form.readydesc",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: {
                    label: "请选择订单文章的reference style:",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: { position: "relative" },
                      model: {
                        value: _vm.form.checkList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "checkList", $$v)
                        },
                        expression: "form.checkList",
                      },
                    },
                    _vm._l(_vm.referenceList, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticStyle: { display: "block" },
                          attrs: { label: item.id },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.checkList.includes(9)
                    ? _c("el-input", {
                        staticStyle: {
                          position: "absolute",
                          bottom: "-17px",
                          right: "20px",
                          width: "220px",
                        },
                        attrs: { type: "textarea", placeholder: "请输入" },
                        model: {
                          value: _vm.form.other,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "other", $$v)
                          },
                          expression: "form.other",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "Deal  Deadline:", prop: "dealDeadline" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-M-dd H:mm:ss",
                      placeholder: "选择dealDeadline",
                    },
                    on: { change: _vm.changeTime },
                    model: {
                      value: _vm.form.dealDeadline,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dealDeadline", $$v)
                      },
                      expression: "form.dealDeadline",
                    },
                  }),
                  _vm._v(" "),
                  _vm.showTip
                    ? _c("div", { staticStyle: { color: "red" } }, [
                        _vm._v("时间超过订单Deal Deadline时间，请确认订单信息"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "推荐使用Citation制作link:", required: "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "link",
                      on: {
                        click: function ($event) {
                          return _vm.openLink(_vm.citation)
                        },
                      },
                    },
                    [_vm._v("\n          " + _vm._s(_vm.citation))]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.readonly
            ? _c(
                "div",
                {
                  staticClass: "footer-btn",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "cancle-btn",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "confirm-btn",
                      on: { click: _vm.comfirmAdd },
                    },
                    [_vm._v("确定")]
                  ),
                ]
              )
            : _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order" },
    [
      _c(
        "div",
        { staticClass: "orderlist-box minwidth" },
        _vm._l(_vm.ordertaplist, function (item, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "list-wrap cursorpointer",
              on: {
                click: function ($event) {
                  return _vm.changeTaps(i)
                },
              },
            },
            [
              _c("img", {
                staticClass: "bg-img",
                attrs: { src: _vm.isactive == i ? item.bgcolor : _vm.bgImg },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "list-box" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src:
                      _vm.isactive == i ? item.beforeimgsrc : item.afterimgsrc,
                  },
                }),
                _vm._v(" "),
                _c("div", { class: _vm.isactive == i ? "colorwhite" : "" }, [
                  _vm._v(_vm._s(item.name)),
                  _c("br"),
                  _vm._v(" "),
                  i < 2
                    ? _c(
                        "b",
                        { class: _vm.isactive == i ? "colorwhite" : "" },
                        [
                          _vm._v(
                            _vm._s(
                              i == 0
                                ? _vm.orderTatol.orderPoolCount
                                : _vm.orderTatol.orderActiveCount
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _vm.isactive != 2
            ? _c(
                "div",
                { staticClass: "search-box" },
                [
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isactive == 1,
                          expression: "isactive == 1",
                        },
                      ],
                    },
                    _vm._l(_vm.ordertapdata, function (item, i) {
                      return _c(
                        "li",
                        {
                          class: [_vm.liactive == i ? "active" : ""],
                          on: {
                            click: function ($event) {
                              return _vm.changeTap(i)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isactive != 0,
                          expression: "isactive != 0",
                        },
                      ],
                      staticClass: "lighttext-box",
                    },
                    [
                      _vm._v(
                        "本月可赠送时长" +
                          _vm._s(_vm.time) +
                          "/" +
                          _vm._s(_vm.user.presenterTimeLimit) +
                          "分钟"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "time-tips-box" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isactive == 1,
                              expression: "isactive == 1",
                            },
                          ],
                          attrs: { size: "small" },
                          model: {
                            value: _vm.timeIsSort,
                            callback: function ($$v) {
                              _vm.timeIsSort = $$v
                            },
                            expression: "timeIsSort",
                          },
                        },
                        _vm._l(_vm.timeArr, function (time, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: time.name, value: time.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "elinputstyle",
                    staticStyle: {
                      width: "190px",
                      height: "30px",
                      "border-raduils": "50%",
                    },
                    attrs: {
                      size: "small",
                      placeholder: "请输入关键词搜索",
                      "prefix-icon": "el-icon-search",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.search($event)
                      },
                    },
                    model: {
                      value: _vm.tableDataName,
                      callback: function ($$v) {
                        _vm.tableDataName = $$v
                      },
                      expression: "tableDataName",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "defaultlight-btn",
                      on: { click: _vm.search },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isactive == 0
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "table-list",
                },
                [
                  _vm._l(_vm.tableDataEnd, function (list, i) {
                    return _c("div", { staticClass: "list-warp" }, [
                      list.borderCount && [6].includes(list.borderType)
                        ? _c("div", { staticClass: "order-num" }, [
                            _vm._v(_vm._s(list.borderCount)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      list.spoType == 67
                        ? _c("img", {
                            staticClass: "list-newimg",
                            attrs: {
                              src: require("@/assets/images/orderlist-new.png"),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-img" }, [
                        [6].includes(list.borderType)
                          ? _c("img", {
                              attrs: {
                                src: _vm.bcoursetypeImg[list.borderType],
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: { src: _vm.coursetypeImg[list.spoType] },
                            }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "list-student ",
                          on: {
                            click: function ($event) {
                              return _vm.see(list, i)
                            },
                          },
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "overflowdot cursorpointer" },
                            [_vm._v(_vm._s(list.orderNo))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "overflowdot cursorpointer" },
                            [_vm._v("学校名称：" + _vm._s(list.newSchoolName))]
                          ),
                          _vm._v(" "),
                          list.copywritingCourse && list.copywritingCourse.type
                            ? [
                                list.realExamStartTime
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "overflowdot cursorpointer",
                                      },
                                      [
                                        _vm._v(
                                          "Start From:" +
                                            _vm._s(list.realExamStartTime) +
                                            " "
                                        ),
                                        list.realExamEndTime
                                          ? _c("span", [
                                              _vm._v(
                                                " — To:" +
                                                  _vm._s(list.realExamEndTime)
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          list.endCourseTime && list.borderType != 6
                            ? _c(
                                "p",
                                { staticClass: "overflowdot cursorpointer" },
                                [
                                  _vm._v(
                                    "Deal Deadline：" +
                                      _vm._s(list.endCourseTime)
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          list.borderType
                            ? _c("p", { staticClass: "b-desc" }, [
                                _vm._v("*此订单为B端订单"),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "list-detail" },
                        [
                          _c("span", { staticClass: "lighttext-box" }, [
                            _vm._v("预计收入" + _vm._s(list.planIncome) + "G"),
                          ]),
                          _vm._v(" "),
                          list.spoType == 65
                            ? _c(
                                "el-tag",
                                {
                                  staticClass:
                                    "el-icon-video-play cursorpointer noborder",
                                  on: {
                                    click: function ($event) {
                                      return _vm.lookVideo(list)
                                    },
                                  },
                                },
                                [_vm._v("论文大礼包辅导视频")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          list.spoType == 67 && list.change != 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "lighttext-box cursorpointer lighttextlight-box",
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotopaper(list)
                                    },
                                  },
                                },
                                [_vm._v("毕业论文规划表")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "专业：" + _vm._s(list.professionalCourseName)
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "list-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "default-btn",
                            on: {
                              click: function ($event) {
                                return _vm.showModel(list, 0)
                              },
                            },
                          },
                          [_vm._v("退单")]
                        ),
                        _vm._v(" "),
                        !_vm.hasBeike(list)
                          ? _c(
                              "button",
                              {
                                staticClass: "defaultlight-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.showModel(list, 1)
                                  },
                                },
                              },
                              [_vm._v("接单")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasBeike(list)
                          ? _c(
                              "button",
                              {
                                staticClass: "defaultlight-btn schedule",
                                on: {
                                  click: function ($event) {
                                    return _vm.showModel(list, 1)
                                  },
                                },
                              },
                              [_vm._v("接单并前往备课平台")]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  _vm.tableDataEnd.length > 0
                    ? _c("el-pagination", {
                        staticStyle: { "margin-top": "15px", float: "right" },
                        attrs: {
                          "current-page": _vm.currentPage,
                          "page-sizes": [15, 20],
                          "page-size": _vm.limit,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.total,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.tableDataEnd.length && _vm.isactive == 0
                    ? [
                        _vm.tableDataName
                          ? _c("contentnull", {
                              attrs: { statuscode: _vm.tableDataName ? 3 : 0 },
                            })
                          : _c("handsUp", { attrs: { type: "2" } }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isactive == 1
            ? _c("myorder", {
                attrs: {
                  postTitle: _vm.liactive,
                  tableDataNameto: _vm.searchtext,
                  timechangesort: _vm.timeIsSort,
                },
                on: { updategetTime: _vm.getUser },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isactive == 2 ? _c("salarySystem") : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("modelbox", {
        attrs: { datas: _vm.testOrderObj, type: "1", current: _vm.clickObj },
        on: {
          receivOrder: _vm.receivOrderTest,
          closeModal: _vm.closeModalTest,
        },
      }),
      _vm._v(" "),
      _vm.maskShow
        ? _c("div", { staticClass: "new-mask" }, [
            _c(
              "div",
              {
                staticClass: "mask-content",
                staticStyle: { "z-index": "999999" },
              },
              [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.modelObj.title) + " "),
                  _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
                ]),
                _vm._v(" "),
                !_vm.modelObj.imgShow
                  ? _c("img", {
                      staticClass: "tips-icon",
                      attrs: { src: require("@/assets/images/login-tips.png") },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.modelObj.content
                  ? _c("p", {
                      staticClass: "two-box",
                      staticStyle: {
                        "padding-left": "20px",
                        "padding-right": "20px",
                      },
                      domProps: { innerHTML: _vm._s(_vm.modelObj.content) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.modelObj.zuoyecontent1
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "padding-left": "20px",
                          "padding-right": "20px",
                          "text-align": "left",
                        },
                      },
                      [_vm._v(_vm._s(_vm.modelObj.zuoyecontent1))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.modelObj.zuoyecontent2
                  ? _c("p", {
                      staticClass: "two-box",
                      staticStyle: {
                        "padding-left": "20px",
                        "padding-right": "20px",
                        "text-align": "left",
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.modelObj.zuoyecontent2),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "btnbox" }, [
                  _vm.modelObj.btnno
                    ? _c(
                        "button",
                        {
                          staticClass: "default-btn forget-pwd",
                          on: { click: _vm.closeMask },
                        },
                        [_vm._v(_vm._s(_vm.modelObj.btnno))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.modelObj.btnyes
                    ? _c(
                        "button",
                        {
                          staticClass: "defaultlight-btn forget-pwd",
                          on: { click: _vm.yesFun },
                        },
                        [_vm._v(_vm._s(_vm.modelObj.btnyes))]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showvideo, width: "50%" },
          on: {
            close: _vm.closevideo,
            "update:visible": function ($event) {
              _vm.showvideo = $event
            },
          },
        },
        [
          _c("video", {
            ref: "myvideo",
            staticStyle: { width: "100%" },
            attrs: {
              autoplay: "",
              id: "myvideo",
              oncontextmenu: "return false;",
              controls: "false",
              src: _vm.videourl,
            },
          }),
          _vm._v(" "),
          _vm.videoduration
            ? _c("div", { staticClass: "canclick-box" }, [
                _vm._v(
                  _vm._s(_vm.videocurrent) +
                    "/" +
                    _vm._s(_vm.videoduration) +
                    " "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("orderReceiving", {
        attrs: { isShow: _vm.showInfo, current: _vm.clickObj },
        on: {
          receivOrder: _vm.receivOrder,
          closeModal: _vm.closeModal,
          playVideo: _vm.playDLWvideo,
        },
      }),
      _vm._v(" "),
      _c("examTip", {
        ref: "examTip",
        attrs: { loading: _vm.loading, current: _vm.clickObj },
        on: { receivOrder: _vm.receivOrder },
      }),
      _vm._v(" "),
      _c("newGetOrder", {
        ref: "newGetOrder",
        attrs: { loading: _vm.loading, current: _vm.clickObj },
        on: { receivOrder: _vm.receivOrder },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tips-box" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/timemodal-bj.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n              经常忘记Deal Deadline时间？"),
        _c("br"),
        _vm._v("\n              一键点击，订单自动根据Deal Deadline时间顺序"),
        _c("br"),
        _vm._v("\n              排列，记得优先处理时间近的哦~\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "certificate-contarner",
      style: _vm.staticPath.BG,
    },
    [
      _c("section", { staticClass: "cup-contarner" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/hatch/cert/cup.png") },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "label" }, [_vm._v("欢迎加入万能讲师")]),
        _vm._v(" "),
        _c("span", { staticClass: "tips", on: { click: _vm.toImage } }, [
          _vm._v("下载证书"),
        ]),
        _vm._v(" "),
        _vm.type == 1
          ? _c("div", { staticClass: "btn", on: { click: _vm.openLink } }, [
              _vm._v("开启讲师生涯"),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("section", { ref: "imageTofile", staticClass: "doc-container" }, [
        _c("span", { staticClass: "date" }, [
          _vm._v(_vm._s(_vm._f("fmtDate")(_vm.info.updateAt))),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "title" }, [
          _vm._v("万能讲师团队" + _vm._s(_vm.info.id) + "号讲师"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "name" }, [
          _c("span", [_vm._v(_vm._s(_vm.info.nickName))]),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "under" }, [_vm._v("能者为师")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "cert",
          attrs: { src: require("@/assets/images/hatch/cert/cert.png") },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "shadow",
          attrs: { src: require("@/assets/images/hatch/cert/shadow.png") },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
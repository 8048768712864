var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "up-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "516px",
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "animation-box" }, [
            _c("div", { attrs: { id: "uplevel" } }),
            _vm._v(" "),
            _c("div", { attrs: { id: "uplevel1" } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "up-content" }, [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/level/golevel-success.png"),
                  alt: "",
                },
              }),
              _vm._v("等级提升到 "),
              _c("span", [
                _vm._v(
                  "Lv" + _vm._s(_vm.upDetail.now && _vm.upDetail.now.level)
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                _vm._s(_vm.upDetail.now && _vm.upDetail.now.hourlyWage * 10) +
                  " "
              ),
              _c("span", [_vm._v("RMB/H")]),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v("升级后时薪")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "operate-btn", on: { click: _vm.seeUpLevel } },
            [_vm._v("朕知道了")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.paperRouterPath = exports.WishType = exports.TaskState = exports.TableEvent = exports.StudentMakeClassStatus = exports.Storage = exports.StageType = exports.StageStatus = exports.StageField = exports.StageEvent = exports.StageClassesType = exports.StageClassFileType = exports.ReturnCode = exports.OwnClassStatus = exports.LcType = exports.HjType = exports.HeaderStatused = exports.HatchRouterPath = exports.DissertationRouterPath = exports.CrmRouterLinkArgs = exports.ClassUploadFileType = exports.ClassStatus = exports.CalendarVisited = exports.CalendarModelType = exports.AlertModalType = exports.AddClasses = void 0;
/**
 * 日历组件
 * @readonly
 * @enum {Number}
 */
var CalendarModelType = exports.CalendarModelType = {
  COURSE: 2 /*课程 */,
  STUDENT: 0 /* 学生 */,
  TEACHER: 1 /* 老师 */
};

/**
 * 讲师孵化端 路由路径
 * @readonly
 * @enum {String}
 */
var HatchRouterPath = exports.HatchRouterPath = {
  REDIRECT: "/hatch" /* 重定向 */,
  LETTER: "/letter" /* 讲师信 1 开启讲师信 */,
  PLAN: "/trainingplan" /* 万能讲师岗前培训图 2 */,
  FAKE: "/fake" /* 模拟订单操作 3 */,
  GROUND: "/trainingground" /* 课堂训练场 4 */,
  FAKE_CLASS: "/fakeclass" /* 全真模拟课 5 */,
  FAKE_CLASS_OLD: "/fakeclassold" /* 全真模拟课 5 */,
  CLASS_CARD: "/schedulecard" /* 课程表 6 */,
  INTENTION: "/intention" /* 意向表 7 (查看产品培训视频)*/,
  INTENTIONOLD: "/intentionold" /* 意向表 7 */,
  ID_FORM: "/idform" /* 讲师形象 8 */,
  CERT: "/certificate" /* 入职证书 9 */,
  QR_CODE: "/classbro_app" /* 讲师app下载 */,
  PERSON_DETAIL: "/account/persondetail" /* 孵化完成 跳转个人中心页面 */
};

/**
 * localStorage 枚举
 * @readonly
 * @enum {Number | String}
 */
var Storage = exports.Storage = {
  INTERVAL: 1 /* 倒计时 定时器 */,
  FAKE_SPEED: 2 /* 模拟订单操作 样式定时器 */,
  TEACHER_ID: 3 /* 当前讲师id */,
  CLASS_NO: 4 /* 课堂 编号 */,
  TEACHER_INFO: "teacinfo" /* 讲师登录信息 */,
  TOKEN: "teactoken" /* 讲师 登录token */,
  COURSE_ID: 7 /* dissertation courseId */,
  READ_ONLY: 8 /* dissertation readonly */,
  CRM_LINK_DISS: "CrmLinkDiss" /* crm跳转到规划表 */,
  NEW_LINK_DISS: "NewLinkDiss" /* 新crm跳转到规划表 */
};

/**
 * 岗前培训流程图 状态枚举
 * @readonly
 * @enum {Number}
 */
var TaskState = exports.TaskState = {
  DONE: 1 /* 已完成 */,
  DOING: 2 /* 正在进行 */,
  TODO: 3 /* 未完成 */
};

/**
 * next 返回枚举类型
 * @readonly
 * @enum {Number} 1 成功，且有下一步 2 全部完成 3 无效操作
 */
var ReturnCode = exports.ReturnCode = {
  SUCCESS: 1 /* 操作成功 */,
  OVER: 2 /* 全部完成 */,
  NON: 3 /* 无效操作 */
};

/**
 * 我的全真模拟课 statused 状态枚举
 * @readonly
 * @enum {Number}
 * 状态 0-待排课  1-已排课，未审核 2-已结束,待报告 4-已填写报告,待填写意愿单 5已评级 8-完成  16-已排课，已审核，进行中  17-审核未通过  18-上课中
 */
var OwnClassStatus = exports.OwnClassStatus = {
  UNAUDITED: 1 /* 未审核 */,
  END: 2 /* 已结束 */,
  REPORT_DONE: 4 /* 已填写报告 */,
  IS_LEVEL: 5 /* 已评级 */,
  COMPLETE: 8 /* 完成 */,
  ONGOING: 16 /* 进行中 待上课 */,
  AUDIT_FAILED: 17 /* 审核未通过 */,
  IN_CLASS: 18 /* 上课中 */
};

/**
 * 学生预约课程状态枚举
 * @readonly
 * @enum {Number}
 * 状态  1：待上课 2：完成，未填写报告 4：完成，已填写报告 18-上课中 19-已下课，未填写报告
 */
var StudentMakeClassStatus = exports.StudentMakeClassStatus = {
  WAIT: 1 /* 待上课 */,
  COMPLETE: 2 /* 完成 */,
  REPORT_DONE: 4 /* 已填写报告 */,
  IN_CLASS: 18 /* 上课中 */,
  IS_REPORT: 19 /* 已下课，未填写报告 */
};

/**
 * 课堂训练场 弹窗类型枚举
 */
var AlertModalType = exports.AlertModalType = {
  QUESTION: 1 /* 遇到问题 */,
  TODO: 2 /* 未完成课堂任务 */,
  DONE: 3 /* 已完成课堂任务 */,
  REACTION: 4 /* reaction 回调 */
};

/**
 * 意向表 意向类型
 */
var WishType = exports.WishType = {
  // CUSTOM: 0 /* 定制辅导 */,
  ASSAULT: 1 /* 考前突击 */,
  GIFT: 65 /* 论文大礼包 */,
  SPECIAL: 66 /* 特殊订单 */
};

/**
 * 流程类型
 */
var LcType = exports.LcType = {
  // NORMAL: 0 /* 普通流程类型 */,
  // IS_REAL: 1 /* 全真模拟课 */,
  // FAKE_ORDER: 2 /* 模拟订单流程 */,
  // SYSTEM: 4 /* 系统操作培训 */,
  // SYSTEMTWO: 8/* 系统操作培训2.0 */,
  // ORDER_TRAIN:2/* 订单模拟培训 */
  CLASSTRAIN: 16,
  /* 课程培训 */
  ACMTESRT: 128,
  /* 学术检测 */
  BEFORETRAIN: 256,
  /* 岗前培训 */
  CLOSElOOP: 8 /*闭环管理 */
};

/**
 * 环节类型
 * 环节类型：0-视频环节 1-文档环节 2-测试题环节 4-无文档环节 8-课堂训练场 16-模拟订单 32-讲师and学生 64-讲师意愿单 128-Fo考前突击 256-Fo论文大礼包 512-Fo特殊订单
 */
var HjType = exports.HjType = {
  TRAINING_GROUND: 8 /* 课堂训练场 */
};

/**
 * 日历模式下 访问状态
 */
var CalendarVisited = exports.CalendarVisited = {
  VISITED: "visited" /* 已经访问过 */,
  NOT_YET: "notyet" /* 还没有访问过 */
};

/* ********************************************* 毕业论文大礼包 *********************************************** */

/**
 * 毕业论文大礼包 路由路径
 * @readonly
 * @enum {Number}
 */
var DissertationRouterPath = exports.DissertationRouterPath = {
  HOME: "/dissertation" /* 首页 */
};

/**
 * 论文大礼包路由
 */
var paperRouterPath = exports.paperRouterPath = {
  HOME: "/paperSchedule" /* 首页 */
};
/**
 * 阶段 字段枚举
 */
var StageField = exports.StageField = {
  ORDER: "order" /* 阶段顺序 */,
  PHASE: "phase" /* 课程阶段 */,
  CONTAIN: "contain" /* 包含课程 */,
  ALREADY: "already" /* 已上课时长 */,
  SUMMARY: "summary" /* 阶段总结 */
};

/**
 * 阶段状态  0未开始  1正在进行 2已完成
 */
var StageStatus = exports.StageStatus = {
  TODO: 0 /* 0未开始 */,
  DOING: 1 /* 1正在进行 */,
  DONE: 2 /* 2已完成 */
};

/**
 * 阶段类型枚举
 */
var StageType = exports.StageType = {
  BREAKING_ICE: 0 /* 1 破冰阶段 */,
  FIXED_TOPIC: 1 /* 1 定题阶段 */,
  INTRODUCTION: 2 /* 2 introduction */,
  REVIEW: 3 /* 3 Literature Review  */,
  METHODOLOGY: 4 /* 4 Methodology */,
  ANALYSIS: 5 /* 5 Data Analysis */,
  CONCLUSION: 6 /* 6 Dicussion&Conclusion */,
  EXAMINE: 7 /* 7 梳理全文 */
};

/**
 * 课堂状态 枚举（进行中，已完成，未完成）
 * 状态 课堂说明 0未开启 1待排课 2待上课 3已完成
 * @enum
 */
var ClassStatus = exports.ClassStatus = {
  TODO: 0 /* 0未开启 */,
  WAIT: 1 /* 1待排课 */,
  BEGIN: 2 /* 2待上课 */,
  DONE: 3 /* 3已完成 */
};

/**
 * 课程类别 是否是加课 0否 1是
 * @enum
 */
var AddClasses = exports.AddClasses = {
  NO: 0 /* 0否 */,
  YES: 1 /* 1是 */
};

/**
 * 课程类别
 * 阶段课类型 1必须上课  2可选上课  3 必论文修改
 * @enum
 */
var StageClassesType = exports.StageClassesType = {
  MUST: 1 /* 1必须上课 */,
  SELECTABLE: 2 /* 2可选上课 */,
  DISSERTATION: 3 /* 3 必论文修改 */
};

/**
 * Table Event
 * @enum
 */
var TableEvent = exports.TableEvent = {
  LOOK_UP: 1 /* 查看 */,
  UPLOAD: 2 /* 上传 */,
  EDIT: 3 /* 修改 */,
  ARRANGE: 4 /* 排课 */
};

/**
 * Stage Event
 */
var StageEvent = exports.StageEvent = {
  MODIFIED: 1 /* 修改 */,
  FEEDBACK: 2 /* 反馈 */
};

/**
 * 讲师材料 stageClassFileType
 * 阶段课堂文件类型
 * 1topicList  2 outLine  3 批改文件
 * 4 data Results 5 Final Document
 * 6 Research List 7 批改文件完整
 * @enum
 */
var StageClassFileType = exports.StageClassFileType = {
  TOPIC_LIST: 1 /* 1topicList */,
  OUTLINE: 2 /* 2 outLine */,
  CORRECTION: 3 /* 3 批改文件 */,
  DATA_RESULTS: 4 /* 4 data Results */,
  FINAL_DOC: 5 /* 5 Final Document */,
  RESEARCH_LIST: 6 /* 6 Research List */,
  COMPLETE: 7 /* 7 批改文件完整 */
};

/**
 * 课堂上传文件类型
 * 1 毕业大论文订单课堂讲师材料 2 毕业大论文创建引流订单 学生文件 3 毕业大论文创建收费订单 学生近期自主完成的essay 4 毕业大论文阶段信息 学生资料 5 毕业大论文阶段必论文修改 上传文件
 * @enum
 */
var ClassUploadFileType = exports.ClassUploadFileType = {
  TEACHER_MATERIAL: 1 /* 毕业大论文订单课堂讲师材料 */,
  MUST_MATERIAL: 5 /* 毕业大论文阶段必论文修改 */
};

/**
 * 当前大论文订单进展状态
 * @enum
 */
var HeaderStatused = exports.HeaderStatused = {
  DONE: 3 /* 已完成 */,
  OVER: 4 /* 已结课 */,
  ERR_OVER: 5 /* 异常结课 */
};

/**
 * crm 跳转规划表 参数hashkey 枚举
 * @enum
 */
var CrmRouterLinkArgs = exports.CrmRouterLinkArgs = {
  TOKEN: "Q7HbyVkEtAGTKBk5",
  COURSE_ID: "b7L3ZNXIKy42qV39",
  PATH: "5AOriMixcF9r5s50"
};
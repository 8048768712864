"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.urlList = void 0;
var urlList = exports.urlList = {
  0: 'customizedTutoring',
  27: 'paperPolishing',
  26: 'dbCoursePackage'
};
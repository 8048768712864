"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("../detail/css/conmmon.scss");
var _auth = require("@/utils/auth");
var _fakecourse = require("@/views/hatch/api/fakecourse");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  filters: {
    rounding: function rounding(value) {
      return value.toFixed(2);
    },
    spliting: function spliting(value) {
      return value.substr(0, 10);
    }
  },
  data: function data() {
    return {
      checkedAll: false,
      coursesItem: [],
      bigClassRoom: false,
      personid: '',
      currentPage: 0,
      pageSize: 0,
      total: 0,
      limit: 0,
      personpage: 1,
      personlimit: 5,
      persondata: [],
      personmodel: false,
      aboutOrder: false,
      radshow: false,
      fileList1: [],
      myHeaders1: {
        token: window.localStorage.getItem('teactoken')
      },
      url1: this.$api.baseURL + 'sys/seller/order/evidenceThumbSave',
      data1: {
        amount: '',
        current: '',
        studId: ''
      },
      b1: false,
      b2: '确 定',
      s1: true,
      s2: true,
      red: '',
      radio: '1',
      badMoney: '',
      isFromOrder: true,
      mm1: false,
      mm: false,
      studCourses: [],
      fileList: [],
      myHeaders: {
        token: window.localStorage.getItem('teactoken')
      },
      url: this.$api.baseURL + 'sys/seller/courseware/save',
      data: {
        cdId: '',
        groupId: ''
      },
      btnstatus: false,
      dialogVisible: false,
      checked: false,
      courses: '',
      history: '',
      urls: [],
      id: '',
      classroomdata: [],
      extSysPaymentdata: [],
      studCoursewareList: [],
      filelist: [],
      ruleForm: {
        preferential: '',
        teacNickName: '',
        materialCost: '',
        course: '',
        parentTotalPrice: '',
        Integer: '',
        parentLastPrepayment: '',
        temCourseName: '',
        universityName: '',
        statused: '',
        studUser: ''
      },
      ruleForm1: {
        username: '',
        nickname: '',
        sellerDemandDesc: '',
        persenterTime: '',
        allSchooltime: '',
        reaMonetary: '',
        current: '',
        amount1: '',
        amount: '',
        studname: '',
        studId: ''
      },
      ruleForm2: {
        courseCode: '',
        courseName: '',
        type: '',
        current: '',
        amount: '',
        studId: '',
        id: '',
        amount1: '',
        courseId: ''
      },
      rules1: {},
      rules: {}
    };
  },
  mounted: function mounted() {
    this.getclassroomdata();
    this.coursedata();
  },
  methods: {
    checkAllFun: function checkAllFun() {
      var _this = this;
      console.log('选择了', this.checkedAll);
      this.coursesItem.forEach(function (item) {
        item.check = _this.checkedAll;
      });
      this.$forceUpdate();
    },
    singleCheckFun: function singleCheckFun(files) {
      var _this2 = this;
      var filesNums = this.coursesItem.length,
        counts = 0;
      this.coursesItem.forEach(function (item, index) {
        if (item.id == files.id) {
          _this2.$set(_this2.coursesItem[index], 'check', files.check);
          _this2.$forceUpdate();
        }
        if (item.check) {
          counts += 1;
        }
      });
      console.log(filesNums, counts);
      if (filesNums == counts) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false;
      }
      this.$forceUpdate();
    },
    openUrl: function openUrl(url) {
      window.open(url, '_blank');
    },
    downFun: function downFun() {
      var downloadArr = [];
      this.coursesItem.length && this.coursesItem.forEach(function (file) {
        if (file.check) {
          downloadArr.push({
            fileName: file.name,
            fileUrl: file.url
          });
        }
      });
      if (downloadArr.length == 0) {
        return this.$message.error("请至少选择一条");
      }
      this.downloadFile(downloadArr);
    },
    downloadFile: function downloadFile(downloadArr) {
      var _this3 = this;
      (0, _fakecourse.getZipFilePlus)(downloadArr).then(function (res) {
        if (res.status == 200) {
          _this3.$message({
            type: "success",
            message: "文件打包成功"
          });
          var url = res.body.url;
          window.open(url, '_blank');
        } else {
          _this3.$message({
            type: "warning",
            message: res.body.msg
          });
        }
      }).catch(function (err) {
        _this3.$message({
          type: "warning",
          message: body.msg
        });
      });
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    success: function success(response, file, fileList) {
      if (response.status == 200) {
        this.$notify({
          title: '提示',
          message: '上传文件成功',
          type: 'success'
        });
        // this.fileList = []
        // $('.ul').empty()
        // this.getclassroomdata()
        window.location.reload();
      }
      if (response.status == 400) {
        this.$notify({
          title: '提示',
          message: response.body.msg,
          type: 'warning'
        });
        this.fileList = [];
      }
    },
    onerror: function onerror(response, file, fileList) {
      this.$notify({
        title: '提示',
        message: response.body.msg,
        type: 'warning'
      });
    },
    change_lession: function change_lession(row, index) {
      window.location.href = 'editClassroomOrder.html?id=' + row.courseId + '';
    },
    change: function change(file, fileList) {
      // getUrlStr = function(name) {
      //     var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      //     var r = window.location.search.substr(1).match(reg)
      //     if (r != null) return unescape(r[2])
      //     return null
      // }
      var id = this.$route.query.id;
      this.data.cdId = id;
    },
    handleRemove: function handleRemove(file, fileList) {},
    uploadFile: function uploadFile(file) {
      this.formDate = new FormData();
      this.formDate.append('file', file.file);
    },
    submitUpload: function submitUpload() {
      this.$refs.upload.submit();
    },
    check: function check(val) {
      // if (val == true) {
      //     $('.checkbox').prop('checked', true)
      // } else {
      //     $('.checkbox').prop('checked', false)
      // }
    },
    coursedata: function coursedata() {
      var _this4 = this;
      this.$axios({
        url: this.$api.baseURL + '/courseware/groupList',
        params: {
          page: 1,
          limit: 1000
        }
      }).then(function (r) {
        var lists = [];
        r.data.body.list.forEach(function (element) {
          var list = {};
          list.groupName = element.groupName;
          list.name = element.groupName.split(' ')[0];
          list.id = element.id;
          lists.push(list);
        });
        _this4.courses = lists;
      });
    },
    change_course: function change_course(courseid) {
      this.data.groupId = courseid;
    },
    // downfile() {
    //     this.$notify({
    // title: '提示',
    //         message: '文件正在服务器打包,请耐心等待,不要刷新页面',
    //         type: 'success'
    //     })
    //     this.btnstatus = true
    //     var arr = []
    //     var checkedCount = 0
    //     for (var i = 0; i < $('#ul li input').length; i++) {
    //         var Uarry = $('#ul li input')
    //         var Tresult = Uarry.eq(i).attr('data-name')
    //         var checkbox = document.getElementsByName('tie_in[]')
    //         if (checkbox[i].checked) {
    //             checkedCount++
    //             arr.push(Tresult)
    //         }
    //     }
    //     var arrjoin = arr.join(';')
    //     $.ajax({
    //         type: 'GET',
    //         dataType: 'json',
    //         headers: {
    //             'token': token
    //         },
    //         data: {
    //             fileStr: arrjoin
    //         },
    //         url: '' + baseURL + '/resource/getZipFile',
    //         success: function(json) {
    //             if (json.status == 200) {
    //                 this.btnstatus = false
    //                 this.$notify({
    // title: '提示',
    //                     message: '文件正在服务器打包,请耐心等待,不要刷新页面',
    //                     type: 'success'
    //                 })
    //                 window.open(json.body.filePath)
    //             }
    //         }
    //     })
    // },
    // del(row, index) {
    //     this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //     }).then(() => {
    //         $.ajax({
    //             type: 'POST',
    //             dataType: 'json',
    //             headers: {
    //                 'token': token
    //             },
    //             data: JSON.stringify([row.id]),
    //             url: '' + baseURL + 'sys/seller/courseware/delete',
    //             success: function(json) {
    //                 this.$notify({
    // title: '提示',
    //                     type: 'success',
    //                     message: '删除成功!'
    //                 })
    //                 this.getclassroomdata()
    //             }
    //         })
    //     }).catch(() => {})
    // },
    getclassroomdata: function getclassroomdata() {
      var _this5 = this;
      // getUrlStr = function(name) {
      //     var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      //     var r = window.location.search.substr(1).match(reg)
      //     if (r != null) return unescape(r[2])
      //     return null
      // }
      var id = this.$route.query.id;
      var parentId = this.$route.query.parentId;
      if (parentId == 'null') {
        // $('#parents').css('display', 'none')
        document.getElementById('parents').style.display = 'none';
      }
      this.bigClassRoom = true;
      this.$axios({
        url: this.$api.baseURL + '//classcourse/queryClassCourseInfo?id=' + id + '',
        params: {
          'page': 1,
          'limit': 6,
          'isBig': true
        }
      }).then(function (r) {
        var json = r.data;
        if (json.status == 400) {
          alert(json.body.msg);
          return;
        }
        _this5.classroomdata = json.body.classroomList;
        if (json.body.studPurchaseOrder.endCourseTime !== null) {
          _this5.ruleForm.endCourseTime = json.body.studPurchaseOrder.endCourseTime.substring(0, 10);
        }
        if (json.body.studPurchaseOrder.examTime !== null) {
          _this5.ruleForm.examTime = json.body.studPurchaseOrder.examTime.substring(0, 10);
        }
        _this5.ruleForm.orderNo = json.body.orderNo;
        _this5.ruleForm.explained = json.body.studPurchaseOrder.sellerDemandDesc;
        _this5.ruleForm.temCourseName = json.body.studPurchaseOrder.courseName;
        _this5.ruleForm.courseCode = json.body.studPurchaseOrder.courseCode;
        _this5.ruleForm.universityName = json.body.studPurchaseOrder.universityName;
        _this5.ruleForm.sysSeller = json.body.sysSeller.username;
        _this5.ruleForm.teacNickName = json.body.teacNickName;
        _this5.ruleForm.professionalName = json.body.professionalName;
        _this5.extSysPaymentdata = json.body.studClassStudents;
        var studPreferentialPolicies = [];
        json.body.studPreferentialPolicies.forEach(function (element) {
          studPreferentialPolicies.push(Number(element.preferential) + '折');
        });
        _this5.ruleForm.preferential = studPreferentialPolicies.join(';');
        if (json.body.statused == 8) {
          _this5.ruleForm.statused = '待规划(等待教师/运营接单)';
        }
        if (json.body.statused == 16) {
          _this5.ruleForm.statused = '规划中(订单第一次超时等待运营接单)';
        }
        if (json.body.statused == 32) {
          _this5.ruleForm.statused = '规划中(订单第二次超时等待运营主管强制指派)';
        }
        if (json.body.statused == 64) {
          _this5.ruleForm.statused = '规划中(运营接单待指派，正在处理)';
        }
        if (json.body.statused == 128) {
          _this5.ruleForm.statused = '待排课';
        }
        if (json.body.statused == 256) {
          _this5.ruleForm.statused = '已排课';
        }
        if (json.body.statused == 512) {
          _this5.ruleForm.statused = '待结课';
        }
        if (json.body.statused == 1024) {
          _this5.ruleForm.statused = '已结课';
        }
        if (json.body.statused == 4096) {
          _this5.ruleForm.statused = '已取消';
        }
        if (json.body.statused == 65536) {
          _this5.ruleForm.statused = '事故单申请中';
        }
        if (json.body.statused == 262144) {
          _this5.ruleForm.statused = '事故单更换教师';
        }
        _this5.bigClassRoom = false;
        _this5.coursesItem = JSON.parse(JSON.stringify(r.data.body.studCoursewareList));
        for (var i in _this5.coursesItem) {
          _this5.coursesItem[i].checkbox = false;
        }
        // var studCoursewareList = json.body.studCoursewareList
        // var groupName = studCoursewareList.groupName
        // var arr1 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Requirement') {
        //         for (i = 0; i < 1; i++) {
        //             var array1 = {}
        //             array1.name = value.name
        //             array1.createAt = value.createAt
        //             array1.url = value.url
        //             array1.id = value.id
        //             arr1.push(array1)
        //         }
        //     }
        // })
        // arr1.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('.Requirement').append(html)
        // })
        // var arr2 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Unit Guide(Syllabus)') {
        //         for (i = 0; i < 1; i++) {
        //             var array2 = {}
        //             array2.name = value.name
        //             array2.createAt = value.createAt
        //             array2.url = value.url
        //             array2.id = value.id
        //             arr2.push(array2)
        //         }
        //     }
        // })
        // arr2.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('#Unit').append(html)
        // })
        // var arr3 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Lecture note') {
        //         for (i = 0; i < 1; i++) {
        //             var array3 = {}
        //             array3.name = value.name
        //             array3.createAt = value.createAt
        //             array3.url = value.url
        //             array3.id = value.id
        //             arr3.push(array3)
        //         }
        //     }
        // })
        // arr3.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('#Lecture').append(html)
        // })
        // var arr4 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Sample') {
        //         for (i = 0; i < 1; i++) {
        //             var array4 = {}
        //             array4.name = value.name
        //             array4.createAt = value.createAt
        //             array4.url = value.url
        //             array4.id = value.id
        //             arr4.push(array4)
        //         }
        //     }
        // })
        // arr4.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('#Sample').append(html)
        // })

        // var arr5 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Readings') {
        //         for (i = 0; i < 1; i++) {
        //             var array5 = {}
        //             array5.name = value.name
        //             array5.createAt = value.createAt
        //             array5.url = value.url
        //             array5.id = value.id
        //             arr5.push(array5)
        //         }
        //     }
        // })
        // arr5.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('#Readings').append(html)
        // })
        // var arr6 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Other') {
        //         for (i = 0; i < 1; i++) {
        //             var array6 = {}
        //             array6.name = value.name
        //             array6.createAt = value.createAt
        //             array6.url = value.url
        //             array6.id = value.id
        //             arr6.push(array6)
        //         }
        //     }
        // })
        // arr6.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('#Other').append(html)
        // })
        // var arr7 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Tutor Material') {
        //         for (i = 0; i < 1; i++) {
        //             var array7 = {}
        //             array7.name = value.name
        //             array7.createAt = value.createAt
        //             array7.url = value.url
        //             array7.id = value.id
        //             arr7.push(array7)
        //         }
        //     }
        // })
        // arr7.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('#Tutor').append(html)
        // })
        // var arr8 = []
        // studCoursewareList.map(function(value, key) {
        //     if (value.groupName == 'Additional') {
        //         for (i = 0; i < 1; i++) {
        //             var array8 = {}
        //             array8.name = value.name
        //             array8.createAt = value.createAt
        //             array8.url = value.url
        //             array8.id = value.id
        //             arr8.push(array8)
        //         }
        //     }
        // })
        // arr8.map(item => {
        //     var html = "<li data-id='" + item.id +
        //             "'><input class='checkbox' name='tie_in[]' type='checkbox' data-name='" + item.url +
        //             "'/><a class='view' data-name='" + item.url + "'>" + item.name + '</a>' + item
        //         .createAt + "<button class='button'>删除</button></li>"
        //     $('#Additional').append(html)
        // })

        // $('.button').click(function() {
        //     var id = $(this).parent('li').attr('data-id')
        //     this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(() => {
        //         $.ajax({
        //             type: 'POST',
        //             contentType: 'application/json',
        //             headers: {
        //                 'token': token
        //             },
        //             data: JSON.stringify([id]),
        //             url: '' + baseURL + 'sys/seller/courseware/delete',
        //             success: function(json) {
        //                 this.$notify({
        // title: '提示',
        //                     type: 'success',
        //                     message: '删除成功!'
        //                 })
        //                 $('ul').html('')
        //                 this.getclassroomdata()
        //             }
        //         })
        //     }).catch(() => {})
        // })

        // $(".view").click(function(){
        //   var name = $(this).attr("data-name");
        //   window.open( "viewpdf.html?name="+name+"");
        // });

        // $('.view').click(function() {
        //     var name = $(this).attr('data-name')
        //     window.open(name)
        // })
      });
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     data: {
      //         'token': token,
      //         'page': 1,
      //         'limit': 6,
      //         'isBig': true
      //     },
      //     url: '' + baseURL + '//classcourse/queryClassCourseInfo?id=' + id + '',
      //     success: function(json) {

      //     }
      // })
    },
    apply: function apply(row, index) {
      this.mm1 = true;
      this.type = row.type;
      this.badMoney = row.badMoney;
      this.ruleForm.studId = row.studId;
      this.ruleForm.current = row.current;
      this.ruleForm.id = row.id;
      this.amount = row.badMoney;
      this.ruleForm1.studname = row.nickName;
      this.ruleForm1.amount1 = row.minMoney;
      this.ruleForm1.amount = row.badMoney;
      if (row.parentId == null) {
        this.radshow = false;
        this.radio = '2';
      } else {
        this.radshow = true;
        this.radio = '1';
      }
      if (row.courseId == null) {
        this.ruleForm.courseId = ' ';
      } else {
        this.ruleForm.courseId = row.courseId;
      }
      return;
    },
    save1: function save1(formName) {
      var _this6 = this;
      var self = this;
      if (this.radio == '1') {
        this.isFromOrder = true;
      } else {
        this.isFromOrder = false;
      }
      this.creatst1 = true;
      this.creatbtn1 = '加载中';
      this.$axios({
        method: 'post',
        url: this.$api.baseURL + '/sys/seller/order/offsetPaymentDemand',
        data: {
          'studId': this.ruleForm.studId,
          'currency': this.ruleForm.current,
          'payMoney': this.ruleForm1.amount,
          'sysPaymentDemandId': this.ruleForm.id,
          'isFromOrder': this.isFromOrder
        }
      }).then(function (r) {
        var json = r.data;
        if (json.status == 200) {
          _this6.creatmodel1 = false;
          _this6.creatst1 = false;
          _this6.creatbtn1 = '确 定';
          if (json.body.needMoney == 0) {
            self.$notify({
              title: '提示',
              message: '账单支付成功',
              type: 'success'
            });
            _this6.getclassroomdata();
            _this6.mm1 = false;
          } else {
            _this6.mm1 = false;
            _this6.ruleForm.amount = json.body.needMoney;
            _this6.ruleForm.amount1 = _this6.ruleForm1.amount;
            _this6.ruleForm.studname = _this6.ruleForm1.studname;
            _this6.mm = true;
          }
        }
        if (json.status == 400) {
          _this6.$notify({
            title: '提示',
            message: json.body.msg,
            type: 'warning'
          });
          _this6.creatst1 = false;
          _this6.creatbtn1 = '确 定';
        }
      });
    },
    submitUpload1: function submitUpload1() {
      var _this7 = this;
      if (this.ruleForm.remark == undefined) {
        this.ruleForm.remark = ' ';
      }
      this.formDate = new FormData();
      this.$refs.upload.submit();
      this.formDate.append('current', this.ruleForm.current);
      this.formDate.append('studId', this.ruleForm.studId);
      this.formDate.append('amount', this.ruleForm.amount);
      this.formDate.append('remark', this.ruleForm.remark);
      this.formDate.append('courseId', this.ruleForm.courseId);
      var config = {
        headers: {
          processData: false,
          contentType: false,
          mimeType: 'multipart/form-data',
          token: window.localStorage.getItem('teactoken')
        }
      };
      this.$axios.post(this.$api.baseURL + 'sys/seller/order/evidenceThumbSave', this.formDate, config).then(function (res) {
        if (res.data.status == 200) {
          _this7.save1();
          _this7.$notify({
            title: '提示',
            message: '账单支付成功',
            type: 'success'
          });
          _this7.mm = false;
          _this7.getclassroomdata();
          _this7.fileList = [];
          _this7.ruleForm.remark = '';
          _this7.b1 = false;
          _this7.b2 = '确 定';
        }
        if (res.data.status == 400) {
          if (_this7.$refs.ruleForm.model.amount != '') {
            _this7.$notify({
              title: '提示',
              message: res.data.body.msg,
              type: 'warning'
            });
          }
          _this7.b1 = false;
          _this7.b2 = '确 定';
        }
      }).catch(function (res) {
        _this7.$notify({
          title: '提示',
          message: res.data.body.msg,
          type: 'warning'
        });
      });
    },
    save: function save(formName) {
      var _this8 = this;
      var badMoney = Number(this.badMoney);
      var amount = Number(this.ruleForm.amount);
      if (this.type == 1) {
        if (amount > badMoney) {
          this.red = '课程预付 可以少不可以多';
          return;
        }
      }
      if (this.type == 2) {
        if (amount < badMoney) {
          this.red = '欠费账单 可以多不可以少';
          return;
        }
      }
      if (this.fileListnum == 0) {
        this.$notify({
          title: '提示',
          message: '请选择图片',
          type: 'warning'
        });
        return;
      }
      var self = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this8.b1 = true;
          _this8.b2 = '加载中';
          _this8.submitUpload1();
        } else {
          _this8.$notify({
            title: '提示',
            message: '请完善信息',
            type: 'warning'
          });
        }
      });
    },
    handlePreview1: function handlePreview1(file) {
      console.log(file);
    },
    success1: function success1(response, file, fileList) {
      console.log(response);
    },
    onerror1: function onerror1(response, file, fileList) {
      this.$notify({
        title: '提示',
        message: response.body.msg,
        type: 'warning'
      });
    },
    change1: function change1(file, fileList) {
      this.fileListnum = fileList.length;
      // console.log(this.fileListnum)
      // if (fileList.length == 1) {

      // }
    },
    uploadFile1: function uploadFile1(file) {
      this.formDate = new FormData();
      for (var i = 0; i < this.fileListnum; i++) {
        this.formDate.append('file' + i, file.file);
      }
    },
    handleRemove1: function handleRemove1(file, fileList) {
      this.fileListnum = fileList.length;
      // console.log(this.fileListnum)
      // if (fileList.length == 1) {

      // }
    },
    deleteClasseoom: function deleteClasseoom(row, index) {
      var _this9 = this;
      // getUrlStr = function(name) {
      //     var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      //     var r = window.location.search.substr(1).match(reg)
      //     if (r != null) return unescape(r[2])
      //     return null
      // }
      var id = this.$route.query.id;
      this.$confirm('此操作将结束该班课, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this9.$axios({
          method: 'post',
          url: _this9.$api.baseURL + '/sys/seller/classcourse/finishedClassCourse?courseId=' + id + '',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function (r) {
          if (r.data.status == 200) {
            _this9.$notify({
              title: '提示',
              type: 'success',
              message: r.data.body.msg
            });
          }
          if (r.data.status == 400) {
            _this9.$notify({
              title: '提示',
              type: 'warning',
              message: r.data.body.msg
            });
          }
        });
        // $.ajax({
        //     type: 'POST',
        //     contentType: 'application/json',
        //     headers: {
        //         'token': token
        //     },
        //     url: '' + baseURL + '/sys/seller/classcourse/finishedClassCourse?courseId=' + id + '',
        //     success: function(json) {
        //         if (json.status == 200) {
        //             this.$notify({
        // title: '提示',
        //                 type: 'success',
        //                 message: json.body.msg
        //             })
        //         }

        //         if (json.status == 400) {
        //             this.$notify({
        // title: '提示',
        //                 type: 'warning',
        //                 message: json.body.msg
        //             })
        //         }
        //     }
        // })
      }).catch(function () {});
    },
    seebill: function seebill(row, index) {
      // getUrlStr = function(name) {
      //     var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      //     var r = window.location.search.substr(1).match(reg)
      //     if (r != null) return unescape(r[2])
      //     return null
      // }
      var id = this.$route.query.id;
      window.location.href = 'editClassroom.html?id=' + id + '';
    },
    addClasseoom: function addClasseoom(row, index) {
      // getUrlStr = function(name) {
      //     var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      //     var r = window.location.search.substr(1).match(reg)
      //     if (r != null) return unescape(r[2])
      //     return null
      // }
      var id = this.$route.query.id;
      window.location.href = 'addClassroom.html?id=' + id + '';
    },
    change_teacher: function change_teacher(row, index) {
      var _this10 = this;
      // getUrlStr = function(name) {
      //     var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      //     var r = window.location.search.substr(1).match(reg)
      //     if (r != null) return unescape(r[2])
      //     return null
      // }
      var id = this.$route.query.id;
      this.$confirm('是否执行此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (_) {
        _this10.$axios({
          url: _this10.$api.baseURL + 'sys/seller/order/applyAccidentOrder',
          method: 'post',
          data: {
            'courseId': id
          }
        }).then(function (r) {
          if (r.data.status == '200') {
            _this10.$notify({
              title: '提示',
              message: r.data.body.msg,
              type: 'success'
            });
          }
          if (r.data.status == 400) {
            _this10.$notify({
              title: '提示',
              message: r.data.body.msg,
              type: 'warning'
            });
          }
        });
        // $.ajax({
        //     type: 'POST',
        //     dataType: 'json',
        //     data: {
        //         'token': token,
        //         'courseId': id
        //     },
        //     url: '' + baseURL + 'sys/seller/order/applyAccidentOrder',
        //     success: function(json) {
        //         if (json.status == '200') {
        //             this.$notify({
        // title: '提示',
        //                 message: json.body.msg,
        //                 type: 'success'
        //             })
        //         }
        //         if (json.status == 400) {
        //             this.$notify({
        // title: '提示',
        //                 message: json.body.msg,
        //                 type: 'warning'
        //             })
        //         }
        //     }
        // })
      }).catch(function () {});
    },
    getperson: function getperson() {
      var _this11 = this;
      this.$axios({
        url: this.$api.baseURL + 'sys/seller/classcourse/queryStudByClassroomId',
        params: {
          'page': this.personpage,
          'limit': this.personlimit,
          'classroomId': this.personid
        }
      }).then(function (r) {
        _this11.persondata = r.data.body.list;
        _this11.total = r.data.body.totalCount;
        _this11.pageSize = r.data.body.totalCount;
        _this11.currentPage = r.data.body.currPage;
      });
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     data: {
      //         'token': token,
      //         'page': this.personpage,
      //         'limit': this.personlimit,
      //         'classroomId': this.personid
      //     },
      //     url: '' + baseURL + 'sys/seller/classcourse/queryStudByClassroomId',
      //     success: function(json) {
      //         this.persondata = json.body.list
      //         this.total = json.body.totalCount
      //         this.pageSize = json.body.totalCount
      //         this.currentPage = json.body.currPage
      //     }
      // })
    },
    person: function person(row, index) {
      this.personid = row.id;
      this.getperson();
      this.personmodel = true;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.personpage = val;
      this.getperson();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.personlimit = val;
      this.getperson();
      this.handleCurrentChange(this.currentPage);
    },
    editOrder: function editOrder(row, index) {
      window.location.href = 'editClassroomOrder.html?id=' + row.courseId + '';
    },
    seedetail: function seedetail(row, index) {
      var Logistics = this.$router.resolve({
        path: "/sellerDetail",
        query: {
          id: row.courseId,
          type: 'daobi'
        }
      });
      window.open(Logistics.href);
      // window.location.href = 'seller_detail.html?id=' + row.courseId + ''
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "460px",
            "show-close": false,
            "append-to-body": true,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("重要通知")]),
          _vm._v(" "),
          _c("div", { staticClass: "first-line" }, [
            _vm._v("尊敬的各位万能讲师:"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-desc" }, [
            _c("div", [
              _vm._v(
                "由于线上课堂系统迭代，本次系统升级的时间约为一周。目前线上环节课堂培训改为线下，我们的师资人员会和您预约线下课堂培训时间，给您带来不便我们深表歉意。"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "defaultlight-btn", on: { click: _vm.closeModal } },
            [_vm._v("我知道了 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      !_vm.disabled
        ? _c("uploadFile", {
            ref: "upload",
            staticClass: "upload-file",
            attrs: { text: "上传备考审核表" },
            on: { uploadFiles: _vm.uploadFiles },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("file-list", {
        attrs: {
          fileList: _vm.referenceFiles,
          filesName: "referenceFiles",
          formName: "testForm",
          disabled: _vm.disabled,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "desc-content" }, [
        _c("div", { staticClass: "desc-title" }, [_vm._v("备考审核表说明：")]),
        _vm._v(" "),
        _c("div", [_vm._v("尊敬的各位讲师请仔细阅读以下须知：")]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v(
            "2.我们希望您认真完成备考内容，以满足服务质量，请注意提前预留时间并且满是以下备考要求："
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "sample-desc" }, [
          _vm._v("我们在下方为您准备了备考审核表的两份sample,仅作内容参考："),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sample-box" },
          _vm._l(_vm.sampleList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                on: {
                  click: function ($event) {
                    return _vm.openLink(item)
                  },
                },
              },
              [
                _c("img", { attrs: { src: item.img, alt: "" } }),
                _vm._v("\n        " + _vm._s(item.name) + "\n      "),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _vm._v(
        "1.备考审核旨在了解各位讲师在准备的订单详细情况，同时也希望发现并解决讲师在备考过程中遇到的问题，请"
      ),
      _c("span", [_vm._v("至少在DDL提前24小时上传")]),
      _vm._v("备考内容。"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(
        "1）请确保您查阅了学生所有的订单内容，如遇到缺少部分订单需求的内容文件，如"
      ),
      _c("span", [_vm._v("往期习题或课堂课件")]),
      _vm._v("等，请及时联系师资人员索要。"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("2）备考复习的内容可以是学生"),
      _c("span", [_vm._v("pastpaper")]),
      _vm._v("的完成记录，知识点重难点高亮以及批注，明确学生的订单完成内容。"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("3）上传的"),
      _c("span", [_vm._v("文件格式不限")]),
      _vm._v(
        "，且可以上传多份文件来佐证您有很好的准备此次订单内容，我们的师资人员会逐一审核，如遇到不符合备考内容的最低要求，可能会被"
      ),
      _c("span", [_vm._v("退回重做")]),
      _vm._v("甚至"),
      _c("span", [_vm._v("取消此次委托。")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _vm._v("3.最后当您查阅完整信息之后，"),
      _c("span", [_vm._v("发现考试内容难度与预期不符，或遇到其他困难的情况")]),
      _vm._v("，请及时向我方师资人员提出。"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.remindOptions = void 0;
/* 教师约课 上课提醒下拉列表 */
var remindOptions = exports.remindOptions = [{
  label: "不提醒",
  value: "1"
}, {
  label: "开始时提醒",
  value: "2"
}, {
  label: "5分钟前",
  value: "3"
}, {
  label: "10分钟前",
  value: "4"
}, {
  label: "15分钟前",
  value: "5"
}, {
  label: "30分钟前",
  value: "6"
}, {
  label: "1小时前",
  value: "7"
}];
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mask-content mask-width560",
      class: {
        "min-content":
          _vm.newPreExam ||
          _vm.BBPreExamOrCustomized ||
          (_vm.orderObj.spoType == 67 &&
            _vm.orderObj.change == 1 &&
            !_vm.orderObj.borderType),
      },
    },
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.isEdit ? "修改课堂" : "安排课堂")),
        _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
        _vm._v(" "),
        _c("div", { staticClass: "tip" }, [
          _vm._v("课堂排课时间统一为北京时间,请讲师注意时差"),
        ]),
      ]),
      _vm._v(" "),
      _vm.showfillNeed
        ? _c(
            "div",
            {
              staticStyle: {
                color: "red",
                "text-align": "center",
                "margin-bottom": "10px",
              },
            },
            [_vm._v("请在破冰课上课前完成【破冰课授课要求】填写")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.addLoading,
              expression: "addLoading",
            },
          ],
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.ruleForm, rules: _vm.rules },
        },
        [
          _c(
            "div",
            { staticClass: "content-box" },
            [
              _c(
                "div",
                { staticClass: "dis-flex" },
                [
                  _c("img", {
                    staticClass: "cinput-icon",
                    attrs: {
                      src: require("../../assets/images/icon-course.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "cinput-label" }, [
                    _vm._v("选择订单"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "order" } },
                    [
                      _c(
                        "el-select",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.orderLoading,
                              expression: "orderLoading",
                            },
                          ],
                          staticStyle: { width: "340px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择课堂",
                            disabled: Boolean(_vm.courseDetail) || _vm.isEdit,
                          },
                          on: { change: _vm.chooseOrder },
                          model: {
                            value: _vm.ruleForm.order,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "order", $$v)
                            },
                            expression: "ruleForm.order",
                          },
                        },
                        _vm._l(_vm.orderlist, function (item) {
                          return _c("el-option", {
                            key: item.courseId,
                            attrs: {
                              label: item.orderNo,
                              value: item.courseId,
                              disabled:
                                item.spoType == 4 ||
                                item.spoType == 32 ||
                                item.spoType == 64 ||
                                item.spoType == 66,
                            },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm.orderObj.spoType == 67 &&
                      _vm.orderObj.change == 1 &&
                      !_vm.orderObj.borderType
                        ? _c("div", { staticClass: "papers-tips" }, [
                            _vm._v(
                              "\n              毕业大论文需要前往论文规划表进行排课\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.newPreExam
                        ? _c("div", { staticClass: "papers-tips" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.orderObj.borderType
                                    ? "此订单为B端考前突击订单，"
                                    : ""
                                ) +
                                "考前突击请前往规划表进行排课\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.BBPreExamOrCustomized
                        ? _c("div", { staticClass: "overtime-tips" }, [
                            _vm._v("\n              学生存在"),
                            _c("span", [_vm._v("欠费")]),
                            _vm._v("，"),
                            _c("span", [_vm._v("无法排课")]),
                            _vm._v("，请联系"),
                            _c("span", [_vm._v("师资运营")]),
                            _vm._v("或"),
                            _c("span", [_vm._v("教辅顾问")]),
                            _vm._v("处理！\n\n            "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.newPreExam && !_vm.BBPreExamOrCustomized
                ? [
                    _vm.orderObj.change != 1 && _vm.newPreExam
                      ? _c(
                          "div",
                          { staticClass: "dis-flex" },
                          [
                            _c("img", {
                              staticClass: "cinput-icon",
                              attrs: {
                                src: require("../../assets/images/icon-course.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { staticClass: "cinput-label" }, [
                              _vm._v("课堂类型"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "stageType" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "340px" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择课堂类型",
                                    },
                                    model: {
                                      value: _vm.ruleForm.stageType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "stageType", $$v)
                                      },
                                      expression: "ruleForm.stageType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.preExamClassTypeList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.stageType,
                                        attrs: {
                                          label: item.name,
                                          value: item.stageType,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !(_vm.orderObj.change == 1 && !_vm.orderObj.borderType)
                      ? _c(
                          "div",
                          { staticClass: "dis-flex" },
                          [
                            _c("img", {
                              staticClass: "cinput-icon",
                              attrs: {
                                src: require("../../assets/images/icon-course.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { staticClass: "cinput-label" }, [
                              _vm._v("课堂名称"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "name" } },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    height: "36px",
                                    width: "340px",
                                  },
                                  attrs: {
                                    placeholder: "请输入课堂名称",
                                    disabled: _vm.isEdit,
                                  },
                                  model: {
                                    value: _vm.ruleForm.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "name", $$v)
                                    },
                                    expression: "ruleForm.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.orderObj.spoType == 65 &&
                    _vm.orderObj.courseVersion > 1 &&
                    !_vm.orderObj.borderType
                      ? [
                          _c(
                            "div",
                            { staticClass: "dis-flex" },
                            [
                              _c("img", {
                                staticClass: "cinput-icon",
                                attrs: {
                                  src: require("../../assets/images/icon-time.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { staticClass: "cinput-label" }, [
                                _vm._v("课堂阶段"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "stageId" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.stageLoading,
                                          expression: "stageLoading",
                                        },
                                      ],
                                      staticStyle: { width: "340px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择课堂阶段",
                                      },
                                      on: { change: _vm.chooseStage },
                                      model: {
                                        value: _vm.ruleForm.stageId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "stageId", $$v)
                                        },
                                        expression: "ruleForm.stageId",
                                      },
                                    },
                                    _vm._l(_vm.stagelist, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label:
                                            item.name +
                                            "(" +
                                            item.startDate +
                                            "-" +
                                            item.endDate +
                                            ")",
                                          value: item.id,
                                          disabled:
                                            item.spoType == 4 ||
                                            item.spoType == 32 ||
                                            item.spoType == 64 ||
                                            item.spoType == 66,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  !_vm.stagelist.length
                                    ? _c(
                                        "div",
                                        { staticClass: "papers-tips" },
                                        [_vm._v("暂无阶段请先前往规划表排课")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dis-flex" },
                            [
                              _c("img", {
                                staticClass: "cinput-icon",
                                attrs: {
                                  src: require("../../assets/images/icon-time.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { staticClass: "cinput-label" }, [
                                _vm._v("课堂任务"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "taskId" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "340px" },
                                      attrs: { placeholder: "请选择课堂任务" },
                                      model: {
                                        value: _vm.ruleForm.taskId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "taskId", $$v)
                                        },
                                        expression: "ruleForm.taskId",
                                      },
                                    },
                                    _vm._l(_vm.list, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.remark,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    !(_vm.orderObj.change == 1 && !_vm.orderObj.borderType)
                      ? _c(
                          "div",
                          { staticClass: "dis-flex" },
                          [
                            _c("img", {
                              staticClass: "cinput-icon",
                              attrs: {
                                src: require("../../assets/images/icon-desc.png"),
                              },
                            }),
                            _vm._v(" "),
                            _c("label", { staticClass: "cinput-label" }, [
                              _vm._v("课堂说明"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "description" } },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ruleForm.description,
                                      expression: "ruleForm.description",
                                    },
                                  ],
                                  staticClass: "cinput-textarea",
                                  attrs: { placeholder: "请输入课堂说明" },
                                  domProps: { value: _vm.ruleForm.description },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "description",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !(_vm.orderObj.change == 1 && !_vm.orderObj.borderType)
                      ? _c("div", { staticClass: "dis-flex" }, [
                          _c("img", {
                            staticClass: "cinput-icon",
                            attrs: {
                              src: require("../../assets/images/icon-time.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { staticClass: "cinput-label" }, [
                            _vm._v("开课日期"),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "p",
                              { staticStyle: { "text-align": "left" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "190px" },
                                  attrs: {
                                    type: "date",
                                    "picker-options": {
                                      disabledDate: function disabledDate(
                                        time
                                      ) {
                                        return (
                                          time.getTime() <
                                          new Date(_vm.beijingTime).getTime() -
                                            8.64e7
                                        )
                                      },
                                    },
                                    placeholder: "开始日期",
                                    format: "yyyy 年 MM 月 dd 日",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  on: { change: _vm.changpick },
                                  model: {
                                    value: _vm.dateStart,
                                    callback: function ($$v) {
                                      _vm.dateStart = $$v
                                    },
                                    expression: "dateStart",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-time-select", {
                                  staticStyle: {
                                    width: "135px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    placeholder: "起始时间",
                                    "picker-options": {
                                      start: "00:00",
                                      step: "00:10",
                                      end: "24:00",
                                      minTime: _vm.minstartTime,
                                    },
                                  },
                                  on: { change: _vm.getEndTime },
                                  model: {
                                    value: _vm.ruleForm.startTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "startTime", $$v)
                                    },
                                    expression: "ruleForm.startTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !(_vm.orderObj.change == 1 && !_vm.orderObj.borderType)
                      ? _c(
                          "div",
                          {
                            staticClass: "dis-flex",
                            staticStyle: { "margin-left": "67px" },
                          },
                          [
                            _c("label", { staticClass: "cinput-label" }, [
                              _vm._v("课节时长"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "time-select",
                                    attrs: { placeholder: "", filterable: "" },
                                    on: { change: _vm.getEndTime },
                                    model: {
                                      value: _vm.ruleForm.hour,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "hour", $$v)
                                      },
                                      expression: "ruleForm.hour",
                                    },
                                  },
                                  _vm._l(24, function (item, index) {
                                    return _c("el-option", {
                                      key: item,
                                      attrs: {
                                        label:
                                          index < 10 ? "0" + index : "" + index,
                                        value: String(index),
                                      },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v("\n              小时\n              "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "time-select",
                                    attrs: { placeholder: "", filterable: "" },
                                    on: { change: _vm.getEndTime },
                                    model: {
                                      value: _vm.ruleForm.minute,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "minute", $$v)
                                      },
                                      expression: "ruleForm.minute",
                                    },
                                  },
                                  _vm._l(_vm.minList, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: { label: item, value: item },
                                    })
                                  }),
                                  1
                                ),
                                _vm._v("\n              分钟\n              "),
                                _vm.classEndTime
                                  ? _c("div", { staticClass: "end" }, [
                                      _vm._v(
                                        "下课时间：" + _vm._s(_vm.classEndTime)
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !(_vm.orderObj.change == 1 && !_vm.orderObj.borderType)
                      ? _c("div", { staticClass: "dis-flex time-tip" }, [
                          _vm._v("请输入准确上课时长到达结束时间"),
                          _c("span", [_vm._v("20分钟")]),
                          _vm._v("后课堂关闭"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showExtra
                      ? _c("div", { staticClass: "dis-flex used-tip" }, [
                          _vm._v(
                            "此订单排课时长为" +
                              _vm._s(_vm.usedTime) +
                              "h/" +
                              _vm._s(_vm.totalClassTime) +
                              "h，超出将不会为您结算"
                          ),
                          _c("span", [_vm._v("超额薪资")]),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          !(_vm.orderObj.change == 1 && !_vm.orderObj.borderType) &&
          !_vm.newPreExam &&
          !_vm.BBPreExamOrCustomized
            ? _c(
                "div",
                { staticClass: "dis-flex" },
                [
                  _c("img", {
                    staticClass: "cinput-icon",
                    attrs: {
                      src: require("../../assets/images/icon-course.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "cinput-label" }, [
                    _vm._v("提醒"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "beforeNotifyTime" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "340px" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm.beforeNotifyTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "beforeNotifyTime", $$v)
                            },
                            expression: "ruleForm.beforeNotifyTime",
                          },
                        },
                        _vm._l(_vm.timeTip, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      !_vm.newPreExam && !_vm.BBPreExamOrCustomized
        ? [
            _vm.showfillNeed
              ? _c(
                  "div",
                  { staticStyle: { "line-height": "30px", color: "red" } },
                  [_vm._v("排课后前往填写破冰课授课需求")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.orderObj.spoType == 67 &&
            _vm.orderObj.change == 1 &&
            !_vm.orderObj.borderType
              ? _c("div", { staticClass: "btnbox" }, [
                  _c(
                    "button",
                    {
                      staticClass: "defaultlight-btn btn-width320",
                      on: {
                        click: function ($event) {
                          return _vm.gotopaper()
                        },
                      },
                    },
                    [_vm._v("查看论文规划表")]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            !(_vm.orderObj.change == 1 && !_vm.orderObj.borderType)
              ? _c("div", { staticClass: "btnbox" }, [
                  _c(
                    "button",
                    {
                      staticClass: "defaultlight-btn btn-width320",
                      on: {
                        click: function ($event) {
                          return _vm.submitForm1("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.newPreExam
        ? [
            _c(
              "button",
              {
                staticClass: "defaultlight-btn btn-width320",
                on: {
                  click: function ($event) {
                    return _vm.goToPreExamSchedule(_vm.orderObj)
                  },
                },
              },
              [_vm._v("查看考前突击规划表")]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
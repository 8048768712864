var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "560px",
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("Exam&Quiz订单处理流程变动"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v("尊敬的各位万能讲师你们好:"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", [
                _vm._v(
                  "为了更好的提升讲师质量，即日起我们会调整Exam&Quiz订单的服务流程，整体拆分为两步："
                ),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("step 1：填写订单相关内容以及备考审核表。")]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "step 2：后台审核通过，参与后续服务内容，完成订单内容。"
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "同时，相关结课薪酬比例也会分步结算，完成订单审核内容并且通过的讲师，即可 获得预计收入的20%（当前订单薪酬锁定），反之则失去，当顺利完成整笔订单即可获得全额薪酬。"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tip" }, [
                _vm._v("请注意：此部分的20%会锁定至订单结课之后全额发放。"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "defaultlight-btn",
                  attrs: {
                    slot: "footer",
                    disabled: !_vm.showClose && _vm.needTime,
                  },
                  on: { click: _vm.closeModal },
                  slot: "footer",
                },
                [
                  _vm._v("我已知晓 "),
                  _vm.time && _vm.needTime
                    ? _c("span", [_vm._v("(" + _vm._s(_vm.time) + ")")])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingCourse,
          expression: "loadingCourse",
        },
      ],
      staticClass: "my-order",
    },
    [
      _vm.tableDataEnd.length > 0
        ? _c(
            "div",
            { staticClass: "order-content" },
            _vm._l(_vm.tableDataEnd, function (list, i) {
              return _c("div", { staticClass: "order-list" }, [
                list.noReadAdditionaCount > 0
                  ? _c("div", { staticClass: "noReadAdditiona-styles" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/seller/additional-icon.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("订单Additional信息有更新,请查看")]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "order-header" },
                  [
                    _c("div", { staticClass: "order-statuswrap" }, [
                      _c(
                        "span",
                        [
                          list.spoType == 67
                            ? _c(
                                "span",
                                { staticClass: "order-statusbox" },
                                [
                                  !list.borderType
                                    ? [
                                        list.courseStatus < 512
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  list.studBigPaperStage &&
                                                    list.studBigPaperStage
                                                      .stageName
                                                )
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.courseStatusType67[
                                                    list.courseStatus
                                                  ]
                                                )
                                              ),
                                            ]),
                                      ]
                                    : [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.OrderStatus[list.courseStatus]
                                            )
                                          ),
                                        ]),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          list.courseVersion != 2 || list.courseVersion < 2
                            ? [
                                list.spoType < 66 ||
                                ([66, 69].includes(Number(list.spoType)) &&
                                  list.courseStatus == 512) ||
                                list.spoType == 71
                                  ? _c(
                                      "span",
                                      { staticClass: "order-statusbox" },
                                      [
                                        list.courseStatus != 128
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.OrderStatus[
                                                    list.courseStatus
                                                  ]
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        list.courseStatus == 128 &&
                                        list.spoType == 65
                                          ? _c("span", [_vm._v("待排破冰课")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        list.courseStatus == 128 &&
                                        list.spoType != 65
                                          ? _c("span", [_vm._v("待排课")])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          [66, 69].includes(Number(list.spoType)) &&
                          list.startTimeEnd &&
                          list.courseStatus != 512
                            ? _c(
                                "span",
                                { staticClass: "order-statusbox" },
                                [
                                  (list.spoType == 66 &&
                                    list.studSpecialOrderExt &&
                                    list.studSpecialOrderExt.type != 128) ||
                                  (list.spoType == 69 &&
                                    list.copywritingCourse &&
                                    list.copywritingCourse.type != 3)
                                    ? [
                                        list.deadlineHasPassed
                                          ? _c(
                                              "span",
                                              { staticClass: "bgcolorout" },
                                              [
                                                _vm._v(
                                                  " 已超时" +
                                                    _vm._s(list.startTimeEnd)
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "bgcoloring" },
                                              [
                                                _vm._v("距离结束还有"),
                                                _c(
                                                  "i",
                                                  {
                                                    staticStyle: {
                                                      color: "#f00",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(list.startTimeEnd)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ]
                                    : [
                                        _c(
                                          "span",
                                          {
                                            class: list.isTimeOut
                                              ? "bgcolorout"
                                              : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  list.isTimeOut
                                                    ? list.realExamStartTime
                                                      ? "考试已开始"
                                                      : "已超时"
                                                    : list.realExamStartTime ==
                                                        null &&
                                                      list.teacherLatestCommitTime ==
                                                        null
                                                    ? "时间待确定"
                                                    : "等待考试" +
                                                      list.startTimeEnd
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      list.spoType == 65 && list.courseVersion == 2
                        ? _c(
                            "span",
                            { staticClass: "order-statusbox" },
                            [
                              list.courseStatus == 512
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.OrderStatus[list.courseStatus])
                                    ),
                                  ])
                                : [
                                    list.lwdlbStageStatus == 1 &&
                                    !list.borderType
                                      ? _c(
                                          "span",
                                          { staticClass: "bgorange" },
                                          [_vm._v(" 请前去制作辅导规划表")]
                                        )
                                      : [
                                          list.deadlineHasPassed
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "bgcolorout",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " 已超时" +
                                                      _vm._s(list.startTimeEnd)
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                { staticClass: "bgcoloring" },
                                                [
                                                  _vm._v("距离结束还有"),
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "#f00",
                                                        "font-size": "12px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          list.startTimeEnd
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                        ],
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: [
                          list.starFlag ? _vm.lightstarimg : _vm.graystarimg,
                        ],
                      },
                      on: {
                        click: function ($event) {
                          return _vm.lightStar(list, i)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "give",
                      attrs: {
                        src: _vm.canSend(list)
                          ? require("@/assets/images/order/zeng.png")
                          : require("@/assets/images/order/nozeng.png"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.give_time(list, i)
                        },
                      },
                    }),
                    _vm._v(" "),
                    false
                      ? _c(
                          "el-dropdown",
                          { on: { command: _vm.handleCommands } },
                          [
                            _c("div", { staticClass: "more-icon" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/images/myorder-more.png"),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            ![32, 66, 69].includes(Number(list.spoType))
                              ? _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown", size: "small" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: {
                                            type: "applycourse",
                                            params: list,
                                          },
                                        },
                                      },
                                      [
                                        list.paperStatus == 40 &&
                                        list.spoType == 65 &&
                                        list.courseStatus != 512
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                  申请结课\n                "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        (list.statused > 1 ||
                                          list.spoType > 1) &&
                                        list.spoType != 64 &&
                                        list.statused != 4 &&
                                        list.spoType != 65
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                  申请结课\n                "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    list.statused != 1 && !list.borderType
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: {
                                                type: "givetime",
                                                params: list,
                                              },
                                            },
                                          },
                                          [
                                            list.statused >= 2 &&
                                            list.statused <= 8 &&
                                            list.spoType != 64
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.give_time(
                                                          list,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("赠送时长")]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            list.statused < 2 ||
                                            list.statused > 8 ||
                                            list.spoType == 64
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.give_time(
                                                          list,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "赠送时长\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    list.statused === 1 && !list.borderType
                                      ? _c(
                                          "el-dropdown-item",
                                          { attrs: { disabled: "" } },
                                          [
                                            _vm._v(
                                              "\n                赠送时长\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown", size: "small" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        attrs: {
                                          command: {
                                            type: "applycourse",
                                            params: list,
                                          },
                                        },
                                      },
                                      [_vm._v("申请结课")]
                                    ),
                                    _vm._v(" "),
                                    list.studSpecialOrderExam &&
                                    list.studSpecialOrderExam.zhentikuFlag
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.showSubmitwork(list)
                                              },
                                            },
                                          },
                                          [_vm._v("提交作业")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !list.borderType
                                      ? _c(
                                          "el-dropdown-item",
                                          { attrs: { disabled: "" } },
                                          [_vm._v("赠送时长")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "list-status" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: list.orderNo,
                          placement: "top",
                        },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass: "overflowdot cursorpointer",
                            on: {
                              click: function ($event) {
                                return _vm.see(list, i)
                              },
                            },
                          },
                          [_vm._v(_vm._s(list.orderNo) + "\n            ")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-text" }, [
                      (list.studSpecialOrderExt &&
                        list.studSpecialOrderExt.type == 128) ||
                      (list.spoType == 69 &&
                        list.copywritingCourse &&
                        list.copywritingCourse.type == 3)
                        ? _c("div", { staticClass: "ordertest-box" }, [
                            _vm._m(0, true),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      list.spoType == 65 && list.courseVersion == 2
                        ? _c("div", { staticClass: "stageshow-box" }, [
                            list.lwdlbStageSituationDetail &&
                            list.lwdlbStageSituationDetail.stageSunCount &&
                            !list.borderType
                              ? _c("p", { staticClass: "now-stage" }, [
                                  list.lwdlbStageSituationDetail
                                    .imminentTimeoutStage.stageName
                                    ? _c("b", [_vm._v("Now：")])
                                    : _vm._e(),
                                  _vm._v(
                                    _vm._s(
                                      list.lwdlbStageSituationDetail
                                        .imminentTimeoutStage.stageName
                                    ) + " "
                                  ),
                                  list.lwdlbStageSituationDetail
                                    .imminentTimeoutStage.taskCount
                                    ? _c("b", { staticClass: "text-bule" }, [
                                        _vm._v(
                                          "待完成" +
                                            _vm._s(
                                              list.lwdlbStageSituationDetail
                                                .imminentTimeoutStage
                                                .taskCount || 0
                                            ) +
                                            "项"
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !list.lwdlbStageSituationDetail.stageSunCount &&
                            !list.borderType
                              ? _c("p", { staticClass: "none-stage" }, [
                                  _vm._v("请确定"),
                                  _c("b", { staticClass: "font-red" }, [
                                    _vm._v("破冰阶段"),
                                  ]),
                                  _vm._v("时间周期，并在阶段内安排"),
                                  _c("b", { staticClass: "text-red" }, [
                                    _vm._v("破冰课"),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            list.lwdlbStageSituationDetail.timeOutStageCount >
                              0 && !list.borderType
                              ? _c("p", { staticClass: "now-delay" }, [
                                  _vm._v("已有阶段延期，请立即处理 "),
                                  _c("span", [_vm._v(" 1 ")]),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "stageshow-box" }, [
                        list.borderType
                          ? _c("p", { staticClass: "font-red" }, [
                              _vm._v("此订单为B端订单"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        [0, 1, 65, 67, 71, 26].includes(list.spoType)
                          ? _c("p", { staticClass: "font-red" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.needSchedule(list)
                                    ? "规划表功能已迁移，请前往【备课中心】"
                                    : "前往【备课中心】备课&排课"
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn-box" },
                        [
                          (list.studSpecialOrderExt &&
                            list.studSpecialOrderExt.type == 128) ||
                          (list.spoType == 69 &&
                            list.copywritingCourse &&
                            list.copywritingCourse.type == 3)
                            ? _c("div", { staticClass: "ordertest-box" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "new-btn",
                                    class: {
                                      "time-out": list.buttonStatus == 4,
                                      "qc-free": list.buttonStatus == 6,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showTestBox(list)
                                      },
                                    },
                                  },
                                  [
                                    list.buttonStatus == 6 &&
                                    list.qcFree != 0 &&
                                    (list.isExamTime || list.isWeek)
                                      ? [
                                          _vm.teacherType == 2
                                            ? _c("img", {
                                                staticClass: "auto",
                                                attrs: {
                                                  src: require("@/assets/images/order/autopass.png"),
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                    免提交\n\t\t\t\t\t\t\t\t "
                                          ),
                                        ]
                                      : [
                                          _vm.buttonStatusImgList[
                                            list.buttonStatus
                                          ] &&
                                          !(
                                            list.qcFree != 0 &&
                                            (list.isExamTime || list.isWeek)
                                          ) &&
                                          _vm.teacherType == 2
                                            ? _c("img", {
                                                staticClass: "status-img",
                                                class: {
                                                  free: list.buttonStatus == 6,
                                                },
                                                attrs: {
                                                  src: _vm.buttonStatusImgList[
                                                    list.buttonStatus
                                                  ],
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                \t\t" +
                                              _vm._s(
                                                _vm.buttonStatusList[
                                                  list.buttonStatus
                                                ]
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          list.spoType == 67 &&
                          list.change != 0 &&
                          !list.borderType
                            ? _c(
                                "span",
                                {
                                  staticClass: "new-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.gotopaper(list)
                                    },
                                  },
                                },
                                [_vm._v("毕业论文规划表")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.hasBeike(list)
                            ? _c(
                                "button",
                                {
                                  staticClass: "new-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showSubmitwork(list)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      list.spoType == 67
                                        ? "完成文件"
                                        : "提交作业"
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasBeike(list)
                            ? _c(
                                "button",
                                {
                                  staticClass: "new-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToBeiKe(list)
                                    },
                                  },
                                },
                                [_vm._v("前往备课中心")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          [
                            ![32, 66, 69].includes(Number(list.spoType))
                              ? [
                                  (list.paperStatus == 40 &&
                                    list.spoType == 65 &&
                                    list.courseStatus != 512) ||
                                  ([65].includes(list.spoType) &&
                                    list.borderType)
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "new-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.applyover(list)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  申请结课\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  ((list.statused > 1 || list.spoType > 1) &&
                                    list.spoType != 64 &&
                                    list.statused != 4 &&
                                    list.spoType != 65) ||
                                  ([67, 1].includes(list.spoType) &&
                                    list.borderType)
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "new-btn ",
                                          on: {
                                            click: function ($event) {
                                              return _vm.applyover(list)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  申请结课\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "new-btn",
                                      attrs: {
                                        command: {
                                          type: "applycourse",
                                          params: list,
                                        },
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyover(list)
                                        },
                                      },
                                    },
                                    [_vm._v("申请结课")]
                                  ),
                                ],
                          ],
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("ul", [
                  ![66, 32, 69].includes(Number(list.spoType))
                    ? _c("li", [
                        _c("p", [_vm._v("剩余时长")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("globalTimeFilter")(list.residueTime)
                              ) +
                              "H"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [_vm._v("预计收入")]),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", { staticClass: "will" }, [
                        _vm._v(_vm._s(list.planIncome) + "G"),
                      ]),
                      list.lockTeacG
                        ? _c("span", [
                            _vm._v(" （"),
                            _c("img", {
                              staticClass: "lock",
                              attrs: {
                                src: require("@/assets/images/order/lock.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(_vm._s(list.lockTeacG) + "G）"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("p", [_vm._v("已收入")]),
                    _vm._v(" "),
                    _c("p", [
                      _c("span", { staticClass: "get" }, [
                        _vm._v(_vm._s(list.teacG) + "G"),
                      ]),
                      list.lockTeacG
                        ? _c("span", [
                            _vm._v(" （"),
                            _c("img", {
                              staticClass: "lock",
                              attrs: {
                                src: require("@/assets/images/order/lock.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(_vm._s(list.lockTeacG) + "G）"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("img", {
                      attrs: { src: _vm.coursetypeImg[list.spoType] },
                      on: {
                        click: function ($event) {
                          return _vm.play(list)
                        },
                      },
                    }),
                  ]),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tableDataEnd.length > 0
        ? _c("el-pagination", {
            staticStyle: { "margin-top": "15px", float: "right" },
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [16, 20],
              "page-size": _vm.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadDropBoxDialog
        ? _c("div", { staticClass: "new-mask" }, [
            _c(
              "div",
              {
                staticClass: "mask-content mask-width560",
                staticStyle: { width: "620px" },
              },
              [
                _c("h3", [
                  _vm._v("提交作业 "),
                  _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
                ]),
                _vm._v(" "),
                [16, 30].includes(Number(_vm.uploadDropBoxRadio)) &&
                _vm.uploadUseData.cwCount
                  ? _c("div", { staticClass: "tip" }, [
                      _vm._v(
                        "当前订单已提交Completed Work，如确定需二次提交，请联系订单师资老师同步此信息，否则存在学生提交错误文件风险！"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                ![0, 1, 66, 69, 71, 27].includes(Number(_vm.specialType))
                  ? _c("div", { staticClass: "coursewaressssss" }, [
                      _c(
                        "div",
                        {
                          staticClass: "dis-flex",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c("img", {
                            staticClass: "cinput-icon",
                            attrs: {
                              src: require("../../assets/images/icon-course.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { staticClass: "cinput-label" }, [
                            _vm._v("类型："),
                          ]),
                          _vm._v(" "),
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "11" },
                                model: {
                                  value: _vm.uploadDropBoxRadio,
                                  callback: function ($$v) {
                                    _vm.uploadDropBoxRadio = $$v
                                  },
                                  expression: "uploadDropBoxRadio",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.specialType == 67 &&
                                      _vm.uploadUseData.change == 0
                                      ? "完成文件"
                                      : "作业"
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: "12",
                                  disabled: !_vm.uploadUseData.hasPastPaper,
                                },
                                model: {
                                  value: _vm.uploadDropBoxRadio,
                                  callback: function ($$v) {
                                    _vm.uploadDropBoxRadio = $$v
                                  },
                                  expression: "uploadDropBoxRadio",
                                },
                              },
                              [_vm._v("Past Paper 答案")]
                            ),
                          ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "dis-flex" }, [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-upload.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("上传文件："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("uploaderbtn", {
                              attrs: {
                                commid: _vm.uploadFileData.stepList[5].id,
                                couseobj: _vm.uploadUseData,
                              },
                              on: {
                                setUploadList: _vm.setUploadList,
                                submitFile: _vm.submitFile,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "upload-common",
                                staticStyle: { padding: "10px 0" },
                                attrs: { i: "" },
                              },
                              _vm._l(_vm.uploadFilesLength, function (item) {
                                return _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        "/" +
                                        _vm._s(item.filesize) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteFile(item)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    item.percent > 0
                                      ? _c("div", { staticClass: "progress" }, [
                                          _c("div", {
                                            staticClass: "progress-bar",
                                            style: {
                                              width: item.percent + "%",
                                            },
                                          }),
                                        ])
                                      : _vm._e(),
                                    item.percent > 0
                                      ? _c("span", [
                                          _vm._v(_vm._s(item.percent) + "%"),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _c("div", { staticClass: "coursewaressssss" }, [
                      _c(
                        "div",
                        {
                          staticClass: "dis-flex",
                          staticStyle: { "margin-bottom": "20px" },
                        },
                        [
                          _c("img", {
                            staticClass: "cinput-icon",
                            attrs: {
                              src: require("../../assets/images/icon-course.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { staticClass: "cinput-label" }, [
                            _vm._v("类型："),
                          ]),
                          _vm._v(" "),
                          [66, 69, 71, 27].includes(Number(_vm.specialType))
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { width: "340px" },
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.change_classroom },
                                  model: {
                                    value: _vm.uploadDropBoxRadio,
                                    callback: function ($$v) {
                                      _vm.uploadDropBoxRadio = $$v
                                    },
                                    expression: "uploadDropBoxRadio",
                                  },
                                },
                                [
                                  _vm.specialOrderSonType == 1
                                    ? _c("el-option", {
                                        attrs: { label: "作业", value: "11" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.specialOrderSonType == 32
                                    ? _c("el-option", {
                                        attrs: {
                                          label: "Lecture Note",
                                          value: "19",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  (_vm.specialOrderSonType != 32 &&
                                    _vm.specialOrderSonType != 1) ||
                                  [69, 71, 27].includes(Number(_vm.specialType))
                                    ? _c("el-option", {
                                        attrs: {
                                          label: "Complete Work",
                                          value: [69].includes(+_vm.specialType)
                                            ? 30
                                            : 16,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.specialOrderSonType == 32
                                    ? _c("el-option", {
                                        attrs: {
                                          label: "Past-Paper的答案",
                                          value: "12",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.specialType == 0
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { width: "340px" },
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.change_classroom },
                                  model: {
                                    value: _vm.uploadDropBoxRadio,
                                    callback: function ($$v) {
                                      _vm.uploadDropBoxRadio = $$v
                                    },
                                    expression: "uploadDropBoxRadio",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        label: "Lecture Note",
                                        value: "19",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v(" (必传) ")]
                                      ),
                                      _vm._v("Lecture Note"),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.uploadUseData.hasPastPaper
                                    ? _c("el-option", {
                                        attrs: {
                                          label: "Complete Work",
                                          value: "16",
                                        },
                                      })
                                    : _c("el-option", {
                                        attrs: {
                                          label: "Past-Paper的答案",
                                          value: "12",
                                        },
                                      }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.specialType == 1
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { width: "340px" },
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.change_classroom },
                                  model: {
                                    value: _vm.uploadDropBoxRadio,
                                    callback: function ($$v) {
                                      _vm.uploadDropBoxRadio = $$v
                                    },
                                    expression: "uploadDropBoxRadio",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        label: "Lecture Note",
                                        value: "19",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [_vm._v(" (必传) ")]
                                      ),
                                      _vm._v("Lecture Note"),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    attrs: { label: "资料整理", value: "18" },
                                  }),
                                  _vm._v(" "),
                                  _vm.uploadUseData.hasPastPaper
                                    ? _c("el-option", {
                                        attrs: {
                                          label: "Past-Paper的答案",
                                          value: "12",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "dis-flex" }, [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-upload.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("上传文件："),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("uploaderbtn", {
                              staticClass: "btn-left",
                              attrs: {
                                commid: _vm.uploadFileData.stepList[5].id,
                                couseobj: _vm.uploadUseData,
                              },
                              on: {
                                setUploadList: _vm.setUploadList,
                                submitFile: _vm.submitFile,
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "upload-common",
                                staticStyle: { padding: "10px 0" },
                              },
                              _vm._l(_vm.uploadFilesLength, function (item) {
                                return _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        "/" +
                                        _vm._s(item.filesize) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteFile(item)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    item.percent > 0
                                      ? _c("div", { staticClass: "progress" }, [
                                          _c("div", {
                                            staticClass: "progress-bar",
                                            style: {
                                              width: item.percent + "%",
                                            },
                                          }),
                                        ])
                                      : _vm._e(),
                                    item.percent > 0
                                      ? _c("span", [
                                          _vm._v(_vm._s(item.percent) + "%"),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dis-flex" },
                  [
                    _c("i", { staticClass: "el-icon-switch-button" }),
                    _vm._v(" "),
                    _c("label", { staticClass: "cinput-label " }, [
                      _vm._v("\n            是否同步申请结课："),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "apply-radio",
                        attrs: { disabled: _vm.uploadUseData.isClassEnd == 1 },
                        model: {
                          value: _vm.isClassEnd,
                          callback: function ($$v) {
                            _vm.isClassEnd = $$v
                          },
                          expression: "isClassEnd",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("暂不结课"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "btnbox",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.uploadFileData.loading,
                            expression: "uploadFileData.loading",
                          },
                        ],
                        staticClass: "defaultlight-btn btn-width320",
                        attrs: { id: "allupload" },
                      },
                      [_vm._v("确定上传")]
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modal
        ? _c("div", { staticClass: "new-mask" }, [
            _c(
              "div",
              { staticClass: "mask-content mask-width560" },
              [
                _c("h3", [
                  _vm._v(" 排课 "),
                  _c(
                    "b",
                    {
                      on: {
                        click: function ($event) {
                          _vm.modal = false
                        },
                      },
                    },
                    [_vm._v("+")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: { model: _vm.ruleForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "dis-flex" },
                      [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-course.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("课堂名称"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "title" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "340px" },
                              model: {
                                value: _vm.ruleForm.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "title", $$v)
                                },
                                expression: "ruleForm.title",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dis-flex" },
                      [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-time.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("开始时间"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "value1" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "340px" },
                              attrs: {
                                type: "datetime",
                                "picker-options": _vm.pciker1,
                                "value-format": "yyyy-MM-dd HH:mm",
                                placeholder: "开始日期",
                              },
                              model: {
                                value: _vm.ruleForm.value1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "value1", $$v)
                                },
                                expression: "ruleForm.value1",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dis-flex" },
                      [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-time.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("结束时间"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "value2" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "340px" },
                              attrs: {
                                type: "datetime",
                                "value-format": "yyyy-MM-dd HH:mm",
                                "picker-options": _vm.pciker2,
                                placeholder: "结束日期",
                              },
                              model: {
                                value: _vm.ruleForm.value2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "value2", $$v)
                                },
                                expression: "ruleForm.value2",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dis-flex" },
                      [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-desc.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("课堂描述"),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { prop: "description" } }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleForm.description,
                                expression: "ruleForm.description",
                              },
                            ],
                            staticClass: "cinput-textarea",
                            domProps: { value: _vm.ruleForm.description },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ruleForm,
                                  "description",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "btnbox" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnstatus,
                          expression: "btnstatus",
                        },
                      ],
                      staticClass: "defaultlight-btn btn-width320",
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.btnval))]
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.maskShow
        ? _c("div", { staticClass: "new-mask" }, [
            _c("div", { staticClass: "mask-content" }, [
              _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
              _vm._v(" "),
              _c("img", {
                staticClass: "tips-icon",
                attrs: { src: require("../../assets/images/login-tips.png") },
              }),
              _vm._v(" "),
              _c("h5", {
                staticStyle: { "line-height": "32px" },
                domProps: { innerHTML: _vm._s(_vm.jiekeobj.text) },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.endcourseing,
                      expression: "endcourseing",
                    },
                  ],
                  staticClass: "btnbox",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "defaultlight-btn forget-pwd",
                      on: { click: _vm.noapply },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "default-btn forget-pwd",
                      on: { click: _vm.closeMask },
                    },
                    [_vm._v("取消")]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modal_time
        ? _c("div", { staticClass: "new-mask" }, [
            _c(
              "div",
              { staticClass: "mask-content mask-width560" },
              [
                _c("h3", [
                  _vm._v("赠送时长 "),
                  _c(
                    "b",
                    {
                      on: {
                        click: function ($event) {
                          _vm.modal_time = false
                        },
                      },
                    },
                    [_vm._v("+")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: { model: _vm.ruleForm, rules: _vm.rules },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "dis-flex" },
                      [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-course.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("选择课堂"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "classroom" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "340px" },
                                attrs: { placeholder: "请选择课堂" },
                                on: { change: _vm.change_classroom },
                                model: {
                                  value: _vm.ruleForm.classroom,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "classroom", $$v)
                                  },
                                  expression: "ruleForm.classroom",
                                },
                              },
                              _vm._l(_vm.classrooms, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dis-flex" },
                      [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-time.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("赠送时长"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { staticClass: "times", attrs: { prop: "times" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.ruleForm.times,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "times", $$v)
                                  },
                                  expression: "ruleForm.times",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("min"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dis-flex" },
                      [
                        _c("img", {
                          staticClass: "cinput-icon",
                          attrs: {
                            src: require("../../assets/images/icon-desc.png"),
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { staticClass: "cinput-label" }, [
                          _vm._v("赠送说明"),
                        ]),
                        _vm._v(" "),
                        _c("el-form-item", { attrs: { prop: "explain" } }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleForm.explain,
                                expression: "ruleForm.explain",
                              },
                            ],
                            staticClass: "cinput-textarea",
                            domProps: { value: _vm.ruleForm.explain },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ruleForm,
                                  "explain",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "btnbox" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.btnstatus,
                          expression: "btnstatus",
                        },
                      ],
                      staticClass: "defaultlight-btn btn-width320",
                      on: {
                        click: function ($event) {
                          return _vm.submitForm1("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.btnval))]
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.uploadFileData.show
        ? _c("div", { staticClass: "new-mask" }, [
            _c("div", { staticClass: "mask-content mask-width560" }, [
              _c("h3", [
                _vm._v("上传文件 "),
                _c(
                  "b",
                  {
                    on: {
                      click: function ($event) {
                        _vm.uploadFileData.show = false
                      },
                    },
                  },
                  [_vm._v("+")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "stepmodel-box flex-allcenter" },
                _vm._l(_vm.uploadFileData.stepList, function (item, i) {
                  return i < 5
                    ? _c(
                        "li",
                        {
                          class: !item.disablestatus || i == 4 ? "active" : "",
                        },
                        [
                          _vm.beforeId == item.id
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/images/upload-now.png"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          i < 4
                            ? _c("p", [_vm._v("STEP" + _vm._s(i + 1))])
                            : _c("p", [_vm._v("阅读文件")]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(item.name))]),
                          _vm._v(" "),
                          !item.disablestatus
                            ? _c("uploaderbtn", {
                                attrs: {
                                  commid: item.id,
                                  couseobj: _vm.uploadUseData,
                                },
                                on: {
                                  setUploadList: _vm.setUploadList,
                                  submitFile: _vm.submitFile,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _vm.uploadFileData.index == 2
                ? _c("div", { staticClass: "dis-flex" }, [
                    _c("img", {
                      staticClass: "cinput-icon",
                      attrs: {
                        src: require("../../assets/images/icon-desc.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "cinput-label cinput-width" }, [
                      _vm._v("学生全文初稿评级"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { width: "340px" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "320px" },
                            attrs: {
                              disabled: _vm.thispaperStatus == 30,
                              placeholder: "请评级",
                            },
                            on: { change: _vm.levelchange },
                            model: {
                              value: _vm.levelChoose,
                              callback: function ($$v) {
                                _vm.levelChoose = $$v
                              },
                              expression: "levelChoose",
                            },
                          },
                          _vm._l(_vm.leveloptions, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                        _vm._v(" "),
                        _vm.levelChoose == "1"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "green" },
                              },
                              [_vm._v("*高分论文 Perfect！*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.levelChoose == "2"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "green" },
                              },
                              [_vm._v("*论文可以通过*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.levelChoose == "3"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "red" },
                              },
                              [
                                _vm._v(
                                  "*可能是fail论文,请学生认真修改,论文挂单扣50%订单收入*"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.levelChoose == "4"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "red" },
                              },
                              [_vm._v("*必定不及格论文，挂单扣50%订单收入*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.levelChoose == "A"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "green" },
                              },
                              [_vm._v("*高分论文 Perfect！*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.levelChoose == "B"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "green" },
                              },
                              [_vm._v("*论文可以通过*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.levelChoose == "C"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "red" },
                              },
                              [_vm._v("*可能是fail论文,请学生认真修改*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.levelChoose == "D"
                          ? _c(
                              "span",
                              {
                                staticClass: "detailline-text",
                                staticStyle: { color: "red" },
                              },
                              [_vm._v("*必定不及格论文*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "red",
                              margin: "10px 0",
                              "text-align": "left",
                            },
                          },
                          [_vm._v("*仅可评级一次，请针对评级结果进行批改")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.uploadFileData.index == 2
                ? _c("div", { staticClass: "dis-flex" }, [
                    _c("img", {
                      staticClass: "cinput-icon",
                      attrs: {
                        src: require("../../assets/images/icon-desc.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "cinput-label cinput-width" }, [
                      _vm._v("评级说明"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "340px" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.remark,
                            expression: "remark",
                          },
                        ],
                        staticClass: "cinput-textarea",
                        staticStyle: {
                          width: "320px!important",
                          height: "36px",
                        },
                        attrs: { disabled: _vm.thispaperStatus == 30 },
                        domProps: { value: _vm.remark },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.remark = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "dis-flex" }, [
                _c("img", {
                  staticClass: "cinput-icon",
                  attrs: {
                    src: require("../../assets/images/icon-upload.png"),
                  },
                }),
                _vm._v(" "),
                _c("label", { staticClass: "cinput-label cinput-width" }, [
                  _vm._v("上传文件至>>" + _vm._s(_vm.commidType)),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "upload-common",
                      staticStyle: { padding: "10px 0" },
                    },
                    _vm._l(_vm.uploadFilesLength, function (item) {
                      return _c("ul", [
                        _c("li", [
                          _vm._v(
                            _vm._s(item.name) +
                              "/" +
                              _vm._s(item.filesize) +
                              " "
                          ),
                          _c("i", {
                            staticClass: "el-icon-delete",
                            on: {
                              click: function ($event) {
                                return _vm.deleteFile(item)
                              },
                            },
                          }),
                          _vm._v(" "),
                          item.percent > 0
                            ? _c("div", { staticClass: "progress" }, [
                                _c("div", {
                                  staticClass: "progress-bar",
                                  style: { width: item.percent + "%" },
                                }),
                              ])
                            : _vm._e(),
                          item.percent > 0
                            ? _c("span", [_vm._v(_vm._s(item.percent) + "%")])
                            : _vm._e(),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.uploadFileData.index == 2
                ? _c(
                    "div",
                    { staticClass: "showlist-box" },
                    _vm._l(_vm.backfilelist, function (val) {
                      return _c(
                        "div",
                        {
                          key: val.id,
                          staticStyle: {
                            "padding-top": "10px",
                            "padding-bottom": "10px",
                          },
                          attrs: { id: val.id, label: val.name, value: val.id },
                        },
                        [
                          _c("b", { staticStyle: { color: "green" } }, [
                            _vm._v("√"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(" " + _vm._s(val.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [_vm._v(" " + _vm._s(val.createAt) + " ")]
                          ),
                          _vm._v(" "),
                          _c("b"),
                          _vm._v(" "),
                          _vm._m(1, true),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "btnbox" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.uploadFileData.loading,
                        expression: "uploadFileData.loading",
                      },
                    ],
                    staticClass: "defaultlight-btn btn-width320",
                    attrs: { id: "allupload" },
                  },
                  [_vm._v("确定")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.tableDataEnd.length
        ? _c("contentnull", {
            attrs: { statuscode: _vm.tableDataNameto ? 3 : 1 },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.lookvideotips
        ? _c("div", { staticClass: "new-mask" }, [
            _c(
              "div",
              {
                staticClass: "mask-content",
                staticStyle: { "z-index": "999999" },
              },
              [
                _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "tips-icon",
                  attrs: { src: require("@/assets/images/login-tips.png") },
                }),
                _vm._v(" "),
                _c("h5", [_vm._v(_vm._s(_vm.modelObj.title))]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "padding-left": "20px",
                      "padding-right": "20px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.modelObj.content))]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "btnbox" }, [
                  _c(
                    "button",
                    {
                      staticClass: "defaultlight-btn forget-pwd",
                      on: { click: _vm.yesFun },
                    },
                    [_vm._v("查看视频")]
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showvideo, width: "50%" },
          on: {
            close: _vm.closevideo,
            "update:visible": function ($event) {
              _vm.showvideo = $event
            },
          },
        },
        [
          _c("video", {
            ref: "myvideos",
            staticStyle: { width: "100%" },
            attrs: {
              autoplay: "",
              id: "myvideos",
              preload: "meta",
              "data-setup": "{}",
              oncontextmenu: "return false;",
              controls: "false",
              controlslist: "nodownload",
              src: _vm.videourl,
            },
          }),
          _vm._v(" "),
          _vm.videoduration
            ? _c("div", { staticClass: "canclick-box" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.videocurrent) +
                    "/" +
                    _vm._s(_vm.videoduration) +
                    " "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog delete-modal",
          attrs: {
            visible: _vm.showConfirm,
            width: "500px",
            "before-close": _vm.closeDialog,
            "append-to-body": "",
          },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/login-tips.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("续接订单")]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "\n        该订单已有老师跟进过，请先熟悉订单已进行的内容后，再处理订单后续内容\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "span",
                { staticClass: "confirm-btn", on: { click: _vm.readNotice } },
                [_vm._v("我知道了")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("modelbox", {
        attrs: { datas: _vm.testOrderObj, type: "2" },
        on: { closeModal: _vm.showTestboxFun },
      }),
      _vm._v(" "),
      _vm.testMsgObj.show
        ? _c("testmsgForm", {
            attrs: { datas: _vm.testMsgObj },
            on: { close: _vm.closeTestMsg },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showtip66Img,
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showtip66Img = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "tipsimgs-box",
            attrs: {
              src: "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20190502/messagetips.jpg",
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("fillExamContent", { ref: "fillExamContent" }),
      _vm._v(" "),
      _c("examComplatework", {
        ref: "examComplatework",
        on: { refresh: _vm.getTableData },
      }),
      _vm._v(" "),
      _c("examCheckModelNew", {
        ref: "examCheckModelNew",
        attrs: { testMsgObj: _vm.testMsgObj },
        on: { refresh: _vm.getTableData },
      }),
      _vm._v(" "),
      _vm.addstatus
        ? _c(
            "div",
            { staticClass: "new-mask" },
            [
              _c("addcourse", {
                attrs: { courseDetail: _vm.courseDetail },
                on: { close: _vm.closeAddClass, successFun: _vm.getTableData },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("playVideo", { ref: "playVideo" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _vm._v("【备考审核表】通过审核可获得订单20%收入"),
      _c("div", [_vm._v("（锁定至订单结课发放）")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "progress", staticStyle: { display: "none" } },
      [_c("div", { staticClass: "progress-bar", staticStyle: { width: "0%" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          attrs: { model: _vm.form, "label-width": "140px" },
        },
        [
          _vm.assistExamWay == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Loom Link",
                    "label-width": "100px",
                    props: "loomLink",
                    rules: [
                      {
                        required: true,
                        message: "请输入Loom Link",
                        trigger: "blur",
                      },
                      {
                        type: "url",
                        message: "请输入正确的Loom Link",
                        trigger: ["blur", "change"],
                      },
                    ],
                  },
                },
                [
                  !_vm.disabled
                    ? _c("el-input", {
                        on: { blur: _vm.submit },
                        model: {
                          value: _vm.form.loomLink,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "loomLink", $$v)
                          },
                          expression: "form.loomLink",
                        },
                      })
                    : _c(
                        "span",
                        {
                          staticClass: "link",
                          on: {
                            click: function ($event) {
                              return _vm.openLink(_vm.form.loomLink)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.form.loomLink))]
                      ),
                ],
                1
              )
            : [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Exam Questions",
                      rules: [
                        {
                          required: true,
                          message: "请输入Loom Link",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    !_vm.disabled
                      ? _c("uploadFile", {
                          ref: "upload",
                          staticStyle: { display: "inline-block" },
                          on: { uploadFiles: _vm.uploadFiles },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("file-list", {
                      attrs: {
                        fileList: _vm.form.subject,
                        disabled: _vm.disabled || _vm.form.subject.length < 2,
                        filesName: "subject",
                        type: "2",
                      },
                      on: { refresh: _vm.submit },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "Yours Answers",
                      rules: [
                        {
                          required: true,
                          message: "请输入Loom Link",
                          trigger: "blur",
                        },
                      ],
                    },
                  },
                  [
                    !_vm.disabled
                      ? _c("uploadFile", {
                          ref: "upload",
                          staticStyle: { display: "inline-block" },
                          on: { uploadFiles: _vm.uploadFiles2 },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("file-list", {
                      attrs: {
                        fileList: _vm.form.answer,
                        disabled: _vm.disabled || _vm.form.answer.length < 2,
                        filesName: "answer",
                        type: "2",
                      },
                      on: { refresh: _vm.submit },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
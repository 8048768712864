"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.linkClassRoom = exports.completeRoomToNextProcess = void 0;
var _request = require("./request");
var _api2 = require("@/api/api");
// 课堂训练场

/**
 * 是否完成课堂训练场？进入下一个环节
 * @param {String} userId
 */
var completeRoomToNextProcess = exports.completeRoomToNextProcess = function completeRoomToNextProcess(userId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/completeRoomToNextProcess/").concat(userId),
    method: "get"
  });
};

// const testUrl = `/static-resource/tkcloud/giveLessons.html?classroomId=${classNo}&iden=noobTeacher&tk=%85%9Dqi%60%95%9Cib%60b%98%9Dj%94%93fg%98%95bfggkpo%97%C6%98ef&teacId=${teacId}&nickName=${nickName}&roomMode=true`;

/**
 * 课堂训练场 链接
 */
var linkClassRoom = exports.linkClassRoom = function linkClassRoom(classNo, teacherId, nickName) {
  var _api = "/static-resource/tkcloud/giveLessons.html?classroomId=".concat(classNo, "&iden=noobTeacher&tk=%85%9Dqi%60%95%9Cib%60b%98%9Dj%94%93fg%98%95bfggkpo%97%C6%98ef&teacId=").concat(teacherId, "&nickName=").concat(nickName, "&roomMode=true");
  return _api2.EntryClassUrl + _api;
};
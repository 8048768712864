var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "560px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("讲师合作模式及薪资体系变更通知"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "font-weight": "700", "font-size": "16px" } },
            [_vm._v("各位讲师您好！")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "f14" }, [
            _vm._v(
              "1、为更好促进我司与讲师的合作关系，自2020年11月起您将以自由职业者身份与我司进行合作。"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "f14" }, [
            _vm._v("2、合作模式变更后，您每月工资所需缴纳"),
            _c(
              "span",
              { staticStyle: { "font-size": "16px", color: "#E45434" } },
              [_vm._v("税收起征点由原来20%调整至固定1%")]
            ),
            _vm._v("。您所需履行的兼职讲师职责义务依旧不变。"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "f14" }, [
            _vm._v("3、为保障到您的个人权益，您与我司通过"),
            _c("span", { staticStyle: { color: "#92B1FF" } }, [
              _vm._v("《兼职讲师保密协议》"),
            ]),
            _vm._v("及"),
            _c("span", { staticStyle: { color: "#92B1FF" } }, [
              _vm._v("《自由职业者服务协议》"),
            ]),
            _vm._v("建立合作关系，请前往微信公众号“"),
            _c("span", { staticStyle: { color: "#92B1FF" } }, [
              _vm._v("小班带你飞"),
            ]),
            _vm._v("”务必谨慎阅读两份协议内所有条款。"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "f14" }, [
            _vm._v("4、请各位讲师在11月10号前完成，否则当月工资将"),
            _c("span", { staticStyle: { color: "#E45434" } }, [_vm._v("隔月")]),
            _vm._v(
              "发送。如有疑问请在微信公众号“小班带你飞”联系师资管理人员。"
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("img", {
              attrs: {
                src: require("@/assets/images/level/xiaoban.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "12px",
                  color: "#666666",
                  "text-align": "center",
                  "margin-top": "-10px",
                },
              },
              [_vm._v("微信扫码前往“小班带你飞”公众号签署协议")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fileLoading,
          expression: "fileLoading",
        },
      ],
      staticClass: "detial-warp",
    },
    [
      _c("h5", [
        _vm._v("Order Files "),
        _c("button", { staticClass: "download", on: { click: _vm.downFun } }, [
          _vm._v("下载"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "detial-msg-box filemsg-height",
          class: [
            _vm.orderMagData.copywritingCourse &&
            _vm.orderMagData.copywritingCourse.type == 5
              ? "filemsg-height-mini"
              : "",
            _vm.orderMagData.isMy == 1 ? "" : "filemsg-height-nomy",
          ],
        },
        [
          _c(
            "h4",
            [
              _vm._v("文件还毫无头绪吗？快来查看各类Reference模板吧！\n      "),
              _c("reference", {
                attrs: {
                  referenceList: _vm.orderMagData.referenceStyles
                    ? _vm.orderMagData.referenceStyles
                    : [],
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "testForm-wrap allchecked-box" },
            [
              _c("el-checkbox", {
                staticStyle: { "margin-right": "10px" },
                on: { change: _vm.checkAllFun },
                model: {
                  value: _vm.checkedAll,
                  callback: function ($$v) {
                    _vm.checkedAll = $$v
                  },
                  expression: "checkedAll",
                },
              }),
              _vm._v("文件全选\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.getGroupListArr, function (item, key) {
            return _c("div", { key: key, staticClass: "fileslist-box" }, [
              _c("h4", [_vm._v(_vm._s(item.groupName))]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(item.fileArr, function (files, indexs) {
                  return _c(
                    "li",
                    { key: indexs, staticClass: "testForm-wrap" },
                    [
                      _c("el-checkbox", {
                        attrs: { name: "indexs" },
                        on: {
                          change: function ($event) {
                            return _vm.singleCheckFun(files)
                          },
                        },
                        model: {
                          value: files.check,
                          callback: function ($$v) {
                            _vm.$set(files, "check", $$v)
                          },
                          expression: "files.check",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "cursorpointer",
                          on: {
                            click: function ($event) {
                              return _vm.openUrl(files.url)
                            },
                          },
                        },
                        [_vm._v(_vm._s(files.name))]
                      ),
                      _vm._v(" "),
                      _c("b", [_vm._v(_vm._s(files.createAt))]),
                      _vm._v(" "),
                      files.description
                        ? _c("p", [_vm._v(_vm._s(files.description))])
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
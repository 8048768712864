var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "tip-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "500px",
            "before-close": _vm.closeModal,
            "close-on-click-modal": false,
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/card/timesetting.png"),
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("h3", [_vm._v("时间设置")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n            请前往课堂表设置您最近是否有空余时间处理订单，最多设置30天\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("设置后您可以从更多订单中选择您所要接的订单")]),
          _vm._v(" "),
          _c("img", {
            attrs: { src: require("@/assets/images/card/tip.png"), alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
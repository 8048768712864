var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container", style: _vm.staticPath.BG },
    [
      _c("countdown"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "trainingground-container",
        },
        [
          _c(
            "section",
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c("mini-map", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.modalShow,
                        expression: "!modalShow",
                      },
                    ],
                    on: { open: _vm.openModal },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("map-modal", {
                ref: "modal",
                on: {
                  close: function ($event) {
                    _vm.modalShow = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "main",
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("section", { staticClass: "btn-container" }, [
                _vm.isVisited
                  ? _c(
                      "div",
                      {
                        staticClass: "btn btn-next",
                        on: { click: _vm.nextStep },
                      },
                      [
                        _vm._v(
                          "\n                        课堂训练完成，下一步\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btn btn-enter", on: { click: _vm.openLink } },
                  [
                    _c("span", [_vm._v("进入课堂训练场")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "question",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openQuestion($event)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                            遇到问题\n                            "
                        ),
                        _c("svg-icon", { attrs: { "icon-class": "question" } }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("alert-modal", { ref: "alertModal" }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("classNotify", { ref: "classNotify", on: { nextStep: _vm.nextStep } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "title" }, [
      _c("span", [_vm._v("课堂训练场")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "content-container" }, [
      _c("span", { staticClass: "label" }, [_vm._v("什么是课堂训练场？")]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "1.课堂系统是您给学生授课的平台，课堂训练场将帮助您在正式上课前熟悉课堂系统。"
        ),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v("2.课堂训练场中包含所有上课教学工具，您需要熟练掌握使用方法。"),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "3.进入课堂训练场1min后，开始课堂任务考核，您需要根据提示完成每个课堂任务，所有任务完成后进入下个环节。"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "tips" }, [
        _vm._v("注意事项：您必须使用Chrome浏览器登录，进入课堂训练场"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var vueVersion = 1;
var isNewVersion = function isNewVersion() {
  var localVueVersion = localStorage.getItem('vueVersion');
  if (localVueVersion && localVueVersion != vueVersion) {
    localStorage.setItem('vueVersion', vueVersion);
    window.location.reload(true);
    return;
  } else {
    localStorage.setItem('vueVersion', vueVersion);
  }
};
var _default = exports.default = {
  isNewVersion: isNewVersion
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    file: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    viewFile: function viewFile(file) {
      window.open(file.url);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "848px",
            "append-to-body": true,
            "show-close": false,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("考前突击新流程上线")]),
          _vm._v(" "),
          _c("div", { staticClass: "first-desc" }, [
            _vm._v(
              "2023.3.28考前突击全新出击，新版考前突击全面赋能于讲师，提升讲师服务学生质量，让讲师获得更多学生好评"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "second-title" }, [
            _vm._v("考前突击规划流程要求"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "title-desc" }, [
            _c("div", [
              _vm._v(
                "考前突击全流程标准课时为7h，若您的排课需要延时请先联系运营获得执行方案，自行排课将无法获得收入哦～～"
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("接单后规划表内每一步都有sample哦，请酌情使用～～"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/home/follow.png"),
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "defaultlight-btn",
              attrs: { disabled: !_vm.showClose && _vm.needTime },
              on: { click: _vm.closeModal },
            },
            [
              _vm._v("我知道了 "),
              _vm.time && _vm.needTime
                ? _c("span", { staticStyle: { color: "#ff8723" } }, [
                    _vm._v("(" + _vm._s(_vm.time) + ")"),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "contentnull-box ",
      class: _vm.statuscode > 3 ? "" : "haspadding",
    },
    [
      _c("img", { attrs: { src: _vm.statusArr[this.statuscode].img } }),
      _vm._v(" "),
      _c("p", { class: _vm.text ? "textcolor" : "" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.statusArr[this.statuscode].content) +
            "\n    "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp " }, [
    _c("h5", [_vm._v("讲师收入")]),
    _vm._v(" "),
    _c("div", { staticClass: "detial-msg-box detial-msg-height" }, [
      _c("p", [
        _c("label", [_vm._v("字数")]),
        _vm._v(
          "\n            " +
            _vm._s(_vm.orderMagData.word_count) +
            " 字\n         "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("订单pass分")]),
        _vm._v(
          "\n            " + _vm._s(_vm.daobidata.passScore) + "\n         "
        ),
      ]),
      _vm._v(" "),
      _vm.daobidata.teacOrder && _vm.daobidata.teacOrder.penaltyRate
        ? _c("p", [
            _c("label", [_vm._v("Penalty Rate ")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.daobidata.teacOrder.penaltyRate || 0) +
                " %\n         "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("讲师费用(G)")]),
        _vm._v(" "),
        _vm._v(
          "\n            " +
            _vm._s(
              _vm.daobidata.teacFixedPrice
                ? _vm.daobidata.teacFixedPrice
                : _vm.orderMagData.moon
            ) +
            "G\n         "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
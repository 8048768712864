var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-box" },
    [
      _c(
        "el-upload",
        {
          ref: "uploader",
          staticClass: "upload-demo",
          attrs: {
            disabled: _vm.loading,
            data: _vm.uploadData,
            action: _vm.OSS_URL,
            "before-upload": _vm.handleBefore,
            "on-progress": _vm.uploadProcess,
            "file-list": _vm.uploadFile,
            "show-file-list": false,
          },
        },
        [
          _c(
            "el-button",
            { staticClass: "uploader-btn", attrs: { loading: _vm.loading } },
            [_vm._v(_vm._s(_vm.text))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentFile.percentage > 0
        ? _c(
            "div",
            { staticClass: "file-box" },
            [
              _c("div", [_vm._v(_vm._s(_vm.currentFile.name))]),
              _vm._v(" "),
              _c("el-progress", {
                attrs: {
                  "text-inside": true,
                  "stroke-width": 12,
                  color: "#4292D5",
                  "stroke-linecap": "square",
                  percentage: _vm.currentFile.percentage,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.search");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      giveTimes: false,
      tableDataBegin: [],
      tableDataName: "",
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      user: "",
      modal: false,
      modal_time: false,
      total: 0,
      limit: 15,
      time: "",
      idss: [],
      courseId: "",
      val: 1,
      page: "",
      ruleForm: {}
    };
  },
  multipleSelection: [],
  mounted: function mounted() {
    this.getTableData();
    this.getUser();
    this.searchEnter();
  },
  methods: {
    searchEnter: function searchEnter() {
      var _this = this;
      document.onkeydown = function (event) {
        if (event.keyCode == 13) {
          _this.search();
        }
      };
    },
    search: function search() {
      this.getTableData();
    },
    getTableData: function getTableData() {
      var _this2 = this;
      this.giveTimes = true;
      this.$axios({
        url: this.$api.baseURL + "/presentertime/list",
        params: {
          page: this.val,
          limit: this.limit,
          key: this.tableDataName
        }
      }).then(function (r) {
        _this2.giveTimes = false;
        _this2.tableDataEnd = r.data.body.list;
        _this2.total = r.data.body.totalCount;
        _this2.pageSize = r.data.body.totalCount;
        _this2.currentPage = r.data.body.currPage;
      }).catch(function (er) {
        _this2.giveTimes = false;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.limit = val;
      this.getTableData();
    },
    getUser: function getUser() {
      var _this3 = this;
      this.$axios({
        url: this.$api.baseURL + "/user/getMyInfo"
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this3.$router.push({
              path: "/login"
            });
          }
        }
        _this3.user = r.data.body;
        _this3.time = Number(_this3.user.presenterTimeLimit) - Number(_this3.user.presenterTimeConsume);
      }).catch(function (er) {});
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.map(function (item) {
        var id = item.id;
        ids.push("" + id + "");
      });
      this.selectedIDs = ids;
      console.log("多选", ids);
      this.idss = this.selectedIDs;
      console.log(this.idss);
    }
  }
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.search");
var _plupload = _interopRequireDefault(require("plupload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { stringify } from 'querystring'
// import plupload from './@/static/plupload.full.min.js'
var _default = exports.default = {
  data: function data() {
    var _this2 = this;
    return {
      loadingCourse: false,
      uploader: null,
      uploadFilesLength: [],
      uploadButtonEvent: null,
      uploadFilesArray: [],
      updataFiles: false,
      isTest: false,
      uploadDropBoxDialog: false,
      uploadUseData: {},
      pciker1: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      pciker2: {
        disabledDate: function disabledDate(time) {
          var beginDateVal = _this2.ruleForm.value1;
          if (beginDateVal) {
            return time.getTime() < beginDateVal;
          }
        }
      },
      uploadStatus: "exception",
      uploadPercent: 20,
      showUploadProgress: false,
      span: 0,
      dropboxCourse: false,
      fileListnum: 0,
      fileList: [],
      uploadImgTimes: 0,
      formdata: new FormData(),
      // 创建一个上传图片用的new formdata
      btnstatus: false,
      btnval: "确 定",
      value1: "",
      value2: "",
      tableDataBegin: [],
      tableDataName: "",
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      modal: false,
      modal_time: false,
      total: 0,
      limit: 15,
      idss: [],
      courseId: "",
      classrooms: "",
      classroom: "",
      val: 1,
      page: "",
      ruleForm: {
        title: "",
        value1: "",
        value2: "",
        classroom: "",
        description: "",
        times: "",
        explain: ""
      },
      rules: {
        title: [{
          required: true,
          message: "请填写课程标题",
          trigger: "blur"
        }],
        value1: [{
          required: true,
          message: "请选择排课时间",
          trigger: "change"
        }],
        value2: [{
          required: true,
          message: "请选择排课时间",
          trigger: "change"
        }],
        description: [{
          required: true,
          message: "请填写课程详情",
          trigger: "blur"
        }],
        times: [{
          required: true,
          message: "请填写赠送时长",
          trigger: "blur"
        }],
        classroom: [{
          required: true,
          message: "请填写赠送时长",
          trigger: "blur"
        }],
        explain: [{
          required: true,
          message: "请填写赠送说明",
          trigger: "blur"
        }]
      }
    };
  },
  multipleSelection: [],
  mounted: function mounted() {
    this.isNTest();
    this.getTableData();
    this.searchEnter();
  },
  methods: {
    searchEnter: function searchEnter() {
      var _this = this;
      document.onkeydown = function (event) {
        if (event.keyCode == 13) {
          _this.search();
        }
      };
    },
    isNTest: function isNTest() {
      if (this.$api.baseURL === "http://121.43.174.41/") {
        this.isTest = true;
      } else {
        this.isTest = false;
      }
    },
    search: function search() {
      this.getTableData();
    },
    getTableData: function getTableData() {
      var _this3 = this;
      this.loadingCourse = true;
      this.$axios({
        url: this.$api.baseURL + "/order/list",
        method: "get",
        params: {
          page: this.val,
          limit: this.limit,
          statused: 15,
          // "hasOrderPool": true,
          key: this.tableDataName
        }
      }).then(function (r) {
        _this3.tableDataEnd = r.data.body.list;
        _this3.total = r.data.body.totalCount;
        _this3.pageSize = r.data.body.totalCount;
        _this3.currentPage = r.data.body.currPage;
        _this3.loadingCourse = false;
      }).catch(function (er) {
        _this3.loadingCourse = false;
      });
    },
    apply: function apply(row, index) {
      this.modal = true;
      this.ruleForm.title = "";
      this.ruleForm.course_time = "";
      this.ruleForm.description = "";
      this.courseId = row.courseId;
    },
    change_classroom: function change_classroom(val) {},
    see: function see(row, index, offCourseName) {
      // const routeData = this.$router.resolve({
      //     name: 'searchGoods',
      //     query: params,
      //     params: { catId: params.catId }
      // })
      // window.open(routeData.href, '_blank')
      if (row.spoType === 64) {
        var Logistics2 = this.$router.resolve({
          path: "/bigClassRoom",
          query: {
            id: row.courseId,
            parentId: null
          }
        });
        window.open(Logistics2.href);
      } else {
        var type = 'daobi';
        if (row.courseType === 4) {
          type = 'wenya';
        }
        var Logistics1 = this.$router.resolve({
          path: "/sellerDetail",
          query: {
            id: row.courseId,
            type: type
          }
        });
        window.open(Logistics1.href);
      }
    },
    give_time: function give_time(row, index) {
      this.ruleForm.classroom = "";
      this.ruleForm.times = "";
      this.ruleForm.explain = "";
      this.modal_time = true;
      this.courseId = row.courseId;
      this.get_classroom();
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this4.ruleForm.value1 >= _this4.ruleForm.value2) {
            _this4.$notify({
              title: '提示',
              message: "开始时间不能大于结束时间",
              type: "warning"
            });
            return;
          }
          var sdate = new Date(_this4.ruleForm.value1);
          var now = new Date(_this4.ruleForm.value2);
          var days = now.getTime() - sdate.getTime();
          var day = parseInt(days / (1000 * 60));
          if (day < 30) {
            _this4.$notify({
              title: '提示',
              message: "时间相差必须30分钟",
              type: "warning"
            });
            return;
          }
          _this4.btnstatus = true;
          _this4.btnval = "加载中";
          _this4.$axios({
            url: _this4.$api.baseURL + "//order/putClassroom",
            headers: {
              "Content-Type": "application/json"
            },
            method: "post",
            data: JSON.stringify({
              cdId: _this4.courseId,
              name: _this4.$refs.ruleForm.model.title,
              startTime: _this4.$refs.ruleForm.model.value1 + ":00",
              endTime: _this4.$refs.ruleForm.model.value2 + ":00",
              description: _this4.$refs.ruleForm.model.description,
              roomType: 1
            })
          }).then(function (r) {
            if (r.data.status === 200) {
              _this4.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "success"
              });
              _this4.btnstatus = false;
              _this4.btnval = "确定";
              _this4.modal = false;
            }
            if (r.data.status === 400) {
              _this4.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "warning"
              });
              _this4.btnstatus = false;
              _this4.btnval = "确定";
            }
          }).catch(function (r) {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitForm1: function submitForm1(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.btnstatus = true;
          _this5.btnval = "加载中";
          _this5.$axios({
            url: _this5.$api.baseURL + "//account/presenterTime",
            method: "post",
            data: {
              classroomId: _this5.$refs.ruleForm.model.classroom,
              time: Number(_this5.$refs.ruleForm.model.times),
              explain: Number(_this5.$refs.ruleForm.model.explain)
            }
          }).then(function (r) {
            if (r.data.status === 200) {
              _this5.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "success"
              });
              _this5.btnval = "确定";
              _this5.btnstatus = false;
              _this5.modal = false;
              _this5.modal_time = false;
            }
            if (r.data.status === 400) {
              _this5.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "warning"
              });
              _this5.btnval = "确定";
              _this5.btnstatus = false;
              _this5.modal = false;
            }
          }).catch(function (er) {});
        }
      });
    },
    noapply: function noapply(row, index) {
      var _this6 = this;
      this.$confirm("是否要确认结课?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function (_) {
        _this6.$axios({
          url: _this6.$api.baseURL + "/order/applyFinishCourse",
          data: {
            courseId: row.courseId
          },
          method: "post"
        }).then(function (r) {
          if (r.data.status === 200) {
            _this6.$notify({
              title: '提示',
              message: "您已提交了结课申请，请等待师资主管审核",
              type: "success"
            });
            _this6.getTableData();
          }
          if (r.data.status === 400) {
            _this6.$notify({
              title: '提示',
              message: r.data.body.msg,
              type: "warning"
            });
          }
        });
      }).catch(function (_) {});
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.limit = val;
      this.getTableData();
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    get_classroom: function get_classroom() {
      var _this7 = this;
      this.$axios({
        url: this.$api.baseURL + "//order/queryClassroomsByCourseId",
        params: {
          courseId: this.courseId,
          status: 14
        }
      }).then(function (r) {
        _this7.classrooms = r.data.body.list;
      }).catch(function (er) {});
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.map(function (item) {
        var id = item.id;
        ids.push("" + id + "");
      });
      this.selectedIDs = ids;
      console.log("多选", ids);
      this.idss = this.selectedIDs;
      console.log(this.idss);
    },
    uploadDropbox: function uploadDropbox(val, id) {
      var _this8 = this;
      this.uploadDropBoxDialog = true;
      this.uploadUseData = val;
      function GUID() {
        this.date = new Date(); /* 判断是否初始化过，如果初始化过以下代码，则以下代码将不再执行，实际中只执行一次 */
        if (typeof this.newGUID !== "function") {
          /* 生成GUID码 */
          GUID.prototype.newGUID = function () {
            this.date = new Date();
            var guidStr = "";
            var sexadecimalDate = this.hexadecimal(this.getGUIDDate(), 16);
            var sexadecimalTime = this.hexadecimal(this.getGUIDTime(), 16);
            for (var i = 0; i < 9; i++) {
              guidStr += Math.floor(Math.random() * 16).toString(16);
            }
            guidStr += sexadecimalDate;
            guidStr += sexadecimalTime;
            while (guidStr.length < 32) {
              guidStr += Math.floor(Math.random() * 16).toString(16);
            }
            return this.formatGUID(guidStr);
          };
          /* * 功能：获取当前日期的GUID格式，即8位数的日期：19700101 * 返回值：返回GUID日期格式的字条串 */
          GUID.prototype.getGUIDDate = function () {
            return this.date.getFullYear() + this.addZero(this.date.getMonth() + 1) + this.addZero(this.date.getDay());
          };
          /* * 功能：获取当前时间的GUID格式，即8位数的时间，包括毫秒，毫秒为2位数：12300933 * 返回值：返回GUID日期格式的字条串 */
          GUID.prototype.getGUIDTime = function () {
            return this.addZero(this.date.getHours()) + this.addZero(this.date.getMinutes()) + this.addZero(this.date.getSeconds()) + this.addZero(parseInt(this.date.getMilliseconds() / 10));
          };
          /* * 功能: 为一位数的正整数前面添加0，如果是可以转成非NaN数字的字符串也可以实现 * 参数: 参数表示准备再前面添加0的数字或可以转换成数字的字符串 * 返回值: 如果符合条件，返回添加0后的字条串类型，否则返回自身的字符串 */
          GUID.prototype.addZero = function (num) {
            if (Number(num).toString() != "NaN" && num >= 0 && num < 10) {
              return "0" + Math.floor(num);
            } else {
              return num.toString();
            }
          };
          /*  * 功能：将y进制的数值，转换为x进制的数值 * 参数：第1个参数表示欲转换的数值；第2个参数表示欲转换的进制；第3个参数可选，表示当前的进制数，如不写则为10 * 返回值：返回转换后的字符串 */
          GUID.prototype.hexadecimal = function (num, x, y) {
            if (y != undefined) {
              return parseInt(num.toString(), y).toString(x);
            } else {
              return parseInt(num.toString()).toString(x);
            }
          };
          /* * 功能：格式化32位的字符串为GUID模式的字符串 * 参数：第1个参数表示32位的字符串 * 返回值：标准GUID格式的字符串 */
          GUID.prototype.formatGUID = function (guidStr) {
            var str1 = guidStr.slice(0, 8) + "-";
            var str2 = guidStr.slice(8, 12) + "-";
            var str3 = guidStr.slice(12, 16) + "-";
            var str4 = guidStr.slice(16, 20) + "-";
            var str5 = guidStr.slice(20);
            return str1 + str2 + str3 + str4 + str5;
          };
        }
      }
      var accessid, host, policyBase64, signature, g_dirname, uploadFileName, url, uploadFilesTimes;
      var guid = new GUID();
      uploadFilesTimes = 0;
      if (this.uploadUseData.scTypeed == 4) {
        url = this.$api.baseURL + "/oss/getAliOSSUploadSignWyard?dir=" + "courseware/" + guid.newGUID() + "/";
      } else {
        url = this.$api.baseURL + "/oss/getAliOSSUploadSign?dir=" + "courseware/" + guid.newGUID() + "/";
      }
      this.$axios({
        url: url
      }).then(function (r) {
        if (r.data.status === 200) {
          var formatFileName = function formatFileName(up, filename) {
            uploadFileName = encodeURIComponent(filename);
            console.log(uploadFileName);
            set_upload_param(up, filename, false);
          };
          var set_upload_param = function set_upload_param(up, filename, ret) {
            if (ret) {
              formatFileName(up, filename);
            }
            var new_multipart_params = {
              key: g_dirname + filename,
              policy: policyBase64,
              OSSAccessKeyId: accessid,
              success_action_status: "200",
              // 让服务端返回200,不然，默认会返回204
              signature: signature
            };
            up.setOption({
              url: host,
              multipart_params: new_multipart_params
            });
            up.start();
          };
          accessid = r.data.body.accessid;
          host = r.data.body.host;
          policyBase64 = r.data.body.policy;
          signature = r.data.body.signature;
          g_dirname = r.data.body.dir;
          var _this = _this8;
          _this8.uploader = new _plupload.default.Uploader({
            runtimes: "html5,flash,silverlight,html4",
            browse_button: "selectfiles",
            flash_swf_url: "js/Moxie.swf",
            silverlight_xap_url: "js/Moxie.xap",
            url: "http://oss.aliyuncs.com",
            init: {
              PostInit: function PostInit() {
                document.getElementById("ossfile").innerHTML = "";
                document.getElementById("postfiles").onclick = function () {
                  set_upload_param(_this.uploader, "", false);
                  return false;
                };
              },
              FilesAdded: function FilesAdded(up, files) {
                console.log(files);
                for (var i in files) {
                  _this.uploadFilesLength.push(files[i]);
                }
                // console.log(_this.uploadFilesLength.length)
                // uploadFilesNo = files.length
                _plupload.default.each(files, function (file) {
                  document.getElementById("ossfile").innerHTML += '<div id="' + file.id + '" style="padding: 10px 0;">' + file.name + " (" + _plupload.default.formatSize(file.size).split(" ")[0] + _plupload.default.formatSize(file.size).split(" ")[1].toUpperCase() + ")<b></b>" + '<div class="progress" style="display:none;"><div class="progress-bar" style="width: 0%"></div></div>' + "</div>";
                });
              },
              BeforeUpload: function BeforeUpload(up, file) {
                set_upload_param(up, file.name, true);
              },
              UploadProgress: function UploadProgress(up, file) {
                _this.updataFiles = true;
                var d = document.getElementById(file.id);
                d.getElementsByClassName("progress")[0].style.display = "block";
                d.getElementsByTagName("b")[0].innerHTML = '<span style="display:inline-block;padding-left:10px;">' + file.percent + "%</span>";
                var prog = d.getElementsByTagName("div")[0];
                var progBar = prog.getElementsByTagName("div")[0];
                progBar.style.width = file.percent + "%";
                progBar.setAttribute("aria-valuenow", file.percent);
              },
              FileUploaded: function FileUploaded(up, file, info) {
                if (info.status == 200) {
                  uploadFilesTimes++;
                  _this.uploadFilesArray = [];
                  var aaa = {
                    cdId: _this.uploadUseData.courseId,
                    groupId: 0,
                    name: file.name,
                    url: host + "/" + g_dirname + uploadFileName
                  };
                  _this.uploadFilesArray.push(aaa);
                  _this.$axios({
                    url: _this.$api.baseURL + "/courseware/saveWithOssUrl",
                    method: "post",
                    data: JSON.stringify(_this.uploadFilesArray),
                    headers: {
                      "Content-Type": "application/json"
                    }
                  }).then(function (r) {
                    _this.updataFiles = false;
                    if (r.data.status == 200) {
                      _this.$notify({
                        title: '提示',
                        type: "success",
                        message: "文件上传成功"
                      });
                      if (uploadFilesTimes === _this.uploadFilesLength.length) {
                        setInterval(function () {
                          window.location.reload();
                        }, 1200);
                      }
                    }
                  }).catch(function (er) {});
                } else {
                  document.getElementById(file.id).getElementsByTagName("b")[0].innerHTML = info.response;
                }
              },
              Error: function Error(up, err) {
                _this.$notify({
                  title: '提示',
                  type: "error",
                  message: "文件上传失败， 请刷新重试"
                });
                _this.updataFiles = false;
                _this.uploader = this.$plup.destroy();
                _this.uploadDropBoxDialog = false;
                _this.uploadFilesLength = [];
              }
            }
          });
          _this8.uploader.init();
        }
      }).catch(function (er) {});
      // $.ajax({
      //     url: url,
      //     headers: {
      //         token: token
      //     },
      //     success: function(r) {
      //         if (r.status == 200) {
      //             accessid = r.body.accessid
      //             host = r.body.host
      //             policyBase64 = r.body.policy
      //             signature = r.body.signature
      //             g_dirname = r.body.dir

      //             function formatFileName(up, filename) {
      //                 uploadFileName = encodeURIComponent(filename)
      //                 console.log(uploadFileName)
      //                 set_upload_param(up, filename, false)
      //             }

      //             function set_upload_param(up, filename, ret) {
      //                 if (ret) {
      //                     formatFileName(up, filename)
      //                 }
      //                 new_multipart_params = {
      //                     'key': g_dirname + filename,
      //                     'policy': policyBase64,
      //                     'OSSAccessKeyId': accessid,
      //                     'success_action_status': '200', // 让服务端返回200,不然，默认会返回204
      //                     'signature': signature
      //                 }

      //                 up.setOption({
      //                     'url': host,
      //                     'multipart_params': new_multipart_params
      //                 })

      //                 up.start()
      //             }

      //             this.uploader = new plupload.Uploader({
      //                 runtimes: 'html5,flash,silverlight,html4',
      //                 browse_button: 'selectfiles',
      //                 flash_swf_url: 'js/Moxie.swf',
      //                 silverlight_xap_url: 'js/Moxie.xap',
      //                 url: 'http://oss.aliyuncs.com',
      //                 init: {
      //                     PostInit: function() {
      //                         document.getElementById('ossfile').innerHTML = ''
      //                         document.getElementById('postfiles').onclick = function() {
      //                             set_upload_param(this.uploader, '', false)
      //                             return false
      //                         }
      //                     },
      //                     FilesAdded: function(up, files) {
      //                         console.log(files)
      //                         for (var i in files) {
      //                             this.uploadFilesLength.push(files)
      //                         }
      //                         console.log(vthism.uploadFilesLength.length)
      //                         uploadFilesNo = files.length
      //                         plupload.each(files, function(file) {
      //                             document.getElementById('ossfile').innerHTML += '<div id="' + file.id +
      //                                 '" style="padding: 10px 0;">' + file.name + ' (' + plupload.formatSize(file
      //                                 .size).split(' ')[0] + plupload.formatSize(file.size).split(' ')[1]
      //                                 .toUpperCase() + ')<b></b>' +
      //                                 '<div class="progress" style="display:none;"><div class="progress-bar" style="width: 0%"></div></div>' +
      //                                 '</div>'
      //                         })
      //                     },

      //                     BeforeUpload: function(up, file) {
      //                         set_upload_param(up, file.name, true)
      //                     },

      //                     UploadProgress: function(up, file) {
      //                         this.updataFiles = true
      //                         var d = document.getElementById(file.id)
      //                         d.getElementsByClassName('progress')[0].style.display = 'block'
      //                         d.getElementsByTagName('b')[0].innerHTML =
      //                             '<span style="display:inline-block;padding-left:10px;">' + file.percent +
      //                             '%</span>'
      //                         var prog = d.getElementsByTagName('div')[0]
      //                         var progBar = prog.getElementsByTagName('div')[0]
      //                         progBar.style.width = file.percent + '%'
      //                         progBar.setAttribute('aria-valuenow', file.percent)
      //                     },
      //                     FileUploaded: function(up, file, info) {
      //                         if (info.status == 200) {
      //                             uploadFilesTimes++
      //                             vm.uploadFilesArray = []
      //                             var aaa = {
      //                                 cdId: this.uploadUseData.courseId,
      //                                 groupId: 0,
      //                                 name: file.name,
      //                                 url: host + '/' + g_dirname + uploadFileName
      //                             }
      //                             this.uploadFilesArray.push(aaa)

      //                             $.ajax({
      //                                 url: baseURL + '/courseware/saveWithOssUrl',
      //                                 type: 'POST',
      //                                 contentType: 'application/json',
      //                                 headers: {
      //                                     token: token
      //                                 },
      //                                 data: JSON.stringify(this.uploadFilesArray),
      //                                 success: function(r) {
      //                                     vm.updataFiles = false
      //                                     if (r.status == 200) {
      //                                         vm.$notify({
      //   title: '提示',
      //                                             type: 'success',
      //                                             message: '文件上传成功'
      //                                         })
      //                                         if (uploadFilesTimes === this.uploadFilesLength.length) {
      //                                             setInterval(function() {
      //                                                 window.location.reload()
      //                                             }, 1000)
      //                                         }
      //                                     }
      //                                 },
      //                                 error: function(er) {
      //                                     this.updataFiles = false
      //                                 }
      //                             })
      //                         } else {
      //                             document.getElementById(file.id).getElementsByTagName('b')[0].innerHTML = info
      //                                 .response
      //                         }
      //                     },
      //                     Error: function(up, err) {
      //                         this.$notify({
      //   title: '提示',
      //                             type: 'error',
      //                             message: '文件上传失败， 请刷新重试'
      //                         })
      //                         this.updataFiles = false
      //                         this.uploader = destroy()
      //                         this.uploadDropBoxDialog = false
      //                         this.uploadFilesLength = []
      //                     }
      //                 }
      //             })
      //             vm.uploader.init()
      //         }
      //     },
      //     error: function(er) {}
      // })
    },
    uploadDropBoxDialogClose: function uploadDropBoxDialogClose() {
      this.uploadDropBoxDialog = false;
      this.uploadFilesLength = [];
      this.uploader.destroy();
    }
  }
};
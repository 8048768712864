var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "848px",
            "show-close": false,
            "append-to-body": true,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("订单沟通群组迁移通知")]),
          _vm._v(" "),
          _c("div", { staticClass: "first-line" }, [_vm._v("嗨 各位讲师！")]),
          _vm._v(" "),
          _c("div", { staticClass: "title-desc" }, [
            _c("div", [
              _vm._v("1、全新沟通方式诞生啦，"),
              _c("span", [_vm._v("微信插件")]),
              _vm._v("不仅可以和师资沟通，"),
              _c("span", [_vm._v("订单群组聊天")]),
              _vm._v("功能也有啦！"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("2、11月05号起讲师接单后，可通过"),
              _c("span", [_vm._v("万能班长微信插件")]),
              _vm._v("或"),
              _c("span", [_vm._v("企业微信")]),
              _vm._v("内聊天群组与学生沟通订单详情。"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("3、目前，请各位老师"),
              _c("span", [_vm._v("务必同时保留讲师APP")]),
              _vm._v("，当前进行中订单依然需要老师前往讲师APP群组沟通完成哦。"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "感恩各位同行讲师，我们希望能为您和学生搭建更便利的沟通方式，请各位讲师务必根据官方通知调整沟通方式哦。"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "img-box" }, [
            _c("div", [
              _c("div", [_vm._v("微信插件")]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/weixin.png"),
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", [_vm._v("企业微信")]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/qiye.png"),
                  alt: "",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [_vm._v("有疑问请联系你的师资管理人员")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "defaultlight-btn",
              attrs: { disabled: !_vm.showClose && _vm.needTime },
              on: { click: _vm.closeModal },
            },
            [
              _vm._v("朕知道了 "),
              _vm.time && _vm.needTime
                ? _c("span", { staticStyle: { color: "#ff8723" } }, [
                    _vm._v("(" + _vm._s(_vm.time) + ")"),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
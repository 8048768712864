var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "navbar", staticClass: "navbar" },
    [
      _c(
        "div",
        {
          ref: "leftname",
          staticClass: "left-name",
          on: {
            click: function ($event) {
              return _vm.gotoUrl("/account/persondetail")
            },
          },
        },
        [
          _c("img", {
            attrs: { src: require("../../assets/images/logo-login2.png") },
          }),
          _vm._v(" "),
          _c("span", { class: _vm.aindex == 4 ? "active" : "" }, [
            _vm._v("讲师空间"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "left-nav" },
        [
          _c("classfollow"),
          _vm._v(" "),
          _vm._l(_vm.navlist, function (list, i) {
            return [
              _c(
                "el-tooltip",
                {
                  key: i,
                  attrs: {
                    effect: "dark",
                    content:
                      "讲师帮助中心内可以提供新课堂的下载与课堂模拟功能哦~",
                    placement: "top-start",
                    disabled: list.name != "讲师帮助中心",
                    "popper-class": "class-popper-tip",
                    value: true,
                    id: list.id,
                  },
                },
                [
                  _c(
                    "li",
                    {
                      key: i,
                      ref: "leftnav",
                      refInFor: true,
                      class: [i == _vm.aindex ? "active" : ""],
                      on: {
                        click: function ($event) {
                          return _vm.changeTap(i)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon-img",
                        class: {
                          "big-icon": ["讲师帮助中心", "备课中心"].includes(
                            list.name
                          ),
                        },
                        attrs: {
                          src: i == _vm.aindex ? list.activeImg : list.img,
                          alt: "",
                        },
                      }),
                      _vm._v(
                        "\n          " + _vm._s(list.name) + "\n          "
                      ),
                      i == 0 && _vm.ordernum > 0
                        ? _c("span", [_vm._v(_vm._s(_vm.ordernum))])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-content" },
        [
          _c("goInClassByCode", {
            staticStyle: { "margin-right": "20px" },
            attrs: {
              teacId:
                _vm.userDetail.extTeacUserDetailsExt &&
                _vm.userDetail.extTeacUserDetailsExt.teacId,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "right-person" },
            [
              _vm.userDetail.type == 2 && _vm.userDetail.statused == 0
                ? _c("handsUp", { staticClass: "hands-up" })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "elc-btn" },
                [
                  _c("elcDialog", {
                    staticClass: "elc-tip",
                    on: { refresh: _vm.getUser },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "peron-box" },
                [
                  _c(
                    "el-dropdown",
                    { on: { command: _vm.handleCommand } },
                    [
                      _c("div", { staticClass: "person-icon" }, [
                        _c("img", {
                          attrs: {
                            src: _vm._f("globalImgFilter")(_vm.userDetail),
                          },
                          on: { error: _vm.imgOnError },
                        }),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticStyle: {},
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        [
                          _vm._l(_vm.persondata, function (item, i) {
                            return _c(
                              "el-dropdown-item",
                              { key: i, attrs: { command: i } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "logout" } },
                            [_vm._v("退出系统")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.techerInfo.paperThumbStatus < 1 &&
              _vm.techerInfo.childType != 3
                ? _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "点击后进入地图页",
                        placement: "top",
                        "popper-class": "class-popper-tip",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "map",
                        attrs: {
                          src: require("@/assets/images/hatch/miniMap.png"),
                          alt: "",
                        },
                        on: { click: _vm.goToPlain },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.maskShow
            ? _c("div", { staticClass: "new-mask z-index102" }, [
                _c("div", { staticClass: "mask-content" }, [
                  _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "tips-icon",
                    attrs: {
                      src: require("../../assets/images/login-tips.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("h5", [_vm._v("确认退出系统?")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "btnbox" }, [
                    _c(
                      "button",
                      {
                        staticClass: "defaultlight-btn forget-pwd",
                        staticStyle: { "margin-right": "20px" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("确认")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "default-btn forget-pwd",
                        on: { click: _vm.closeMask },
                      },
                      [_vm._v("取消")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("downloadApp", { ref: "downloadApp" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
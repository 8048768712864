var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "delete-modal" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "500px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("img", {
            attrs: { src: require("@/assets/images/login-tips.png"), alt: "" },
          }),
          _vm._v(" "),
          _vm.hasClass
            ? _c("div", { staticClass: "title" }, [
                _vm._v("\n      该阶段已安排课堂，不支持删除\n    "),
              ])
            : _c("div", [
                _c("div", { staticClass: "title" }, [_vm._v("确定删除阶段")]),
                _vm._v(" "),
                _c("div", { staticClass: "desc" }, [
                  _vm._v(
                    "\n        阶段删除后无法恢复，且阶段下所有任务都会一并删除，请重新安排任务\n      "
                  ),
                ]),
              ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.hasClass
                ? _c(
                    "span",
                    {
                      staticClass: "confirm-btn",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("朕知道了")]
                  )
                : [
                    _c(
                      "span",
                      {
                        staticClass: "cancle-btn",
                        on: { click: _vm.deleteStage },
                      },
                      [_vm._v("删除")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "confirm-btn",
                        on: { click: _vm.closeModal },
                      },
                      [_vm._v("我再想想")]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
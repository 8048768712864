"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wyStatusedObj = exports.OrderStatus = void 0;
/**
 * 订单状态值
 * @readonly
 * @enum {Number}
 */
var OrderStatus = exports.OrderStatus = {
  1: '未处理（无任何销售接单）',
  2: '待审核',
  4: '待确定',
  8: '待规划（等待教师/运营接单）',
  16: '规划中(订单第一次超时等待运营接单)',
  32: '规划中(订单第二次超时等待运营主管强制指派)',
  64: '规划中(运营接单待指派，正在处理)',
  128: '待排课',
  256: '已排课',
  512: '申请结课中',
  1024: '已结课',
  2048: '已强制结课',
  4096: '已取消',
  8192: '坏单申请中',
  16384: '订单作废',
  32768: '销售被更换',
  65536: '事故单申请中',
  131072: '讲师已更换',
  262144: '事故单更换教师待委派'
};
var wyStatusedObj = exports.wyStatusedObj = {
  3: 'waiting for writer',
  //等待委派写手
  4: 'wy_statused_lock',
  // 锁定
  5: 'waiting for writer',
  //已经委派写手
  10: 'order is been taken',
  //写手已经接单
  15: 'order is completed(waiting for QC)',
  //写手已上传作业（等待QC审核)
  20: 'order is completed(waiting for QC)',
  //qc未通过
  25: 'order is approved by QC',
  //qc通过
  30: ' waiting for feedback',
  //等待上传feedback
  32: 'waiting for feedback(verifying)',
  //已上传分数待审核
  35: 'full completed' //订单结束
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _hatch = _interopRequireDefault(require("./hatch"));
var _dissertation = _interopRequireDefault(require("./dissertation"));
var _paperschedule = _interopRequireDefault(require("./paperschedule"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/sellerDetail',
  name: 'SellerDetail',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/detail/seller_detail'));
    });
  },
  hidden: true
}, {
  path: '/sellerDetailWy',
  name: 'sellerDetailWy',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/detail/seller_detailWy'));
    });
  },
  hidden: true
}, {
  path: '/bigClassRoom',
  name: 'bigClassRoom',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/detail/bigClassRoom'));
    });
  },
  hidden: true
}, {
  path: '/login',
  // component: () => import('@/views/login/index'),
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
    });
  },
  hidden: true
}, {
  path: '/orders',
  name: 'orders',
  component: _layout.default,
  redirect: '/orders/orderlist',
  meta: {
    title: '订单池',
    icon: 'table'
  },
  alwaysShow: true,
  children: [{
    path: 'orderlist',
    name: 'orderlist',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/orderList'));
      });
    },
    meta: {
      title: '订单列表'
      // icon: 'form'
    }
  }, {
    path: 'myorder',
    name: 'myorder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orders/orderList'));
      });
    },
    meta: {
      title: '我的订单'
      // icon: 'form'
    }
  }, {
    path: 'finishedcourse',
    name: 'finishedcourse',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/myorders/finishedCourse'));
      });
    },
    meta: {
      title: '完成课堂'
      // icon: 'form'
    }
  }]
}, {
  path: '/myorders',
  name: 'myorders',
  component: _layout.default,
  redirect: '/myorders',
  meta: {
    title: '我的订单',
    icon: 'tree'
  },
  children: [{
    path: 'mycourse',
    name: 'mycourse',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/myorders/myCourse'));
      });
    },
    meta: {
      title: '我的课程'
      //  icon: 'tree'
    }
  }, {
    path: 'myorder',
    name: 'myorder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/myorders/myorder'));
      });
    },
    meta: {
      title: '我的订单2'
      //  icon: 'tree'
    }
  }, {
    path: 'oncourse',
    name: 'oncourse',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/myorders/onCourse'));
      });
    },
    meta: {
      title: '进行中课堂'
      // icon: 'tree'
    }
  }
  //  {
  //     path: 'finishedcourse',
  //     name: 'finishedcourse',
  //     component: () => import('@/views/myorders/finishedCourse'),
  //     meta: {
  //         title: '已结束课堂'
  //         // icon: 'tree'
  //     }
  // }
  ]
}, {
  path: '/messages',
  name: 'Messages',
  component: _layout.default,
  hidden: true,
  // redirect: '/messages/message',
  // meta: {
  //     title: '通知',
  //     icon: 'form'
  // },

  // alwaysShow: true,
  children: [{
    path: 'messages',
    name: 'messages',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/messages/messages'));
      });
    },
    meta: {
      title: '通知列表'
      //  icon: 'tree'
    }
  }]
}, {
  path: '/schooltimetable',
  name: 'schooltimetable',
  component: _layout.default,
  redirect: '/schooltimetable',
  meta: {
    title: '课堂表',
    icon: 'card'
  },
  alwaysShow: true,
  children: [{
    path: 'cards',
    name: 'cards',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/schooltimetable/cards'));
      });
    },
    meta: {
      title: '表格'
      // icon: 'form'
    }
  }]
}, {
  path: '/account',
  name: 'account',
  component: _layout.default,
  redirect: '/account',
  meta: {
    title: '我的',
    icon: 'user'
  },
  children: [{
    path: 'myinforamtion',
    name: 'myinfonamtion',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/myInformation'));
      });
    },
    meta: {
      title: '个人资料'
      // icon: 'nested'
    }
  }, {
    path: 'medalWall',
    name: 'medalWall',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/medalWall'));
      });
    },
    meta: {
      title: '勋章墙'
    }
  }, {
    path: 'persondetail',
    name: 'persondetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/persondetail'));
      });
    },
    meta: {
      title: '讲师个性页'
      // icon: 'nested'
    }
  }, {
    path: 'certificate',
    name: 'certificate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/hatch/certificate'));
      });
    },
    meta: {
      title: '讲师证书'
      // icon: 'nested'
    }
  }, {
    path: 'historylessons',
    name: 'historylessons',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/historyLessons'));
      });
    },
    meta: {
      title: '历史课程'
      // icon: 'link'
    }
  },, {
    path: 'historyorder',
    name: 'historyorder',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/historyorder'));
      });
    },
    meta: {
      title: '历史订单'
      // icon: 'link'
    }
  }, {
    path: 'incomelist',
    name: 'incomelist',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/incomeList'));
      });
    },
    meta: {
      title: '收入记录'
      // icon: 'link'
    }
  }, {
    path: 'withdrawlist',
    name: 'withdrawlist',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/withdrawList'));
      });
    },
    meta: {
      title: '工资记录'
      // icon: 'link'
    }
  }, {
    path: 'givetimes',
    name: 'givetimes',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/account/giveTimes'));
      });
    },
    meta: {
      title: '赠送时长'
      // icon: 'link'
    }
  }, {
    path: 'helpCenter',
    name: 'helpCenter',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/help/index'));
      });
    },
    meta: {
      title: '帮助中心'
      // icon: 'link'
    }
  }, {
    path: 'finishCourse',
    name: 'finishCourse',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/myorders/finishedCourse'));
      });
    },
    meta: {
      title: '完成课堂'
    }
  }, {
    path: 'mkApp',
    name: 'mkApp',
    // component: () => import('@/views/mkApp'),
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/help'));
      });
    },
    meta: {
      title: '课堂应用'
    }
  }]
}].concat((0, _toConsumableArray2.default)(_hatch.default), (0, _toConsumableArray2.default)(_dissertation.default), (0, _toConsumableArray2.default)(_paperschedule.default), [/* ********************************** 论文大礼包 ********************************** */

{
  path: '*',
  redirect: '/login',
  hidden: true
}, {
  path: '/interviewFollow',
  name: 'interviewFollow',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/interviewFollow'));
    });
  },
  meta: {
    title: '复试指引'
  }
}]);
var createRouter = function createRouter() {
  return new _vueRouter.default({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
var _default = exports.default = router;
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _eventBus = _interopRequireDefault(require("@/eventBus"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Logo from './Logo'
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default
  },
  data: function data() {
    return {
      username: '',
      userImg: '',
      // navbar: this.$api.navBarWidth,
      showHeaderPicture: false,
      headerPicture: ''
    };
  },
  // watch: {
  //     navbar(curVal, oldVal) {
  //         console.log(curVal, oldVal)
  //     }
  // },
  mounted: function mounted() {
    var _this = this;
    this.getUser();
    if (document.getElementsByClassName('sidebar-container')[0].offsetWidth === 210) {
      this.showHeaderPicture = false;
    } else if (document.getElementsByClassName('sidebar-container')[0].offsetWidth === 54) {
      this.showHeaderPicture = true;
    }
    _eventBus.default.$on('navBarWidth', function (data) {
      if (data.navBarWidth === false) {
        _this.showHeaderPicture = true;
      } else if (data.navBarWidth === true) {
        _this.showHeaderPicture = false;
      } else {
        _this.showHeaderPicture = null;
      }
    });
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar'])), {}, {
    routes: function routes() {
      return this.$router.options.routes;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {
    getUser: function getUser() {
      this.username = (0, _auth.getLocal)('teacinfo').nickName;
      // this.username = getLocal('teacinfo').nickName
      if (this.headerPicture.indexOf('http') === -1 || this.headerPicture.indexOf('https') === -1) {
        this.headerPicture = this.$api.redirectURL + (0, _auth.getLocal)('teacinfo').thumb;
      } else {
        this.headerPicture = (0, _auth.getLocal)('teacinfo').thumb;
      }
    },
    toAccount: function toAccount() {
      this.$router.push({
        path: '/account/myinfonamtion'
      });
    }
  }
};
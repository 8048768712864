var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _vm._l(_vm.additional, function (item, indexs) {
        return _c(
          "button",
          {
            key: indexs,
            staticClass: "download",
            on: {
              click: function ($event) {
                return _vm.showReferenceFun(item)
              },
            },
          },
          [
            _vm._v(
              "Additional " +
                _vm._s(indexs < 9 ? "0" + (indexs + 1) : indexs + 1)
            ),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            title: "Additional",
            center: "",
            width: "360px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c("p", { staticClass: "additional-list-show" }, [
            _c("label", [_vm._v("file")]),
            _vm._v(" "),
            _c(
              "span",
              _vm._l(_vm.chooseObj.coursewareList, function (files, indexsa) {
                return _c(
                  "b",
                  {
                    key: indexsa,
                    on: {
                      click: function ($event) {
                        return _vm.openUrl(files.url, files.name)
                      },
                    },
                  },
                  [_vm._v(_vm._s(files.name))]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "additional-list-show" }, [
            _c("label", [_vm._v("message")]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.chooseObj.remark))]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StageType2Val = exports.StageType2ClassTypeListVal = exports.ClassStatus2Val = exports.ClassFileType2Val = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _VEnum = require("@/utils/VEnum");
var TODO = _VEnum.ClassStatus.TODO,
  WAIT = _VEnum.ClassStatus.WAIT,
  BEGIN = _VEnum.ClassStatus.BEGIN,
  DONE = _VEnum.ClassStatus.DONE;
/**
 * 课程说明
 */
var ClassStatus2Val = exports.ClassStatus2Val = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, TODO, "未开启"), WAIT, "待排课"), BEGIN, "待上课"), DONE, "已完成");
var BREAKING_ICE = _VEnum.StageType.BREAKING_ICE,
  FIXED_TOPIC = _VEnum.StageType.FIXED_TOPIC,
  INTRODUCTION = _VEnum.StageType.INTRODUCTION,
  REVIEW = _VEnum.StageType.REVIEW,
  METHODOLOGY = _VEnum.StageType.METHODOLOGY,
  ANALYSIS = _VEnum.StageType.ANALYSIS,
  CONCLUSION = _VEnum.StageType.CONCLUSION,
  EXAMINE = _VEnum.StageType.EXAMINE;
/**
 * 阶段类型
 */
var StageType2Val = exports.StageType2Val = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BREAKING_ICE, "破冰/定题阶段"), FIXED_TOPIC, "Proposal阶段"), INTRODUCTION, "Introduction"), REVIEW, "Literature Review"), METHODOLOGY, "Methodology"), ANALYSIS, "Data Analysis"), CONCLUSION, "Dicussion&Conclusion"), EXAMINE, "梳理全文");

/**
 * 阶段类型 对照 课堂类型 数组
 */
var StageType2ClassTypeListVal = exports.StageType2ClassTypeListVal = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, BREAKING_ICE, [{
  classType: 1,
  label: "破冰课"
}, {
  classType: 2,
  label: "定题课"
}]), FIXED_TOPIC, [
// { classType: 1, label: "破冰课" },
// { classType: 2, label: "定题课" },
{
  classType: 3,
  label: "Tutor Meeting课 - P01"
}, {
  classType: 4,
  label: "Proposal / Draft 课"
}, {
  classType: 5,
  label: "Proposal批改课"
}
// { classType: 6, label: "提供Proposal部分的proofreading" }
]), INTRODUCTION, [{
  classType: 1,
  label: "论文框架搭建"
}, {
  classType: 2,
  label: "绪论部分批改课"
}
// { classType: 3, label: "提供绪论部分的proofreading" }
]), REVIEW, [{
  classType: 1,
  label: "LR课"
}, {
  classType: 2,
  label: "LR精读课"
}, {
  classType: 3,
  label: "Tutor Meeting课 - LR01"
}, {
  classType: 4,
  label: "LR部分批改课"
}, {
  classType: 5,
  label: "Tutor Meeting课 - LR02"
}
// { classType: 6, label: "提供LR部分的proofreading" }
]), METHODOLOGY, [{
  classType: 1,
  label: "解锁研究方法"
}, {
  classType: 2,
  label: "Tutor Meeting课"
}, {
  classType: 3,
  label: "ME部分批改课"
}
// { classType: 4, label: "提供ME部分的proofreading" }
]), ANALYSIS, [{
  classType: 1,
  label: "数据处理结果剖析课"
}, {
  classType: 2,
  label: "Tutor Meeting课"
}
// { classType: 3, label: "Tutor Meeting课" }
]), CONCLUSION, [{
  classType: 1,
  label: "讨论总结课"
}, {
  classType: 2,
  label: "D&C部分批改课"
}
// { classType: 3, label: "提交D&C部分的proofreading" }
]), EXAMINE, [{
  classType: 1,
  label: "Tutor Meeting课"
}
// { classType: 2, label: "全文校验" }
]);

/**
 * 阶段课堂文件类型 对照文案
 */
var ClassFileType2Val = exports.ClassFileType2Val = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, _VEnum.StageClassFileType.TOPIC_LIST, "Topic List"), _VEnum.StageClassFileType.OUTLINE, "Outline"), _VEnum.StageClassFileType.CORRECTION, "批改文件"), _VEnum.StageClassFileType.COMPLETE, "批改文件(完整)"), _VEnum.StageClassFileType.RESEARCH_LIST, "Research List"), _VEnum.StageClassFileType.DATA_RESULTS, "Data Results"), _VEnum.StageClassFileType.FINAL_DOC, "Final Document");
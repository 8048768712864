var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "material-container" }, [
    _c(
      "section",
      { staticClass: "copywriting" },
      [
        _vm._l(_vm.copywriting, function (index, i) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isMany,
                  expression: "isMany",
                },
              ],
              key: i,
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.ClassFileType2Val[index]))]),
              _vm._v(" "),
              _vm.readonly
                ? _c("div", { staticClass: "btn-plain btn-div readonly" }, [
                    _vm._v("上传文件"),
                  ])
                : [
                    _c(
                      "div",
                      {
                        staticClass: "btn-plain btn-div",
                        on: {
                          click: function ($event) {
                            return _vm.openUpload(index)
                          },
                        },
                      },
                      [_vm._v("上传文件")]
                    ),
                    _vm._v(" "),
                    _vm.showTip
                      ? _c("div", { staticClass: "font-red" }, [
                          _vm._v("*请务必完成此任务"),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOne,
                expression: "isOne",
              },
            ],
          },
          [
            _c("span", [
              _vm._v(_vm._s(_vm.ClassFileType2Val[_vm.copywriting[0]])),
            ]),
          ]
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c("section", [
      _vm.readonly
        ? _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOne,
                    expression: "isOne",
                  },
                ],
                staticClass: "btn-plain btn-div readonly",
              },
              [_vm._v("上传文件")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                staticClass: "btn-plain btn-div readonly",
              },
              [_vm._v("查看文件")]
            ),
          ])
        : _c("div", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOne,
                    expression: "isOne",
                  },
                ],
                staticClass: "btn-plain btn-div",
                on: {
                  click: function ($event) {
                    return _vm.openUpload(_vm.copywriting[0])
                  },
                },
              },
              [_vm._v("上传文件")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isRead,
                    expression: "isRead",
                  },
                ],
                staticClass: "btn-plain btn-div",
                on: { click: _vm.lookUp },
              },
              [_vm._v("查看文件")]
            ),
            _vm._v(" "),
            _vm.showTip
              ? _c("div", { staticClass: "font-red" }, [
                  _vm._v("*请务必完成此任务"),
                ])
              : _vm._e(),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      [
        _c("Material-List-Modal", {
          ref: "materialListModal",
          attrs: { stageClassId: _vm.data.id },
        }),
        _vm._v(" "),
        _c("Material-Upload-Modal", { ref: "uploadModal" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
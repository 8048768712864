var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-card" }, [
    _c("div", { staticClass: "file-info" }, [
      _c(
        "span",
        {
          staticClass: "file-name",
          on: {
            click: function ($event) {
              return _vm.viewFile(_vm.file)
            },
          },
        },
        [_vm._v(_vm._s(_vm.file.name))]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "file-time " }, [
      _vm._v(_vm._s(_vm.file.createdTime)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
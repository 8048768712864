var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "460px",
            "show-close": false,
            "append-to-body": true,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("重要通知")]),
          _vm._v(" "),
          _c("div", { staticClass: "first-line" }, [_vm._v("各位讲师好：")]),
          _vm._v(" "),
          _c("div", { staticClass: "title-desc" }, [
            _c("div", [
              _vm._v(
                "由于系统升级维护，PC端应用上课功能存在部分调试问题，大概一周左右恢复使用。为向您提供更优质便捷的服务，您可使用web端口进行上课，在此期间给您带来的不便敬请谅解。"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { staticClass: "defaultlight-btn", on: { click: _vm.closeModal } },
            [_vm._v("朕知道了 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
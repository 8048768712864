"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlanningCourseware = exports.getPlanningChooseArea = exports.getOrderTime = exports.getCodeByToken = exports.editClass = exports.cancelClass = exports.applyExtTime = exports.applyEndClass = void 0;
exports.getReqUrl = getReqUrl;
exports.waitDealOrder = exports.saveRemind = exports.queryOvertimeCourses = exports.queryCourseClassHour = exports.putCatalogue = exports.getZipFilePlus = exports.getTimeZoneList = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
var basicUrl1 = "".concat(_api.BasicUrl, "/api/teacher");
var basicUrl2 = "".concat(_api.crmUrl, "/sys/teacher");
var basicUrl3 = "".concat(_api.newCrmUrl, "/db/sys/teacher");
function getReqUrl() {
  return localStorage.getItem('CrmLinkDiss') === 'true' ? basicUrl2 : localStorage.getItem('NewLinkDiss') === 'true' ? basicUrl3 : basicUrl1;
}
;

//获取考前突击课堂类型
var getPlanningChooseArea = exports.getPlanningChooseArea = function getPlanningChooseArea(params) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studCoursePlanningStage/getPlanningChooseArea"),
    method: "get",
    params: params
  });
};
// 新增阶段并排课
var putCatalogue = exports.putCatalogue = function putCatalogue(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studCoursePlanningStage/putCatalogue"),
    method: "post",
    data: data
  });
};

// 编辑课堂
var editClass = exports.editClass = function editClass(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studCoursePlanningStage/updateStage"),
    method: "post",
    data: data
  });
};
// 获取新版考前突击dropbox
var getPlanningCourseware = exports.getPlanningCourseware = function getPlanningCourseware(params) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studCoursePlanningStage/getPlanningCourseware"),
    // otherUrl:`http://208dccb0.r9.cpolar.top/api/teacher/studCoursePlanningStage/getPlanningCourseware`,
    method: "get",
    params: params
  });
};
// 下载压缩文件
var getZipFilePlus = exports.getZipFilePlus = function getZipFilePlus(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/resource/getZipFilePlus"),
    method: "post",
    data: data
  });
};
// 新版本考前突击取消排课
var cancelClass = exports.cancelClass = function cancelClass(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studCoursePlanningStage/cancelClass"),
    method: "post",
    data: data
  });
};
// 查询超时订单
var queryOvertimeCourses = exports.queryOvertimeCourses = function queryOvertimeCourses() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studCourse/v1/queryClassroomOvertimeCourses"),
    method: "get"
  });
};
// 申请结课
var applyEndClass = exports.applyEndClass = function applyEndClass(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/applyFinishCourse/").concat(courseId),
    method: "post",
    data: {}
  });
};
//查询订单课时信息
var queryCourseClassHour = exports.queryCourseClassHour = function queryCourseClassHour(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studCoursePlanningApply/v1/queryCourseClassHour"),
    method: "get",
    params: params
  });
};
//申请额外课时
var applyExtTime = exports.applyExtTime = function applyExtTime(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studCoursePlanningApply/v1/applyClassHour"),
    method: "post",
    data: data
  });
};
//考前突击超时申请结课后端让调
var saveRemind = exports.saveRemind = function saveRemind(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studCoursePlanningStage/saveRemind"),
    method: "post",
    data: data
  });
};
// 获取时区
var getTimeZoneList = exports.getTimeZoneList = function getTimeZoneList(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/countryExt/v1/getStudentTimeZoneList"),
    method: "get",
    params: params
  });
};
//待定超时订单
var waitDealOrder = exports.waitDealOrder = function waitDealOrder(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studCourse/v1/noPrompting"),
    method: "post",
    data: data
  });
};
var getOrderTime = exports.getOrderTime = function getOrderTime(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/queryOrderTypeIsBB"),
    method: "get",
    params: params
  });
};
var getCodeByToken = exports.getCodeByToken = function getCodeByToken(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studPlatFromCode/v1/generateCode"),
    method: "get",
    params: params
  });
};
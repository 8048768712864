"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.xsnl = exports.tgpp = exports.preExamSort = exports.ksxs = exports.ksjd = exports.fdxq = void 0;
var preExamSort = exports.preExamSort = {
  'Requirement': 1,
  'Unit Guide(Syllabus)': 2,
  'Past Paper': 3,
  'Additional': 4,
  'Student Completed Work': 5,
  'Lecture note': 6,
  'Readings': 7,
  'Sample': 8,
  'Tutor Material': 9,
  'Other': 10
};
// 辅导需求
var fdxq = exports.fdxq = {
  1: '细水长流型复习',
  2: '冲刺刷题式突击'
};
// 学生能力
var xsnl = exports.xsnl = {
  0: '较弱',
  2: '一般',
  3: '较强'
};
// 考试阶段
var ksjd = exports.ksjd = {
  0: '期中',
  1: '期末',
  2: '补考'
};
// 考试形式
var ksxs = exports.ksxs = {
  0: '闭卷限时',
  1: '开卷限时',
  2: 'take-home',
  3: '线上',
  4: '线下'
};
// 是否提供Past Paper
var tgpp = exports.tgpp = {
  0: '否',
  1: '是'
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "1150px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("DropBox材料表")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "upload-box" },
            [
              _vm._l(_vm.typeList, function (item, index) {
                return [
                  _vm.uploadType != "pobing" || !index
                    ? _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "item-name",
                              staticStyle: {
                                "line-height": "30px",
                                "font-size": "14px",
                              },
                              style: { background: item.color },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    !index && _vm.uploadType == "pobing"
                                      ? "破冰课课件"
                                      : item.title
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          index
                            ? [
                                _c("upload", {
                                  ref: "uploader",
                                  refInFor: true,
                                  staticClass: "choose-btn",
                                  attrs: {
                                    commid: item.title,
                                    curIndex: index,
                                    text: "上传",
                                  },
                                  on: {
                                    getFiles: _vm.getFiles,
                                    uploadAllFile: _vm.uploadAllFile,
                                  },
                                }),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "file-box" },
                            [
                              index
                                ? [
                                    item.waitFile.length
                                      ? _c(
                                          "div",
                                          { staticClass: "wait-box" },
                                          [
                                            _vm._l(
                                              item.waitFile,
                                              function (file, i) {
                                                return _c(
                                                  "div",
                                                  { staticClass: "wait-file" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "wait-name",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(file.name) +
                                                            "(" +
                                                            _vm._s(
                                                              _vm._f("tofix")(
                                                                file.size /
                                                                  1024,
                                                                0
                                                              )
                                                            ) +
                                                            "kb)\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("el-progress", {
                                                      attrs: {
                                                        percentage:
                                                          file.precent,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-delete cursor",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleWaitFile(
                                                            file,
                                                            i,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            item.waitFile.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "btn-box" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.cancelUpload(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("取消")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.comfirmUpload(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "loading",
                                                                rawName:
                                                                  "v-loading",
                                                                value:
                                                                  item.loading,
                                                                expression:
                                                                  "item.loading",
                                                              },
                                                            ],
                                                          },
                                                          [_vm._v("确定上传")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              index
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "uploaded-box" },
                                      _vm._l(
                                        item.uploaded,
                                        function (file, idx) {
                                          return _c(
                                            "div",
                                            { staticClass: "uploaded-file" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "file-name" },
                                                [_vm._v(_vm._s(file.name))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "file-time" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(file.createAt) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "loading",
                                                    rawName: "v-loading",
                                                    value: file.loading,
                                                    expression: "file.loading",
                                                  },
                                                ],
                                                staticClass:
                                                  "el-icon-delete cursor",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delPaperFiles(
                                                      file,
                                                      idx,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                : [
                                    _vm._l(
                                      item.btnList,
                                      function (upload, _idx) {
                                        return [
                                          !(_vm.uploadType == "pobing" && _idx)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "uploaded-box",
                                                  staticStyle: {
                                                    "margin-top": "0px",
                                                  },
                                                },
                                                [
                                                  upload.uploaded.length || true
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "child-title",
                                                          class: {
                                                            "child-title2":
                                                              _idx == 2,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              upload.text
                                                            ) +
                                                              "\n                      "
                                                          ),
                                                          _vm._v(" "),
                                                          _c("upload", {
                                                            ref: "uploader",
                                                            refInFor: true,
                                                            staticClass:
                                                              "choose-btn",
                                                            attrs: {
                                                              commid:
                                                                upload.text,
                                                              curIndex: index,
                                                              childIndex: _idx,
                                                              text: "上传",
                                                            },
                                                            on: {
                                                              getFiles:
                                                                _vm.getFiles,
                                                              uploadAllFile:
                                                                _vm.uploadAllFile,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  upload.waitFile.length
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "wait-box",
                                                        },
                                                        [
                                                          _vm._l(
                                                            upload.waitFile,
                                                            function (file, i) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wait-file",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "wait-name",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            file.name
                                                                          ) +
                                                                          "(" +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "tofix"
                                                                            )(
                                                                              file.size /
                                                                                1024,
                                                                              0
                                                                            )
                                                                          ) +
                                                                          "kb)\n                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "el-progress",
                                                                    {
                                                                      attrs: {
                                                                        percentage:
                                                                          file.precent,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-delete cursor",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleWaitFile(
                                                                            file,
                                                                            i,
                                                                            index,
                                                                            _idx
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          upload.waitFile.length
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btn-box",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.cancelUpload(
                                                                              index,
                                                                              _idx
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "取消"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.comfirmUpload(
                                                                              index,
                                                                              _idx
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "loading",
                                                                                rawName:
                                                                                  "v-loading",
                                                                                value:
                                                                                  upload.loading,
                                                                                expression:
                                                                                  "upload.loading",
                                                                              },
                                                                            ],
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "确定上传"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "files-box",
                                                    },
                                                    _vm._l(
                                                      upload.uploaded,
                                                      function (file, idx) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "uploaded-file",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-name",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-time",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      file.createAt
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("i", {
                                                              directives: [
                                                                {
                                                                  name: "loading",
                                                                  rawName:
                                                                    "v-loading",
                                                                  value:
                                                                    file.loading,
                                                                  expression:
                                                                    "file.loading",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "el-icon-delete cursor",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delPaperFiles(
                                                                      file,
                                                                      idx,
                                                                      index,
                                                                      _idx
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "message-box",
    },
    [
      _c(
        "div",
        [
          _c("span", { staticClass: "message-title" }, [_vm._v("Reflection")]),
          _vm._v(" "),
          _c("el-badge", {
            staticStyle: { cursor: "pointer" },
            attrs: { hidden: !_vm.notComplate, value: _vm.notComplate },
          }),
          _vm._v(" "),
          _vm.list.length
            ? _c(
                "span",
                {
                  staticClass: "see-all",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showModal("reflectionList")
                    },
                  },
                },
                [
                  _vm._v("\n      查看全部"),
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/tangle.png"),
                      alt: "",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "desc-box" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.showModal("reflectionRule", _vm.item)
                  },
                },
              },
              [
                _vm._v("处理及薪资扣除规则"),
                _c("i", { staticClass: "el-icon-question" }),
              ]
            ),
            _vm._v(" "),
            _vm.list.length
              ? _c("span", [
                  _vm._v("点击下载"),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.openlink(_vm.template)
                        },
                      },
                    },
                    [_vm._v("申诉模板")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.list.length
            ? _c(
                "div",
                { staticClass: "list-content" },
                _vm._l(_vm.list, function (item, index) {
                  return _c("div", { key: index, staticClass: "list" }, [
                    _c("div", { staticClass: "detail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "order",
                          on: {
                            click: function ($event) {
                              return _vm.goToOrder(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.orderNo) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      [2, 3].includes(Number(item.teacherStatused)) &&
                      item.fileList.length
                        ? _c(
                            "div",
                            {
                              staticClass: "upload",
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFile(
                                    item.id,
                                    item.fileList
                                  )
                                },
                              },
                            },
                            [
                              _vm._v("请先"),
                              _c("span", { staticClass: "file" }, [
                                _vm._v(
                                  "下载申诉文件(" +
                                    _vm._s(
                                      item.fileList && item.fileList.length
                                    ) +
                                    "),"
                                ),
                              ]),
                              _c("span", { staticClass: "red-font" }, [
                                _vm._v("否则会导致回传失败"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "desc" }, [
                        _c("span", [
                          _vm._v("扣除：" + _vm._s(item.subGb) + "G"),
                        ]),
                        _c("span", [
                          _vm._v(
                            "原因：" + _vm._s(_vm.reasonList[item.reasonType])
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "status" }, [
                      _c(
                        "div",
                        {
                          class: {
                            "orange-font": [2, 3, 4].includes(
                              Number(item.teacherStatused)
                            ),
                          },
                        },
                        [_vm._v(_vm._s(_vm.statusList[item.teacherStatused]))]
                      ),
                      _vm._v(" "),
                      [2, 3].includes(Number(item.teacherStatused))
                        ? _c(
                            "div",
                            { staticClass: "time" },
                            [
                              _vm._v("倒计时 "),
                              _c("countDown", {
                                attrs: { remainTime: item.lastTime },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      [1, 2, 3].includes(Number(item.teacherStatused))
                        ? _c(
                            "div",
                            {
                              staticClass: "apply",
                              class: {
                                "disabled-btn": [1, 2].includes(
                                  Number(item.teacherStatused)
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showModal("appealFile", item)
                                },
                              },
                            },
                            [_vm._v("申诉")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      [4, 5, 6].includes(Number(item.teacherStatused))
                        ? _c(
                            "div",
                            {
                              staticClass: "see",
                              on: {
                                click: function ($event) {
                                  return _vm.showModal("reflectionLog", item)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              )
            : _c("img", {
                staticClass: "noreflection",
                attrs: {
                  src: require("@/assets/images/home/noreflection.png"),
                  alt: "",
                },
              }),
          _vm._v(" "),
          _c("appealFile", { ref: "appealFile", on: { refresh: _vm.init } }),
          _vm._v(" "),
          _c("reflectionList", {
            ref: "reflectionList",
            attrs: {
              statusList: _vm.statusList,
              reasonList: _vm.reasonList,
              template: _vm.template,
            },
          }),
          _vm._v(" "),
          _c("reflectionLog", {
            ref: "reflectionLog",
            attrs: { reasonList: _vm.reasonList, statusList: _vm.statusList },
          }),
          _vm._v(" "),
          _c("reflectionRule", { ref: "reflectionRule" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadApplyFile = exports.updateThumb = exports.seeWelecomNew = exports.seeUpLevel = exports.seeSettingTime = exports.seeSalary = exports.seeNotice = exports.seeMonth = exports.seeHighScore = exports.seeDoWxPlugFlag = exports.redGroup = exports.redExam = exports.readelcAPP = exports.readSalary = exports.readFollow = exports.readAddWx = exports.queryWaitComplateNumber = exports.getWaitList = exports.getTeacherInfo = exports.getReflectionLog = exports.getReflectionList = exports.getRedbag = exports.getQcNotify = exports.getPraiseRank = exports.getNotRead = exports.getMonthList = exports.getMonth = exports.getHighScore = exports.getHighList = exports.getFailList = exports.getFail = exports.getAward = exports.getAliOSSUploadSign = exports.geIncomeDetail = exports.geIncome = exports.downloadFile = exports.doCoursewareTeacherFlag = exports.OpenRedbag = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
//获取是否显示月度通知
var getMonth = exports.getMonth = function getMonth(teacId) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacRankMonthSummary/getPrevMonthRanklyData?teacId=").concat(teacId),
    method: "get"
  });
};
// 已经显示了月度通知
var seeMonth = exports.seeMonth = function seeMonth(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacRankMonthSummary/readRankMonthlyData"),
    method: "post",
    data: data
  });
};
//获取月度数据列表
var getMonthList = exports.getMonthList = function getMonthList(teacId, page, limit, ym) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacRankMonthSummary/getTeacRankMonthlyData?teacId=").concat(teacId, "&page=").concat(page, "&limit=").concat(limit, "&ym=").concat(ym || ""),
    method: "get"
  });
};
//获取未读消息数
var getNotRead = exports.getNotRead = function getNotRead() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/notify/list?page=1&limit=1000&webRead=0"),
    method: "get"
  });
};
//获取质检通知
var getQcNotify = exports.getQcNotify = function getQcNotify() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/notify/getQcNotify"),
    method: "get"
  });
};
//读了添加讲师运营师资微信
var readAddWx = exports.readAddWx = function readAddWx() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doPassFoWebLoginFalg"),
    method: "post",
    data: {}
  });
};
//标记已读薪资体系通知
var readSalary = exports.readSalary = function readSalary() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doViewGradeMark"),
    method: "post",
    data: {}
  });
};
//标记已领获赞值
var getAward = exports.getAward = function getAward() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doLeadingMark"),
    method: "post",
    data: {}
  });
};
//标记金秋活动已查看
var seeNotice = exports.seeNotice = function seeNotice() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doViewSeasonMark"),
    method: "post",
    data: {}
  });
};
//标记讲师升级已查看
var seeUpLevel = exports.seeUpLevel = function seeUpLevel() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doLevelUpMark"),
    method: "post",
    data: {}
  });
};
// 标记讲师已查看企业微信通知消息插件
var seeDoWxPlugFlag = exports.seeDoWxPlugFlag = function seeDoWxPlugFlag() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doWxPlugFlag"),
    method: "post",
    data: {}
  });
};
// 标记已查看日程设置插件
var seeSettingTime = exports.seeSettingTime = function seeSettingTime() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doFlowScheduleFlag"),
    method: "post",
    data: {}
  });
};
//获取高分
var getHighScore = exports.getHighScore = function getHighScore() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/countryExt/v1/getAllDbCountryExts"),
    method: "get"
  });
};
//标记已读高分晋升
var seeHighScore = exports.seeHighScore = function seeHighScore() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doTeacWebHighScoreFormFlag"),
    method: "post",
    data: {}
  });
};
//获取高反馈排行榜
var getHighList = exports.getHighList = function getHighList(ym) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/custom/page/highFeedback/rank/").concat(ym),
    method: "get"
  });
};
//获取红包列表
var getRedbag = exports.getRedbag = function getRedbag(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacRedEnvelopeRecord/getRewardRedEnvelopes"),
    method: "get",
    params: params
  });
};
//领取红包
var OpenRedbag = exports.OpenRedbag = function OpenRedbag(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacRedEnvelopeRecord/redEnvelopeIncome"),
    method: "post",
    data: data
  });
};

//已读上线通知
var seeSalary = exports.seeSalary = function seeSalary(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doSalaryModelUpgradeFlag"),
    method: "post",
    data: data
  });
};
//fail汇总
var getFail = exports.getFail = function getFail(teacId) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/queryTeacFailRate/").concat(teacId),
    method: "get"
  });
};
//fail明细
var getFailList = exports.getFailList = function getFailList(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/queryTeacFailDetails"),
    method: "get",
    params: params
  });
};

//已读群组沟通
var redGroup = exports.redGroup = function redGroup() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doGroupMigrationFlag"),
    method: "post",
    data: {}
  });
};
//已读考试订单录屏通知
var redExam = exports.redExam = function redExam() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doZhentikuFlag"),
    method: "post",
    data: {}
  });
};

//已读迎新贺通知
var seeWelecomNew = exports.seeWelecomNew = function seeWelecomNew() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doVersionUpdFlag"),
    method: "post",
    data: {}
  });
};
//已读安装elcAPP提示
var readelcAPP = exports.readelcAPP = function readelcAPP() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doShengwangFlag"),
    method: "post",
    data: {}
  });
};
//查询reflection列表
var getReflectionList = exports.getReflectionList = function getReflectionList(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/quality/queryList"),
    method: "get",
    params: params
  });
};

//查询reflection申诉文件
var uploadApplyFile = exports.uploadApplyFile = function uploadApplyFile(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/quality/uploadAppealFile"),
    method: "post",
    data: data
  });
};
//查询reflection日志
var getReflectionLog = exports.getReflectionLog = function getReflectionLog(id) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/quality/queryLog/").concat(id),
    method: "get"
  });
};
//标记下载质检上传的文件
var downloadFile = exports.downloadFile = function downloadFile(id) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/quality/downloadFile/").concat(id),
    method: "get"
  });
};
//  获取reflectionc未处理完成的数量
var queryWaitComplateNumber = exports.queryWaitComplateNumber = function queryWaitComplateNumber() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/quality/queryWaitDownloadNumber"),
    method: "get"
  });
};
// 已读新手指引
var readFollow = exports.readFollow = function readFollow() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doOrderRommIntroduce"),
    method: "post",
    data: {}
  });
};
// 查询讲师待办
var getWaitList = exports.getWaitList = function getWaitList() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUpcoming/queryTeacUpcomings"),
    method: "get"
  });
};
// 查询讲师收入数据
var geIncome = exports.geIncome = function geIncome() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/getTeacIncomeDeductionIsLiked"),
    method: "get"
  });
};
// 查询讲师当月收入明细
var geIncomeDetail = exports.geIncomeDetail = function geIncomeDetail(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/queryTeacIncomeDetails"),
    method: "get",
    params: params
  });
};
var getAliOSSUploadSign = exports.getAliOSSUploadSign = function getAliOSSUploadSign(dir) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/oss/getAliOSSUploadSign?dir=").concat(dir, "/").concat(Date.now()),
    method: "get"
  });
};
// 修改讲师头像
var updateThumb = exports.updateThumb = function updateThumb(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/updateThumb"),
    method: "post",
    data: data
  });
};
// 学生最爱榜
var getPraiseRank = exports.getPraiseRank = function getPraiseRank(date) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/custom/page/praise/rank/").concat(date),
    method: "get"
  });
};
// 学生最爱榜
var getTeacherInfo = exports.getTeacherInfo = function getTeacherInfo() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/getMyInfo"),
    method: "get"
  });
};
// 标记已读备课上线通知
var doCoursewareTeacherFlag = exports.doCoursewareTeacherFlag = function doCoursewareTeacherFlag() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doCoursewareTeacherFlag"),
    method: "post",
    data: {}
  });
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alert-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.alertModal,
            center: "",
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.alertModal = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [
                _vm._v(_vm._s(_vm.alertData.title)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.alertData.content, function (item, i) {
              return _c("span", { key: i }, [_vm._v(_vm._s(item))])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "btn", on: { click: _vm.handleClose } },
                [_vm._v(_vm._s(_vm.alertData.btn))]
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
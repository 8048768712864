var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fileLoading,
          expression: "fileLoading",
        },
      ],
      staticClass: "detial-warp ",
    },
    [
      _c("h5", [_vm._v("Order Info")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detial-msg-box detial-msg-height" },
        [
          _vm.copywritingCourse
            ? _c("p", [
                _c("label", [_vm._v("Product Category")]),
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.specilTypeArr[_vm.copywritingCourse.type]) +
                    "\n         "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !(
            _vm.copywritingCourse &&
            [3].includes(Number(_vm.copywritingCourse.type))
          )
            ? _c("p", [
                _c("label", [_vm._v("Deal Deadline")]),
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.orderMagData.studPurchaseOrder &&
                        _vm.orderMagData.studPurchaseOrder.endCourseTime
                    ) +
                    "\n            "
                ),
                _vm.orderMagData.studPurchaseOrder &&
                _vm.orderMagData.studPurchaseOrder.endCourseTime
                  ? _c("span", { staticClass: "tips" }, [
                      _vm._v("请讲师严格执行此时间,否则会有额外薪资处罚"),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("OrderStatus")]),
            _vm._v(" "),
            _vm._v(
              "\n            " +
                _vm._s(_vm.OrderStatus[_vm.orderMagData.statused]) +
                "\n         "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Workload")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.orderMagData.studPurchaseOrder.workload) +
                "\n         "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [
              _vm._v(
                _vm._s(
                  _vm.copywritingCourse &&
                    [4].includes(Number(_vm.copywritingCourse.type))
                    ? "Speech"
                    : ""
                ) + "Word Count"
              ),
            ]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.orderMagData.studPurchaseOrder.wordsNum) +
                "\n         "
            ),
          ]),
          _vm._v(" "),
          _vm.copywritingCourse &&
          [4].includes(Number(_vm.copywritingCourse.type))
            ? [
                _c("p", [
                  _c("label", [_vm._v("PPT Slides")]),
                  _vm._v(
                    "\n               " +
                      _vm._s(_vm.orderMagData.copywritingCourse.pptSlides) +
                      "\n            "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Sales")]),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.orderMagData.sysSeller &&
                    _vm.orderMagData.sysSeller.username
                    ? _vm.orderMagData.sysSeller.username
                    : ""
                ) +
                "\n         "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Operator")]),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.orderMagData.sysUserEntity &&
                    _vm.orderMagData.sysUserEntity.username
                    ? _vm.orderMagData.sysUserEntity.username
                    : ""
                ) +
                "\n         "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Teacher")]),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.orderMagData.teacUser &&
                    _vm.orderMagData.teacUser.username
                    ? _vm.orderMagData.teacUser.username
                    : ""
                ) +
                "\n         "
            ),
          ]),
          _vm._v(" "),
          _vm.copywritingCourse &&
          [5].includes(Number(_vm.copywritingCourse.type))
            ? [
                _c("p", [
                  _c("label", [_vm._v("Files")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "file-box" },
                    _vm._l(
                      _vm.orderMagData.revisionFiles,
                      function (files, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "file-wrap",
                            on: {
                              click: function ($event) {
                                return _vm.openUrl(files.url)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                     " + _vm._s(files.name)
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("i", [_vm._v(_vm._s(files.createAt))]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("message")]),
                  _vm._v(
                    "\n               " +
                      _vm._s(_vm.orderMagData.copywritingCourse.validatedMsg) +
                      "\n            "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.copywritingCourse &&
          [1, 2, 3, 4, 8, 9, 11].includes(Number(_vm.copywritingCourse.type))
            ? _c("p", [
                _c("label", [_vm._v("Reference Style")]),
                _vm._v(" "),
                _vm.copywritingCourse && _vm.copywritingCourse.referenceStyle
                  ? _c("span", [
                      _vm._v("\n               MLA "),
                      _c(
                        "i",
                        {
                          staticStyle: {
                            "font-style": "normal",
                            color: "#3496FB",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openUrl(
                                _vm.copywritingCourse.referenceGuide
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.copywritingCourse.referenceStyle))]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-download",
                        staticStyle: { color: "#3496FB", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(_vm.copywritingCourse)
                          },
                        },
                      }),
                    ])
                  : _c("span", [_vm._v("——")]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./Summary.vue?vue&type=template&id=16de686e&scoped=true"
import script from "./Summary.vue?vue&type=script&lang=js"
export * from "./Summary.vue?vue&type=script&lang=js"
import style0 from "./Summary.vue?vue&type=style&index=0&id=16de686e&lang=scss"
import style1 from "./Summary.vue?vue&type=style&index=1&id=16de686e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16de686e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16de686e')) {
      api.createRecord('16de686e', component.options)
    } else {
      api.reload('16de686e', component.options)
    }
    module.hot.accept("./Summary.vue?vue&type=template&id=16de686e&scoped=true", function () {
      api.rerender('16de686e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dissertation/components/Stage/Summary.vue"
export default component.exports
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "550px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "订单超时提醒",
            "append-to-body": "",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        _vm._l(_vm.list, function (item) {
          return _c("orderCard", {
            key: item.courseId,
            ref: "orderCard",
            refInFor: true,
            staticClass: "order-card",
            attrs: { order: item },
            on: { refresh: _vm.queryOvertimeCourses },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
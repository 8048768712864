var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-container",
      style: _vm.staticPath.BG,
    },
    [
      _c("countdown"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fakeclass-container" },
        [
          _c(
            "el-popover",
            {
              staticClass: "tip-popover",
              attrs: { width: "280", trigger: "manual" },
              model: {
                value: _vm.showPopover,
                callback: function ($$v) {
                  _vm.showPopover = $$v
                },
                expression: "showPopover",
              },
            },
            [
              _c("div", { staticClass: "tip-box" }, [
                _c("span", { staticClass: "triangle" }),
                _vm._v(" "),
                _c("div", { staticClass: "tip-desc" }, [
                  _vm._v(
                    "\n                    想尝试课堂上课吗？来课堂训练场试一试吧\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tip-desc" }, [
                  _vm._v(
                    "\n                    在真实课堂尝试上下课会扣分哦\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "gocourse cursorpointer",
                  attrs: { slot: "reference" },
                  on: {
                    click: function ($event) {
                      return _vm.gotoUrl("/trainingground")
                    },
                  },
                  slot: "reference",
                },
                [_vm._v("\n                课堂模拟场\n            ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c("mini-map", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.modalShow,
                        expression: "!modalShow",
                      },
                    ],
                    on: { open: _vm.openModal },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("map-modal", {
                ref: "modal",
                on: {
                  close: function ($event) {
                    _vm.modalShow = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("main", [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c(
              "section",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.allLoading,
                    expression: "allLoading",
                  },
                ],
                staticClass: "identity",
              },
              [
                _vm._l(_vm.identityIcon, function (steps, indexs) {
                  return _c(
                    "div",
                    { key: indexs, staticClass: "docker" },
                    [
                      steps.status == 2
                        ? _c("img", {
                            staticClass: "checkbox-style",
                            attrs: {
                              src: require("@/assets/images/hatch/fakeclass/check.png"),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("img", {
                        attrs: {
                          src: [2, 3].includes(steps.status)
                            ? steps.done
                            : steps.todo,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openLink(steps, steps.id, indexs)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "item" }, [
                        _c("p", [_vm._v("Step" + _vm._s(indexs + 1))]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(steps.name) +
                              "\n                            "
                          ),
                          _c("span", { staticClass: "tips" }, [
                            _vm._v("(必选)"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      indexs == 2 &&
                      [3].includes(steps.status) &&
                      steps.mission == 1
                        ? [
                            _c("div", { staticClass: "fake-tip" }, [
                              _vm._v(
                                "\n                            请尽快预约授课时间！越快预约越快收到课件，获得更充足的备课时间哦！\n                        "
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "docker" }, [
                  _c("img", {
                    attrs: { src: _vm.listenImgSrc },
                    on: {
                      click: function ($event) {
                        return _vm.reservedClass()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm._m(2),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _vm.stepCurrentIndex >= 3
              ? _c("div", { staticClass: "btn wait" }, [
                  _vm._v(
                    "\n                身份审核在一个工作日左右完成，请耐心等待\n            "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("intention-modal", { ref: "intentionModal" }),
          _vm._v(" "),
          _c("course", {
            ref: "courseDom",
            on: { submitId: _vm.teacCompleteMandatoryStep },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "title" }, [
      _c("span", [_vm._v("万能班长讲师全真模拟课")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "content-container" }, [
      _c("div", { staticClass: "label" }, [_vm._v("讲师你好！")]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                    在全真模拟课下，您需要先点击\n                    "
        ),
        _c("span", [_vm._v("【我是学生】选择听课课程并查看评分表 ")]),
        _vm._v("。\n                "),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                    在预约完听课时间后，请尽快\n                    "
        ),
        _c("span", [_vm._v("点击【我是老师】选择自己授课的时间")]),
        _vm._v(
          "。越快预约授课时间，您将更快收到课件后，获得充分的备课时间。\n                "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", [_vm._v("听课与授课")]),
        _vm._v(" 务必在\n                    "),
        _c("span", [_vm._v("倒计时结束前")]),
        _vm._v("\n                    完成哦~超时则自动淘汰！"),
        _c("span", [_vm._v("课堂选择后无法更改")]),
        _vm._v("，请自行合理安排~\n                "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("p", [
        _vm._v("预约听课 "),
        _c("span", { staticClass: "default" }, [_vm._v("(自选)")]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "default" }, [
        _vm._v(
          "\n                            此项不做考核\n                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "GStage", {
  enumerable: true,
  get: function get() {
    return _Stage.default;
  }
});
Object.defineProperty(exports, "GTable", {
  enumerable: true,
  get: function get() {
    return _Table.default;
  }
});
Object.defineProperty(exports, "GTitle", {
  enumerable: true,
  get: function get() {
    return _Title.default;
  }
});
var _Title = _interopRequireDefault(require("./Title"));
var _Stage = _interopRequireDefault(require("./Stage"));
var _Table = _interopRequireDefault(require("./Table"));
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      imgList: [{
        url: require('@/assets/images/home/ware1.png'),
        desc: '背景提升包'
      }, {
        url: require('@/assets/images/home/ware2.png'),
        desc: '教学设备'
      }, {
        url: require('@/assets/images/home/ware3.png'),
        desc: '经理介绍信'
      }]
    };
  },
  methods: {
    show: function show() {
      this.isShow = true;
    },
    closeModal: function closeModal() {
      this.isShow = false;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "680px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("\n      学生订单需求\n    "),
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form-box",
              attrs: {
                model: _vm.form,
                rules: _vm.isPermision ? _vm.rules : {},
                "label-width": "240px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "level-form",
                  attrs: { label: "学生对作业的了解程度:", prop: "level" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "level-box" },
                    _vm._l(10, function (item) {
                      return _c(
                        "div",
                        {
                          staticClass: "level cursor",
                          class: { active: item <= _vm.form.level },
                          style: {
                            background:
                              item <= _vm.form.level
                                ? "rgba(255,135,35," + (item + 1) / 10 + ")"
                                : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeLevel(item)
                            },
                          },
                        },
                        [
                          _vm.form.level == item
                            ? _c("div", { staticClass: "level-value" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item) +
                                    "\n              "
                                ),
                                _c("span", { staticClass: "arrow-down" }),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "desc" }, [_vm._v("没有任何了解")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "desc-right" }, [
                    _vm._v("十分了解"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { label: "学生的写作方向（Topic）是:", prop: "topic" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "textarea", readonly: _vm.readonly },
                    on: { input: _vm.change },
                    model: {
                      value: _vm.form.topic,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "topic", $$v)
                      },
                      expression: "form.topic",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: {
                    label: "学生的订单需求是:",
                    required: _vm.readonly ? false : true,
                  },
                },
                [
                  _vm._l(_vm.form.needsList, function (item, index) {
                    return _c(
                      "div",
                      {
                        staticStyle: { position: "relative" },
                        style: { marginTop: index ? "8px" : "" },
                      },
                      [
                        _c(
                          "span",
                          { staticStyle: { position: "absolute", top: "0px" } },
                          [_vm._v("需求" + _vm._s(index + 1) + ":")]
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          staticStyle: {
                            width: "250px",
                            "margin-left": "52px",
                          },
                          attrs: { type: "textarea", readonly: _vm.readonly },
                          on: { input: _vm.change },
                          model: {
                            value: item.answer,
                            callback: function ($$v) {
                              _vm.$set(item, "answer", $$v)
                            },
                            expression: "item.answer",
                          },
                        }),
                        _vm._v(" "),
                        _vm.form.needsList.length > 1 && !_vm.readonly
                          ? _c("i", {
                              staticClass: "el-icon-delete cursor",
                              staticStyle: { "font-size": "25px" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteNeed(index)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  !_vm.readonly
                    ? _c(
                        "span",
                        {
                          staticClass: "add-need cursor",
                          on: { click: _vm.addNeed },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                          }),
                          _vm._v("新增\n        "),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否有特殊软件/语言要求:", prop: "radio" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.readonly },
                      model: {
                        value: _vm.form.radio,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "radio", $$v)
                        },
                        expression: "form.radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.radio == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { type: "textarea", readonly: _vm.readonly },
                        on: { input: _vm.change },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { position: "relative" },
                  attrs: {
                    label: "请选择订单文章的reference style:",
                    required: "",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: { position: "relative" },
                      model: {
                        value: _vm.form.checkList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "checkList", $$v)
                        },
                        expression: "form.checkList",
                      },
                    },
                    _vm._l(_vm.referenceList, function (item, index) {
                      return _c(
                        "el-checkbox",
                        {
                          key: index,
                          staticStyle: { display: "block" },
                          attrs: { label: item.id },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.form.checkList.includes(9)
                    ? _c("el-input", {
                        staticStyle: {
                          position: "absolute",
                          bottom: "-17px",
                          right: "20px",
                          width: "220px",
                        },
                        attrs: { type: "textarea", placeholder: "请输入" },
                        model: {
                          value: _vm.form.other,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "other", $$v)
                          },
                          expression: "form.other",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "附赠如何填写citation的网站:", required: "" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "10px",
                        display: "inline-block",
                      },
                    },
                    [
                      _vm._v(
                        "\n          https://libguides.reading.ac.uk/citing-references/referencingstyles"
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.readonly
            ? _c(
                "div",
                {
                  staticClass: "footer-btn",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "cancle-btn",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "confirm-btn",
                      on: { click: _vm.comfirmAdd },
                    },
                    [_vm._v("确定")]
                  ),
                ]
              )
            : _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
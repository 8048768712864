var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      class: { isB: _vm.isB },
      attrs: {
        visible: _vm.dialogVisible,
        width: _vm.isB ? "400px" : "900px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.isB
        ? [
            _c("h3", { staticClass: "title" }, [_vm._v("提示")]),
            _vm._v(" "),
            _c("div", { staticClass: "tip" }, [
              _vm._v("注意:此课堂属于B端渠道客户"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "confirm-btn", on: { click: _vm.confirm } },
              [_vm._v("确定")]
            ),
          ]
        : [
            _c("h3", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.secretKey ? "课堂提醒" : "课堂软件下载")),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "container-main",
                style: { padding: !_vm.secretKey ? "0" : "" },
              },
              [
                _vm.secretKey
                  ? _c("div", { staticClass: "download-container top" }, [
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("img", {
                            staticClass: "elc",
                            attrs: {
                              src: require("@/assets/images/home/elc.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "defaultlight-btn into-web-btn",
                              on: { click: _vm.gotoClass },
                            },
                            [_vm._v("\n            进入应用上课\n          ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("img", {
                            staticClass: "elc",
                            attrs: {
                              src: require("@/assets/images/download/web.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "defaultlight-btn into-web-btn",
                              on: { click: _vm.gotoWebClass },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/download/jianyi.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v("\n            进入网页上课\n          "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.secretKey
                  ? _c("div", { staticClass: "not-down" }, [
                      _vm._v("请先下载“Classbro Room”应用"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "type-title" }, [
                  _vm._v("Windows系统下载"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "win-box" }, [
                  _c("img", {
                    staticClass: "winstep",
                    attrs: {
                      src: require("@/assets/images/home/winstep.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "win-content" }, [
                    _c(
                      "div",
                      { staticClass: "step-content" },
                      [
                        _c("div", [_vm._v("Step 1 下载安装包")]),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/home/win.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "defaultlight-btn",
                            on: {
                              click: function ($event) {
                                return _vm.downloadApp("win")
                              },
                            },
                          },
                          [_vm._v("Windows系统下载")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "step-content",
                        staticStyle: { "margin-left": "30px" },
                      },
                      [
                        _c("div", { staticClass: "step-title" }, [
                          _vm._v(
                            "Step 2 安装完成后点击\n              “进入应用上课”"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.secretKey
                          ? _c(
                              "el-button",
                              {
                                staticClass: "defaultlight-btn",
                                staticStyle: { "margin-left": "15px" },
                                on: { click: _vm.gotoClass },
                              },
                              [_vm._v("进入应用上课")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "type-title" }, [
                  _vm._v("Mac系统下载"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "win-box" }, [
                  _c("img", {
                    staticClass: "macstep",
                    attrs: {
                      src: require("@/assets/images/home/macstep.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "win-content" }, [
                    _c(
                      "div",
                      { staticClass: "step-content" },
                      [
                        _c("div", { staticClass: "step-title" }, [
                          _vm._v("Step 1 下载安装包"),
                        ]),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/home/mac.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "defaultlight-btn",
                            on: {
                              click: function ($event) {
                                return _vm.downloadApp("mac")
                              },
                            },
                          },
                          [_vm._v("Mac系统下载")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "step-content",
                        staticStyle: { "margin-left": "40px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "step-title",
                            staticStyle: { width: "180px" },
                          },
                          [_vm._v("Step 2 安装完成后打开应用")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "step-content",
                        staticStyle: { "margin-left": "40px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "step-title",
                            staticStyle: { width: "205px" },
                          },
                          [
                            _vm._v("Step 3 "),
                            _c("span", [_vm._v("请根据以下文档指引操作")]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "tipdesc" }, [
                          _c("div", [
                            _vm._v("Mac系统安装完系统后请根据\n              "),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v(" 文档指引操作：")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "link",
                              on: {
                                click: function ($event) {
                                  return _vm.openLink(_vm.link)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.link))]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "step-content",
                        staticStyle: { "margin-left": "60px" },
                      },
                      [
                        _c("div", { staticClass: "step-title" }, [
                          _vm._v(
                            "Step 4 安装完成后点击\n              “进入应用上课”"
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.secretKey
                          ? _c(
                              "el-button",
                              {
                                staticClass: "defaultlight-btn",
                                staticStyle: { "margin-left": "15px" },
                                on: { click: _vm.gotoClass },
                              },
                              [_vm._v("进入应用上课")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "medal-content" },
    [
      _c("div", { staticClass: "info-detail" }, [
        _c(
          "div",
          {
            staticClass: "img-box",
            class: { mr10: _vm.teacherInfo.headImage },
          },
          [
            _vm.teacherInfo.headImage
              ? _c("img", {
                  staticClass: "head-img",
                  attrs: { src: _vm.teacherInfo.headImage, alt: "" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("img", {
              attrs: { src: _vm._f("globalImgFilter")(_vm.teacherInfo) },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "info-level" }, [
          _c("div", { staticClass: "font-white" }, [
            _vm._v(_vm._s(_vm.teacherInfo.nickName)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "get-num" }, [
            _c("img", {
              staticClass: "star",
              attrs: {
                src: require("@/assets/images/home/wujiaoxing.png"),
                alt: "",
              },
            }),
            _c("span", [
              _c("b", [_vm._v(_vm._s(_vm.getMedalNum || 0))]),
              _vm._v("枚勋章"),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: " desc",
              attrs: {
                src: require("@/assets/images/home/zhuyi.png"),
                alt: "",
              },
            }),
            _vm._v("个人首页最多可挂起5枚勋章\n      "),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "min-height": "calc(100vh - 380px)" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "medal-item" }, [
                _vm._v("\n        月度排行勋章"),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-left": "5px", "font-weight": "400" },
                  },
                  [
                    _vm._v(_vm._s(_vm.monthMedal.getCount) + "/\n          "),
                    _c("span", { staticClass: "item-num" }, [
                      _vm._v(_vm._s(_vm.monthMedal.sumCount)),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medal-box" },
                _vm._l(_vm.monthMedal.medals, function (item, index) {
                  return _c(
                    "div",
                    { staticClass: "medal-info" },
                    [_c("medalItem", { attrs: { item: item } })],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "medal-item" }, [
                _vm._v("\n        2023年度勋章"),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-left": "5px", "font-weight": "400" },
                  },
                  [
                    _vm._v(_vm._s(_vm.yearMedal.getCount) + "/\n          "),
                    _c("span", { staticClass: "item-num" }, [
                      _vm._v(_vm._s(_vm.yearMedal.sumCount)),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medal-box" },
                _vm._l(_vm.yearMedal.medals, function (item, index) {
                  return _c(
                    "div",
                    { staticClass: "medal-info" },
                    [_c("medalItem", { attrs: { item: item } })],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "medal-item" }, [
                _vm._v("\n        特别成就勋章"),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-left": "5px", "font-weight": "400" },
                  },
                  [
                    _vm._v(_vm._s(_vm.specialMedal.getCount) + "/\n          "),
                    _c("span", { staticClass: "item-num" }, [
                      _vm._v(_vm._s(_vm.specialMedal.sumCount)),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medal-box" },
                _vm._l(_vm.specialMedal.medals, function (item, index) {
                  return _c(
                    "div",
                    { staticClass: "medal-info" },
                    [
                      _c("medalItem", {
                        attrs: { item: item, showNum: false },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "medal-item" }, [
                _vm._v("\n        绝版勋章"),
                _c(
                  "span",
                  {
                    staticStyle: { "margin-left": "5px", "font-weight": "400" },
                  },
                  [
                    _vm._v(_vm._s(_vm.onlyMedal.getCount) + "/\n          "),
                    _c("span", { staticClass: "item-num" }, [
                      _vm._v(_vm._s(_vm.onlyMedal.sumCount)),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "medal-box" },
                _vm._l(_vm.onlyMedal.medals, function (item, index) {
                  return _c(
                    "div",
                    { staticClass: "medal-info" },
                    [
                      _c("medalItem", {
                        attrs: { item: item, showNum: false },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("newMedalDetail", {
        ref: "medalDetail",
        attrs: { teacherInfo: _vm.teacherInfo },
        on: { refresh: _vm.getTeacMedalInfo },
      }),
      _vm._v(" "),
      _c("getMedalModal", { ref: "getMedalModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
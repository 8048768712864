"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.startTrain = exports.startLetter = void 0;
var _request = require("./request");
// 讲师信

/**
 * 开启培训
 * @param {String} userId
 */
var startTrain = exports.startTrain = function startTrain(userId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/startTrain/").concat(userId),
    method: "get"
  });
};
var startLetter = exports.startLetter = function startLetter(userId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/startLoginState/").concat(userId),
    method: "get"
  });
};
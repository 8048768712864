"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["statuscode", "text"],
  data: function data() {
    return {
      statusArr: [{
        img: require("@/assets/images/order-contentnone.png"),
        content: "订单池暂无委派订单,快去联系师资老师接单赚钱吧～"
      }, {
        img: require("@/assets/images/order-contentnone.png"),
        content: "订单空空如也～赶快去接单吧"
      }, {
        img: require("@/assets/images/order-contentnone.png"),
        content: "去上你的第一堂课吧～"
      }, {
        img: require("@/assets/images/searchcontent-none.png"),
        content: "无搜索数据"
      }, {
        img: require("@/assets/images/notice-none.png"),
        content: "暂无数据"
      },
      //获赞无数据展示
      {
        img: require("@/assets/images/notice-none.png"),
        content: "暂无数据"
      } //没有数据,及其是全职老师的展示
      ]
    };
  },
  mounted: function mounted() {
    if (this.statuscode == 5) {
      this.statusArr[this.statuscode].content = this.text;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _persondetail = require("@/api/persondetail");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    showM: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('closeMonth');
    },
    //去看看（去工资记录页）
    goToSee: function goToSee() {
      this.$router.push('/account/withdrawlist');
      this.handleClose();
    }
  }
};
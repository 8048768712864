var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dissertation-grade-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isShow, center: "", width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [_vm._v("论文评级")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "grade-form" }, [
            _c("div", { staticClass: "tips" }, [
              _c("span", [
                _vm._v("请针对学生的论文水平，给出你客观的评价等级不能修改"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "check-box" }, [
              _c("div", [
                _c("img", {
                  class: { isActive: _vm.form.rate === 1 },
                  attrs: {
                    src: require("@/assets/images/dissertation/modal/A.png"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkGrade(1)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("优秀")]),
                _vm._v(" "),
                _c("span", { staticClass: "remark" }, [_vm._v("100-75分")]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  class: { isActive: _vm.form.rate === 2 },
                  attrs: {
                    src: require("@/assets/images/dissertation/modal/B.png"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkGrade(2)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("良好")]),
                _vm._v(" "),
                _c("span", { staticClass: "remark" }, [_vm._v("55-74分")]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  class: { isActive: _vm.form.rate === 3 },
                  attrs: {
                    src: require("@/assets/images/dissertation/modal/C.png"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkGrade(3)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("一般")]),
                _vm._v(" "),
                _c("span", { staticClass: "remark" }, [_vm._v("45-54分")]),
                _vm._v(" "),
                _c("span", { staticClass: "error warning" }, [
                  _vm._v("存在挂科风险"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  class: { isActive: _vm.form.rate === 4 },
                  attrs: {
                    src: require("@/assets/images/dissertation/modal/D.png"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.checkGrade(4)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error" }, [_vm._v("必挂")]),
                _vm._v(" "),
                _c("span", { staticClass: "error" }, [_vm._v("小于45分")]),
              ]),
            ]),
            _vm._v(" "),
            !_vm.dissertationRate
              ? _c("div", { staticClass: "btn-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-plain",
                      on: {
                        click: function ($event) {
                          _vm.isShow = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn", on: { click: _vm.submit } }, [
                    _vm._v("确定"),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
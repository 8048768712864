"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _Utils = require("@/utils/task/Utils");
var _contentnull = _interopRequireDefault(require("../contentnull"));
var _handsUp = _interopRequireDefault(require("@/views/orders/handsUp"));
var _myorder = _interopRequireDefault(require("../myorders/myorder"));
var _orderReceivingNow = _interopRequireDefault(require("@/views/paperchedule/orderReceivingNow"));
var _modelbox = _interopRequireDefault(require("./testorder/modelbox"));
var _examTip = _interopRequireDefault(require("@/views/orders/components/examTip"));
var _newGetOrder = _interopRequireDefault(require("./newGetOrder"));
var _common = require("@/utils/common.js");
var _salarySystem = _interopRequireDefault(require("@/views/orders/components/salarySystem"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import finishedCourse from "../myorders/finishedCourse"
// import orderReceiving from "@/views/paperchedule/orderReceiving"
var _default = exports.default = {
  data: function data() {
    return {
      orderTatol: {},
      modelObj: {
        btnno: '',
        btnyes: '',
        title: '',
        content: ''
      },
      timeIsSort: 1,
      testOrderObj: {
        show: false,
        title: '考试订单',
        text1: '1、Exam&Quiz订单接单后，须在考试前',
        text2: '2、接单后退单将面临<b>2倍</b>的订单预计收入扣除作为处罚（不可抗力因素除外）',
        textWeight: '填写考试信息',
        imgSrc: require("@/assets/images/test/testorder-img.png")
      },
      videourl: '',
      showvideo: false,
      user: '',
      time: '',
      clickObj: {},
      clcikType: '',
      maskShow: false,
      showInfo: false,
      imgsrc: '',
      tableDataBegin: [],
      tableDataName: "",
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      total: 0,
      limit: 15,
      idss: [],
      val: 1,
      page: "",
      ruleForm: {},
      rules: {},
      loading: false,
      isactive: 0,
      searchtext: '',
      ordertapdata: [{
        name: '全部订单',
        value: '0'
      }, {
        name: '星标订单',
        value: '0'
      }],
      liactive: 0,
      teacherType: localStorage.getItem("teacType"),
      bgImg: require("@/assets/images/taplistbg.png"),
      lookLwdlbStatus: false,
      videoduration: '',
      videocurrent: '',
      coursetypeImg: {
        0: require("@/assets/images/order_custom.png"),
        //定制辅导
        1: require("@/assets/images/order_test.png"),
        //考前突击
        4: require("@/assets/images/order_paper.png"),
        //论文大礼包
        65: require("@/assets/images/order_paper.png"),
        //论文大礼包
        32: require("@/assets/images/order_material.png"),
        //特殊
        66: require("@/assets/images/order_material.png"),
        //特殊
        64: require("@/assets/images/order_class.png"),
        //班课
        67: require("@/assets/images/order_graduation.png"),
        //毕业论文大礼包
        68: require("@/assets/images/order_course.png"),
        //智能课程
        69: require("@/assets/images/order_material.png"),
        //特殊
        71: require("@/assets/images/order_zyfd.png"),
        //作业辅导
        26: require("@/assets/images/order_pack.png"),
        //新版包课
        27: require("@/assets/images/lunwenrunse.png") //论文润色
      },
      bcoursetypeImg: {
        6: require("@/assets/images/order_pack.png")
      },
      ordertaplist: [{
        name: '订单池',
        num: 10,
        beforeimgsrc: require("@/assets/images/orderlist.png"),
        afterimgsrc: require("@/assets/images/orderlist-none.png"),
        bgcolor: require("@/assets/images/orderlist-choose.png")
      }, {
        name: '我的订单',
        num: 10,
        beforeimgsrc: require("@/assets/images/myorder.png"),
        afterimgsrc: require("@/assets/images/myorder-none.png"),
        bgcolor: require("@/assets/images/myorder-choose.png")
      },
      // { name: '完成课堂', num: 10, beforeimgsrc: require("@/assets/images/course.png"), afterimgsrc: require("@/assets/images/course-none.png"), bgcolor: require("@/assets/images/course-choose.png") },
      {
        name: '薪资模式',
        num: 10,
        beforeimgsrc: require("@/assets/images/salary.png"),
        afterimgsrc: require("@/assets/images/no-salary.png"),
        bgcolor: require("@/assets/images/salary-choose.png")
      }],
      timeArr: [{
        name: '【默认】操作时间近-远排序',
        id: 1
      }, {
        name: 'DDL时间 近-远排序',
        id: 2
      }
      // {
      //   name:'Deal Deadline时间 远-近',
      //   id:3
      // }
      ]
    };
  },
  components: {
    myorder: _myorder.default,
    contentnull: _contentnull.default,
    // finishedCourse,
    orderReceiving: _orderReceivingNow.default,
    modelbox: _modelbox.default,
    handsUp: _handsUp.default,
    examTip: _examTip.default,
    newGetOrder: _newGetOrder.default,
    salarySystem: _salarySystem.default
  },
  multipleSelection: [],
  watch: {
    isactive: function isactive(val) {
      if (val == 0) {
        this.timeIsSort = 1;
        this.val = 1; //重置页码
        this.getTableData();
      }
      this.getClasstotal();
    }
  },
  mounted: function mounted() {
    this.$route.query.isactive && (this.isactive = this.$route.query.isactive);
    if (localStorage.getItem('ordertap')) {
      this.isactive = localStorage.getItem('ordertap');
    }
    this.videoready();
    this.getTableData();
    this.getUser();
    this.getClasstotal();
  },
  methods: {
    newPreExam: function newPreExam(list) {
      return [1].includes(list.spoType) && [2, 3].includes(list.spoVersion);
    },
    hasBeike: function hasBeike(list) {
      return list.hasCoursewarePlatform;
    },
    gotopaper: function gotopaper(list) {
      this.$store.dispatch("dissertation/gotoDissertation", {
        courseId: list.courseId,
        readonly: true
      });
      //跳转论文大礼包
    },
    videoready: function videoready() {
      var _this = this;
      var that = this;
      var timer = setInterval(function () {
        var myvideo = document.getElementById("myvideo");
        if (myvideo) {
          myvideo.controls = false;
          if (_this.lookLwdlbStatus) {
            myvideo.controls = true;
          } else {
            myvideo.addEventListener('timeupdate', function (event) {
              var showtimes = (0, _Utils.formatSeconds)(event.target.currentTime);
              _this.videocurrent = showtimes.m + ':' + showtimes.s;
              if (_this.videoduration) {
                return;
              }
              var times = (0, _Utils.formatSeconds)(event.target.duration);
              _this.videoduration = times.m + ':' + times.s;
            });
          }
          myvideo.addEventListener('ended', function () {
            var datas = {
              courseId: _this.courseId
            };
            if (_this.lookLwdlbStatus) {
              _this.lookLwdlbStatus = false;
              _this.closevideo();
              return false;
            }
            _this.$axios({
              url: _this.$api.baseURL + "/order/teacherSeenMovie",
              method: "post",
              data: datas
            }).then(function (mr) {
              if (mr.status == 200) {
                _this.closevideo();
                //   看完视频接单
                !_this.newPreExam(_this.clickObj) && _this.$refs.newGetOrder.show(); //新版考前突击不重复弹
              }
            });
          });
          clearInterval(timer);
        }
      }, 1000);
    },
    closevideo: function closevideo() {
      this.showvideo = false;
      this.videourl = '';
      if (this.newPreExam(this.clickObj)) {
        //新版考前突击不强制看关闭视频弹接单
        this.$refs.newGetOrder.show();
      }
    },
    getUser: function getUser() {
      var _this2 = this;
      this.$axios({
        url: this.$api.baseURL + "/user/getMyInfo"
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this2.$router.push({
              path: "/login"
            });
          }
        }
        _this2.user = r.data.body;
        _this2.time = Number(_this2.user.presenterTimeLimit) - Number(_this2.user.presenterTimeConsume);
      }).catch(function (er) {});
    },
    getClasstotal: function getClasstotal() {
      var _this3 = this;
      this.$axios({
        url: this.$api.baseURL + "/order/order/class/total"
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this3.$router.push({
              path: "/login"
            });
          }
        }
        _this3.orderTatol = r.data.body;
        _this3.$store.dispatch('user/changeorderNum', r.data.body.orderPoolCount);
      }).catch(function (er) {});
    },
    lookVideo: function lookVideo(list) {
      var _this4 = this;
      this.$axios({
        url: this.$api.baseURL + "/order/checkIsFirstReceiveExamGiftOrder?courseId=" + list.courseId,
        method: "get"
      }).then(function (res) {
        console.log('论文大礼包判断数据', res);
        if (res.data.status == 400) {
          _this4.$notify({
            title: '提示',
            message: res.data.body.msg,
            type: "warning"
          });
          return;
        }
        // if(res.data.body.watchLwdlbVideo) return;
        _this4.lookLwdlbStatus = true;
        _this4.modelObj = {
          btnyes: '查看视频',
          title: '什么是论文大礼包?',
          content: '视频为您讲解什么是论文大礼包订单，认真听讲有助于你每一环节的上课过程哦'
        };
        _this4.openMask();
        _this4.videourl = res.data.body.urls.lwdlbVideoUrl;
        _this4.clcikType = 'playvideo';
      }).catch(function (err) {});
    },
    showModel: function showModel(row, type) {
      var _this5 = this;
      this.clickObj = row;
      this.clcikType = type;
      if (type) {
        this.$axios({
          url: this.$api.baseURL + "/order/checkIsFirstReceiveExamGiftOrder?courseId=" + row.courseId,
          data: {
            courseId: row.courseId
          },
          method: "get"
        }).then(function (res) {
          if (res.data.status == 400) {
            _this5.$notify({
              title: '提示',
              message: res.data.body.msg,
              type: "warning"
            });
            return;
          }
          if (res.status == 200) {
            // 文案-考试订单接单
            if (row.spoType == 69 && row.copywritingCourse && row.copywritingCourse.type == 3) {
              _this5.$refs.examTip.show(row);
              return;
            } else {
              _this5.$refs.newGetOrder.show();
            }
            // }
          }
        }).catch(function (er) {});
      } else {
        this.modelObj = {
          btnno: '我再看看',
          btnyes: '确定',
          title: '是否要退单?',
          content: ''
        };
        this.clcikType = 'tuidan';
        this.openMask();
      }
    },
    //确认接单
    confirmgetFun: function confirmgetFun(row) {
      var _this6 = this;
      this.closeMask();
      if (row.firstFreeCourse == 1) {
        this.$confirm("请在1小时内联系学生，确定破冰课上课时间，需要在24小时内开始课堂哦", "提示", {
          confirmButtonText: "确定",
          type: "warning",
          // center: true,
          customClass: "confirmAlert"
        });
      }
      this.loading = true;
      this.$axios({
        url: this.$api.baseURL + "/order/receiveOrder",
        data: {
          courseId: row.courseId
        },
        method: "post"
      }).then(function (r) {
        _this6.loading = false;
        if (r.data.status == 200) {
          _this6.$notify({
            title: '提示',
            message: r.data.body.msg,
            type: "success"
          });
          // 如果辅导单子去备课平台
          if (_this6.hasBeike(row)) {
            (0, _common.goToBeiKe)(row);
          }
          _this6.getTableData();
          _this6.getClasstotal();
          _this6.closeModal();
        } else {
          _this6.loading = false;
          _this6.$notify.error(r.data && r.data.body && r.data.body.msg || '请求失败');
        }
      }).catch(function (er) {
        _this6.loading = false;
      });
    },
    //点击确认的按钮
    yesFun: function yesFun() {
      var row = this.clickObj;
      if (this.clcikType == 'playvideo') {
        this.courseId = row.courseId;
        this.showvideo = true;
        this.closeMask();
      } else if (this.clcikType == 'getorder') {
        this.confirmgetFun(row);
      } else if (this.clcikType == 'tuidan') {
        this.noapply(row);
      } else {
        this.closeMask();
      }
    },
    openMask: function openMask() {
      this.maskShow = true;
    },
    closeMask: function closeMask() {
      this.maskShow = false;
      console.log('this.clickObj', this.clickObj, this.modelObj, this.showvideo);
      if (this.newPreExam(this.clickObj)) {
        //新版考前突击接单流程
        // 关闭弹观看视频和视频窗弹接单窗口
        if (this.modelObj.title == '观看视频?' && !this.showvideo) this.$refs.newGetOrder.show();
      }
    },
    changeTap: function changeTap(index) {
      this.liactive = index;
    },
    search: function search() {
      this.searchtext = this.tableDataName;
      if (this.isactive == 0) {
        //搜索时重置页码
        this.val = 1;
        this.getTableData();
      }
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      return 'rowstyles';
    },
    changeTaps: function changeTaps(index) {
      this.isactive = index;
      localStorage.setItem('ordertap', this.isactive);
      this.searchtext = '';
      this.tableDataName = '';
    },
    getTableData: function getTableData() {
      var _this7 = this;
      this.loading = true;
      var loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading"
      });
      this.$axios({
        url: this.$api.baseURL + "/order/list",
        method: "get",
        headers: {
          "Content-Type": "application/json"
        },
        params: {
          page: this.val,
          limit: this.limit,
          statused: 0,
          hasOrderPool: true,
          key: this.searchtext,
          pool: 1
        }
      }).then(function (r) {
        _this7.loading = false;
        _this7.tableDataEnd = r.data.body.list;
        _this7.total = r.data.body.totalCount;
        _this7.pageSize = r.data.body.totalCount;
        _this7.currentPage = r.data.body.currPage;
        loading.close();
      }).catch(function (er) {
        _this7.loading = false;
      });
    },
    see: function see(row, index, offCourseName) {
      if ((0, _common.JudgeNewOrder)(row)) {
        (0, _common.goToPublicDetail)(row);
      } else if (row.spoType === 64) {
        var Logistics2 = this.$router.resolve({
          path: "/bigClassRoom",
          query: {
            id: row.courseId,
            parentId: null
          }
        });
        window.open(Logistics2.href);
      } else {
        var type = 'daobi';
        if (row.courseType === 4) {
          type = 'wenyaold';
        }
        if (row.spoType === 69) {
          type = 'wenya';
        }
        var Logistics1 = this.$router.resolve({
          path: "/sellerDetail",
          query: {
            id: row.courseId,
            type: type
          }
        });
        window.open(Logistics1.href);
      }
    },
    noapply: function noapply(row, index) {
      var _this8 = this;
      this.$axios({
        url: this.$api.baseURL + "/order/returnedOrder",
        data: {
          courseId: row.courseId
        },
        method: "post"
      }).then(function (r) {
        if (r.data.status == 200) {
          _this8.$notify({
            title: '提示',
            message: r.data.body.msg,
            type: "success"
          });
          _this8.getTableData();
          _this8.getClasstotal();
          _this8.closeMask();
        }
        if (r.data.status == 400) {
          _this8.$notify({
            title: '错误提示',
            message: r.data.body.msg,
            type: 'error'
          });
        }
      }).catch(function (er) {});
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.limit = val;
      this.getTableData();
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.map(function (item) {
        var id = item.id;
        ids.push("" + id + "");
      });
      this.selectedIDs = ids;
      console.log("多选", ids);
      this.idss = this.selectedIDs;
    },
    closeModalTest: function closeModalTest() {
      this.testOrderObj.show = false;
    },
    receivOrderTest: function receivOrderTest() {
      this.closeModalTest();
      this.confirmgetFun(this.clickObj);
    },
    closeModal: function closeModal() {
      this.showInfo = false;
      this.$refs.examTip && this.$refs.examTip.closeModal();
      this.$refs.newGetOrder && this.$refs.newGetOrder.closeModal();
    },
    //论文大礼包3.0接单
    receivOrder: function receivOrder() {
      this.confirmgetFun(this.clickObj);
    },
    // 播放大论文的video
    playDLWvideo: function playDLWvideo() {
      this.videourl = 'https://classbro-oss.oss-cn-hongkong.aliyuncs.com/staticUpload/10-15s%E6%94%B9%E6%94%B9%E6%94%B9%E5%8A%A0%E6%B0%B4%E5%8D%B0.mp4';
      this.showvideo = true;
      this.lookLwdlbStatus = true;
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("学生信息")]),
    _vm._v(" "),
    _vm.orderMagData.studUser
      ? _c("div", { staticClass: "detial-msg-box detial-msg-height" }, [
          _c("p", [
            _c("label", [_vm._v("姓名")]),
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.orderMagData.studUser &&
                    _vm.orderMagData.studUser.nickName
                ) +
                "\n           "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("学校名称")]),
            _vm._v(
              "\n             " +
                _vm._s(
                  _vm.orderMagData.sysUniversity &&
                    _vm.orderMagData.sysUniversity.name
                ) +
                "\n           "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("专业")]),
            _vm._v(
              "\n             " +
                _vm._s(
                  _vm.orderMagData.sysProfessionalCourses &&
                    _vm.orderMagData.sysProfessionalCourses.chineseName
                    ? _vm.orderMagData.sysProfessionalCourses.chineseName
                    : ""
                ) +
                "\n           "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("学年")]),
            _vm._v(
              "\n             " +
                _vm._s(_vm.orderMagData.schoolYearName) +
                "\n           "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("学校系统账号")]),
            _vm._v(
              "\n             " +
                _vm._s(_vm.orderMagData.studPurchaseOrder.schoolAccount) +
                "\n           "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("学校系统密码")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.orderMagData.studPurchaseOrder.schoolPws) +
                "\n           "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "level-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "1000px",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "level-wrap level-bg teacher-height" }, [
            _c("div", { staticClass: "level-top" }, [
              _c("div", { staticClass: "level-title" }, [
                _vm._v("\n            讲师等级\n          "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-wrap" }, [
                _c("div", { staticClass: "teacher-level" }, [
                  _c("div", [
                    _vm._v("\n                当前讲师等级\n                "),
                    _vm.teacinfo.level
                      ? _c("img", {
                          attrs: {
                            src: require("@/assets/images/level/newlevel/v" +
                              _vm.teacinfo.level +
                              "samll.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("\n                当前时薪\n                "),
                    _c("div", [
                      _c("span", [
                        _vm._v(_vm._s((_vm.teacinfo.hourlyWage || 0) * 10)),
                      ]),
                      _vm._v("RMB/H"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("\n                当前获赞值\n                "),
                    _c("div", [
                      _c("span", [_vm._v(_vm._s(_vm.teacinfo.thumbsNumber))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "zan-num" },
                        [
                          _vm.showZan || _vm.showZanNum
                            ? [
                                _vm.showZan
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "30px",
                                        height: "auto",
                                        "vertical-align": "-5px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/level/zan.gif"),
                                        alt: "",
                                      },
                                      on: { click: _vm.getAward },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showZanNum
                                  ? _c("img", {
                                      staticStyle: {
                                        position: "absolute",
                                        width: "30px",
                                        height: "auto",
                                        top: "0px",
                                        left: "0px",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/level/plus" +
                                          (_vm.teacinfo.level < 2 ? "1" : "5") +
                                          ".gif"),
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("img", {
                                  staticStyle: {
                                    position: "absolute",
                                    width: "30px",
                                    height: "auto",
                                    top: "27px",
                                    left: "0px",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/level/tuoyuan.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("\n                当前投诉率\n                "),
                    _c("div", [
                      _c("span", { staticStyle: { color: "#fff" } }, [
                        _vm._v(
                          _vm._s(
                            ((_vm.teacinfo.failRate || 0) * 100).toFixed(2)
                          ) + "%"
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info-right" }, [
                  _vm.teacinfo.level
                    ? _c("img", {
                        staticClass: "level-mid",
                        attrs: {
                          src: require("@/assets/images/level/newlevel/v" +
                            _vm.teacinfo.level +
                            "mid.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "levelall-wrap" }, [
                _c(
                  "ul",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "level-space",
                        style: { width: _vm.proLength + "px" },
                      },
                      [
                        _vm.thumbsNext
                          ? _c("span", [
                              _vm._v("\n                  还差"),
                              _c("b", [_vm._v(_vm._s(_vm.thumbsNext))]),
                              _vm._v("赞即可升级\n                "),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.teacinfo.level == 8
                          ? _c("span", { staticClass: "high-level" }, [
                              _vm._v("已达到最高等级"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.levelsArr, function (level, i) {
                      return i < 8
                        ? _c(
                            "li",
                            {
                              key: i,
                              class: [
                                _vm.currentLevel == level.level
                                  ? "currentactive"
                                  : "",
                                _vm.teacinfo.level + 1 == level.level
                                  ? "nextlevel"
                                  : "",
                                _vm.teacinfo.level < level.level
                                  ? "noachive"
                                  : "",
                                _vm.teacinfo.level >= level.level
                                  ? "pass-level"
                                  : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.clickLevel(level)
                                },
                              },
                            },
                            [
                              _c("p", [_vm._v("LV" + _vm._s(level.level))]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    level.level == 1
                                      ? "出道新人"
                                      : level.calThumbsNum + "赞"
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _c("b", [_vm._v(_vm._s(level.hourWage))]),
                                _vm._v("RMB/H"),
                              ]),
                              _vm._v(" "),
                              level.failRate
                                ? _c("p", [
                                    _vm._v(_vm._s(level.failRate) + "%投诉率"),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-rule" }, [
              _vm.currentLevel > 3
                ? _c("div", { staticClass: "rule-desc" }, [
                    _vm._v("等级福利请联系师资管理人员获取奥~"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rule-box" },
                [
                  _vm.currentLevel
                    ? [
                        _vm._l(
                          _vm.partLevelData[
                            "lv" +
                              (_vm.currentLevel < 4
                                ? 1
                                : _vm.currentLevel < 5
                                ? 4
                                : _vm.currentLevel < 7
                                ? 5
                                : 7)
                          ].welfare,
                          function (item, index) {
                            return [
                              !(_vm.currentLevel == 5 && index == 3)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "rule-item",
                                      class: {
                                        "flex-1": _vm.currentLevel < 4,
                                        "flex-2":
                                          _vm.currentLevel == 4 ||
                                          _vm.currentLevel > 6,
                                        "flex-5": _vm.currentLevel == 5,
                                        "flex-6": _vm.currentLevel == 6,
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "rule-icon",
                                        attrs: { src: item.icon, alt: "" },
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "rule-desc",
                                        domProps: {
                                          innerHTML: _vm._s(item.desc1),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "rule-desc" }, [
                                        _vm._v(_vm._s(item.desc2)),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "desc-box" }, [
              _c("div", [
                _c("h5", [
                  _vm._v("获赞途径"),
                  _c("span", [_vm._v("以下途径订单内可重复获得")]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("span", [_vm._v("【课堂反馈】")]),
                  _vm._v("：课堂结束后，让学生在"),
                  _c("span", [
                    _vm._v("【我的订单】-【课堂列表】-【点赞/投诉】"),
                  ]),
                  _vm._v("按钮，给讲师点赞\n            "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("span", [_vm._v("【订单反馈】")]),
                  _vm._v("：订单结束后，让学生在"),
                  _c("span", [
                    _vm._v("【我的订单】-【订单列表】-【点赞/投诉】"),
                  ]),
                  _vm._v("按钮，给讲师点赞\n            "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("span", [_vm._v("【高评价反馈】")]),
                  _vm._v(
                    "：订单结束后，课程顾问高评价反馈，讲师可额外获得订单50%-100% 赞值"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("img", {
                attrs: {
                  src: require("@/assets/images/level/newlevel/ware/dianzan.png"),
                  alt: "",
                },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "operate-box",
          style: { height: _vm.stageList.length ? "156px" : "80px" },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      固定辅导阶段\n      "),
            _vm.hasPB
              ? _c("span", { staticStyle: { "margin-left": "15px" } }, [
                  _vm._v("请先将破冰阶段拖拽到论文辅导规划表内，并完成"),
                  _vm.version == 3
                    ? _c("span", { staticStyle: { color: "#ff3a3a" } }, [
                        _vm._v("【破冰授课要求】"),
                      ])
                    : _vm._e(),
                  _vm._v("任务"),
                ])
              : _c("span", { staticClass: "title-desc" }, [
                  _vm._v("请将固定辅导阶段拖拽到论文辅导规划表内"),
                ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "stage-box" },
            _vm._l(_vm.stageList, function (item, index) {
              return _c(
                "el-tooltip",
                { key: index, attrs: { placement: "bottom" } },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      {
                        staticClass: "task-box",
                        staticStyle: { "line-height": "20px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { color: "#fff", "font-size": "12px" },
                          },
                          [_vm._v(" 任务列表")]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          item.studPaperSpreeStageTasks,
                          function (task, _index) {
                            return _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#ff8723",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v("任务" + _vm._s(_index + 1) + ":")]
                              ),
                              _vm._v(" "),
                              _c("span", {
                                staticStyle: {
                                  color: "#fff",
                                  "line-height": "18px",
                                  "font-size": "12px",
                                },
                                domProps: { innerHTML: _vm._s(task.name) },
                              }),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "operate-stage",
                      class: {
                        active: item.createFlag == 2,
                        "first-active": !index && item.createFlag == 1,
                      },
                      on: {
                        mouseover: function ($event) {
                          _vm.curIdx = index
                        },
                        mouseleave: function ($event) {
                          _vm.curIdx = -1
                        },
                      },
                    },
                    [
                      _vm.curIdx == index
                        ? _c("div", { staticClass: "tips" }, [
                            _vm._v("\n            拖拽至规划表 "),
                            _c("span"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "point-box" },
                        _vm._l(6, function (item, index) {
                          return _c("span", { staticClass: "point" })
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stage-name" }, [
                        _vm._v(
                          "阶段" +
                            _vm._s(item.sort) +
                            "【" +
                            _vm._s(item.name) +
                            "】"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("有" + _vm._s(item.fixedTaskNum) + "项固定任务"),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("editTime", {
        attrs: {
          isShow: _vm.showEdit,
          stage: _vm.currentStage,
          deadline: _vm.deadline,
        },
        on: {
          closeModal: _vm.closeModal,
          getStage: _vm.getStage,
          init: _vm.init,
          getButtonList: _vm.getButtonList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
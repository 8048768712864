var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp " }, [
    _c("h5", [_vm._v("订单需求")]),
    _vm._v(" "),
    _c("div", { staticClass: "detial-msg-box detial-msg-height" }, [
      _vm.orderMagData.order_type == 8
        ? _c("p", [
            _c("label", [_vm._v("考试开始时间")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.orderMagData.opening_time_from) +
                "\n         "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.orderMagData.order_type == 8
        ? _c("p", [
            _c("label", [_vm._v("考试结束时间")]),
            _vm._v(
              "\n            " +
                _vm._s(_vm.orderMagData.opening_time_to) +
                "\n         "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [
        _c("label", { staticStyle: { float: "left" } }, [_vm._v("辅导需求")]),
        _vm._v(" "),
        _c("b", {
          staticStyle: { width: "80%", float: "left", "font-weight": "normal" },
          domProps: { innerHTML: _vm._s(_vm.orderMagData.client_message) },
        }),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("Reference Style")]),
        _vm._v(
          "\n           " +
            _vm._s(_vm.orderMagData.reference_style) +
            "\n         "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("Reference Guide")]),
        _vm._v(" "),
        _vm.orderMagData.reference_guide != null
          ? _c(
              "span",
              {
                staticStyle: {
                  color: "#408EF4",
                  "font-weight": "600",
                  cursor: "pointer",
                },
                on: {
                  click: function ($event) {
                    return _vm.downFile(
                      _vm.orderMagData.reference_guide.file_path
                    )
                  },
                },
              },
              [_vm._v(_vm._s(_vm.orderMagData.reference_guide.real_name))]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
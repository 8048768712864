"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  userphoto: null
};
var mutations = {
  SET_USERPHOTO: function SET_USERPHOTO(state, userphoto) {
    state.userphoto = userphoto;
  }
};
var actions = {
  changephoto: function changephoto(_ref, res) {
    var commit = _ref.commit;
    commit('SET_USERPHOTO', res);
  }
};
var _default = exports.default = {
  state: state,
  mutations: mutations,
  actions: actions
};
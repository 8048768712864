var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fileList.length
        ? _vm._l(_vm.fileList, function (item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c(
                "span",
                {
                  staticClass: "file-name",
                  on: {
                    click: function ($event) {
                      return _vm.seeFile(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(item.createAt)),
              ]),
              _vm._v(" "),
              !_vm.disabled
                ? _c("i", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: item.loading,
                        expression: "item.loading",
                      },
                    ],
                    staticClass: "el-icon-delete delete-icon",
                    on: {
                      click: function ($event) {
                        return _vm.deleteFile(item, index)
                      },
                    },
                  })
                : _vm._e(),
            ])
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-container",
      style: _vm.staticPath.BG,
    },
    [
      _c("countdown"),
      _vm._v(" "),
      _c("div", { staticClass: "fake-container" }, [
        _c(
          "section",
          [
            _c(
              "transition",
              { attrs: { name: "fade", mode: "out-in" } },
              [
                _c("mini-map", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.modalShow,
                      expression: "!modalShow",
                    },
                  ],
                  on: { open: _vm.openModal },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("map-modal", {
              ref: "modal",
              on: {
                close: function ($event) {
                  _vm.modalShow = false
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("main", [
          _vm._m(0),
          _vm._v(" "),
          _c("section", { staticClass: "step-container" }, [
            _c(
              "div",
              {
                staticClass: "box",
                on: {
                  click: function ($event) {
                    return _vm.activate(1, [1])
                  },
                },
              },
              [
                _c("div", [
                  _c("img", {
                    class: {
                      visited: _vm.actives.includes(1),
                      current: _vm.current == 1,
                    },
                    attrs: {
                      src: require("@/assets/images/hatch/fake/download.png"),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { class: { active: _vm.actives.includes(1) } }, [
                  _vm._v("下载APP"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "process" }, [
              _c("div", { staticClass: "speed", style: _vm.isRun(1) }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "box",
                on: {
                  click: function ($event) {
                    return _vm.activate(2, [1, 2])
                  },
                },
              },
              [
                _c("div", [
                  _c("img", {
                    class: {
                      visited: _vm.actives.includes(2),
                      current: _vm.current == 2,
                    },
                    staticStyle: { filter: "none" },
                    attrs: {
                      src: require("@/assets/images/hatch/fake/login.png"),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { class: { active: _vm.actives.includes(2) } }, [
                  _vm._v("登录APP"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "process" }, [
              _c("div", { staticClass: "speed", style: _vm.isRun(2) }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "box",
                on: {
                  click: function ($event) {
                    return _vm.activate(3, [1, 2, 3])
                  },
                },
              },
              [
                _c("div", [
                  _c("img", {
                    class: {
                      visited: _vm.actives.includes(3),
                      current: _vm.current == 3,
                    },
                    attrs: {
                      src: require("@/assets/images/hatch/fake/next.png"),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", { class: { active: _vm.actives.includes(3) } }, [
                  _vm._v("下一步"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "static-box" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.current == 1,
                    expression: "current == 1",
                  },
                ],
              },
              [
                _c("img", {
                  attrs: {
                    width: "200px",
                    height: "200px",
                    src: require("@/assets/images/hatch/fake/qrcode.png"),
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("下载万能讲师APP")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.current == 2,
                    expression: "current == 2",
                  },
                ],
              },
              [
                _c("div", { attrs: { id: "login" } }),
                _vm._v(" "),
                _c("span", [_vm._v("登录万能讲师APP")]),
                _vm._v(" "),
                _c("span", [_vm._v("账户名和密码与Web端一致")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.current == 3,
                    expression: "current == 3",
                  },
                ],
              },
              [
                _c("img", {
                  attrs: {
                    width: "310px",
                    src: require("@/assets/images/hatch/fake/next_icon.png"),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "btn" }, [
                  _vm._v("请等待师资老师联系，注意查看企业微信"),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "title" }, [
      _c("span", [_vm._v("APP下载指引")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
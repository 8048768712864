"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menusList = exports.downList = void 0;
var _api = require("@/api/api");
var menusList = exports.menusList = [{
  title: '授课应用相关',
  id: '1',
  showArrow: true,
  upType: true,
  children: [{
    title: '万能讲师课堂测试入口',
    id: '1.1',
    showNo: true,
    //是否展示序号
    showArrow: false //是否展示收缩
  }, {
    title: '万能讲师客户端下载',
    id: '1.2',
    showNo: true,
    showArrow: false
  }, {
    title: '万能讲师课堂操作指南',
    id: '1.3',
    showNo: true,
    showArrow: true,
    upType: false,
    children: [
    // {
    // 	title: '上传课件',
    // 	id: '1.3.1',
    // 	showNo:true, //是否展示序号
    // 	showArrow:false//是否展示收缩
    // },
    {
      title: '课程大纲',
      id: '1.3.2',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '共享屏幕',
      id: '1.3.3',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '手写板',
      id: '1.3.4',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '虚拟背景',
      id: '1.3.5',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '下课',
      id: '1.3.6',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '镜像摄像头',
      id: '1.3.7',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '教室背景更换',
      id: '1.3.8',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '教室功能按键介绍',
      id: '1.3.9',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '教学工具介绍',
      id: '1.3.10',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '教室设置介绍',
      id: '1.3.11',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '美颜设置',
      id: '1.3.12',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }, {
      title: '学生点赞',
      id: '1.3.13',
      showNo: true,
      //是否展示序号
      showArrow: false //是否展示收缩
    }]
  }]
}, {
  title: '万能讲师规范手册',
  id: '2',
  showArrow: true,
  upType: true,
  children: [{
    title: '部门介绍',
    id: '2.1',
    showNo: true,
    upType: true,
    showArrow: true,
    children: [{
      title: '师资渠道 ',
      id: '2.1.1',
      showNo: true
    }, {
      title: '师资孵化',
      id: '2.1.2',
      showNo: true
    }, {
      title: '师资运营 ',
      id: '2.1.3',
      showNo: true
    }, {
      title: '讲师培优 ',
      id: '2.1.4',
      showNo: true
    }]
  }, {
    title: '工作软件',
    id: '2.2',
    showNo: true,
    upType: true,
    showArrow: true,
    children: [{
      title: '万能讲师手机端APP',
      id: '2.2.3',
      showNo: true
    }, {
      title: '通讯软件：企业微信',
      id: '2.2.1',
      showNo: true
    }, {
      title: '教学软件：万能讲师网页端&ClassRoom',
      id: '2.2.2',
      showNo: true
    }]
  }, {
    title: '讲师接单制度',
    id: '2.3',
    showNo: true
  }, {
    title: '讲师吐单惩罚',
    id: '2.7',
    showNo: true
  },
  // {
  // 	title: '讲师考勤制度',
  // 	id: '2.4',
  // 	showNo: true,
  // 	upType: true,
  // 	showArrow: true,
  // 	children: [
  // 		{
  // 			title: '拖延排课',
  // 			id: '2.4.1',
  // 			showNo:true,
  // 		},
  // 		{
  // 			title: '讲师迟到',
  // 			id: '2.4.2',
  // 			showNo:true,
  // 		},
  // 		{
  // 			title: '讲师调/停课（请假）',
  // 			id: '2.4.3',
  // 			showNo:true,
  // 		},
  // 		{
  // 			title: '讲师旷课',
  // 			id: '2.4.4',
  // 			showNo:true,

  // 		},
  // 	]
  // },
  {
    title: '订单质量管理',
    id: '2.5',
    showNo: true
  }, {
    title: '离职解约流程',
    id: '2.6',
    showNo: true
  }]
}];
var downList = exports.downList = [{
  img: require('@/assets/images/ios.png'),
  id: 'classInDownLoad_macOS',
  desc: 'Mac版',
  downLoadLink: _api.mkMacURL,
  guideName: 'Mac版安装指南',
  guideUrl: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/%E8%B5%84%E6%BA%90/Mac%E7%89%88%20%E5%AE%A2%E6%88%B7%E7%AB%AF%E4%B8%8B%E8%BD%BD.pdf'
}, {
  img: require('@/assets/images/win.png'),
  id: 'classInDownLoad_win7',
  desc: 'Windows',
  downLoadLink: _api.mkWinURL,
  guideName: 'Windows版安装指南',
  guideUrl: 'https://wchk-oss.oss-cn-hongkong.aliyuncs.com/statics/app/%E8%B5%84%E6%BA%90/Win%E7%89%88%20%E5%AE%A2%E6%88%B7%E7%AB%AF%E4%B8%8B%E8%BD%BD.pdf'
}];
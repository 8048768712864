"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("../css/conmmon.scss");
var _orderdetail = require("@/api/orderdetail");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['orderMagData'],
  data: function data() {
    return {
      stageStatus: {
        1: '未开始',
        2: '进行中',
        3: '已延期',
        4: '已完成'
      }
    };
  },
  created: function created() {
    console.log(this.orderMagData, 'this.classroomdata = r.data.body.classroomList;');
    // this.coursesItem = JSON.parse(JSON.stringify(this.orderMagData.studCoursewareList));
  },
  methods: {}
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "980px",
            "append-to-body": true,
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("Reflection")]),
          _vm._v(" "),
          _c("div", { staticClass: "desc-box" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.showModal("reflectionRule")
                  },
                },
              },
              [
                _vm._v("处理及薪资扣除规则"),
                _c("i", { staticClass: "el-icon-question" }),
              ]
            ),
            _vm._v(" "),
            _c("span", [
              _vm._v("点击下载"),
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.openlink(_vm.template)
                    },
                  },
                },
                [_vm._v("申诉模板")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "list-content",
            },
            _vm._l(_vm.list, function (item, index) {
              return _c("div", { key: index, staticClass: "list" }, [
                _c("div", { staticClass: "num" }, [
                  _vm._v(
                    " " + _vm._s(index < 9 ? "0" + (index + 1) : index + 1)
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "detail" }, [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "order",
                        on: {
                          click: function ($event) {
                            return _vm.goToOrder(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.orderNo))]
                    ),
                    _vm._v(" "),
                    [2, 3].includes(Number(item.teacherStatused)) &&
                    item.fileList.length
                      ? _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.downloadFile(
                                  item.id,
                                  item.fileList || []
                                )
                              },
                            },
                          },
                          [
                            _vm._v("【请先"),
                            _c("span", [
                              _vm._v(
                                "下载文件(" +
                                  _vm._s(
                                    item.fileList && item.fileList.length
                                  ) +
                                  ")"
                              ),
                            ]),
                            _vm._v(","),
                            _c("span", { staticClass: "red-font" }, [
                              _vm._v("否则会导致回传失败"),
                            ]),
                            _vm._v("】"),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [_vm._v("扣除： " + _vm._s(item.subGb) + "G")]),
                    _c("span", [
                      _vm._v(
                        "原因 ：" + _vm._s(_vm.reasonList[item.reasonType])
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "status" }, [
                  _c("div", [
                    [2, 3].includes(Number(item.teacherStatused))
                      ? _c(
                          "span",
                          { staticClass: "time" },
                          [
                            _vm._v("倒计时"),
                            _c("countDown", {
                              attrs: { remainTime: item.lastTime },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.statusList[item.teacherStatused])),
                  ]),
                  _vm._v(" "),
                  [1, 2, 3].includes(Number(item.teacherStatused))
                    ? _c(
                        "div",
                        {
                          staticClass: "\n                apply",
                          class: {
                            "disabled-btn": [1, 2].includes(
                              Number(item.teacherStatused)
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showModal("appealFile", item)
                            },
                          },
                        },
                        [_vm._v("申诉")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  [4, 5, 6].includes(Number(item.teacherStatused))
                    ? _c(
                        "div",
                        {
                          staticClass: "see",
                          on: {
                            click: function ($event) {
                              return _vm.showModal("reflectionLog", item)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "bottom-page",
            attrs: {
              small: "",
              "page-sizes": [20],
              "current-page": _vm.queryParams.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": function ($event) {
                return _vm.changePage($event, "limit")
              },
              "current-change": function ($event) {
                return _vm.changePage($event, "page")
              },
            },
          }),
          _vm._v(" "),
          _c("appealFile", { ref: "appealFile", on: { refresh: _vm.getList } }),
          _vm._v(" "),
          _c("reflectionLog", {
            ref: "reflectionLog",
            attrs: { reasonList: _vm.reasonList, statusList: _vm.statusList },
          }),
          _vm._v(" "),
          _c("reflectionRule", { ref: "reflectionRule" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
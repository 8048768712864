var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.isShow,
        width: "320px",
        title: _vm.titleList[_vm.step],
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.isShow = $event
        },
        close: _vm.closeModal,
      },
    },
    [
      _vm.step == 1
        ? _c(
            "div",
            { staticClass: "tip" },
            [
              _vm._v("\n    授课中请勿提及"),
              _c("span", [_vm._v("公司信息")]),
              _vm._v("与"),
              _c("span", [_vm._v("个人隐私")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "cancle-btn btn small-btn",
                  on: { click: _vm.closeModal },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "btn small-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showClass(_vm.classRoomId)
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : [
            _c("div", [_vm._v("等待5s若未打开万能讲师,请前去往下载")]),
            _vm._v(" "),
            _c(
              "el-button",
              { staticClass: "btn", on: { click: _vm.goToDownLoad } },
              [_vm._v("前往下载（" + _vm._s(_vm.time) + "s）")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
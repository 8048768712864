"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _Header = _interopRequireDefault(require("./Header"));
var _Table = _interopRequireDefault(require("./Table"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    GHeader: _Header.default,
    GTable: _Table.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["stageLoading", "stageTables"]))
};
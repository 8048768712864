"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false
    };
  },
  methods: {
    show: function show() {
      this.isShow = true;
    },
    closeModal: function closeModal() {
      this.isShow = false;
      this.$emit('nextStep');
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacCompleteMandatoryStep = exports.specialtyList = exports.simulationMandatoryStep = exports.saveTeacSkills = exports.professionalList = exports.getZipFilePlus = exports.getTeacInfo = exports.foMandatoryStep = void 0;
var _request = require("./request");
// 万能班长讲师全真模拟课 fakeclass
/**
 * 获取个人信息
 * @param {String} teacId
 */
var getTeacInfo = exports.getTeacInfo = function getTeacInfo(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/trainWhitelist/v1/getTeacInfo"),
    method: "get",
    params: params
  });
};
/**
 * 获取课程专业列表
 * @param {String} params
 */
var professionalList = exports.professionalList = function professionalList(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/trainWhitelist/v1/professionalList"),
    method: "get",
    params: params
  });
};
/**
* 擅长学科列表
* @param {String} professionalIds
*/
var specialtyList = exports.specialtyList = function specialtyList(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/trainWhitelist/v1/specialtyList"),
    method: "get",
    params: params
  });
};
/**
* 保存讲师专业技能
* @param {String} data
*/
var saveTeacSkills = exports.saveTeacSkills = function saveTeacSkills(data) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/trainWhitelist/v1/saveTeacSkills"),
    method: "post",
    data: data
  });
};
/**
* 全真模拟课步骤
* @param {String} teacId
*/
var simulationMandatoryStep = exports.simulationMandatoryStep = function simulationMandatoryStep(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/trainWhitelist/v1/simulationMandatoryStep"),
    method: "get",
    params: params
  });
};
/**
 * fo步骤
 * @param {String} teacId
 */
var foMandatoryStep = exports.foMandatoryStep = function foMandatoryStep(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/trainWhitelist/v1/foMandatoryStep"),
    method: "get",
    params: params
  });
};
/**
 * 完成步骤
 * @param {String} id
 */

var teacCompleteMandatoryStep = exports.teacCompleteMandatoryStep = function teacCompleteMandatoryStep(data) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/trainWhitelist/v1/teacCompleteMandatoryStep"),
    method: "post",
    data: data
  });
};
/**
 * 下载课件
 * @param {String} id
 */
var getZipFilePlus = exports.getZipFilePlus = function getZipFilePlus(data) {
  return (0, _request.request)({
    api: "/api/teacher/resource/getZipFilePlus",
    method: "post",
    data: data
  });
};
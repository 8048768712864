var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.bigClassRoom,
          expression: "bigClassRoom",
        },
      ],
      staticClass: "sellerDetail",
    },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.mm1, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.mm1 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm1",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm1,
                rules: _vm.rules1,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "studname" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.ruleForm1.studname,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm1, "studname", $$v)
                      },
                      expression: "ruleForm1.studname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "定金", prop: "amount1" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.ruleForm1.amount1,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm1, "amount1", $$v)
                      },
                      expression: "ruleForm1.amount1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "金额", prop: "amount" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      onkeyup:
                        "this.value=this.value.replace(/[^\\d.]/g,'').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3')",
                    },
                    model: {
                      value: _vm.ruleForm1.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm1, "amount", $$v)
                      },
                      expression: "ruleForm1.amount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              [
                _c(
                  "el-radio",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.radshow,
                        expression: "radshow",
                      },
                    ],
                    attrs: { label: "1" },
                    model: {
                      value: _vm.radio,
                      callback: function ($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio",
                    },
                  },
                  [_vm._v("订单扣款")]
                ),
                _vm._v(" "),
                _c(
                  "el-radio",
                  {
                    attrs: { label: "2" },
                    model: {
                      value: _vm.radio,
                      callback: function ($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio",
                    },
                  },
                  [_vm._v("余额扣款")]
                ),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.b1 },
                  on: {
                    click: function ($event) {
                      return _vm.save1("ruleForm1")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.b2))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.personmodel, width: "60%" },
          on: {
            "update:visible": function ($event) {
              _vm.personmodel = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: {
                width: "100%",
                "margin-top": "5px",
                background: "#e5e9f2",
              },
              attrs: { data: _vm.persondata, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "学生姓名", "show-overflow-tooltip": true },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.nickName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "销售名称", "show-overflow-tooltip": true },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.username))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "订单状态", "show-overflow-tooltip": true },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.classroomName))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-top": "15px" },
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [5],
              "page-size": _vm.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.mm, width: "40%" },
          on: {
            "update:visible": function ($event) {
              _vm.mm = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "studname" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.ruleForm.studname,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "studname", $$v)
                      },
                      expression: "ruleForm.studname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.s1,
                      expression: "s1",
                    },
                  ],
                  attrs: { label: "账单总金额", prop: "amount1" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.ruleForm.amount1,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "amount1", $$v)
                      },
                      expression: "ruleForm.amount1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.s2,
                      expression: "s2",
                    },
                  ],
                  attrs: { label: "还需充值", prop: "amount" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.ruleForm.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "amount", $$v)
                      },
                      expression: "ruleForm.amount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { color: "red", "margin-left": "75px" } },
                [_vm._v(" " + _vm._s(_vm.red) + " ")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "转账备注" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 4 },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-demo",
                  attrs: {
                    headers: _vm.myHeaders1,
                    data: _vm.data1,
                    multiple: true,
                    accept: "image/png, image/jpeg",
                    action: _vm.url1,
                    "on-preview": _vm.handlePreview1,
                    "on-remove": _vm.handleRemove1,
                    "on-success": _vm.success1,
                    "http-request": _vm.uploadFile1,
                    "on-error": _vm.onerror1,
                    "file-list": _vm.fileList1,
                    "list-type": "picture",
                    "on-change": _vm.change1,
                    "auto-upload": false,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "80px" },
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary",
                      },
                      slot: "trigger",
                    },
                    [_vm._v("选择图片")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { display: "none" },
                      attrs: { size: "small", type: "success" },
                      on: { click: _vm.submitUpload1 },
                    },
                    [_vm._v("上传到服务器")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.b1 },
                  on: {
                    click: function ($event) {
                      return _vm.save("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.b2))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "header-wrap" }, [
        _vm._v("\n      " + _vm._s(_vm.ruleForm.orderNo) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sellerDetail-wrap" }, [
        _c("div", { staticClass: "detail-box-left" }, [
          _c("div", { staticClass: "detial-warp" }, [
            _c("h5", [_vm._v("课程信息")]),
            _vm._v(" "),
            _c("div", { staticClass: "detial-msg-box detial-msg-height" }, [
              _c("p", [
                _c("label", [_vm._v("班课名称")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.temCourseName))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("课程代码")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.courseCode))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("优惠信息")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.preferential))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("课程状态")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.statused))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("班课所属学校")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.universityName))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("班主任")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.sysSeller))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("班课讲师")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.teacNickName))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("班课辅导需求")]),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.explained))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("Deal Deadline")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.endCourseTime))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("Offcial Deadline")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.examTime))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("label", [_vm._v("Specialization")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.ruleForm.professionalName))]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "detail-box-right" }, [
          _c("div", { staticClass: "detial-warp" }, [
            _c("h5", [
              _vm._v("课件信息 "),
              _c(
                "button",
                { staticClass: "download", on: { click: _vm.downFun } },
                [_vm._v("下载")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "detial-msg-box filemsg-height" },
              [
                _c(
                  "div",
                  { staticClass: "testForm-wrap allchecked-box" },
                  [
                    _c("el-checkbox", {
                      staticStyle: { "margin-right": "10px" },
                      on: { change: _vm.checkAllFun },
                      model: {
                        value: _vm.checkedAll,
                        callback: function ($$v) {
                          _vm.checkedAll = $$v
                        },
                        expression: "checkedAll",
                      },
                    }),
                    _vm._v("文件全选"),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.courses, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "fileslist-box" },
                    [
                      _c("h4", [_vm._v(_vm._s(item.groupName))]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(_vm.coursesItem, function (it, $index) {
                          return item.groupName == it.groupName
                            ? _c(
                                "li",
                                { key: $index, staticClass: "testForm-wrap" },
                                [
                                  _c("el-checkbox", {
                                    attrs: { name: "indexs" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.singleCheckFun(it)
                                      },
                                    },
                                    model: {
                                      value: it.check,
                                      callback: function ($$v) {
                                        _vm.$set(it, "check", $$v)
                                      },
                                      expression: "it.check",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "cursorpointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openUrl(it.url)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(it.name))]
                                  ),
                                  _vm._v(" "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm._f("spliting")(it.createAt))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  it.description
                                    ? _c("p", [
                                        _vm._v(
                                          "文件描述：" + _vm._s(it.description)
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e()
                        }),
                        0
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detial-warp" },
        [
          _c("h5", [_vm._v("用户信息")]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: {
                width: "100%",
                "margin-top": "5px",
                background: "#e5e9f2",
              },
              attrs: { data: _vm.extSysPaymentdata, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单号", width: "280" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#409EFF",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.seedetail(scope.row, scope.$index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.orderNo))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户姓名", "show-overflow-tooltip": true },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.nickName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "销售名称", "show-overflow-tooltip": true },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.username))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "已报课堂", "show-overflow-tooltip": true },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.classroomName))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "detial-warp" }, [
        _c("h5", [_vm._v("课堂信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "detial-msg-box" },
          [
            _c(
              "el-table",
              {
                staticStyle: {
                  width: "100%",
                  "margin-top": "5px",
                  background: "#e5e9f2",
                },
                attrs: { data: _vm.classroomdata, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "课堂名称", "show-overflow-tooltip": true },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "课堂说明", "show-overflow-tooltip": true },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.description))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "预计开始时间", width: "160" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.startTime))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "预计结束时间", width: "160" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.endTime))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "报名人数", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#409EFF",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.person(scope.row, scope.$index)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.buyNumber))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "状态", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.statused === 1
                            ? _c("span", [_vm._v("待确认课堂")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.statused === 2
                            ? _c("span", [_vm._v("已确认")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.statused === 4
                            ? _c("span", [_vm._v("正在上课")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.statused === 8
                            ? _c("span", [_vm._v("已结束")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.statused === 16
                            ? _c("span", [_vm._v("异常课堂")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.statused === 32
                            ? _c("span", [_vm._v("申请取消课堂中")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "课堂记录",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.history, function (item, index) {
            return _c("div", { key: index, staticClass: "text item" }, [
              _c("div", [
                _vm._v(
                  _vm._s(item.suerrole) +
                    " " +
                    _vm._s(item.nickname) +
                    " " +
                    _vm._s(item.joinTime) +
                    " 进入了课堂"
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  _vm._s(item.suerrole) +
                    " " +
                    _vm._s(item.nickname) +
                    " " +
                    _vm._s(item.leaveTime) +
                    " 离开了课堂"
                ),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("持续了 " + _vm._s(item.duration) + " 分钟")]),
            ])
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "qyweixin-dialog",
          class: { "update-dialog": _vm.step == 1 },
          attrs: {
            visible: _vm.isShow,
            width: _vm.step == 1 ? "847px" : "500px",
            title: "课堂授课工具迁移",
            "close-on-click-modal": _vm.type == 2,
            "close-on-press-escape": _vm.type == 2,
            modal: _vm.type == 1,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _vm.step == 1
            ? _c(
                "div",
                { staticClass: "\n    update-box" },
                [
                  _vm.type == 2
                    ? _c("i", {
                        staticClass: "el-icon-close",
                        on: { click: _vm.closeModal },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", [_vm._v("尊敬的讲师，万能讲师已完成禁用课堂更新")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-5" }, [
                    _vm._v(
                      "【更新时间】2023年6月7日8:00至13:00（本公告所有时间均为"
                    ),
                    _c("span", [_vm._v("北京时间")]),
                    _vm._v("）"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-5" }, [
                    _vm._v("【更新范围】万能讲网页端、万能讲师课堂"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-5" }, [
                    _vm._v("【更新方式】禁用课堂及授课平台更新"),
                  ]),
                  _vm._v(" "),
                  _c("el-divider", { staticClass: "line" }, [
                    _vm._v("以下为详细介绍请仔细阅读"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ml-5 f700",
                      staticStyle: { "margin-top": "10px" },
                    },
                    [_vm._v("【更新内容】")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "small-size" }, [
                    _c("div", [
                      _vm._v("万能讲师本次升级集成了功能更加强大的内置课堂（"),
                      _c("span", [_vm._v("ClassRoom")]),
                      _vm._v(
                        " ）代替了classin课堂，请讲师尽快下载万能讲师客户端进行课堂功能测试\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("万能讲师网页端新增了"),
                      _c("span", [_vm._v("【课堂应用】")]),
                      _vm._v(
                        "功能，各位讲师可以在网页上方点击进入，此页面可以下载并测试万能讲师课堂，您可以参考同一页面的"
                      ),
                      _c("span", [_vm._v("《教师使用手册》")]),
                      _vm._v("在测试课堂中进行功能测试与教学演练\n        "),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "各位讲师也可以通过在万能讲师网页端上方输入工作人员提供的"
                      ),
                      _c("span", [_vm._v("识别码")]),
                      _vm._v("进入课堂与工作人员沟通"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("小班带你飞复试环节流程结合新的课堂进行了更新"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "f700 ml-5 " }, [
                    _vm._v("【相关提示】"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "small-size" }, [
                    _c("div", [
                      _vm._v(
                        "请讲师注意所有课程安排将在新课堂（ClassRoom）中进行，"
                      ),
                      _c("span", [_vm._v("Classin已停止使用")]),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "若您在排课或使用新课堂中遇到问题请及时与工作人员取得联系"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.type == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "start-btn",
                          on: { click: _vm.nextStep },
                        },
                        [_vm._v("开始认证")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 2
            ? [
                _c(
                  "div",
                  { staticClass: "content-box" },
                  [
                    _c("h3", [_vm._v("讲师您好！")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "万能班长再次迎来升级！集成了更加强大的授课工具！即日起平台停止使用Classin授课，请各位根据以下流程完成万能讲师客户端下载操作！"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("mkMoticeStep", {
                      ref: "mkMoticeStep",
                      attrs: {
                        teacinfo: _vm.teacinfo,
                        classInfo: _vm.classInfo,
                        type: "2",
                      },
                      on: { changeComplated: _vm.changeComplated },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "complate",
                    attrs: { disabled: !_vm.isComplated, loading: _vm.loading },
                    on: { click: _vm.complateClass },
                  },
                  [_vm._v("认证完成")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isShow
        ? _c(
            "el-dialog",
            {
              staticClass: "medal-dialog",
              attrs: {
                visible: _vm.isShow,
                width: "420px",
                "close-on-click-modal": false,
                "before-close": _vm.handleClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isShow = $event
                },
              },
            },
            [
              _c("img", {
                staticClass: "hang",
                attrs: {
                  src: require("@/assets/images/medal/hungup.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "medal-box" }, [
                _c("div", { staticClass: "medal-bg", attrs: { id: "medal" } }, [
                  _c("div", { staticClass: "medal-content" }, [
                    _vm.current.medalImage
                      ? _c("img", {
                          staticClass: "medal-img",
                          attrs: { src: _vm.current.medalImage, alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "medal-text" }, [
                      _c("div", { staticClass: "medal-name" }, [
                        _vm._v(_vm._s(_vm.current.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "medal-desc" }, [
                        _vm._v(_vm._s(_vm.current.text)),
                        [5, 6, 7].includes(_vm.current.id)
                          ? _c("span", [
                              _c("span", { staticClass: "medal-desc" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.current.medalStatus == 2
                                        ? _vm.current.rank
                                        : "1-10"
                                    )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "medal-bg", attrs: { id: "medal1" } }),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./filemsg.vue?vue&type=template&id=5175f596&scoped=true"
import script from "./filemsg.vue?vue&type=script&lang=js"
export * from "./filemsg.vue?vue&type=script&lang=js"
import style0 from "./filemsg.vue?vue&type=style&index=0&id=5175f596&lang=css"
import style1 from "./filemsg.vue?vue&type=style&index=1&id=5175f596&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5175f596",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5175f596')) {
      api.createRecord('5175f596', component.options)
    } else {
      api.reload('5175f596', component.options)
    }
    module.hot.accept("./filemsg.vue?vue&type=template&id=5175f596&scoped=true", function () {
      api.rerender('5175f596', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/detail/conponment/filemsg.vue"
export default component.exports
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("img", {
      staticClass: "medal-img",
      attrs: { src: _vm.item.medalImage, alt: "" },
      on: {
        click: function ($event) {
          return _vm.seeMedal(_vm.item)
        },
      },
    }),
    _vm._v(" "),
    _c("div", [
      _vm._v("\n    " + _vm._s(_vm.item.name) + "\n    "),
      _vm.item.medalNum && _vm.showNum
        ? _c("span", { staticClass: "medal-num" }, [
            _vm._v("*" + _vm._s(_vm.item.medalNum)),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    [2, 3].includes(Number(_vm.item.medalStatus))
      ? _c(
          "div",
          {
            staticClass: "oper-btn",
            class: { "get-btn": _vm.item.medalStatus == 2 },
            on: {
              click: function ($event) {
                return _vm.operateMedal(_vm.item)
              },
            },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.item.medalStatus == 2 ? "挂上" : "取下") +
                "\n  "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
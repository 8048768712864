"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _contentnull = _interopRequireDefault(require("@/views/contentnull"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    contentnull: _contentnull.default
  },
  props: {
    model: {
      type: Object,
      default: function _default() {}
    },
    thumpsText: {
      type: Object,
      default: function _default() {}
    },
    totals: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    },
    tableDataEnd: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    likeLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      typeList: {
        1: "收入榜",
        2: "获赞榜",
        3: "订单榜",
        4: "活跃榜",
        5: "高反馈榜"
      }
    };
  },
  methods: {
    handleSizeChange: function handleSizeChange() {
      this.$parent.handleSizeChange();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$parent.handleCurrentChange1(val);
    },
    closeMask: function closeMask() {
      this.$parent.closeMask();
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  ordernum: function ordernum(state) {
    return state.user.ordernum;
  },
  countdown: function countdown(state) {
    return state.hatch.countdown;
  },
  countTime: function countTime(state) {
    return state.hatch.countTime;
  },
  fuvfalg: function fuvfalg(state) {
    return state.hatch.fuvfalg;
  },
  process: function process(state) {
    return state.hatch.process;
  },
  navstatus: function navstatus(state) {
    return state.hatch.navstatus;
  },
  draggableDisabled: function draggableDisabled(state) {
    return state.dissertation.disabled;
  },
  stages: function stages(state) {
    return state.dissertation.stages;
  },
  stageTables: function stageTables(state) {
    return state.dissertation.tables;
  },
  stageLoading: function stageLoading(state) {
    return state.dissertation.stageLoading;
  },
  courseId: function courseId(state) {
    return state.dissertation.courseId;
  },
  dissertationTitleData: function dissertationTitleData(state) {
    return state.dissertation.titleData;
  },
  progressOver: function progressOver(state) {
    return state.dissertation.progressOver;
  },
  dissertationRate: function dissertationRate(state) {
    return state.dissertation.rate;
  },
  readonly: function readonly(state) {
    return state.dissertation.readonly;
  },
  userphoto: function userphoto(state) {
    return state.userchange.userphoto;
  }
};
var _default = exports.default = getters;
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-content",
      style: {
        left: _vm.positionLeft,
        height: _vm.dateType ? "105px" : "125px",
      },
    },
    [
      _c("i", {
        staticClass: "el-icon-close close",
        on: { click: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        !_vm.dateType
          ? _c("div", { staticClass: "date" }, [_vm._v(_vm._s(_vm.today))])
          : _vm._e(),
        _vm._v(
          "\n        请设置您当日是否有空余时间处理订单，长期未设置会影响您接单\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn-box" },
        [
          _c("el-button", { attrs: { loading: _vm.loading1 } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/card/hasbtn.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  return _vm.confirm(1)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("el-button", { attrs: { loading: _vm.loading2 } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/card/nobtn.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  return _vm.confirm(2)
                },
              },
            }),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
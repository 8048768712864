"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _VEnum = require("@/utils/VEnum");
// import Redirect from "@/views/hatch";
// import Trainingplan from "@/views/hatch/trainingplan";
// import Letter from "@/views/hatch/letter";
// import Fake from "@/views/hatch/fake";
// import Trainingground from "@/views/hatch/trainingground";
// import Fakeclass from "@/views/hatch/fakeclass";
// import Fakeclassold from "@/views/hatch/fakeclassold";
// import Idform from "@/views/hatch/idform";
// import Certificate from "@/views/hatch/certificate";
// import Intention from "@/views/hatch/intention";
// import Intentionold from "@/views/hatch/intentionold";
// import Schedulecard from "@/views/hatch/schedulecard";
// import QrCode from "@/views/hatch/qrCode";

/* 讲师孵化 */

var REDIRECT = _VEnum.HatchRouterPath.REDIRECT,
  LETTER = _VEnum.HatchRouterPath.LETTER,
  PLAN = _VEnum.HatchRouterPath.PLAN,
  FAKE = _VEnum.HatchRouterPath.FAKE,
  GROUND = _VEnum.HatchRouterPath.GROUND,
  FAKE_CLASS = _VEnum.HatchRouterPath.FAKE_CLASS,
  FAKE_CLASS_OLD = _VEnum.HatchRouterPath.FAKE_CLASS_OLD,
  CLASS_CARD = _VEnum.HatchRouterPath.CLASS_CARD,
  INTENTION = _VEnum.HatchRouterPath.INTENTION,
  INTENTIONOLD = _VEnum.HatchRouterPath.INTENTIONOLD,
  ID_FORM = _VEnum.HatchRouterPath.ID_FORM,
  CERT = _VEnum.HatchRouterPath.CERT,
  QR_CODE = _VEnum.HatchRouterPath.QR_CODE;
var _default = exports.default = [{
  path: REDIRECT,
  // component: Redirect
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch'));
    });
  }
}, {
  /* 万能讲师岗前培训图 */
  path: PLAN,
  // component: Trainingplan
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/trainingplan'));
    });
  }
}, {
  /* 讲师信 */
  path: LETTER,
  // component: Letter
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/letter'));
    });
  }
}, {
  /* 模拟订单操作 */
  path: FAKE,
  // component: Fake
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/fake'));
    });
  }
}, {
  /* 课堂训练场 */
  path: GROUND,
  // component: Trainingground
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/trainingground'));
    });
  }
}, {
  /* 全真模拟课 */
  path: FAKE_CLASS,
  // component: Fakeclass
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/fakeclass'));
    });
  }
}, {
  /* 全真模拟课 */
  path: FAKE_CLASS_OLD,
  // component: Fakeclassold
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/fakeclassold'));
    });
  }
}, {
  /* 讲师形象 */
  path: ID_FORM,
  // component: Idform
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/idform'));
    });
  }
}, {
  /* 入职证书 */
  path: CERT,
  // component: Certificate
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/certificate'));
    });
  }
}, {
  /* 意向表 */
  path: INTENTION,
  // component: Intention
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/intention'));
    });
  }
}, {
  /* 意向表 */
  path: INTENTIONOLD,
  // component: Intentionold
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/intentionold'));
    });
  }
}, {
  /* 课程表 */
  path: CLASS_CARD,
  // component: Schedulecard
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/schedulecard'));
    });
  }
}, {
  /* 讲师app下载 */
  path: QR_CODE,
  // component: QrCode
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/hatch/qrCode'));
    });
  }
}];
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _contentnull = _interopRequireDefault(require("../contentnull"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {
        classroom: "",
        classroomName: '',
        description: "",
        times: "",
        explain: "",
        teacherType: localStorage.getItem('teacType')
      },
      rules: {},
      btnstatus: false,
      classrooms: [],
      maskShow: false,
      tableDataBegin: [],
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      modal: false,
      modal_time: false,
      total: 0,
      limit: 15,
      idss: [],
      courseId: "",
      val: 1,
      page: "",
      finishedCourseLoading: false,
      time: '',
      user: {},
      tableDataNamet: ''
    };
  },
  multipleSelection: [],
  components: {
    contentnull: _contentnull.default
  },
  mounted: function mounted() {
    this.getTableData();
    this.getUser();
    // this.searchEnter();
  },
  methods: {
    search: function search() {
      this.val = 1; //页码重置
      this.getTableData();
    },
    getUser: function getUser() {
      var _this = this;
      this.$axios({
        url: this.$api.baseURL + "/user/getMyInfo"
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this.$router.push({
              path: "/login"
            });
          }
        }
        _this.user = r.data.body || {};
        _this.time = Number(_this.user.presenterTimeLimit) - Number(_this.user.presenterTimeConsume);
      }).catch(function (er) {});
    },
    closeMask: function closeMask() {
      this.maskShow = false;
    },
    change_classroom: function change_classroom(val) {},
    gitetimeFun: function gitetimeFun(row) {
      console.log(row);
      this.courseId = row.cdId;
      this.ruleForm.classroom = row.id;
      this.ruleForm.classroomName = row.name;
      this.ruleForm.times = '';
      this.ruleForm.explain = '';
      this.maskShow = true;
    },
    seeCouse: function seeCouse(row, index) {
      if (row.spoType === 64) {
        var Logistics2 = this.$router.resolve({
          path: "/bigClassRoom",
          query: {
            id: row.cdId,
            parentId: null
          }
        });
        window.open(Logistics2.href);
      } else {
        var type = 'daobi';
        if (row.courseType === 4) {
          type = 'wenya';
        }
        var Logistics1 = this.$router.resolve({
          path: "/sellerDetail",
          query: {
            id: row.cdId,
            type: type
          }
        });
        window.open(Logistics1.href);
      }
    },
    submitForm1: function submitForm1(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.btnstatus = true;
          _this2.btnval = "加载中";
          _this2.$axios({
            url: _this2.$api.baseURL + "/account/presenterTime",
            method: "post",
            data: {
              classroomId: _this2.$refs.ruleForm.model.classroom,
              time: _this2.$refs.ruleForm.model.times,
              explain: _this2.$refs.ruleForm.model.explain
            }
          }).then(function (r) {
            _this2.btnstatus = false;
            if (r.data.status === 400) {
              if (r.data.body.code === 4011) {
                _this2.$router.push({
                  path: '/login'
                });
              }
            }
            if (r.data.status === 200) {
              _this2.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "success"
              });
              _this2.btnval = "确定";
              _this2.modal = false;
              _this2.modal_time = false;
              _this2.closeMask();
              _this2.getTableData();
              _this2.getUser();
            }
            if (r.data.status === 400) {
              _this2.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: "warning"
              });
              _this2.btnval = "确定";
              _this2.btnstatus = false;
              _this2.modal = false;
            }
          }).catch(function (er) {
            _this2.btnstatus = false;
          });
        }
      });
    },
    getTableData: function getTableData() {
      var _this3 = this;
      this.finishedCourseLoading = true;
      this.$axios({
        url: this.$api.baseURL + "/classRoom/list",
        params: {
          page: this.val,
          limit: this.limit,
          statusedArr: "8;16",
          desc: 1,
          key: this.tableDataNamet
        }
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this3.$router.push({
              path: '/login'
            });
          }
        }
        console.log(r.data.body);
        _this3.finishedCourseLoading = false;
        _this3.tableDataEnd = r.data.body.list;
        _this3.total = r.data.body.totalCount;
        _this3.pageSize = r.data.body.totalCount;
        _this3.currentPage = r.data.body.currPage;
      }).catch(function (er) {
        _this3.finishedCourseLoading = false;
      });
      // var self = this
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     data: {
      //         'token': token,
      //         'page': this.val,
      //         'limit': this.limit,
      //         'statusedArr': '8;16',
      //         'key': this.tableDataNamet
      //     },
      //     url: '' + baseURL + '/classRoom/list',
      //     success: function(json) {
      //         self.tableDataEnd = json.body.list
      //         self.total = json.body.totalCount
      //         self.pageSize = json.body.totalCount
      //         self.currentPage = json.body.currPage
      //     }
      // })
    },
    apply: function apply(row, index) {},
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.limit = val;
      this.getTableData();
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.map(function (item) {
        var id = item.id;
        ids.push("" + id + "");
      });
      this.selectedIDs = ids;
      console.log("多选", ids);
      this.idss = this.selectedIDs;
      console.log(this.idss);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      descArr: [{
        title: '1、关于获赞值',
        content: '获赞值是讲师在上课等相关活动获得学生认可的反馈，是对讲师上课能力的体现'
      }, {
        title: '2、如何获得',
        content: '获赞值可通过下列方式获得，具体规则如下：',
        expends: [{
          type: '课堂',
          content: '每一堂课结束后，学生有一次给讲师评价权利，获得好评加获赞值'
        }, {
          type: '订单',
          content: '每一个订单结束后，学生可针对该订单进行评价，获得好评加获赞值'
        }, {
          type: '反馈',
          content: '学生反馈成绩达到预期值，加获赞值'
        }],
        lighttext: '10G=1获赞值 12.3G=1.2获赞值 12.6G=1.3获赞值'
      }, {
        title: '3、会不会减少',
        content: '会，讲师收到学生投诉意见会根据责任大小扣除相应获赞值'
      }, {
        title: '4、获赞值有效期',
        content: '万能讲师获赞值在你的讲师生涯永久有效，若有变更会通知到您'
      }]
    };
  }
};
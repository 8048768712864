var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: { visible: _vm.isShow, width: "430px", "show-close": false },
    },
    [
      _c("div", [
        _c("h3", { staticClass: "title" }, [_vm._v("进入流程提醒")]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            "\n      本流程预计花费30分钟，请在24小时内完成，超时有淘汰风险\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "footer-btn",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "span",
            { staticClass: "confirm-btn", on: { click: _vm.startTrain } },
            [_vm._v("确认开始")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _Table = require("@/views/dissertation/cfg/Table");
var _Modal = require("@/views/dissertation/components/Modal");
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  data: function data() {
    return {
      ClassFileType2Val: _Table.ClassFileType2Val
    };
  },
  components: {
    MaterialListModal: _Modal.MaterialListModal,
    MaterialUploadModal: _Modal.MaterialUploadModal
  },
  props: {
    data: {
      type: Object,
      default: function _default() {}
    },
    /* 是否是 必论文修改 */
    isDissertation: {
      type: Boolean,
      default: false
    },
    /* 阶段id */
    stageId: {
      type: Number,
      default: 0
    }
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["readonly"])), {}, {
    isRead: function isRead() {
      return this.data.teacherFiles && this.data.teacherFiles.length > 0;
    },
    copywriting: function copywriting() {
      var teacherFilsValue = this.data.teacherFilsValue;
      if (teacherFilsValue) {
        var result = [];
        var _list = teacherFilsValue.split(",");
        _list.forEach(function (item) {
          if (+item > 0) {
            result.push(+item);
          }
        });
        return result;
      }
    },
    isMany: function isMany() {
      return !!this.copywriting && this.copywriting.length > 1;
    },
    isOne: function isOne() {
      return !!this.copywriting && this.copywriting.length === 1;
    },
    showTip: function showTip() {
      return this.isDissertation;
    }
  }),
  methods: {
    lookUp: function lookUp() {
      this.$refs.materialListModal.show();
    },
    openUpload: function openUpload(stageClassFileType) {
      this.$refs.uploadModal.show(this.stageId, this.data.id, stageClassFileType, this.isDissertation);
    }
  }
};
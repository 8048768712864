var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("DropBox")]),
    _vm._v(" "),
    _vm.list.length
      ? _c(
          "div",
          { staticClass: "detial-msg-box" },
          [
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: { loading: _vm.downLoading },
                on: { click: _vm.getZipFilePlus },
              },
              [_vm._v("下载全部资料")]
            ),
            _vm._v(" "),
            _c(
              "el-row",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "file-container",
                attrs: { gutter: 15 },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "el-col",
                  {
                    attrs: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 24 / (_vm.list && _vm.list.length),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "file-box",
                        style: {
                          "border-top": "3px solid " + _vm.colorArr[index],
                        },
                      },
                      [
                        _c("div", { staticClass: "type-title" }, [
                          _vm._v(_vm._s(item.groupName)),
                        ]),
                        _vm._v(" "),
                        _vm._l(item.list, function (file, _idx) {
                          return _c("fileCard", {
                            key: _idx,
                            attrs: { file: file },
                          })
                        }),
                      ],
                      2
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "detial-msg-box",
            staticStyle: { overflow: "hidden", margin: "0" },
          },
          [_vm._m(0)]
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticStyle: { overflow: "hidden", margin: "0" } }, [
      _c("li", [_vm._v("暂无Dropbox")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "item-title" }, [_vm._v("薪资模式组成视频")]),
      _vm._v(" "),
      _c("img", {
        staticClass: "video-top",
        attrs: { src: require("@/assets/images/home/videoTop.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("video", {
        ref: "video",
        staticClass: "video-style",
        attrs: {
          poster: require("@/assets/images/home/newposter.png"),
          id: "welcomevideo",
          controlsList: "nodownload",
          disablePictureInPicture: "true",
          oncontextmenu: "return false;",
          controls: true,
          src: _vm.url,
        },
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "header-cell-style": {
              background: "#E5E5E5",
              color: "#333333",
              height: "40px",
              padding: 0,
            },
            "row-style": { height: "40px", padding: 0 },
            "cell-style": { padding: 0 },
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "countryName", label: "国家" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "highScore", label: "高分一档" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.highScore && scope.row.highScore.length
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getScore(scope.row.highScore, 0)) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "highScore", label: "奖励订单预计收入（GB）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v("10%")])]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-title " }, [
      _vm._v("高分标准线"),
      _c("span", { staticClass: "orange-text" }, [
        _vm._v("订单出分达到高分标准线则会根据相应比例收到奖励"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showStep == 4
    ? _c("div", [
        _c("div", { staticClass: "elc-box" }, [
          _c("img", {
            staticClass: "elc",
            attrs: {
              src: require("@/assets/images/home/yingyongzinew.png"),
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "arrow",
            attrs: { src: require("@/assets/images/home/arrow.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "tipcontent",
            attrs: {
              src: require("@/assets/images/home/tipcontent.png"),
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "know",
            attrs: { src: require("@/assets/images/home/know.png"), alt: "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.know($event)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "new-mask z-index102" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sysfile_uploadList = exports.sysfile_upload = exports.stage_sort = exports.stage_over = exports.queryfile_stageclass = exports.paper_teacherRate = exports.paper_stage = exports.paper_rate = exports.paper_feedback = exports.order_updateClassroom = exports.order_putClassroom = exports.getAliOSSUploadSign = exports.findClassroomInfo = exports.delete_id = exports.delete_class = exports.crmTeacherRate = exports.crmStageTable = exports.add_class = void 0;
var _request = require("@/views/hatch/api/request");
var _api = require("@/api/api");
var _mock = _interopRequireDefault(require("./mock"));
var MOCK = false;

/**
 * 查询毕业大论文规划表
 * http://121.43.174.41:3000/project/28/interface/api/3607
 * @param {String} courseId 订单ID
 */
var crmStageTable = exports.crmStageTable = function crmStageTable(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/crm/sys/big/paper/stageTable"),
    method: "get",
    params: params,
    token: true
  });
};

/**
 * 查询规划表头部信息
 * http://121.43.174.41:3000/project/28/interface/api/3625
 * @param {String} courseId 订单ID
 */
var crmTeacherRate = exports.crmTeacherRate = function crmTeacherRate(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/crm/sys/big/paper/teacherRate"),
    method: "get",
    params: params,
    token: true
  });
};

/* ^^^^^^^^^^^^^^^^^^^^^^ crm跳转 查询接口 ^^^^^^^^^^^^^^^^^^^^^^ */

/**
 * 查询订单阶段信息
 * http://121.43.174.41:3000/project/73/interface/api/2536
 * @param {String} id 订单ID
 */
var paper_stage = exports.paper_stage = function paper_stage(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/stage"),
    method: "get",
    params: params
  });
};

/**
 * 阶段反馈
 * http://121.43.174.41:3000/project/73/interface/api/2545
 * @param {String} stageId 阶段ID
 * @param {String} feedbackValue 反馈内容
 */
var paper_feedback = exports.paper_feedback = function paper_feedback(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/feedback"),
    method: "post",
    data: data
  });
};

/**
 * 阶段重新排序
 * http://121.43.174.41:3000/project/73/interface/api/2554
 * @param {String} stageId 阶段ID
 * @param {String} oldSort 旧排序sort
 * @param {String} newSort 新排序sort
 */
var stage_sort = exports.stage_sort = function stage_sort(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/stage/sort"),
    method: "post",
    data: data
  });
};

/**
 * 加课
 * http://121.43.174.41:3000/project/73/interface/api/2572
 * @param {String} stageType 阶段类型
 * @param {String} classType 阶段课堂类型
 * @param {String} className 课堂名称
 * @param {String} remark 课堂说明
 * @param {String} courseId 订单ID
 */
var add_class = exports.add_class = function add_class(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/add/class"),
    method: "post",
    data: data
  });
};

/**
 * 排课
 * http://121.43.174.41:3000/project/73/interface/api/2581
 * @param {String} name 课堂名称
 * @param {String} startTime 开始时间
 * @param {String} endTime 结束时间
 * @param {String} description 课堂说明
 * @param {String} cdId 订单ID
 * @param {String} bigPaperStageId 阶段ID
 * @param {String} bigPaperClassId 大论文课堂ID
 */
var order_putClassroom = exports.order_putClassroom = function order_putClassroom(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/putClassroom"),
    method: "post",
    data: data
  });
};

/**
 * 查询毕业论文辅导进度等头部信息
 * http://121.43.174.41:3000/project/73/interface/api/2599
 * @param {String} courseId 订单ID
 */
var paper_teacherRate = exports.paper_teacherRate = function paper_teacherRate(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/teacherRate"),
    method: "get",
    params: params
  });
};

/**
 * 上传文件
 * http://121.43.174.41:3000/project/73/interface/api/2626
 * @param {String} url url
 * @param {String} name 文件名称
 * @param {String} type 此处固定为 类型1 类型 1 毕业大论文订单课堂讲师材料 2 毕业大论文创建引流订单 学生文件 3 毕业大论文创建收费订单 学生近期自主完成的essay 4 毕业大论文阶段信息 学生资料
 * @param {String} remark 备注描述
 * @param {String} teacherId 讲师ID
 * @param {String} courseId 订单ID
 * @param {String} stageId 阶段ID
 * @param {String} stageClassId 课堂ID classroomId
 * @param {String} stageClassFileType 大论文阶段课堂文件类型  阶段课堂文件类型 1topicList  2 outLine  3 批改文件 4 data Results 5 Final Document 6 Research List 7 批改文件完整  8 Final Document
 */
var sysfile_upload = exports.sysfile_upload = function sysfile_upload(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/sysfile/upload"),
    method: "post",
    data: data
  });
};

/**
 * 论文评级
 * http://121.43.174.41:3000/project/73/interface/api/2635
 * @param {String} courseId 订单ID
 * @param {String} rate 论文评级 1 A  2 B 3 C  4 D
 */
var paper_rate = exports.paper_rate = function paper_rate(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/rate"),
    method: "post",
    data: data
  });
};

/**
 * 上传必论文修改文件 更新阶段状态
 * http://121.43.174.41:3000/project/73/interface/api/2653
 * @param {String} courseId 订单ID
 * @param {String} stageId 阶段ID
 * @param {String} stageClassId 阶段课堂实例ID
 * @param {String} teacherId 讲师ID
 * @param {String} url 文件地址
 * @param {String} remark 备注
 * @param {String} type 1 毕业大论文订单课堂讲师材料 2 毕业大论文创建引流订单 学生文件 3 毕业大论文创建收费订单 学生近期自主完成的essay 4 毕业大论文阶段信息 学生资料 5 毕业大论文阶段必论文修改 上传文件
 */
var stage_over = exports.stage_over = function stage_over(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/upload/stage/over"),
    method: "post",
    data: data
  });
};

/**
 * 删除上传的文件
 * http://121.43.174.41:3000/project/73/interface/api/2689
 * @param {String} id
 */
var delete_id = exports.delete_id = function delete_id(id) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/sysfile/delete/").concat(id),
    method: "post",
    data: {}
  });
};

/**
 * 刪除阶段课堂接口
 * http://121.43.174.41:3000/project/73/interface/api/2761
 * @param {String} courseId 订单ID
 * @param {String} stageClassId 阶段课堂ID
 */
var delete_class = exports.delete_class = function delete_class(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/delete/class"),
    method: "post",
    data: data
  });
};

/**
 * 通过课堂ID查询大论文课堂大论文材料文件
 * http://121.43.174.41:3000/project/73/interface/api/2770
 * @param {String} courseId 订单ID
 * @param {String} stageClassId 阶段课堂ID
 */
var queryfile_stageclass = exports.queryfile_stageclass = function queryfile_stageclass(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/big/paper/queryfile/stageclass"),
    method: "post",
    data: data
  });
};

/**
 * 更新课堂
 * http://121.43.174.41:3000/project/73/interface/api/2788
 * @param {String} id classroomId
 * @param {String} cdId 订单ID
 * @param {String} name 课堂名称
 * @param {String} startTime 开始时间
 * @param {String} endTime 结束时间
 * @param {String} description 课堂说明
 */
var order_updateClassroom = exports.order_updateClassroom = function order_updateClassroom(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/updateClassroom"),
    method: "post",
    data: data
  });
};

/**
 * 根据classroomId查询实际课堂信息
 * http://121.43.174.41:3000/project/73/interface/api/2788
 * @param {String} classroomId
 */
var findClassroomInfo = exports.findClassroomInfo = function findClassroomInfo(classroomId, params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classRoom/findClassroomInfo/").concat(classroomId),
    method: "get",
    params: params
  });
};

/**
 * 获取oss 私钥
 */
var getAliOSSUploadSign = exports.getAliOSSUploadSign = function getAliOSSUploadSign() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/oss/getAliOSSUploadSign?dir=courseware/1583907492/&token=e8b02a112d53096e938174470950a304&_=") + Date.now(),
    method: "get"
  });
};

/**
 * 批量上传文件
 * http://121.43.174.41:3000/project/73/interface/api/2842
 * @param {String} url url
 * @param {String} name 文件名称
 * @param {String} type 此处固定为 类型1 类型 1 毕业大论文订单课堂讲师材料 2 毕业大论文创建引流订单 学生文件 3 毕业大论文创建收费订单 学生近期自主完成的essay 4 毕业大论文阶段信息 学生资料
 * @param {String} remark 备注描述
 * @param {String} teacherId 讲师ID
 * @param {String} courseId 订单ID
 * @param {String} stageId 阶段ID
 */
var sysfile_uploadList = exports.sysfile_uploadList = function sysfile_uploadList(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/sysfile/uploadList"),
    method: "post",
    data: data
  });
};
if (MOCK) {
  exports.paper_stage = paper_stage = _mock.default.paper_stage;
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.query.type == "reserved"
    ? _c("div", [_c("reservedClass")], 1)
    : _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "page-container",
          style: _vm.staticPath.BG,
        },
        [
          _c("countdown"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "schedulecard-container" },
            [
              _c(
                "section",
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _c("mini-map", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.modalShow,
                            expression: "!modalShow",
                          },
                        ],
                        on: { open: _vm.openModal },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("map-modal", {
                    ref: "modal",
                    on: {
                      close: function ($event) {
                        _vm.modalShow = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("section", { staticClass: "title-container" }, [
                _c("div", { staticClass: "title-box" }, [
                  _c(
                    "div",
                    { staticClass: "back", on: { click: _vm.openBack } },
                    [
                      _c("i", { staticClass: "el-icon-arrow-left" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("返回上一页")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.title))]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "explain-box" }, [
                  _c("section", { staticClass: "tips" }, [_vm._v("必读")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "content" }, [
                    _vm._v("预约模拟课说明"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "look",
                      on: {
                        click: function ($event) {
                          return _vm.showModal()
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v("去看看")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-arrow-right" }),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "calendar-app-container" },
                [
                  _c(
                    "ds-calendar-app",
                    {
                      ref: "calendarApp",
                      staticClass: "calendar-app",
                      attrs: {
                        modelType: +_vm.$route.query.to,
                        hasModel: false,
                        validTimes: _vm.dayTimeRange,
                        value: _vm.defaultEvents,
                        dayValue: _vm.dayValue,
                        vacationTime: _vm.vacationTime,
                        hasValidRange: true,
                      },
                      on: {
                        picked: _vm.onPicked,
                        added: _vm.calendarAdd,
                        prompt: _vm.handleMove,
                        "event-update": _vm.handleMoveData,
                        "mouse-up": _vm.mouseUp,
                        "mouse-down": _vm.mouseDown,
                        "mouse-up-event": _vm.mouseUpEvent,
                        "change-date": _vm.changeDate,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "eventTimeTitle",
                          fn: function (ref) {
                            var calendarEvent = ref.calendarEvent
                            var details = ref.details
                            return [
                              _c("div", { staticClass: "pact-panel" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "status",
                                    style: {
                                      "background-color": details.statusColor,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.Status2Val[details.statused]
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "content" }, [
                                  _c(
                                    "span",
                                    { style: { color: details.statusColor } },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(details.className) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { style: { color: details.statusColor } },
                                    [_vm._v(_vm._s(details.dateRange))]
                                  ),
                                  _vm._v(" "),
                                  _vm.isInClass(details.statused)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "btn-in-class",
                                          on: {
                                            click: function ($event) {
                                              return _vm.enterClass(
                                                details.teacherJoinUrl
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                进入课堂\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isInClass(details.statused)
                                    ? _c("div", { staticClass: "down-tips" }, [
                                        _vm._v(
                                          "\n                请下载课件\n              "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "classContent",
                          fn: function (ref) {
                            var dayValue = ref.dayValue
                            return [
                              dayValue.length > 0
                                ? _c(
                                    "section",
                                    { staticClass: "student-container" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "student-header" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.currentYMD)),
                                          ]),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "共" +
                                                _vm._s(_vm.classCount) +
                                                "节课"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-timeline",
                                        _vm._l(dayValue, function (item, i) {
                                          return _c(
                                            "el-timeline-item",
                                            {
                                              key: i,
                                              attrs: {
                                                color: "#FF8723",
                                                timestamp: item.dateRange,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "student-panel-container",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "box" },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "left" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "topic",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.courseName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "reaction",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.roomTextContent
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "right",
                                                        },
                                                        [
                                                          item.my == 1
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btn",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openLink(
                                                                          item.myJoinUrl
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        待上课\n                      "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "btn",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.reservationClass(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        约课\n                      "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _vm._v(" "),
                      _vm.ownClass.className
                        ? _c("template", { slot: "classTop" }, [
                            _c("div", { staticClass: "myclass-container" }, [
                              _c("div", { staticClass: "classname" }, [
                                _vm._v("我的课堂"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "box" }, [
                                _c("div", { staticClass: "left" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.ownClass.ownDateRange)),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.ownClass.className)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "right" }, [
                                  _vm.isStuInClass(_vm.ownClass.statused)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.enterClass(
                                                _vm.ownClass.roomJoinUrl
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.StuStatus2Val[
                                                  _vm.ownClass.statused
                                                ]
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isReaction(_vm.ownClass.statused) ||
                                  _vm.ownClass.statused == 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "btn reaction",
                                          on: { click: _vm.linkReaction },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  课堂评分表\n                  "
                                          ),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "请提前查看评分细则，课堂结束后提交评分表"
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isStuInClass(_vm.ownClass.statused) &&
                                  !_vm.isReaction(_vm.ownClass.statused)
                                    ? _c("div", { staticClass: "btn done" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.StuStatus2Val[
                                                _vm.ownClass.statused
                                              ]
                                            ) +
                                            "\n                "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("alert-modal", {
                    ref: "alertModal",
                    on: { close: _vm.initMoniOwnList },
                  }),
                  _vm._v(" "),
                  _c("fake-explain-modal", {
                    ref: "explainModal",
                    attrs: { type: +_vm.$route.query.to },
                  }),
                  _vm._v(" "),
                  _c("class-info-modal", { ref: "classInfo" }),
                  _vm._v(" "),
                  _c(
                    "section",
                    { staticClass: "pact-modal" },
                    [
                      _c("teacher-pact-modal", {
                        ref: "teacherPact",
                        attrs: { todayDate: _vm.todayDate },
                        on: {
                          close: _vm.initMoniOwnList,
                          submit: _vm.formSubmit,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            visible: _vm.alertModal,
                            top: "300px",
                            width: "380px",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.alertModal = $event
                            },
                          },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c("div", { staticClass: "title-box" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/hatch/schedulecard/success.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "title" }, [
                                _vm._v("等待审核"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content" }, [
                            _c("span", [
                              _vm._v(
                                "课程审核约一个工作日，审核完成后短信通知到您"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "课程审核通过后，请务查看讲师授课课件及授课要求"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  on: {
                                    click: function ($event) {
                                      _vm.alertModal = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              我知道了\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  staticClass: "paper-dialog",
                  attrs: {
                    visible: _vm.showTip,
                    width: "300px",
                    "append-to-body": "",
                    "show-close": false,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "16px",
                        "font-weight": "600",
                        "text-align": "center",
                        margin: "-30px 0px 20px",
                      },
                    },
                    [_vm._v("安排上课课堂")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "line-height": "25px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        您可以安排自己的讲师课堂时间啦！赶快去前往【我是讲师】吧！\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "footer-btn",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "confirm-btn",
                          on: {
                            click: function ($event) {
                              _vm.showTip = false
                            },
                          },
                        },
                        [_vm._v("我知道了")]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "medal-dialog",
          attrs: {
            visible: _vm.showMedal,
            width: "560px",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMedal = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "medal-content",
            },
            [
              _c("i", {
                staticClass: "el-icon-circle-close",
                on: { click: _vm.closeModal },
              }),
              _vm._v(" "),
              [1].includes(_vm.current.type)
                ? _c("img", {
                    staticClass: "only-img",
                    attrs: {
                      src: require("@/assets/images/medal/jueban.png"),
                      alt: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              ![1].includes(_vm.current.type) &&
              ![19, 20, 21, 31].includes(_vm.current.medalType)
                ? _c("div", { staticClass: "title" }, [
                    [27].includes(_vm.current.medalType)
                      ? _c("span", [_vm._v("2023第一旺季-")])
                      : _vm._e(),
                    _vm._v(_vm._s(_vm.current.name) + " "),
                    [12, 13].includes(_vm.current.medalType) &&
                    _vm.current.medalNum
                      ? _c("span", { staticClass: "title" }, [
                          _vm._v("*" + _vm._s(_vm.current.medalNum)),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              [12, 13, 14, 22, 23, 17, 24, 25].includes(_vm.current.medalType)
                ? [
                    _vm.current.medalNum <= 1
                      ? _c("div", { staticClass: "top-medal-info" }, [
                          _vm.medalInfo.gainTime
                            ? _c(
                                "div",
                                { staticClass: "mt15 font20 get-time" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.medalInfo.gainTime) + "获得"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            attrs: { src: _vm.current.medalImage, alt: "" },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "font22 mt15" }, [
                            _vm._v(_vm._s(_vm.current.title)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.current.medalNum > 1
                      ? _c("div", { staticClass: "top-medal-info" }, [
                          _c("div", { staticClass: "font22 mt15 " }, [
                            _vm._v(_vm._s(_vm.current.title)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "many-box" },
                            _vm._l(
                              _vm.medalInfo.performance || [],
                              function (time, index) {
                                return _c("div", { key: index }, [
                                  _c("img", {
                                    staticClass: "middle-img",
                                    attrs: {
                                      src: _vm.current.medalImage,
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "font16 mt25" }, [
                                    _vm._v(_vm._s(time) + " "),
                                    _vm.current.medalType != 25
                                      ? _c("span", { staticClass: "font16" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.current.medalType == 24
                                                ? "学生最爱冠军"
                                                : _vm.current.name
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v("* 1"),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              [
                [27].includes(_vm.current.medalType)
                  ? _c(
                      "div",
                      { staticClass: "\n          top-medal-info" },
                      [
                        _c("div", { staticClass: "left-img-box" }, [
                          _c("img", {
                            staticClass: "head-img",
                            attrs: { src: _vm.current.headImage, alt: "" },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            attrs: { src: _vm.current.medalImage, alt: "" },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.getted
                          ? [
                              _c("div", { staticClass: "no1 font22" }, [
                                _c(
                                  "span",
                                  { staticClass: "orange-text font22" },
                                  [_vm._v("您是")]
                                ),
                                _vm._v(" 2023第一季困难订单挑战当前 "),
                                _c(
                                  "span",
                                  { staticClass: "orange-text font22" },
                                  [_vm._v("第一名")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "font20 mt10" }, [
                                _vm._v("第二名距离您仅差 "),
                                _c("span", { staticClass: "red-text font20" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.medalInfo.gapOrderCount) +
                                      " "
                                  ),
                                ]),
                                _vm._v(" 单"),
                              ]),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.notGetted
                          ? [
                              _c("div", { staticClass: "font22 no2 w310" }, [
                                _vm._v("您距离第一名仅差"),
                                _c("span", { staticClass: "red-text font32" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.medalInfo.gapOrderCount) +
                                      " "
                                  ),
                                ]),
                                _vm._v("单"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt10 font20" }, [
                                _vm._v("2023第一旺季困难订单挑战第一名完成 "),
                                _c(
                                  "span",
                                  { staticClass: "green-text font20" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.medalInfo.completeOrderCount)
                                    ),
                                  ]
                                ),
                                _vm._v("单"),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                [28].includes(_vm.current.medalType)
                  ? [
                      _vm.notGetted
                        ? _c("div", { staticClass: "medal-info" }, [
                            _c("div", { staticClass: "left-img-box" }, [
                              _c("img", {
                                staticClass: "head-img",
                                attrs: { src: _vm.current.headImage, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: { src: _vm.current.medalImage, alt: "" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "medal-desc mt25" },
                              [
                                _c("div", [
                                  _vm._v(
                                    "出分达到高分标准线累计" +
                                      _vm._s(_vm.medalInfo.totalOrderCount) +
                                      "单"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-progress", {
                                  attrs: {
                                    percentage: _vm.getPercentage(
                                      _vm.medalInfo.highScoreOderCount,
                                      _vm.medalInfo.totalOrderCount
                                    ),
                                    color: "#FF9F40",
                                    "show-text": false,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "orange-text font22" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.medalInfo.highScoreOderCount)
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "font22" }, [
                                  _vm._v(
                                    "/" + _vm._s(_vm.medalInfo.totalOrderCount)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "mt25" }, [
                                  _vm._v("成为卓越辅导教练"),
                                ]),
                                _vm._v(" "),
                                _c("el-progress", {
                                  attrs: {
                                    percentage: _vm.medalInfo.isGoodCoach
                                      ? _vm.medalInfo.isGoodCoach * 100
                                      : 0,
                                    color: "#FF9F40",
                                    "show-text": false,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "orange-text font22" },
                                  [_vm._v(_vm._s(_vm.medalInfo.isGoodCoach))]
                                ),
                                _c("span", { staticClass: "font22" }, [
                                  _vm._v("/1"),
                                ]),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getted
                        ? _c("div", { staticClass: "top-medal-info" }, [
                            _c("div", { staticClass: "mt15 font20 get-time" }, [
                              _vm._v(_vm._s(_vm.medalInfo.gainTime) + "获得"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "img-box" }, [
                              _c("img", {
                                staticClass: "head-img",
                                attrs: { src: _vm.current.headImage, alt: "" },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: { src: _vm.current.medalImage, alt: "" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "font22 mt15" }, [
                              _vm._v(
                                "出分达到高分标准线累计" +
                                  _vm._s(_vm.medalInfo.totalOrderCount) +
                                  "单&成为卓越辅导教练"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                [36, 35, 34, 26].includes(_vm.current.medalType)
                  ? [
                      _vm.notGetted
                        ? _c("div", { staticClass: "medal-info" }, [
                            _c("img", {
                              attrs: { src: _vm.current.medalImage, alt: "" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "  medal-desc" }, [
                              _c("div", [
                                _vm._v(
                                  "季度完成" +
                                    _vm._s(_vm.medalInfo.totalOrderCount) +
                                    "单"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c("el-progress", {
                                    attrs: {
                                      percentage: _vm.getPercentage(
                                        _vm.medalInfo.completeOrderCount,
                                        _vm.medalInfo.totalOrderCount
                                      ),
                                      color: "#FF9F40",
                                      "show-text": false,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "orange-text font22" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.medalInfo.completeOrderCount)
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "font22" }, [
                                    _vm._v(
                                      "/" +
                                        _vm._s(_vm.medalInfo.totalOrderCount)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt25" }, [
                                _vm._v("投诉率小于"),
                                _c(
                                  "span",
                                  { staticClass: "green-text font22" },
                                  [_vm._v("3%")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt25" }, [
                                _vm._v("您当前挂单投诉率为"),
                                _c("span", { staticClass: "red-text font22" }, [
                                  _vm._v(_vm._s(_vm.medalInfo.failRate) + "%"),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getted
                        ? _c("div", { staticClass: "top-medal-info" }, [
                            _c("div", { staticClass: "font20 mt10 get-time" }, [
                              _vm._v(_vm._s(_vm.medalInfo.gainTime) + "获得"),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: { src: _vm.current.medalImage, alt: "" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "font22 mt25" }, [
                              _vm._v(
                                "季度完成 " +
                                  _vm._s(_vm.medalInfo.totalOrderCount) +
                                  " 单 & 投诉率小于 3%"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              _vm._v(" "),
              [
                [29, 30].includes(_vm.current.medalType)
                  ? _c("div", { staticClass: "top-medal-info" }, [
                      _c("div", { staticClass: "img-box" }, [
                        _c("img", {
                          staticClass: "head-img",
                          attrs: { src: _vm.current.headImage, alt: "" },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: { src: _vm.current.medalImage, alt: "" },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm.getted
                        ? _c("div", { staticClass: "mt15 font20 get-time" }, [
                            _vm._v(_vm._s(_vm.medalInfo.gainTime) + "获得"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.notGetted
                        ? _c("div", { staticClass: "font22 mt25" }, [
                            _vm._v(_vm._s(_vm.current.title)),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                [19, 20, 21, 31].includes(_vm.current.medalType)
                  ? _c(
                      "div",
                      { staticClass: "medal-info mt10" },
                      [
                        _c(
                          "div",
                          { staticClass: "flex1" },
                          [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.current.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "font22 mt10" }, [
                              _vm._v(_vm._s(_vm.current.title)),
                            ]),
                            _vm._v(" "),
                            _c("img", {
                              class: { mt20: !_vm.current.title },
                              attrs: { src: _vm.current.medalImage, alt: "" },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                staticClass: "btn ",
                                class: {
                                  "dis-btn": [0, 1].includes(
                                    _vm.medalInfo.status
                                  ),
                                  "doing-btn": [2].includes(
                                    _vm.medalInfo.status
                                  ),
                                  "unlock-btn": [3].includes(
                                    _vm.medalInfo.status
                                  ),
                                },
                                attrs: {
                                  loading: _vm.btnLoading,
                                  type: "primary",
                                  disabled: ![2].includes(_vm.medalInfo.status),
                                },
                                on: { click: _vm.unlockMedal },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      [3].includes(_vm.medalInfo.status)
                                        ? "已解锁"
                                        : [2].includes(_vm.medalInfo.status)
                                        ? "去解锁"
                                        : "解锁"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-row",
                          {
                            staticClass: "flex1",
                            class: {
                              pt65: ![21].includes(_vm.current.medalType),
                              "mt-30": [21].includes(_vm.current.medalType),
                            },
                            attrs: { gutter: 20 },
                          },
                          _vm._l(
                            _vm.medalInfo.medalList,
                            function (item, index) {
                              return _c(
                                "el-col",
                                {
                                  key: index,
                                  attrs: {
                                    span: _vm.current.medalType == 19 ? 12 : 8,
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "small-img mt35",
                                    attrs: { src: item.imgUrl, alt: "" },
                                  }),
                                  _vm._v(" "),
                                  _c("div", [_vm._v(_vm._s(item.title))]),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              _vm._v(" "),
              [1].includes(_vm.current.type)
                ? _c(
                    "div",
                    { staticClass: "top-medal-info" },
                    [
                      _vm.getted
                        ? [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.current.name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt15 font20 get-time" }, [
                              _vm._v(_vm._s(_vm.medalInfo.gainTime) + "获得"),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: _vm.current.medalImage, alt: "" },
                      }),
                      _vm._v(" "),
                      _vm.notGetted
                        ? _c("div", { staticClass: "title mt15" }, [
                            _vm._v(_vm._s(_vm.current.name)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "font22 mt15",
                          class: { mt25: _vm.notGetted },
                        },
                        [_vm._v(_vm._s(_vm.current.title))]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c("getMedalModal", { ref: "getMedalModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
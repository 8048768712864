"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _additional = _interopRequireDefault(require("./additional"));
require("../css/conmmon.scss");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['copywritingCourse', 'orderMagData'],
  data: function data() {
    return {
      specilTypeArr: {
        1: "资料整理",
        2: "Appeal Letter",
        4: "作业订单",
        8: "论文批改",
        16: "Proofreading",
        32: "Past-paper",
        64: "论文修改",
        128: "考试订单"
      },
      originalWork: []
    };
  },
  components: {
    additional: _additional.default
  },
  created: function created() {
    this.originalWork = this.copywritingCourse && this.copywritingCourse.originalWork ? JSON.parse(this.copywritingCourse.originalWork) : [];
  },
  methods: {
    openUrl: function openUrl(url) {
      window.open(url);
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    info: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      typeList: {
        1: '绝版',
        3: '特别成就',
        4: '月度排行',
        5: '2023年度'
      }
    };
  }
};
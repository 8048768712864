"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      sellerDetailWy: false,
      dropboxList: [],
      wyactiveNames: null,
      wylog: null,
      wyassociated: null,
      wyorder: {
        upload: []
      },
      iswy: false,
      wyid: null,
      typeOrder: true,
      cailiaofei: true,
      history: "",
      dialogVisible: false,
      checked: false,
      courses: "",
      urls: [],
      id: "",
      btnstatus: false,
      classroomdata: [],
      studCoursewareList: [],
      filelist: [],
      ruleForm: {
        course: ""
      },
      ruleForm1: {
        username: "",
        nickname: "",
        sellerDemandDesc: "",
        hourlyWage: ""
      },
      ruleForm2: {
        courseCode: "",
        courseName: "",
        type: ""
      },
      studPurchaseOrderType: "",
      //订单状态
      // 新特殊订单
      studSpecialOrderExt: {
        consumerInfoExcel: {
          url: "",
          name: ""
        },
        //客户信息表
        specialOrderWorkload: "",
        //工作量
        specialOrderSonType: "",
        //特殊订单子类型 （1-资料整理，2-Appeal Letter，4-作业订单,8-论文批改,16-Proofreading,32-Past-paper,64-论文修改）
        reviewMaterialType: "",
        //客户希望复习资料类型
        customerExtraDemand: "",
        //客户的额外需求
        passBonus: "" //pass奖金
      },
      getCourseObjData: {
        theSpecialOffer: "" //特殊报价
      },
      penaltyRate: 0,
      passScore: "",
      getCourseObject: {}
    };
  },
  mounted: function mounted() {
    this.getclassroomdata();
    this.coursedata();
  },
  methods: {
    downDropbox: function downDropbox(path, name) {
      //如果是兼职老师的历史订单不能下载/查看(已结课/异常结束)
      if (this.getCourseObject.teacUser.type == 2 && [16, 32].includes(this.getCourseObject.teacOrder.statused)) {
        return;
      }
      window.open(path);
    },
    down: function down(url, fileName) {
      //如果是兼职老师的历史订单不能下载/查看(已结课/异常结束)
      if (this.getCourseObject.teacUser.type == 2 && [16, 32].includes(this.getCourseObject.teacOrder.statused)) {
        return;
      }
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        var blob = this.response;
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          var link = document.createElement("a");
          link.style.display = "none";
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      };
      xhr.ontimeout = function (e) {};
      xhr.onerror = function (e) {};
      xhr.send();
    },
    revise: function revise(row, index) {
      var _this = this;
      this.$axios({
        url: this.$api.baseURL + "/order/getClassroomConversation",
        params: {
          classroomId: row.id
        }
      }).then(function (r) {
        _this.history = [];
        if (r.data.status == 400) {
          _this.dialogVisible = true;
          return;
        } else {
          var timestampToTime = function timestampToTime(timestamp) {
            var date = new Date(timestamp);
            var Y = date.getFullYear() + "-";
            var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
            var D = date.getDate() + " ";
            var h = date.getHours() + ":";
            var m = date.getMinutes() + ":";
            var s = date.getSeconds();
            return Y + M + D + h + m + s;
          };
          _this.dialogVisible = true;
          var ess = [];
          for (var i = 0; i < r.data.body.list.length; i++) {
            var es = [];
            var joinTime = timestampToTime(r.data.body.list[i].joinTime);
            var leaveTime = timestampToTime(r.data.body.list[i].leaveTime);
            var userroleid = r.data.body.list[i].userroleid;
            var suerrole = "";
            if (userroleid == "0") {
              suerrole = "讲师";
            } else if (userroleid == "1") {
              suerrole = "助教";
            } else if (userroleid == 2) {
              suerrole = "学员";
            } else if (userroleid == "3") {
              suerrole = "直播用户";
            } else {
              suerrole = "巡检员";
            }
            var m = r.data.body.list[i].duration;
            var duration = m / 60;
            var durations;
            durations = duration.toFixed(2);
            es.nickname = r.data.body.list[i].nickname;
            es.joinTime = joinTime;
            es.suerrole = suerrole;
            es.duration = durations;
            es.leaveTime = leaveTime;
            ess.push(es);
          }
          _this.history = ess;
          // console.log(this.history);
        }
      });
      this.dialogVisible = true;
    },
    success: function success(response, file, fileList) {
      if (response.status == 200) {
        this.$message({
          message: "上传文件成功",
          type: "success"
        });
        this.fileList = [];
        // $('.ul').empty()
        this.getclassroomdata();
      }
      if (response.status == 400) {
        this.$message({
          message: response.body.msg,
          type: "warning"
        });
        this.fileList = [];
      }
    },
    onerror: function onerror(response, file, fileList) {
      this.$message({
        message: response.body.msg,
        type: "warning"
      });
    },
    check: function check(val) {
      if (val == true) {
        $(".checkbox").prop("checked", true);
      } else {
        $(".checkbox").prop("checked", false);
      }
    },
    coursedata: function coursedata() {
      var _this2 = this;
      this.$axios({
        url: this.$api.baseURL + "/courseware/groupList",
        params: {
          page: 1,
          limit: 1000
        }
      }).then(function (r) {
        if (r.data.status == 200) {
          var lists = [];
          r.data.body.list.forEach(function (element) {
            var list = {};
            list.groupName = element.groupName;
            list.name = element.groupName.split(" ")[0];
            list.id = element.id;
            lists.push(list);
          });
          _this2.courses = lists;
        }
      });
    },
    change_course: function change_course(courseid) {
      this.data.groupId = courseid;
    },
    downFile: function downFile(url) {
      window.open(url);
    },
    getclassroomdata: function getclassroomdata() {
      var _this3 = this;
      // return null
      // }
      var ids = this.$route.query.id;
      this.wyid = this.$route.query.wyid;
      this.$axios({
        url: this.$api.baseURL + "/order/queryWyardOrderInfo?courseId=" + ids
      }).then(function (r) {
        // this.dropboxList = r.data.data.order.completed_work;
        _this3.wyorder = r.data.data.order;
        _this3.wyassociated = r.data.data.associated;
        _this3.wylog = r.data.data.log;
        _this3.wyactiveNames = r.data.data.order.activeNames;
      });
      this.$axios({
        url: this.$api.baseURL + "/order/getCourseObject?id=" + ids + "",
        params: {
          page: 1,
          limit: 6,
          exceptionType: ""
        }
      }).then(function (r) {
        var json = r.data;
        _this3.penaltyRate = json && json.body && json.body.teacOrder && json.body.teacOrder.penaltyRate;
        _this3.passScore = json && json.body && json.body.passScore;
        _this3.getCourseObject = json.body || {};
        console.log(111, _this3.getCourseObject);
        _this3.dropboxList = _this3.getCourseObject.studCoursewareList && _this3.getCourseObject.studCoursewareList.filter(function (v) {
          return v.groupName == "Complete Work";
        }) || [];
        if (json.body.studPurchaseOrder) {
          _this3.studPurchaseOrderType = json.body.studPurchaseOrder.picType;
        }
        // 新特殊订单
        if (json.body.studPurchaseOrder.picType == 66) {
          var specialData = r.data.body.studPurchaseOrder.studSpecialOrderExt;
          _this3.studSpecialOrderExt.specialOrderSonType = specialData.type; //子订单类型
          if (specialData.type == 1 || specialData.type == 2) {
            _this3.showMark = true;
            _this3.studSpecialOrderExt.passBonus = r.data.body.teacOrder.passBonus; //pass奖金
          }
          _this3.studSpecialOrderExt.specialOrderWorkload = specialData.workload; //工作量
          _this3.studSpecialOrderExt.consumerInfoExcel.url = specialData.customerInfoExcelUrl; //客户信息表
          _this3.studSpecialOrderExt.consumerInfoExcel.name = specialData.customerInfoExcelName; //客户信息表
          _this3.studSpecialOrderExt.reviewMaterialType = specialData.reviewMaterialType; //客户希望复习资料类型
          _this3.studSpecialOrderExt.customerExtraDemand = specialData.customerExtraDemand; //客户的额外需求

          _this3.specialOfferUser = r.data.body.studPurchaseOrder.specialOfferSysUser.username;
          var theSpecialShow = r.data.body.studPurchaseOrder.theSpecialOffer; //是否有特殊报价
          if (theSpecialShow) {}
          r.data.body.studPurchaseOrder.theSpecialOffer == true ? _this3.getCourseObjData.theSpecialOffer = "是" : _this3.getCourseObjData.theSpecialOffer = "否";
        }
        if (json.body.studPurchaseOrder.type == "0") {
          _this3.cailiaofei = false;
        }
        _this3.ruleForm1.hourlyWage = json.body.hourlyWage;
        _this3.classroomdata = json.body.classroomList;
        _this3.studCoursewareList = json.body.studCoursewareList;
        _this3.studdate = json.body.studUser;
        _this3.filelist = json.body.studCoursewareList;
        _this3.ruleForm1.orderNo = json.body.orderNo;
        _this3.ruleForm1.remark = json.body.remark;
        _this3.ruleForm1.scheduledTime = json.body.studPurchaseOrder.scheduledTime;
        _this3.ruleForm1.allSchooltime = json.body.allSchooltime;
        _this3.ruleForm1.totalPrice = json.body.studPurchaseOrder.totalPrice + " " + json.body.studPurchaseOrder.currency;
        _this3.ruleForm1.prepayment = json.body.studPurchaseOrder.prepayment + " " + json.body.studPurchaseOrder.currency;
        _this3.ruleForm1.prices1 = Number(json.body.teacG) + Number(json.body.teacPreG);
        _this3.ruleForm1.prices2 = Number(json.body.teacOtg) + Number(json.body.teacPreOtg);
        _this3.ruleForm1.sellerDemandDesc = json.body.studPurchaseOrder.sellerDemandDesc;
        _this3.ruleForm2.spareTime = json.body.studPurchaseOrder.spareTime;
        if (_this3.ruleForm2.spareTime != null) {
          var spareTime = json.body.studPurchaseOrder.spareTime.split("|");
          var one = spareTime[0];
          var ones = one.split(",");
          _this3.ruleForm2.am1 = ones[0];
          _this3.ruleForm2.bm1 = ones[1];
          _this3.ruleForm2.pm1 = ones[2];
          var two = spareTime[1];
          var twos = two.split(",");
          _this3.ruleForm2.am2 = twos[0];
          _this3.ruleForm2.bm2 = twos[1];
          _this3.ruleForm2.pm2 = twos[2];
          var three = spareTime[2];
          var threes = three.split(",");
          _this3.ruleForm2.am3 = threes[0];
          _this3.ruleForm2.bm3 = threes[1];
          _this3.ruleForm2.pm3 = threes[2];
          var four = spareTime[3];
          var fours = four.split(",");
          _this3.ruleForm2.am4 = fours[0];
          _this3.ruleForm2.bm4 = fours[1];
          _this3.ruleForm2.pm4 = fours[2];
          var five = spareTime[4];
          var fives = five.split(",");
          _this3.ruleForm2.am5 = fives[0];
          _this3.ruleForm2.bm5 = fives[1];
          _this3.ruleForm2.pm5 = fives[2];
          var six = spareTime[5];
          var sixs = six.split(",");
          _this3.ruleForm2.am6 = sixs[0];
          _this3.ruleForm2.bm6 = sixs[1];
          _this3.ruleForm2.pm6 = sixs[2];
          var seven = spareTime[6];
          var sevens = seven.split(",");
          _this3.ruleForm2.am7 = sevens[0];
          _this3.ruleForm2.bm7 = sevens[1];
          _this3.ruleForm2.pm7 = sevens[2];
        }
        if (json.body.statused == 1) {
          _this3.ruleForm1.statused = "未处理（无任何销售接单）";
        }
        if (json.body.statused == 2) {
          _this3.ruleForm1.statused = "待审核";
        }
        if (json.body.statused == 4) {
          _this3.ruleForm1.statused = "待确定";
        }
        if (json.body.statused == 8) {
          _this3.ruleForm1.statused = "待规划（等待教师/运营接单）";
        }
        if (json.body.statused == 16) {
          _this3.ruleForm1.statused = "规划中(订单第一次超时等待运营接单)";
        }
        if (json.body.statused == 32) {
          _this3.ruleForm1.statused = "规划中(订单第二次超时等待运营主管强制指派)";
        }
        if (json.body.statused == 64) {
          _this3.ruleForm1.statused = "规划中(运营接单待指派，正在处理)";
        }
        if (json.body.statused == 128) {
          _this3.ruleForm1.statused = "待排课";
        }
        if (json.body.statused == 256) {
          _this3.ruleForm1.statused = "已排课";
        }
        if (json.body.statused == 512) {
          _this3.ruleForm1.statused = "申请结课中";
        }
        if (json.body.statused == 1024) {
          _this3.ruleForm1.statused = "已结课";
        }
        if (json.body.statused == 2048) {
          _this3.ruleForm1.statused = "已强制结课";
        }
        if (json.body.statused == 4096) {
          _this3.ruleForm1.statused = "已取消";
        }
        if (json.body.statused == 8192) {
          _this3.ruleForm1.statused = "坏单申请中";
        }
        if (json.body.statused == 16384) {
          _this3.ruleForm1.statused = "订单作废";
        }
        if (json.body.statused == 32768) {
          _this3.ruleForm1.statused = "销售被更换";
        }
        if (json.body.statused == 65536) {
          _this3.ruleForm1.statused = "事故单申请中";
        }
        if (json.body.statused == 131072) {
          _this3.ruleForm1.statused = "事故单已处理";
        }
        if (json.body.statused == 262144) {
          _this3.ruleForm1.statused = "事故单更换教师";
        }
        _this3.ruleForm1.username = json.body.sysSeller.username;
        _this3.ruleForm1.nickname = json.body.sysUserEntity.username;
        _this3.ruleForm1.nickname1 = json.body.teacUser.username;
        _this3.ruleForm1.teacFixedPrice = json.body.teacFixedPrice;
        _this3.ruleForm2.courseName = json.body.studPurchaseOrder.courseName;
        _this3.ruleForm2.courseCode = json.body.studPurchaseOrder.courseCode;
        if (json.body.studPurchaseOrder.type == 0) {
          _this3.ruleForm2.type = "定制辅导";
        }
        if (json.body.studPurchaseOrder.type == 1) {
          _this3.ruleForm2.type = "考前突击";
        }
        if (json.body.studPurchaseOrder.type == 2) {
          _this3.ruleForm2.type = "包课辅导";
        }
        if (json.body.studPurchaseOrder.type == 4) {
          _this3.ruleForm2.type = "论文辅导";
        }
        if (json.body.studPurchaseOrder.type == 32) {
          _this3.ruleForm2.type = "特殊订单";
        }
        _this3.ruleForm.nickname = json.body.studUser.nickName;
        _this3.ruleForm.schoolName = json.body.sysUniversity.name;
        _this3.ruleForm.temCourseName = json.body.sysProfessionalCourses.chineseName;
        _this3.ruleForm.levelName = json.body.studUser.eduName;
        _this3.ruleForm.schoolYear = json.body.studPurchaseOrder.schoolYear;
        _this3.ruleForm.endCourseTime = json.body.studPurchaseOrder.endCourseTime;
        _this3.ruleForm.examTime = json.body.studPurchaseOrder.examTime;
        _this3.ruleForm.schoolAccount = json.body.studPurchaseOrder.schoolAccount;
        _this3.ruleForm.schoolPws = json.body.studPurchaseOrder.schoolPws;
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-container",
      style: _vm.staticPath.BG,
    },
    [
      _c("countdown"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "intention-container" },
        [
          _c(
            "section",
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c("mini-map", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.modalShow,
                        expression: "!modalShow",
                      },
                    ],
                    on: { open: _vm.openModal },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("map-modal", {
                ref: "modal",
                on: {
                  close: function ($event) {
                    _vm.modalShow = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "main",
            [
              _vm._m(0),
              _vm._v(" "),
              _c("section", { staticClass: "content-container" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("亲爱的" + _vm._s(_vm.userName) + "讲师，您好！"),
                ]),
                _vm._v(" "),
                _c("div", [_vm._v("恭喜您开启万能讲师培训！")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n          下列为万能班长主打课程产品培训视频，请认真观看，初步了解产品特性，为之后正式授课做好准备，\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "video-container" }, [
                _c("span", { staticClass: "video-title" }, [
                  _vm._v("产品培训视频"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "video-box" },
                  _vm._l(_vm.videoArr, function (video, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "box",
                        on: {
                          click: function ($event) {
                            return _vm.showVideoFun(index)
                          },
                        },
                      },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: { placement: "top-start", trigger: "hover" },
                          },
                          [
                            _c("video", {
                              ref: "video",
                              refInFor: true,
                              staticStyle: { "object-fit": "fill" },
                              attrs: {
                                id: "video" + index,
                                preload: "meta",
                                controlslist: "nodownload",
                                "webkit-playsinline": "",
                                playsinline: "",
                                "x5-playsinline": "",
                                "x-webkit-airplay": "allow",
                                muted: "muted",
                                src: video.url,
                                width: "400",
                                height: "230",
                                poster: require("@/assets/images/hatch/intention/video" +
                                  (index + 1) +
                                  ".png"),
                              },
                              domProps: { muted: true },
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.playVideo(index, "play")
                                },
                                mouseout: function ($event) {
                                  return _vm.playVideo(index, "stop")
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "video-img",
                              attrs: {
                                slot: "reference",
                                width: "200px",
                                src: require("@/assets/images/hatch/intention/video" +
                                  (index + 1) +
                                  ".png"),
                              },
                              slot: "reference",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        !video.see
                          ? _c("img", {
                              staticClass: "play-btn",
                              attrs: {
                                src: require("@/assets/images/hatch/intention/play.png"),
                                alt: "",
                              },
                            })
                          : _c("img", {
                              staticClass: "finshed-btn",
                              attrs: {
                                src: require("@/assets/images/hatch/intention/finshed.png"),
                                alt: "",
                              },
                            }),
                        _vm._v(" "),
                        _c("div", { staticClass: "time-show-box" }, [
                          _vm._v(_vm._s(video.time)),
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(video.name))]),
                        _vm._v(" "),
                        video.desc
                          ? _c("span", [_vm._v(_vm._s(video.desc))])
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn",
                  class: _vm.showNext ? "" : "canclick-status",
                  on: { click: _vm.showtips },
                },
                [_vm._v("\n        视频学习完成，下一步\n      ")]
              ),
              _vm._v(" "),
              _c("unlock-success", {
                ref: "success",
                on: { submit: _vm.nextStep },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("videoplay", { ref: "videoplays", on: { addstep: _vm.addstep } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("comeInTip", { ref: "comeInTip" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "title" }, [
      _c("span", [_vm._v("万能班长讲师产品培训")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isShow, width: "1120px", "show-close": false },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          (![0].includes(_vm.current.buttonStatus) && _vm.teacType == 2) ||
          ([2].includes(_vm.current.buttonStatus) && _vm.teacType == 1)
            ? _c(
                "marquee",
                {
                  staticClass: "marquee",
                  attrs: {
                    behavior: "scroll",
                    direction: "left",
                    onmouseover: "stop()",
                    onmouseout: "start()",
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/order/yanhua.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  [1, 8].includes(_vm.current.buttonStatus)
                    ? _c("span", [_vm._v("备考审核表上传完毕，请等待审核")])
                    : _vm._e(),
                  _vm._v(" "),
                  [3].includes(_vm.current.buttonStatus)
                    ? _c("span", [
                        _vm._v("质检审核不通过，请按照质检审核要求重新上传"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  [2, 5, 6].includes(_vm.current.buttonStatus)
                    ? _c("span", [
                        _vm._v(
                          "恭喜您！" +
                            _vm._s(_vm.current.lockTeacG) +
                            "G已到账，完成考试即可解锁哦！继续加油吧"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  [7].includes(_vm.current.buttonStatus)
                    ? _c("span", [
                        _vm._v(
                          "质检审核不通过,该订单已过Deal Dealine,请联系师资管理员处理"
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-info" },
            [
              _c("div", { staticClass: "order-title" }, [
                _vm._v("单号 "),
                _c("span", [_vm._v(_vm._s(_vm.current.orderNo))]),
              ]),
              _vm._v(" "),
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.handleClose },
              }),
              _vm._v(" "),
              _c("examInfo", {
                ref: "examInfo",
                attrs: { current: _vm.current },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "bottom-box" }, [
            _vm.teacType == 2
              ? _c(
                  "div",
                  { staticClass: "steps" },
                  _vm._l(_vm.stepList, function (key, value) {
                    return _c(
                      "div",
                      {
                        key: value,
                        on: {
                          click: function ($event) {
                            return _vm.changeStep(value)
                          },
                        },
                      },
                      [
                        _vm.active >= value
                          ? _c("i", { staticClass: "el-icon-success" })
                          : _c("div", { staticClass: "num" }, [
                              _vm._v(" " + _vm._s(value)),
                            ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            class: { active: _vm.active >= value },
                          },
                          [_vm._v(_vm._s(key))]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bottom-content" },
              [
                _c("testmsgForm", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step != 1,
                      expression: "step!=1",
                    },
                  ],
                  ref: "testmsgForm",
                  staticClass: "test-form",
                  attrs: { datas: _vm.testMsgObj },
                  on: { close: _vm.handleClose, refresh: _vm.refresh },
                }),
                _vm._v(" "),
                _c("uploadExamCheck", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step == 1,
                      expression: "step==1",
                    },
                  ],
                  ref: "uploadExamCheck",
                  attrs: {
                    disabled: [2, 5, 6, 7, 8].includes(
                      _vm.current.buttonStatus
                    ),
                    datas: _vm.testMsgObj,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn-box" },
              [
                _vm.teacType == 1 && !_vm.testMsgObj.disabled
                  ? _c(
                      "el-button",
                      { staticClass: "btn", on: { click: _vm.handleClose } },
                      [_vm._v("取消")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.teacType == 2 && _vm.step != 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { disabled: _vm.step == 0 },
                        on: { click: _vm.pre },
                      },
                      [_vm._v("上一步")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.teacType == 2 && _vm.step == 0
                  ? _c(
                      "el-button",
                      { staticClass: "btn", on: { click: _vm.next } },
                      [_vm._v("下一步")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.step == 1 || _vm.teacType == 1) && !_vm.testMsgObj.disabled
                  ? _c(
                      "el-button",
                      {
                        staticClass: "submit-btn",
                        attrs: { loading: _vm.btnLoading },
                        on: { click: _vm.uploadCheck },
                      },
                      [_vm._v("提交审核")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _level = require("@/utils/level");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    teacinfo: {
      type: Object,
      default: function _default() {}
    },
    levelsArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    thumbsNext: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      levelData: _level.levelData,
      proLength: 0,
      currentLevel: 1,
      teacherNamelist: {
        1: "王牌讲师",
        2: '资深讲师',
        3: '特级讲师',
        4: 'T3特级讲师',
        5: 'T4特级讲师'
      }
    };
  },
  watch: {
    isShow: function isShow(val) {
      if (val) {
        this.currentLevel = this.teacinfo.grade;
        this.initLength();
      }
    }
  },
  methods: {
    //  初始化进度条（全职讲师手动调整等级不会自动补赞和扣赞）
    initLength: function initLength() {
      // 判断当前获赞值是否大于等于下一级的获赞值，如果大于等于，进度条到下一级，下一级圆点空心
      var index = this.teacinfo.grade - 1; //当前等级对应的index
      if (index < 4) {
        if (this.teacinfo.thumbsNumber < this.levelsArr[index].calThumbsNum) {
          //当前的赞值如果小于等级所需要的赞，说明手动调高过等级
          // 进度条跟等级走
          this.proLength = 130 + index * 180 + 5;
        } else {
          //手动调低/正常等级运转（进度条根据等级和赞综合判断）
          if (this.teacinfo.thumbsNumber >= this.levelsArr[index + 1].calThumbsNum) {
            this.proLength = 130 + (index + 1) * 180 + 5; //进度条到下一级130是1前面的180是每个区间的长度5为了到圆点中间
          } else {
            //如果没到下一级的获赞值，计算进度条长度
            var pre = this.teacinfo.thumbsNumber - this.levelsArr[index].calThumbsNum; //多出当前等级的赞
            var now = this.levelsArr[index + 1].calThumbsNum - this.levelsArr[index].calThumbsNum; //升级所需的赞
            var all = pre / now;
            this.proLength = 130 + index * 180 + all * 180 + 5;
          }
        }
      } else {
        //已经到最高级了
        this.proLength = 130 + index * 180 + 150 + 5; //150是5级后面的长度
      }
    },
    handleClose: function handleClose() {
      this.$emit("closeLevel");
    }
  }
};
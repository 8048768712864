var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showvideo,
        width: "50%",
        "close-on-click-modal": false,
      },
      on: {
        close: _vm.closevideo,
        "update:visible": function ($event) {
          _vm.showvideo = $event
        },
      },
    },
    [
      _c("video", {
        ref: "myvideo",
        staticStyle: { width: "100%", "object-fit": "fill" },
        attrs: {
          autoplay: "",
          id: "myvideo",
          oncontextmenu: "return false;",
          src: _vm.videourl,
        },
      }),
      _vm._v(" "),
      _vm.videoduration
        ? _c("div", { staticClass: "canclick-box" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.videocurrent) +
                "/" +
                _vm._s(_vm.videoduration) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: {
        visible: _vm.isShow,
        width: "500px",
        "append-to-body": true,
        "before-close": _vm.closeModal,
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("额外课时申请")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.order,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "order", $$v)
                  },
                  expression: "form.order",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "0 0 10px 110px" } }, [
            _vm._v("已上课时/总计课时\n\n      "),
            _c("span", [
              _vm._v(_vm._s(_vm.formatMinAndHour(_vm.schooltime * 60))),
            ]),
            _vm._v(
              "\n      /" +
                _vm._s(_vm.formatMinAndHour(_vm.scheduledTime * 60)) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "200px", display: "inline-block" },
              attrs: { label: "\n        课节数量", prop: "applyClassNumber" },
            },
            [
              _c("el-input", {
                staticClass: "number-input",
                staticStyle: { width: "60px" },
                attrs: { type: "number" },
                on: {
                  input: function ($event) {
                    return _vm.limitInputNumber(
                      $event,
                      null,
                      0,
                      0,
                      "applyClassNumber"
                    )
                  },
                },
                model: {
                  value: _vm.form.applyClassNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "applyClassNumber", $$v)
                  },
                  expression: "form.applyClassNumber",
                },
              }),
              _vm._v(" 节\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "200px", display: "inline-block" },
              attrs: { label: "额外课时", prop: "applyClassHour" },
            },
            [
              _c("el-input", {
                staticClass: "number-input",
                staticStyle: { width: "60px" },
                attrs: { type: "number" },
                on: {
                  input: function ($event) {
                    return _vm.limitInputNumber(
                      $event,
                      null,
                      0,
                      1,
                      "applyClassHour"
                    )
                  },
                },
                model: {
                  value: _vm.form.applyClassHour,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "applyClassHour", $$v)
                  },
                  expression: "form.applyClassHour",
                },
              }),
              _vm._v(" h\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "额外课时原因", prop: "applyExplain" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "请填写" },
                model: {
                  value: _vm.form.applyExplain,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "applyExplain", $$v)
                  },
                  expression: "form.applyExplain",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "0 0 30px 110px" } }, [
            _vm._v("确定申请后师资运营将通知申请结果"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "footer-btn" },
            [
              _c(
                "el-button",
                {
                  staticClass: "confirm-btn",
                  attrs: { loading: _vm.btnLoding, size: "mini" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "classbro-app-container" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isWechat,
              expression: "isWechat",
            },
          ],
          staticClass: "tips",
        },
        [
          _c("span", [_vm._v("点击右上角“…”")]),
          _vm._v(" "),
          _c("span", [_vm._v("选择在浏览器中打开")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isWechat,
              expression: "isWechat",
            },
          ],
          staticClass: "img",
        },
        [_c("img", { attrs: { src: _vm.arrow } })]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "under", style: _vm.BG }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "download-container" }, [
        !_vm.isWechat
          ? _c(
              "div",
              { staticClass: "download-btn", on: { click: _vm.download } },
              [_c("span", [_vm._v("立即下载")])]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "name" }, [
      _c("span", [_vm._v("万能讲师")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProcessStartedTime = exports.getMyInfo = exports.getCurryProcessByTeacId = exports.getCertificateFlag = exports.doAfterStep = void 0;
var _request = require("./request");
var _api = require("@/api/api");
//小地图流程

/**
 * 查询当前讲师所在的环节流程
 * @param {String} teacherId
 */
var getCurryProcessByTeacId = exports.getCurryProcessByTeacId = function getCurryProcessByTeacId(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/process/v1/getCurryProcessByTeacId"),
    method: "get",
    params: params
  });
};

/**
 * 进入下一个环节
 * @param {String} teacherId
 */
var doAfterStep = exports.doAfterStep = function doAfterStep(data) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacherstep/v1/doAfterStep"),
    method: "post",
    data: data
  });
};

/**
 * 查询是否定制过 我的讲师形象
 */
var getMyInfo = exports.getMyInfo = function getMyInfo() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/user/getMyInfo"),
    method: "get",
    token: true
  });
};

/**
 * 查询倒计时
 */
var getProcessStartedTime = exports.getProcessStartedTime = function getProcessStartedTime(teacherId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacherstep/v1/getProcessStartedTime?teacherId=").concat(teacherId),
    method: "get"
  });
};
/**
 * 领取入职证书
 */
var getCertificateFlag = exports.getCertificateFlag = function getCertificateFlag() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacUserDetailsExt/doEmploymentCertificateFlag "),
    method: "post",
    data: {}
  });
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShow,
            width: "516px",
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/level/banner.png"),
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("img", {
            attrs: { src: require("@/assets/images/level/btn.png"), alt: "" },
            on: { click: _vm.showLevel },
          }),
          _vm._v(" "),
          _c("i", {
            staticClass: "el-icon-circle-close",
            on: { click: _vm.handleClose },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "g-table-header-row header" }, [
      _c("div", { staticClass: "phase" }, [_c("span", [_vm._v("课程阶段")])]),
      _vm._v(" "),
      _c("div", { staticClass: "name" }, [_c("span", [_vm._v("课堂名称")])]),
      _vm._v(" "),
      _c("div", { staticClass: "type" }, [_c("span", [_vm._v("课堂类别")])]),
      _vm._v(" "),
      _c("div", { staticClass: "plan" }, [_c("span", [_vm._v("安排说明")])]),
      _vm._v(" "),
      _c("div", { staticClass: "time" }, [_c("span", [_vm._v("课程时间")])]),
      _vm._v(" "),
      _c("div", { staticClass: "classExplain" }, [
        _c("span", [_vm._v("课堂状态")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "classroomRemark" }, [
        _c("span", [_vm._v("课堂说明")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "timeRange" }, [
        _c("span", [_vm._v("上课时长")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "material" }, [
        _c("span", [_vm._v("讲师材料")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "planClass" }, [_c("span", [_vm._v("排课")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "persondetail-top" }, [
      _c(
        "div",
        [
          _vm.showStep == 1
            ? [
                _c("teacherInfoImg", {
                  attrs: {
                    teacinfo: _vm.teacinfo,
                    teacherType: _vm.teacherType,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "info-imgbox" }, [
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: require("@/assets/images/home/follow/infoarrow.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "info-text",
                    attrs: {
                      src: require("@/assets/images/home/follow/infotext.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "next",
                    attrs: {
                      src: require("@/assets/images/home/follow/next.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.next(2)
                      },
                    },
                  }),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "250px" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "24px", display: "flex" } },
            [
              _vm.showStep == 2
                ? _c("div", { staticClass: "follow" }, [
                    _c("div", { staticClass: "img-box" }, [
                      _c("img", {
                        staticClass: "arrow",
                        attrs: {
                          src: require("@/assets/images/home/follow/rigtharrow.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "info-text",
                        attrs: {
                          src: require("@/assets/images/home/follow/reflectiontext.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "next",
                        attrs: {
                          src: require("@/assets/images/home/follow/next.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.next(3)
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showStep == 2 ? _c("reflectionNoticeImg") : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "income" }),
    ]),
    _vm._v(" "),
    [1, 2].includes(Number(_vm.showStep))
      ? _c("div", { staticClass: "new-mask z-index102" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "560px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("论文大礼包订单")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            [
              [3].includes(_vm.version)
                ? [
                    _c("p", [
                      _vm._v("1、论文大礼包 "),
                      _c("span", [_vm._v("规划表流程新调整")]),
                      _vm._v("啦,请前往"),
                      _c("span", [_vm._v("破冰阶段")]),
                      _vm._v("查看吧"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "2、论文大礼包订单接单后，须在及时安排【辅导规划表】"
                      ),
                    ]),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        "1、年初福利来啦，论文大礼包订单提薪高达50%，基础薪资提升到"
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("b", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("「每1000workload 30G」"),
                      ]),
                      _vm._v("啦！\n        "),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "2、除讲师等级对应的课时费外，订单完成还会加额外收入呐"
                      ),
                    ]),
                  ],
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "3、订单默认Penalty Rate 100%，接单后要对订单结果负责哦~~ "
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { position: "relative" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/paper/lunwen-video.png"),
                alt: "",
              },
              on: { click: _vm.playVideo },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "span",
                { staticClass: "cancle-btn", on: { click: _vm.closeModal } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "confirm-btn",
                  attrs: { loading: _vm.$parent.loading },
                  on: { click: _vm.receivOrder },
                },
                [_vm._v("接单")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
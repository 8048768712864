var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("Client Info")]),
    _vm._v(" "),
    _vm.orderMagData.studUser
      ? _c("div", { staticClass: "detial-msg-box detial-msg-height" }, [
          _c("p", [
            _c("label", [_vm._v("client Name")]),
            _vm._v(
              "\n         " +
                _vm._s(_vm.orderMagData.studUser.clientName) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Country")]),
            _vm._v(
              "\n         " +
                _vm._s(_vm.orderMagData.studUser.countryName) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("School")]),
            _vm._v(
              "\n         " +
                _vm._s(
                  _vm.orderMagData.sysUniversity &&
                    _vm.orderMagData.sysUniversity.name
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Major")]),
            _vm._v(
              "\n         " +
                _vm._s(
                  _vm.orderMagData.sysProfessionalCourses &&
                    _vm.orderMagData.sysProfessionalCourses.chineseName
                    ? _vm.orderMagData.sysProfessionalCourses.chineseName
                    : ""
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Year")]),
            _vm._v(
              "\n         " +
                _vm._s(_vm.orderMagData.schoolYearName) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Blackboard ID")]),
            _vm._v(
              "\n         " +
                _vm._s(_vm.orderMagData.studUser.schoolAccount) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Blackboard Password")]),
            _vm._v(
              "\n         " +
                _vm._s(_vm.orderMagData.studUser.schoolPws) +
                "\n      "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { class: { "flex-box": ["3"].includes(_vm.type) } }, [
        ["1"].includes(_vm.type)
          ? _c("div", { staticClass: "step" }, [
              _c("div", { staticClass: "title", attrs: { id: "1.1" } }, [
                _vm._v("1.万能讲师课堂测试入口"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "step-content" }, [
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("span", { staticClass: "star" }, [_vm._v("*")]),
                    _vm._v("万能讲师课堂:"),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn right",
                        attrs: { loading: _vm.classLoding1 },
                        on: {
                          click: function ($event) {
                            return _vm.creatClass(1)
                          },
                        },
                      },
                      [_vm._v("开始测试")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                ["1"].includes(_vm.type)
                  ? _c("div", { staticClass: "font-red" }, [
                      _c("span", { staticClass: "star" }, [_vm._v("*")]),
                      _vm._v("请讲师们在此处进行模拟上课的操作演练"),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "step" },
          [
            ["3"].includes(_vm.type)
              ? [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/help/step1.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: require("@/assets/images/help/arrow.png"),
                      alt: "",
                    },
                  }),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "title",
                class: { "orange-title": _vm.type == 2 },
                attrs: { id: "1.2" },
              },
              [
                ["2", "3"].includes(_vm.type)
                  ? _c("span", [_vm._v("Step1")])
                  : _vm._e(),
                _vm._v(" "),
                ["1"].includes(_vm.type)
                  ? _c("span", [_vm._v("2.")])
                  : _vm._e(),
                _vm._v("万能讲师客户端下载"),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "step-content" }, [
              _c("div", { staticClass: "desc" }, [
                _vm._v(" 适用于macOS 10.13及以上， Windows7及以上。"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "down-content" },
                _vm._l(_vm.downList, function (item, index) {
                  return _c("div", { key: item.id }, [
                    _c("img", { attrs: { src: item.img, alt: "" } }),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(item.desc))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "down-btn",
                        on: {
                          click: function ($event) {
                            return _vm.downLoad(item.guideUrl, item.guideName)
                          },
                        },
                      },
                      [_vm._v("安装指南")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "down-btn",
                        on: {
                          click: function ($event) {
                            return _vm.downLoad(item.downLoadLink, item.desc)
                          },
                        },
                      },
                      [_c("a", [_vm._v("下载")])]
                    ),
                  ])
                }),
                0
              ),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        !["1"].includes(_vm.type)
          ? _c(
              "div",
              { staticClass: "step" },
              [
                ["3"].includes(_vm.type)
                  ? [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/help/step2.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "arrow2",
                        attrs: {
                          src: require("@/assets/images/help/arrow.png"),
                          alt: "",
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "title",
                    class: { "orange-title": _vm.type == 2 },
                    attrs: { id: "1.2" },
                  },
                  [
                    ["2", "3"].includes(_vm.type)
                      ? _c("span", [_vm._v("Step2 ")])
                      : _vm._e(),
                    _vm._v("万能讲师课堂测试入口"),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "step-content" }, [
                  ["2", "3"].includes(_vm.type)
                    ? _c(
                        "div",
                        {
                          staticClass: "font-red desc",
                          class: { "ml-8": _vm.type == 2 },
                        },
                        [
                          _vm._v(
                            "*请务必完成课堂测试，若未成功进入课堂将无法" +
                              _vm._s(_vm.type == 2 ? "认证" : "复试")
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item", class: { "mt-18": _vm.type == 3 } },
                    [
                      _c("span", { staticClass: "star" }, [_vm._v("*")]),
                      _vm._v("万能讲师课堂:"),
                      _c(
                        "el-button",
                        {
                          staticClass: "btn right",
                          attrs: { loading: _vm.classLoding1 },
                          on: {
                            click: function ($event) {
                              return _vm.creatClass(1)
                            },
                          },
                        },
                        [_vm._v("开始测试")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  ["1"].includes(_vm.type)
                    ? _c("div", { staticClass: "font-red" }, [
                        _c("span", { staticClass: "star" }, [_vm._v("*")]),
                        _vm._v("请讲师们在此处进行模拟上课的操作演练"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  ["2", "3"].includes(_vm.type)
                    ? _c(
                        "div",
                        {
                          staticClass: "item",
                          class: { "mt-18": _vm.type == 3 },
                        },
                        [
                          _c("span", { staticClass: "star" }, [_vm._v("*")]),
                          _vm._v("课堂检测提示："),
                          _c(
                            "span",
                            {
                              staticClass: "right",
                              class: {
                                "gray-font": !_vm.isComplated,
                                "orange-font": _vm.isComplated,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isComplated
                                    ? "成功进入课堂"
                                    : "暂未进入课堂"
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == 3
                    ? _c(
                        "div",
                        { staticClass: "item mt-18" },
                        [
                          _vm._v(
                            "\n            复试课件一键下载:\n            "
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn right",
                              attrs: { loading: _vm.downLoading },
                              on: { click: _vm.getZipFilePlus },
                            },
                            [_vm._v("下载")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        ["2", "3"].includes(_vm.type)
          ? _c("div", { staticClass: "step" }, [
              ["3"].includes(_vm.type)
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/help/step3.png"),
                      alt: "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "title",
                  class: { "orange-title": _vm.type == 2 },
                },
                [
                  ["2", "3"].includes(_vm.type)
                    ? _c("span", [
                        _vm._v(
                          _vm._s(["3"].includes(_vm.type) ? "Step4" : "Step3") +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v("万能讲师课堂操作指南 "),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "step-content" }, [
                _c("div", { staticClass: "file" }, [
                  _c("img", {
                    staticClass: "pdf",
                    attrs: {
                      src: require("@/assets/images/help/pdf.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "cousor",
                        on: {
                          click: function ($event) {
                            return _vm.openLink(_vm.link.url, 1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.link.name))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "size" }, [
                      _vm._v(_vm._s(_vm.link.size)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "down",
                    attrs: {
                      src: require("@/assets/images/help/down.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.openLink(_vm.link.url)
                      },
                    },
                  }),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.type == 3
          ? _c(
              "div",
              { staticClass: "step" },
              [
                ["3"].includes(_vm.type)
                  ? [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/help/step4.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "arrow3",
                        attrs: {
                          src: require("@/assets/images/help/arrow.png"),
                          alt: "",
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _vm._v("开课日期 "),
                    _c("el-date-picker", {
                      staticStyle: { width: "145px" },
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期",
                        disabled: _vm.disabledTime,
                        "picker-options": _vm.picker0ptions,
                      },
                      on: { focus: _vm.getBeiJinTime },
                      model: {
                        value: _vm.form.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "date", $$v)
                        },
                        expression: "form.date",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-time-select", {
                      staticStyle: { width: "120px" },
                      attrs: {
                        disabled: _vm.disabledTime,
                        placeholder: "选择时间",
                        "popper-class": "time-select",
                        "picker-options": {
                          start: "10:00",
                          step: "00:30",
                          end: "20:30",
                        },
                      },
                      on: { focus: _vm.showTimeSelect },
                      model: {
                        value: _vm.form.time,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "time", $$v)
                        },
                        expression: "form.time",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt10" },
                  [
                    _vm._v("课节时长"),
                    _c("el-input", {
                      staticStyle: { width: "55px", margin: "0 10px" },
                      attrs: { value: "30", disabled: "" },
                    }),
                    _vm._v("分钟"),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.disabledTime
                  ? _c(
                      "el-button",
                      {
                        staticClass: "small-btn",
                        attrs: { loading: _vm.classLoding2 },
                        on: {
                          click: function ($event) {
                            return _vm.creatClass(2)
                          },
                        },
                      },
                      [_vm._v("确定复试")]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.type == 3
        ? _c(
            "div",
            { staticClass: "footer-box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "complate",
                  attrs: { loading: _vm.classLoding3 },
                  on: {
                    click: function ($event) {
                      return _vm.creatClass(3)
                    },
                  },
                },
                [_vm._v("练习课堂(随意使用)")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "complate",
                  attrs: { loading: _vm.loading, disabled: _vm.disabledBtn },
                  on: {
                    click: function ($event) {
                      return _vm.showModal("confirmModal")
                    },
                  },
                },
                [_vm._v("进入复试课堂(正式课堂)")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("confirmModal", {
        ref: "confirmModal",
        on: { comeInClass: _vm.comeInClass },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _vm._v("Step3 "),
      _vm._v("选择复试时间"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "first-line" }, [
      _vm._v("请务必在"),
      _c("span", { staticClass: "font-red" }, [_vm._v("两周内")]),
      _vm._v("完成复试，以下时间为"),
      _c("span", { staticClass: "font-red" }, [_vm._v("北京时间")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
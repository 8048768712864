var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type == 1
        ? [
            _c(
              "div",
              { staticClass: "operate-btn", on: { click: _vm.getOrder } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/getorder.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.showGet ? "我要接单" : "师资正在加速匹配订单中..."
                    )
                  ),
                ]),
              ]
            ),
          ]
        : [
            _vm.userDetail.statused == 0 && _vm.userDetail.type == 2
              ? _c(
                  "div",
                  { staticClass: "no-order" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/getOrder2.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _vm.showGet
                      ? _c(
                          "el-button",
                          {
                            staticClass: "ope-btn",
                            on: { click: _vm.getOrder },
                          },
                          [_vm._v("我要接单")]
                        )
                      : _c("div", [_vm._v("师资正在加速匹配订单中...")]),
                  ],
                  1
                )
              : _c("div", { staticClass: "content" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/order-contentnone.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(
                    "\n            订单空空如也，快去联系师资接单赚钱吧\n        "
                  ),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
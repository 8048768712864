var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model.show && ["获赞值明细"].includes(_vm.model.type)
    ? _c("div", { staticClass: "new-mask" }, [
        _c(
          "div",
          {
            staticClass: "mask-content mask-width560 fixed-mask mask-width984",
          },
          [
            _c("h3", { staticClass: " content-title" }, [
              _vm._v(" " + _vm._s(_vm.model.type) + " "),
              _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contents-box auto-box" },
              [
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.likeLoading,
                        expression: "likeLoading",
                      },
                    ],
                    staticClass: "likedetail-box",
                  },
                  _vm._l(_vm.tableDataEnd, function (items, indexs) {
                    return items.type > 0
                      ? _c("li", { key: indexs }, [
                          _c("p", { staticClass: "zan-detial" }, [
                            _vm._v(
                              "[" +
                                _vm._s(_vm.thumpsText[items.type]) +
                                _vm._s(
                                  [7, 9].includes(items.type)
                                    ? items.thumbsType == 2
                                      ? "扣赞"
                                      : "点赞"
                                    : ""
                                ) +
                                "]"
                            ),
                            _c(
                              "span",
                              { class: [items.thumbsType == 2 ? "less" : ""] },
                              [
                                _vm._v(
                                  _vm._s(items.thumbsType == 2 ? "-" : "+") +
                                    _vm._s(items.number) +
                                    " 赞"
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p"),
                          ![5, 7, 8, 9, 10, 11, 13, 15, 6, 17].includes(
                            items.type
                          )
                            ? _c("p", { staticClass: "detail-box" }, [
                                _vm._v(
                                  "\n            Hi," +
                                    _vm._s(items.teacName) +
                                    "讲师，" +
                                    _vm._s(
                                      [4, 16].includes(items.type)
                                        ? "学生订单出分"
                                        : ""
                                    )
                                ),
                                items.type == 4
                                  ? _c(
                                      "b",
                                      { staticStyle: { "font-weight": "400" } },
                                      [
                                        _c("b", { staticClass: "num" }, [
                                          _vm._v(_vm._s(items.score || 0)),
                                        ]),
                                        _vm._v(","),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  _vm._s(
                                    items.thumbsType == 2 ? "被扣除" : "又获得"
                                  )
                                ),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.number)),
                                ]),
                                _vm._v(
                                  "个赞啦，" +
                                    _vm._s(
                                      items.thumbsType == 2
                                        ? "获赞不易且珍惜！"
                                        : "继续加油离升级不远啦！"
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            订单：" + _vm._s(items.orderNo)
                                ),
                                _c("br"),
                                _vm._v(" "),
                                items.type == 2 && items.classRoomName
                                  ? _c("b", [
                                      _vm._v(
                                        " 课堂：" + _vm._s(items.classRoomName)
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          items.type == 7
                            ? _c("p", { staticClass: "detail-box" }, [
                                _vm._v(
                                  "\n            Hi," +
                                    _vm._s(items.teacName) +
                                    "讲师你好，你的获赞值因“" +
                                    _vm._s(items.describe) +
                                    "”，" +
                                    _vm._s(
                                      items.thumbsType == 2 ? "被扣除" : "增加"
                                    )
                                ),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.number)),
                                ]),
                                _vm._v("个赞，目前赞数为"),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.curryNumber)),
                                ]),
                                _vm._v("个。\n          "),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          items.type == 5
                            ? _c("p", { staticClass: "detail-box" }, [
                                _vm._v(
                                  "\n            Hi," +
                                    _vm._s(items.teacName) +
                                    "讲师，师资将您的等级从原来lv" +
                                    _vm._s(items.oldLevel) +
                                    "调整到lv" +
                                    _vm._s(items.curryLevel) +
                                    "，获赞值由"
                                ),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.oldNumber)),
                                ]),
                                _vm._v("个变为"),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.curryNumber)),
                                ]),
                                _vm._v("个。\n          "),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          items.type == 8
                            ? _c("p", { staticClass: "detail-box" }, [
                                _vm._v(
                                  "\n            讲师你好，你的获赞值因金秋旺季福利赞获得"
                                ),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.number)),
                                ]),
                                _vm._v("个赞，目前赞数为"),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.curryNumber)),
                                ]),
                                _vm._v("个。\n          "),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          [9, 15, 6, 17].includes(items.type)
                            ? _c(
                                "p",
                                { staticClass: "detail-box" },
                                [
                                  _vm._v(
                                    "\n            Hi," +
                                      _vm._s(items.teacName) +
                                      "讲师,\n            "
                                  ),
                                  items.thumbsType == 1
                                    ? [
                                        _vm._v("学生订单出分"),
                                        _c("b", { staticClass: "num" }, [
                                          _vm._v(_vm._s(items.score)),
                                        ]),
                                        _vm._v("达到高分标准，额外获得"),
                                        _c("b", { staticClass: "num" }, [
                                          _vm._v(_vm._s(items.number)),
                                        ]),
                                        _vm._v(
                                          "个赞啦，继续加油离升级不远啦！"
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          "因订单出分被取消，所以订单获得的"
                                        ),
                                        _c("b", { staticClass: "num" }, [
                                          _vm._v(_vm._s(items.number)),
                                        ]),
                                        _vm._v("个赞将被扣除，请悉知!"),
                                      ],
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(
                                    "\n            订单：" +
                                      _vm._s(items.orderNo) +
                                      "\n          "
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          [10].includes(items.type)
                            ? _c("p", { staticClass: "detail-box" }, [
                                _vm._v(
                                  "\n            Hi," +
                                    _vm._s(items.teacName) +
                                    "讲师," +
                                    _vm._s(
                                      items.redYm +
                                        "月" +
                                        _vm.typeList[items.readType] +
                                        "排名第" +
                                        items.rank +
                                        ",奖励"
                                    )
                                ),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.redUp || 0)),
                                ]),
                                _vm._v("个赞，继续加油离升级不远啦！"),
                                _c("br"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          [11].includes(items.type)
                            ? _c("p", { staticClass: "detail-box" }, [
                                _vm._v(
                                  "\n            Hi," +
                                    _vm._s(items.teacName) +
                                    "讲师,您30天GB产出没有50G ，根据讲师等级体系，扣除"
                                ),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.number)),
                                ]),
                                _vm._v("赞,"),
                                _vm._v("获赞值由"),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.oldNumber)),
                                ]),
                                _vm._v("个变为"),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.curryNumber)),
                                ]),
                                _vm._v(
                                  "个,等级lv" +
                                    _vm._s(items.curryLevel) +
                                    "。\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          [13].includes(items.type)
                            ? _c("p", { staticClass: "detail-box" }, [
                                _vm._v(
                                  "\n            Hi," +
                                    _vm._s(items.teacName) +
                                    "讲师,订单结束"
                                ),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v("12个月之内"),
                                ]),
                                _vm._v("可获得评价赞，您有"),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.describe) + "个订单反馈"),
                                ]),
                                _vm._v("已超12个月,将扣除"),
                                _c("b", { staticClass: "num" }, [
                                  _vm._v(_vm._s(items.number)),
                                ]),
                                _vm._v(
                                  "赞，如有疑问请联系师资处理。\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(items.createdAt))]),
                          _vm._v(" "),
                          _c("p"),
                        ])
                      : _vm._e()
                  }),
                  0
                ),
                _vm._v(" "),
                !_vm.tableDataEnd.length && !_vm.likeLoading
                  ? _c("contentnull", { attrs: { statuscode: 4 } })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.tableDataEnd.length
              ? _c("el-pagination", {
                  staticClass: "bottom-page",
                  staticStyle: { margin: "15px 10px 0 0", float: "right" },
                  attrs: {
                    "current-page": _vm.page,
                    small: "",
                    "page-sizes": [15],
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.totals,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
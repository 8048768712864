var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "medal-dialog",
          attrs: { visible: _vm.showMedal, width: "560px" },
          on: {
            "update:visible": function ($event) {
              _vm.showMedal = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "medal-img",
            attrs: { src: _vm.current.medalImage, alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "medal-name" }, [
            _vm._v(_vm._s(_vm.current.name) + "\n      "),
            _vm.current.type == 4 && _vm.current.medalNum
              ? _c("span", { staticClass: "medal-num" }, [
                  _vm._v("*" + _vm._s(_vm.current.medalNum)),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "medal-desc" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.current.name == _vm.current.title ? "" : _vm.current.title
                ) +
                "\n      "
            ),
            _c("div", { staticClass: "gettime" }, [
              _vm._v(_vm._s(_vm.current.medalExplain)),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "adjust-class-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isShow, center: "", width: "500px" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [_vm._v("调整课堂阶段")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("span", [_vm._v("请根据学生需求调整相应上课的阶段顺序")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-box" }, [
            _c(
              "div",
              {
                staticClass: "btn i-know",
                on: {
                  click: function ($event) {
                    _vm.isShow = false
                  },
                },
              },
              [_vm._v("我知道了")]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "600px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("div", { staticClass: "title-box" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("论文评级")]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n                请针对当前阶段需求,给出你客观的评价("),
              _c("span", [_vm._v("提交后不能修改")]),
              _vm._v(")\n            "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeType },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("批改论文评级"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: "2" } }, [
                        _vm._v("终极论文评级"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasRate < 1 && _vm.form.type == 2
                ? _c("div", { staticClass: "not-rate" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/login-tips.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "请先完成批改论文评级后，方可进入终版论文评级阶段"
                      ),
                    ]),
                  ])
                : [
                    _c("div", { staticClass: "rules" }, [
                      _vm._v(_vm._s(_vm.curRule)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "level-box" },
                      _vm._l(_vm.levelList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            on: {
                              click: function ($event) {
                                return _vm.chooseLevel(item, index)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "cursor",
                              attrs: {
                                src:
                                  _vm.form.result == item.result
                                    ? item.yesimg
                                    : item.noimg,
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass: "level",
                                style: {
                                  color: index >= 2 ? "red" : "#29AD6F",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(item.level)
                                ),
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: index >= 2 ? "red" : "#29AD6F",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.score))]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    ["C", "D"].includes(_vm.form.result) && _vm.form.type == 1
                      ? _c("p", { staticClass: "memo" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.form.result == "C"
                                  ? "※存在Fail风险"
                                  : "※评级为D论文将Fail"
                              ) +
                              ",请您"
                          ),
                          _c("span", [_vm._v("安排批改课")]),
                          _vm._v("且"),
                          _c("span", [_vm._v("认真批改每一处问题")]),
                          _vm._v(
                            "，否则挂科将判定责任人；\n                    若讲师为责任方将扣除讲师"
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.failGb) + "G")]),
                          _vm.form.result == "D"
                            ? _c(
                                "span",
                                { staticStyle: { color: "#222222" } },
                                [_vm._v("，如遇问题请及时联系师资处理")]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    ["C", "D"].includes(_vm.form.result) && _vm.form.type == 2
                      ? _c("p", { staticClass: "memo" }, [
                          _vm.form.result == "C"
                            ? _c(
                                "span",
                                { staticStyle: { color: "#222222" } },
                                [
                                  _vm._v("※存在"),
                                  _c("span", [_vm._v("Fail")]),
                                  _vm._v("风险"),
                                ]
                              )
                            : _vm._e(),
                          _vm.form.result == "D"
                            ? _c(
                                "span",
                                { staticStyle: { color: "#222222" } },
                                [_vm._v("※论文"), _c("span", [_vm._v("Fail")])]
                              )
                            : _vm._e(),
                          _vm._v(",请讲师确定"),
                          _c("span", [_vm._v("论文已无法进行任何批改")]),
                          _vm._v("且"),
                          _c("span", [_vm._v("已完成批改课")]),
                          _vm._v(
                            "；请" +
                              _vm._s(_vm.form.result == "C" ? "务必" : "立即") +
                              "向师资管理人员申报，否则学生挂科将预计扣除讲师"
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.failGb) + "G")]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.form,
                              "label-width": "100px",
                              rules: _vm.isPermision ? _vm.rules : {},
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "论文评语：", prop: "comment" },
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    _vm._v(
                                      "\n                            请客观根据学生实际情况评级，若评级与实际分数不符，则有薪资扣除风险\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticStyle: {
                                    width: "350px",
                                    "margin-top": "-20px",
                                  },
                                  attrs: {
                                    type: "textarea",
                                    readonly:
                                      _vm.form.readOnly || !_vm.isPermision,
                                    placeholder: "请评价",
                                  },
                                  model: {
                                    value: _vm.form.comment,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "comment", $$v)
                                    },
                                    expression: "form.comment",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.form.type == 2
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "评价说明：" } },
                                  [
                                    _c(
                                      "p",
                                      { staticStyle: { "font-size": "12px" } },
                                      [
                                        _vm._v(
                                          "\n                                若论文存在挂科风险，请将详细情况描述给师资\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "350px",
                                        "margin-top": "-20px",
                                      },
                                      attrs: {
                                        type: "textarea",
                                        readonly:
                                          _vm.form.readOnly || !_vm.isPermision,
                                        placeholder: "请评价",
                                      },
                                      model: {
                                        value: _vm.form.explain,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "explain", $$v)
                                        },
                                        expression: "form.explain",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          !_vm.form.readOnly && _vm.isPermision
            ? _c(
                "div",
                {
                  staticClass: "footer-btn",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "cancle-btn",
                      attrs: { size: "mini" },
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "confirm-btn",
                      attrs: { size: "mini", loading: _vm.loading },
                      on: { click: _vm.confirmRating },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "paper-dialog",
      attrs: {
        visible: _vm.isShow,
        width: "700px",
        "append-to-body": true,
        "before-close": _vm.closeModal,
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.current.orderNo))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "log" },
        [
          _c(
            "el-timeline",
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "el-timeline-item",
                {
                  key: index,
                  attrs: {
                    timestamp: item.updateAt,
                    placement: "top",
                    color: "#333333",
                  },
                },
                [
                  _c("div", { staticClass: "time-content" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.typeList[item.type])),
                    ]),
                    _vm._v(" "),
                    item.type == 1
                      ? _c("div", [
                          _vm._v(
                            "\n            扣除：" +
                              _vm._s(item.subGb || 0) +
                              "G "
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "20px" } },
                            [
                              _vm._v(
                                "原因：" +
                                  _vm._s(_vm.reasonList[item.reasonType])
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 2
                      ? _c(
                          "div",
                          _vm._l(item.uploadFiles, function (file, _index) {
                            return _c(
                              "div",
                              {
                                key: _index,
                                staticClass: "link",
                                on: {
                                  click: function ($event) {
                                    return _vm.openlink(file.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(file.name))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 3
                      ? _c(
                          "div",
                          _vm._l(item.appealFiles, function (file, _index) {
                            return _c(
                              "div",
                              {
                                key: _index,
                                staticClass: "link",
                                on: {
                                  click: function ($event) {
                                    return _vm.openlink(file.url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(file.name))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.type == 4
                      ? _c("div", { staticClass: "result" }, [
                          _c("div", [
                            _vm._v(
                              "订单薪资：" +
                                _vm._s(
                                  [1, 2].includes(Number(item.reasonType))
                                    ? item.courseIncomeGb
                                    : item.classIncomeGb
                                ) +
                                "G "
                            ),
                            _c("span", [
                              _vm._v("扣除：" + _vm._s(item.subGb) + "G"),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "薪资返回：" + _vm._s(item.refundGb) + "G"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "实际获得：" + _vm._s(item.realIncomeGb) + "G"
                            ),
                            _c("span", [
                              _vm._v("备注:" + _vm._s(item.decisionReason)),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
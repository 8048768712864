import { render, staticRenderFns } from "./changeModal.vue?vue&type=template&id=33dcdc51&scoped=true"
import script from "./changeModal.vue?vue&type=script&lang=js"
export * from "./changeModal.vue?vue&type=script&lang=js"
import style0 from "./changeModal.vue?vue&type=style&index=0&id=33dcdc51&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33dcdc51",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33dcdc51')) {
      api.createRecord('33dcdc51', component.options)
    } else {
      api.reload('33dcdc51', component.options)
    }
    module.hot.accept("./changeModal.vue?vue&type=template&id=33dcdc51&scoped=true", function () {
      api.rerender('33dcdc51', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/schooltimetable/changeModal.vue"
export default component.exports
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "698px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", { staticClass: "title" }, [_vm._v("功能更新通知")]),
            _vm._v(" "),
            _c("div", { staticClass: "text" }, [
              _c("div", { staticClass: "first" }, [
                _vm._v(
                  "为了更好地匹配各位讲师的成长历程，我们对讲师勋章功能进行了优化："
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("1.更多更丰富的勋章。共计"),
                _c("span", [_vm._v("21")]),
                _vm._v("款勋章，更多挑战等待你的解锁！"),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("2.新增"),
                _c("span", [_vm._v("头像框")]),
                _vm._v("功能。4款"),
                _c("span", [_vm._v("特别成就勋章")]),
                _vm._v("解锁时附赠精美头像框，快去挑战吧。"),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("3.勋章现在会展示在"),
                _c("span", [_vm._v("数据排行榜")]),
                _vm._v("中，把成就show给其他讲师！"),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("4.优化了勋章分类。")]),
              _vm._v(" "),
              _c("div", [_vm._v("5.优化了一系列勋章相关的按钮和弹框。")]),
              _vm._v(" "),
              _c("div", [
                _vm._v("快去"),
                _c("span", [_vm._v("勋章墙")]),
                _vm._v("，挑选您希望展示的勋章吧～"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn", on: { click: _vm.goToMedalWall } },
              [_vm._v("点击进入勋章墙")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notice" },
    [
      _c("div", { staticClass: "notice-title" }, [
        _vm._v("CLASSBRO 讲师团队官方申明"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notice-content" },
        [
          _c(
            "div",
            {
              staticClass: "sec-title",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.showNotice },
            },
            [
              _vm._v("订单沟通群组迁移通知 "),
              _c("span", { staticClass: "new" }, [_vm._v("new")]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sec-title" }, [
            _vm._v("降级&解约机制通知"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.noticeList, function (item, index) {
            return _c("div", { key: index }, [
              _c("span", [_vm._v(_vm._s(item))]),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("chatNotify", { ref: "chatNotify" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
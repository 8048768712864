"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _chatNotify = _interopRequireDefault(require("@/views/account/newHome/chatNotify"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    chatNotify: _chatNotify.default
  },
  data: function data() {
    return {
      noticeList: ["1. 若讲师当前投诉率大于等级标准线，讲师等级自动下降；", "2. 若讲师连续30天内的累积产出未达到50G，讲师等级自动下降；", "3. 若讲师连续60天内的累积产出未达到50G，视作解约处理；", "4. 若讲师投诉率大于10%，将做解约处理。"]
    };
  },
  methods: {
    showNotice: function showNotice() {
      this.$refs.chatNotify.show(1);
    }
  }
};
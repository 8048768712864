var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.sellerDetailWy,
          expression: "sellerDetailWy",
        },
      ],
      staticClass: "sellerDetail",
    },
    [
      _c(
        "el-row",
        { staticStyle: { margin: "0" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { id: "leftdiv", span: 12 } }, [
            _c("div", { staticClass: "grid-content bg-purple" }, [
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("订单号:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.order_name))]),
              ]),
              _vm._v(" "),
              _vm.studPurchaseOrderType == 66
                ? _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("订单子类型:"),
                    ]),
                    _vm._v(" "),
                    _vm.studSpecialOrderExt.specialOrderSonType == 1
                      ? _c("span", [_vm._v("资料整理")])
                      : _vm.studSpecialOrderExt.specialOrderSonType == 2
                      ? _c("span", [_vm._v("Appeal Letter")])
                      : _vm.studSpecialOrderExt.specialOrderSonType == 4
                      ? _c("span", [_vm._v("作业订单")])
                      : _vm.studSpecialOrderExt.specialOrderSonType == 8
                      ? _c("span", [_vm._v("论文批改")])
                      : _vm.studSpecialOrderExt.specialOrderSonType == 16
                      ? _c("span", [_vm._v("Proofreading")])
                      : _vm.studSpecialOrderExt.specialOrderSonType == 32
                      ? _c("span", [_vm._v("Past-paper")])
                      : _vm.studSpecialOrderExt.specialOrderSonType == 64
                      ? _c("span", [_vm._v("论文修改")])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("课程代码:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.course_code))]),
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { "border-top": "1px dashed #409EFF" } }),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("字数:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.word_count) + " 字")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("订单pass分 :")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.passScore))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("Penalty Rate（%）：" + _vm._s(_vm.penaltyRate || 0)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("Deadline:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.deadline))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c(
                  "span",
                  { staticStyle: { color: "red", "font-size": "14px" } },
                  [
                    _vm._v(
                      "\n                        请讲师严格执行此时间,否则会有额外薪资处罚"
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.wyorder.order_type == 8
                ? _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("考试开始时间:"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.wyorder.opening_time_from))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.wyorder.order_type == 8
                ? _c("div", { staticClass: "grid-content bg-purple" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("考试结束时间:"),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.wyorder.opening_time_to))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticStyle: { "border-top": "1px dashed #409EFF" } }),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("讲师费用(G):")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.moon) + "G")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("辅导需求:")]),
                _vm._v(" "),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.wyorder.client_message) },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("Reference Style:"),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.reference_style))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("Reference Guide:"),
                ]),
                _vm._v(" "),
                _vm.wyorder.reference_guide != null
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#408EF4",
                          "font-weight": "600",
                          cursor: "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.downFile(
                              _vm.wyorder.reference_guide.file_path
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.wyorder.reference_guide.real_name))]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("学校名称:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.university))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("学校网址:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.university_url))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("学校系统账号:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.blackboard_id))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "grid-content bg-purple" }, [
                _c("span", { staticClass: "label" }, [_vm._v("学校系统密码:")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.wyorder.password))]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "grid-content bg-purple" }, [
              _c("p", { staticClass: "title" }, [_vm._v("课程流水")]),
              _vm._v(" "),
              _c(
                "ul",
                {
                  staticStyle: {
                    "min-height": "443px",
                    "max-height": "443px",
                    "overflow-y": "scroll",
                  },
                  attrs: { id: "logs" },
                },
                _vm._l(_vm.wylog, function (item, index) {
                  return _c("li", { key: index, staticClass: "clears" }, [
                    _c("i", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(_vm._s(item.created_at)),
                    ]),
                    _c("i", [
                      _vm._v(
                        _vm._s(item.name) +
                          " " +
                          _vm._s(item.username) +
                          "\n                            " +
                          _vm._s(item.info) +
                          "\n                        "
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            background: "#fff",
            padding: "10px",
            margin: "8px 10px",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "20px",
                border: "1px solid #ccc",
                "margin-top": "20px",
              },
            },
            [
              _c("p", { staticClass: "title" }, [_vm._v("课件信息")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("p", [_vm._v("lecture_notes：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.lecture_notes,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("original_work：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.original_work,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("other_materials：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.other_materials,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("personal_information：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.personal_information,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("requirement：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.requirement,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("samples：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.samples,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("suggested_readings：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.suggested_readings,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("unit_guide：")]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.wyorder.upload.unit_guide,
                    function (item, key, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "p",
                          {
                            key: index,
                            staticStyle: {
                              cursor: "pointer",
                              color: "#408EF4",
                              "font-weight": "600",
                              "padding-left": "10px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.down(item.file_path, item.real_name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(item.real_name) +
                                "\n                        "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#333",
                                  "font-weight": "400",
                                  "padding-left": "5px",
                                },
                              },
                              [_vm._v(_vm._s(item.created_at))]
                            ),
                          ]
                        ),
                      ])
                    }
                  ),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                padding: "20px",
                border: "1px solid #ccc",
                "margin-top": "20px",
              },
            },
            [
              _c("p", { staticClass: "title" }, [_vm._v("Dropbox")]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.dropboxList, function (item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "p",
                      {
                        key: index,
                        staticStyle: {
                          cursor: "pointer",
                          color: "#408EF4",
                          "font-weight": "600",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.downDropbox(item.url, item.name)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(item.name) +
                            "\n                        "
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#333",
                              "font-weight": "400",
                              "padding-left": "5px",
                            },
                          },
                          [_vm._v(_vm._s(item.createAt))]
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "课堂记录",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.history, function (item, $index) {
            return _c("div", { key: $index, staticClass: "text item" }, [
              _c("div", [
                _vm._v(
                  "\n                " +
                    _vm._s(item.suerrole) +
                    " " +
                    _vm._s(item.nickname) +
                    "\n                " +
                    _vm._s(item.joinTime) +
                    " 进入了课堂\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n                " +
                    _vm._s(item.suerrole) +
                    " " +
                    _vm._s(item.nickname) +
                    "\n                " +
                    _vm._s(item.leaveTime) +
                    " 离开了课堂\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", [_vm._v("持续了 " + _vm._s(item.duration) + " 分钟")]),
            ])
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", {
        staticStyle: {
          background: "#fff",
          padding: "10px",
          margin: "8px 10px",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "list-title" }, [
      _vm._v("\n    当月榜单奖励\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.rewardList, function (item, index) {
        return _c("div", { key: index, staticClass: "list-item" }, [
          _c("span", [_vm._v(_vm._s("No." + (index + 1)))]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                item.bonus && item.upNum
                  ? item.bonus + "G / " + item.upNum + "赞"
                  : item.bonus
                  ? item.bonus + "G"
                  : item.upNum
                  ? item.upNum + "赞"
                  : ""
              )
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.modalShow,
          expression: "modalShow",
        },
      ],
      staticClass: "intentionmodal-container",
    },
    [
      _c("div", { attrs: { id: "intention" } }),
      _vm._v(" "),
      _c("div", { staticClass: "hover-box", on: { click: _vm.openLink } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
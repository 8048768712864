var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.redbagData.show
    ? _c("div", { staticClass: "new-mask" }, [
        _c("div", { staticClass: "redbag-box" }, [
          _c("div", {
            staticClass: "close",
            on: { click: _vm.redbagStatusChange },
          }),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src:
                _vm.redbagData.status == 0
                  ? _vm.redbagData.imgclose
                  : _vm.redbagData.imgopen,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "redbag-text" },
            [
              _vm.redbagData.status == 0
                ? _c("button", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/redbag-btn.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.OpenRedbag(0)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  class:
                    _vm.redbagData.status == 1
                      ? "redbag-common-open"
                      : "redbag-common-close",
                },
                [
                  _c("b", [_vm._v("万能讲师奖励包")]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.current.redEnvelope) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.redbagData.status == 1
                ? [
                    _c("p", { staticClass: "num" }, [
                      _c("b", [_vm._v(_vm._s(_vm.current.num))]),
                      _vm._v(_vm._s(_vm.current.childType == 1 ? "GB" : "赞")),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "sort" }, [
                      _vm._v(
                        _vm._s(_vm.redbagtypeText[_vm.current.readType]) +
                          "No." +
                          _vm._s(_vm.current.level)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "tips" }, [
                      _vm._v(
                        _vm._s(
                          _vm.current.childType == 1
                            ? "已存入当月收入，可随下月工资一同提现"
                            : "已累加到获赞值"
                        )
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
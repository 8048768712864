"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    item: {
      type: Object,
      default: function _default() {}
    },
    showNum: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    operateMedal: function operateMedal(item) {
      this.$parent.operateMedal(item);
    },
    seeMedal: function seeMedal(item) {
      this.$parent.seeMedal(item);
    }
  }
};
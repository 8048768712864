var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "first-title", attrs: { id: "1.3" } }, [
        _vm._v(" 3.万能讲师课堂操作指南"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "guide-content" }, [
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.2" } }, [
          _vm._v("课程大纲"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("打开大纲后，可整体预览所有页面也可以选择相应页面进行跳转"),
        ]),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: require("@/assets/images/help/follow/dagang.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.3" } }, [
          _vm._v("共享屏幕"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("打开工具后，进入桌面共享可以选择区域窗口共享")]),
        _vm._v(" "),
        _c("img", {
          attrs: {
            src: require("@/assets/images/help/follow/gongxiang.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/gongxiang2.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.4" } }, [
          _vm._v("手写板"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "打开工具后，点击【pad手写板】浏览器扫码即可在pad上实现手写功能"
          ),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/shouxieban.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.5" } }, [
          _vm._v("虚拟背景"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("在美颜设置中，打开【幕布】即可选择相应虚拟背景")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/xunibeijing.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.6" } }, [
          _vm._v("下课"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("在教室正上方，点击【下课】按钮即可下课")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/xiake.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.7" } }, [
          _vm._v("镜像摄像头"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("进入设备测试后，打开摄像头镜像开关，点击【关闭】键"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/jingxiang.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.8" } }, [
          _vm._v("教室背景更换"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("进入设置后，选择白板背景与白板网格，点击保存配置")]),
        _vm._v(" "),
        _c("div", { staticClass: "font-red " }, [_vm._v("注意事项：")]),
        _vm._v(" "),
        _c("div", [_vm._v("修改后仅对本节课生效")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/beijing.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.9" } }, [
          _vm._v("教室功能按键介绍"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("教室功能按键使用说明")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/gongneng.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.10" } }, [
          _vm._v("教学工具介绍"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("教室工具使用说明")]),
        _vm._v(" "),
        _c("div", { staticClass: "font-red " }, [_vm._v("注意事项：")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "激光笔功能点击鼠标左键并移动可以保留痕迹，若抬起鼠标左键则痕迹消失"
          ),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/gongju.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.11" } }, [
          _vm._v("教室设置介绍"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("教室设置按键说明 ")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/jiaoshi.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.12" } }, [
          _vm._v("美颜设置"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "进入美颜设置后，打开美颜功能开关，选择需要的（美颜\\美型\\滤镜\\幕布）功能，点击【关闭】键"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "font-red " }, [_vm._v("注意事项：")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "点击【重置】键可以重置（美颜\\美型\\滤镜）功能分别变为默认状态"
          ),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("默认状态：美颜——清晰；美型——默认；滤镜——原图")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/meiyan.png"),
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "second-title", attrs: { id: "1.3.13" } }, [
          _vm._v("学生点赞"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "老师可以提醒同学，在【学生客户端】内点击【评价】并进行相应操作"
          ),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "mt",
          attrs: {
            src: require("@/assets/images/help/follow/dianzan.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./StudentNeed.vue?vue&type=template&id=8737038c&scoped=true"
import script from "./StudentNeed.vue?vue&type=script&lang=js"
export * from "./StudentNeed.vue?vue&type=script&lang=js"
import style0 from "./StudentNeed.vue?vue&type=style&index=0&id=8737038c&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8737038c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8737038c')) {
      api.createRecord('8737038c', component.options)
    } else {
      api.reload('8737038c', component.options)
    }
    module.hot.accept("./StudentNeed.vue?vue&type=template&id=8737038c&scoped=true", function () {
      api.rerender('8737038c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/paperchedule/StudentNeed.vue"
export default component.exports
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "test-boxs paper-dialog",
      attrs: { center: "", width: "560px", visible: _vm.datas.show },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.datas, "show", $event)
        },
      },
    },
    [
      _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.datas.title))]),
      _vm._v(" "),
      _vm.datas.text1
        ? _c("p", [
            _vm._v(_vm._s(_vm.datas.text1) + " "),
            _vm.datas.textWeight
              ? _c("b", [_vm._v("【" + _vm._s(_vm.datas.textWeight) + "】")])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.datas.text2
        ? _c("p", {
            staticClass: "two-box",
            domProps: { innerHTML: _vm._s(_vm.datas.text2) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.datas.imgSrc
        ? _c("img", { attrs: { src: _vm.datas.imgSrc, alt: "" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "footer-btn",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.type == 1
            ? [
                _c(
                  "span",
                  { staticClass: "cancle-btn", on: { click: _vm.closeModal } },
                  [_vm._v("我再看看")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "confirm-btn",
                    on: { click: _vm.receivOrder },
                  },
                  [_vm._v("确定接单")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type == 2
            ? [
                _c(
                  "span",
                  { staticClass: "cancle-btn", on: { click: _vm.closeModal } },
                  [_vm._v("我知道了")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("订单需求")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detial-msg-box detial-msg-height" },
      [
        _vm.orderMagData.spoType == 67
          ? [
              _vm.orderMagData.change && !_vm.needsIsString
                ? [
                    _c("p", [
                      _c("label", [_vm._v("学生对作业的了解程度")]),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.counselingNeeds &&
                              _vm.counselingNeeds.find(function (v) {
                                return v.value == 1
                              }).answer
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("label", [_vm._v("学生的订单需求")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.counselingNeeds &&
                                _vm.counselingNeeds.find(function (v) {
                                  return v.value == 2
                                }).answer
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _c("label", [_vm._v("学生的写作方向(Topic)")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.orderMagData &&
                                _vm.orderMagData.bigPaperChangeOrder &&
                                _vm.orderMagData.bigPaperChangeOrder
                                  .needTopic != 1
                                ? _vm.orderMagData.bigPaperChangeOrder
                                    .topicDirect
                                : _vm.orderMagData.bigPaperChangeOrder
                                    .topicThinkDirect
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.orderMagData.change
                ? [
                    _vm.orderMagData.bigPaperDrainageOrder
                      ? [
                          _vm._m(0),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.orderMagData.bigPaperDrainageOrder
                                  .topicDemand
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p"),
                          _vm._v(" "),
                          _vm._m(1),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.orderMagData.bigPaperDrainageOrder
                                  .paperAnswerDemand ||
                                  _vm.orderMagData.bigPaperDrainageOrder
                                    .topicAnswerDemand
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p"),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(2),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.orderMagData.bigPaperDrainageOrder
                            .topicThinkDirect
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p"),
                  ]
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.specialOfferSysUser
          ? _c("p", [
              _c("label", [_vm._v("报价人")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.specialOfferSysUser.username))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.afterSaleRemark
          ? [
              _vm._l(_vm.afterSaleRemark, function (item, index) {
                return _c("p", { key: index }, [
                  _c("label", [_vm._v(_vm._s(item.question))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticStyle: { color: "#FF9900", "font-weight": "600" } },
                    [_vm._v(_vm._s(item.answer))]
                  ),
                ])
              }),
              _vm._v(" "),
              _c("hr"),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.orderMagData.spoType == 66
          ? [
              [2, 130].includes(_vm.specialChildType)
                ? _c("p", [
                    _c("label", [_vm._v("客户信息表:")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#1993fa" },
                        attrs: {
                          href: _vm.orderMagData.studPurchaseOrder
                            .studSpecialOrderExt.customerInfoExcelUrl
                            ? _vm.orderMagData.studPurchaseOrder
                                .studSpecialOrderExt.customerInfoExcelUrl
                            : _vm.orderMagData.studPurchaseOrder
                                .studSpecialOrderExt.appealFeedbackUrl,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.orderMagData.studPurchaseOrder
                              .studSpecialOrderExt.customerInfoExcelName
                              ? _vm.orderMagData.studPurchaseOrder
                                  .studSpecialOrderExt.customerInfoExcelName
                              : _vm.orderMagData.studPurchaseOrder
                                  .studSpecialOrderExt.appealFeedbackFileName
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.specialChildType == 1
                ? _c("p", [
                    _c("label", [_vm._v("客户希望复习资料类型")]),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.reviewMaterialType[
                            _vm.orderMagData.studPurchaseOrder
                              .studSpecialOrderExt.reviewMaterialType
                          ]
                        ) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.specialChildType == 129
                ? _c("p", [
                    _c("label", [_vm._v("Original Work")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "file-box" },
                      _vm._l(_vm.originalWork, function (files, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "file-wrap" },
                          [
                            _vm._v("\n              " + _vm._s(files.name)),
                            _c("br"),
                            _vm._v(" "),
                            _c("i", [_vm._v(_vm._s(files.date))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.specialChildType == 130
                ? [
                    _c("p", [
                      _c("label", [_vm._v("CV")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "file-box" },
                        _vm._l(_vm.cvoriginalWork, function (files, index) {
                          return _c(
                            "span",
                            {
                              key: index,
                              staticClass: "file-wrap",
                              on: {
                                click: function ($event) {
                                  return _vm.openUrl(files.url)
                                },
                              },
                            },
                            [
                              _vm._v("\n                " + _vm._s(files.name)),
                              _c("br"),
                              _vm._v(" "),
                              _c("i", [_vm._v(_vm._s(files.date))]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.afterSaleRemark
          ? _vm._l(_vm.afterSaleRemark, function (item, index) {
              return _c("p", { key: index }, [
                _c("label", [_vm._v(_vm._s(item.question))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.answer))]),
              ])
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.orderMagData.spoType == 67
          ? _c("p", [
              _c(
                "label",
                {
                  staticStyle: { float: "left" },
                  style: { width: _vm.orderMagData.change == 1 ? "145px" : "" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.orderMagData.change == 1
                        ? "学生近期自主完成的essay:"
                        : "学生文件:"
                    )
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._l(_vm.studentEssayFile, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "needs-boxlist",
              staticStyle: { color: "#3496FB" },
              on: {
                click: function ($event) {
                  return _vm.openurl(item)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        _vm._v(" "),
        _vm.orderMagData.spoType == 0 && _vm.studCourseCustomized
          ? [
              _vm.studCourseCustomized.orderType
                ? _c("div", { staticClass: "needs-boxlist" }, [
                    _c("label", [_vm._v("订单类型")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.studCourseCustomized.orderType == 1
                            ? "定制化需求"
                            : "长期需求"
                        ) + " "
                      ),
                      _vm.studCourseCustomized.coverTutoring == 1
                        ? _c("span", { staticClass: "red-text" }, [
                            _vm._v("(需要覆盖考核项目)"),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "needs-boxlist half-line" }, [
                _c("label", [_vm._v("授课范围")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "From【week" +
                      _vm._s(_vm.studCourseCustomized.teachWeekFrom) +
                      "】To【week" +
                      _vm._s(_vm.studCourseCustomized.teachWeekTo) +
                      "】"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "needs-boxlist half-line" }, [
                _c("label", [_vm._v("学生当前进度")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "学生当前课程进度week【" +
                      _vm._s(_vm.studCourseCustomized.currentCourseWeek) +
                      "】"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "needs-boxlist half-line" }, [
                _c("label", [_vm._v("学生的可排课时间")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.studCourseCustomized.schedulableTime)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "needs-boxlist half-line" }, [
                _c("label", [_vm._v("学生希望的排课频次")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.studCourseCustomized.schedulingFrequency)),
                ]),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        [
          !this.needsIsString ? _c("label", [_vm._v("辅导需求")]) : _vm._e(),
          _vm._v(" "),
          this.needsIsString &&
          _vm.counselingNeeds.length <= 1 &&
          _vm.orderMagData.spoType != 71
            ? _c("label", [_vm._v("学生订单需求是")])
            : _vm._e(),
          _vm._v(" "),
          this.needsIsString
            ? _c(
                "div",
                { staticStyle: { "max-width": "100%" } },
                _vm._l(_vm.counselingNeeds, function (needs, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass: "needs-boxlist",
                      class: {
                        "space-break":
                          needs.question != "学生对课程的了解程度（0-10）：",
                        half: [
                          "学生目前作业进度",
                          "学生需要怎样的辅导",
                        ].includes(needs.question),
                      },
                    },
                    [
                      (
                        ["Past Paper是否有答案："].includes(needs.question)
                          ? _vm.counselingNeeds.filter(function (i) {
                              return i.question == "是否提供Past Paper："
                            })[0].answer == 1
                          : true
                      )
                        ? [
                            _c("label", [_vm._v(_vm._s(needs.question))]),
                            _vm._v(" "),
                            needs.question != "学生对课程的了解程度（0-10）："
                              ? _c(
                                  "span",
                                  [
                                    [
                                      "学生能力基础：",
                                      "所处考试阶段：",
                                      "是否提供Past Paper：",
                                      "Past Paper是否有答案：",
                                    ].includes(needs.question)
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              needs.answer || needs.answer === 0
                                                ? (
                                                    JSON.parse(
                                                      needs.select
                                                    ).find(function (i) {
                                                      return (
                                                        i.value == +needs.answer
                                                      )
                                                    }) || {}
                                                  ).name
                                                : ""
                                            )
                                          ),
                                        ]
                                      : ["考试形式："].includes(needs.question)
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              _vm.dealExamStyle(
                                                needs.answer,
                                                JSON.parse(needs.select)
                                              )
                                            )
                                          ),
                                        ]
                                      : ["学生所在时区："].includes(
                                          needs.question
                                        )
                                      ? [
                                          _vm._v(
                                            _vm._s(
                                              needs.answer &&
                                                _vm.timeZoneList.length > 0 &&
                                                _vm.timeZoneList.filter(
                                                  function (i) {
                                                    return i.id == +needs.answer
                                                  }
                                                )[0].name
                                            )
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(needs.answer) +
                                              _vm._s(
                                                ["学生目前作业进度"].includes(
                                                  needs.question
                                                ) && needs.answer == "进行中"
                                                  ? " （进度：" +
                                                      needs.progress +
                                                      "%）"
                                                  : ""
                                              ) +
                                              "\n                "
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  { staticClass: "deg-wrap" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "deg-box",
                                        style: {
                                          left: needs.answer * 10 - 9 + "%",
                                        },
                                      },
                                      [_vm._v(_vm._s(needs.answer))]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.nums, function (num, indexs) {
                                      return _c("span", {
                                        key: indexs,
                                        style: {
                                          opacity:
                                            indexs < needs.answer
                                              ? (indexs + 1) *
                                                (needs.answer / 10)
                                              : "1",
                                          background:
                                            indexs < needs.answer
                                              ? "rgba(255, 135, 35, 1)"
                                              : "",
                                          border:
                                            indexs < needs.answer ? 0 : "",
                                        },
                                      })
                                    }),
                                    _vm._v(" "),
                                    _vm._m(3, true),
                                  ],
                                  2
                                ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              )
            : _c(
                "b",
                {
                  staticStyle: {
                    "font-weight": "normal",
                    float: "left",
                    "max-width": "100%",
                    "margin-top": "20px",
                  },
                },
                [_vm._v(_vm._s(_vm.counselingNeeds))]
              ),
        ],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("label", [_vm._v("学生的定题需求：")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("label", [_vm._v("学生的答疑需求：")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
      _c("label", { staticStyle: { width: "175px" } }, [
        _vm._v("学生感兴趣的写作(topic)方向："),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("没有任何了解")]),
      _c("b", [_vm._v("十分了解")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
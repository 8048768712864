var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "month-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "984px",
            "show-close": false,
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "header" }, [
            _vm._v("\n      添加 订单运营老师 微信\n      "),
            _c("p", [
              _vm._v("请"),
              _c("span", [_vm._v("务必")]),
              _vm._v("添加满以下三位【订单运营老师】微信"),
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [_vm._v("三位老师会将合适的订单推送给你，")]),
              _vm._v("请务必每位都添加，否则你将失去部分订单资源\n      "),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            _vm._l(_vm.wxList, function (item) {
              return _c("img", {
                staticStyle: { flex: "1", width: "302px", height: "auto" },
                attrs: { src: item, alt: "" },
              })
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "operate-btn ",
              class: { active: _vm.time <= 0 },
              on: { click: _vm.Added },
            },
            [
              _vm._v("\n      我已添加"),
              _vm.time > 0
                ? _c("span", [_vm._v("(" + _vm._s(_vm.time) + "s)")])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
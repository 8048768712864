var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "560px",
            "close-on-click-modal": false,
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("高分标准")]),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-content" }, [
            _c("div", [
              _vm._v("订单出分达到"),
              _c("span", [_vm._v("高分1档，奖励10%")]),
              _vm._v("订单已获G"),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("订单出分达到"),
              _c("span", [_vm._v("高分2档，奖励20%")]),
              _vm._v("订单已获G"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                "header-cell-style": {
                  background: "#FAFAFA",
                  color: "#2C2D2F",
                  height: "40px",
                  padding: 0,
                },
                "row-style": { height: "40px", padding: 0 },
                "cell-style": { padding: 0 },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "countryName", label: "国家" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "highScore", label: "高分1档" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.highScore && scope.row.highScore.length
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getScore(scope.row.highScore, 0)) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "highScore", label: "高分2档" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.highScore && scope.row.highScore.length > 1
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.getScore(scope.row.highScore, 1)) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isShow: function isShow(val) {
      var _this = this;
      if (val) {
        var timmer = setInterval(function () {
          _this.time--;
          if (_this.time <= 0) {
            clearInterval(timmer);
          }
        }, 1000);
      }
    }
  },
  data: function data() {
    return {
      time: 20,
      wxList: ["http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource%2F20200211%2FLris%402x.png", "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource%2F20200211%2FNina%402x.png", "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource%2F20200211%2FCC%402x.png"]
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit("closeAdd");
      this.time = 20;
    },
    Added: function Added() {
      if (this.time <= 0) {
        this.handleClose();
      }
    }
  }
};
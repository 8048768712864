import { render, staticRenderFns } from "./awardList.vue?vue&type=template&id=5d41c09f&scoped=true"
import script from "./awardList.vue?vue&type=script&lang=js"
export * from "./awardList.vue?vue&type=script&lang=js"
import style0 from "./awardList.vue?vue&type=style&index=0&id=5d41c09f&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d41c09f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d41c09f')) {
      api.createRecord('5d41c09f', component.options)
    } else {
      api.reload('5d41c09f', component.options)
    }
    module.hot.accept("./awardList.vue?vue&type=template&id=5d41c09f&scoped=true", function () {
      api.rerender('5d41c09f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account/newHome/awardList.vue"
export default component.exports
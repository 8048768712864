"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("../css/conmmon.scss");
var _orderdetialVEnum = require("@/utils/orderdetialVEnum");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["orderMagData", "childtype"],
  data: function data() {
    return {
      OrderStatus: _orderdetialVEnum.OrderStatus,
      needsIsString: false,
      specialChildType: "",
      specialOfferSysUser: "",
      reviewMaterialType: {
        1: '覆盖全部lecture',
        2: '考点/重点优先，突击为主',
        4: '越细越好，希望拓展'
      },
      specilTypeArr: {
        1: "资料整理",
        2: "Appeal Letter",
        4: "作业订单",
        8: "论文批改",
        16: "Proofreading",
        32: "Past-paper",
        64: "论文修改",
        128: "考试订单",
        129: "Translation",
        130: 'Personal Statement'
      },
      studentEssayFile: [],
      studSpecialOrderExt: {},
      afterSaleRemark: ''
    };
  },
  created: function created() {
    this.handNeeds();
  },
  computed: {
    //是否是是自己负责的单子
    isMy: function isMy() {
      return this.orderMagData.isMy;
    }
  },
  methods: {
    handNeeds: function handNeeds() {
      this.afterSaleRemark = this.orderMagData.afterSaleRemark ? JSON.parse(this.orderMagData.afterSaleRemark) : null;
      if (this.orderMagData.spoType == 66) {
        this.specialChildType = this.orderMagData.studPurchaseOrder.studSpecialOrderExt.type;
      }
      if ([65, 67, 71].includes(Number(this.orderMagData.spoType))) {
        if (this.orderMagData.studPurchaseOrder.theSpecialOffer) {
          this.specialOfferSysUser = this.orderMagData.studPurchaseOrder.specialOfferSysUser;
        }
      }
      // 显示essay
      if (this.orderMagData.spoType == 67) {
        if (this.orderMagData) {
          this.studentEssayFile = this.orderMagData.change == 0 ? this.orderMagData.bigPaperDrainageOrder && this.orderMagData.bigPaperDrainageOrder.studentFile : this.orderMagData.bigPaperChangeOrder && this.orderMagData.bigPaperChangeOrder.studentEssayFile || [];
        }
      }
      //辅导需求处理
      if (this.orderMagData.studPurchaseOrder.orderQuestions) {
        this.needsIsString = true;
        this.counselingNeeds = this.orderMagData.studPurchaseOrder.orderQuestions;
      } else {
        if (this.orderMagData.spoType === 66 || this.orderMagData.spoType === 64 || this.orderMagData.spoType === 32) {
          this.needsIsString = false;
          this.counselingNeeds = this.orderMagData.studPurchaseOrder.sellerDemandDesc;
        } else {
          this.needsIsString = true;
          this.counselingNeeds = JSON.parse(this.orderMagData.studPurchaseOrder.sellerDemandDesc);
        }
      }
    },
    gotoPaper: function gotoPaper() {
      // 如果没有委派过讲师不给跳||未接单
      if (!this.orderMagData.teacherId && !this.orderMagData.hisChangeOrderIds || this.orderMagData.teacOrder && this.orderMagData.teacOrder.statused == 0) {
        this.$notify.error('订单无讲师接过单，规划表无内容查看');
        return;
      }
      this.$store.dispatch("dissertation/crmGotoPaperRouterPath", {
        courseId: this.$route.query.id,
        isPermision: 1,
        flag: true //在新页面打开
      });
    },
    openurl: function openurl(item) {
      window.open(item.url, '_blank');
    }
  }
};
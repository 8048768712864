"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
var _plupload = _interopRequireDefault(require("plupload"));
var _jquery = _interopRequireDefault(require("jquery"));
//
//
//
var _default = exports.default = {
  props: ['commid', 'couseobj'],
  data: function data() {
    return {
      uploader: null,
      uploadParams: {},
      timeStamp: '',
      uploadUseData: '',
      uploadFilesLength: [],
      uploadFileName: '',
      beforeId: '',
      uploadFilesArray: [],
      uploadFilesTimes: 0
    };
  },
  mounted: function mounted() {
    this.uploadUseData = this.couseobj;
    this.initupload();
  },
  methods: {
    //时间戳
    createTimeStamp: function createTimeStamp() {
      this.timeStamp = parseInt(new Date().getTime() / 1000) + "";
    },
    // 上传流程
    initupload: function initupload() {
      this.uploader = null;
      this.createdUploadsign();
      this.createUploader();
    },
    createdUploadsign: function createdUploadsign() {
      var _this2 = this;
      //类型判断URL
      this.createTimeStamp();
      var url = '';
      console.log(this.uploadUseData);
      if (this.uploadUseData && this.uploadUseData.scTypeed == 4 || this.uploadUseData.spoType == 69) {
        url = this.$api.baseURL + "/oss/getAliOSSUploadSignWyard?dir=" + "courseware/" + this.timeStamp + "/";
      } else {
        url = this.$api.baseURL + "/oss/getAliOSSUploadSign?dir=" + "courseware/" + this.timeStamp + "/";
      }
      var _this = this;
      this.$axios({
        url: url
      }).then(function (r) {
        if (r.data.status === 200) {
          console.log(_this2.commid, '000', r.data.body);
          var _r$data$body = r.data.body,
            accessid = _r$data$body.accessid,
            host = _r$data$body.host,
            policy = _r$data$body.policy,
            signature = _r$data$body.signature,
            dir = _r$data$body.dir,
            g_dirname = dir;
          _this.uploadParams.accessid = accessid;
          _this.uploadParams.host = host;
          _this.uploadParams.policyBase64 = policy;
          _this.uploadParams.signature = signature;
          _this.uploadParams.g_dirname = dir;
        }
      }).catch(function (er) {});
    },
    formatFileName: function formatFileName(up, filename) {
      this.uploadFileName = encodeURIComponent(filename);
      this.set_upload_param(up, filename, false);
    },
    set_upload_param: function set_upload_param(up, filename, ret) {
      if (ret) {
        this.formatFileName(up, filename);
      }
      var new_multipart_params = {
        key: this.uploadParams.g_dirname + filename,
        policy: this.uploadParams.policyBase64,
        OSSAccessKeyId: this.uploadParams.accessid,
        success_action_status: "200",
        // 让服务端返回200,不然，默认会返回204
        signature: this.uploadParams.signature
      };
      up.setOption({
        url: this.uploadParams.host,
        multipart_params: new_multipart_params
      });
      console.log('new_multipart_params', new_multipart_params);
      // 所有uploadstart
      this.$parent.alluploadFilesList.forEach(function (item) {
        console.log(item);
        item.start();
      });
      //    up.start();
    },
    createUploader: function createUploader() {
      this.fileload();
    },
    fileload: function fileload() {
      var _this = this;
      console.log('idname', this.commid);
      this.uploader = new _plupload.default.Uploader({
        runtimes: "html5,flash,silverlight,html4",
        browse_button: this.commid,
        flash_swf_url: "js/Moxie.swf",
        silverlight_xap_url: "js/Moxie.xap",
        url: "http://oss.aliyuncs.com",
        init: {
          PostInit: function PostInit() {
            document.getElementById('allupload').onclick = function () {
              if (!_this.$parent.uploadFilesLength.length) {
                _this.$notify({
                  title: '提示',
                  type: 'error',
                  message: '请选择要上传的文件'
                });
                return false;
              }
              if (!_this.$parent.remark && _this.$parent.uploadFileData.index == 2) {
                console.log(222);
                _this.$notify({
                  title: '提示',
                  type: 'error',
                  message: '请填写评级说明！'
                });
                return false;
              }
              if (_this.commid == 'SubmitWorks') {
                if (!_this.$parent.isClassEnd && _this.$parent.isClassEnd !== 0) {
                  _this.$notify({
                    title: '提示',
                    type: 'error',
                    message: '请选择是否同步申请结课'
                  });
                  return false;
                }
                if (![0, 1, 66, 71].includes(Number(_this.$parent.specialType))) {
                  if (_this.$parent.uploadDropBoxRadio == "11" || _this.$parent.uploadDropBoxRadio == "30" || _this.$parent.uploadDropBoxRadio == "16" && [27].includes(+_this.$parent.specialType)) {
                    _this.set_upload_param(_this.uploader, "", false);
                    return false;
                  } else {
                    if (_this.$parent.uploadUseData.hasPastPaper) {
                      _this.set_upload_param(_this.uploader, "", false);
                      return false;
                    }
                  }
                } else {
                  if (_this.$parent.uploadDropBoxRadio == "") {
                    _this.$message.error("请选择分组");
                    return false;
                  } else {
                    _this.set_upload_param(_this.uploader, "", false);
                    return false;
                  }
                }
              } else {
                _this.$parent.uploadFileData.loading = true;
                _this.set_upload_param(_this.uploader, "", false);
                return false;
              }
            };
          },
          FilesAdded: function FilesAdded(up, files) {
            console.log('files', files, up);
            console.log('FilesAdded1', _this.commid, 'FilesAdded2', _this.$parent.beforeId);
            _this.$parent.alluploadFilesList = [];
            if (_this.$parent.beforeId == _this.commid || !_this.$parent.beforeId) {} else {
              _this.$parent.uploadFilesLength = [];
            }
            _this.$parent.beforeId = _this.commid;
            _this.$parent.alluploadFilesList.push(up);
            for (var i in files) {
              _this.$parent.uploadFilesLength.push(files[i]);
            }
            _plupload.default.each(files, function (file) {
              file.filesize = _plupload.default.formatSize(file.size).split(" ")[0] + _plupload.default.formatSize(file.size).split(" ")[1].toUpperCase();
            });
          },
          BeforeUpload: function BeforeUpload(up, file) {
            _this.set_upload_param(up, file.name, true);
          },
          UploadProgress: function UploadProgress(up, file) {
            _this.updataFiles = true;
            _this.$parent.uploadFilesLength.forEach(function (item) {
              if (item.id === file.id) {
                item.progressshow = true;
                item.percent = file.percent;
              }
            });
          },
          FileUploaded: function FileUploaded(up, file, info) {
            if (info.status == 200) {
              _this.uploadFilesTimes++;
              _this.uploadFilesArray = [];
              console.log('_this.uploadFilesTimes', _this.uploadFilesTimes);
              var obj = {
                cdId: _this.uploadUseData.courseId,
                groupId: 0,
                name: file.name,
                url: _this.uploadParams.host + "/" + _this.uploadParams.g_dirname + _this.uploadFileName
              };
              if (_this.commid == 'SubmitWorks') {
                obj.groupId = _this.$parent.uploadDropBoxRadio;
                obj.isClassEnd = _this.$parent.uploadUseData.isClassEnd == 1 ? 0 : _this.$parent.isClassEnd; //是否同步申请结课(如果已经申请过，再次提交传0)
              }
              _this.uploadFilesArray.push(obj);
              _this.submitFile();
            } else {
              _this.uploader.destroy();
              _this.$notify.error(info.response);
            }
          },
          Error: function Error(up, err) {
            console.log(up, err, '文件上传失败的展示');
            _this.uploader.destroy();
            _this.$notify({
              title: '提示',
              type: "error",
              message: "文件上传失败， 请刷新重试"
            });
            _this.updataFiles = false;
            _this.$parent.uploadFilesLength = [];
          }
        }
      });
      this.uploader.init();
    },
    submitFile: function submitFile() {
      var _this3 = this;
      this.$parent.uploadFileData.loading = true;
      var url = '',
        index = '';
      this.$parent.uploadFileData.stepList.forEach(function (item, i) {
        if (item.id == _this3.commid) {
          index = i;
          return url = item.url;
        }
      });
      var paramsdata = {
        list: this.uploadFilesArray
      };
      if (this.commid == 'Remarkfiles') {
        var lev = 0;
        if (this.$parent.levelChoose == "A") {
          lev = 1;
        } else if (this.$parent.levelChoose == "B") {
          lev = 2;
        } else if (this.$parent.levelChoose == "C") {
          lev = 3;
        } else if (this.$parent.levelChoose == "D") {
          lev = 4;
        } else {
          lev = this.$parent.levelChoose;
        }
        paramsdata.level = lev;
        paramsdata.remark = this.$parent.remark;
      }
      var paramsDatas = '';
      if (this.commid == 'SubmitWorks') {
        paramsDatas = JSON.stringify(this.uploadFilesArray);
      } else {
        paramsDatas = JSON.stringify(paramsdata);
      }
      this.$axios({
        url: this.$api.baseURL + url,
        method: "post",
        data: paramsDatas,
        headers: {
          "Content-Type": "application/json"
        }
      }).then(function (r) {
        _this3.$parent.uploadFileData.loading = false;
        if (r.data.status == 200) {
          _this3.$message({
            type: "success",
            message: "文件上传成功"
          });
          if (_this3.uploadFilesTimes === _this3.$parent.uploadFilesLength.length) {
            _this3.uploadFilesTimes = 0;
          }
          _this3.$parent.getTableData();
        } else {
          _this3.$parent.uploadFileData.show = false;
          _this3.$parent.uploadFileData.loading = false;
          _this3.$parent.uploadFilesLength = [];
          _this3.$message.error(r.data.body.msg);
        }
      }).catch(function (er) {
        _this3.$parent.uploadFileData.show = false;
        _this3.$parent.uploadFileData.loading = false;
        _this3.$message.error(er.data.body.msg);
      });
    }
  }
};
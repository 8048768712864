"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _VEnum = require("@/utils/VEnum");
var QUESTION = _VEnum.AlertModalType.QUESTION,
  TODO = _VEnum.AlertModalType.TODO,
  DONE = _VEnum.AlertModalType.DONE,
  REACTION = _VEnum.AlertModalType.REACTION;
var _default = exports.default = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, QUESTION, {
  title: "遇到问题",
  content: ["请在【小班带你飞】小程序，咨询客服为你解决问题"],
  btn: "我知道了",
  target: QUESTION
}), TODO, {
  title: "未完成课堂任务",
  content: ["你还没有完成课堂任务哦，请先去完成"],
  btn: "我知道了",
  target: TODO
}), DONE, {
  title: "已完成课堂任务",
  content: ["你已经完成课堂任务，请进入下一步"],
  btn: "我知道了",
  target: DONE
}), REACTION, {
  title: "",
  content: ["报告填写完成后，请等待师资", "进行审核"],
  btn: "我知道了",
  target: REACTION
});
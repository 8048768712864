"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryWyardOrderInfo = exports.getZipFile = exports.getWyFileType = exports.getGroupList = exports.getCourseObject = exports.getBchannelCourseListInfo = exports.geAllFilesDrop = exports.additionalRead = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
var _qs = _interopRequireDefault(require("qs"));
//根据月份获取课程
var getCourseObject = exports.getCourseObject = function getCourseObject(id) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/getCourseObject?id=").concat(id),
    method: "get"
  });
};
var getGroupList = exports.getGroupList = function getGroupList(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/courseware/groupList"),
    method: "get",
    params: data
  });
};
var geAllFilesDrop = exports.geAllFilesDrop = function geAllFilesDrop(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/geAllFiles"),
    method: "get",
    params: data
  });
};
//   下载接口
var getZipFile = exports.getZipFile = function getZipFile(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/resource/getZipFile"),
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    data: data
  });
};
//   ---------------------------------------
// 文亚订单详情接口

var queryWyardOrderInfo = exports.queryWyardOrderInfo = function queryWyardOrderInfo(id) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/queryWyardOrderInfo?courseId=").concat(id),
    method: "get"
  });
};
//   文亚文件夹类型
var getWyFileType = exports.getWyFileType = function getWyFileType(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/courseware/copywritingGroupList"),
    method: "get",
    params: params
  });
};
//读了additional  
var additionalRead = exports.additionalRead = function additionalRead(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/additional/read/").concat(courseId),
    method: "post",
    data: {}
  });
};
//获取b端订单的子订单
var getBchannelCourseListInfo = exports.getBchannelCourseListInfo = function getBchannelCourseListInfo(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/getBchannelCourseListInfo"),
    method: "get",
    params: params
  });
};
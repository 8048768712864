var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c(
            "el-dialog",
            {
              staticClass: "paper-dialog",
              attrs: {
                visible: _vm.show,
                width: "700px",
                "before-close": _vm.closeModal,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.show = $event
                },
              },
            },
            [
              _c("h3", { staticClass: "title" }, [_vm._v("选择擅长课程")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.contentLoading,
                      expression: "contentLoading",
                    },
                  ],
                  staticClass: "content",
                },
                [
                  _c("p", [
                    _vm._v(
                      "擅长科目的填写会影响到未来订单匹配的精准度，为了帮助老师尽快晋升，请讲师务必根据自己的专业认真填写，"
                    ),
                    _c("span", { staticClass: "oranges" }, [
                      _vm._v("请至少选择3个课程"),
                    ]),
                    _vm._v("，全真模拟课课件根据讲师擅长课程匹配课件"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      staticClass: "course-form",
                      attrs: { model: _vm.courseForm, "label-width": "82px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "你的专业", prop: "major" } },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.courseloading,
                                  expression: "courseloading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                filterable: "",
                                size: "small",
                              },
                              on: {
                                change: _vm.specialtyList,
                                "remove-tag": _vm.removeExtList,
                              },
                              model: {
                                value: _vm.courseForm.professionals,
                                callback: function ($$v) {
                                  _vm.$set(_vm.courseForm, "professionals", $$v)
                                },
                                expression: "courseForm.professionals",
                              },
                            },
                            _vm._l(_vm.majorList, function (major, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    disabled: major.type != 2,
                                    label:
                                      major.chineseName +
                                      "[" +
                                      major.name +
                                      "]",
                                    value: major.id,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(major.chineseName) +
                                      "[" +
                                      _vm._s(major.name) +
                                      "]"
                                  ),
                                  major.type != 2
                                    ? _c("img", {
                                        staticStyle: { height: "20px" },
                                        attrs: {
                                          src: require("@/assets/images/hatch/fakeclass/old.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.extList.length
                        ? _c(
                            "div",
                            { staticStyle: { overflow: "hidden" } },
                            _vm._l(_vm.extList, function (extobj, indexe) {
                              return _c(
                                "div",
                                {
                                  key: indexe,
                                  staticStyle: { "margin-bottom": "10px" },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "checkbox-show" },
                                    [
                                      extobj.ext.prompt.select
                                        ? _c("el-checkbox", {
                                            attrs: { label: true },
                                            model: {
                                              value: extobj.ext.prompt.select,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  extobj.ext.prompt,
                                                  "select",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "extobj.ext.prompt.select",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "oranges",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            extobj.ext.prompt.explain
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  extobj.ext.options.length
                                    ? _c("div", [
                                        extobj.ext.optionsType == "1"
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                extobj.ext.options,
                                                function (check, index) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: index + ",",
                                                      staticStyle: {
                                                        position: "relative",
                                                      },
                                                      attrs: {
                                                        name: "radios",
                                                        label: true,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.showOhterInput(
                                                            check,
                                                            index,
                                                            indexe,
                                                            1
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: check.select,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            check,
                                                            "select",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "check.select",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(check.name)
                                                      ),
                                                      check.otherInputStatus &&
                                                      check.select == true
                                                        ? _c("textarea", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  check.value,
                                                                expression:
                                                                  "check.value",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "other-input",
                                                            attrs: {
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            domProps: {
                                                              value:
                                                                check.value,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  check,
                                                                  "value",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c(
                                              "div",
                                              _vm._l(
                                                extobj.ext.options,
                                                function (check, index) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        position: "relative",
                                                      },
                                                      attrs: { label: true },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.showOhterInput(
                                                            check,
                                                            index,
                                                            indexe
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: check.select,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            check,
                                                            "select",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "check.select",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(check.name)
                                                      ),
                                                      check.select == true &&
                                                      check.otherInputStatus
                                                        ? _c("textarea", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  check.value,
                                                                expression:
                                                                  "check.value",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "other-input",
                                                            attrs: {
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            domProps: {
                                                              value:
                                                                check.value,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  check,
                                                                  "value",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "checkbox-show" }, [
                                    _c("span", {
                                      staticClass: "oranges",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          extobj.ext.prompt.hint
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "擅长课程", prop: "course" } },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.specialtyLoading,
                                  expression: "specialtyLoading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: { size: "small", multiple: "" },
                              model: {
                                value: _vm.courseForm.specialtyClassess,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.courseForm,
                                    "specialtyClassess",
                                    $$v
                                  )
                                },
                                expression: "courseForm.specialtyClassess",
                              },
                            },
                            _vm._l(_vm.courseList, function (course, index) {
                              return _c(
                                "el-option",
                                {
                                  key: index,
                                  attrs: {
                                    label:
                                      course.name + "[" + course.enName + "]",
                                    value: course.id,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(course.name) +
                                      "[" +
                                      _vm._s(course.enName) +
                                      "]"
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "footer-btn",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "cancle-btn",
                      on: { click: _vm.closeModal },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "confirm-btn",
                      on: { click: _vm.saveTeacSkills },
                    },
                    [_vm._v("确认")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
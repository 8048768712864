var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "first-item", attrs: { id: "1" } }, [
      _vm._v("授课应用相关"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "title", attrs: { id: "1.1" } }, [
      _vm._v("1.万能讲师课堂测试入口"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "step-content" }, [
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("span", { staticClass: "star" }, [_vm._v("*")]),
          _vm._v("万能讲师课堂:"),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { loading: _vm.classLoding },
              on: {
                click: function ($event) {
                  return _vm.creatClass(1)
                },
              },
            },
            [_vm._v("开始测试")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "title", attrs: { id: "1.2" } }, [
      _vm._v("2.万能讲师客户端下载"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "step-content" }, [
      _c("div", { staticClass: "desc" }, [
        _vm._v(" 适用于macOS 10.13及以上， Windows7及以上。"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "down-content" },
        _vm._l(_vm.downList, function (item, index) {
          return _c("div", { key: item.id }, [
            _c("img", { attrs: { src: item.img, alt: "" } }),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(item.desc))]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "down-btn",
                on: {
                  click: function ($event) {
                    return _vm.downLoad(item.guideUrl, item.guideName)
                  },
                },
              },
              [_vm._v("安装指南")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "down-btn",
                on: {
                  click: function ($event) {
                    return _vm.downLoad(item.downLoadLink, item.desc)
                  },
                },
              },
              [_c("a", [_vm._v("下载")])]
            ),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "font-red" }, [
      _c("span", { staticClass: "star" }, [_vm._v("*")]),
      _vm._v("请讲师们在此处进行模拟上课的操作演练"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "operate-box" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("固定任务操作区")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._l(_vm.btnList, function (item, index) {
            return [
              item.flag
                ? _c(
                    "span",
                    {
                      key: index,
                      staticClass: "operate-btn",
                      class: {
                        "disable-btn":
                          !item.show || !_vm.buttonList.includes(item.type),
                      },
                      on: {
                        click: function ($event) {
                          _vm.showModal(
                            item.name,
                            item.show && _vm.buttonList.includes(item.type)
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.text))]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("addNeed", {
        attrs: { isShow: _vm.showNeed, needs: _vm.needs },
        on: { closeModal: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("addNeedNew", {
        attrs: {
          isShow: _vm.showNeedNew,
          needs: _vm.needs,
          deadline: _vm.deadline,
        },
        on: { closeModal: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("paperFiles", {
        attrs: { isShow: _vm.showUpload },
        on: { closeModal: _vm.closeModal },
      }),
      _vm._v(" "),
      _c("addClass", {
        attrs: {
          isShow: _vm.showClass,
          stageList: _vm.stageList,
          deadline: _vm.deadline,
        },
        on: { closeModal: _vm.closeModal, init: _vm.init },
      }),
      _vm._v(" "),
      _c("paperLevel", {
        attrs: { isShow: _vm.showLevel },
        on: { closeModal: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-class-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isShow, center: "", width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [_vm._v("添加课堂")]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "add-checkbox" }, [
                _c("div", { staticClass: "label" }, [
                  _c("span", [_vm._v(_vm._s(_vm.title))]),
                  _vm._v(" "),
                  _c("span", [_vm._v("阶段课堂")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "content-list" },
                  _vm._l(_vm.classTypes, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        class: { active: _vm.isActive === i },
                        on: {
                          click: function ($event) {
                            return _vm.checkClassType(item, i)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(item.className))])]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "upload-form" }, [
                _c("div", [
                  _c("div", { staticClass: "label" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/dissertation/modal/name.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("课堂名称")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          disabled: "",
                          placeholder: "请输入课堂名称",
                        },
                        model: {
                          value: _vm.form.className,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "className", $$v)
                          },
                          expression: "form.className",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "label" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/dissertation/modal/remark.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("课堂说明")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          maxlength: 255,
                          type: "textarea",
                          placeholder: "填写课堂说明",
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "btn-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-plain",
                      on: {
                        click: function ($event) {
                          _vm.isShow = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.validate } },
                    [_vm._v("确定添加")]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-upload-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.isShow, top: "5%", center: "", width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [_vm._v("上传文件材料")]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "upload-form",
            },
            [
              _c("div", { staticClass: "upload-box" }, [
                _c("div", { staticClass: "label" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/dissertation/modal/file.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("文件材料")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-plain btn-upload",
                      on: { click: _vm.checkFile },
                    },
                    [_vm._v("选择文件")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.listForm, function (item, i) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.enable,
                        expression: "item.enable",
                      },
                    ],
                    key: i,
                    staticClass: "remark",
                  },
                  [
                    _c("div", { staticClass: "label" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "content file-list",
                        class: { "list-border": item.enable },
                      },
                      [
                        _c("Upload-Temp", {
                          ref: i,
                          refInFor: true,
                          attrs: { index: item.index, form: _vm.listForm[i] },
                          on: {
                            "update:form": function ($event) {
                              return _vm.$set(_vm.listForm, i, $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "btn-box" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn-plain",
                    on: {
                      click: function ($event) {
                        _vm.isShow = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "btn", on: { click: _vm.dispatch } }, [
                  _vm._v("确定上传"),
                ]),
              ]),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
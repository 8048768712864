"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _smallmedalList = _interopRequireDefault(require("./smallmedalList"));
var _medalList = _interopRequireDefault(require("./medalList"));
var _Utils = require("@/utils/task/Utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    likeData: {
      type: Object,
      default: function _default() {}
    },
    type: {
      type: String,
      default: ''
    },
    listType: {
      type: String,
      default: '1'
    }
  },
  components: {
    smallmedalList: _smallmedalList.default,
    medalList: _medalList.default
  },
  data: function data() {
    return {};
  },
  methods: {
    globalImgFilter: _Utils.globalImgFilter
  }
};
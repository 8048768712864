var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-box" }, [
    _c(
      "div",
      { staticClass: "message-title" },
      [
        _c("span", [_vm._v("消息通知")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "notice-img",
          attrs: { src: require("@/assets/images/ling.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("el-badge", {
          staticStyle: { cursor: "pointer" },
          attrs: { hidden: !_vm.notReadNum, value: _vm.notReadNum },
          nativeOn: {
            click: function ($event) {
              return _vm.lookallNotice($event)
            },
          },
        }),
        _vm._v(" "),
        _vm.messageData.length
          ? _c(
              "span",
              {
                staticClass: "see-all",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.lookallNotice($event)
                  },
                },
              },
              [
                _vm._v("查看全部"),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/tangle.png"),
                    alt: "",
                  },
                }),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notice-content" },
      [
        _vm.showTip
          ? _c("div", { staticClass: "notice-item" }, [
              _c("span", [_vm._v(" 07/08")]),
              _vm._v(" "),
              _vm._m(0),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.messageData.length
          ? _vm._l(_vm.messageData, function (msg, i) {
              return i < (_vm.showTip ? 1 : 2)
                ? _c("div", { staticClass: "notice-item" }, [
                    _c("span", [_vm._v(_vm._s(msg.date.replace(/\-/g, "/")))]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", [_vm._v(_vm._s(msg.title))]),
                      _vm._v(" "),
                      msg.type != 11
                        ? _c("p", {
                            domProps: { innerHTML: _vm._s(msg.description) },
                          })
                        : _c("p", [
                            _vm._v(
                              "\n            恭喜您从LV" +
                                _vm._s(JSON.parse(msg.description).oldLevel) +
                                "升级到LV" +
                                _vm._s(JSON.parse(msg.description).nowLevel) +
                                "\n          "
                            ),
                          ]),
                    ]),
                  ])
                : _vm._e()
            })
          : [
              _c("img", {
                staticClass: "no-message",
                attrs: {
                  src: require("@/assets/images/home/nomessage.png"),
                  alt: "",
                },
              }),
            ],
        _vm._v(" "),
        _c("div"),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticStyle: { color: "#ff3232" } }, [_vm._v("重要通知")]),
      _vm._v(" "),
      _c("p", { staticStyle: { "-webkit-line-clamp": "7" } }, [
        _vm._v(
          "亲爱的各位万能讲师，万能班长团队将于2022/07/09日起 至 2022/07/17日进入到【公司季度假】。由于每月15号发薪日处于假期中，经由公司决定，本年度7月份兼职团队薪酬将于2022/07/18日发放。同时，假期期间各岗位的老师在消息处理和回复上可能会出现延迟，希望各位讲师配合和理解。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./schduleInfo.vue?vue&type=template&id=6827e719&scoped=true"
import script from "./schduleInfo.vue?vue&type=script&lang=js"
export * from "./schduleInfo.vue?vue&type=script&lang=js"
import style0 from "./schduleInfo.vue?vue&type=style&index=0&id=6827e719&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6827e719",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6827e719')) {
      api.createRecord('6827e719', component.options)
    } else {
      api.reload('6827e719', component.options)
    }
    module.hot.accept("./schduleInfo.vue?vue&type=template&id=6827e719&scoped=true", function () {
      api.rerender('6827e719', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/paperchedule/schduleInfo.vue"
export default component.exports
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      BG: "background: url(".concat(require("@/assets/images/hatch/qrcode/bg.png"), ") no-repeat; background-size:100% "),
      arrow: require("@/assets/images/hatch/qrcode/arrow.png"),
      isWechat: false
    };
  },
  methods: {
    isWeiXin: function isWeiXin() {
      //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      //通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    __versions: function __versions() {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      return {
        //移动终端浏览器版本信息
        trident: u.indexOf("Trident") > -1,
        //IE内核
        presto: u.indexOf("Presto") > -1,
        //opera内核
        webKit: u.indexOf("AppleWebKit") > -1,
        //苹果、谷歌内核
        gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1,
        //火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/),
        //是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        //ios终端
        android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
        //android终端或者uc浏览器
        iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1,
        //是否为iPhone或者QQHD浏览器
        iPad: u.indexOf("iPad") > -1,
        //是否iPad
        webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部
      };
    },
    download: function download() {
      var versions = this.__versions();
      if (versions.ios || versions.iPhone || versions.iPad) {
        // 此处写ios的下载地址
        window.location = "https://apps.apple.com/cn/app/%E4%B8%87%E8%83%BD%E8%AE%B2%E5%B8%88-%E4%B8%87%E9%87%8C%E6%8C%91%E4%B8%80-%E6%97%A0%E6%87%88%E5%8F%AF%E5%87%BB%E7%9A%84%E4%BD%A0/id1403146153";
      } else if (versions.android) {
        // 此处写Andoird 的下载地址
        window.location = "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/apk/teacher_latest.apk";
      }
    }
  },
  created: function created() {
    this.isWechat = this.isWeiXin();
  }
};
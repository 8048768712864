import { render, staticRenderFns } from "./medalWall.vue?vue&type=template&id=64bb8456&scoped=true"
import script from "./medalWall.vue?vue&type=script&lang=js"
export * from "./medalWall.vue?vue&type=script&lang=js"
import style0 from "./medalWall.vue?vue&type=style&index=0&id=64bb8456&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bb8456",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64bb8456')) {
      api.createRecord('64bb8456', component.options)
    } else {
      api.reload('64bb8456', component.options)
    }
    module.hot.accept("./medalWall.vue?vue&type=template&id=64bb8456&scoped=true", function () {
      api.rerender('64bb8456', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/account/medalWall.vue"
export default component.exports
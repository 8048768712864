var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "letter-container" }, [
    _c("div", {
      staticClass: "letter-bg",
      class: { cover: _vm.isCover },
      staticStyle: { width: "100%", height: "100%" },
      attrs: { id: "letter" },
    }),
    _vm._v(" "),
    _vm.btnShow
      ? _c("img", {
          staticClass: "click-box",
          class: [_vm.cssMoveLoad ? "move" : "change"],
          attrs: { src: require("@/assets/images/hatch/letter/button.png") },
          on: { click: _vm.showLetter },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.openBtn
      ? _c("div", { staticClass: "btn", on: { click: _vm.openLink } }, [
          _vm._v("开启培训"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
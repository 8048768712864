"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
var _level = require("@/utils/level");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    teacinfo: {
      type: Object,
      default: function _default() {}
    },
    showZan: {
      type: Boolean,
      default: false
    },
    showZanNum: {
      type: Boolean,
      default: false
    },
    levelsArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    thumbsNext: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      partLevelData: _level.partLevelData,
      isShow: false,
      proLength: 0,
      currentLevel: "" //点击的等级
    };
  },
  watch: {
    isShow: function isShow(val) {
      if (val) {
        this.currentLevel = this.teacinfo.level;
        this.initLength();
      }
    }
  },
  methods: {
    //  初始化进度条
    initLength: function initLength() {
      // 判断当前获赞值是否大于等于下一级的获赞值，如果大于等于，进度条到下一级，下一级原点空心
      var index = this.teacinfo.level - 1; //当前等级对应的index
      if (index < 7) {
        if (this.teacinfo.thumbsNumber >= this.levelsArr[index + 1].calThumbsNum) {
          this.proLength = 1 * 60 + (index + 1) * 125 + 5; //进度条到下一级/60是1前面的长度,125是每个区间的长度5为了到圆点中间
        } else {
          //如果没到下一级的获赞值，计算进度条长度
          var pre = this.teacinfo.thumbsNumber - this.levelsArr[index].calThumbsNum; //多出当前等级的赞
          var now = this.levelsArr[index + 1].calThumbsNum - this.levelsArr[index].calThumbsNum; //升级所需的赞
          var all = pre / now;
          this.proLength = 1 * 60 + index * 125 + all * 125 + 5;
        }
      } else {
        this.proLength = 1 * 60 + index * 125 + 60 + 5;
      }
    },
    handleClose: function handleClose() {
      this.isShow = false;
    },
    //算获赞值所处区间
    gethumbsLevel: function gethumbsLevel(val, type) {
      return this.levelsArr.find(function (v) {
        return val < v[type];
      });
    },
    //投诉率值所处区间
    getLevel: function getLevel(val, type) {
      return this.levelsArr.find(function (v) {
        return val > v[type];
      });
    },
    //领取送的赞
    getAward: function getAward() {
      this.$parent.getAward();
    },
    clickLevel: function clickLevel(item) {
      this.currentLevel = item.level;
    }
  }
};
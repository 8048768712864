"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChars = getChars;
//得到字符总数
function getChars(str) {
  var i = 0;
  var c = 0.0;
  var unicode = 0;
  var len = 0;
  if (str == "" || str == null) {
    return 0;
  }
  len = str.length;
  for (i = 0; i < len; i++) {
    unicode = str.charCodeAt(i);
    if (unicode < 127) {
      //判断是单字符还是双字符
      c += 1;
    } else {
      //chinese
      c += 2;
    }
  }
  return c;
}
function sb_substr(str, startp, endp) {
  var i = 0;
  c = 0;
  unicode = 0;
  rstr = "";
  var len = str.length;
  var sblen = sb_strlen(str);
  if (startp < 0) {
    startp = sblen + startp;
  }
  if (endp < 1) {
    endp = sblen + endp; // - ((str.charCodeAt(len-1) < 127) ? 1 : 2);
  }
  // 寻找起点
  for (i = 0; i < len; i++) {
    if (c >= startp) {
      break;
    }
    var unicode = str.charCodeAt(i);
    if (unicode < 127) {
      c += 1;
    } else {
      c += 2;
    }
  }
  // 开始取
  for (i = i; i < len; i++) {
    var unicode = str.charCodeAt(i);
    if (unicode < 127) {
      c += 1;
    } else {
      c += 2;
    }
    rstr += str.charAt(i);
    if (c >= endp) {
      break;
    }
  }
  return rstr;
}
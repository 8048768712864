var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-content" },
    [
      _c("div", { staticClass: "page-title" }, [
        _vm._v("论文辅导规划表 "),
        _c("img", {
          attrs: { src: require("@/assets/images/paper/wenhao.png"), alt: "" },
          on: {
            click: function ($event) {
              _vm.showInfo = true
            },
          },
        }),
        _vm._v(" "),
        _c("span", [
          _c("img", {
            attrs: { src: require("@/assets/images/paper/zhuyi.png"), alt: "" },
          }),
          _vm._v("订单Deal dealline : " + _vm._s(_vm.deadline)),
        ]),
      ]),
      _vm._v(" "),
      _c("taskOperate", {
        ref: "operate",
        attrs: {
          needs: _vm.needs,
          deadline: _vm.deadline,
          version: Number(_vm.version),
        },
        on: { init: _vm.init },
      }),
      _vm._v(" "),
      _c("trainStage", {
        attrs: { version: Number(_vm.version) },
        on: {
          init: _vm.init,
          buttonInit: _vm.buttonInit,
          getData: _vm.getData,
        },
      }),
      _vm._v(" "),
      _c("timeTable", {
        ref: "timeTable",
        attrs: { deadline: _vm.deadline, needs: _vm.needs },
      }),
      _vm._v(" "),
      _c("schduleInfo", {
        attrs: { isShow: _vm.showInfo },
        on: { closeModal: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
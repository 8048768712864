var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "content-title" }, [
        _vm._v(_vm._s(_vm.typeList[_vm.type])),
        _c("span", [_vm._v(_vm._s(_vm.textList[_vm.type]))]),
      ]),
      _vm._v(" "),
      _vm.redbagObj[_vm.redType] ||
      (_vm.likeData &&
        _vm.likeData.teacRewardedLevelConfigs &&
        _vm.likeData.teacRewardedLevelConfigs.length)
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom",
                width: "120",
                trigger: "hover",
                "popper-class": "high-reward-popover",
                disabled: !(
                  _vm.likeData &&
                  _vm.likeData.teacRewardedLevelConfigs &&
                  _vm.likeData.teacRewardedLevelConfigs.length
                ),
              },
            },
            [
              _c("img", {
                staticClass: "small-redbag",
                attrs: {
                  slot: "reference",
                  src: require("@/assets/images/redbag-small.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.openSingleredbag(_vm.type)
                  },
                },
                slot: "reference",
              }),
              _vm._v(" "),
              _c("highRewardList", {
                attrs: { rewardList: _vm.likeData.teacRewardedLevelConfigs },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.likeData.teacUser
        ? _c(
            "ul",
            { staticClass: "power-box" },
            [
              _c("teacherItem", {
                attrs: { likeData: _vm.likeData, type: _vm.type },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _vm.likeData.ranks && _vm.likeData.ranks.length
          ? _c(
              "div",
              { staticClass: "list" },
              _vm._l(_vm.likeData.ranks, function (item, i) {
                return _c("teacherItem", {
                  attrs: { likeData: item, type: _vm.type, listType: "2" },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      !(_vm.likeData.ranks && _vm.likeData.ranks.length)
        ? _c("contentnull", {
            staticClass: "shownulls",
            attrs: { statuscode: 5, text: _vm.texts[_vm.redType] },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list-title" }, [
      _c("div", [_vm._v("排名")]),
      _vm._v(" "),
      _c("div", [_vm._v("讲师信息")]),
      _vm._v(" "),
      _c("div", [_vm._v("收入(G)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "finishedCourse content-box" }, [
    _c("span", { staticClass: "lighttext-box" }, [
      _vm._v(
        "本月可赠送时长" +
          _vm._s(_vm.time) +
          "/" +
          _vm._s(_vm.user.presenterTimeLimit) +
          "分钟"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "search-box" },
      [
        _c("el-input", {
          attrs: {
            size: "small",
            placeholder: "请输入关键词搜索",
            "prefix-icon": "el-icon-search",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.search($event)
            },
          },
          model: {
            value: _vm.tableDataNamet,
            callback: function ($$v) {
              _vm.tableDataNamet = $$v
            },
            expression: "tableDataNamet",
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "defaultlight-btn", on: { click: _vm.search } },
          [_vm._v("搜索")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.finishedCourseLoading,
            expression: "finishedCourseLoading",
          },
        ],
      },
      [
        _vm.tableDataEnd.length > 0
          ? _c(
              "el-table",
              {
                ref: "multipleTable",
                staticClass: "table",
                attrs: {
                  stripe: "",
                  border: "",
                  "header-cell-style": {
                    height: "36px",
                    color: "#999999",
                    fontWeight: "400",
                    background: "#F3F3F3",
                  },
                  "row-style": { height: "36px" },
                  "cell-style": { padding: "5px 0" },
                  data: _vm.tableDataEnd,
                  height: "calc(100vh - 200px)",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "订单号",
                    width: "300",
                    align: "left",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "cursorpointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.seeCouse(scope.row, scope.$index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.orderNo))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3528495997
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "课堂说明",
                    align: "center",
                    width: "150",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.description))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1516911488
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "课堂名称",
                    align: "center",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                        },
                      },
                    ],
                    null,
                    false,
                    2020036417
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "上课时间",
                    align: "center",
                    width: "200",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "time-box" }, [
                              _c("div", [
                                _c("span", [_vm._v("Start：")]),
                                _vm._v(" " + _vm._s(scope.row.startTime) + " "),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("span", [_vm._v("End：")]),
                                _vm._v(" " + _vm._s(scope.row.endTime)),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2369649532
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "上课时长(min)",
                    align: "center",
                    width: "115",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.schooltime))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4094705031
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "所获G币",
                    align: "center",
                    width: "85",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.teacG))])]
                        },
                      },
                    ],
                    null,
                    false,
                    1771361906
                  ),
                }),
                _vm._v(" "),
                _vm.ruleForm.teacherType != 2
                  ? _c("el-table-column", {
                      attrs: {
                        label: "所获OTG币",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": true,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [_vm._v(_vm._s(scope.row.teacOtg))]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        428678345
                      ),
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "好评G",
                    align: "center",
                    width: "85",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.praiseGb || "--")),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    997875711
                  ),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "赠送时长",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.spoType != 66 &&
                            scope.row.spoType != 32 &&
                            scope.row.spoType != 64
                              ? _c(
                                  "span",
                                  { staticClass: "finshedgive-boxs" },
                                  [
                                    scope.row.statused >= 2 &&
                                    scope.row.statused <= 8 &&
                                    scope.row.spoType != 64
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "default-btn",
                                            staticStyle: { width: "60px" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.gitetimeFun(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("赠送")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.statused < 2 ||
                                    scope.row.statused > 8 ||
                                    scope.row.spoType == 64
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "default-btn",
                                            staticStyle: { width: "60px" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.gitetimeFun(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("赠送")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scope.row.presenterTime > 0
                                      ? _c(
                                          "el-tag",
                                          { attrs: { size: "small" } },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.presenterTime) +
                                                "min"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v("--")]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1108630487
                  ),
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.tableDataEnd.length > 0
          ? _c("el-pagination", {
              staticStyle: { "margin-top": "15px", float: "right" },
              attrs: {
                align: "center",
                "current-page": _vm.currentPage,
                "page-sizes": [15, 20],
                "page-size": _vm.limit,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.tableDataEnd.length
          ? _c("contentnull", {
              attrs: { statuscode: _vm.tableDataNamet ? 3 : 2 },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.maskShow
      ? _c("div", { staticClass: "new-mask" }, [
          _c(
            "div",
            { staticClass: "mask-content mask-width560" },
            [
              _c("h3", [
                _vm._v("赠送时长 "),
                _c("b", { on: { click: _vm.closeMask } }, [_vm._v("+")]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: { model: _vm.ruleForm, rules: _vm.rules },
                },
                [
                  _c(
                    "div",
                    { staticClass: "dis-flex" },
                    [
                      _c("img", {
                        staticClass: "cinput-icon",
                        attrs: {
                          src: require("../../assets/images/icon-course.png"),
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "cinput-label" }, [
                        _vm._v("选择课堂"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "classroomName" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "340px!important",
                              height: "36px",
                            },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.ruleForm.classroomName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "classroomName", $$v)
                              },
                              expression: "ruleForm.classroomName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dis-flex" },
                    [
                      _c("img", {
                        staticClass: "cinput-icon",
                        attrs: {
                          src: require("../../assets/images/icon-time.png"),
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "cinput-label" }, [
                        _vm._v("赠送时长"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "commnon-por",
                          attrs: { prop: "times" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleForm.times,
                                expression: "ruleForm.times",
                              },
                            ],
                            staticClass: "cinput-textarea",
                            staticStyle: {
                              width: "340px!important",
                              height: "36px",
                            },
                            attrs: { type: "number" },
                            domProps: { value: _vm.ruleForm.times },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ruleForm,
                                  "times",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "common-danwei" }, [
                            _vm._v("min"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dis-flex" },
                    [
                      _c("img", {
                        staticClass: "cinput-icon",
                        attrs: {
                          src: require("../../assets/images/icon-desc.png"),
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "cinput-label" }, [
                        _vm._v("赠送说明"),
                      ]),
                      _vm._v(" "),
                      _c("el-form-item", { attrs: { prop: "explain" } }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ruleForm.explain,
                              expression: "ruleForm.explain",
                            },
                          ],
                          staticClass: "cinput-textarea",
                          domProps: { value: _vm.ruleForm.explain },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.ruleForm,
                                "explain",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "btnbox" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.btnstatus,
                        expression: "btnstatus",
                      },
                    ],
                    staticClass: "defaultlight-btn btn-width320",
                    on: {
                      click: function ($event) {
                        return _vm.submitForm1("ruleForm")
                      },
                    },
                  },
                  [_vm._v("确定")]
                ),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
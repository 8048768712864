var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("课程日志")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detial-msg-box filemsg-height" },
      _vm._l(_vm.orderMagData, function (item, index) {
        return _c("div", { key: index, staticClass: "fileslist-box" }, [
          _c("h4", [_vm._v(_vm._s(item.groupName))]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _c("span", [
                _vm._v(" " + _vm._s(item.name) + " " + _vm._s(item.username)),
              ]),
              _vm._v(" "),
              _c("b", [_vm._v(_vm._s(item.created_at))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(item.info))]),
            ]),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
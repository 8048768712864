"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _contentnull = _interopRequireDefault(require("@/views/contentnull"));
var _welecomeNew = _interopRequireDefault(require("@/views/account/newHome/welecomeNew"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    contentnull: _contentnull.default,
    welecomeNew: _welecomeNew.default
  },
  props: {
    model: {
      type: Object,
      default: function _default() {}
    },
    allNoticeData: {
      type: Object,
      default: function _default() {}
    },
    likeLoading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      modalList: {
        22: 'welecomeNew'
      }
    };
  },
  methods: {
    handleSizeChangen: function handleSizeChangen(val) {
      this.$parent.handleSizeChangen(val);
    },
    handleCurrentChangen: function handleCurrentChangen(val) {
      this.$parent.handleCurrentChangen(val);
    },
    closeMask: function closeMask() {
      this.$parent.closeMask();
    },
    showTypeModal: function showTypeModal(type) {
      this.$refs[this.modalList[type]].show();
    }
  }
};
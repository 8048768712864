var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.sellerDetail,
          expression: "sellerDetail",
        },
      ],
      staticClass: "sellerDetail",
    },
    [
      _c("div", { staticClass: "header-wrap" }, [
        _vm.copywritingCourse && _vm.copywritingCourse.type == 5
          ? _c("b", { staticClass: "bR-style" }, [
              _vm._v("R" + _vm._s(_vm.copywritingCourse.revisionSort)),
            ])
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.orderDetailDataAll.orderNo) + "\n  "),
      ]),
      _vm._v(" "),
      (_vm.ordertype == "wenya" &&
        _vm.orderDetailDataAll.linkOrderList &&
        _vm.orderDetailDataAll.linkOrderList.length) ||
      (_vm.copywritingCourse &&
        _vm.copywritingCourse.type == 5 &&
        _vm.orderDetailDataAll.revisionOrders &&
        _vm.orderDetailDataAll.revisionOrders.length)
        ? _c(
            "div",
            { staticClass: "grouporder-wrap" },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.copywritingCourse && _vm.copywritingCourse.type == 5
                      ? "Revision 订单"
                      : "Group Orders"
                  ) + " "
                ),
                _c("i", {
                  class: _vm.showgrouplist
                    ? "el-icon-arrow-down"
                    : "el-icon-arrow-up",
                  on: {
                    click: function ($event) {
                      _vm.showgrouplist = !_vm.showgrouplist
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("el-collapse-transition", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showgrouplist,
                        expression: "showgrouplist",
                      },
                    ],
                  },
                  _vm._l(
                    _vm.copywritingCourse && _vm.copywritingCourse.type == 5
                      ? _vm.orderDetailDataAll.revisionOrders
                      : _vm.orderDetailDataAll.linkOrderList,
                    function (order, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "transition-box list-box",
                          on: {
                            click: function ($event) {
                              return _vm.gotoOtherOrder(order, index)
                            },
                          },
                        },
                        [
                          _vm.copywritingCourse &&
                          _vm.copywritingCourse.type == 5 &&
                          order.mainOrderFlag != 1
                            ? _c("b", [
                                _vm._v("R" + _vm._s(order.revisionSort)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("\n             " + _vm._s(order.orderNo)),
                            _c("br"),
                            _vm._v(" "),
                            order
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.copywritingCourse &&
                                          _vm.copywritingCourse.type == 5
                                          ? _vm.wyStatusedObj[order.wyStatus]
                                          : _vm.OrderStatus[order.statused]
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.orderDetailDataAll.borderType == 6 ? _c("bOrderTree") : _vm._e(),
      _vm._v(" "),
      !_vm.sellerDetail
        ? _c("div", { staticClass: "sellerDetail-wrap" }, [
            _c(
              "div",
              { staticClass: "detail-box-left" },
              [
                _vm.ordertype == "daobi"
                  ? [
                      _c("ordermsg", {
                        attrs: {
                          orderMagData: _vm.orderDetailDataAll,
                          childtype: _vm.childType,
                        },
                        on: {
                          "update:orderMagData": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.showNewNeed
                        ? _c("orderdemandmsgV2", {
                            attrs: { orderMagData: _vm.orderDetailDataAll },
                            on: {
                              "update:orderMagData": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:order-mag-data": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                            },
                          })
                        : _c("orderdemandmsg", {
                            attrs: { orderMagData: _vm.orderDetailDataAll },
                            on: {
                              "update:orderMagData": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:order-mag-data": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c("ordermsgteacher", {
                        attrs: { orderMagData: _vm.orderDetailDataAll },
                        on: {
                          "update:orderMagData": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("ordermsgstu", {
                        attrs: { orderMagData: _vm.orderDetailDataAll },
                        on: {
                          "update:orderMagData": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordertype == "wenyaold"
                  ? [
                      _c("orderwymsg", {
                        attrs: {
                          orderMagData: _vm.wyOderdata,
                          childtype: _vm.childType,
                          daobidata: _vm.orderDetailDataAll,
                        },
                        on: {
                          "update:orderMagData": function ($event) {
                            _vm.wyOderdata = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.wyOderdata = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("orderwydemand", {
                        attrs: {
                          orderMagData: _vm.wyOderdata,
                          childtype: _vm.childType,
                          daobidata: _vm.orderDetailDataAll,
                        },
                        on: {
                          "update:orderMagData": function ($event) {
                            _vm.wyOderdata = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.wyOderdata = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("orderwyteacher", {
                        attrs: {
                          orderMagData: _vm.wyOderdata,
                          childtype: _vm.childType,
                          daobidata: _vm.orderDetailDataAll,
                        },
                        on: {
                          "update:orderMagData": function ($event) {
                            _vm.wyOderdata = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.wyOderdata = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("orderwymsgstu", {
                        attrs: { orderMagData: _vm.wyOderdata },
                        on: {
                          "update:orderMagData": function ($event) {
                            _vm.wyOderdata = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.wyOderdata = $event
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordertype == "wenya"
                  ? [
                      _c("messagewy", {
                        attrs: {
                          copywritingCourse: _vm.copywritingCourse,
                          orderMagData: _vm.orderDetailDataAll,
                        },
                        on: {
                          "update:copywritingCourse": function ($event) {
                            _vm.copywritingCourse = $event
                          },
                          "update:copywriting-course": function ($event) {
                            _vm.copywritingCourse = $event
                          },
                          "update:orderMagData": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                          "update:order-mag-data": function ($event) {
                            _vm.orderDetailDataAll = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.copywritingCourse
                        ? _c("demandwy", {
                            attrs: {
                              copywritingCourse: _vm.copywritingCourse,
                              orderMagData: _vm.orderDetailDataAll,
                            },
                            on: {
                              "update:copywritingCourse": function ($event) {
                                _vm.copywritingCourse = $event
                              },
                              "update:copywriting-course": function ($event) {
                                _vm.copywritingCourse = $event
                              },
                              "update:orderMagData": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:order-mag-data": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.orderDetailDataAll.isMy == 1
                        ? _c("ordermsgteacher", {
                            attrs: { orderMagData: _vm.orderDetailDataAll },
                            on: {
                              "update:orderMagData": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:order-mag-data": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.copywritingCourse && _vm.copywritingCourse.type != 5
                        ? _c("clientinfo", {
                            attrs: { orderMagData: _vm.orderDetailDataAll },
                            on: {
                              "update:orderMagData": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:order-mag-data": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "detail-box-right" },
              [
                (_vm.ordertype == "daobi" &&
                  _vm.orderDetailDataAll.spoType != 67) ||
                (_vm.ordertype == "wenya" &&
                  _vm.orderDetailDataAll.spoType == 69)
                  ? _c("helpmsg", {
                      attrs: { orderMagData: _vm.orderDetailDataAll },
                      on: {
                        "update:orderMagData": function ($event) {
                          _vm.orderDetailDataAll = $event
                        },
                        "update:order-mag-data": function ($event) {
                          _vm.orderDetailDataAll = $event
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordertype == "daobi"
                  ? [
                      _vm.ordertype == "daobi"
                        ? _c("filemsg", {
                            attrs: { orderMagData: _vm.orderDetailDataAll },
                            on: {
                              "update:orderMagData": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:order-mag-data": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordertype == "wenya"
                  ? [
                      _vm.copywritingCourse && _vm.copywritingCourse.type != 5
                        ? _c("filewy", {
                            attrs: {
                              orderMagData: _vm.orderDetailDataAll,
                              fileData:
                                _vm.orderDetailDataAll.studCoursewareList,
                            },
                            on: {
                              "update:orderMagData": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:order-mag-data": function ($event) {
                                _vm.orderDetailDataAll = $event
                              },
                              "update:fileData": function ($event) {
                                return _vm.$set(
                                  _vm.orderDetailDataAll,
                                  "studCoursewareList",
                                  $event
                                )
                              },
                              "update:file-data": function ($event) {
                                return _vm.$set(
                                  _vm.orderDetailDataAll,
                                  "studCoursewareList",
                                  $event
                                )
                              },
                            },
                          })
                        : _c("revisionfile"),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordertype == "wenyaold" && !_vm.wenyaLading
                  ? _c("filewymsg", {
                      attrs: {
                        orderMagData: _vm.wyOderdata,
                        daobidata: _vm.orderDetailDataAll,
                      },
                      on: {
                        "update:orderMagData": function ($event) {
                          _vm.wyOderdata = $event
                        },
                        "update:order-mag-data": function ($event) {
                          _vm.wyOderdata = $event
                        },
                        "update:daobidata": function ($event) {
                          _vm.orderDetailDataAll = $event
                        },
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.sellerDetail
        ? _c(
            "div",
            [
              _vm.orderDetailDataAll.spoType == 65 &&
              _vm.orderDetailDataAll.courseVersion == 2
                ? _c("student-need", {
                    staticStyle: { "margin-top": "20px" },
                    attrs: {
                      id: _vm.$route.query.id,
                      orderDetail: _vm.orderDetailDataAll,
                      teacId:
                        _vm.orderDetailDataAll.teacUser &&
                        _vm.orderDetailDataAll.teacUser.id,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              (_vm.orderDetailDataAll.spoType == 66 &&
                _vm.orderDetailDataAll.studPurchaseOrder &&
                _vm.orderDetailDataAll.studPurchaseOrder.studSpecialOrderExt
                  .type == 128) ||
              (_vm.orderDetailDataAll.spoType == 69 &&
                _vm.copywritingCourse &&
                _vm.copywritingCourse.type == 3)
                ? _c("testordersellerDetail", {
                    attrs: {
                      type: _vm.orderDetailDataAll.spoType,
                      daobidata: _vm.orderDetailDataAll,
                    },
                    on: {
                      "update:daobidata": function ($event) {
                        _vm.orderDetailDataAll = $event
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              (_vm.ordertype == "daobi" &&
                _vm.orderDetailDataAll.spoType != 66) ||
              (_vm.ordertype == "wenya" &&
                !(_vm.copywritingCourse && _vm.copywritingCourse.type == 5))
                ? _c("coursemsg", {
                    attrs: { orderMagData: _vm.orderDetailDataAll },
                    on: {
                      "update:orderMagData": function ($event) {
                        _vm.orderDetailDataAll = $event
                      },
                      "update:order-mag-data": function ($event) {
                        _vm.orderDetailDataAll = $event
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !(_vm.copywritingCourse && _vm.copywritingCourse.type == 5) &&
              !_vm.newPreExam
                ? _c("droplistmsg", {
                    attrs: { orderMagData: _vm.orderDetailDataAll },
                    on: {
                      "update:orderMagData": function ($event) {
                        _vm.orderDetailDataAll = $event
                      },
                      "update:order-mag-data": function ($event) {
                        _vm.orderDetailDataAll = $event
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.newPreExam ? _c("preExamDropBox") : _vm._e(),
              _vm._v(" "),
              _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.downloadFiles,
                        expression: "downloadFiles",
                      },
                    ],
                    staticClass: "maskLoading",
                  },
                  [
                    _c("div", { staticClass: "imgText" }, [
                      _c("img", {
                        staticStyle: { "margin-left": "40%" },
                        attrs: { src: "images/loading.gif", alt: "" },
                      }),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("\n            打包下载中，请耐心等待。"),
                        _c("br"),
                        _vm._v(
                          "过程中请不要关闭或刷新浏览器！避免下载失败。\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
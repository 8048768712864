var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "680px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("\n      编辑头像\n    "),
          ]),
          _vm._v(" "),
          _c("uploadImg", {
            attrs: {
              accept: _vm.accept,
              url: _vm.url,
              needCompress: _vm.needCompress,
            },
            on: {
              "update:url": function ($event) {
                _vm.url = $event
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "span",
                { staticClass: "confirm-btn", on: { click: _vm.comfirmAdd } },
                [_vm._v("确定上传")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
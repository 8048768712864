var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stage-container" }, [
    _vm.isHeader
      ? _c(
          "div",
          {
            staticClass: "stage-row header",
            class: { stripe: _vm.index % 2 === 0 },
          },
          [
            _c("div", { staticClass: "order" }, [_vm._v("阶段顺序")]),
            _vm._v(" "),
            _c("div", { staticClass: "phase" }, [_vm._v("课程阶段")]),
            _vm._v(" "),
            _c("div", { staticClass: "contain" }, [_vm._v("包含课程")]),
            _vm._v(" "),
            _c("div", { staticClass: "already" }, [_vm._v("已上课时长")]),
            _vm._v(" "),
            _vm._m(0),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "stage-row",
            class: {
              hover: true,
              stripe: _vm.index % 2 !== 0,
              "last-row": _vm.isLast,
            },
          },
          [
            _c("div", { staticClass: "order" }, [
              _c("img", { attrs: { src: _vm.OrderImg[+_vm.data.statused] } }),
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.StageType2Val[_vm.data.sort - 1])),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "phase" }, [
              _vm._v(_vm._s(_vm.data.stageName)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "contain" },
              [
                _vm.overflow(_vm.data.contain)
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: { content: _vm.data.contain, placement: "top" },
                      },
                      [
                        _c("span", { staticClass: "span-limit" }, [
                          _vm._v(_vm._s(_vm.data.contain)),
                        ]),
                      ]
                    )
                  : _c("span", [_vm._v(_vm._s(_vm.data.contain))]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "already" }, [
              _vm._v(_vm._s(_vm.data.classTime) + "mins"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "summary" },
              [_c("G-Summary", { attrs: { data: _vm.data } })],
              1
            ),
          ]
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "summary" }, [
      _vm._v("\n      阶段总结\n      "),
      _c("span", [_vm._v("请针对学生该阶段的表现予以评价(仅师资可见)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateSimulationClass = exports.checkCompleteSimulationOrder = void 0;
var _request = require("./request");
// 模拟订单操作

/**
 * 模拟订单下一步，检查是否完成模拟订单
 * @param {String} userId
 */
var checkCompleteSimulationOrder = exports.checkCompleteSimulationOrder = function checkCompleteSimulationOrder(userId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/checkCompleteSimulationOrder/").concat(userId),
    method: "get"
  });
};

/**
 * 模拟订单进入下一步
 * @param {String} userId
 */
var generateSimulationClass = exports.generateSimulationClass = function generateSimulationClass(userId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacherstep/v1/generateSimulationClass/").concat(userId),
    method: "post",
    data: {} //后台让传的
  });
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadComplatework = exports.submitOrderInfo = exports.getExamInfo = exports.getExamDetail = exports.getExamCheckFile = exports.getBeiJinTime = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
// 提交考试内容
var submitOrderInfo = exports.submitOrderInfo = function submitOrderInfo(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/saveSubject"),
    method: "post",
    data: data
  });
};
// 查询提交的考试内容
var getExamInfo = exports.getExamInfo = function getExamInfo(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/queryCourseSubject/").concat(courseId),
    method: "get"
  });
};

// 查询备考审核表
var getExamCheckFile = exports.getExamCheckFile = function getExamCheckFile(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/getPrepareReferenceFiles"),
    method: "get",
    params: params
  });
};

//获取考试订单信息详情
var getExamDetail = exports.getExamDetail = function getExamDetail(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/getStudSpecialOrderExam"),
    method: "get",
    params: params
  });
};
//获取北京时间
var getBeiJinTime = exports.getBeiJinTime = function getBeiJinTime() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/wxsubscripV3/getBeiJinTime"),
    method: "get"
  });
};
var uploadComplatework = exports.uploadComplatework = function uploadComplatework() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/uploadCoursewareFiles"),
    method: "get"
  });
};
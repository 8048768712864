"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableDataBegin: [],
      tableDataName: "",
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      modal: false,
      modal_time: false,
      total: 0,
      limit: 6,
      idss: [],
      courseId: "",
      val: 1,
      page: "",
      ruleForm: {},
      messageLoading: false
    };
  },
  multipleSelection: [],
  mounted: function mounted() {
    this.getTableData();
  },
  methods: {
    search: function search() {
      this.getTableData();
    },
    getTableData: function getTableData() {
      var _this = this;
      this.messageLoading = true;
      this.$axios({
        url: this.$api.baseURL + "/notify/list",
        params: {
          page: this.val,
          limit: this.limit,
          statused: 1,
          key: this.tableDataName
        }
      }).then(function (r) {
        _this.messageLoading = false;
        _this.tableDataEnd = r.data.body.list;
        _this.total = r.data.body.totalCount;
        _this.pageSize = r.data.body.totalCount;
        _this.currentPage = r.data.body.currPage;
      }).catch(function (er) {
        _this.messageLoading = false;
      });
      // var self = this
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     data: {
      //         'token': token,
      //         'page': this.val,
      //         'limit': this.limit,
      //         'statused': 1,
      //         'key': this.tableDataName
      //     },
      //     url: '' + baseURL + '/notify/list',
      //     success: function(json) {
      //         self.tableDataEnd = json.body.list
      //         self.total = json.body.totalCount
      //         self.pageSize = json.body.totalCount
      //         self.currentPage = json.body.currPage
      //         loading.close()
      //     }
      // })
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.limit = val;
      this.getTableData();
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.map(function (item) {
        var id = item.id;
        ids.push("" + id + "");
      });
      this.selectedIDs = ids;
      console.log("多选", ids);
      this.idss = this.selectedIDs;
      console.log(this.idss);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.messageLoading,
            expression: "messageLoading",
          },
        ],
      },
      [
        _c("el-input", {
          staticStyle: { width: "240px" },
          attrs: { placeholder: "请输入姓名" },
          model: {
            value: _vm.tableDataName,
            callback: function ($$v) {
              _vm.tableDataName = $$v
            },
            expression: "tableDataName",
          },
        }),
        _vm._v(" "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.search } },
          [_vm._v("搜索")]
        ),
        _vm._v(" "),
        _c(
          "el-table",
          {
            ref: "multipleTable",
            staticStyle: { width: "100%", "margin-top": "5px" },
            attrs: { data: _vm.tableDataEnd, border: "" },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { label: "id", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "消息标题", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "消息描述", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.description))])]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("el-pagination", {
          staticStyle: { "margin-top": "15px" },
          attrs: {
            "current-page": _vm.currentPage,
            "page-sizes": [15, 20],
            "page-size": _vm.limit,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
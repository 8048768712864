"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _lottieWeb = _interopRequireDefault(require("lottie-web"));
var _intentionJson = _interopRequireDefault(require("./intentionJson"));
var _VEnum = require("@/utils/VEnum");
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      modalShow: false,
      animation: {}
    };
  },
  methods: {
    openLink: function openLink() {
      this.$router.replace(_VEnum.HatchRouterPath.INTENTION);
    },
    show: function show() {
      this.modalShow = true;
      this.animation.stop();
      this.animation.play();
    },
    setAnimation: function setAnimation() {
      var icon = document.getElementById("intention");
      this.animation = _lottieWeb.default.loadAnimation({
        container: icon,
        // 包含动画的dom元素
        renderer: "svg",
        // 渲染出来的是什么格式
        loop: false,
        // 循环播放
        autoplay: false,
        // 自动播放
        animationData: _intentionJson.default // 动画json的路径
      });
    }
  },
  mounted: function mounted() {
    this.setAnimation();
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _auth = require("@/utils/auth");
var _VEnum = require("@/utils/VEnum");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Login",
  data: function data() {
    return {
      maskShow: false,
      loginForm: {
        username: "",
        password: ""
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
      teacUser: {}
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        // console.log(route)
        this.redirect = route.query && route.query.redirect;
        // console.log(this.redirect)
      },
      immediate: true
    }
  },
  computed: {
    isMinZhao: function isMinZhao() {
      //未上传形象照的敏招聘老师
      return this.teacUser.type = 2 && this.teacUser.childType == 3 && this.teacUser.paperThumbStatus < 1;
    }
  },
  methods: {
    openMask: function openMask() {
      this.maskShow = true;
    },
    closeMask: function closeMask() {
      this.maskShow = false;
    },
    handleLogin: function handleLogin() {
      var _this = this;
      if (!this.loginForm.username || !this.loginForm.username) {
        return this.$notify({
          title: '错误提示',
          message: '账号或者密码不能为空',
          type: 'error'
        });
      }
      localStorage.setItem('notokenstatus', 1);
      this.loading = true;
      this.$axios({
        url: this.$api.baseURL + "/login?mobile=" + this.loginForm.username + "&password=" + this.loginForm.password + "&loginSource=1",
        headers: {
          'Content-Type': ' '
        },
        method: 'post'
      }).then(function (r) {
        if (r.data.status === 200) {
          console.log('讲师', r);
          (0, _auth.setLocal)("teacinfo", JSON.stringify(r.data.body.teacUser));
          //存储老师类型兼职，全职
          _this.teacUser = r.data.body.teacUser || {};
          localStorage.setItem('teacType', r.data.body.teacUser.type);
          (0, _auth.setLocal)("teactoken", r.data.body.token);
          (0, _auth.setCookie)("teactoken", r.data.body.token);
          window.localStorage.setItem("teactoken", r.data.body.token);
          window.localStorage.setItem("notokenstatus", 0);
          window.localStorage.setItem("eliminatecode", r.data.body.train.code);
          var train = r.data.body && r.data.body.train || {};
          _this.loading = false;
          //  复试中的跳复试指引页
          if ([14, 15].includes(r.data.body.interviewStatus)) {
            _this.$router.push({
              path: '/interviewFollow'
            });
            return;
          }
          //  领了入职证书去首页
          if (_this.teacUser && _this.teacUser.paperThumbStatus > 0) {
            _this.$router.push({
              path: "/account/persondetail"
            });
            return;
          }
          //敏招聘讲师孵化阶段登录讲师端，如果完善讲师形象领入职证书
          if (_this.isMinZhao) {
            _this.$router.push('/idform');
            return;
          }
          //判断讲师培训状态，然后不同跳转（领完入职证书直接去首页就行了）
          if ([0, 1].includes(+train.code)) {
            //跳转培训状态
            _this.$router.push({
              path: _VEnum.HatchRouterPath.REDIRECT,
              query: {
                code: r.data.body.train.code
              }
            });
          } else {
            //正常状态
            _this.$router.push({
              path: "/account/persondetail"
            });
          }
        } else {
          _this.loading = false;
          _this.$notify({
            title: '错误提示',
            message: r.data.body.msg,
            type: 'error'
          });
        }
      }).catch(function (r) {
        _this.loading = false;
      });
    }
  }
};
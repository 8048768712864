"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlanningTableVersion = exports.getPaperRating = exports.getPaperFiles = exports.getNeedsList = exports.getList = exports.getFileNum = exports.getFailGb = exports.getClassList = exports.getButtonList = exports.getAliOSSUploadSign = exports.editNeeds = exports.editClass = exports.deleteTask = exports.deleteStage = exports.delPaperFiles = exports.complateTask = exports.addTask = exports.addStage = exports.addOrEditRating = exports.addNeeds = exports.addClass = void 0;
exports.getReqUrl = getReqUrl;
exports.uploadPaperFiles = exports.updateStage = exports.readNotice = exports.getTaskStatus = exports.getTaskList = exports.getTask = exports.getStuNeeds = exports.getStageList = exports.getStageDetail = exports.getStage = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
var basicUrl1 = "".concat(_api.BasicUrl, "/api/teacher");
var basicUrl2 = "".concat(_api.crmUrl, "/sys/teacher");
var basicUrl3 = "".concat(_api.newCrmUrl, "/db/sys/teacher");
function getReqUrl() {
  return localStorage.getItem('CrmLinkDiss') === 'true' ? basicUrl2 : localStorage.getItem('NewLinkDiss') === 'true' ? basicUrl3 : basicUrl1;
}
;

//获取可以拖拽的固定阶段
var getStage = exports.getStage = function getStage(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStage/v1/getUnfinishedOrderStages?courseId=").concat(courseId),
    method: "get"
  });
};

//根据月份获取课程
var getClassList = exports.getClassList = function getClassList(ym, courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeCourseOperation/v1/getClassroomList?ym=").concat(ym, "&courseId=").concat(courseId),
    method: "get"
  });
};

//获取月度阶段列表
var getStageList = exports.getStageList = function getStageList(courseId, ym) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageInstance/v1/getPaperSpreeStageMonthData?courseId=").concat(courseId, "&ym=").concat(ym),
    method: "get"
  });
};
// 获取阶段任务
var getTask = exports.getTask = function getTask(stageId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageTask/v1/getPaperSpreeStageTasksByParentId?stageId=").concat(stageId),
    method: "get"
  });
};
//新建阶段
var addStage = exports.addStage = function addStage(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageInstance/v1/addPaperSpreeStage"),
    method: "post",
    data: data
  });
};

//新增学生订单需求
var addNeeds = exports.addNeeds = function addNeeds(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeDemand/v1/add"),
    method: "post",
    data: data
  });
};
//排课
var addClass = exports.addClass = function addClass(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeCourseOperation/v1/paperSpreeStagePutClassroom"),
    method: "post",
    data: data
  });
};
//获取排课需要的阶段列表数据
var getList = exports.getList = function getList(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageInstance/v1/getOrderStageList?courseId=").concat(courseId),
    method: "get"
  });
};
// 根据日期或者阶段id查询阶段详情
var getStageDetail = exports.getStageDetail = function getStageDetail(ymd, courseId) {
  var stageId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageInstance/v1/getOneOrMoreStageDetail?courseId=").concat(courseId, "&ymd=").concat(ymd, "&stageId=").concat(stageId),
    method: "get"
  });
};
//修改新增阶段时间/名称
var updateStage = exports.updateStage = function updateStage(data, updType) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageInstance/v1/updateStage/").concat(updType),
    method: "post",
    data: data
  });
};
//删除阶段
var deleteStage = exports.deleteStage = function deleteStage(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageInstance/v1/deletePaperSpreeStage"),
    method: "post",
    data: data
  });
};
//添加阶段任务
var addTask = exports.addTask = function addTask(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageTaskInstance/v1/add"),
    method: "post",
    data: data
  });
};
//删除任务
var deleteTask = exports.deleteTask = function deleteTask(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageTaskInstance/v1/delete"),
    method: "post",
    data: data
  });
};
//完成任务
var complateTask = exports.complateTask = function complateTask(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageTaskInstance/v1/complete"),
    method: "post",
    data: data
  });
};
//获取任务列表
var getTaskList = exports.getTaskList = function getTaskList(stageId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageTaskInstance/v1/list?stageId=").concat(stageId),
    method: "get"
  });
};
//获取订单需求
var getStuNeeds = exports.getStuNeeds = function getStuNeeds(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeDemand/v1/getTeacWebStudPaperSpreeDemand?courseId=").concat(courseId),
    method: "get"
  });
};
//编辑订单需求
var editNeeds = exports.editNeeds = function editNeeds(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeDemand/v1/update"),
    method: "post",
    data: data
  });
};
//获取论文评级结果
var getPaperRating = exports.getPaperRating = function getPaperRating(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeRating/v1/getOrderRatings?courseId=").concat(courseId),
    method: "get"
  });
};
//新增或修改评论
var addOrEditRating = exports.addOrEditRating = function addOrEditRating(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeRating/v1/addOrUpdStudPaperSpreeRating"),
    method: "post",
    data: data
  });
};
//上传论文大礼包的材料
var uploadPaperFiles = exports.uploadPaperFiles = function uploadPaperFiles(data, url) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/").concat(url),
    method: "post",
    data: data
  });
};
//获取已上传的材料列表
var getPaperFiles = exports.getPaperFiles = function getPaperFiles(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeDropbox/v1/getOrderCoursewareGroup?courseId=").concat(courseId),
    method: "get"
  });
};
//删除材料
var delPaperFiles = exports.delPaperFiles = function delPaperFiles(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeDropbox/v1/deleteOrderCourseware"),
    method: "post",
    data: data
  });
};
//获取订单详情页的学生订单需求
var getNeedsList = exports.getNeedsList = function getNeedsList(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeDemand/v1/get?courseId=").concat(courseId),
    method: "get"
  });
};
//获取首页操作区的按钮状态
var getButtonList = exports.getButtonList = function getButtonList(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeStageTaskInstance/v1/getFiexdTaskInfo?courseId=").concat(courseId),
    method: "get"
  });
};
//获取论文评级失败扣除的gb
var getFailGb = exports.getFailGb = function getFailGb(courseId) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeRating/v1/preconditionsForFinalReview?courseId=").concat(courseId),
    method: "get"
  });
};
//讲师是否读了续接提醒（只有讲师web订单列表页用）
var readNotice = exports.readNotice = function readNotice(data) {
  return (0, _request.request)({
    otherUrl: "".concat(basicUrl1, "/order/updateRestartAccessFalgById"),
    method: "post",
    data: data
  });
};
//规划表更新课堂时间
var editClass = exports.editClass = function editClass(data) {
  return (0, _request.request)({
    otherUrl: "".concat(getReqUrl(), "/studPaperSpreeCourseOperation/v1/updpaperSpreeStageClassroom"),
    method: "post",
    data: data
  });
};
//获取上传文件密钥
var getAliOSSUploadSign = exports.getAliOSSUploadSign = function getAliOSSUploadSign(url, params) {
  return (0, _request.request)({
    otherUrl: url,
    method: "get",
    params: params
  });
};
//获取论文大礼包版本
var getPlanningTableVersion = exports.getPlanningTableVersion = function getPlanningTableVersion(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studPaperSpreeStage/v1/getPlanningTableVersion"),
    method: "get",
    params: params
  });
};
//获取需要上传的文章篇数
var getFileNum = exports.getFileNum = function getFileNum(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studPaperSpreeStage/v1/getOrderMaterialSpace"),
    method: "get",
    params: params
  });
};
//查询指定任务是否完成
var getTaskStatus = exports.getTaskStatus = function getTaskStatus(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/studPaperSpreeStageTaskInstance/v1/preDetection"),
    method: "get",
    params: params
  });
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detial-warp", staticStyle: { position: "relative" } },
    [
      _vm.tipStatus && _vm.orderMagData.extStudCourseFile
        ? _c(
            "div",
            { staticClass: "right-tips right-tips-sell shake-anmaite" },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/detail-aarrow.png"),
                  alt: "",
                },
              }),
              _vm._v(
                "\n      订单进行过程中遇到问题，到帮助信息里找办法吧！\n      "
              ),
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      _vm.tipStatus = false
                    },
                  },
                },
                [_vm._v("我知道了")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h5", [_vm._v("帮助信息")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detial-msg-box detial-msg-height" },
        [
          _vm.orderMagData.extStudCourseFile
            ? _c("p", { staticClass: "titles" }, [
                _vm._v(
                  _vm._s(_vm.orderMagData.extStudCourseFile.videoName) +
                    "\n        "
                ),
                _c("span", [
                  _vm._v(
                    "（如何进行" +
                      _vm._s(_vm.textType[_vm.orderMagData.spoType]) +
                      "辅导流程？）"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.orderMagData.extStudCourseFile.videos &&
          _vm.orderMagData.extStudCourseFile.videos.length
            ? _c(
                "div",
                { staticClass: "video-box" },
                _vm._l(
                  _vm.orderMagData.extStudCourseFile.videos || [],
                  function (video, index) {
                    return _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.playFun(video.videoUrl, "video")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "play",
                          attrs: {
                            src: require("@/assets/images/detail-play.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("video", {
                          staticClass: "video-style",
                          attrs: {
                            poster:
                              _vm.showVideoimg[
                                _vm.orderMagData.spoType != 65
                                  ? _vm.orderMagData.spoType
                                  : _vm.orderMagData.spoType + "-" + (index + 1)
                              ],
                            src: video.videoUrl,
                            controls: "false",
                          },
                        }),
                      ]
                    )
                  }
                ),
                0
              )
            : [
                _vm.orderMagData.extStudCourseFile
                  ? _c(
                      "div",
                      {
                        staticClass: "showvideo-box cursorpointer",
                        on: {
                          click: function ($event) {
                            return _vm.playFun(
                              _vm.orderMagData.extStudCourseFile.videoUrl,
                              "video"
                            )
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "play",
                          attrs: {
                            src: require("@/assets/images/detail-play.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("video", {
                          staticClass: "video-style",
                          attrs: {
                            poster: _vm.showVideoimg[_vm.orderMagData.spoType],
                            src: _vm.orderMagData.extStudCourseFile.videoUrl,
                            controls: "false",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.orderMagData.extStudCourseFile &&
          _vm.orderMagData.extStudCourseFile.courseFiles &&
          !(_vm.orderMagData.spoType == 65 && _vm.orderMagData.borderType)
            ? _c("div", { staticClass: "box-style" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "testplist-wrap" },
                  _vm._l(
                    _vm.orderMagData.extStudCourseFile.courseFiles,
                    function (courseFiles, index) {
                      return courseFiles.url &&
                        ((["资料整理 sample&模版（空白）.zip"].includes(
                          courseFiles.name
                        ) &&
                          [66].includes(_vm.orderMagData.spoType) &&
                          _vm.orderMagData.studPurchaseOrder
                            .studSpecialOrderExt &&
                          _vm.orderMagData.studPurchaseOrder.studSpecialOrderExt
                            .type == 1) ||
                          !["资料整理 sample&模版（空白）.zip"].includes(
                            courseFiles.name
                          ))
                        ? _c(
                            "li",
                            {
                              key: index,
                              staticClass: "cursorpointer",
                              on: {
                                click: function ($event) {
                                  return _vm.playFun(courseFiles.url)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(courseFiles.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e()
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "titles" }, [
      _vm._v("\n          帮助资料\n          "),
      _c("span", [_vm._v("（供讲师使用的本产品SAMPLE/编辑文档）")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
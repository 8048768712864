var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-list-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShow,
            center: "",
            width: "600px",
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [_vm._v("文件材料列表")]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.files, function (data, j) {
            return _c("div", { key: j }, [
              _c("div", { staticClass: "stage-title" }, [
                _vm._v(_vm._s(_vm.ClassFileType2Val[j]) + "文件"),
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "material-list" }, [
                _c(
                  "ul",
                  _vm._l(data, function (item, i) {
                    return _c("li", { key: i }, [
                      _c(
                        "div",
                        [
                          _c("a", { attrs: { href: item.url } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _vm._v(" "),
                          _c("svg-icon", {
                            attrs: {
                              "class-name": "dustbin-icon",
                              "icon-class": "dustbin",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.delFile(item.id)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", { staticClass: "remark" }, [
                          _vm._v(_vm._s(item.remark)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm._f("fmtDate")(item.createAt))),
                        ]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
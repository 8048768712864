"use strict";

var _interopRequireWildcard = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rewirteLog = rewirteLog;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.to-string");
require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/core-js/modules/es6.array.iterator.js");
require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/core-js/modules/es6.promise.js");
require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/core-js/modules/es6.object.assign.js");
require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _versionUpdate = _interopRequireDefault(require("@/libs/versionUpdate"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _qs = _interopRequireDefault(require("qs"));
require("@/icons");
var _api = _interopRequireDefault(require("@/api/api"));
var _axios = _interopRequireDefault(require("axios"));
var imageConversion = _interopRequireWildcard(require("image-conversion"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _vueLazyload = _interopRequireDefault(require("vue-lazyload"));
var _vuetify = _interopRequireDefault(require("vuetify"));
var _classbroTimetableWeb = _interopRequireDefault(require("classbro-timetable-web"));
require("vuetify/dist/vuetify.min.css");
require("material-design-icons-iconfont/dist/material-design-icons.css");
require("classbro-timetable-web/dist/lib/dayspan-vuetify.min.css");
require("@/utils/checkext");
require("@/api/filter");
// A modern alternative to CSS resets

// lang i18n
// global css

_vue.default.prototype.$qs = _qs.default;
// icon

_vue.default.prototype.$api = _api.default;

// import VueCropper from 'vue-cropper'//图片剪裁
// Vue.use(VueCropper)

//复制粘贴到浏览器
_vue.default.use(_vueClipboard.default);
_vue.default.use(_vueLazyload.default);
// 配置项
_vue.default.use(_vueLazyload.default, {
  preLoad: 1,
  attempt: 5
});
_vue.default.use(_vuetify.default);
_vue.default.use(_classbroTimetableWeb.default, {
  methods: {
    getDefaultEventColor: function getDefaultEventColor() {
      return '#1976d2';
    }
  }
});
_axios.default.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
_axios.default.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
_axios.default.defaults.transformRequest = [function (data) {
  if (Object.prototype.toString.call(data) === '[object FormData]') {
    return data;
  }
  var ret = '';
  if (typeof data !== 'string') {
    for (var it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&';
    }
  } else {
    ret = data;
  }
  return ret;
}];
/* 请求拦截器 */
// const url = 'http://192.168.1.83/'
_axios.default.interceptors.request.use(function (config) {
  var token = localStorage.getItem('teactoken');
  var notokenstatus = localStorage.getItem('notokenstatus');
  if (token) {
    if (notokenstatus == 0) {
      config.headers.token = "".concat(token);
    } else {}
    // 判断是否存在token，如果存在的话，则每个http header都加上token
  }
  return config;
}, function (err) {
  return Promise.reject(err);
});
_axios.default.interceptors.response.use(function (response) {
  localStorage.setItem('notokenstatus', 0);
  if (response.data.body != undefined) {
    if (response.data.body.code === 4011) {
      localStorage.clear();
      _router.default.push({
        path: '/login'
      });
    }
    return response;
  } else {
    return response;
  }
  // token 已过期，重定向到登录页面
}, function (error) {
  return Promise.reject(error);
});
_vue.default.prototype.$axios = _axios.default;
_vue.default.prototype.$compress = function (file) {
  var compressSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  //压缩图片
  //图片大小超过100k,(长度超过2000就压缩)
  // console.log(11,file.size)
  return new Promise(function (resolve, reject) {
    var _URL = window.URL || window.webkitURL;
    var needCompress = file.size / 1024 > compressSize; // 判定图片大小是否小于100kb
    // 这里需要计算出图片的长宽
    var img = new Image();
    img.onload = function () {
      file.width = img.width; // 获取到width放在了file属性上
      file.height = img.height; // 获取到height放在了file属性上
      // let valid = img.width > 1000 // 图片宽度大于2000
      console.log('压缩前', file);
      // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
      // if (valid || isLt2M) {
      if (needCompress) {
        imageConversion.compressAccurately(file, {
          size: 100
          // width: 1000
        }).then(function (res) {
          console.log('压缩后', res);
          resolve(res);
        });
      } else {
        resolve(file);
      }
    }; // 需要把图片赋值
    img.src = _URL.createObjectURL(file);
  });
};
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
window.electronUrl = 'classbro://openapp?secretKey=';

// set ElementUI lang to EN
_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});
_vue.default.config.productionTip = false;
// router.beforeEach((to, from, next) => {
// 	//判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
// 	versionTood.isNewVersion();
// 	next()
// })
function rewirteLog() {
  // console.log('process.env ',process.env.VUE_APP_ENV )
  console.log = function (log) {
    return !['PRO'].includes(process.env.VUE_APP_ENV) ? log : function () {};
  }(console.log);
}
rewirteLog();
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fileLoading,
          expression: "fileLoading",
        },
      ],
      staticClass: "detial-warp",
    },
    [
      _c("h5", [_vm._v("Completed  Files")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detial-msg-box filemsg-height filemsg-height-mini" },
        _vm._l(_vm.datasList, function (files, index) {
          return files.groupId == 30
            ? _c(
                "div",
                {
                  key: index,
                  staticClass: "file-wrap",
                  on: {
                    click: function ($event) {
                      return _vm.openUrl(files.url)
                    },
                  },
                },
                [
                  _vm._v("\n            " + _vm._s(files.name)),
                  _c("br"),
                  _vm._v("\n            " + _vm._s(files.date) + "\n     "),
                ]
              )
            : _vm._e()
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "page-container",
      style: _vm.staticPath.BG,
    },
    [
      _c("countdown"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fakeclass-container" },
        [
          _c(
            "el-popover",
            {
              staticClass: "tip-popover",
              attrs: { width: "280", trigger: "manual" },
              model: {
                value: _vm.showPopover,
                callback: function ($$v) {
                  _vm.showPopover = $$v
                },
                expression: "showPopover",
              },
            },
            [
              _c("div", { staticClass: "tip-box" }, [
                _c("span", { staticClass: "triangle" }),
                _vm._v(" "),
                _c("div", { staticClass: "tip-desc" }, [
                  _vm._v(
                    "\n                    想尝试课堂上课吗？来课堂训练场试一试吧\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tip-desc" }, [
                  _vm._v(
                    "\n                    在真实课堂尝试上下课会扣分哦\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "gocourse cursorpointer",
                  attrs: { slot: "reference" },
                  on: {
                    click: function ($event) {
                      return _vm.gotoUrl("/trainingground")
                    },
                  },
                  slot: "reference",
                },
                [_vm._v("\n                课堂模拟场\n            ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c("mini-map", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.modalShow,
                        expression: "!modalShow",
                      },
                    ],
                    on: { open: _vm.openModal },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("map-modal", {
                ref: "modal",
                on: {
                  close: function ($event) {
                    _vm.modalShow = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("main", [
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("section", { staticClass: "identity" }, [
              _c("div", { staticClass: "docker" }, [
                _c("img", {
                  attrs: { src: _vm.identityIcon.listen.todo },
                  on: {
                    click: function ($event) {
                      return _vm.reservedClass()
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(2),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "docker" }, [
                _vm.teachDone
                  ? _c("img", {
                      staticClass: "checkbox-style",
                      attrs: {
                        src: require("@/assets/images/hatch/fakeclass/check.png"),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.teachDone,
                      expression: "!teachDone",
                    },
                  ],
                  attrs: { src: _vm.identityIcon.teach.todo },
                  on: {
                    click: function ($event) {
                      return _vm.openLink(_vm.ModelType.TEACHER)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.teachDone,
                      expression: "teachDone",
                    },
                  ],
                  attrs: { src: _vm.identityIcon.teach.done },
                  on: {
                    click: function ($event) {
                      return _vm.openLink(_vm.ModelType.TEACHER)
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(3),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "docker" }, [
                _vm.stuDone
                  ? _c("img", {
                      staticClass: "checkbox-style",
                      attrs: {
                        src: require("@/assets/images/hatch/fakeclass/check.png"),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.stuDone,
                      expression: "!stuDone",
                    },
                  ],
                  attrs: { src: _vm.identityIcon.stu.todo },
                  on: {
                    click: function ($event) {
                      return _vm.openLink(_vm.ModelType.STUDENT)
                    },
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stuDone,
                      expression: "stuDone",
                    },
                  ],
                  attrs: { src: _vm.identityIcon.stu.done },
                  on: {
                    click: function ($event) {
                      return _vm.openLink(_vm.ModelType.STUDENT)
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(4),
              ]),
            ]),
            _vm._v(" "),
            _vm.btnEnable && _vm.isNext
              ? _c("div", { staticClass: "btn", on: { click: _vm.nextStep } }, [
                  _vm._v("\n                下一步\n            "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.btnEnable && !_vm.isNext
              ? _c("div", { staticClass: "btn wait" }, [_vm._v("等待中")])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("intention-modal", { ref: "intentionModal" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "title" }, [
      _c("span", [_vm._v("万能班长讲师全真模拟课")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "content-container" }, [
      _c("div", { staticClass: "label" }, [_vm._v("讲师你好！")]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                    全真模拟课流程下，您需要先进行\n                    "
        ),
        _c("span", [_vm._v("[老师] 授课")]),
        _vm._v(", 再以\n                    "),
        _c("span", [_vm._v("[学生] 身份听课并填写评分表。")]),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                    若需要在授课前体验听课，可点击\n                    "
        ),
        _c("span", [_vm._v("预约听课")]),
        _vm._v("，此步骤非强制要求。\n                "),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                    本环节会根据老师的综合表现进行考核，即考核不通过将会被淘汰，请认真对待！\n                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("span", [
        _vm._v(
          "\n                            预约听课\n                            "
        ),
        _c("span", { staticClass: "default" }, [_vm._v("(自选)")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("span", [
        _vm._v(
          "\n                            我是老师\n                            "
        ),
        _c("span", { staticClass: "tips" }, [_vm._v("(必选)")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("span", [
        _vm._v(
          "\n                            我是学生\n                            "
        ),
        _c("span", { staticClass: "tips" }, [_vm._v("(必选)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['datas'],
  data: function data() {
    return {
      modelObj: {
        show: '',
        btnno: '',
        btnyes: '',
        title: '',
        content: ''
      }
    };
  },
  mounted: function mounted() {
    this.modelObj = this.datas;
  },
  methods: {
    closeMask: function closeMask() {
      this.modelObj.show = false;
      this.gotoLogin();
    },
    yesFun: function yesFun() {
      this.modelObj.show = false;
      this.gotoLogin();
      this.$emit('yesfuns');
    },
    gotoLogin: function gotoLogin() {
      this.$router.push({
        path: "/login"
      });
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "explain-container" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: { visible: _vm.modalShow, width: "1000px", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.modalShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.defData.title)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "detail-container" }, [
            _c("div", { staticClass: "step" }, [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.defData.step.title)),
              ]),
              _vm._v(" "),
              _c("img", { attrs: { src: _vm.defData.step.url } }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "explain" },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.defData.explain.title)),
                ]),
                _vm._v(" "),
                _vm._l(_vm.defData.explain.list, function (detail, i) {
                  return _c(
                    "div",
                    { key: i },
                    _vm._l(detail, function (con, j) {
                      return _c(
                        "span",
                        {
                          key: j,
                          staticClass: "row",
                          class: {
                            special: !(j % 2 == 0),
                            bulecolor: j == 3 && i == 2,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toScoringTable(i, j)
                            },
                          },
                        },
                        [_vm._v(_vm._s(con))]
                      )
                    }),
                    0
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
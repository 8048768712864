"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enterClass = enterClass;
exports.isInClass = isInClass;
exports.isReaction = isReaction;
exports.isStuInClass = isStuInClass;
exports.openLink = openLink;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _VEnum = require("@/utils/VEnum");
var _Utils = require("@/utils/task/Utils");
/* 学生模式 是否显示进入课堂 按钮 */
function isStuInClass(status) {
  var IN_CLASS = _VEnum.StudentMakeClassStatus.IN_CLASS,
    WAIT = _VEnum.StudentMakeClassStatus.WAIT;
  return [IN_CLASS, WAIT].includes(status);
}

/* 讲师模式 是否显示进入课堂 按钮 */
function isInClass(status) {
  var ONGOING = _VEnum.OwnClassStatus.ONGOING,
    IN_CLASS = _VEnum.OwnClassStatus.IN_CLASS;
  return [ONGOING, IN_CLASS].includes(status);
}
function openLink(url) {
  if (url) window.open(url);
}

/* 进入课堂 */
function enterClass(teacherJoinUrl) {
  window.open(teacherJoinUrl);
}

/* 是否填写reaction */
function isReaction(status) {
  var IS_REPORT = _VEnum.StudentMakeClassStatus.IS_REPORT;
  return [IS_REPORT].includes(status);
}
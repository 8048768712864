"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _VEnum = require("@/utils/VEnum");
var _Utils = require("@/utils/task/Utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    type: Number,
    default: _VEnum.CalendarModelType.TEACHER
  },
  computed: {
    defData: function defData() {
      var STUDENT = _VEnum.CalendarModelType.STUDENT,
        TEACHER = _VEnum.CalendarModelType.TEACHER;
      return this.details[this.type];
    }
  },
  data: function data() {
    return {
      modalShow: false,
      details: (0, _defineProperty2.default)((0, _defineProperty2.default)({}, _VEnum.CalendarModelType.TEACHER, {
        title: "讲师模拟课说明",
        step: {
          title: "一、约课流程",
          url: require("@/assets/images/hatch/explain/teachnew.png")
        },
        explain: {
          title: "二、上课要求",
          list: [["1.请您以讲师身份安排模拟课上课时间，总授课时间在40分钟左右，分为30分钟", "课件讲解", "、七分钟资料整理", "制作思路讲解。"], ["2.约课后", "一个工作日", "内，您会收到课堂确认短信。"], ["3.收到短信后，请及时", "下载上课课件&资料整理模板", "进行备课。"], ["4.请在正式", "上课前10分钟", "进入课堂，上传课件，准备开始授课，", "请勿提前点击上课。"], ["5.模拟课", "不可取消或修改时间", "，若无故临时变更或缺席，则取消您在此阶段的参与资格，除不可抗力原因。"]]
        }
      }), _VEnum.CalendarModelType.STUDENT, {
        title: "学生模拟课听课说明",
        step: {
          title: "一、约课流程",
          url: require("@/assets/images/hatch/explain/stunew.png")
        },
        explain: {
          title: "二、听课要求",
          list: [["1.请您自主选择一堂模拟课参与听课，确定好上课时间，请", "准时参与", "。"], ["2.在课上，需", "踊跃回答", "讲师提出的问题。也可以针对授课内容", "主动提问", "，充分体验学生上课情景，有助于您之后的讲师工作开展，也是模拟课", "考核之一。"], ["3.讲师授课结束后，将进行5分钟的", "听课老师评价", "，请提前", "查看评分表", "查看并参考其中内容，互评环节将有助于您自己的授课技巧提升，也是模拟课", "考核之一", "。"], ["4.参与的模拟课", "不可取消或更改时间", "，若无故临时变更或缺席，则取消您在此阶段的参与资格，除不可抗力原因。"]
          // ["5.参与的模拟课是否可以取消或无故取消？"],
          // [
          //   " ",
          //   "不可以取消，若无故临时变更或缺席，则取消您在此阶段的参与资格，除不可抗力原因。"
          // ]
          ]
        }
      })
    };
  },
  methods: {
    show: function show(local) {
      /* 手动查看 */
      if (!local) {
        this.modalShow = true;
        return;
      }
      var VISITED = _VEnum.CalendarVisited.VISITED;
      /* 页面加载时自动判断 是否展示 */
      if (local) {
        var _getTeacherInfo = (0, _Utils.getTeacherInfo)(),
          id = _getTeacherInfo.id;
        var status = localStorage.getItem("calendar_".concat(this.type, "_").concat(id));
        /* 没有访问过 */
        if (!status) {
          localStorage.setItem("calendar_".concat(this.type, "_").concat(id), VISITED);
          this.modalShow = true;
        }
      }
    },
    toScoringTable: function toScoringTable(i, j) {
      if (j == 3 && i == 2) {
        window.open('https://www.classbro.com/survey/#/queryExam?id=254', "_blank");
        // https://www.classbro.com/survey/跳转链接需要再确定评分表跳转
      } else {
        return;
      }
    }
  }
};
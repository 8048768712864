var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "button",
        { staticClass: "download", on: { click: _vm.showReferenceFun } },
        [_vm._v("Reference  List")]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show,
            title: "Reference  List",
            center: "",
            width: "360px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "ul",
            { staticClass: "referencelist-style" },
            _vm._l(_vm.referenceList, function (item, index) {
              return _c("li", { key: index }, [
                _vm._v(_vm._s(item.referenceGuideName) + " "),
                _c("i", {
                  staticClass: "el-icon-download",
                  on: {
                    click: function ($event) {
                      return _vm.openUrl(
                        item.referenceGuide,
                        item.referenceGuideName
                      )
                    },
                  },
                }),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
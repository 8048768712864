var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "desc-box" },
      _vm._l(_vm.descArr, function (item) {
        return _c(
          "li",
          [
            _c("h4", [_vm._v(_vm._s(item.title))]),
            _vm._v(" "),
            _c("p", [_vm._v("\n        " + _vm._s(item.content) + "\n      ")]),
            item.lighttext
              ? _c("p", { staticClass: "light-text" }, [
                  _vm._v(_vm._s(item.lighttext) + "\n      "),
                ])
              : _vm._e(),
            _c("p"),
            item.expends ? _c("p") : _vm._e(),
            _vm._l(item.expends, function (text) {
              return _c("p", [
                _c("b", [_vm._v(_vm._s(text.type))]),
                _vm._v(_vm._s(text.content)),
              ])
            }),
            _vm._v(" "),
            _c("p"),
            _vm._v(" "),
            _c("p"),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("课堂信息")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "detial-msg-box" },
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.orderMagData.classroomList,
              border: "",
              "max-height": "240px",
            },
          },
          [
            _vm.orderMagData.spoType == 65 &&
            _vm.orderMagData.courseVersion == 2
              ? _c("el-table-column", {
                  attrs: { label: "时间周期阶段", width: "200" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.studPaperSpreeStageInstanceVo
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "\n               【 " +
                                        _vm._s(
                                          _vm._f("maTime")(
                                            scope.row
                                              .studPaperSpreeStageInstanceVo
                                              .startDate
                                          )
                                        ) +
                                        "-" +
                                        _vm._s(
                                          _vm._f("maTime")(
                                            scope.row
                                              .studPaperSpreeStageInstanceVo
                                              .endDate
                                          )
                                        ) +
                                        "】\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "【" +
                                        _vm._s(
                                          _vm.stageStatus[
                                            scope.row
                                              .studPaperSpreeStageInstanceVo
                                              .status
                                          ]
                                        ) +
                                        "】\n               " +
                                        _vm._s(
                                          scope.row
                                            .studPaperSpreeStageInstanceVo.name
                                        )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    976963736
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "课堂名称", "show-overflow-tooltip": true },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "课堂说明", "show-overflow-tooltip": true },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.description))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "预计开始时间", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.startTime))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "预计结束时间", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.endTime))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "实际开始时间", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.reaStartTime))]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "实际结束时间", width: "160" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(_vm._s(scope.row.reaEndTime))])]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "上课时长", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(scope.row.schooltime) + "分")]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "赠送时长", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.presenterTime) + "分"),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "获赞", align: "center", width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.up
                        ? _c("span", { staticClass: "score-box" }, [
                            scope.row.up.thumbsType == 1
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/haopin.png"),
                                  },
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/chapin.png"),
                                  },
                                }),
                            _vm._v(" "),
                            _c("b", { staticClass: "overflowdot" }, [
                              _vm._v(_vm._s(scope.row.up.number)),
                            ]),
                          ])
                        : _c("span", [_vm._v("---")]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-down", class: { "type-box": _vm.type == 2 } },
    [
      _vm.countTime && _vm.showTime
        ? _c("flip-countdown", {
            attrs: { deadline: _vm.countTime, labels: _vm.labels },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bg-linner",
          class: { redbg: _vm.type == 1, mt100: !_vm.showTime },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/hatch/lefthand.png"),
              alt: "",
            },
          }),
          _vm._v(_vm._s(_vm.text)),
          _c("img", {
            attrs: {
              src: require("@/assets/images/hatch/righthand.png"),
              alt: "",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
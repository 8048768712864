"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 日历 week 自动对焦 时间线
var _default = exports.default = {
  methods: {
    __autoScrollTop: function __autoScrollTop() {
      var childDom = document.getElementsByClassName("ds-day-today")[1];
      if (childDom) {
        var nodes = childDom.childNodes;
        var target = nodes[nodes.length - 3];
        var topPx = target.style.top;
        var top = topPx.substr(0, topPx.length - 2);
        var resultTop;
        if (+top > 400) resultTop = top - 200;
        setTimeout(function () {
          document.getElementsByClassName("ds-week-view-scrollable")[0].scrollTop = resultTop;
        }, 400);
      }
    }
  },
  mounted: function mounted() {
    this.__autoScrollTop();
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "desc-box" },
        [
          _vm.failData
            ? [
                _c("span", [_vm._v("总G:" + _vm._s(_vm.failData.sumGB) + " ")]),
                _vm._v(" "),
                _c("span", [_vm._v("总投诉G:" + _vm._s(_vm.failData.failGB))]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("投诉fail率:" + _vm._s(_vm.failData.formulaDesc)),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("Penalty Rate=0的订单被投诉不计入总投诉G中"),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            stripe: "",
            border: "",
            "header-cell-style": {
              color: "#999999",
              fontSize: "12px",
              fontWeight: "400",
              background: "#F3F3F3",
            },
            "row-style": { height: "36px" },
            "cell-style": { padding: "5px 0" },
            data: _vm.tableData,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "订单号",
              width: "420px",
              align: "left",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#FF8723", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.seeCouse(scope.row, scope.$index)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.orderNo))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "总获G币",
              prop: "sumGB",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "投诉时间",
              prop: "failTime",
              "show-overflow-tooltip": true,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "投诉原因",
              prop: "failExplain",
              "show-overflow-tooltip": true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
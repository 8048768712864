var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-info" },
    [
      _c("div", { staticClass: "info-head" }, [
        _c("img", {
          staticClass: "bg-img",
          attrs: {
            src: "http://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource%2F20200211%2Fbg1111.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "info-detail" }, [
          _c(
            "div",
            { staticClass: "teacher-icon", on: { click: _vm.changeheader } },
            [
              _c("div", { staticClass: "photo" }, [
                _c("img", {
                  attrs: { src: _vm._f("globalImgFilter")(_vm.ruleForm.src) },
                  on: { error: _vm.imgOnError },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("编辑")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "info-level" }, [
            _c(
              "div",
              {
                staticClass: "font-white",
                staticStyle: { "font-size": "20px", "margin-top": "10px" },
              },
              [_vm._v(_vm._s(_vm.ruleForm.nickName))]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "detail-content" }, [
        _c("div", { staticClass: "left-navs" }, [
          _c(
            "ul",
            _vm._l(_vm.navdata, function (item, i) {
              return _c(
                "li",
                {
                  class: [_vm.isactives == i ? "active" : ""],
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeTap(i)
                    },
                  },
                },
                [_vm._v(_vm._s(item)), _c("span")]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "base-content",
            class: { "fix-height": _vm.isactives },
          },
          [
            _vm.isactives == 0
              ? [
                  _c("div", { staticClass: "base-title" }, [
                    _vm._v("\n          讲师信息\n        "),
                  ]),
                  _vm._v(" "),
                  _c("el-divider"),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: { model: _vm.ruleForm, "label-width": "200px" },
              },
              [
                _vm.isactives == 0
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "nickName",
                            label: "昵称",
                            "label-width": "105px",
                            rules: {
                              required: true,
                              message: "昵称不能为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleForm.nickName,
                                expression: "ruleForm.nickName",
                              },
                            ],
                            staticClass: "informationt-input",
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              placeholder: "请输入您的昵称",
                            },
                            domProps: { value: _vm.ruleForm.nickName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ruleForm,
                                  "nickName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "birthday",
                            label: "生日",
                            "label-width": "105px",
                            rules: {
                              required: true,
                              message: "生日不能为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.ruleForm.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "birthday", $$v)
                              },
                              expression: "ruleForm.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "微信号",
                            prop: "wxAccount",
                            "label-width": "105px",
                            rules: {
                              required: true,
                              message: "微信号不能为空",
                              trigger: "blur",
                            },
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleForm.wxAccount,
                                expression: "ruleForm.wxAccount",
                              },
                            ],
                            staticClass: "informationt-input",
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              placeholder: "请输入您的微信号",
                            },
                            domProps: { value: _vm.ruleForm.wxAccount },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ruleForm,
                                  "wxAccount",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱", "label-width": "105px" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleForm.email,
                                expression: "ruleForm.email",
                              },
                            ],
                            staticClass: "informationt-input",
                            staticStyle: { width: "500px" },
                            attrs: {
                              type: "email",
                              size: "small",
                              placeholder: "请输入您的邮箱",
                            },
                            domProps: { value: _vm.ruleForm.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ruleForm,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址", "label-width": "105px" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ruleForm.local,
                                expression: "ruleForm.local",
                              },
                            ],
                            staticClass: "informationt-input",
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              placeholder: "请输入您的住址",
                            },
                            domProps: { value: _vm.ruleForm.local },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ruleForm,
                                  "local",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "105px" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "defaultlight-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.editTeacher("ruleForm")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-divider"),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "账号", "label-width": "105px" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "small", disabled: true },
                            model: {
                              value: _vm.ruleForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "mobile", $$v)
                              },
                              expression: "ruleForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", "label-width": "105px" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "small", disabled: true },
                            model: {
                              value: _vm.ruleForm.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "username", $$v)
                              },
                              expression: "ruleForm.username",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "选择学校", "label-width": "105px" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "500px" },
                              attrs: {
                                placeholder: "请选择学校",
                                disabled: true,
                                size: "small",
                              },
                              model: {
                                value: _vm.ruleForm.universityName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "universityName", $$v)
                                },
                                expression: "ruleForm.universityName",
                              },
                            },
                            _vm._l(_vm.universitys, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "profession",
                          attrs: { label: "专业", "label-width": "105px" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "500px",
                                overflow: "hidden",
                                "white-space": "nowrap",
                                "text-overflow": "ellipsis",
                              },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择",
                                disabled: true,
                                size: "small",
                              },
                              model: {
                                value: _vm.ruleForm.professionalCourses,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "professionalCourses",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.professionalCourses",
                              },
                            },
                            _vm._l(
                              _vm.professionalCoursesNames,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  staticClass: "overflowdot",
                                  staticStyle: {
                                    overflow: "hidden",
                                    "white-space": "nowrap",
                                    "text-overflow": "ellipsis",
                                  },
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "学历", "label-width": "105px" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { disabled: "", size: "small" },
                            model: {
                              value: _vm.ruleForm.eduName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "eduName", $$v)
                              },
                              expression: "ruleForm.eduName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "擅长学科", "label-width": "105px" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "small", disabled: true },
                            model: {
                              value: _vm.ruleForm.specializeds,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "specializeds", $$v)
                              },
                              expression: "ruleForm.specializeds",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "讲师类型", "label-width": "105px" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "500px" },
                              attrs: {
                                placeholder: "请输入老师类型",
                                size: "small",
                                disabled: "",
                              },
                              model: {
                                value: _vm.ruleForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "type", $$v)
                                },
                                expression: "ruleForm.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全职", value: "1" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "兼职", value: "2" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "讲师级别", "label-width": "105px" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              disabled: "",
                              placeholder: "请输入老师类型",
                            },
                            model: {
                              value: _vm.ruleForm.grade,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "grade", $$v)
                              },
                              expression: "ruleForm.grade",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "时薪", "label-width": "105px" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "small", disabled: "" },
                            model: {
                              value: _vm.ruleForm.hourlyWage,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "hourlyWage", $$v)
                              },
                              expression: "ruleForm.hourlyWage",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "danwei" }, [
                            _vm._v("G/H"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "授课经验", "label-width": "105px" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "small", disabled: "" },
                            model: {
                              value: _vm.ruleForm.teachingExperience,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "teachingExperience",
                                  $$v
                                )
                              },
                              expression: "ruleForm.teachingExperience",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "danwei" }, [
                            _vm._v("小时"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "已赠送时长",
                            "label-width": "105px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "small", disabled: "" },
                            model: {
                              value: _vm.ruleForm.presenterTimeLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.ruleForm,
                                  "presenterTimeLimit",
                                  $$v
                                )
                              },
                              expression: "ruleForm.presenterTimeLimit",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "danwei" }, [
                            _vm._v("MIN"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "最大抢单数",
                            "label-width": "105px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: { size: "small", disabled: "" },
                            model: {
                              value: _vm.ruleForm.maxLootNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "maxLootNum", $$v)
                              },
                              expression: "ruleForm.maxLootNum",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "danwei" }, [_vm._v("个")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-form-item", {
                        attrs: { label: "", "label-width": "105px" },
                      }),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.isactives == 1
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "账号", "label-width": "105px" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              placeholder: "请输入您的账号",
                              disabled: "",
                            },
                            model: {
                              value: _vm.ruleForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "mobile", $$v)
                              },
                              expression: "ruleForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "旧密码",
                            rules: [
                              {
                                required: true,
                                message: "请输入正确的密码格式",
                                validator: _vm.passwordvalidate,
                                trigger: "blur",
                              },
                            ],
                            "label-width": "105px",
                            prop: "password",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              minlength: "6",
                              maxlength: "12",
                              type: "password",
                              placeholder: "请输入之前的密码",
                            },
                            model: {
                              value: _vm.ruleForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "password", $$v)
                              },
                              expression: "ruleForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "新密码",
                            "label-width": "105px",
                            prop: "newpassword",
                            rules: [
                              {
                                required: true,
                                message: "请输入正确的密码格式",
                                validator: _vm.passwordvalidate,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              minlength: "6",
                              maxlength: "12",
                              "show-password": "",
                              placeholder: "请输入新密码",
                            },
                            model: {
                              value: _vm.ruleForm.newpassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "newpassword", $$v)
                              },
                              expression: "ruleForm.newpassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "确认密码",
                            "label-width": "105px",
                            prop: "cnewpassword",
                            rules: [
                              {
                                required: true,
                                message: "请输入正确的密码格式",
                                validator: _vm.passwordvalidate,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "500px" },
                            attrs: {
                              size: "small",
                              minlength: "6",
                              maxlength: "12",
                              "show-password": "",
                              placeholder: "请再次输入新密码",
                            },
                            model: {
                              value: _vm.ruleForm.cnewpassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "cnewpassword", $$v)
                              },
                              expression: "ruleForm.cnewpassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "105px" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "default-btn",
                              on: { click: _vm.cancel },
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "defaultlight-btn",
                              on: { click: _vm.editPassword },
                            },
                            [_vm._v("确定")]
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.updatePhoto.show
        ? _c("div", { staticClass: "new-mask" }, [
            _c("div", { staticClass: "mask-content mask-width560" }, [
              _c("h3", [
                _vm._v("编辑头像 "),
                _c(
                  "b",
                  {
                    on: {
                      click: function ($event) {
                        _vm.updatePhoto.show = false
                      },
                    },
                  },
                  [_vm._v("+")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "uploadIcon" }, [
                _c("ul", { staticClass: "flex-allcenter" }, [
                  _c("li", [
                    !_vm.imgcode
                      ? _c("div", [
                          _c("img", {
                            staticClass: "addimg",
                            attrs: {
                              src: require("../../assets/images/upload-add.png"),
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("请上传少于4M的半身照 格式jpg/png")]),
                        ])
                      : _c("img", { attrs: { src: _vm.imgcode } }),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "kyc-passin",
                      attrs: { type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.getbase64($event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.imgcode
                      ? _c("span", [_vm._v("点击重新上传")])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.ulpolading,
                        expression: "ulpolading",
                      },
                    ],
                    staticClass: "defaultlight-btn",
                    on: { click: _vm.ulpoladImg },
                  },
                  [_vm._v("确认上传")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("edit-avator", {
        ref: "editAvator",
        attrs: { teacId: _vm.id },
        on: { refresh: _vm.view_detail },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "num-box" }, [
      _c("div", [
        _c("div", { staticClass: "font-white" }, [_vm._v("0")]),
        _vm._v(" "),
        _c("div", { staticClass: "font-white" }, [_vm._v("内容获赞")]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "font-white" }, [_vm._v("0")]),
        _vm._v(" "),
        _c("div", { staticClass: "font-white" }, [_vm._v("关注")]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "font-white" }, [_vm._v("0")]),
        _vm._v(" "),
        _c("div", { staticClass: "font-white" }, [_vm._v("被关注")]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "font-white" }, [_vm._v("0")]),
        _vm._v(" "),
        _c("div", { staticClass: "font-white" }, [_vm._v("积分")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
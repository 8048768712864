"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Summary = _interopRequireDefault(require("./Summary"));
var _Stage = require("@/views/dissertation/cfg/Stage");
var _Dissertation = require("@/utils/Dissertation");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    GSummary: _Summary.default
  },
  data: function data() {
    return {
      OrderImg: _Stage.OrderImg,
      StageType2Val: _Stage.StageType2Val
    };
  },
  props: {
    isHeader: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function _default() {}
    },
    index: {
      type: Number,
      default: 0
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    overflow: function overflow(strings) {
      var MAX = 67;
      var len = (0, _Dissertation.getChars)(strings);
      return len >= MAX;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("../css/conmmon.scss");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['orderMagData', 'childtype', 'daobidata'],
  data: function data() {
    return {
      specilTypeArr: {
        1: "资料整理",
        2: "Appeal Letter",
        4: "作业订单",
        8: "论文批改",
        16: "Proofreading",
        32: "Past-paper",
        64: "论文修改",
        128: "考试订单"
      }
    };
  },
  created: function created() {},
  computed: {},
  methods: {
    downFile: function downFile(url) {
      window.open(url);
    }
  }
};
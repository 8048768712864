"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _app = _interopRequireDefault(require("./modules/app"));
var _settings = _interopRequireDefault(require("./modules/settings"));
var _user = _interopRequireDefault(require("./modules/user"));
var _hatch = _interopRequireDefault(require("./modules/hatch"));
var _userchange = _interopRequireDefault(require("./modules/userchange"));
var _dissertation = _interopRequireDefault(require("./modules/dissertation"));
var _medal = _interopRequireDefault(require("./modules/medal"));
var _handsup = _interopRequireDefault(require("./modules/handsup"));
var _elcApp = _interopRequireDefault(require("./modules/elcApp"));
var _follow = _interopRequireDefault(require("./modules/follow"));
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    settings: _settings.default,
    user: _user.default,
    hatch: _hatch.default,
    userchange: _userchange.default,
    dissertation: _dissertation.default,
    medal: _medal.default,
    handsup: _handsup.default,
    elcApp: _elcApp.default,
    follow: _follow.default
  },
  getters: _getters.default
});
var _default = exports.default = store;
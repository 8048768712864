import { render, staticRenderFns } from "./helpmsg.vue?vue&type=template&id=b2d4d860&scoped=true"
import script from "./helpmsg.vue?vue&type=script&lang=js"
export * from "./helpmsg.vue?vue&type=script&lang=js"
import style0 from "./helpmsg.vue?vue&type=style&index=0&id=b2d4d860&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2d4d860",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b2d4d860')) {
      api.createRecord('b2d4d860', component.options)
    } else {
      api.reload('b2d4d860', component.options)
    }
    module.hot.accept("./helpmsg.vue?vue&type=template&id=b2d4d860&scoped=true", function () {
      api.rerender('b2d4d860', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/detail/conponment/helpmsg.vue"
export default component.exports
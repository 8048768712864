var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info.medals && _vm.info.medals.length
    ? _c("div", { staticClass: "medal-item" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", { staticClass: "type" }, [
            _vm._v(_vm._s(_vm.typeList[_vm.info.type])),
          ]),
          _vm._v("勋章"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "img-box" },
          _vm._l(_vm.info.medals || [], function (item) {
            return _c("div", { staticClass: "img-item" }, [
              _c("div", { staticClass: "num" }, [
                _vm._v("x" + _vm._s(item.count)),
              ]),
              _vm._v(" "),
              _c("img", { attrs: { src: item.imageUrl, alt: "" } }),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.title))]),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "line" }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Utils = require("@/utils/task/Utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      videoArr: ["https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20230222/%E6%80%BB%E5%90%88%E6%88%90-_1.mp4",
      //定制辅导
      "https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/trainVideos/%E5%90%88%E5%B9%B6%E7%AC%AC%E4%B8%89%E7%89%88.mp4",
      //论文大礼包
      "https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20230222/%E6%96%B0%E7%89%88.mp4",
      //考前突击
      "https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20190322/%E7%89%B9%E6%AE%8A%E8%AE%A2%E5%8D%95%E6%96%B0%E4%BA%8C%E7%BB%B4%E7%A0%8120210423.mp4" //特殊订单
      ],
      showvideo: false,
      videoduration: "",
      videocurrent: "00:00",
      videourl: ""
    };
  },
  methods: {
    open: function open(num) {
      this.showvideo = true;
      this.videourl = this.videoArr[num];
      this.videoready();
    },
    videoready: function videoready() {
      var _this = this;
      var that = this;
      var timer = setInterval(function () {
        var myvideo = document.getElementById("myvideo");
        if (myvideo) {
          myvideo.controls = false;
          myvideo.addEventListener("timeupdate", function (event) {
            var showtimes = (0, _Utils.formatSeconds)(event.target.currentTime);
            _this.videocurrent = showtimes.m + ":" + showtimes.s;
            if (_this.videoduration && _this.videoduration != '00:00') {
              return;
            }
            var times = (0, _Utils.formatSeconds)(event.target.duration);
            _this.videoduration = times.m + ":" + times.s;
          });
          myvideo.addEventListener("ended", function () {
            _this.closevideo();
            _this.$emit("addstep");
          });
          clearInterval(timer);
        }
      }, 1000);
    },
    closevideo: function closevideo() {
      this.showvideo = false;
      this.videourl = "";
      this.videoduration = '';
    }
  }
};
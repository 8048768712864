var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stage-row" },
    [
      _c("G-Row", { attrs: { isHeader: true } }),
      _vm._v(" "),
      _c(
        "draggable",
        _vm._b(
          {
            attrs: {
              move: _vm.onMoveCallback,
              disabled: _vm.readonly || _vm.draggableDisabled,
            },
            on: {
              start: function ($event) {
                _vm.drag = true
              },
              end: _vm.moveEnd,
            },
            model: {
              value: _vm.stageList,
              callback: function ($$v) {
                _vm.stageList = $$v
              },
              expression: "stageList",
            },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        [
          _c(
            "transition-group",
            {
              attrs: {
                type: "transition",
                name: !_vm.drag ? "flip-list" : null,
              },
            },
            _vm._l(_vm.stageList, function (item, i) {
              return _c("G-Row", {
                key: "_key" + i,
                staticClass: "list-group-item",
                attrs: {
                  data: item,
                  index: i,
                  isLast: i === _vm.stageList.length - 1,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      current: {},
      showMedal: false
    };
  },
  methods: {
    show: function show(item) {
      this.showMedal = true;
      this.current = JSON.parse(JSON.stringify(item));
      console.log('this.current', this.current);
    }
  }
};
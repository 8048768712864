"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    closeModal: function closeModal() {
      this.$emit('close');
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['datas', 'type'],
  data: function data() {
    return {};
  },
  methods: {
    closeModal: function closeModal() {
      this.$emit("closeModal");
    },
    receivOrder: function receivOrder() {
      console.log('走了吗');
      this.$emit("receivOrder");
    },
    showTestbox: function showTestbox() {
      this.$emit("closeModal");
    }
  }
};
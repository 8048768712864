var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "menu-title", attrs: { id: "2" } }, [
        _vm._v("万能讲师规范手册"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item-title", attrs: { id: "2.1" } }, [
          _vm._v("1.部门介绍"),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "2.1.1" } }, [
          _c("div", [
            _vm._v("● 师资渠道：负责讲师的简历筛选、试讲考核、入职签约等事宜"),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("（紧急联系人企业微信@ Chloe）")]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "2.1.2" } }, [
          _c("div", [
            _vm._v(
              "● 师资孵化：负责讲师的学术检测、入职培训、新手期孵化等事宜"
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("（紧急联系人企业微信@ 培训师（曼曼））")]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "2.1.3" } }, [
          _c("div", [
            _vm._v(
              "● 师资运营：负责各学科讲师订单的委派、薪资核算、订单跟进等事宜"
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("（企业微信@ Eureka）")]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "2.1.4" } }, [
          _c("div", [
            _vm._v(
              "● 讲师培优：负责监控教学服务质量、处理客户投诉、订单退款及讲师奖惩复核等事宜"
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("（企业微信@ Elena 段）")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-title", attrs: { id: "2.2" } }, [
          _vm._v("2.工作软件"),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "2.2.3" } }, [
          _c("div", { staticClass: "third-title" }, [
            _vm._v("\n        ● 万能讲师手机端APP\n      "),
          ]),
          _vm._v(" "),
          _c("img", {
            staticStyle: { margin: "-10px 0 -20px 0" },
            attrs: { src: require("@/assets/images/help/app.png"), alt: "" },
          }),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "2.2.1" } }, [
          _c("div", { staticClass: "third-title " }, [
            _vm._v("● 通讯软件：企业微信"),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "为提升公司各职能部门与兼职讲师的沟通效率，以及接单系统自动生成学习交流群，公司使用"
            ),
            _c("span", { staticClass: "desc" }, [
              _vm._v("“企业微信”作为万能讲师的唯一通讯办公软件"),
            ]),
            _vm._v("。讲师入职后，"),
            _c("span", { staticClass: "desc" }, [
              _vm._v("请打开手机通知提醒、及时关注企微消息"),
            ]),
            _vm._v("。"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/help/ruanjian1.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: require("@/assets/images/help/ruanjian2.png"),
                alt: "",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "2.2.2" } }, [
          _c("div", { staticClass: "third-title " }, [
            _vm._v("● 教学软件：万能讲师网页端&ClassRoom"),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "万能讲师网页端为讲师提供培训视频、订单管理、课堂日程、工资记录等多信息，是老师正式接单的唯一操作平台。讲师可经排课表进入授课课堂（ClassRoom），课程自动录制并保存在订单后台。如遇学生无法进入或正常使用ClassRoom，请讲师及时向师资部门报备，方可使用腾讯会议等其他软件并自行保存课程回放、提交师资组复核教学质量、核算薪资。"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-title", attrs: { id: "2.3" } }, [
          _vm._v("3. 讲师接单制度"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "运营伙伴会根据讲师信息与订单需求，在企业微信发起询单。请讲师务必点击订单链接、仔细查阅订单信息（包括但不限于学员需求、订单DDL、备课资料、预计收入等），并通过企微及时回复接单意愿，并在1小时内在万能讲师平台操作“接单”。讲师点击接单后，系统会自动生成学习交流群（包含学员、运营、讲师、培训师、销售等人员）。请讲师主动在群内打招呼，与学生确认破冰课的上课时间。"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "desc" }, [
          _vm._v(
            "* 如遇无法及时操作接单但接单意向明确，请讲师与运营协商好“接单”时间，以免耽误订单进度"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-title", attrs: { id: "2.7" } }, [
          _vm._v("4. 讲师吐单惩罚"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("以下两种情况均被视为【讲师吐单】：")]),
        _vm._v(" "),
        _c("div", [_vm._v("●讲师在企微内答应接单但系统未操作接单")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "●讲师系统操作接单后、破冰课前因个人原因提出更换讲师，与【正式接单后的甩课行为】（讲师在破冰课后任意阶段因个人原因提出更换讲师）一样，均会影响讲师信誉及后期派单。"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("请讲师谨慎回复接单意向，并及时在平台接单。具体处罚如下:"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("●【讲师"),
          _c("span", { staticClass: "desc" }, [_vm._v("首次吐单")]),
          _vm._v("】首次出现予以"),
          _c("span", { staticClass: "desc" }, [_vm._v("警告")]),
          _vm._v("；出现"),
          _c("span", { staticClass: "desc" }, [_vm._v("第2次")]),
          _vm._v("，"),
          _c("span", { staticClass: "desc" }, [_vm._v("扣除")]),
          _vm._v("该订单收入的"),
          _c("span", { staticClass: "desc" }, [_vm._v("50%")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("●【讲师"),
          _c("span", { staticClass: "desc" }, [_vm._v("吐单两次及以上")]),
          _vm._v("】"),
          _c("span", { staticClass: "desc" }, [_vm._v("扣除")]),
          _vm._v("该订单收入的"),
          _c("span", { staticClass: "desc" }, [_vm._v("100%")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("●【"),
          _c("span", { staticClass: "desc" }, [_vm._v("中途甩课")]),
          _vm._v("】："),
          _c("span", { staticClass: "desc" }, [_vm._v("扣除")]),
          _vm._v("该订单收入的"),
          _c("span", { staticClass: "desc" }, [_vm._v("200%")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-title", attrs: { id: "2.5" } }, [
          _vm._v("5.订单质量管理"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "师资部门对讲师的群内答疑、授课质量等教学服务项目会进行不定期的QC。"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("● 对于出色完成教学服务的讲师，公司会给予以下奖励："),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "success-icon" }, [_vm._v("√")]),
          _vm._v(" 学生赞：学生课后点赞讲师将获得当节课时费10%的bonus奖励"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "success-icon" }, [_vm._v("√")]),
          _vm._v(
            " 高分bonus：根据不同地区的出分结果，额外给予订单预计收入的10%"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "success-icon" }, [_vm._v("√")]),
          _vm._v(" 优先派单：提升讲师的订单委派优先权"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "success-icon" }, [_vm._v("√")]),
          _vm._v("时薪上调：累计完成授课80小时且无客诉"),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("● 如出现以下问题，系统将下调讲师的派单优先级：")]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "error-icon" }, [_vm._v("×")]),
          _vm._v(" 向学生透露毕业院校、留学国家等个人信息"),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "error-icon" }, [_vm._v("×")]),
          _vm._v(
            " 授课时衣着不整、背景昏暗杂乱、精神面貌不佳（频繁打哈欠、喝水、吃东西、看手机等）"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "error-icon" }, [_vm._v("×")]),
          _vm._v(
            " 无课件授课/白板授课/无备课痕迹授课/授课无规划/授课中使用翻译软件"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { staticClass: "error-icon" }, [_vm._v("×")]),
          _vm._v(
            " 企微响应不及时（包括但不限于6小时不为学生答疑、未及时回复派单培训信息等）\n      出现有效客户投诉或累计2次及以上QC不合格，讲师将被扣罚受影响订单总收入的200%，且停止委派订单、重新接受培训。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("● 但凡出现下列问题，讲师将被扣除当前订单收入的200%："),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("❗ 与学生交换微信/邮箱/小红书/Ins等私人联系方式")]),
        _vm._v(" "),
        _c("div", [_vm._v("❗ 辅导中出现知识点讲解错误/未按订单要求提供产出")]),
        _vm._v(" "),
        _c("div", [
          _vm._v("❗ 与学生发生冲突/与内部工作人员在学生群内发生冲突"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "若给公司造成严重损失（包括但不限于联系学生私下授课、或学生取消委托退款等），公司有权立即与讲师终止合作，并保留对该讲师的追责权。"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item-title", attrs: { id: "2.6" } }, [
          _vm._v("6.离职解约流程"),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "●讲师因个人原因主动离职，可在企微内联系师资部门紧急联系人。确认无在途订单后，师资部门在系统做解约操作"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("●讲师因个人原因多次拒单或"),
          _c("span", { staticClass: "desc" }, [_vm._v("2个月内")]),
          _vm._v(
            "无法接单，视为讲师无合作意向。师资部门会联系讲师，确认最终意向，并在系统做解约操作。\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom" }, [
          _vm._v(
            "本规范属内部资料，禁止外传；最终解释权归万能班长师资部所有。"
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            " 随着公司业务发展需求，以上各条例或将随之部分修改，届时以系统中最新手册为准。"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
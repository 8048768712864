var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("DropBox")]),
    _vm._v(" "),
    _vm.allshow > 0
      ? _c(
          "div",
          {
            staticClass: "detial-msg-box",
            staticStyle: { overflow: "hidden" },
          },
          _vm._l(_vm.dorplist, function (list, index) {
            return list.flieArr.length &&
              list.types.includes(_vm.orderMagData.spoType)
              ? _c(
                  "ul",
                  { key: index, staticClass: "dropbox-box" },
                  [
                    list.flieArr.length
                      ? [
                          _c("li", [
                            _c("h6", {
                              class: "color" + index,
                              style: { background: _vm.colorArr[index % 5] },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "cursorpointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.openfiles(list)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(list.groupName))]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(list.flieArr, function (file, indexs) {
                            return _c("li", { key: indexs }, [
                              _c(
                                "p",
                                {
                                  staticClass: "colorbule cursorpointer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openfiles(file.url)
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(file.name) + " ")])]
                              ),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(file.createAt))]),
                            ])
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e()
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.allshow <= 0
      ? _c(
          "div",
          {
            staticClass: "detial-msg-box",
            staticStyle: { overflow: "hidden", margin: "0" },
          },
          [_vm._m(0)]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticStyle: { overflow: "hidden", margin: "0" } }, [
      _c("li", [_vm._v("暂无Dropbox")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "medal-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "420px",
            "close-on-click-modal": false,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("div", { staticClass: "medal-box" }, [
            _vm.showAnimation
              ? _c("div", { staticClass: "medal-bg", attrs: { id: "medal" } }, [
                  _c("div", { staticClass: "medal-content" }, [
                    _vm.current.medalImage
                      ? _c("img", {
                          staticClass: "medal-img",
                          attrs: { src: _vm.current.medalImage, alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "medal-text" }, [
                      _c("div", { staticClass: "medal-name" }, [
                        _vm._v(_vm._s(_vm.current.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "medal-desc" }, [
                        _vm._v(_vm._s(_vm.current.title)),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showAnimation
              ? _c("div", { staticClass: "medal-bg", attrs: { id: "medal1" } })
              : _vm._e(),
          ]),
          _vm._v(" "),
          !_vm.showAnimation
            ? _c("div", { staticClass: "medal-content-two-box" }, [
                _c(
                  "div",
                  { staticClass: "medal-content-two" },
                  [
                    _vm.current.medalImage
                      ? _c("img", {
                          staticClass: "medal-img-noscale",
                          attrs: { src: _vm.current.medalImage, alt: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.current.headImage && !_vm.showAnimation
                      ? [
                          _c("span", { staticClass: "add" }, [_vm._v("+")]),
                          _vm._v(" "),
                          _vm.current.headImage
                            ? _c("img", {
                                staticClass: "medal-img-noscale head-img",
                                attrs: { src: _vm.current.headImage, alt: "" },
                              })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "medal-text" }, [
                      _c("div", { staticClass: "medal-name" }, [
                        _vm._v(_vm._s(_vm.current.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "medal-desc" }, [
                        _vm._v(_vm._s(_vm.current.title)),
                      ]),
                    ]),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "operate-btn",
              attrs: { loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.receiveMedal(3)
                },
              },
            },
            [_vm._v("挂上勋章墙")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
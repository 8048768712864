var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "paper-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "560px",
            "before-close": _vm.closeModal,
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("论文大礼包3.0辅导规划表说明"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", [_vm._v("1、什么是阶段？")]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "整个论文大礼包订单，将拆分为五个阶段来完成，每个阶段需要您来安排时间。"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", [_vm._v("2、什么是任务？")]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "每个阶段下面将会给您设定好课程所需要的固定任务，您需要完成所有阶段下面的任务。"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item" }, [
            _c("div", [_vm._v("3、如何排课？")]),
            _vm._v(" "),
            _c("div", [
              _vm._v("请在阶段内进行排课，排课时间不要超过阶段时间哦。"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("注：该功能自"),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v(_vm._s(_vm.startTime) + "起"),
            ]),
            _vm._v("新接订单开始使用，此前依旧使用旧版本。"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "footer-btn",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "span",
                { staticClass: "confirm-btn", on: { click: _vm.closeModal } },
                [_vm._v("我知道了")]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
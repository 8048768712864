"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false
    };
  },
  methods: {
    show: function show(row) {
      // this.isShow = true
      this.isShow = [1, null].includes(row.extTeacUserDetailsExt.coursewareTeacherFlag) ? true : false;
    },
    goToFollow: function goToFollow() {
      var _this = this;
      this.isShow = false;
      this.$nextTick(function () {
        if (_this.$parent.$refs.navbar) {
          _this.$parent.$refs.navbar.showBeikeFollow();
        } else {
          _this.$parent.$parent && _this.$parent.$parent.$parent && _this.$parent.$parent.$parent.$refs.navbar && _this.$parent.$parent.$parent.$refs.navbar.showBeikeFollow();
        }
      });
    }
  }
};
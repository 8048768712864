"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _contentnull = _interopRequireDefault(require("@/views/contentnull"));
var _medalList = _interopRequireDefault(require("./medalList"));
var _highRewardList = _interopRequireDefault(require("./highRewardList"));
var _Utils = require("@/utils/task/Utils");
var _teacherItem = _interopRequireDefault(require("./teacherItem"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    contentnull: _contentnull.default,
    medalList: _medalList.default,
    highRewardList: _highRewardList.default,
    teacherItem: _teacherItem.default
  },
  props: {
    likeData: {
      type: Object,
      default: function _default() {}
    },
    type: {
      type: String,
      default: ''
    },
    redType: {
      type: String,
      default: ''
    },
    texts: {
      type: Object,
      default: function _default() {}
    },
    redbagObj: {
      type: Object,
      default: function _default() {}
    },
    teacherType: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      typeList: {
        1: '收入榜',
        2: "获赞榜",
        3: "完成订单数",
        4: '活跃榜',
        5: "高反馈评价榜",
        6: "学生最爱榜"
      },
      textList: {
        1: '学生课堂给予好评反馈排名',
        2: '每月总计获赞值',
        4: '聊天消息回复学生数量纪录排名',
        5: '当月高反馈订单总G',
        6: '学生课堂给予好评反馈排名'
      }
    };
  },
  created: function created() {},
  methods: {
    globalImgFilter: _Utils.globalImgFilter,
    openSingleredbag: function openSingleredbag(val) {
      if (this.redbagObj[this.redType]) {
        this.$parent.openSingleredbag(val);
      }
    }
  }
};
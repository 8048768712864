"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
var _moment = _interopRequireDefault(require("moment"));
var _Utils = require("@/utils/task/Utils");
var _default = exports.default = {
  methods: {
    fmtTimestamp: function fmtTimestamp(time) {
      var ymdhm = (0, _moment.default)(time).format("YYYY-MM-DD HH:mm");
      var hhmm = (0, _moment.default)(time).format("HH:mm");
      var year = (0, _moment.default)(time).year();
      var month = (0, _moment.default)(time).month();
      var today = +(0, _moment.default)(time).format("D");
      var hour = (0, _moment.default)(time).hour();
      return {
        ymdhm: ymdhm,
        hhmm: hhmm,
        year: year,
        month: month,
        today: today,
        hour: hour
      };
    },
    /* 计算 时间 区间 */fmtDuration: function fmtDuration(startTime, endTime) {
      return (0, _moment.default)(endTime).diff((0, _moment.default)(startTime), "minutes") / 60;
    },
    /* 凌晨00:39-凌晨01:09 */getZHDateRange: function getZHDateRange(startTime, endTime) {
      return (0, _moment.default)(startTime).format("A") + (0, _moment.default)(startTime).format("HH:mm") + "-" + (0, _moment.default)(endTime).format("A") + (0, _moment.default)(endTime).format("HH:mm");
    },
    getDeadLine: function getDeadLine(val) {
      val = val.replace(/\-/g, '/'); //写这么麻烦还不是为了兼容safir
      var time = (0, _Utils.formatTime)(val, 'h');
      var time1 = '';
      if (Number(time) < 12) {
        time1 = 'AM' + (0, _Utils.formatTime)(val, 'h:m');
      } else {
        time1 = 'PM' + (0, _Utils.formatTime)(val, 'h:m');
      }
      return time1;
    }
  }
};
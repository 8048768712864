"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mymedal = _interopRequireDefault(require("@/views/account/medal/mymedal"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    mymedal: _mymedal.default
  },
  data: function data() {
    return {};
  }
};
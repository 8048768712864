"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _medalModal = _interopRequireDefault(require("@/views/account/medal/medalModal"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    medalsList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    medalCount: {
      type: Number,
      default: 0
    },
    info: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {
    medalModal: _medalModal.default
  },
  data: function data() {
    return {
      showNum: 0,
      screenWidth: ""
    };
  },
  watch: {
    screenWidth: function screenWidth() {
      this.getShowNum();
    }
  },
  mounted: function mounted() {
    this.getShowNum();
    this.initScreenWidth();
  },
  methods: {
    show: function show() {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.$refs.medalModal.getTeacMedal();
      });
    },
    initScreenWidth: function initScreenWidth() {
      this.screenWidth = document.body.clientWidth; //监听页面缩
      var _this = this;
      window.addEventListener('resize', function () {
        _this.screenWidth = document.body.clientWidth;
      });
    },
    getShowNum: function getShowNum() {
      var _this3 = this;
      this.$nextTick(function () {
        if (_this3.$refs.medalbox) {
          var width = window.getComputedStyle(_this3.$refs.medalbox).width;
          width = width && width.split('px')[0];
          _this3.showNum = Math.floor((width - 80) / 60);
        }
      });
    }
  }
};
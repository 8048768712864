"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trainingplan = exports.trainingground = exports.schedulecard = exports.letter = exports.intention = exports.idform = exports.fakeclass = exports.fake = void 0;
/* 讲师形象 */
var idform = exports.idform = require("./idform");
/* 课程表 */
var schedulecard = exports.schedulecard = require("./schedulecard");
/* 训练计划、小地图流程 */
var trainingplan = exports.trainingplan = require("./trainingplan");
/* 课堂训练场 */
var trainingground = exports.trainingground = require("./trainingground");
/* 模拟订单操作 */
var fake = exports.fake = require("./fake");
/* 意向表 */
var intention = exports.intention = require("./intention");
/* 万能班长讲师全真模拟课 fakeclass */
var fakeclass = exports.fakeclass = require("./fakeclass");
/* 讲师信 */
var letter = exports.letter = require("./letter");
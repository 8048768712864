"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("../css/conmmon.scss");
var _reference = _interopRequireDefault(require("../wenya/reference"));
var _orderdetialVEnum = require("@/utils/orderdetialVEnum");
var _ordertest = require("@/api/ordertest");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['copywritingCourse', 'orderMagData'],
  data: function data() {
    return {
      OrderStatus: _orderdetialVEnum.OrderStatus,
      specilTypeArr: {
        1: "group order",
        2: "Writing materials",
        3: "Quizzes/Exams/Weekly tasks",
        4: "Presentation",
        5: "Revision",
        8: "Profreading",
        9: "Editiong",
        11: "Dissertation"
      },
      fileLoading: false
    };
  },
  created: function created() {},
  computed: {},
  components: {
    reference: _reference.default
  },
  methods: {
    openUrl: function openUrl(url) {
      window.open(url);
    },
    downloadFile: function downloadFile(file) {
      var _this = this;
      var downloadArr = [{
        fileName: file.referenceStyle,
        fileUrl: file.referenceGuide
      }];
      this.fileLoading = true;
      (0, _ordertest.getZipFilePlus)(downloadArr).then(function (res) {
        if (res.status == 200) {
          _this.fileLoading = false;
          _this.$message({
            type: "success",
            message: "文件打包成功"
          });
          var url = res.body.url;
          window.open(url, '_blank');
        } else {
          _this.fileLoading = false;
          _this.$message({
            type: "warning",
            message: res.body.msg
          });
        }
      }).catch(function (err) {
        _this.$message({
          type: "warning",
          message: body.msg
        });
      });
    }
  }
};
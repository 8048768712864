"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _RightRow = _interopRequireDefault(require("./RightRow"));
var _Table = require("@/views/dissertation/cfg/Table");
var _Modal = require("@/views/dissertation/components/Modal");
var _vuex = require("vuex");
var _VEnum = require("@/utils/VEnum");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    GRightRow: _RightRow.default,
    AddClassModal: _Modal.AddClassModal
  },
  filters: {
    fmtSpan: function fmtSpan(val) {
      var _index = val.indexOf("&");
      if (_index > 0) {
        return "".concat(val.substr(0, _index + 1), "\n").concat(val.substr(_index + 1));
      }
      return val;
    }
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["readonly", "progressOver"])),
  props: {
    table: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      StageStatus: _VEnum.StageStatus,
      StageType2Val: _Table.StageType2Val,
      expand: true /* 课程阶段展开 */
    };
  },
  methods: {
    openAdd: function openAdd() {
      this.$refs.addClassModal.show(this.table.stageType, this.table);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "qyweixin-dialog",
          attrs: {
            visible: _vm.isShow,
            width: "450px",
            title: "课堂工具升级",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
        },
        [
          _c("div", [_vm._v("讲师您好！")]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px", "line-height": "25px" } },
            [
              _vm._v(
                "万能班长内置授课工具ClassRoom迎来升级，请各位下载万能讲师升级版客户端！ (新版会自动覆盖旧版客户端，讲师无需进行卸载)"
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("万能讲师客户端下载")]),
          _vm._v(" "),
          _c("div", { staticClass: "step-content" }, [
            _c("div", { staticClass: "desc" }, [
              _vm._v(" 适用于macOS 10.13及以上， Windows7及以上。"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "down-content" },
              _vm._l(_vm.downList, function (item, index) {
                return _c("div", { key: item.id }, [
                  _c("img", { attrs: { src: item.img, alt: "" } }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(item.desc))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "down-btn",
                      on: {
                        click: function ($event) {
                          return _vm.downLoad(item.guideUrl, item.guideName)
                        },
                      },
                    },
                    [_vm._v("安装指南")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "down-btn",
                      on: {
                        click: function ($event) {
                          return _vm.downLoad(item.downLoadLink, item.desc)
                        },
                      },
                    },
                    [_c("a", [_vm._v("下载")])]
                  ),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "complate",
              attrs: { loading: _vm.loading },
              on: { click: _vm.complateUpdate },
            },
            [_vm._v("升级完成")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-info" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticStyle: { padding: "20px 40px" } }, [_c("mymedal")], 1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-head" }, [
      _c("img", {
        staticClass: "bg-img",
        attrs: { src: require("@/assets/images/medal/bg.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: "https://classbro-oss.oss-accelerate.aliyuncs.com/",
            "on-remove": _vm.handleRemove,
            multiple: false,
            data: _vm.ossData,
            limit: 1,
            "on-success": _vm.handleSuccess,
            "before-upload": _vm.beforeUpload,
          },
        },
        [_c("div", { attrs: { id: "target" + _vm.index } })]
      ),
      _vm._v(" "),
      _c("el-input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.textShow,
            expression: "textShow",
          },
        ],
        attrs: {
          size: "mini",
          type: "textarea",
          placeholder: "请输入文件描述",
        },
        model: {
          value: _vm.form.remark,
          callback: function ($$v) {
            _vm.$set(_vm.form, "remark", $$v)
          },
          expression: "form.remark",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "testorder-msg-wrap" }, [
    _c("h5", [_vm._v("考试信息")]),
    _vm._v(" "),
    _c("div", { staticClass: "testorder-msg-content" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingLeft,
              expression: "loadingLeft",
            },
          ],
          staticClass: "left",
        },
        [
          _vm.type == 66
            ? [
                _c("p", [
                  _c("label", [_vm._v("考试方式")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.assistExamWayObj[
                            _vm.detailData.studSpecialOrderExam.assistExamWay
                          ]
                        )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("考试类型")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.detailData.studSpecialOrderExam.examType != 5
                          ? _vm.examTypeObj[
                              _vm.detailData.studSpecialOrderExam.examType
                            ]
                          : _vm.detailData.studSpecialOrderExam.examTypeName
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("考试形式")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.testStypw[
                            _vm.detailData.studSpecialOrderExam.examForm
                          ]
                        ) +
                        "\n                        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.assistExamWay == 1
                  ? _c("p", [
                      _c("label", [_vm._v("考试链接")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.detailData.studSpecialOrderExam.examUrl) +
                            "\n                            "
                        ),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value:
                                  _vm.detailData.studSpecialOrderExam.examUrl,
                                expression:
                                  "\n                                    detailData.studSpecialOrderExam.examUrl\n                                ",
                                arg: "copy",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:success",
                                value: _vm.onCopy,
                                expression: "onCopy",
                                arg: "success",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:error",
                                value: _vm.onError,
                                expression: "onError",
                                arg: "error",
                              },
                            ],
                            staticClass: "defaultlight-btn cursorpointer",
                          },
                          [
                            _vm._v(
                              "\n                                复制链接\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.assistExamWay == 1
                  ? _c("p", [
                      _c("label", [_vm._v("学生系统账户")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam.studSysAccount
                          )
                        ),
                        _c("span", {}, [
                          _vm._v("(请提前登录学生系统查看考试方式）"),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.assistExamWay == 1
                  ? _c("p", [
                      _c("label", [_vm._v("学生系统密码")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam
                              .studSysAccountPassword
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.assistExamWay == 3
                  ? _c("p", [
                      _c("label", [_vm._v("如有特定软件需求,请填写清楚")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam.softwareDemand
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !(
                  _vm.detailData.studSpecialOrderExam.assistExamWay == 1 &&
                  _vm.detailData.studSpecialOrderExam.examForm == 2
                )
                  ? _c("p", [
                      _c("label", [_vm._v("具体考试开始时间")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam
                              .realExamStartTime
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !(
                  _vm.detailData.studSpecialOrderExam.assistExamWay == 1 &&
                  _vm.detailData.studSpecialOrderExam.examForm == 2
                )
                  ? _c("p", [
                      _c("label", [_vm._v("具体考试结束时间")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam.realExamEndTime
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.examForm != 1
                  ? _c("p", [
                      _c("label", [_vm._v("考试开放时间")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam.examOpenTime
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.examForm != 1
                  ? _c("p", [
                      _c("label", [_vm._v("考试关闭时间")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam.examCloseTime
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.examForm != 2
                  ? _c("p", [
                      _c("label", [_vm._v("考试限制时长")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam.examLimitTime
                          ) + "Mins"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.assistExamWay == 1 &&
                _vm.detailData.studSpecialOrderExam.examForm == 2
                  ? _c("p", [
                      _c("label", [_vm._v("讲师最晚提交时间")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailData.studSpecialOrderExam
                              .teacLatestCommitTime
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("考试题型(可多选)")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value:
                              _vm.detailData.studSpecialOrderExam.examItemType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detailData.studSpecialOrderExam,
                                "examItemType",
                                $$v
                              )
                            },
                            expression:
                              "\n                                    detailData.studSpecialOrderExam.examItemType\n                                ",
                          },
                        },
                        _vm._l(
                          _vm.detailData.studSpecialOrderExam.examItemType,
                          function (answer, index) {
                            return _c(
                              "el-checkbox",
                              {
                                key: index,
                                attrs: { label: answer, disabled: "" },
                              },
                              [_vm._v(_vm._s(answer.name))]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("是否为补考考试")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.detailData.studSpecialOrderExam.resit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detailData.studSpecialOrderExam,
                                "resit",
                                $$v
                              )
                            },
                            expression: "detailData.studSpecialOrderExam.resit",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.detailData.studSpecialOrderExam.resit,
                                  expression:
                                    "\n                                        detailData.studSpecialOrderExam.resit\n                                    ",
                                },
                              ],
                              attrs: { label: 1, disabled: "" },
                            },
                            [_vm._v("是")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.detailData.studSpecialOrderExam.resit,
                                  expression:
                                    "\n                                        !detailData.studSpecialOrderExam.resit\n                                    ",
                                },
                              ],
                              attrs: { label: 0, disabled: "" },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("是否上传pastPaper文件")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.detailData.studSpecialOrderExam
                                .isUploadPastPaperFile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detailData.studSpecialOrderExam,
                                "isUploadPastPaperFile",
                                $$v
                              )
                            },
                            expression:
                              "\n                                    detailData.studSpecialOrderExam\n                                        .isUploadPastPaperFile\n                                ",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.detailData.studSpecialOrderExam
                                      .isUploadPastPaperFile,
                                  expression:
                                    "\n                                        detailData.studSpecialOrderExam\n                                            .isUploadPastPaperFile\n                                    ",
                                },
                              ],
                              attrs: { label: 1, disabled: "" },
                            },
                            [_vm._v("是")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.detailData.studSpecialOrderExam
                                      .isUploadPastPaperFile,
                                  expression:
                                    "\n                                        !detailData.studSpecialOrderExam\n                                            .isUploadPastPaperFile\n                                    ",
                                },
                              ],
                              attrs: { label: 0, disabled: "" },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type == 69
            ? [
                _c("p", [
                  _c("label", [_vm._v("Exam Type")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.wenyaExamTypeObj[_vm.daobidata.exam.examType])
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("Exam Form")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.daobidata.exam.assistExamWay == 1
                          ? "Require Login"
                          : _vm.daobidata.exam.assistExamWay == 2
                          ? "Photo Exam"
                          : "Take Home"
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.daobidata.exam.assistExamWay != 3
                  ? _c("p", [
                      _c("label", [_vm._v("Exam Style")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.daobidata.exam.examStyle == 1
                              ? "Limited Time"
                              : "Open Time"
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.testFormShow.OfficialOpenFrom
                  ? _c("p", [
                      _c("label", [_vm._v("Official Open From")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.daobidata.exam.examOpenTime)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.testFormShow.ToexamCloseTime
                  ? _c("p", [
                      _c("label", [_vm._v("To")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.daobidata.exam.examCloseTime)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.testFormShow.ExamDuration
                  ? _c("p", [
                      _c("label", [_vm._v("Exam Duration")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.daobidata.exam.examLimitTime) + "Mins"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.testFormShow.StartFrom
                  ? _c("p", [
                      _c("label", [_vm._v("Start From")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.daobidata.exam.realExamStartTime)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.testFormShow.TorealExamEndTime
                  ? _c("p", [
                      _c("label", [_vm._v("To")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.daobidata.exam.realExamEndTime)),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.testFormShow.RealDeadline
                  ? _c("p", [
                      _c("label", [_vm._v("Deal Deadline")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.daobidata.studPurchaseOrder &&
                              _vm.daobidata.studPurchaseOrder.examTime
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.daobidata.exam.assistExamWay != 3
                  ? _c("p", [
                      _c("label", [_vm._v("Exam Link")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.daobidata.exam.examUrl) +
                            "\n                            "
                        ),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:copy",
                                value: _vm.daobidata.exam.examUrl,
                                expression: "daobidata.exam.examUrl",
                                arg: "copy",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:success",
                                value: _vm.onCopy,
                                expression: "onCopy",
                                arg: "success",
                              },
                              {
                                name: "clipboard",
                                rawName: "v-clipboard:error",
                                value: _vm.onError,
                                expression: "onError",
                                arg: "error",
                              },
                            ],
                            staticClass: "defaultlight-btn cursorpointer",
                          },
                          [
                            _vm._v(
                              "\n                                复制链接\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("学生系统账户")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.daobidata.studUser.schoolAccount)),
                    _c("span", {}, [
                      _vm._v("(请提前登录学生系统查看考试方式）"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("学生系统密码")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.daobidata.studUser.schoolPws)),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("如有特定软件需求,请填写清楚")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.detailData.studSpecialOrderExam.softwareDemand)
                    ),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingRight,
              expression: "loadingRight",
            },
          ],
          staticClass: "right",
        },
        [
          _vm._l(
            _vm.detailData.studSpecialOrderExamExt,
            function (detail, index) {
              return _c("div", { key: index }, [
                _c("p", [
                  _c("label", [_vm._v("填写人/时间")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(detail.creator) + "/" + _vm._s(detail.createAt)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("考试题目数量")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(detail.examTopicCount))]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("考试范围")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        detail.examScope
                          ? "所有章节/内容"
                          : "Week/Chapter" +
                              detail.examScopeStart +
                              " — " +
                              "Week/Chapter" +
                              detail.examScopeEnd
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("考试答案要求的文件格式")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    _vm._l(
                      detail.examAnswerFileFormat,
                      function (answer, index) {
                        return _c(
                          "b",
                          { key: index, staticClass: "testtype-b" },
                          [_vm._v(_vm._s(answer.name))]
                        )
                      }
                    ),
                    0
                  ),
                ]),
                _vm._v(" "),
                _vm.type == 69
                  ? _c("p", [
                      _c("label", [_vm._v("考试题型")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { width: "calc(100% - 156px)" } },
                        _vm._l(
                          JSON.parse(detail.examItemTypes),
                          function (exam, index) {
                            return _c(
                              "b",
                              { key: index, staticClass: "testtype-b" },
                              [
                                _vm._v(
                                  _vm._s(exam.name) +
                                    "/" +
                                    _vm._s(exam.total) +
                                    "\n                                " +
                                    _vm._s(exam.type != 8 ? "题" : "")
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                (Number(detail.examScope) == 0 && _vm.type == 69) ||
                _vm.type == 66
                  ? _c("p", [
                      _c("label", [_vm._v("考试重难知识点")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(detail.examDifficulties))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                Number(detail.examScope) == 1 && _vm.type == 69
                  ? _c(
                      "div",
                      [
                        _vm._m(0, true),
                        _vm._v(" "),
                        _vm._l(
                          JSON.parse(detail.examDifficultiesArr),
                          function (exam, index) {
                            return _c("p", { key: index }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Theory name："),
                              ]),
                              _vm._v(" "),
                              _c("b", { staticClass: "testtype-b" }, [
                                _vm._v(_vm._s(exam.content)),
                              ]),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _c("label", [_vm._v("是否遇到问题or特殊要求")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      detail.specialRequirementsFlag
                        ? _c(
                            "el-radio",
                            {
                              attrs: { label: 1, disabled: "" },
                              model: {
                                value: detail.specialRequirementsFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    detail,
                                    "specialRequirementsFlag",
                                    $$v
                                  )
                                },
                                expression: "detail.specialRequirementsFlag",
                              },
                            },
                            [_vm._v("是")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !detail.specialRequirementsFlag
                        ? _c(
                            "el-radio",
                            {
                              attrs: { label: 0, disabled: "" },
                              model: {
                                value: detail.specialRequirementsFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    detail,
                                    "specialRequirementsFlag",
                                    $$v
                                  )
                                },
                                expression: "detail.specialRequirementsFlag",
                              },
                            },
                            [_vm._v("否")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      detail.specialRequirementsFlag
                        ? _c("b", [_vm._v(_vm._s(detail.specialRequirements))])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.detailData.studSpecialOrderExam.assistExamWay == 1
                  ? _c("p", [
                      _c("label", [_vm._v("请上传登录学生系统查看的截图")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        _vm._l(detail.examExtFileList, function (imgs, indexi) {
                          return _c("img", {
                            key: indexi,
                            attrs: {
                              src: imgs.loginScreenshotFileUrl,
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openUrl(imgs.loginScreenshotFileUrl)
                              },
                            },
                          })
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            }
          ),
          _vm._v(" "),
          _c("p", { staticClass: "check" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "file-box" },
              [
                _vm.referenceFiles.length
                  ? _vm._l(_vm.referenceFiles || [], function (file, _index) {
                      return _c(
                        "div",
                        {
                          key: _index,
                          staticClass: "file",
                          on: {
                            click: function ($event) {
                              return _vm.seeFile(file)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_index + 1) + "、" + _vm._s(file.name))]
                      )
                    })
                  : [
                      _vm._v(
                        "\n                          等待上传\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                      ),
                    ],
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("examContent", {
            ref: "examContent",
            attrs: { orderInfo: _vm.detailData, daobidata: _vm.daobidata },
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("label", { staticStyle: { width: "100%" } }, [
        _vm._v("考试重难知识点(e.g:假设检验、微分方程、M&M理论)"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", { staticClass: "star" }, [_vm._v("*")]),
      _vm._v("备考审核表"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "classinfo-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.alertModal, center: "", width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.alertModal = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("span", { staticClass: "q-title" }, [_vm._v("模拟课信息")]),
            ]),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "content-container" }, [
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", [_vm._v("课堂名称:")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right", staticStyle: { width: "75%" } },
                [_c("span", [_vm._v(_vm._s(_vm.alertData.className))])]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", [_vm._v("上课时间:")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                _c("span", [_vm._v(_vm._s(_vm.alertData.dateRange))]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", [_vm._v("上课提醒:")]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                _c("span", [_vm._v("15分钟前")]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", [_vm._v("上课课件:")]),
              ]),
              _vm._v(" "),
              _vm.courseware.length
                ? _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _vm._l(_vm.courseware, function (list, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "link",
                            staticStyle: { "text-decoration-color": "#ff8723" },
                            on: {
                              click: function ($event) {
                                return _vm.download(list)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(list.name))]),
                            _vm._v(" "),
                            list.name
                              ? _c("svg-icon", {
                                  attrs: {
                                    "class-name": "link",
                                    "icon-class": "download",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "link-bule",
                          on: {
                            click: function ($event) {
                              return _vm.download()
                            },
                          },
                        },
                        [_vm._v("下载全部")]
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "left" }, [
                _c("span", [_vm._v("评分表:")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "right",
                  staticStyle: { width: "75%" },
                  on: { click: _vm.openLink },
                },
                [
                  _c("span", { staticClass: "link" }, [
                    _vm._v("https://www.classbro.com/survey"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.alertData.teacherJoinUrl,
                    expression: "alertData.teacherJoinUrl",
                  },
                ],
                staticClass: "box",
                staticStyle: { "text-align": "center" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    staticStyle: { display: "inline-block" },
                    on: {
                      click: function ($event) {
                        return _vm.enterClass(_vm.alertData.teacherJoinUrl)
                      },
                    },
                  },
                  [_vm._v("进入课堂")]
                ),
              ]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
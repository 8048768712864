"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _slicedToArray2 = _interopRequireDefault(require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
require("core-js/modules/es6.array.find-index");
var _moment = _interopRequireDefault(require("moment"));
var _mixins = require("@/mixins");
var _coursedetail = _interopRequireDefault(require("./coursedetail"));
var _addcourse = _interopRequireDefault(require("./addcourse"));
var _changeModal = _interopRequireDefault(require("./changeModal"));
var _settingTimeTip = _interopRequireDefault(require("./settingTimeTip"));
var _Utils = require("@/utils/task/Utils");
var _addNeedNew = _interopRequireDefault(require("@/views/paperchedule/addNeedNew"));
var _index = require("@/utils/index");
var _DownloadApp = _interopRequireDefault(require("@/components/DownloadApp"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "timetable",
  components: {
    coursedetil: _coursedetail.default,
    addcourse: _addcourse.default,
    changeModal: _changeModal.default,
    settingTimeTip: _settingTimeTip.default,
    addNeedNew: _addNeedNew.default,
    downloadApp: _DownloadApp.default
  },
  data: function data() {
    return {
      firstTime: 0,
      isShowChange: localStorage.getItem("teacType") == 2 ? true : false,
      //兼职老师显示
      showClassNum: true,
      isShowTip: false,
      cardloading: false,
      maskShow: false,
      addstatus: false,
      seestatus: false,
      alertModal: false /* 成功提示弹窗 */,
      cacheMoveCalendar: {} /* 日历组件划区域时缓存 */,
      dayValue: [],
      topType: localStorage.getItem("teacType") == 2 ? 2 : 1,
      //显示时间默认为1不含开关，2含开关(全职老师不显示teacType:1，兼职老师显示teacType:2)
      showTime: 2,
      //只展示8，16，24点
      hasBorder: 2,
      //只展示8，16，24点边框
      pickWidth: "460px",
      //日模式下日历面板的宽度
      minHeight: 28,
      defaultEvents: [],
      dayData: [],
      dayCouseNum: 0,
      courseObj: "",
      addcourestime: {
        hhmm: "",
        month: "",
        today: "",
        ymdhm: ""
      },
      statusText: {
        1: "学生未确认课堂",
        2: "等待上课",
        3: "等待上课",
        4: "正在上课",
        8: "已结束",
        16: "异常课堂",
        32: "取消排课中"
      },
      fontColor: {
        1: "#355D98",
        2: "#355D98",
        3: "#355D98",
        4: "#FF8D02",
        8: "#999999",
        16: "#999999"
      },
      topColor: {
        1: "#5A9CFF",
        2: "#5A9CFF",
        3: "#5A9CFF",
        4: "#FF8D02",
        8: "#8A93A7",
        16: "#8A93A7"
      },
      Color: {
        1: "#E5EFFE",
        2: "#E5EFFE",
        3: "#E5EFFE",
        4: "#FFF3E4",
        8: "#E8E9EB",
        16: "#E8E9EB"
      },
      BGColor: {
        1: "#E5EFFE",
        2: "#E5EFFE",
        3: "#E5EFFE",
        4: "#FFF3E4",
        8: "#E8E9EB",
        //
        16: "#E8E9EB"
      },
      defaultDate: 0,
      dateType: 1,
      isLeft0: true,
      dayIndex: 0,
      //当前可视区的第几天
      today: "",
      //日模式下点击开关选中的日期
      currentDay: {},
      //日模式下的当前日期
      loading: false,
      showNeedNew: false,
      orderObj: {},
      classInfo: {},
      windowHeight: 800
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    next(function (vm) {
      vm.getWindowHeight();
    });
  },
  created: function created() {
    window.addEventListener('resize', this.getWindowHeight);
  },
  mounted: function mounted() {
    this.initFun();
  },
  mixins: [_mixins.CalendarMixin, _mixins.ClassroomMixin],
  watch: {
    dateType: function dateType(val) {
      //日和周模式的切换
      if (val != 1) {
        this.getdayCardList(); //获取日模式下课程（月初-月末）
        this.getTableList(0, new Date(this.initSummary()).getTime());
        this.isShowChange = false;
      } else {
        localStorage.getItem("teacType") == 2 && (this.isShowChange = true);
        this.dealbug();
      }
    }
  },
  methods: {
    getWindowHeight: function getWindowHeight() {
      this.windowHeight = window.innerHeight;
      console.log(111, window);
    },
    initFun: function initFun() {
      var timeData = JSON.parse(localStorage.getItem("dayspanState"));
      this.defaultDate = timeData.around;
      this.dateType = timeData.type;
    },
    updatetable: function updatetable() {
      var timeData = JSON.parse(localStorage.getItem("dayspanState"));
      this.defaultDate = timeData.around;
      this.dateType = timeData.type;
      this.getTableList(timeData.type, timeData.around);
      this.firstTime = timeData.around;
    },
    showCourseFun: function showCourseFun(status, obj) {
      if (obj && obj.recordType == 2) return; //如果时订单
      if (status == "addstatus") {} else {
        this.courseObj = obj;
      }
      this.maskShow = true;
      this[status] = true;
      // this.get_classroom();
    },
    openLink: function openLink(url) {
      if (url) window.open(url);
    },
    in_classroom: function in_classroom(row, index) {
      this.goInClassNew(row.secretKey, row.teacherJoinUrl, row);
    },
    closeMask: function closeMask(status) {
      this.maskShow = false;
      this[status] = false;
      this.classInfo = {};
    },
    // 日模式下点击查看课程(切换到日模式和点击日期的时候回触发)
    onPicked: function onPicked(data) {
      var day = data.calendar.days[0];
      if (day.month != this.currentDay.month) {
        //月份变了重新获取当月有课时间
        this.currentDay = data.calendar.days[0];
        this.getdayCardList();
      }
      this.isShowChange = false;
      if (this.dateType == data.type && this.defaultDate == data.time) {
        return;
      }
      this.defaultDate = data.time;
      this.dateType = data.type;
      this.getTableList(data.type, data.time);
      this.firstTime = data.time;
    },
    // 切换周模式的日期
    changeDate: function changeDate(eventUpdate) {
      if (this.dateType == eventUpdate.type && this.defaultDate == eventUpdate.time) {
        return;
      }
      this.defaultDate = eventUpdate.time;
      this.dateType = eventUpdate.type;
      this.getTableList(eventUpdate.type, eventUpdate.time);
      this.firstTime = eventUpdate.time;
      this.getDayIndex(eventUpdate);
    },
    //获取周模式下的日期下标
    getDayIndex: function getDayIndex(day) {
      if (day.time < new Date().getTime()) {
        var nowday = (0, _Utils.formatTime)(new Date(), "Y-M-D") + " 00:00:00";
        //如果周末是下开始日期小于当前日期，默认未当天的下标
        this.dayIndex = day.calendar.days && day.calendar.days.findIndex(function (v) {
          return v.date._d.getTime() == new Date(nowday).getTime();
        });
      } else {
        //否则就是第一天的下标
        this.dayIndex = 0;
      }
    },
    /* 日历划区域 开始 */mouseDown: function mouseDown(e) {
      var time = e.time;
      var _this$fmtTimestamp = this.fmtTimestamp(time.time),
        month = _this$fmtTimestamp.month,
        today = _this$fmtTimestamp.today,
        hhmm = _this$fmtTimestamp.hhmm,
        ymdhm = _this$fmtTimestamp.ymdhm;
      this.cacheMoveCalendar = {
        month: month,
        today: today,
        hhmm: hhmm,
        ymdhm: ymdhm
      };
    },
    /* 日历划区域 结束 */mouseUp: function mouseUp(e) {
      var time = e.time;
      var _this$fmtTimestamp2 = this.fmtTimestamp(time.time),
        month = _this$fmtTimestamp2.month,
        today = _this$fmtTimestamp2.today,
        hhmm = _this$fmtTimestamp2.hhmm,
        ymdhm = _this$fmtTimestamp2.ymdhm;
      var _this$cacheMoveCalend = this.cacheMoveCalendar,
        startM = _this$cacheMoveCalend.month,
        startT = _this$cacheMoveCalend.today,
        startH = _this$cacheMoveCalend.hhmm,
        startY = _this$cacheMoveCalend.ymdhm;

      /* 计算 duration 区间*/
      var _start, _end, _direction;
      if (ymdhm < startY) {
        _direction = "-";
        _start = startY;
        _end = ymdhm;
      } else {
        _direction = "+";
        _start = ymdhm;
        _end = startY;
      }
      var calc = (0, _moment.default)(_start).diff((0, _moment.default)(_end), "minutes") / 60;

      /* 判断是拖拽 还是 新建 */
      if (startY) {
        /* 默认半小时 */
        if (calc < 0.5) calc = 0.5;
        var _this$getLocalStorage = this.getLocalStorage(),
          exist = _this$getLocalStorage.exist;
        this.addcourestime = {
          month: month,
          today: today,
          hhmm: hhmm,
          ymdhm: ymdhm
        };
        this.addcourestime.ymdhm = startY;
        this.addcourestime.startTime = _end.substr(10, 20);
        this.addcourestime.endTime = _start.substr(10, 20);
        this.addcourestime.calc = calc;
        this.addcourestime.direction = _direction;
        this.showCourseFun("addstatus", this.addcourestime);
      }
      /* 清空cache */
      this.cacheMoveCalendar = {};
    },
    // mouseUpEvent(e) {
    // },
    handleMove: function handleMove(e) {
      var _e = (0, _slicedToArray2.default)(e, 2),
        _ = _e[0],
        move = _e[1];
      move.callback();
    },
    /* 拖动日历内容面板 */handleMoveData: function handleMoveData(e) {
      var time = e.time,
        detail = e.detail;
      var _this$fmtTimestamp3 = this.fmtTimestamp(time),
        month = _this$fmtTimestamp3.month,
        today = _this$fmtTimestamp3.today,
        hhmm = _this$fmtTimestamp3.hhmm,
        ymdhm = _this$fmtTimestamp3.ymdhm;
      var className = detail.className,
        startDate = detail.startDate,
        endDate = detail.endDate;
      var duration = this.fmtDuration(startDate, endDate);
      this.addcourestime = {
        month: month,
        today: today,
        hhmm: hhmm,
        ymdhm: ymdhm
      };
    },
    lookCourse: function lookCourse(list) {
      this.showCourseFun("seestatus", list);
    },
    backDateRange: function backDateRange() {
      if (!this.currentDay.date) {
        this.currentDay = this.$refs.calendarApp.calendar.days[0];
      }
      var day = this.currentDay.date._d;
      var year = day.getFullYear();
      var mm = day.getMonth() + 1;
      var start = new Date("".concat(year, "-").concat(mm, "-01")).getTime();
      var end = new Date("".concat(year, "-").concat(mm, "-").concat(new Date(year, mm, 0).getDate())).getTime() + 8.64e7; //(获取的时当月的最后一天的0点，加个最后一天的时间)
      this.cerrentMonth = mm;
      return {
        startTime: start,
        endTime: end,
        status: [2]
      };
    },
    //获取日模式下当月有课的日期及开关
    getdayCardList: function getdayCardList() {
      var _this = this;
      this.loading = true;
      this.dayData = [];
      var params = this.backDateRange();
      this.$axios({
        url: this.$api.baseURL + "/classRoom/queryClassroomForDate",
        method: "post",
        data: params
      }).then(function (res) {
        _this.loading = false;
        if (res.data.status === 400) {
          if (res.data.body.code === 4011) {
            _this.$router.push({
              path: "/login"
            });
          }
        }
        _this.dayData = res.data.body;
        _this.dayData.forEach(function (v) {
          v.off = v.teacScheduleVo && v.teacScheduleVo.timeState == 1 ? true : false; //1时间空余2，时间繁忙
          v.disableFlag = v.teacScheduleVo && v.teacScheduleVo.disableFlag;
        });
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 查询课堂数据展示
    getTableList: function getTableList(type, startTime) {
      var _this2 = this;
      this.dayValue = [];
      this.defaultEvents = [];
      var timeSpace = 86400000;
      var endTime = startTime + timeSpace * (type ? 7 : 1);
      this.cardloading = true;
      var start = (0, _Utils.formatTime)(startTime, "Y-M-D h:m:s");
      var end = (0, _Utils.formatTime)(endTime, "Y-M-D h:m:s");
      console.log(1111, endTime, startTime);
      console.log(2222, start, end);
      this.$axios({
        url: this.$api.baseURL + "/classRoom/queryClassroomForDate",
        method: "post",
        data: {
          startTime: parseInt(startTime),
          endTime: parseInt(endTime),
          start: start,
          end: end
        }
      }).then(function (res) {
        _this2.cardloading = false;
        if (res.data.status === 400) {
          if (res.data.body.code === 4011) {
            _this2.$router.push({
              path: "/login"
            });
          }
        }
        if (res.data.status === 200) {
          var allData = res.data.body;
          var dayValue = [];
          if (type == 0) {
            var item = allData[0];
            _this2.addcourestime.ymdhm = allData[0].date;
            _this2.addcourestime.hhmm = "";
            _this2.dayCouseNum = item.classrooms.length;
            item.classrooms.forEach(function (smallitem) {
              var dayallobj = smallitem;
              var type = smallitem.spoType == 69 && smallitem.specialOrderExtType != 128 || smallitem.spoType == 65 ? 2 : 1;
              // console.log(111888, smallitem.startTime.replace(/\-/g, '/'))
              dayallobj.time = _this2.handleTime(smallitem.startTime.replace(/\-/g, '/'), smallitem.endTime.replace(/\-/g, '/'), type);
              dayallobj.data = [];
              dayValue.push(dayallobj); //日模式下的课程列表
              _this2.dayValue = JSON.parse(JSON.stringify(dayValue));
            });
          } else {
            _this2.clearLocalStorage();
            _this2.dayData = allData;
            _this2.dayData.forEach(function (v) {
              v.off = v.teacScheduleVo.timeState == 1 ? true : false; //1时间空余2，时间繁忙
              v.disableFlag = v.teacScheduleVo.disableFlag;
            });
            allData.forEach(function (list) {
              list.classrooms.forEach(function (listsmall) {
                var startTime = listsmall.startTime,
                  endTime = listsmall.endTime,
                  name = listsmall.name,
                  teacherJoinUrl = listsmall.teacherJoinUrl,
                  statused = listsmall.statused,
                  id = listsmall.id,
                  cdId = listsmall.cdId,
                  orderNo = listsmall.orderNo,
                  recordType = listsmall.recordType,
                  spoType = listsmall.spoType,
                  specialOrderExtType = listsmall.specialOrderExtType,
                  shengwangFlag = listsmall.shengwangFlag,
                  secretKey = listsmall.secretKey,
                  bordertype = listsmall.bordertype;
                startTime = startTime.replace(/\-/g, '/');
                endTime = endTime.replace(/\-/g, '/');
                var dateRange = _this2.getZHDateRange(startTime, endTime); //常规的时间（开始-结束）
                //文案订单(非考试订单)和论文大礼包展示deadLine
                if (spoType == 69 && specialOrderExtType != 128 || spoType == 65) {
                  dateRange = "Deal dealine " + _this2.getDeadLine(startTime);
                  //因为返回的开始时间和结束时间一样影响渲染，手动给结束时间加点
                  endTime = new Date(startTime).getTime() + 60 * 1000;
                  endTime = (0, _Utils.formatTime)(endTime, "Y-M-D h:m");
                }
                var duration = _this2.fmtDuration(startTime, endTime);
                var _this2$fmtTimestamp = _this2.fmtTimestamp(startTime),
                  month = _this2$fmtTimestamp.month,
                  today = _this2$fmtTimestamp.today,
                  hhmm = _this2$fmtTimestamp.hhmm,
                  ymdhm = _this2$fmtTimestamp.ymdhm;
                _this2.setCalendar(month, today, hhmm, ymdhm, duration, dateRange, {
                  className: name,
                  teacherJoinUrl: teacherJoinUrl,
                  statused: statused,
                  startTime: startTime,
                  endTime: endTime,
                  id: id,
                  cdId: cdId,
                  orderNo: orderNo,
                  recordType: recordType,
                  spoType: spoType,
                  //订单类型
                  specialOrderExtType: specialOrderExtType,
                  //订单子类型
                  shengwangFlag: shengwangFlag,
                  secretKey: secretKey,
                  bordertype: bordertype
                });
              });
            });
          }
        }
      }).catch(function (er) {});
    },
    clearLocalStorage: function clearLocalStorage() {
      var _this$getLocalStorage2 = this.getLocalStorage(),
        data = _this$getLocalStorage2.data;
      data.events = [];
      localStorage.setItem("dayspanState", JSON.stringify(data));
    },
    getLocalStorage: function getLocalStorage() {
      var storage = localStorage.getItem("dayspanState");
      var data = JSON.parse(storage);
      return {
        data: data,
        events: data.events,
        exist: data.events && data.events.length > 0
      };
    },
    /* 设置日历面板 */setCalendar: function setCalendar(month, today, hhmm, ymdhm, duration, dateRange, form) {
      //先清理本地缓存
      var sto = JSON.parse(localStorage.getItem("dayspanState"));
      var _data = {
        month: month,
        today: today,
        hhmm: hhmm,
        ymdhm: ymdhm,
        duration: duration,
        dateRange: dateRange
      };
      var className = form.className,
        startTime = form.startTime,
        endTime = form.endTime,
        statused = form.statused,
        teacherJoinUrl = form.teacherJoinUrl,
        id = form.id,
        cdId = form.cdId,
        orderNo = form.orderNo,
        recordType = form.recordType,
        spoType = form.spoType,
        specialOrderExtType = form.specialOrderExtType,
        shengwangFlag = form.shengwangFlag,
        secretKey = form.secretKey,
        bordertype = form.bordertype;
      var statusedtext = statused >= 16 ? statused != 32 ? 16 : 32 : statused,
        statused2 = statused >= 16 ? 16 : statused;
      var data_ = {
        data: {
          className: className,
          orderNo: orderNo,
          recordType: recordType,
          dateRange: dateRange,
          startTime: startTime,
          endTime: endTime,
          statused: this.statusText[statusedtext],
          teacherJoinUrl: teacherJoinUrl,
          statusColor: this.Color[statused2],
          color: this.BGColor[statused2],
          fontColor: this.fontColor[statused2],
          topColor: this.topColor[statused2],
          id: id,
          cdId: cdId,
          _data: _data,
          shengwangFlag: shengwangFlag,
          secretKey: secretKey,
          spoType: spoType,
          bordertype: bordertype
        },
        schedule: {
          month: [month],
          // 月份
          dayOfMonth: [today],
          times: [hhmm],
          // 几点
          duration: duration // 小时区间
        }
      };
      this.defaultEvents.push(data_);
      sto.events.push(data_);
      localStorage.setItem("dayspanState", JSON.stringify(sto));
      this.$refs.calendarApp.loadState();
    },
    // 上课时间段拼接
    handleTime: function handleTime(startTime, endTime) {
      var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      return type == 1 ? (0, _Utils.formatTime)(startTime, "h:m") + "-" + (0, _Utils.formatTime)(endTime, "h:m") : (0, _Utils.formatTime)(startTime, "h:m");
    },
    dem: function dem() {},
    get_classroom: function get_classroom() {
      var _this3 = this;
      this.$axios({
        url: this.$api.baseURL + "/order/queryClassroomsByCourseId",
        params: {
          courseId: this.courseId,
          status: 14
        }
      }).then(function (res) {
        if (res.data.status === 400) {
          if (res.data.body.code === 4011) {
            _this3.$router.push({
              path: "/login"
            });
          }
        }
        _this3.classrooms = r.data.body.list;
      }).catch(function (er) {});
    },
    changeOff: function changeOff(day, index) {
      this.isShowChange = true;
      this.dayIndex = index;
      if (!this.dateType) {
        //日模式
        this.today = (0, _Utils.formatTime)(new Date(day.date._d), "Y-M-D");
        this.dayIndex = day.dayOfMonth - 1;
      } else {
        this.today = (0, _Utils.formatTime)(new Date(day._d), "Y-M-D");
      }
    },
    closeModal: function closeModal(type) {
      this[type] = false;
    },
    //跳转到日视图下
    goToday: function goToday(data) {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.$refs.calendarApp.changePattern(0); //切换到日模式。默认会先调一下当天的
      });
      var time = new Date((0, _Utils.formatTime)(data.startTime, "Y/M/D") + " 00:00:00");
      var params = {
        time: time.getTime(),
        type: 0
      };
      console.log('params', params);
      //  this.$refs.calendarApp.onPicked(params); //手动调一下切换到课堂日期（跨月有bug）
      // // 计算点击的日期和日历表头部日期相差天数，手动前后移动
      var now = new Date(this.initSummary()).getTime();
      var days = this.getDaysBetween(params.time, now);
      setTimeout(function () {
        for (var i = 0; i < Math.abs(days); i++) {
          if (days < 0) {
            _this4.$refs.calendarApp.prev();
          } else {
            _this4.$refs.calendarApp.next();
          }
        }
        _this4.getTableList(0, params.time);
        _this4.getdayCardList();
      }, 1000);
    },
    //获取日模式下考试订单的状态
    getExamStatus: function getExamStatus(list) {
      var time = new Date(list.startTime).getTime();
      var endtime = new Date(list.endTime).getTime();
      var now = new Date().getTime();
      var text = "";
      if (now < time) {
        text = "等待考试";
      } else if (now >= time && now < endtime) {
        text = "考试中";
      } else {
        text = "已完成";
      }
      return text;
    },
    changeSwitch: function changeSwitch(obj, index, type, flag) {
      if (flag == 1) {
        console.log("index, type", obj, index, type);
        this.$set(this.dayData, index, obj);
        this.$set(this.dayData[index], "off", type == 1 ? true : false);
      } else {
        this.$set(this.dayData[index].teacScheduleVo, "timeState", type);
      }
    },
    goToOrderDetail: function goToOrderDetail(row) {
      console.log("row", row);
      var url = "";
      if (row.spoType === 64) {
        url = this.$router.resolve({
          path: "/bigClassRoom",
          query: {
            id: row.cdId,
            parentId: null
          }
        });
      } else {
        var type = row.orderTypeed === 4 ? "wenya" : "daobi";
        url = this.$router.resolve({
          path: "/sellerDetail",
          query: {
            id: row.cdId,
            type: type
          }
        });
      }
      window.open(url.href);
    },
    setdateType: function setdateType(val) {
      this.dateType = val;
    },
    //从日模式点击周日的日期到周模式，不会触发onpick，导致页面没刷新，手动调一下
    dealbug: function dealbug() {
      // let date=this.$refs.calendarApp.summary
      // let year=date.substring(0,4);
      // let month=date.substring(5,7);
      // let day=date.substring(8,10);
      var week = (0, _Utils.getweekday)(this.initSummary());
      if (week == "日") {
        this.getTableList(1, this.$refs.calendarApp.calendar.start.time);
      }
    },
    initSummary: function initSummary() {
      var date = this.$refs.calendarApp.summary;
      var year = date.substring(0, 4);
      var month = date.substring(5, 7);
      var day = date.substring(8, 10);
      return "".concat(year, "/").concat(month, "/").concat(day);
    },
    //获取相差天数
    getDaysBetween: function getDaysBetween(time1, time2) {
      // var  startDate = Date.parse(time1);
      // var  endDate = Date.parse(time2);
      var days = (time1 - time2) / (1 * 24 * 60 * 60 * 1000);
      return days;
    },
    closeDialog: function closeDialog() {
      this.showNeedNew = false;
      this.updatetable();
    },
    // 编辑课堂
    editClass: function editClass(list) {
      console.log('this.addstatus', this.addstatus);
      this.showCourseFun("addstatus", list);
      this.classInfo = list || {};
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStatus = exports.getSelectlist = exports.getOrder = void 0;
var _request = require("@/views/hatch/api/request.js");
var _api = require("@/api/api");
//获取是否显示月度通知
var getOrder = exports.getOrder = function getOrder() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/handsUp"),
    method: "post",
    data: {}
  });
};
var getStatus = exports.getStatus = function getStatus() {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/checkHandsUpStatus"),
    method: "get"
  });
};
var getSelectlist = exports.getSelectlist = function getSelectlist(params) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/order/list"),
    // otherUrl: `http://g2877u4460.qicp.vip:55864/api/teacher/order/list`,
    method: "get",
    params: params
  });
};
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teacBookAClass = exports.reservationClass = exports.integrateV1MoniOwnList = exports.integrateV1MoniList = exports.getProcessLineProgress = exports.getHoliday = exports.getClassList = exports.getClassDate = exports.arrtSimulationClassroom = void 0;
var _request = require("./request");
var _qs = _interopRequireDefault(require("qs"));
var _jquery = require("jquery");
var _api = require("@/api/api");
//课程表

/**
 * 查询全真模拟课列表
 * @param {String} startDate
 * @param {String} endDate
 * @param {String} userId 讲师id
 */
var integrateV1MoniList = exports.integrateV1MoniList = function integrateV1MoniList(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/moni/list"),
    method: "get",
    params: params
  });
};

/**
 * 查询我的全真模拟课
 * @param {String} userId
 * @returns ownTeacSimulationClassroom 我自己的; ownTeacSimulationReservation 谁约的他的课
 */
var integrateV1MoniOwnList = exports.integrateV1MoniOwnList = function integrateV1MoniOwnList(params) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/moni/ownlist"),
    method: "get",
    params: params
  });
};

/**
 * 讲师安排全真模拟课
 * 添加/编辑
 * @param {String} userId 讲师ID
 * @param {String} startTime 开始时间
 * @param {String} endTime 结束时间
 * @param {String} className 课堂名
 * @description 如果该讲师排过全真模拟课并且审核状态那么再次请求该接口是做重新安排操作
 */
var arrtSimulationClassroom = exports.arrtSimulationClassroom = function arrtSimulationClassroom(data) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/arrtSimulationClassroom"),
    method: "post",
    formData: true,
    data: _qs.default.stringify(data)
  });
};

/**
 * 预约全真模拟课
 * @param {String} userId 讲师ID
 * @param {String} classroomId 要预约的课堂ID
 */

var reservationClass = exports.reservationClass = function reservationClass(userId, classroomId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacintegrate/v1/reservationClass/").concat(userId, "/").concat(classroomId),
    method: "post",
    data: {}
  });
};

/**
 * 查询小地图进度
 * @param {*} teacherId
 */
var getProcessLineProgress = exports.getProcessLineProgress = function getProcessLineProgress(teacherId) {
  return (0, _request.request)({
    api: "".concat(_request.PREFIX, "/train/teacherstep/v1/getProcessLineProgress?teacherId=").concat(teacherId),
    method: "get"
  });
};
// stt新加接口++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
/**
 * 讲师预约全真模拟课
 * @param {*} teacherId 讲师id
 * @param {*} classRoomId 模拟课堂ID
 * @param {*} roomId 模拟课堂roomId
 */
var teacBookAClass = exports.teacBookAClass = function teacBookAClass(data) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacSimulationReservationExt/v1/teacBookAClass"),
    method: "post",
    data: data
  });
};
/**
 * 获取讲师可以预约的课程列表
 * @param {*} teacId 讲师id
 * @param {*} ymd  年月日日期2020-06-03
 */
var getClassList = exports.getClassList = function getClassList(teacId, ymd) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacSimulationReservationExt/v1/getTeacSimulationList?teacId=").concat(teacId, "&ymd=").concat(ymd),
    method: "get"
  });
};
/**
 * 获取有课的日期
 * @param {*} ym  年月日日期2020-06
 */
var getClassDate = exports.getClassDate = function getClassDate(ym) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/teacSimulationReservationExt/v1/queryClassroomForDate?ym=").concat(ym),
    method: "get"
  });
};
/**
 * 获取假期
 */
var getHoliday = exports.getHoliday = function getHoliday(ym) {
  return (0, _request.request)({
    otherUrl: "".concat(_api.BasicUrl, "/api/teacher/classRoom/queryHolidayDates"),
    method: "get"
  });
};
// stt新加接口++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
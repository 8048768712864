var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "finishedCourse-wrap" }, [
    _c("div", { staticClass: "finishedCourse" }, [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "历史订单", name: "1" } }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: { label: "投诉fail订单", name: "2" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "elinputstyle",
            staticStyle: {
              width: "190px",
              height: "30px",
              "border-raduils": "50%",
            },
            attrs: {
              size: "small",
              placeholder: "请输入关键词搜索",
              "prefix-icon": "el-icon-search",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.search($event)
              },
            },
            model: {
              value: _vm.tableDataName,
              callback: function ($$v) {
                _vm.tableDataName = $$v
              },
              expression: "tableDataName",
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "defaultlight-btn", on: { click: _vm.search } },
            [_vm._v("搜索")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.activeName == 1
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.finishedCourseLoading,
                      expression: "finishedCourseLoading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%", "margin-top": "5px" },
                  attrs: {
                    stripe: "",
                    border: "",
                    "header-cell-style": {
                      color: "#999999",
                      fontSize: "12px",
                      fontWeight: "400",
                      background: "#F3F3F3",
                    },
                    "row-style": { height: "36px" },
                    "cell-style": { padding: "5px 0" },
                    data: _vm.tableDataEnd,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "订单号",
                      width: "274px",
                      align: "left",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "cursorpointer",
                                  staticStyle: { color: "#FF8723" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.seeCouse(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderNo))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4230850376
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总上课时长(min)",
                      align: "center",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.allSchooltime)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3391740294
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "总赠送时长",
                      width: "150",
                      align: "center",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.allPresenterTime)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3229631406
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所获G币",
                      align: "center",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.teacG))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1771361906
                    ),
                  }),
                  _vm._v(" "),
                  _vm.all
                    ? _c("el-table-column", {
                        attrs: {
                          label: "所获OTG币",
                          align: "center",
                          "show-overflow-tooltip": true,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.teacOtg)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          428678345
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "button",
                                {
                                  staticClass: "default-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.uploadDropbox(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("提交作业")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2014862491
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单结束状态",
                      align: "center",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.stautsText[scope.row.statused])
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2434803548
                    ),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "好评G",
                      align: "center",
                      width: "140",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.praiseGb || "--")),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      997875711
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeName == 2 ? _c("failList", { ref: "failList" }) : _vm._e(),
          _vm._v(" "),
          _c("el-pagination", {
            staticStyle: { "margin-top": "15px", float: "right" },
            attrs: {
              "page-sizes": [15, 20],
              "current-page": _vm.val,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
          _vm._v(" "),
          _vm.uploadDropBoxDialog
            ? _c("div", { staticClass: "new-mask" }, [
                _c("div", { staticClass: "mask-content mask-width560" }, [
                  _c("h3", [
                    _vm._v("提交作业 "),
                    _c("b", { on: { click: _vm.uploadDropBoxDialogClose } }, [
                      _vm._v("+"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "dis-flex" }, [
                    _c("img", {
                      staticClass: "cinput-icon",
                      attrs: {
                        src: require("../../assets/images/icon-upload.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "cinput-label" }, [
                      _vm._v("上传文件："),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "default-btn",
                          attrs: { id: "selectfiles", refs: "selectfiles" },
                        },
                        [_vm._v("选择文件")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "upload-common",
                          staticStyle: { padding: "10px 0" },
                          attrs: { id: "ossfile" },
                        },
                        _vm._l(_vm.uploadFilesLength, function (item) {
                          return _c("ul", [
                            _c("li", [
                              _vm._v(
                                _vm._s(item.name) +
                                  "/" +
                                  _vm._s(item.filesize) +
                                  " "
                              ),
                              _c("i", {
                                staticClass: "el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(item)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "progress" }, [
                                _c("div", {
                                  staticClass: "progress-bar",
                                  style: { width: item.percent + "%" },
                                }),
                              ]),
                              _c("span", [_vm._v(_vm._s(item.percent) + "%")]),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btnbox" }, [
      _c(
        "button",
        {
          staticClass: "defaultlight-btn btn-width320",
          attrs: { id: "postfiles" },
        },
        [_vm._v("确定上传")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _vue = _interopRequireDefault(require("vue"));
var _Utils = require("@/utils/task/Utils");
_vue.default.filter('globalImgFilter', function (val) {
  if (!val.thumb) {
    return val.sex == "MALE" ? require('@/assets/images/iconmale.png') : require('@/assets/images/iconfemale.png');
  }
  var sval = "" + (val.thumb.includes('https://') ? '' : _vue.default.prototype.$api.baseURL) + "" + val.thumb + "";
  return sval;
});
_vue.default.filter('globalStuImgFilter', function (val) {
  console.log(val);
  if (val.studThumb || val.groupThumb) {
    var sval = "" + _vue.default.prototype.$api.baseURL + "" + val + "";
    return sval;
  } else {
    return val.sex == "FEMALE" ? require('@/assets/images/student-female.png') : require('@/assets/images/student-male.png');
  }
});
_vue.default.filter('globalTimeFilter', function (val) {
  if (!val) return 0;
  var hour = (val / 60).toFixed(2);
  return hour;
});
// 把2020-8-20 10：00：00转为AM10：00
_vue.default.filter('onlyTime', function (val) {
  val = val.replace(/\-/g, '/'); //写这么麻烦还不是为了兼容safir
  var time = (0, _Utils.formatTime)(val, 'h');
  var time1 = '';
  if (Number(time) < 12) {
    time1 = 'AM' + (0, _Utils.formatTime)(val, 'h:m');
  } else {
    time1 = 'PM' + (0, _Utils.formatTime)(val, 'h:m');
  }
  return time1;
});
// 把2020-8-20 10：00：00转为8/20 10：00
_vue.default.filter('mdTime', function (val) {
  val = val.replace(/\-/g, '/'); //为了兼容在safir的展示问题
  var day = (0, _Utils.formatTime)(val, 'M/D');
  var time = (0, _Utils.formatTime)(val, 'h:m');
  return day + ' ' + time;
});
_vue.default.filter('tofix', function (val, num) {
  return Number(val).toFixed(num);
});
_vue.default.filter('ellipsis', function (val, num) {
  return val.length > num ? val.substring(0, num) + '...' : val.substring(0, num - 1);
});
_vue.default.filter('maTime', function (val) {
  val = val.replace(/\-/g, '/'); //为了兼容在safir的展示问题
  return (0, _Utils.formatTime)(val, 'M.D');
});
_vue.default.filter('M/DTime', function (val) {
  val = val.replace(/\-/g, '/'); //为了兼容在safir的展示问题
  return (0, _Utils.formatTime)(val, 'M/D');
});
_vue.default.filter('Y-M/Time', function (val) {
  val = val.replace(/\-/g, '/'); //为了兼容在safir的展示问题
  return (0, _Utils.formatTime)(val, 'Y-M,');
});
_vue.default.filter('hmTime', function (val) {
  val = val.replace(/\-/g, '/'); //为了兼容在safir的展示问题
  return (0, _Utils.formatTime)(val, 'h:m');
});

// 把2020-8-20 10：00：00转为2020-8-20 10：00
_vue.default.filter('ymdTime', function (val) {
  val = val.replace(/\-/g, '/'); //为了兼容在safir的展示问题
  var time = (0, _Utils.formatTime)(val, 'yyyy-mm-dd h:m');
  return time;
});
_vue.default.filter('MTH', function (val) {
  var time = (val / 60).toFixed();
  return time;
});
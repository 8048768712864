var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detial-warp" }, [
    _c("h5", [_vm._v("学生信息")]),
    _vm._v(" "),
    _c("div", { staticClass: "detial-msg-box detial-msg-height" }, [
      _c("p", [
        _c("label", [_vm._v("学校名称:")]),
        _vm._v(
          "\n         " + _vm._s(_vm.orderMagData.university) + "\n      "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("学校网址:")]),
        _vm._v(
          "\n         " + _vm._s(_vm.orderMagData.university_url) + "\n      "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("学校系统账号:")]),
        _vm._v(
          "\n        " + _vm._s(_vm.orderMagData.blackboard_id) + "\n      "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("学校系统密码:")]),
        _vm._v("\n         " + _vm._s(_vm.orderMagData.password) + "\n      "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
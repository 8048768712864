"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.array.find");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      videoArr: [{
        name: "定制辅导",
        url: "https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20230222/%E6%80%BB%E5%90%88%E6%88%90-_1.mp4",
        orderType: [0]
      }, {
        name: "论文大礼包",
        url: "https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/trainVideos/%E5%90%88%E5%B9%B6%E7%AC%AC%E4%B8%89%E7%89%88.mp4",
        orderType: [4, 65]
      }, {
        name: "考前突击",
        url: "https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20230222/%E6%96%B0%E7%89%88.mp4",
        orderType: [1]
      }, {
        name: "特殊订单",
        url: "https://classbro-oss.oss-cn-hongkong.aliyuncs.com/statice-resource/20190322/%E7%89%B9%E6%AE%8A%E8%AE%A2%E5%8D%95%E6%96%B0%E4%BA%8C%E7%BB%B4%E7%A0%8120210423.mp4",
        orderType: [69, 32, 66]
      }],
      isShow: false,
      videourl: ''
    };
  },
  methods: {
    show: function show(order) {
      var videoObj = this.videoArr.find(function (v) {
        return v.orderType && v.orderType.includes(Number(order.spoType));
      });
      if (videoObj) {
        this.videourl = videoObj.url;
        this.isShow = true;
      }
    },
    handleClose: function handleClose() {
      this.isShow = false;
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      isShow: false,
      isClick: false,
      url: 'https://docs.qq.com/doc/DU3RmY3FldVdOWmlz',
      orderInfo: {},
      assistExamWayList: {
        1: 'Login In',
        2: 'Photo Exam',
        3: 'Take Home'
      },
      teacherType: localStorage.getItem("teacType")
    };
  },
  computed: {
    assistExamWay: function assistExamWay() {
      return this.orderInfo.studSpecialOrderExam && this.orderInfo.studSpecialOrderExam.assistExamWay;
    }
  },
  methods: {
    show: function show(row) {
      this.isShow = true;
      this.orderInfo = row;
    },
    closeModal: function closeModal() {
      this.isShow = false;
      this.isClick = false;
    },
    receivOrder: function receivOrder() {
      if (!this.isClick && this.assistExamWay == 1) {
        return this.$notify.warning('请先查看Loom录制软件下载指南');
      }
      this.$emit("receivOrder");
    },
    openLink: function openLink(url) {
      this.isClick = true;
      window.open(url);
    }
  }
};
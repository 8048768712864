var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom-list" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-wrap" },
      [
        _c("bottomItem", {
          attrs: {
            likeData: _vm.incomeData,
            redbagObj: _vm.redbagObj,
            teacherType: _vm.teacherType,
            texts: _vm.texts,
            type: "1",
            redType: "income",
          },
        }),
        _vm._v(" "),
        _c("bottomItem", {
          attrs: {
            likeData: _vm.mostLoveData,
            redbagObj: _vm.redbagObj,
            teacherType: _vm.teacherType,
            texts: _vm.texts,
            type: "6",
          },
        }),
        _vm._v(" "),
        _c("bottomItem", {
          attrs: {
            likeData: _vm.highListData,
            redbagObj: _vm.redbagObj,
            teacherType: _vm.teacherType,
            texts: _vm.texts,
            type: "5",
            redType: "highback",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-title" }, [
      _vm._v("\n      数据排行榜"),
      _c("b", [_vm._v("排行榜数据实时更新，每月1号清零")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.search");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      pickerOptions2: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      incomeList: false,
      all: true,
      otgb: false,
      teacType: false,
      sss: true,
      cards: '',
      num: '',
      carddata: [],
      tableDataBegin: [],
      tableDataName: '',
      tableDataEnd: [],
      currentPage: 0,
      pageSize: 0,
      txmodal: false,
      modal: false,
      cardmodal: false,
      modal_time: false,
      total: 0,
      limit: 15,
      idss: [],
      courseId: '',
      val: 1,
      data: '',
      value: ['', ''],
      disabledInput: true,
      page: '',
      otherMoney: null,
      money: null,
      otherMoneys: null,
      moneys: null,
      finishTimeFrom: '',
      finishTimeTo: '',
      withdrawed: '',
      ruleForm: {
        price1: '',
        price2: '',
        card: '',
        phone: ''
      },
      username: '',
      submitFormLoading: false,
      rules: {
        bankOfDeposit: [{
          required: true,
          message: '请输入开户支行',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入预留手机号',
          trigger: 'blur'
        }],
        // userName: [{
        //     required: true,
        //     message: '请输入开户人',
        //     trigger: 'blur'
        // }],
        accountNumber: [{
          required: true,
          message: '请输入银行卡号',
          trigger: 'blur'
        }],
        userIdCard: [{
          required: true,
          message: '请输入身份证号码',
          trigger: 'blur'
        }]
      }
    };
  },
  multipleSelection: [],
  mounted: function mounted() {
    this.Time();
    this.getMonthDays();
    this.getMonthDay();
    this.getTableData();
    this.getcardlist();
    this.getUser();
    this.getgb();
    this.teacinfo();
    this.searchEnter();
  },
  methods: {
    searchEnter: function searchEnter() {
      var _this = this;
      document.onkeydown = function (event) {
        if (event.keyCode == 13) {
          _this.search();
        }
      };
    },
    search: function search() {
      this.getTableData();
      this.getgb();
    },
    choosedata: function choosedata(value) {
      this.value = value;
      this.finishTimeFrom = value[0] + ' 00:00:00';
      this.finishTimeTo = value[1] + ' 23:23:59';
      this.getTableData();
      this.getgb();
    },
    Time: function Time(now) {
      var year = new Date(now).getFullYear();
      var month = new Date(now).getMonth() + 1;
      var date = new Date(now).getDate();
      if (month < 10) month = '0' + month;
      if (date < 10) date = '0' + date;
      return year + '-' + month + '-' + date;
    },
    getMonthDays: function getMonthDays(myMonth) {
      var monthStartDate = new Date(new Date().getFullYear(), myMonth, 1);
      var monthEndDate = new Date(new Date().getFullYear(), myMonth + 1, 1);
      var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
      return days;
    },
    getMonthDay: function getMonthDay(myMonth) {
      var finishTimeFrom = this.Time(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
      var finishTimeTo = this.Time(new Date(new Date().getFullYear(), new Date().getMonth(), this.getMonthDays(new Date().getMonth())));
      this.finishTimeFrom = finishTimeFrom + ' 00:00:00';
      this.finishTimeTo = finishTimeTo + ' 23:59:59';
      this.data = [finishTimeFrom, finishTimeTo];
    },
    jx: function jx() {
      var _this2 = this;
      this.$axios({
        url: this.$api.baseURL + '//account/withdrawConfirm',
        params: {
          'gb': this.ruleForm.price1,
          'otgb': this.ruleForm.price2
        }
      }).then(function (r) {
        if (r.data.status == 200) {
          _this2.$refs.ruleForm.model.prices = r.data.body;
          // vm.$notify({
          // title: '提示',
          //     message: r.body.msg,
          //     type: 'success'
          // });
          _this2.getcardlist();
        }
        if (r.data.status == 400) {
          _this2.$notify({
            title: '提示',
            message: r.data.body.msg,
            type: 'warning'
          });
          _this2.getcardlist();
        }
      }).catch(function (er) {});
      // $.ajax({
      //     type: 'get',
      //     url: baseURL + '//account/withdrawConfirm',
      //     dataType: 'json',
      //     headers: {
      //         'token': token
      //     },
      //     data: {
      //         'gb': vm.ruleForm.price1,
      //         'otgb': vm.ruleForm.price2
      //     },
      //     success: function(r) {
      //         if (r.status == 200) {
      //             vm.$refs.ruleForm.model.prices = r.body,
      //             // vm.$notify({
      // title: '提示',
      //             //     message: r.body.msg,
      //             //     type: 'success'
      //             // });
      //             vm.getcardlist()
      //         }
      //         if (r.status == 400) {
      //             vm.$notify({
      // title: '提示',
      //                 message: r.body.msg,
      //                 type: 'warning'
      //             })
      //             vm.getcardlist()
      //         }
      //     }
      // })
    },
    teacinfo: function teacinfo() {
      var _this3 = this;
      this.$axios({
        url: this.$api.baseURL + '/user/getMyInfo'
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this3.$router.push({
              path: '/login'
            });
          }
        }
        var detail = r.data.body;
        if (detail.type == 2) {
          _this3.sss = false;
          _this3.otgb = false;
          _this3.all = false;
        } else {
          _this3.sss = true;
          _this3.otgb = true;
          _this3.all = true;
        }
      }).catch(function (er) {});
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     headers: {
      //         'token': token
      //     },
      //     url: '' + baseURL + '/user/getMyInfo',
      //     success: function(json) {
      //         var detail = json.body
      //         if (detail.type == 2) {
      //             vm.sss = false
      //             vm.otgb = false
      //             vm.all = false
      //         } else {
      //             vm.sss = true
      //             vm.otgb = true
      //             vm.all = true
      //         }
      //     }
      // })
    },
    getTableData: function getTableData() {
      var _this4 = this;
      this.incomeList = true;
      this.$axios({
        url: this.$api.baseURL + '//account/getIncomeList',
        params: {
          'page': this.val,
          'limit': this.limit,
          'key': this.tableDataName,
          'timeFrom': this.finishTimeFrom,
          'timeTo': this.finishTimeTo,
          'withdrawed': 0
        }
      }).then(function (r) {
        _this4.incomeList = false;
        _this4.tableDataEnd = r.data.body.list;
        _this4.total = r.data.body.totalCount;
        _this4.pageSize = r.data.body.totalCount;
        _this4.currentPage = r.data.body.currPage;
        _this4.money = r.data.body.totalMoney;
        _this4.otherMoney = r.data.body.totalOtherMoney;
      }).catch(function (er) {
        _this4.incomeList = false;
      });
      // var self = this
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     data: {
      //         'token': token,
      //         'page': this.val,
      //         'limit': this.limit,
      //         'key': this.tableDataName,
      //         'timeFrom': this.finishTimeFrom,
      //         'timeTo': this.finishTimeTo,
      //         'withdrawed': 0
      //     },
      //     url: '' + baseURL + '//account/getIncomeList',
      //     success: function(json) {
      //         self.tableDataEnd = json.body.list
      //         self.total = json.body.totalCount
      //         self.pageSize = json.body.totalCount
      //         self.currentPage = json.body.currPage
      //         vm.money = json.body.totalMoney
      //         vm.otherMoney = json.body.totalOtherMoney
      //         loading.close()
      //     }
      // })
    },
    see: function see(row, index) {
      var type = 'daobi';
      if (row.courseType === 4) {
        type = 'wenya';
      }
      var Logistics1 = this.$router.resolve({
        path: "/sellerDetail",
        query: {
          id: row.courseId,
          type: type
        }
      });
      window.open(Logistics1.href);
    },
    getUser: function getUser() {
      var _this5 = this;
      this.$axios({
        url: this.$api.baseURL + '/user/getMyInfo'
      }).then(function (r) {
        if (r.data.status === 400) {
          if (r.data.body.code === 4011) {
            _this5.$router.push({
              path: '/login'
            });
          }
        }
        _this5.user = r.data.body;
        _this5.username = r.data.body.username;
        var type = r.body.type;
        if (type == 1) {
          _this5.disabledInput = true;
        } else {
          _this5.disabledInput = false;
        }
      }).catch(function (er) {});
      // $.ajax({
      //     type: 'get',
      //     url: baseURL + '/user/getMyInfo',
      //     dataType: 'json',
      //     headers: {
      //         'token': token
      //     },
      //     success: function(r) {
      //         vm.user = r.body
      //         vm.username = r.body.username
      //         var type = r.body.type
      //         if (type == 1) {
      //             vm.disabledInput = true
      //         } else {
      //             vm.disabledInput = false
      //         }
      //     }
      // })
    },
    getgb: function getgb() {
      var _this6 = this;
      this.$axios({
        url: this.$api.baseURL + '/account/findAccountInfo',
        params: {
          'timeFrom': this.finishTimeFrom,
          'timeTo': this.finishTimeTo
        }
      }).then(function (r) {
        _this6.otherMoneys = Number(r.data.body.money);
        _this6.ruleForm.price1 = r.data.body.money;
        _this6.ruleForm.price2 = r.data.body.otherMoney;
      }).catch(function (er) {});
      // $.ajax({
      //     type: 'get',
      //     url: baseURL + '/account/findAccountInfo',
      //     dataType: 'json',
      //     headers: {
      //         'token': token,
      //         'timeFrom': this.finishTimeFrom,
      //         'timeTo': this.finishTimeTo
      //     },
      //     success: function(r) {
      //         vm.otherMoneys = Number(r.body.money)
      //         vm.ruleForm.price1 = r.body.money
      //         vm.ruleForm.price2 = r.body.otherMoney
      //     }
      // })
    },
    tx: function tx() {
      this.txmodal = true;
      this.jx();
    },
    apply: function apply(row, index) {
      this.modal = true;
    },
    add: function add() {
      this.cardmodal = true;
    },
    getcardlist: function getcardlist() {
      var _this7 = this;
      this.$axios({
        url: this.$api.baseURL + '/bankcard/list',
        params: {
          'page': 1,
          'limit': 1000
        }
      }).then(function (r) {
        _this7.carddata = r.data.body.list;
        _this7.cards = r.data.body.list;
      }).catch(function (er) {});
      // $.ajax({
      //     type: 'get',
      //     dataType: 'json',
      //     data: {
      //         'token': token,
      //         'page': 1,
      //         'limit': 1000
      //     },
      //     url: '' + baseURL + '/bankcard/list',
      //     success: function(json) {
      //         vm.carddata = json.body.list
      //         vm.cards = json.body.list
      //     },
      //     error: function(json) {}
      // })
    },
    remove: function remove(row, index) {
      var _this8 = this;
      var cardid = [];
      cardid.push('' + row.id + '');
      this.$confirm('是否解绑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function (_) {
        _this8.$axios({
          url: _this8.$api.baseURL + '/bankcard/delete',
          method: 'post',
          data: JSON.stringify(cardid),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function (r) {
          if (r.data.status == 200) {
            _this8.$notify({
              title: '提示',
              message: '解绑成功',
              type: 'success'
            });
            _this8.getcardlist();
          }
          if (r.data.status == 400) {
            _this8.$notify({
              title: '提示',
              message: '解绑失败',
              type: 'warning'
            });
            _this8.getcardlist();
          }
        }).catch(function (er) {});
        // $.ajax({
        //     type: 'post',
        //     contentType: 'application/json',
        //     headers: {
        //         'token': token
        //     },
        //     data: JSON.stringify(
        //         cardid
        //     ),
        //     url: '' + baseURL + '/bankcard/delete',
        //     success: function(json) {
        //         if (json.status == 200) {
        //             vm.$notify({
        // title: '提示',
        //                 message: '解绑成功',
        //                 type: 'success'
        //             })
        //             vm.getcardlist()
        //         }
        //         if (json.status == 400) {
        //             vm.$notify({
        // title: '提示',
        //                 message: '解绑失败',
        //                 type: 'warning'
        //             })
        //             vm.getcardlist()
        //         }
        //     }
        // })
      }).catch(function (_) {});
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
      this.limit = val;
      this.getTableData();
    },
    handleChangearea: function handleChangearea(val) {
      var obj = {};
      obj = this.selects.find(function (item) {
        return item.userId === val;
      });
      this.userId = obj.userId;
      this.username = obj.username;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.val = val;
      this.getTableData();
    },
    fangyu: function fangyu(row) {
      this.submitFormLoading = true;
      this.submitForm1(row);
    },
    submitForm1: function submitForm1(formName) {
      var _this9 = this;
      // vm.submitFormLoading = true;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this9.$axios({
            method: 'post',
            url: _this9.$api.baseURL + '//account/withdrawToBank',
            data: {
              'gb': _this9.$refs.ruleForm.model.price1,
              'otgb': _this9.$refs.ruleForm.model.price2,
              'cardId': _this9.$refs.ruleForm.model.card
            },
            headers: {
              'Content-type': 'application/json'
            }
          }).then(function (r) {
            if (r.data.status == '200') {
              _this9.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: 'success'
              });
              _this9.txmodal = false;
              _this9.getcardlist();
            }
            if (r.data.status == '400') {
              _this9.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: 'warning'
              });
            }
            _this9.submitFormLoading = false;
          });
          // $.ajax({
          //     type: 'post',
          //     dataType: 'json',
          //     cache: false,
          //     // contentType: "application/json",
          //     headers: {
          //         'token': token
          //     },
          //     data: {
          //         'gb': this.$refs.ruleForm.model.price1,
          //         'otgb': this.$refs.ruleForm.model.price2,
          //         'cardId': this.$refs.ruleForm.model.card
          //     },
          //     url: '' + baseURL + '//account/withdrawToBank',
          //     success: function(json) {
          //         if (json.status == '200') {
          //             vm.$notify({
          // title: '提示',
          //                 message: json.body.msg,
          //                 type: 'success'
          //             })
          //             vm.txmodal = false
          //             vm.getcardlist()
          //         }
          //         if (json.status == '400') {
          //             vm.$notify({
          // title: '提示',
          //                 message: json.body.msg,
          //                 type: 'warning'
          //             })
          //         }
          //         _this.submitFormLoading = false
          //     }
          // })
        }
      });
    },
    submitForm: function submitForm(formName) {
      var _this10 = this;
      // const datas = this.ruleForm
      // const course_data = datas.course_data
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this10.$axios({
            url: _this10.$api.baseURL + '/bankcard/save',
            data: JSON.stringify({
              // "teacId": vm.teacId,
              'userName': _this10.username,
              'idCardNo': _this10.$refs.ruleForm.model.userIdCard,
              'reservedPhone': _this10.$refs.ruleForm.model.phone,
              'bankOfDeposit': _this10.$refs.ruleForm.model.bankOfDeposit,
              'accountNumber': _this10.$refs.ruleForm.model.accountNumber
              // "bankName": this.$refs.ruleForm.model.bankName,
            }),
            headers: {
              'Content-type': 'application/json'
            },
            method: 'post'
          }).then(function (r) {
            if (r.data.status == '200') {
              _this10.$notify({
                title: '提示',
                message: '添加银行卡成功',
                type: 'success'
              });
              _this10.cardmodal = false;
              _this10.getcardlist();
            }
            if (r.data.status == '400') {
              _this10.$notify({
                title: '提示',
                message: r.data.body.msg,
                type: 'warning'
              });
            }
          }).catch(function (er) {});
          // $.ajax({
          //     type: 'post',
          //     dataType: 'json',
          //     cache: false,
          //     contentType: 'application/json',
          //     headers: {
          //         'token': token
          //     },
          //     data: JSON.stringify({
          //         // "teacId": vm.teacId,
          //         'userName': this.username,
          //         'idCardNo': this.$refs.ruleForm.model.userIdCard,
          //         'reservedPhone': this.$refs.ruleForm.model
          //             .phone,
          //         'bankOfDeposit': this.$refs.ruleForm.model
          //             .bankOfDeposit,
          //         'accountNumber': this.$refs.ruleForm.model
          //             .accountNumber
          //         // "bankName": this.$refs.ruleForm.model.bankName,
          //     }),
          //     url: '' + baseURL + '/bankcard/save',
          //     success: function(json) {
          //         if (json.status == '200') {
          //             vm.$notify({
          // title: '提示',
          //                 message: '添加银行卡成功',
          //                 type: 'success'
          //             })
          //             vm.cardmodal = false
          //             vm.getcardlist()
          //         }
          //         if (json.status == '400') {
          //             vm.$notify({
          // title: '提示',
          //                 message: json.body.msg,
          //                 type: 'warning'
          //             })
          //         }
          //     }
          // })
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var ids = [];
      this.multipleSelection.map(function (item) {
        var id = item.id;
        ids.push('' + id + '');
      });
      this.selectedIDs = ids;
      console.log('多选', ids);
      this.idss = this.selectedIDs;
      console.log(this.idss);
    }
  }
};
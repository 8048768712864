"use strict";

var _interopRequireDefault = require("/root/workspace/classbro-teacher-web-vue_9FLv/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _mkMoticeStep = _interopRequireDefault(require("@/views/classin/mkMoticeStep"));
var _teacManual = _interopRequireDefault(require("@/views/help/components/teacManual"));
var _useGuide = _interopRequireDefault(require("@/views/help/components/useGuide"));
var _aboutMk = _interopRequireDefault(require("@/views/help/components/aboutMk"));
var _cfg = require("./cfg");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    mkMoticeStep: _mkMoticeStep.default,
    teacManual: _teacManual.default,
    useGuide: _useGuide.default,
    aboutMk: _aboutMk.default
  },
  data: function data() {
    return {
      menusList: _cfg.menusList,
      currentId: ''
    };
  },
  computed: {
    teacinfo: function teacinfo() {
      return localStorage.getItem('teacinfo') && JSON.parse(localStorage.getItem('teacinfo'));
    }
  },
  methods: {
    scrollToTop: function scrollToTop(id) {
      this.currentId = id;
      var contentElement = this.$refs.content;
      var sectionElements = document.getElementById(id);
      contentElement.scrollTop = sectionElements.offsetTop - 25;
    },
    handleScroll: function handleScroll() {
      var _this = this;
      var contentElement = document.getElementById('right');
      _cfg.menusList.map(function (v) {
        var el = document.getElementById(v.id);
        if (contentElement.scrollTop - el.offsetTop > -50) {
          _this.currentId = v.id;
        }
        _this.dealChildren(v);
      });
    },
    dealChildren: function dealChildren(v) {
      var _this2 = this;
      var contentElement = document.getElementById('right');
      if (v.children) {
        v.children.map(function (_v) {
          var el = document.getElementById(_v.id);
          if (contentElement.scrollTop - el.offsetTop > -50) {
            _this2.currentId = _v.id;
          }
          _this2.dealChildren(_v);
        });
      }
    },
    toView: function toView(id) {
      this.currentId = id;
      document.getElementById(id) && document.getElementById(id).scrollIntoView();
    },
    showUp: function showUp(index, secIndex, upType) {
      if (secIndex != -1) {
        this.$set(this.menusList[index].children[secIndex], 'upType', upType);
      } else {
        this.$set(this.menusList[index], 'upType', upType);
      }
    }
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("../css/conmmon.scss");
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['referenceList'],
  data: function data() {
    return {
      show: false
      //   referenceList:[
      //      {name:'MLA  Reference  guide.png',url:''},
      //      {name:'Harvard  Reference  guide.png',url:''},
      //      {name:'APA  Reference  guide.png',url:''},
      //      {name:'Chicago  Reference  guide.png',url:''}
      //    ]
    };
  },
  created: function created() {},
  computed: {},
  methods: {
    openUrl: function openUrl(url, filename) {
      var eleLink = document.createElement('a');
      eleLink.download = filename;
      eleLink.style.display = 'none';
      // 字符内容转变成blob地址
      eleLink.href = url;
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
    showReferenceFun: function showReferenceFun() {
      this.show = true;
    }
  }
};